import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import FormModal from '../Modal';
import { getData, updateSubscription } from '../../api';

// custom components

import Button from '../Form/Button';
import {
  RenderInput,
  RenderSelect,
} from '../../pages/Customer/CustomerForm/customComponents/inputGroups';
import { brandErrorCheck } from './brandSubscriptionRenewErrorCheck';

const SubscriptionRenew = ({
  subscriptionArray,
  showRenewModal,
  setShowRenewModal,
  brandId,
  setRmList,
  rmList,
  getCustomerData
}) => {
  const [loading, setLoading] = useState(false);
  const [contactSuppliersShow, setContactSuppliersShow] = useState(false);
  const [propertyDemandInput, setPropertyDemandInput] = useState(false);
  //select options list data
  // const [rmList, setRmList] = useState([]);
  //error object
  const [errorObject, setErrorObject] = useState({});
  const [subscription, setSubscription] = useState({
    propertyOrDemandSelect: '',
    propertyOrDemand: '',
    validityDays: '',
    contactSuppliersSelect: '',
    contactSuppliers: '',
    emailtoSuppliers: '',
    userDashboard: 'Yes',
    alert: '',
    featuredDemand: '',
    assistedUploading: '',
    homePageSlotAndBanner: '',
    relationshipManager: '',
    rm: '',
  });

  const handleSubscriptionRenew = () => {
    delete subscription.contactSuppliersSelect;
    delete subscription.propertyOrDemandSelect;
    if (subscription?.relationshipManager === 'No') {
      delete subscription?.rm;
    }
    setLoading(true);
    updateSubscription(brandId, { ...subscription }, 'Brand').then((res) => {
      setLoading(false);
      const {status, statusCode} =res;
      if(status) {
        setShowRenewModal(false);
        getCustomerData()
      }
      
      console.log(res);
    });
  };

  const handleOnSubmit = async (event) => {
    if (subscription.contactSuppliersSelect === 'Unlimited') {
      subscription.contactSuppliers = 'Unlimited';
    }
    if (subscription.propertyOrDemandSelect === 'Unlimited') {
      subscription.propertyOrDemand = 'Unlimited';
    }

    event.preventDefault();
    let errors = await brandErrorCheck(subscription);

    if (Object.keys(errors).length === 0) {
      handleSubscriptionRenew();
    } else {
      setErrorObject(errors);
    }
  };

  const getRmList = () => {
    let url =
      '/user/getAllSubAdmin?pageNo=0&limit=0&role=Relationship Manager&type=rm';
    getData(url, {}, {}).then((response) => {
      setRmList(response?.data?.subAdminList);
    });
  };
  useEffect(() => {
    if (subscription?.relationshipManager === 'Yes') {
      getRmList();
    }
  }, [subscription?.relationshipManager]);

  const onChangeSubscription = (value, key) => {
    delete errorObject[key];
    let data = value;
    if (key === 'relationshipManager') {
      delete errorObject['rm'];
      if (value !== 'Yes') {
        setRmList([]);
      }
    } else if (key === 'rm') {
      let rmObj = rmList?.filter((el) => value === el?._id);
      data = rmObj[0];
    }
    setSubscription({ ...subscription, [key]: data });
  };
  return (
    <FormModal
      show={showRenewModal}
      heading="Renew Subscription"
      size="lg"
      onClose={() => setShowRenewModal(false)}
    >
      <div className="row mb-3">
        {subscriptionArray.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item.type === 'input' && (
                <RenderInput
                  name={item.name}
                  size="w-50"
                  keyValue={item.key}
                  value={subscription[item.key]}
                  error={errorObject[item.key]}
                  inputType={item.inputType}
                  objName="subscription"
                  onChangeSubscription={onChangeSubscription}
                  // disabled={view || !subscrptionEdit ? true : false}
                  placeholder={item.placeholder}
                  subKey={item.subKey ? item.subKey : ''}
                  contactSuppliersShow={contactSuppliersShow}
                  propertyDemandInput={propertyDemandInput}
                />
              )}
              {item.type === 'select' && (
                <RenderSelect
                  name={item.name}
                  keyValue={item.key}
                  option={item?.option}
                  size="w-50"
                  value={subscription[item.key]}
                  error={errorObject[item.key]}
                  placeholder={item.placeholder}
                  onChangeSubscription={onChangeSubscription}
                  objName="subscription"
                  subKey={item.subKey ? item.subKey : ''}
                  rmVal={subscription?.relationshipManager}
                  // disabled={view || !subscrptionEdit ? true : false}
                  setContactSuppliersShow={setContactSuppliersShow}
                  setPropertyDemandInput={setPropertyDemandInput}
                  subscription={subscription}
                />
              )}
            </React.Fragment>
          );
        })}
        <br />
        <div className="btn-container mt-1 ">
          <>
            <Button
              type="button"
              className="btn-outline-color-primary d-block"
              onClick={(e) => {
                setShowRenewModal(false);
              }}
              // disabled={!subscrptionEdit}
            >
              Cancel
            </Button>
            <Button
              type="button"
              // disabled={loading || !subscrptionEdit}
              // loading={loading}
              className="btn-color-primary d-block"
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              disabled={loading}
              loading={loading}
            >
              Save
            </Button>
          </>
        </div>
      </div>
    </FormModal>
  );
};

export default SubscriptionRenew;

import React from "react";

//icons
import {AiOutlineCheckCircle, AiOutlineCloseCircle} from 'react-icons/ai'

//css
import '../../subscription.scss'
const AgentDataTable = (props) => {
  let dummyData = props.data;

  
  const columnsData = [
    "propertyOrDemand",
    "validityDays",
    "demandMails",
    "submissionAgainstDemand",
    "userDashboard",
    "featuredPositioning",
    "assistedUploading",
    "discount",
    "relationshipManager",
    "subscriptionAmount"
  ];

  //camel case conversion
  const camelCaseConversion = (value) => {
    return value.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };

  return (
    <div className="subscription-container">
      <div className="customTable"  style={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
      <div className="react-bootstrap-table table-responsive customScroll">
        <table className="table">

          <thead className="thead-dark">
            <tr>
              <th>Benefits</th>
              {dummyData.map((k, i) => {
                return (
                  <th key={i}>{k.planName}</th>
                )
              })}
            </tr>
          </thead>

          <tbody className="tableBody" style={{verticalAlign:"middle"}}>
          {columnsData.map((k, index) => (
            <React.Fragment key={index}>
              <tr>
                <th className="benefits-subscription">{k === "propertyOrDemand" ? "Property Uploads" :
                k === "featuredPositioning" ? "Featured property":
                k === "subscriptionAmount" ? "Subscription Amount (Excluding GST)"
                :camelCaseConversion(k)}</th>
                {dummyData.map((j, index1) => (
                  <React.Fragment key={index1}>
                    <td>
                      {String(j[k]) === "true" || String(j[k]) === "Yes" || String(j[k]) === "yes"
                        ? <AiOutlineCheckCircle/>
                        : String(j[k]) === "false" || String(j[k]) === "No" || String(j[k]) === "no"
                          ? <AiOutlineCloseCircle />:
                          k === "discount" ? 
                            String(j[k]) +" %"
                          :
                          k === "subscriptionAmount" ? 
                          "₹ " + String(j[k])
                          : String(j[k]) === ""
                            ? "-"
                            : j[k] === null
                              ? 0
                              : String(j[k])}
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            </React.Fragment>
          ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
};
export default AgentDataTable;

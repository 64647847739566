import { useEffect,useState} from "react";
import BuyerDBTable from "./buyerTable";
import SellerDBTable from "./sellerTable";
import AgentDBTable from "./agentTable";
import NavBar from "../../../components/Navbar";
import { useLocation, useParams } from "react-router-dom";
import { camelCaseConversion } from "../../../custom/camelCaseConversion";
import { socket } from "../../chat/socket";

// style
import '../style.scss'

// context
// export const DashboardTableContext = createContext();

const DashboardTable = ({}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { type } = useParams();
  const [connected,setConnected] = useState(false)
  const [trigger,setTrigger] = useState(false)
  const setTriggerData = () => {
    setTrigger((prev) => !prev)
  }
  useEffect(()=>{
    let user =  JSON.parse(
      localStorage.getItem("keydemand_user")
    )
    const eventHandler = () => setConnected(true);
    const eventId = user?._id;
    socket.on(eventId, eventHandler);
    socket.on(eventId, (newMessage) => {
      if(type === "enterpriseBuyers"){
        if (
          (newMessage?.propertyCount && String(newMessage?.propertyCount)) ||
          newMessage?.newProperty && String(newMessage?.newProperty)
        ) {
          setTriggerData()
        }
      }
      else{
        if (
          (newMessage?.responseCount &&
            String(newMessage?.responseCount))||
          newMessage?.newDemand && String(newMessage?.newDemand)
        ) {
          setTriggerData()
        }
      }
    });
    // unsubscribe from event for preventing memory leaks
    return () => {
      socket.off(eventId, eventHandler);
    };
  },[])

 
  const renderTable = (tableType) => {
    switch (tableType) {
      case "enterpriseBuyers":
        return <BuyerDBTable filterData={searchParams.get("filter")} trigger={trigger}/>;
      case "enterpriseSellers":
        return <SellerDBTable filterData={searchParams.get("filter")} trigger={trigger}/>;
      case "agents":
        return <AgentDBTable  filterData={searchParams.get("filter")} trigger={trigger}/>;
    }
  };
  return (
   
       <div className="db-table-container">
      <NavBar/>
      <div className="text-heading mb-0">{camelCaseConversion(type)}</div>
      <div>{renderTable(type)}</div>
    </div>
   
  );
};
export default DashboardTable;


export const buyerUrls = {
  companyList: () => "/admin/getAllCompanyList?type=Brand",
  companyPocList: () => "/admin/getAllCompanyPocList",
  regionPocList: () => "/admin/getAllRegionPocList",
  brandList: () => "/admin/getAllBrandList?type=Brand",
  demandPostedList: () => "/admin/demandPosted",
  brandPocList: () => "/admin/getAllBrandPocList",
  inActiveDemandList: () => "/admin/demandPosted",
  activeDemandList: () => "/admin/demandPosted",
  demandResponseList: () => "/admin/demandPosted",
  propertyContactedList: () => "/admin/getAllPropertyContacted",
  needMoreInfoList: () => "/admin/getAllResponseAccept",
  propertyRejectedList: () => "/admin/getAllResponseAccept",
  propertyAcceptedList: () => "/admin/getAllResponseAccept",
};
export const buyerFilterUrls = {
  companyList: () => "/admin/companyFilter",
  companyPocList: () => "/admin/pocFilter",
  brandList: () => "/admin/brandFilter",
  brandPocList: () => "/admin/pocFilter",
  regionPocList: () => "/admin/pocFilter",
  needMoreInfoList: () => "/admin/propertyReportFilter",
  propertyContactedList: () => "/admin/propertyReportFilter",
  propertyAcceptedList: () => "/admin/propertyReportFilter",
  propertyRejectedList: () => "/admin/propertyReportFilter",
  demandPostedList: () => "/admin/demandPostedFilter",
  inActiveDemandList: () => "/admin/demandPostedFilter",
  activeDemandList: () => "/admin/demandPostedFilter",
  demandResponseList: () => "/admin/demandPostedFilter",
};

import { Form } from "react-bootstrap";

const PhoneNumberInput = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  size,
  errorMessage,
  isInvalid,
  min,
  max,
  disabled,
  addText,
  onBlur,
}) => {
  const handlePaste = (e) => {
    // Get the pasted text
    const pastedText = e.clipboardData.getData("text");
    // Check if the pasted text contains only numbers
    if (!/^\d+$/.test(pastedText)) {
      // If the pasted text is not all numbers, prevent the paste operation
      e.preventDefault();
    }
  };
  const onKeyDown = (e) => {
    // 69 e, 40 downArrow, 38 Uparrow, 190 . , 187 =
    if (
      e.keyCode === 40 ||
      e.keyCode === 38 ||
      e.keyCode === 69 ||
      e.keyCode === 190 ||
      e.keyCode === 187
    ) {
      e.preventDefault();
    }
  };
  const inputChange = (e) => {
    if (e.target.value.length > 10) {
      return false;
    }
    onChange(e);
  };
  return (
    <Form.Group
      className={"form-group phonenumber-input " + size}
      controlId={name}
    >
      <div className="d-grid">
        <Form.Label id={name} className="fw-700 f-14 text-black">
          {label}
        </Form.Label>
        {addText ? (
          <label id={addText} className="fw-400 f-14 text-black mb-1">
            {addText}
          </label>
        ) : (
          ""
        )}
      </div>
      <div className={"input-group"}>
        <div className="input-group-prepend">
          <Form.Select
            isInvalid={isInvalid}
            disabled={disabled}
            className="form-select phonenumber-select"
          >
            <option>+91</option>
          </Form.Select>
        </div>
        <Form.Control
          className={"phonenumber-control"}
          id={name + "input"}
          type={type}
          name={name}
          value={value}
          isInvalid={isInvalid}
          placeholder={placeholder}
          onChange={inputChange}
          min={min}
          max={max}
          disabled={disabled}
          autoComplete="off"
          onBlur={onBlur}
          maxLength="10"
          onPaste={handlePaste}
          onKeyDown={onKeyDown}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
};

export default PhoneNumberInput;

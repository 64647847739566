import { useEffect, useState } from "react";
import { useSelector, } from "react-redux";
import { AccessDenied, getData } from "../../api";
import Navbar from "../../components/Navbar";
import "./index.scss";
import LineCharts from "./lineChart";
import PieCharts from "./pieChart";

function Reports() {
  const [countData, setCountData] = useState([]);
  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    exportReports: loginPermission?.includes(menuId?.exportReports) ? true : false,
    viewReports: loginPermission?.includes(menuId?.viewReports) ? true : false,
  };

  const getCountDataList = async () => {
    let url ="/dashboard/getdashboard";

    const newData = await getData(url, {});
    const { status, data } = newData;
    if (status) {
      setCountData(data);
    }
  };
  const overAllHeader = [
    "Active Properties",
    "Active Demands",
    "Property Responses",
    "Demand Response",
    "Failed Payment",
  ];
  useEffect(() => {
    getCountDataList();
  }, []);
  return (
    <div>
       {loginPermission?.includes(menuId?.reports) ? (
        <>
      <Navbar />
      <div className="reportWrapper">
        {countData?.length > 0
          ? countData?.map((item, index) =>
              overAllHeader?.includes(item?.label) ? (
                <div className="reportHeader" key={index}>
                  <p>{item?.label}</p>
                  <span>
                    <b>{item?.value}</b>
                  </span>
                </div>
              ) : null
            )
          : ""}
      </div>
      <div className="usersWrapper">
        <LineCharts
          cardData={countData ? countData : []}
          title="Active Users"
          chartName="usersActive"
          access={access}
        />
      </div>
      <div className="visitorsWrapper">
        <LineCharts title="Visitors / Users" chartName="usersVisitors" 
          access={access}
          />
      </div>
      
      <div className="RegistrationWrapper">
        <LineCharts
          title="Registration / Enquiries"
          chartName="usersRegistration"
          access={access}
        />
      </div>
     
      <div className="pieWrapper">
        <div className="first">
          <PieCharts
            title="Time Spent on the application"
            filter={false}
            chartname="timeSpent"
          access={access}
          />
        </div>
        <div>
          <PieCharts
            title="Subscribed Users -Agent"
            filter={true}
          access={access}
            chartname="subscribed"
          />
        </div>
        <div>
          <PieCharts
            title="Feild vs hit ratio"
            filter={false}
            chartname="hitRatio"
          access={access}

          />
        </div>
      </div>
      <div className="RegistrationWrapper">
        <LineCharts
          title="Properties / Demands"
          sideVal="No. of Properties / Demands"
          chartName="usersProperty"
          access={access}
        />
      </div>
      </>):
      <AccessDenied/>}
    </div>
  );
}

export default Reports;

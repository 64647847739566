import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { baseURL, getKeyByValue } from "../../api/index";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input/index";
import CardComponent from "../../components/loginCardComponent/cardComponent";
import "../../pages/Login/style.scss";

import "react-toastify/dist/ReactToastify.css";
import { isLogedIn } from "../../api";
// import swal from "sweetalert";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

function Reset() {
  let navigate = useNavigate();
  const formRef = useRef(null);

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCnfPwd, setIsRevealCnfPwd] = useState(false);

  const pathname = window.location.pathname;
  const [errorObject, setErrorObject] = useState({});
  const [apiError, setApiError] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
  const onChange = (value, key) => {
    delete errorObject[key];
    delete errorObject.commonError;
    setInitialValues({ ...initialValues, [key]: value });
  };

  const errorCheck = (value) => {
    let errorObj = {};
    let regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (value.password === "") {
      errorObj.password = "This field is required";
    }
    if (value.confirmPassword === "") {
      errorObj.confirmPassword = "This field is required";
    }
    if (!regex.test(value.confirmPassword) && value.confirmPassword !== "") {
      errorObj.confirmPassword = "Please check password format";
    }
    if (!regex.test(value.password) && value.password !== "") {
      errorObj.password = "Please check password format";
    }

    if (
      value.confirmPassword !== "" &&
      value.password !== "" &&
      value.confirmPassword !== value.password
    ) {
      errorObj.commonError = "Both new and confirm password should be same";
    }
    return errorObj;
  };
  const resetSubmit = async (data) => {
    let param = pathname.split("/");
    setBtnLoading(true);
    try {
    
      const response = await fetch(`${baseURL}/user/updatePassword`, {
        method: "PUT",
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${param[2]}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (responseData.statusCode === 200) {
        setBtnLoading(false);
        Swal.fire({
          // title: header,
          text: responseData.message,
          icon: "success",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
        // navigate("/login");
      } else if (
        responseData.statusCode === 400 ||
        responseData.statusCode === 422
      ) {
        setBtnLoading(false);
      } else if (responseData.statusCode === 401) {
        setBtnLoading(false);
        Swal.fire({
          // title: header,
          text: "You Session Has Expired,Please Try Again",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      } else {
        setBtnLoading(false);
        Swal.fire({
          // title: header,
          text: responseData.message,
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    } catch (e) {
      setBtnLoading(false);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let errors = errorCheck(initialValues);
    setErrorObject(errors);
    setApiError("");
    if (Object.keys(errors).length === 0) {
      const data = {
        password: initialValues.confirmPassword,
      };
      resetSubmit(data);
    }
  };

  const checkTokenVerify = async () => {
    let param = pathname.split("/");
    try {
      const response = await fetch(`${baseURL}/user/getTokenVerify`, {
        method: "GET",
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${param[2]}`,
        },
      });

      const responseData = await response.json();
      if (responseData.statusCode === 200) {
      } else if (responseData.statusCode === 401) {
        // alert("You Session Has Expired,Please Try Again");

        Swal.fire({
          // title: header,
          text: responseData.message,
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
        // navigate("/login");
      }
    } catch (e) {}
  };


  useEffect(() => {
    if (isLogedIn()) {
      let route = getKeyByValue(menuId, loginPermission[0]);
      navigate(`/${route}`);
    }
    checkTokenVerify();
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit(event);
    }
  };

  const loginContent = (name) => {
    return (
      <>
        {/* <Toast/> */}
        {name === "reset" && (
          <form ref={formRef} OnKeyPress={handleKeyPress}>
            <h5 className="m-0 pb-1 fw-bold"> Reset Password </h5>
            <p className="getStarted-txt medium-txt mb-3 mt-1">
              {" "}
              Create your new password{" "}
            </p>
            <div>
              <div className="mb-2 input-position-style">
                <Input
                  label={"Password"}
                  type={isRevealPwd ? "text" : "password"}
                  placeholder={"Enter your password"}
                  isInvalid={!!errorObject.password}
                  errorMessage={errorObject.password}
                  onChange={(e) => onChange(e.target.value, "password")}
                  className={"input-padding-icon"}
                  value={initialValues?.password}
                />
                <span
                  className="eye-icon-style"
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                >
                  {" "}
                  {isRevealPwd ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )}{" "}
                </span>
              </div>
              <div className="input-position-style">
                <Input
                  label={"Confirm Password"}
                  type={isRevealCnfPwd ? "text" : "password"}
                  placeholder={"Enter your password"}
                  isInvalid={!!errorObject.confirmPassword}
                  errorMessage={errorObject.confirmPassword}
                  onChange={(e) => onChange(e.target.value, "confirmPassword")}
                  className={"input-padding-icon"}
                  value={initialValues?.confirmPassword}
                />
                <span
                  className="eye-icon-style"
                  onClick={() => setIsRevealCnfPwd((prevState) => !prevState)}
                >
                  {" "}
                  {isRevealCnfPwd ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )}{" "}
                </span>
                {errorObject.commonError && (
                  <div className="customInvalid">{errorObject.commonError}</div>
                )}

                <div className="error-message_span mb-3">{apiError}</div>
              </div>
              <p className="text-muted f-14">
                (Note:Password must be a minimum of 8 characters including
                number, Upper, Lower And one special character)
              </p>

              <div className="submit-alignment ">
                <Button
                  loading={btnLoading}
                  type={"submit"}
                  className="submit-button-style btn-color-primary"
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
            <NavLink to="/login">
              <p className="blue-color font-weight text-center mt-3 mb-0">
                {" "}
                Back to Log in{" "}
              </p>
            </NavLink>
          </form>
        )}
      </>
    );
  };

  return (
    <div className="login-background">
      <CardComponent loginContent={loginContent("reset")} />
    </div>
  );
}

export default Reset;

import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

// components
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import Search from "../../../components/Search/search";
import {
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  getData2,
  switchToUsers,
} from "../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../assets/images/switchuser.png";
import CrossSign from "../../../assets/images/CrossSign.svg";
import Loader from "../../../components/Loader";
import SwitchAccountPopup from "../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useNavigate, useSearchParams } from "react-router-dom";

const ListResponseDemandAgentTable = ({ filterData, trigger, tableType }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [ContactedBy, setContactedBy] = useState();
  const [sort, setSort] = useState();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  // const newResponses = searchParams.get("filter") === "new";
  const newResponses = tableType.includes("New");

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const handleAccountSwitch = async (id) => {
    setCompanyId(id);
    getAllSwitchUsers(id).then((res) => {
      if (res.status && res.statusCode === 200) {
        const companySpecificPOCs = res?.data?.companySpecificPOCs || [];
        // const brandPOCs = res?.data?.brandPOCs || [];
        // const regionPOCs = res?.data?.regionPOCs || [];
        const combinedArrays = [...companySpecificPOCs];
        if (combinedArrays.length === 1) {
          const user = combinedArrays[0];
          const data = {
            companyId: id,
            customerId: user._id,
            type: "user",
          };
          switchToUsers(data).then((res) => {
            let openUrl = process.env.REACT_APP_SWITCH_URL;
            if (res.status) {
              window.open(`${openUrl}/${res?.data?.token}`);
            }
          });
        } else if (combinedArrays.length < 1) {
          Swal.fire({
            // title: header,
            text: "No Poc Found",
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        } else {
          setSwitchModal(true);
          setPocs({
            companyPocs: companySpecificPOCs,
          });
        }
      }
    });
  };

  const columnsBrand = [
    {
      dataField: "respondedOn",
      text: "Timestamp",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row.respondedOn).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: "Agent Name",
      dataField: "pocName",
    },
    {
      text: "Demand ID",
      dataField: "demandId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className="hyperlinkText"
            onClick={(e) => {
              navigate("DemandDetailView/" + row?.demandId);
            }}
          >
            {row?.demandId}
          </div>
        );
      },
    },
    {
      text: "Contacted By",
      dataField: "ContactedBy",
      formatter: (cellContent, row, rowIndex) => {
        console.log("row", row);
        return <div>{row?.respondedByType?.name}</div>;
      },
    },
    {
      text: "Name/POC Name",
      dataField: "pocName",
    },
    {
      text: "Submitted By",
      dataField: "submittedBy",
      formatter: (cellContent, row, rowIndex) => {
        console.log("row", row);
        return <div>{row?.responededBy}</div>;
      },
    },
    {
      text: "Status",
      dataField: "status",
      formatter: (cellContent, row, rowIndex) => {
        console.log("row", row);
        return <div>{row?.respondedByType?.type}</div>;
      },
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return (
    //       <>
    //         {newResponses ? (
    //           <div className="db-number-card-container">
    //             <img
    //               style={{ width: "20px", height: "20px" }}
    //               alt="cross"
    //               src={CrossSign}
    //             />
    //           </div>
    //         ) : (
    //           <div className="db-number-card-container">
    //             {getCurrentUser()?.role[0]?.name === "admin" ||
    //             getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
    //               <img
    //                 style={{ cursor: "pointer", width: "20px", height: "20px" }}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   handleAccountSwitch(row?._id);
    //                 }}
    //                 alt="switch"
    //                 src={switchuser}
    //               />
    //             ) : (
    //               ""
    //             )}
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];
  if (newResponses) {
    const actionCol = [
      {
        dataField: "action",
        text: "Action",
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div className="db-number-card-container">
              <img
                style={{ width: "20px", height: "20px" }}
                alt="cross"
                src={CrossSign}
              />
            </div>
          );
        },
      },
    ];
    columnsBrand.splice(6, 0, ...actionCol);
  }
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }

    // let url = `/dashboard/getCustomers?type=Brand&page=${offSet}&limit=${perPage}`;
    let url = `/dashboard/getPropertyResponses?pageNo=${offSet}&limit=${perPage}&type=Agent&isNew=${
      newResponses ? true : false
    }`;
    if (search.length > 2) {
      url += `&search=${search}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }

    let cusomerData = await getData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data.count / perPage));
      setTotalCount(data.count);
      setTableData(data.list);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  const tableProps = {
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "RmDashboard",
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [perPage, offSet, search, ContactedBy, sort]);

  useEffect(() => {
    getTableData(false);
  }, [trigger]);

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];
  let ContactedBySortValues = [
    {
      name: "Agent",
      _id: "One",
    },
    {
      name: "Developer",
      _id: "Two",
    },
    {
      name: "Buyer",
      _id: "Three",
    },
  ];
  let statusSortValues = [
    {
      _id: "Inbox Received",
      name: "InboxReceived",
    },
    {
      _id: "Contact requested",
      name: "Revenue Share",
    },
  ];

  return (
    <>
      {loading && <Loader className={"fixed"} />}
      <div className="db-filter-container">
        <Row className=" filter-select">
          <>
            <Col md={4} sm={5}>
              <Select
                placeholder="Posted On"
                options={customerCreatedSortValues}
                name={"Posted"}
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                  setOffset(1);
                }}
                // isHideSelect
              />
            </Col>
            {/* <Col md={4} sm={5}>
              <Select
                placeholder="Contacted By"
                options={ContactedBySortValues}
                name={"ContactedBy"}
                value={ContactedBy}
                onChange={(e) => setContactedBy(e.target.value)}
                // isHideSelect
              />
            </Col>
            <Col md={4} sm={5}>
              <Select
                placeholder="Status"
                options={statusSortValues}
                name={"status"}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                // isHideSelect
              />
            </Col> */}
          </>
        </Row>
        <Row className=" filter-search-inbox">
          <Col md={6} sm={7} className="d-flex align-items-start">
            <Search onChange={handleSearch} value={search} />
          </Col>
        </Row>
      </div>
      <MainTable {...tableProps} trigger={trigger} />
      <SwitchAccountPopup
        show={switchModal}
        onClose={() => setSwitchModal(false)}
        data={pocs}
        companyId={companyId}
      />
    </>
  );
};
export default ListResponseDemandAgentTable;

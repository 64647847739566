import { Form } from "react-bootstrap";
import "./style.scss";
const Select = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  size,
  errorMessage,
  options,
  isInvalid,
  addText,
  onBlur,
  disabled,
  required,
  isHideSelect,
  autoFocus,
}) => {
  const keyDownHandler = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };
  return (
    <Form.Group controlId={name} className={"form-group select-group " + size}>
      <div className="d-grid">
        {label && (
          <Form.Label id={name} className="fw-700 f-14 text-black">
            {label} {required ? "*" : null}
          </Form.Label>
        )}
        {addText ? (
          <label id={addText} className="fw-400 f-14 text-black mb-1">
            {addText}
          </label>
        ) : (
          ""
        )}
      </div>
      <Form.Select
        required={required}
        name={name}
        value={value}
        placeholder={placeholder}
        isInvalid={isInvalid}
        onChange={onChange}
        autoComplete="off"
        onBlur={onBlur}
        disabled={disabled}
        onKeyDown={keyDownHandler}
        autoFocus={autoFocus}
      >
        {!isHideSelect && (
          <option value={""}>{placeholder ? placeholder : "Select"}</option>
        )}
        {options?.length > 0 &&
          options?.map((item, index) => {
            return (
              <option
                key={index}
                value={
                  item._id
                    ? item._id
                    : item.value
                    ? item.value
                    : item.cityId
                    ? item.cityId
                    : item.name
                }
              >
                {item && item?.name
                  ? item.name
                  : item?.label
                  ? item?.label
                  : item}
              </option>
            );
          })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Select;

import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

// components
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import Search from "../../../components/Search/search";
import {
  AccessDenied,
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  refreshData,
  switchToUsers,
} from "../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../assets/images/switchuser.png";
import CrossSign from "../../../assets/images/CrossSign.svg";
import Loader from "../../../components/Loader";
import SwitchAccountPopup from "../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import RefreshButton from "../../../components/RefreshButton";
import { useSelector } from "react-redux";

const ListDeveloperRelevancyTable = ({ trigger, tableType }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [refLoading, setRefLoading] = useState(false);

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  const [searchParams, setSearchParams] = useSearchParams();
  // const newRelevancy = searchParams.get("filter") === "new";
  const newRelevancy = tableType.includes("New");
  const [search, setSearch] = useState("");
  const [listType, setListType] = useState(
    searchParams.get("filter") === "new" ? "new" : "all"
  );
  const [sort, setSort] = useState();
  let navigate = useNavigate();

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleAccountSwitch = async (id) => {
    const data = {
      customerId: id,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = process.env.REACT_APP_SWITCH_URL;
      if (res.status) {
        window.open(`${openUrl}/${res?.data?.token}`);
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };

  const handleSwitchToRelevant = (row, isNew) => {
    const data = {
      customerId: row?.pocId,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = process.env.REACT_APP_SWITCH_URL;
      if (res.status) {
        window.open(
          `${openUrl}/${
            res?.data?.token
          }?trd=/profile/current-relevant-demands${
            isNew ? "/New" : ""
          }?propertyId=${row?.propertyId}`
        );
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };

  let columnsBrand = [
    {
      text: "Timestamp",
      dataField: "createdAt",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row?.createdAt).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: "Developer Name",
      dataField: "developerName",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row?.developerName}</div>;
      },
    },
    {
      text: "POC Name",
      dataField: "pocName",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <>
            {/* {newRelevancy ? (
              <div
                className="hyperlinkText"
                onClick={(e) => {
                  e.preventDefault();
                  handleAccountSwitch(row?.response?.userId);
                }}
              >
                {row?.pocName}
              </div>
            ) : (
              <div>{row?.pocName}</div>
            )} */}
            <div>{row?.pocName}</div>
          </>
        );
      },
    },
    {
      text: "Property ID",
      dataField: "propertyDisplayId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className="hyperlinkText"
            onClick={(e) => {
              cellContent &&
                navigate("propertyDetailView/" + cellContent, {
                  state: { isNew: newRelevancy },
                });
            }}
          >
            {cellContent}
          </div>
        );
      },
    },
    {
      text: "Total Relevancy",
      dataField: "count",
      formatter: (cellContent, row) => {
        return (
          <div
            className={`${
              cellContent &&
              loginPermission?.includes(menuId?.enterpriseSellerSwitch) &&
              "hyperlinkText"
            }`}
            onClick={(e) => {
              if (loginPermission?.includes(menuId?.enterpriseSellerSwitch)) {
                handleSwitchToRelevant(row, false);
              }
            }}
          >
            {cellContent}
          </div>
        );
      },
    },
    // {
    //   text: "Action",
    //   dataField: "action",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return (
    //       <>
    //         {newRelevancy ? (
    //           <div className="db-number-card-container">
    //             <img
    //               style={{ width: "20px", height: "20px" }}
    //               alt="cross"
    //               src={CrossSign}
    //             />
    //           </div>
    //         ) : (
    //           <div className="db-number-card-container">
    //             {getCurrentUser()?.role[0]?.name === "admin" ||
    //             getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
    //               <img
    //                 style={{ cursor: "pointer", width: "20px", height: "20px" }}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   handleAccountSwitch(row?.response?.userId);
    //                 }}
    //                 alt="switch"
    //                 src={switchuser}
    //               />
    //             ) : (
    //               ""
    //             )}
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  if (newRelevancy) {
    let newRelevancyObj = {
      text: "New Relevancy",
      dataField: "unreadCount",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className={`${
              cellContent &&
              loginPermission?.includes(menuId?.enterpriseSellerSwitch) &&
              "hyperlinkText"
            }`}
            onClick={(e) => {
              if (loginPermission?.includes(menuId?.enterpriseSellerSwitch)) {
                handleSwitchToRelevant(row, true);
              }
            }}
          >
            {cellContent}
          </div>
        );
      },
    };
    const actionCol = [
      {
        dataField: "action",
        text: "Action",
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div className="db-number-card-container">
              <img
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                alt="cross"
                src={CrossSign}
              />
            </div>
          );
        },
      },
    ];
    columnsBrand.splice(5, 0, ...actionCol);
    columnsBrand.splice(4, 0, newRelevancyObj);
  }
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }

    let url = `/dashboard/getDashboardRelevantProperties?pageNo=${offSet}&limit=${perPage}&isNew=${
      newRelevancy ? true : false
    }`;
    if (search.length > 2) {
      url += `&searchTerm=${search.trim()}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }

    let cusomerData = await getData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data?.totalData / perPage));
      setTotalCount(data?.totalData);
      setTableData(data?.responseList);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rmName",
        formatter: (cellContent, row, rowIndex) => {
          let rmName = row?.rmData?.name;
          return <div>{rmName ? rmName : "-"}</div>;
        },
      },
    ];
    columnsBrand.splice(4, 0, ...rmData);
  }

  let tableProps = {
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "RmDashboard",
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [perPage, offSet, search, sort, listType]);

  // useEffect(() => {
  //   getTableData(false);
  // }, [trigger]);

  let sortValues = [
    {
      name: "Newest",
      _id: "dsc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];

  const onClickResponse = (value) => {
    if (value === "new") {
      setSearchParams({ filter: value });
      setListType(value);
    }
    if (value === "all") {
      setSearchParams({});
      setListType(value);
    }
  };

  const refreshRelevancy = async () => {
    const url = `/dashboard/setRelevancyData?type=relevantPropertyForDeveloper`;
    setRefLoading(true);
    let response = await refreshData(url);
    setRefLoading(false);
    if (response?.status) {
      Swal.fire({
        // title: header,
        text: "Please wait! New data will reflect after 5 Min",
        icon: "success",
        timer: 5000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
  };

  if  (loginPermission?.includes(menuId?.enterpriseSellerDashboard)) {
    return (
      <>
        {loading && <Loader className={"fixed"} />}
        <div className="db-filter-container">
          <div className="Relevancy_Refresh">
            <RefreshButton loading={refLoading} onClick={refreshRelevancy} />
          </div>
          <Row className=" filter-select">
            <>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Posted On"
                  options={sortValues}
                  name={"sort"}
                  value={sort}
                  onChange={(e) => {
                    setSort(e.target.value);
                    setOffset(1);
                  }}
                />
              </Col>
            </>
          </Row>
          <Row className=" filter-search-inbox">
            <Col md={6} sm={7} className="d-flex align-items-start">
              <Search onChange={handleSearch} value={search} />
            </Col>
          </Row>
        </div>
        <MainTable {...tableProps} trigger={trigger} />
        <SwitchAccountPopup
          show={switchModal}
          onClose={() => setSwitchModal(false)}
          data={pocs}
          companyId={companyId}
        />
      </>
    );
  } else {
    return (
      <AccessDenied />
    )
  }

  
};
export default ListDeveloperRelevancyTable;

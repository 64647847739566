import { useEffect, useState } from "react";

//library
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

//fetch
import {
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  postData,
  putData,
  switchToUsers,
} from "../../../api/index";
//icons
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";

//components
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { AppContext } from "../../../App";
import switchuser from "../../../assets/images/switchuser.png";
import Button from "../../../components/Form/Button";
import MainTable from "../../../components/Table/index";
import {
  getTableStoreValue,
  setTableFilterData,
} from "../../../utilities/pageScroll";
import { CustomerWarningPopup } from "../../Customer/CustomerTable/warningPopup";
import {
  updateBrandId,
  updateComapanyId,
  updateCompanyFormStatus,
  updateHasSubBrand,
} from "../../Login/reducer";
import SwitchSellerAccountPopup from "../../../components/switchModal/SwitchCompanySellerPopup";

const SellerTable = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let tableName = "enterpriseSellers";
  const { tableStoreContext, setTableStoreContext } = useContext(AppContext);
  const currentTableValueFromConText = getTableStoreValue(
    tableName,
    tableStoreContext
  );
  const storetableName = currentTableValueFromConText?.tableName;
  const storePerPage = currentTableValueFromConText?.perPage;
  let checkForTableName = tableName === storetableName;
  //list
  const [listData, setListData] = useState([]);
  //pagination
  const [perPage, setPerPage] = useState(
    checkForTableName ? (storePerPage ? storePerPage : 10) : 10
  );
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  //active
  const [activeStatus, setActiveStatus] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  let search = props.search ? props.search : "";
  let activeFilterKey = props.activeFilterKey ? props.activeFilterKey : "";
  let cityFilterKey = props.cityFilterKey ? props.cityFilterKey : "";
  const setContextValue = (id) => {
    let newValue = setTableFilterData(
      tableName,
      { search: props?.search, pageNo: props.offSet, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };

  const handleAccountSwitch = async (id) => {
    setCompanyId(id);
    getAllSwitchUsers(id).then((res) => {
      if (res.status && res.statusCode === 200) {
        const developerPocs = res?.data?.developerPOCs || [];
        const cityPocs = res?.data?.cityPOCs || [];
        const combinedArrays = [...developerPocs, ...cityPocs];
        if (combinedArrays.length === 1) {
          const user = combinedArrays[0];
          const data = {
            companyId: id,
            customerId: user._id,
            type: "user",
          };
          switchToUsers(data).then((res) => {
            let openUrl = process.env.REACT_APP_SWITCH_URL;
            if (res.status) {
              window.open(`${openUrl}/${res?.data?.token}`);
            }
          });
        } else if (combinedArrays.length < 1) {
          Swal.fire({
            // title: header,
            text: "No Poc Found",
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        } else {
          setSwitchModal(true);
          setPocs({
            developerPocs,
            cityPocs,
          });
        }
      }
    });
  };

  const getCustomerList = () => {
    let url = `/company/?pageNo=${
      props.offSet ? props.offSet : 1
    }&limit=${perPage}&type=${"Developer"}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (activeFilterKey === "Active") {
      url += `&isActive=${true}`;
    }
    if (activeFilterKey === "Inactive") {
      url += `&isActive=${false}`;
    }
    if (cityFilterKey !== "City" && cityFilterKey !== "") {
      url += `&cities=${cityFilterKey}&id=${props.cityId}`;
    }
    getData(url).then((response) => {
      if (response.status) {
        setPageCount(Math.ceil(response.data.totalDoc / perPage));
        setTotalCount(response.data.totalDoc);
        setListData(response.data.companies);
        localStorage.removeItem("customerData");
      } else {
        setPageCount(0);
        setListData([]);
      }
    });
  };

  const warningYesClick = () => {
    let status = {
      isActive: activeStatus ? false : true,
      isCompany: true,
    };
    let url = `/brand/${customerId}`;
    putData(url, {}, status).then((response) => {
      if (response.status) {
        getCustomerList();
        setWarningPopup(false);
      } else {
        setWarningPopup(false);
      }
    });
  };

  const handleWarningClose = () => {
    setWarningPopup(false);
  };
  const onActiveStatusChange = (id, active) => {
    setActiveStatus(active);
    setCustomerId(id);
    setWarningPopup(true);
    if (active === true) {
      setWarningMessage("Are you sure, you want to deactivate this company");
    } else if (active === false) {
      setWarningMessage("Are you sure, you want to activate this company");
    }
  };

  function userButtonFormatterSeller(cell, row, rowIndex) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center ">
          {getCurrentUser()?.role[0]?.name === "admin" ||
          getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
            <img
              style={{ cursor: "pointer", width: "17px", height: "17px" }}
              onClick={(e) => {
                e.preventDefault();
                handleAccountSwitch(row?.companyId);
              }}
              alt="switch"
              src={switchuser}
            />
          ) : (
            ""
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="table-btn p-0"
            onClick={(e) => {
              setContextValue(row?._id);
              localStorage.removeItem("propTabKey");
              dispatch(updateBrandId(row?._id));
              dispatch(updateComapanyId(row?._id));
              dispatch(updateHasSubBrand(row?.subBrands));
              dispatch(updateCompanyFormStatus("view"));
              localStorage.setItem("brandEdit", JSON.stringify(row?.subBrands));
              navigate("/company/developerCompanyCreation", {
                state: { view: true },
              });
            }}
            disabled={props?.access?.viewCompanySeller ? false : true}
          >
            <BsFillEyeFill />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className={"table-btn p-0"}
            onClick={(e) => {
              if (row.isActive) {
                setContextValue(row?._id);
                localStorage.setItem(
                  "brandEdit",
                  JSON.stringify(row?.subBrands)
                );
                dispatch(updateHasSubBrand(row?.subBrands));
                dispatch(updateBrandId(row?._id));
                dispatch(updateComapanyId(row?._id));
                dispatch(updateCompanyFormStatus("edit"));
                navigate("/company/developerCompanyCreation", {
                  state: { edit: true },
                });
              }
            }}
            disabled={props?.access?.editCompany ? false : true}
          >
            <FaPen />
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={(e) => onActiveStatusChange(row._id, row.isActive)}
            disabled={props.access.companySellerEnableDisable ? false : true}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </>
    );
  }
  const columnsBuyer = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "companyName",
      text: "Developer Name",
    },
    {
      dataField: "brandCount",
      text: "No.of Developers",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterSeller,
    },
  ];

  const handelChangePerPage = (count) => {
    setPerPage(count);
    props?.setOffset(1);
  };
  //
  const tableProps = {
    data: listData,
    columns: columnsBuyer,
    offset: props?.offSet,
    setOffset: props?.setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: tableName,
    setPerPage,
  };
  useEffect(() => {
    getCustomerList();
  }, [perPage, props?.offSet, search, activeFilterKey, cityFilterKey]);
  return (
    <div>
      {warningPopup && (
        <CustomerWarningPopup
          show={warningPopup}
          title={"Confirmation"}
          message={warningMessage}
          warningYesClick={warningYesClick}
          onClose={handleWarningClose}
        />
      )}
      <SwitchSellerAccountPopup
        show={switchModal}
        onClose={() => setSwitchModal(false)}
        data={pocs}
        companyId={companyId}
      />
      <MainTable {...tableProps} />
    </div>
  );
};
export default SellerTable;

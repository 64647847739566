import MainTable from "../../Table/index";


const ImportErrorModal = ({data}) =>{
  
    const column = [
        {
          dataField: "sno",
          text: "S.no",
        },
        {
          dataField: "row",
          text: "Row ID",
        },
        {
          dataField: "field",
          text: "Field Name",
        },
        {
          dataField: "message",
          text: "Error",
        },
      ];

     
     
      const tableProp = {
        data: data,
        columns: column,
        offset: 1,
        setOffset: 1,
        // handelChangePerPage,
        pageCount: 0,
        perPage: 10,
        totalCount: 10,
      };
return(
   
      <MainTable {...tableProp} />

   
)
}
export default ImportErrorModal;
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import Select from "react-select";
import "./style.scss";
import { BsFillEyeFill } from "react-icons/bs";
import { truncateString } from "../../utilities/commonFunction";
import { useState } from "react";
import FilterTableList from "../tableFilterList";
import Input from "../Form/Input";

// import { Form } from "react-bootstrap";

const CustomeMultiSelect = (props) => {
  const { isMulti, value, onChange, onChangeStationName, nearestLocationError } = props;
  const [showTableList, setShowTableList] = useState(false);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      padding: "1px 5px",
      transition: "none",
      width: "100%",
      background: props.disabled ? "#e9ecef" : "#ffff",
      outline: "none",
      borderRadius: "0.375rem",
      borderColor: props.errormessage ? "#dc3545" : "#ced4da",
    }),
    indicatorSeparator: (styles) => ({ display: "none" }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#555555",
        fontSize: "16px",
        fontWeight: "500",
      };
    },
  };
  const handleRemoveValue = (e) => {
    e.preventDefault();
    if (e.target.classList.contains("close-icon")) return;
    if (!onChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue =
      !props?.disabled &&
      value?.find((val) => {
        return String(val.value) === String(buttonName);
      });
    if (!removedValue) return;
    onChange(
      value?.filter((val) => String(val?.value) !== String(buttonName)),
      { action: "remove-value", removedValue }
    );
    if (props?.viewAllString) {
      handleClose();
    }
  };

  const handleViewAll = () => {
    // view table
    setShowTableList(true);
  };
  const handleClose = () => {
    // view table
    setShowTableList(false);
  };
  const keyDownHandler = e => {
    if (e.key === "Enter") e.preventDefault();
  }

  return (
    <div className={`multi-select-content ${props.size}`}>
      <div className="d-grid">
        <label className="form-lable mb-2 f-14 fw-700">{props.lable}</label>
        {props?.addText ? (
          <label id={props?.addText} className="fw-400 f-14 text-black mb-1">
            {props?.addText}
          </label>
        ) : (
          ""
        )}
      </div>
      <Select
        {...props}
        controlShouldRenderValue={!isMulti}
        isMulti={isMulti}
        clearable
        // className={props.errormessage ? "invalid-control" : ""}
        placeholder={props.placeholder}
        styles={customStyles}
        isDisabled={props.disabled}
        isInvalid={props.isInvalid}
        onKeyDown={keyDownHandler}
      />
      {(props.errormessage || value?.length === 0) && (
        <div className="customInvalid">{props.errormessage}</div>
      )}

      <div className="d-flex  flex-wrap justify-content-start align-items-center mt-1">
        {isMulti && (
          <>
            {props?.viewAllString ? (
              <>
                {value?.length > 0 &&
                  value.slice(0, 4).map((val, index) => (
                    <div
                      key={val.value}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        key={val.value}
                        className="multi-select-list ml-2 my-1"
                      >
                        <span title={val.label}>
                          {truncateString(val.label, 5)}
                        </span>
                        {props?.view && (
                          <span
                            className="cursor-pointer m-1"
                            onClick={() => props?.onClickViewList(val)}
                          >
                            <BsFillEyeFill />
                          </span>
                        )}
                        <button
                          className="close-icon"
                          name={val.value}
                          disabled={props?.disabled ? true : false}
                          onClick={handleRemoveValue}
                        >
                          <AiOutlineClose
                            style={{
                              color: "white",
                              fontSize: "1em",
                              paddingLeft: "3px",
                              cursor: "pointer",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                {value?.length > 4 && (
                  <span
                    className="view-all-btn cursor-pointer"
                    onClick={handleViewAll}
                  >
                    ,..(View all)
                  </span>
                )}
              </>
            ) : (
              <div className="row w-100">
                {value?.length > 0 &&
                  value.map((val) => (
                    <div key={val.value} className="col col-xs-12 col-sm-12 col-md-8 col-lg-5">
                      <div
                        className="custom-multi-select-list ml-2 my-1"
                        key={val.value}
                      >
                        <span title={val.label}>
                          {truncateString(val.label, 15)}
                        </span>
                        {props?.view && (
                          <span
                            className="cursor-pointer m-1"
                            onClick={() => props?.onClickViewList(val)}
                          >
                            <BsFillEyeFill />
                          </span>
                        )}
                        <button
                          className="close-icon"
                          name={val.value}
                          onClick={handleRemoveValue}
                        >
                          <AiOutlineDelete
                            style={{
                              color: "white",
                              fontSize: "1.5em",
                              paddingLeft: "3px",
                              cursor: "pointer",
                              alignSelf: "end"
                            }}
                          />
                        </button>
                      </div>
                      <div key={val.value} className=" ml-2 ">
                        <Input 
                          type={"text"}
                          name={`${val.value}-Station-Name`} 
                          onChange={(e) => {
                            onChangeStationName(e.target.value, val.value)
                          }}
                          value = {val?.stationName}
                          placeholder="Station Name"
                          isInvalid={nearestLocationError[val.value]}
                          errorMessage={nearestLocationError[val.value]}
                          required={true}
                        />
                          
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </>
        )}
      </div>
      <FilterTableList
        data={value}
        onClose={handleClose}
        show={showTableList}
        handleRemoveValue={handleRemoveValue}
        onClickViewList={props?.onClickViewList}
        view={props?.view}
      />
      {/* <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback> */}
    </div>
  );
};

export default CustomeMultiSelect;


export const cardArray =[
    {
        name:"buyer",
        value:[{
            id:1,
            name:"totalCompanies",
            label:"Company",
            path:"/buyerReport/companyList",
            pageName:"companyList"
           },
           {
            id:2,
            name:"totalCompanyPoc",
            label:"Company POC",
            path:"/buyerReport/pocList",
            pageName:"companyPocList"
           },
           {
            id:3,
            name:"totalBrands",
            label:"No.of brands",
            path:"/buyerReport/brandList",
            pageName: "brandList"
           },
           {
            id:4,
            name:"totalBrandPoc",
            label:"Brand POC",
            path:"/buyerReport/pocList",
            pageName: "brandPocList"
           },
           
           {
            id:5,
            name:"totalRegionPoc",
            label:"Region POC",
            path:"/buyerReport/pocList",
            pageName:"regionPocList"
           },
           {
            id:6,
            name:"totalDemandPosted",
            path:"/buyerReport/demandList",
            pageName:"demandPostedList",
            label:"Demands posted",
           },
           {
            id:7,
            name:"totalDemandResponses",
            label:"Demands responses",
            path:"/buyerReport/demandList",
            pageName:"demandResponseList",
           },
           {
            id:8,
            name:"totalActiveDemands",
            label:"Active demands",
            path:"/buyerReport/demandList",
            pageName:"activeDemandList",
           },
          
           {
            id:9,
            name:"totalInActiveDemands",
            label:"In-Active demands",
            path:"/buyerReport/demandList",
            pageName:"inActiveDemandList",
           },
           {
            id:10,
            name:"totalPropertiesContected",
            label:"Properties contacted",
            path:"/buyerReport/demandList",
            pageName:"propertyContactedList"
           },
           {
            id:11,
            name:"totalPropertiesAccepted",
            label:"Properties accepted",
            path:"/buyerReport/demandList",
            pageName:"propertyAcceptedList"
           },
           {
            id:12,
            name:"totalPropertiesRejected",
            label:"Properties rejected",
            path:"/buyerReport/demandList",
            pageName:"propertyRejectedList"
           },
           {
            id:13,
            name:"totalNeedFurtherInformation",
            label:"Need further information",
            path:"/buyerReport/demandList",
            pageName:"needMoreInfoList"
           },]
    },
    {
        name:"seller",
        value:[{
            id:14,
            name:"totalDevelopers",
            label:"Developer",
            path:"/sellerReport/developerList",
            pageName:"developerList"
           },
           {
            id:15,
            name:"totalDeveloperPoc",
            label:"Developer POC",
            path:"/sellerReport/pocList",
            pageName:"developerPocList"
           },
           {
            id:16,
            name:"totalCityPoc",
            label:"City POC",
            path:"/sellerReport/pocList",
            pageName:"cityPocList"
           },
           {
            id:17,
            name:"totalPropertiesPosted",
            label:"Properties posted",
            path:"/sellerReport/propertyList",
            pageName:"propertyPostedList"
           },
           {
            id:18,
            name:"totalPropertiesResponses",
            label:"Properties responses",
            path:"/sellerReport/propertyList",
            pageName:"propertyResponseList"
           },
           {
            id:19,
            name:"totalPropertiesPostedAgainstDemands",
            label:"Properties posted against demands",
            path:"/sellerReport/propertyList",
            pageName:"propertyPostedAgainstList"
           },
           
           
           {
            id:20,
            name:"totalActiveProperties",
            path:"/sellerReport/propertyList",
            pageName:"propertyActiveList",
            label:"Active properties",
           },
           {
            id:21,
            name:"totalInActiveProperties",
            path:"/sellerReport/propertyList",
            pageName:"propertyInActiveList",
            label:"In-Active properties",
           },
          
           {
            id:22,
            name:"totalPropertiesContected",
            label:"Properties contacted",
            path:"/sellerReport/propertyList",
            pageName:"propertyContactedList"
           },
          
           {
            id:23,
            name:"totalPropertiesAccepted",
            label:"Properties accepted",
            path:"/sellerReport/propertyList",
            pageName:"propertyAcceptedList"
           },
           {
            id:24,
            name:"totalPropertiesRejected",
            label:"Properties rejected",
            path:"/sellerReport/propertyList",
            pageName:"propertyRejectedList"
           },
           {
            id:25,
            name:"totalNeedFurtherInformation",
            label:"Need further information",
            path:"/sellerReport/propertyList",
            pageName:"needMoreInfoList"
           },]
    },
    {
        name:"agent",
        value:[{
            id:25,
            name:"totalAgents",
            label:"Agent",
            path:"/agentReport/agentList",
            pageName:"agentList"
           },
           
           {
            id:26,
            name:"totalPropertiesPosted",
            label:"Properties posted",
            path:"/agentReport/propertyList",
            pageName:"propertyPostedList"
           },
           {
            id:27,
            name:"totalPropertiesResponses",
            label:"Properties responses",
            path:"/agentReport/propertyList",
            pageName:"propertyResponseList"
           },
           {
            id:28,
            name:"totalPropertiesPostedAgainstDemands",
            label:"Properties posted against demands",
            path:"/agentReport/propertyList",
            pageName:"propertyPostedAgainstList"
           },
           
           
           {
            id:29,
            name:"totalActiveProperties",
            path:"/agentReport/propertyList",
            pageName:"propertyActiveList",
            label:"Active properties",
           },
           {
            id:30,
            name:"totalInActiveProperties",
            path:"/agentReport/propertyList",
            pageName:"propertyInActiveList",
            label:"In-Active properties",
           },
          
           {
            id:31,
            name:"totalPropertiesContected",
            label:"Properties contacted",
            path:"/agentReport/propertyList",
            pageName:"propertyContactedList"
           },
          
           {
            id:32,
            name:"totalPropertiesAccepted",
            label:"Properties accepted",
            path:"/agentReport/propertyList",
            pageName:"propertyAcceptedList"
           },
           {
            id:32,
            name:"totalPropertiesRejected",
            label:"Properties rejected",
            path:"/agentReport/propertyList",
            pageName:"propertyRejectedList"
           },
           {
            id:33,
            name:"totalNeedFurtherInformation",
            label:"Need further information",
            path:"/agentReport/propertyList",
            pageName:"needMoreInfoList"
           },]
    }
   



]
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import DragAndDrop from "../../../components/dragAndDrop";
import { postGetData } from "../../../api";
import { getUploadPropertyData } from "../options";
import { useLocation } from "react-router-dom";
const UploadProperty = ({
  gotoStep,
  propertyDetail,
  isExitData,
  step,
  propertyType,
  propertyTypeId,
  uploadPropertyObject,
  setUploadPropertyObject,
  checkImage,
  uploadPropertyStepButtonRef,
  setChanged,
  ImagesDivRef

}) => {
  const [uploadPropertyData, setUploadPropertyData] = useState([]);
  // const [uploadPropertyObject, setUploadPropertyObject] = useState({
  //   frontSide: [],
  //   interior: [],
  //   exterior: [],
  // });
  const [propertyDetailImages, setPropertyDetailImages] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const checkImage = () => {
  //   let errorsList = {};
  //   Object.entries(uploadPropertyObject).forEach((item) => {
  //     if (item[1].length === 0) errorsList[item[0]] = "This Field is required";
  //   });
  //   return errorsList;
  // };
  // const uploadProperyUpdate = async () => {
  //   setLoading(true);
  //   let obj = {
  //     imagesAndVideos: uploadPropertyObject,
  //     step: propertyType === "Office space" ? 3 : 2,
  //     propertyId: searchParams.get("propertyId"),
  //   };
  //   if (propertyType === "Office space") {
  //     obj.propertyTypeId = propertyTypeId;
  //   }
  //   const res = await postGetData("/property", {}, obj);
  //   const { statusCode } = res;
  //   if (statusCode === 200) {
  //     setLoading(false);
  //     if (propertyType === "Office space") {
  //       gotoStep(5, true);
  //     } else {
  //       gotoStep(3, true);
  //     }
  //   }
  //   setLoading(false);
  // };
  const handleSubmit = () => {
    if (Object.keys(checkImage()).length > 0) {
      setError(checkImage());
    }
  };

  const handleAllImages = (urls) => {
    setChanged(true)
    const { name, data } = urls;
    setUploadPropertyObject((prev) => ({
      ...prev,
      [name]: data,
    }));
    setError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };
  const gotoBack = () => {
    gotoStep(step - 1, true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setUploadPropertyData(getUploadPropertyData);
    if (isExitData) {
      setPropertyDetailImages(propertyDetail);
    }
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  useEffect(() => {
    if (step && step !== 5) {
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }

  }, [uploadPropertyObject]);
  return (
    <div className="property-details-container" ref={ImagesDivRef}>
      <Row>
        <Col md="1" />
        <Col md="10">
          <h4>Property Images/Videos</h4>
          {uploadPropertyData &&
            uploadPropertyData.map((item) => (
              <div key={item.name} className="my-3">
                <DragAndDrop
                  {...item}
                  allImages={propertyDetailImages}
                  handles={(urls) => handleAllImages(urls)}
                  error={error[item.name]}
                  propertyId={localStorage.getItem("addPropertyId") ? localStorage.getItem("addPropertyId") : searchParams.get("propertyId")}
                  propertyType = {propertyType}
                />
                <br />
              </div>
            ))}
          {/* {step !== 5 ? ( */}
          <Col md="12" hidden={true}>
            <div className="text-end">
              {/* <Button type="button" className="pre-btn me-3" onClick={gotoBack}>
                Previous
              </Button> */}
              <Button
                type="submit"
                className="next-btn p-0"
                loading={loading}
                onClick={handleSubmit}
                _ref={uploadPropertyStepButtonRef}
              >
                Next
              </Button>
            </div>
          </Col>
          {/* ) : null} */}
        </Col>

      </Row>
    </div>
  );
};

export default UploadProperty;

import { useEffect, useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import Button from "../../components/Form/Button";
import Search from "../../components/Search/search";
import MainTable from "../../components/Table/index";
import { AddLocation } from "./addLocation";
import { AddCity, ViewLocations } from "./locationPopup";

import Loader from "../../components/Loader/index";

import { AccessDenied, getData, postGetData } from "../../api";
import { checkForAccess } from "../../utilities/commonFunction";

export default function LocationManagement() {
  const [locationList, setLocationList] = useState([]);
  const [offset, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [viewLocations, setViewLocations] = useState(false);
  const [addLocation, setAddLocation] = useState(false);
  const [addCity, setAddCity] = useState(false);
  const [formAction, setFormAction] = useState("addLocation");
  const [locationAndzone, setLocationAndZone] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [cityList, setCityList] = useState([]);
  const [viewValue, setViewValue] = useState({});
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  const access = {
    addCity: checkForAccess(loginPermission, menuId?.addCity),
    addLocality: checkForAccess(loginPermission, menuId?.addLocality),
    viewLocality: checkForAccess(loginPermission, menuId?.viewLocality),
    enableDisableLocality: checkForAccess(
      loginPermission,
      menuId?.enableDisableLocality
    ),
  };

  const getLocationListData = async () => {
    setIsLoading(true);
    let url = `/cityZones/getLocalities?page=${offset}&limit=${perPage}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (filter) {
      url += `&cityName=${filter}`;
    }
    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setIsLoading(false);
      setLocationList(data.localities);
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
    } else {
      setPageCount(0);
      setIsLoading(false);
      setLocationList([]);
    }
  };

  const getCityList = async () => {
    let url = "/country/getStates";
    const apiDatas = await postGetData(url, {}, {});
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      setCityList(data);
    } else {
      setCityList([]);
    }
  };

  const handelAddLocation = (actionName, value) => {
    if (actionName === "addLocation") {
      let data = {
        cityId: value?.cityId,
        zoneId: value?._id,
        cityName: value?.name,
        stateId: value?.stateId,
      };
      setLocationAndZone(data);
    }
    setFormAction(actionName);
    setAddLocation(true);
  };

  const handelCloseLocation = (status) => {
    setAddLocation(false);
    if (status === "updated") {
      getLocationListData();
    }
  };
  const handelOpenAndCloseCity = (status = "") => {
    setAddCity(!addCity);
    if (status === "updated") {
      getLocationListData();
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  const handelfilter = (value) => {
    setFilter(value);
    setOffset(1);
  };
  const handleClickView = (value) => {
    setViewLocations(true);
    setViewValue({
      cityName: value.name,
      stateId: value.stateId,
      cityId: value.cityId,
      zoneId: value._id,
    });
  };
  const handleClickCloseView = () => {
    setViewLocations(false);
  };

  function userButtonFormatter(cell, row, rowIndex) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center">
          <Button
            className="table-btn p-0"
            onClick={() => handleClickView(row)}
            disabled={access.viewLocality ? false : true}
          >
            <BsFillEyeFill size={15} />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="table-btn p-0"
            onClick={() => handelAddLocation("addLocation", row)}
            disabled={access.addLocality ? false : true}
          >
            <MdAddCircle size={15} />
          </Button>
        </div>
      </>
    );
  }
  function localitiesCount(cell, row) {
    return (
      <div className={"table-link-btn"}>{`${row?.localities}  localities`}</div>
    );
  }

  const columns = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "regionName",
      text: "Region",
    },
    {
      dataField: "stateName",
      text: "State",
    },
    {
      dataField: "name",
      text: "City",
    },
    {
      dataField: "Locality",
      text: "Locality",
      formatter: localitiesCount,
    },

    {
      dataField: "isActive",
      text: "Action",
      formatter: userButtonFormatter,
    },
  ];

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };

  const tableProps = {
    data: locationList,
    columns,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "",
  };

  useEffect(() => {
    getLocationListData();
  }, [perPage, offset, filter, search]);
  useEffect(() => {
    getCityList();
  }, []);

  return (
    <>
      {loginPermission?.includes(menuId?.locationManagement) ? (
        <div className="dashboard-container location-container">
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row ">
            <div className="d-flex justify-content-between align-items-center text-heading">
              <label title="Dashboard">Location Management</label>
            </div>

            <div className="d-flex mb-4 flexEnd_div">
              <div className="manageWidthSearch">
                <Search onChange={handleSearch} />
              </div>
            </div>
          </div>
          <div className="filter-container">
            <Button
              className="btn-color-primary"
              onClick={handelOpenAndCloseCity}
              disabled={access?.addCity ? false : true}
            >
              Add city
            </Button>

            <div className="d-flex justify-content-end widWrapper">
              <select
                className="form-select filterBGColor w-100"
                key="location"
                name="location"
                id="location"
                onChange={(e) => handelfilter(e.target.value)}
              >
                <option value="">Select</option>
                {cityList.map((item, index) => {
                  return (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="mt-4">
            <MainTable {...tableProps} />
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}

      {viewLocations && (
        <ViewLocations
          show={viewLocations}
          onClose={handleClickCloseView}
          viewDetails={viewValue}
          access={access}
        />
      )}
      {addLocation && (
        <AddLocation
          show={addLocation}
          locationId=""
          onClose={handelCloseLocation}
          action={formAction}
          value={locationAndzone}
        />
      )}
      {addCity && <AddCity show={addCity} onClose={handelOpenAndCloseCity} />}
      {isLoading && <Loader className={"fixed"} />}
    </>
  );
}

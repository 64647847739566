
import "../../pages/Login/style.scss";
import Logo from '../../assets/images/logo.png'

function CardComponent(props) {
  return (
    <div className="w-100 user-login-page">
      <div className="pb-5">
        <img style={{width:"275px"}} src={Logo} alt="brandIcon" />
      </div>

      <div className="login-width">
    
        {props.loginContent}
      </div>
    </div>
  );
}

export default CardComponent;

import { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
//scss
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  checkUserPermission,
  getCurrentUser,
  getData,
  getKeyByValue,
  isLogedIn,
} from "../api";
import Toast from "../components/Toast";
import { updateActiveUrl, updateMenuId, updatePermissions } from "../pages/Login/reducer";
import PrivateRoute from "../privateRoute";
import "./style.scss";
import Loader from "../components/Loader";
//pages

export default function Layout(props) {
  const [showNav, setShowNav] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  console.log({pathname:location.pathname})
  const menuId = useSelector((state) => state.login.menuId);
  const {loginPermission, activeUrl} = useSelector((state) => state.login);

  const getUserPermission = async () => {
    setLoading(true);
    let url = `/userPermission/userBasedPermission`;
    const response = await getData(url, {}, {});
    const { status, data } = response;
    if (status) {
      setLoading(false);
      dispatch(updatePermissions(data?.permission));
    } else {
      setLoading(false);
    }
  };
  const idListApi = async (val) => {
    const url = "/menu/getMenuFormatted";
    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      dispatch(updateMenuId(data));

      if (location.pathname === "/") {
        const { name } = val?.role[0];
        const { permissions } = val;
        
          if (loginPermission?.includes(menuId.dashboard)) {
            navigate("/dashboard");
          } else {
            const route = getKeyByValue(data, permissions[0]);
  
            if (route === "companyBuyer") {
              navigate("/company");
            } else if (
              ["customerSeller", "customerAgent", "customerBuyer"].includes(route)
            ) {
              navigate("/customer");
            } else {
              navigate(`/${route}`);
            }
        }
      }
    }
  };

  useEffect(() => {
    if (isLogedIn()) {
      const data = checkUserPermission().then((res) => {
        dispatch(updatePermissions(res?.permissions));
      });
    }
    // console.log({ data });
  }, [location]);

  useEffect(() => {
    if (isLogedIn()) {
      getUserPermission();
      idListApi(getCurrentUser());
    } else {
      dispatch(updateActiveUrl(location?.pathname.length > 1 ? location?.pathname : ""))
    }
  }, []);
  return (
    <PrivateRoute>
      <Header handleNav={() => setShowNav(!showNav)} handleShow={showNav} />
      {loading ? (
        <>
          <Loader className={"fixed"} />
        </>
      ) : (
        <main className="main-layout">
          {
            <Sidebar
              menu={props.menu}
              getMenuList={props.getMenuList}
              handleShow={showNav}
            />
          }
          <div className="main-body fixed">
            <Toast />
            <Outlet></Outlet>
          </div>
        </main>
      )}
    </PrivateRoute>
  );
}

import "./index.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomDate(props) {
  const onChange = (dates) => {
    const [start, end] = dates;
    props?.from(start);
    props?.to(end);
  };
  return (
    
      <DatePicker
        selected={props?.startVal}
        onChange={onChange}
        startDate={props?.startVal}
        endDate={props?.endVal}
        selectsRange
      />
    
  );
}

export default CustomDate;

import React, { useEffect, useState } from "react";

import Swal from "sweetalert2/dist/sweetalert2.js";

import { useLocation, useNavigate } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss";
import {
  AccessDenied,
  getData,
  postData,
  postGetData,
  postUploadData,
  putData,
  updateSubscription,
} from "../../../api";
import Profilepic from "../../../assets/images/profilePic.jpg";
import Button from "../../../components/Form/Button";

import PhoneNumberInput from "../../../components/Form/Input/phoneNumberInput";
import MultiSelect from "../../../components/select/dropDown";
import "./index.scss";
// icons
import Loader from "../../../components/Loader";

import { Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import NavBar from "../../../components/Navbar";
import SingleImageUpload from "../../../components/imageUpload/singleImageUpload";
import { labelToName, nameToLabel } from "../../../utilities/commonFunction";
import { getRm } from "../../../api/api";
import { RenderInput, RenderSelect } from "./customComponents/inputGroups";
import { FaPen } from "react-icons/fa";
import { errorCheck } from "../../../components/subscriptionRenew/agentSubscriptionRenewErrorCheck";
import SubscriptionRenew from "../../../components/subscriptionRenew/agentSubscriptinRenew";

const AgentFormPage = () => {
  const [errorMessage, setErrorMessage] = useState({});
  const [edit, setEdit] = useState({});
  const [cities, setCities] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const location = useLocation();
  let navigate = useNavigate();

  const [initcities, setInitcities] = useState([]);
  const [initState, setInitState] = useState([]);
  const [initPropertyTypes, setInitPropertyTypes] = useState([]);

  const [imageName, setImageName] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [rmList, setRmList] = useState([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState({
    propertyOrDemandSelect: "",
    propertyOrDemand: "",
    validityDays: "",
    userDashboard: "Yes",
    demandMails: "",
    homePageSlotAndBanner: "",
    assistedUploading: "",
    featuredProperty: "",
    submissionAgainstDemand: "",
    submissionAgainstDemandSelect: "",
    relationshipManager: "",
    rm: "",
    planName: "",
    discount: "",
    subscriptionAmount: "",
  });

  // Subscription Edit
  const [subscrptionEdit, setSubscrptionEdit] = useState(false);
  const [subPlanChosen, setSubPlanChosen] = useState(false);
  const [initialSubscriptionData, setInitialSubscriptionData] = useState({});
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [isSubscriptionRenewed, setIsSubscriptionRenewed] = useState(false);
  const [subscriptionPlanOptions, setSubscriptionPlanOptions] = useState([]);
  const [defaultsSubscriptionPlans, setDefaultsSubscriptionPlans] = useState(
    []
  );
  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState("");
  const [agentId, setAgentId] = useState(null);

  const [submissionInputShow, setSubmissionInputShow] = useState(false);
  const [propertyDemandInput, setPropertyDemandInput] = useState(false);

  //error object
  const [errorObject, setErrorObject] = useState({});

  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);

  const role = location.state
    ? location.state.roleObj
      ? location.state.roleObj
      : ""
    : "";
  let newAgent = location.state
    ? location.state.new
      ? location.state.new
      : false
    : false;

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    editAgentCustomers: loginPermission?.includes(menuId.editAgentCustomers),
    addAgentCustomers: loginPermission?.includes(menuId?.addAgentCustomers),
  };
  let customerView = access?.addAgentCustomers || access?.editAgentCustomers;
  let companyTypeReceived = "companyNoDeveloper";
  const updateCityList = (array) => {
    let cityArray = [];
    array?.map((has) => {
      edit?.cities.map((el) => {
        if (el?.value === has?.value) {
          cityArray.push(el);
        }
        return el;
      });
      return has;
    });
    setEdit((prev) => ({ ...prev, cities: cityArray }));
  };
  const getCities = (id, onChangeState) => {
    if (id && id.length > 0) {
      setInitLoading(true);
      let url = "/country/getCitiesByState";
      let listedId = id.map((item) => item.value);
      let payload = { id: listedId };
      postGetData(url, {}, payload).then((response) => {
        setInitLoading(false);
        if (response?.status) {
          // setCityInitialList(response?.data);
          let cityArray = nameToLabel(response?.data);
          setCities(cityArray);
          if (onChangeState) {
            updateCityList(cityArray);
          }
        } else {
          setCities([]);
        }
      });
    } else {
      setInitLoading(false);
      setCities([]);
    }
  };

  const getStates = () => {
    let url = "/country/getStates";
    postGetData(url, {}, {}).then((response) => {
      if (response?.status) {
        let stateArray = nameToLabel(response?.data);
        setStateList(stateArray);
      }
    });
  };

  const getImageData = (customerData) => {
    if (customerData) {
      let imageUrl = customerData.image ? customerData.image : {};

      setImageName([
        { name: imageUrl ? (imageUrl.fileName ? imageUrl.fileName : "") : "" },
      ]);
    }
  };

  const getCustomCityList = () => {
    let url = "/country/getAllCities";
    let newArr = [];
    getData(url, {}, {}).then((response) => {
      if (response.data?.length > 0) {
        response.data?.forEach((values) => {
          let citiesList = {
            label: values?.name,
            value: values?._id,
            ...values,
          };
          // if (!customCityList?.includes(citiesList))
          //   customCityList.push(citiesList);
          newArr.push(citiesList);
        });
      }
      // setCities(response.data);
    });
  };
  const handleInputFieldDisable = (
    propertyOrDemand,
    submissionAgainstDemand
  ) => {
    let retobj = {};
    if (propertyOrDemand === "Unlimited") {
      retobj = { ...retobj, propertyOrDemandSelect: "Unlimited" };
      setPropertyDemandInput(false);
    } else {
      retobj = { ...retobj, propertyOrDemandSelect: "Enter number" };
      setPropertyDemandInput(true);
    }

    if (submissionAgainstDemand === "Unlimited") {
      retobj = { ...retobj, submissionAgainstDemandSelect: "Unlimited" };
      setSubmissionInputShow(false);
    } else {
      retobj = { ...retobj, submissionAgainstDemandSelect: "Enter number" };
      setSubmissionInputShow(true);
    }

    return retobj;
  };

  const onChangeSubscription = (value, key) => {
    delete errorObject[key];
    if (key === "planName") {
      value === "" ? setSubPlanChosen(false) : setSubPlanChosen(true);
      const defaultSubscriptionPlan = defaultsSubscriptionPlans.filter(
        (plan) => plan.planName === value
      );
      const { propertyOrDemandSelect, submissionAgainstDemandSelect } =
        handleInputFieldDisable(
          defaultSubscriptionPlan[0]?.propertyOrDemand,
          defaultSubscriptionPlan[0]?.submissionAgainstDemand
        );

      setSubscriptionPlan((prev) => ({
        ...defaultSubscriptionPlan[0],
        propertyOrDemandSelect,
        submissionAgainstDemandSelect,
        rm: prev.rm,
      }));
    } else if (key === "relationshipManager") {
      delete errorObject["rm"];
      if (value === "No") {
        // delete subscription["rm"]
        setSubscriptionPlan({
          ...subscriptionPlan,
          rm: { id: "", name: "" },
          [key]: value,
        });
      } else {
        setSubscriptionPlan({ ...subscriptionPlan, [key]: value });
      }
    } else if (key === "rm") {
      let rmObj = rmList?.filter((el) => value === el?._id);
      setSubscriptionPlan({ ...subscriptionPlan, [key]: rmObj[0] });
    } else {
      setSubscriptionPlan({ ...subscriptionPlan, [key]: value });
    }
  };

  //Subscrition Edit
  const handleSubscriptionEdit = () => {
    let errors = errorCheck(subscriptionPlan);
    if (
      !errors["validityDays"] &&
      subscriptionPlan?.validityDays < initialSubscriptionData?.validityDays
    ) {
      errors["validityDays"] =
        "Subscription validity must be greater that current validity";
    }
    if (Object.keys(errors).length === 0) {
      if (subscriptionPlan.submissionAgainstDemandSelect === "Unlimited") {
        subscriptionPlan.submissionAgainstDemand = "Unlimited";
      }
      if (subscriptionPlan.propertyOrDemandSelect === "Unlimited") {
        subscriptionPlan.propertyOrDemand = "Unlimited";
      }
      delete subscriptionPlan.submissionAgainstDemandSelect;
      delete subscriptionPlan.propertyOrDemandSelect;
      if (subscriptionPlan?.relationshipManager === "No") {
        delete subscriptionPlan?.rm;
      }

      setLoading(true);
      updateSubscription(agentId, { ...subscriptionPlan }, "Agent").then(
        (res) => {
          navigate("/customer/agentPageCreation", {
            state: {
              customer: {
                ...location?.state?.customer,
                cities: initcities,
                state: initState,
                propertyTypes: initPropertyTypes,
                subscriptionPlanId: {
                  ...subscriptionPlan,
                  subscriptionDate:
                    location?.state?.customer?.subscriptionPlanId
                      ?.subscriptionDate,
                },
              },
              id: location?.state.id,
              edit: true,
              afterSubEdit: true,
            },
          });
          setSubscrptionEdit(false);
          setLoading(false);
        }
      );
    } else {
      setErrorObject(errors);
    }
  };

  //Subscription array
  const subscriptionArray = [
    {
      name: "Subscription plan *",
      key: "planName",
      type: "select",
      option: subscriptionPlanOptions,
    },
    {
      name: "Property uploads Limit *",
      key: "propertyOrDemandSelect",
      subKey: "propertyOrDemand",
      type: "select",
      option: ["Unlimited", "Enter number"],
    },
    {
      name: "No. of Property uploads *",
      key: "propertyOrDemand",
      subKey: "propertyOrDemandSelect",
      type: "input",
      inputType: "number",
      placeholder: "Property upload",
    },
    {
      name: "Validity days *",
      key: "validityDays",
      type: "input",
      inputType: "number",
    },
    {
      name: "Demand Alerts *",
      key: "demandMails",
      type: "select",
      option: ["Instant", "Daily", "Monthly", "Weekly"],
    },

    {
      name: "Submission against demand Limit *",
      key: "submissionAgainstDemandSelect",
      subKey: "submissionAgainstDemand",
      type: "select",
      option: ["Unlimited", "Enter number"],
    },
    {
      name: "No. of Submission against demand *",
      key: "submissionAgainstDemand",
      subKey: "submissionAgainstDemandSelect",
      type: "input",
      inputType: "number",
      placeholder: "Submission against demand",
    },
    {
      name: "User dashboard *",
      key: "userDashboard",
      type: "input",
      inputType: "text",
      placeholder: "eg:Multi-user dashboard",
    },
    {
      name: "Featured property *",
      key: "featuredProperty",
      type: "select",
      option: ["Yes", "No"],
    },
    {
      name: "Assisted uploading *",
      key: "assistedUploading",
      type: "select",
      option: ["Yes", "No"],
    },
    {
      name: "Relationship manager *",
      key: "relationshipManager",
      type: "select",
      option: ["Yes", "No"],
    },
    {
      name: "Select RM *",
      key: "rm",
      type: "select",
      option: rmList,
    },
  ];

  const logoImageApi = (files, key) => {
    if (files.length === 0) {
      setEdit({ ...edit, [key]: {} });
    }
    let data = new FormData();
    data.append("uploadfiles", files[0]);
    if (files.length > 0) {
      setInitLoading(true);
      let url = "/brand/file-upload/?type=image&module=brand";
      postUploadData(url, {}, data).then((response) => {
        if (response.status) {
          if (errorMessage?.image) {
            delete errorMessage?.image;
          }
          setInitLoading(false);

          setEdit({ ...edit, [key]: response.data[0] });
        } else {
          setEdit({ ...edit, [key]: {} });
          setInitLoading(false);
        }
      });
    }
  };

  const getPropertyData = async () => {
    let url = "/propertyType/";
    const userData = await getData(url, {});
    const { data, statusCode } = userData;

    if (statusCode === 200) {
      let datas = nameToLabel(data);
      setPropertyData(datas);
    }
  };

  const updateSelect = (e, key) => {
    setErrorMessage({});
    if (key === "cities" || key === "propertyTypes") {
      setEdit({ ...edit, [key]: e });
    } else if (key === "state") {
      if (e?.length > 0) {
        getCities(e, true);
        setEdit({ ...edit, [key]: e });
      } else {
        setCities([]);
        setEdit({ ...edit, [key]: e, cities: [] });
      }
    } else {
      const { value } = e.target;
      setEdit({ ...edit, [key]: value });
    }
  };

  const updateChanges = (event) => {
    event.preventDefault();
    setErrorMessage({});
    const { name, value } = event.target;
    let newObj = { ...edit };
    if (name === "contactPerson" || name === "lastName") {
      newObj[name] = value.replace(/[^a-z0-9]/gi, "");
    } else if (name === "phoneNumber") {
      if (value.length === 11) {
        return false;
      } else {
        newObj[name] = value;
      }
    } else {
      newObj[name] = value;
    }
    setEdit(newObj);
    // updateProfile();
  };

  const upload = async ({ target: { files } }) => {
    setInitLoading(true);
    setErrorMessage({});
    let datas = new FormData();
    datas.append("uploadfiles", files[0]);
    let url = "/brand/file-upload/?type=image&module=brand";
    const newDatas = await postUploadData(url, {}, datas);
    const { data, statusCode, status } = newDatas;
    if (status && statusCode === 200) {
      setInitLoading(false);

      let newObj = { ...edit };
      newObj["logo"] = data[0];
      setEdit(newObj);
    } else {
      setInitLoading(false);
    }
  };
  const avoid = [
    "_id",
    "type",
    "userId",
    "createdBy",
    "createdAt",
    "notificationType",
    "agentPropertyCount",
    "isActive",
  ];
  const operatingCities = [
    "label",
    "latitude",
    "longitude",
    "value",
    "propertyCount",
  ];
  const checkValidate = (value) => {
    let errorsList = {};
    let msg = "This Field is required";
    if (
      value?.propertyTypes?.length === 0 ||
      !value?.hasOwnProperty("propertyTypes") ||
      value?.propertyTypes === undefined
    ) {
      errorsList["propertyType"] = msg;
    }

    if (value?.about?.length === 0 || !value?.hasOwnProperty("about")) {
      errorsList["about"] = msg;
    }
    //   if (value?.image === ""  || !value?.hasOwnProperty("propertyType")|| Object.keys(value.image).length === 0) {
    //     errorsList["image"] = msg;
    // };
    if (value?.email?.length === 0 || !value?.hasOwnProperty("email")) {
      errorsList["email"] = msg;
    }

    if (
      value?.contactPerson?.length === 0 ||
      !value?.hasOwnProperty("contactPerson")
    ) {
      errorsList["contactPerson"] = msg;
    }
    if (
      !/^[A-Za-z\s]*$/.test(value?.contactPerson) &&
      value?.contactPerson?.length > 0
    ) {
      errorsList["contactPerson"] = "Enter only character";
    }
    if (
      value?.phoneNumber?.length === 0 ||
      !value?.hasOwnProperty("phoneNumber")
    ) {
      errorsList["phoneNumber"] = msg;
    }
    if (value?.phoneNumber?.length < 10) {
      errorsList["phoneNumber"] = "Enter a valid number";
    }

    if (value?.cities?.length === 0 || !value?.hasOwnProperty("cities")) {
      errorsList["cities"] = msg;
    }
    if (value?.state?.length === 0 || !value?.hasOwnProperty("state")) {
      errorsList["state"] = msg;
    }
    return errorsList;
  };

  const updateSubmit = async (e) => {
    e.preventDefault();
    setInitLoading(true);
    if (subscriptionPlan?.relationshipManager === "No") {
      delete subscriptionPlan?.rm;
    }
    delete subscriptionPlan.submissionAgainstDemandSelect;
    delete subscriptionPlan.propertyOrDemandSelect;
    const details = {
      ...edit,
      subscriptionPlanId: { ...subscriptionPlan },
    };

    if (!newAgent) {
      delete details.slug;
      delete details.subscriptionPlanId;
    }
    // details?.phoneNumber?.toString();
    details?.operatingCities?.forEach((item) => {
      operatingCities?.forEach((val) => {
        if (val in item) {
          delete item[val];
        }
      });
    });
    details.cities = labelToName(edit?.cities);
    details.state = labelToName(edit?.state);
    details.propertyTypes = labelToName(edit?.propertyTypes);

    avoid?.forEach((items) => delete details[items]);
    // change phone number to string
    if (typeof details?.phoneNumber === "number") {
      delete details?.phoneNumber;
      details["phoneNumber"] = edit?.phoneNumber?.toString();
    }

    // }
    let errors = errorCheck(subscriptionPlan);
    let error = checkValidate(details);
    if (Object.keys(error).length > 0 || Object.keys(errors).length > 0) {
      setInitLoading(false);
      setErrorMessage(error);
      setErrorObject(errors);
    } else {
      let url;

      if (newAgent) {
        url = "/brand";
        details["roles"] = role;
        details["type"] = "Agent";
      } else {
        details["referralCode"] = edit?.referralCode ? edit?.referralCode : "";
        url = `/brand/${location.state?.id}?type=update`;
      }
      const newData = newAgent
        ? await postData(url, {}, details)
        : await putData(url, {}, details);
      const { status, statusCode } = newData;
      if (status && statusCode === 200) {
        setInitLoading(false);
        navigate("/customer", {
          state: { customerActiveKey: "customerAgent" },
        });
      } else {
        Swal.fire({
          // title: header,
          text: newData.message,
          icon: "warning",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
        setInitLoading(false);
      }
    }
  };
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      updateSubmit(e);
    }
  };
  const getRmList = () => {
    getData(getRm(), {}, {}).then((response) => {
      setRmList(response?.data?.subAdminList);
    });
  };
  const getSubscription = () => {
    let url = "/subscription";
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        const agentSub = response?.data.filter((sub) => sub._id === "Agent");
        const agentSubPlan = agentSub[0]?.plans;

        setDefaultsSubscriptionPlans(agentSubPlan);

        const subPlanOptions = agentSubPlan.map((plan) => plan.planName);
        setSubscriptionPlanOptions(subPlanOptions);
      }
    });
  };
  useEffect(() => {
    // getPropertyTypeList();
    // getStates();
    // getRole();
    getSubscription();
    getRmList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    newAgent ? setSubscrptionEdit(true) : setSubPlanChosen(true);
  }, [newAgent]);

  useEffect(() => {
    getCustomCityList();
    getPropertyData();
  }, []);

  useEffect(() => {
    getStates();
  }, [location]);

  useEffect(() => {
    if (location?.state?.customer) {
      let data = location?.state?.customer;
      if (!location?.state?.afterSubEdit) {
        setInitcities([...location?.state?.customer?.cities]);
        setInitState([...location?.state?.customer?.state]);
        setInitPropertyTypes([...location?.state?.customer?.propertyTypes]);
      }
      data.cities = nameToLabel(location?.state?.customer?.cities);
      data.state = nameToLabel(location?.state?.customer?.state);
      data.propertyTypes = nameToLabel(
        location?.state?.customer?.propertyTypes
      );
      data?.userId && setAgentId(data?.userId?._id);
      delete data["propertyType"];
      setEdit(data);
      const { propertyOrDemandSelect, submissionAgainstDemandSelect } =
        handleInputFieldDisable(
          data?.subscriptionPlanId?.propertyOrDemand,
          data?.subscriptionPlanId?.submissionAgainstDemand
        );
      if (data?.subscriptionPlanId) {
        setSubscriptionPlan({
          ...data?.subscriptionPlanId,
          propertyOrDemandSelect,
          submissionAgainstDemandSelect,
        });
        setInitialSubscriptionData({
          ...data?.subscriptionPlanId,
          propertyOrDemandSelect,
          submissionAgainstDemandSelect,
        });
      }

      getImageData(location?.state?.customer);

      getCities(data.state, false);

      setSubscription(
        location?.state?.customer?.subscriptionPlanId
          ? location?.state?.customer?.subscriptionPlanId
          : {}
      );
      if (location?.state?.customer?.operatingCities?.length > 0) {
        let newArr = [];
        if (location?.state?.customer?.operatingCities.length > 0) {
          location?.state?.customer?.operatingCities?.forEach((values) => {
            let citiesList = {
              label: values?.name,
              value: values?._id,
              ...values,
            };

            newArr.push(citiesList);
          });
        }
      }
      //Checking for Subscription Expiry
      const validityDays = parseInt(data?.subscriptionPlanId?.validityDays);
      const subscriptionDate = new Date(
        data?.subscriptionPlanId?.subscriptionDate
      );

      const expireDate = new Date(
        subscriptionDate.getTime() + validityDays * 24 * 60 * 60 * 1000
      );

      const currentDate = new Date();

      const hasExpired = expireDate <= currentDate;
      if (hasExpired) {
        setSubscriptionExpired(true);
      } else {
        setSubscriptionExpired(false);
      }

      const day = expireDate.getDate();
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthIndex = expireDate.getMonth();
      const year = expireDate.getFullYear();

      const formattedExpiryDate = `${day}${
        day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th"
      } ${monthNames[monthIndex]} ${year}`;
      if (hasExpired) {
        setSubscriptionExpiryDate(`Expired on ${formattedExpiryDate}`);
      } else {
        setSubscriptionExpiryDate(`Plan expires on ${formattedExpiryDate}`);
      }
    }
  }, [location]);
  
  //Checking for Subscription Expiry
  useEffect(() => {
    if (isSubscriptionRenewed) {
      const validityDays = parseInt(subscriptionPlan?.validityDays);
      const subscriptionDate = new Date();

      const expireDate = new Date(
        subscriptionDate.getTime() + validityDays * 24 * 60 * 60 * 1000
      );

      const currentDate = new Date();

      const hasExpired = expireDate <= currentDate;
      if (hasExpired) {
        setSubscriptionExpired(true);
      } else {
        setSubscriptionExpired(false);
      }

      const day = expireDate.getDate();
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthIndex = expireDate.getMonth();
      const year = expireDate.getFullYear();

      const formattedExpiryDate = `${day}${
        day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th"
      } ${monthNames[monthIndex]} ${year}`;
      if (hasExpired) {
        setSubscriptionExpiryDate(`Expired on ${formattedExpiryDate}`);
      } else {
        setSubscriptionExpiryDate(`Plan expires on ${formattedExpiryDate}`);
      }
      setIsSubscriptionRenewed(false)
    } 
  }, [isSubscriptionRenewed]);
  return (
    <div>
      {" "}
      {customerView ? (
        <>
          <NavBar />
          <div className="brandPageForm-container">
            <Form
              className="detailsForm"
              style={{ backgroundColor: "white", padding: "10px" }}
              onSubmit={updateSubmit}
              onKeyDown={(e) => onKeyDown(e)}
            >
              {initLoading && <Loader className={"fixed"} />}
              <Row>
                <div className="profileWrapper">
                  <div className="imageWrapper">
                    <div className="profileDiv">
                      <img
                        alt="profilePic"
                        className="imagePic"
                        src={
                          edit?.logo?.viewUrl ? edit?.logo?.viewUrl : Profilepic
                        }
                      />
                      <span>
                        <label>
                          Change profile picture
                          <input
                            name={"profilePicture"}
                            id={"profilePicture"}
                            type="file"
                            className="d-none"
                            // multiple={props.multiple?true:false}
                            onChange={upload}
                            multiple={true}
                            accept="image/png, image/jpeg"
                          />
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group className="form-group" controlId={"name"}>
                    <Form.Label className="fw-700 f-14 text-black form-label">
                      First Name*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={"contactPerson"}
                      value={edit.contactPerson}
                      onChange={updateChanges}
                      placeholder="Enter First name"
                    ></Form.Control>
                    <span className="error-message_span mb-3">
                      {errorMessage?.contactPerson}
                    </span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group">
                    <Form.Label className="fw-700 f-14 text-black form-label">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Last name"
                      value={edit.lastName}
                      name="lastName"
                      onChange={updateChanges}
                    />
                    <span className="error-message_span mb-3">
                      {errorMessage?.lastName}
                    </span>
                  </Form.Group>
                </Col>
                {/* </div> */}
                {/* <div className="row"> */}
              </Row>
              <Row>
                <Col>
                  <Form.Group className="form-group" controlId={"email"}>
                    <Form.Label className="fw-700 f-14 text-black form-label">
                      Email Address*
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={edit.email}
                      onChange={updateChanges}
                    />
                    <span className="error-message_span mb-3">
                      {errorMessage?.email}
                    </span>
                  </Form.Group>
                </Col>
                <Col clasName="mt-1">
                  <MultiSelect
                    onChange={(val) => updateSelect(val, "state")}
                    isMulti={true}
                    name={"state"}
                    size={"w-40"}
                    options={stateList}
                    value={edit?.state?.length > 0 ? edit?.state : []}
                    lable={"State*"}
                    placeholder={"Select state"}
                    errormessage={errorMessage?.state}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} clasName="mt-1">
                  <MultiSelect
                    onChange={(val) => updateSelect(val, "cities")}
                    isMulti={true}
                    name={"cities"}
                    size={"w-40"}
                    options={cities}
                    value={edit?.cities?.length > 0 ? edit?.cities : []}
                    lable={"Cities*"}
                    placeholder={"Select City"}
                    errormessage={errorMessage?.cities}
                  />
                </Col>

                <Col md={6}>
                  <PhoneNumberInput
                    type={"number"}
                    name="phoneNumber"
                    label={"Phone Number*"}
                    className="customChanges"
                    // disabled={editPhoneNumber}
                    placeholder={"Enter your Phone Number"}
                    value={edit.phoneNumber}
                    errorMessage={errorMessage?.phoneNumber}
                    isInvalid={!!errorMessage?.phoneNumber}
                    onChange={updateChanges}
                  />
                </Col>
                <Col md={6}>
                  <MultiSelect
                    onChange={(val) => updateSelect(val, "propertyTypes")}
                    isMulti={true}
                    name={"propertyTypes"}
                    size={"w-40"}
                    options={propertyData}
                    value={
                      edit?.propertyTypes?.length > 0 ? edit?.propertyTypes : []
                    }
                    lable={"Property Type*"}
                    placeholder={"Select Property type"}
                    errormessage={errorMessage?.propertyType}
                  />
                </Col>
                <Col md={6}>
                  <div className=" mb-3">
                    <SingleImageUpload
                      multiple={false}
                      files={imageName ? imageName : []}
                      name="image"
                      id="image"
                      className="w-100"
                      imageApi={(files) =>
                        logoImageApi(files, "image", errorMessage)
                      }
                      placeholder="Upload Cover Image"
                      label="Upload cover image"
                      errorObject={
                        errorMessage?.image ? errorMessage?.image : ""
                      }
                      setErrorObject={setErrorMessage}
                      errorMessage={
                        errorMessage?.image ? errorMessage?.image : ""
                      }
                    />
                    <p className="f-12 mb-0">
                      *Cover image resolution must be 1440 X 348
                    </p>

                    <div className="invalid-feedback">
                      {errorMessage?.image ? errorMessage?.image : ""}
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <label>About *</label>
                  <Form.Control
                    // disabled={disableFields}
                    as="textarea"
                    placeholder="write text"
                    style={{ height: "130px", resize: "none" }}
                    value={edit.about}
                    name={"about"}
                    onChange={updateChanges}
                  />
                  <span className="error-message_span mb-3">
                    {errorMessage?.about}
                  </span>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-group" controlId={"name"}>
                    <Form.Label className="fw-700 f-14 text-black form-label">
                      Referral code
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={"referralCode"}
                      value={edit.referralCode}
                      onChange={updateChanges}
                      disabled={edit?.referralStatus ? true : false}
                      placeholder="Enter Referral code"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <br />
              {Object.keys(subscription).length > 0 ? (
                <div>
                  <label className="text-heading">Subscription</label>
                  <div className="currentPlanCard">
                    <Card>
                      <Card.Body>
                        <div className="card-left">
                          <Card.Title>{subscription?.planName}</Card.Title>
                          <Card.Text>Current Plan</Card.Text>
                          <Card.Text>
                            <b>Valid Up To: </b>
                            {subscription?.validityDays + "-days"}
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col col-6">
                  <label title="Subscription" className="text-heading">
                    Subscription
                  </label>
                  {!subscriptionExpired && !newAgent && (
                    <span className="text-sm text-muted ms-3">
                      {`(${subscriptionExpiryDate})`}
                    </span>
                  )}
                </div>
                <div className="col col-6">
                  <div className="d-flex gap-3 float-end align-items-center">
                    {subscriptionExpired ? (
                      <>
                        <span className="text-sm text-danger">
                          {subscriptionExpiryDate}
                        </span>
                        <Button
                          type="button"
                          className="btn-color-primary d-block"
                          onClick={() => {
                            setShowRenewModal(true);
                          }}
                        >
                          <span>Renew Subscription</span>
                        </Button>
                      </>
                    ) : (
                      !subscrptionEdit && (
                        <Button
                          type="button"
                          className="btn-outline-color-primary d-block border-0"
                          onClick={(e) => {
                            setSubscrptionEdit(true);
                          }}
                        >
                          <span className="me-2 text-blue ">Edit Plan</span>
                          <FaPen className="text-blue" />
                        </Button>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                {subscriptionArray?.length > 0 &&
                  subscriptionArray.map((item, i) => {
                    // destructuring subscription array
                    const {
                      key,
                      type,
                      inputType,
                      placeholder,
                      name,
                      option,
                      subKey,
                    } = item;
                    return (
                      <React.Fragment key={i}>
                        {type === "input" ? (
                          <RenderInput
                            name={name}
                            keyValue={key}
                            value={subscriptionPlan[key]}
                            disabled={
                              location?.state?.view ||
                              !subscrptionEdit ||
                              !subPlanChosen
                                ? true
                                : false
                            }
                            error={errorObject[key]}
                            inputType={inputType}
                            objName="subscription"
                            onChangeSubscription={onChangeSubscription}
                            edit={edit}
                            placeholder={placeholder}
                            subKey={subKey ? subKey : ""}
                            submissionInputShow={submissionInputShow}
                            propertyDemandInput={propertyDemandInput}
                            size="w-50"
                          />
                        ) : (
                          <RenderSelect
                            name={name}
                            keyValue={key}
                            option={option}
                            value={subscriptionPlan[key]}
                            disabled={
                              location?.state?.view ||
                              !subscrptionEdit ||
                              (key !== "planName" && !subPlanChosen)
                                ? true
                                : false
                            }
                            error={errorObject[key]}
                            placeholder={placeholder}
                            onChangeSubscription={onChangeSubscription}
                            objName="subscription"
                            subKey={subKey ? subKey : ""}
                            rmVal={subscriptionPlan?.relationshipManager}
                            setSubmissionInputShow={setSubmissionInputShow}
                            setPropertyDemandInput={setPropertyDemandInput}
                            subscription={subscriptionPlan}
                            companyTypeReceived={companyTypeReceived}
                            size="w-50"
                          />
                        )}
                        {key === "planName" && <div />}
                      </React.Fragment>
                    );
                  })}
              </div>

              {subscriptionExpired && (
                <SubscriptionRenew
                  subscriptionArray={subscriptionArray}
                  showRenewModal={showRenewModal}
                  setShowRenewModal={setShowRenewModal}
                  defaultsSubscriptions={defaultsSubscriptionPlans}
                  agentId={agentId}
                  setSubscriptionPlan={setSubscriptionPlan}
                  setIsSubscriptionRenewed={setIsSubscriptionRenewed}
                  setInitialSubscriptionData = {setInitialSubscriptionData}
                />
              )}
              <div className="row">
                <div className="col col-sm-12 col-md-12 col-lg-6 ">
                  {!newAgent && subscrptionEdit && (
                    <div className="btn-container  mb-5 float-lg-start ">
                      <>
                        <Button
                          type="button"
                          className="btn-outline-color-primary d-block"
                          onClick={(e) => {
                            setSubscriptionPlan(initialSubscriptionData);
                            setSubscrptionEdit(false);
                            setErrorObject({});
                          }}
                          disabled={!subscrptionEdit}
                        >
                          Cancel
                        </Button>
                        {
                          <Button
                            type="button"
                            disabled={loading || !subscrptionEdit}
                            loading={loading}
                            className="btn-color-primary d-block"
                            onClick={handleSubscriptionEdit}
                          >
                            Update Subscription
                          </Button>
                        }
                      </>
                    </div>
                  )}
                </div>
                <div className="col col-sm-12 col-md-12 col-lg-6 d-flex align-items-end justify-content-end">
                  <div className="btn-container">
                    <Button type="submit" className="btn-color-primary d-block">
                      {newAgent ? "Create Agent page" : "Update Agent page"}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>{" "}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default AgentFormPage;

import "./style.scss";
const Button = ({ id, children, loading, onKeyPress, disabled, className, type, onClick, _ref }) => {
  return (
    <button
      onKeyPress={onKeyPress}
      id={id}
      type={type}
      className={`btn f-14 fw-700 ${className}`}
      disabled={loading || disabled}
      onClick={onClick}
      ref={_ref}
    >
      {!loading && <span>{children}</span>}
      {loading && <i className="fa fa-spin fa-spinner"></i>}
    </button>
  );
};
export default Button;
export const sellerColumns = [
  {
    pageName: "agentList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
     

      {
        dataField: "name",
        text: "Agent",
        formatter: false,
      },

      {
        dataField: "cityAction",
        text: "City",
        formatter: true,
      },
      {
        dataField: "propertyCount",
        text: "Property uploads",
        formatter: false,
      },

      {
        dataField: "agentAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyAcceptedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "acceptBy",
        text: "Accepted by",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "postedBy",
        text: "Posted by",
        formatter: false,
      },
      {
        dataField: "companyNameFormatter",
        text: "Agent",
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
        
      },
      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "acceptedAction",
        text: "Accepted on",
        formatter:true
      },
    ],
  },
  {
    pageName: "propertyRejectedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "rejectedBy",
        text: "Rejected by",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "postedBy",
        text: "Posted by",
        formatter: false,
      },
      {
        dataField: "companyNameFormatter",
        text: "Agent",
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
        
      },
      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "rejectedAction",
        text: "Rejected on",
        formatter: true,
      },
    ],
  },
  {
    pageName: "needMoreInfoList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "acceptBy",
        text: "Contacted by",
        formatter: false,
      },
    
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      // {
      //   dataField: "postedBy",
      //   text: "Posted By",
      //   formatter: false,
      // },
      // {
      //   dataField: "companyNameFormatter",
      //   text: "Developer",
      //   formatter: true,
      // },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyTypeFormatter",
        text: "Property type",
        formatter: true,
      },
      {
        dataField: "city",
        text: "City",
        formatter: true,
      },
      {
        dataField: "contactedAction",
        text: "Contacted on",
        formatter: true,
      },
    ],
  },
  {
    pageName:"propertyContactedList",
    data:[
        {
            dataField: "sno",
            text: "S.no",
            formatter:false
          },
      
          {
            dataField: "brand",
            text: "Brand",
            formatter:false
          },
          {
            dataField: "company.companyName",
            text: "Company",
            formatter: false,
          },
          {
            dataField: "pocName",
            text: "POC name",
            formatter: false,
          },
          {
            dataField: "supplierName.name",
            text: "Supplier name",
            formatter: false,
          },
          {
            dataField: "propertyIdFormatter",
            text: "Property ID",
            formatter: true,
          },
           {
            dataField: "projectNameFormatter",
            text: "Project name",
            formatter: true,
            // formatter: (cellContent, row, rowIndex) => row?.projectName?row?.projectName:"-",
          },
          {
            dataField: "propertyType.name",
            text: "Property type",
            formatter: false,
            // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
          },         
          {
            dataField: "city",
            text: "City",
            formatter: false,
          },
           {
            dataField: "createdAtAction",
            text: "Contacted on",
            formatter:true 
          },
    ]
},
  {
    pageName:"propertyPostedList",
    data:[
        {
            dataField: "sno",
            text: "S.no",
            formatter:false
          },
      
          {
            dataField: "developerId.name",
            text: "Agent",
            formatter:false
          },
          {
            dataField: "listPropertyIdFormatter",
            text: "Property ID",
            formatter: true,
          },
          {
            dataField: "propertyTypeId.name",
            text: "Property type",
            formatter: false,
            // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
          },         
          {
            dataField: "cities",
            text: "City",
            formatter: false,
          },
          // {
          //   dataField: "submittedBy.name",
          //   text: "Submitted By",
          //   formatter: false,
          // },
          {
            dataField: "createdAtAction",
            text: "Created on",
            formatter:true 
          },

          // {
          //   dataField:"reponseCoutFormatter",
          //   text: "Properties response",
          //   formatter: true,
          // },
          {
            dataField: "propertyResponse",
            text: "Properties response",
            formatter: false,
          },
          {
            dataField: "isActive",
            text: "Active/In-Active",
            formatter: (cellContent, row, rowIndex)=>row?.isActive?"Active":"In-Active",
          },
          // {
          //   dataField: "matchingCountFormatter",
          //   text: "Matching demands",
          //   formatter: true,
          // },
          //  {
          //   dataField:"matchingContactedFormatter",
          //   text: "Matching demands Contacted",
          //   formatter: true,
          // },
          {
            dataField: "matchingDemand",
            text: "Matching demands",
            formatter: false,
          },
          {
            dataField: "matchingDemandContacted",
            text: "Matching demands contacted",
            formatter: false,
          },
          {
            dataField:"propertyListAction",
            text: "Action",
            formatter: true,
          },
         
    ]
},
  {
    pageName:"propertyActiveList",
    data:[
        {
            dataField: "sno",
            text: "S.no",
            formatter:false
          },
      
          {
            dataField: "developerId.name",
            text: "Agent",
            formatter:false
          },
          {
            dataField: "listPropertyIdFormatter",
            text: "Property ID",
            formatter: true,
          },
          {
            dataField: "propertyTypeId.name",
            text: "Property type",
            formatter: false,
            // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
          },         
          {
            dataField: "cities",
            text: "City",
            formatter: false,
          },
          {
            dataField: "submittedBy.name",
            text: "Submitted by",
            formatter: false,
          },
          {
            dataField: "createdAtAction",
            text: "Created on",
            formatter:true 
          },

          // {
          //   dataField:"reponseCoutFormatter",
          //   text: "Properties response",
          //   formatter: true,
          // },
          {
            dataField: "propertyResponse",
            text: "Properties response",
            formatter: false,
          },
          {
            dataField: "isActive",
            text: "Active/In-Active",
            formatter: (cellContent, row, rowIndex)=>row?.isActive?"Active":"In-Active",
          },
          // {
          //   dataField: "matchingCountFormatter",
          //   text: "Matching demands",
          //   formatter: true,
          // },
          //  {
          //   dataField:"matchingContactedFormatter",
          //   text: "Matching demands Contacted",
          //   formatter: true,
          // },
          {
            dataField: "matchingDemand",
            text: "Matching demands",
            formatter: false,
          },
          {
            dataField: "matchingDemandContacted",
            text: "Matching demands contacted",
            formatter: false,
          },
          {
            dataField:"propertyListAction",
            text: "Action",
            formatter: true,
          },
         
    ]
},
  {
    pageName:"propertyInActiveList",
    data:[
        {
            dataField: "sno",
            text: "S.no",
            formatter:false
          },
      
          {
            dataField: "developerId.name",
            text: "Agent",
            formatter:false
          },
          {
            dataField: "listPropertyIdFormatter",
            text: "Property ID",
            formatter: true,
          },
          {
            dataField: "propertyTypeId.name",
            text: "Property type",
            formatter: false,
            // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
          },         
          {
            dataField: "cities",
            text: "City",
            formatter: false,
          },
          {
            dataField: "submittedBy.name",
            text: "Submitted by",
            formatter: false,
          },
          {
            dataField: "createdAtAction",
            text: "Created on",
            formatter:true 
          },

          // {
          //   dataField:"reponseCoutFormatter",
          //   text: "Properties response",
          //   formatter: true,
          // },
          {
            dataField: "propertyResponse",
            text: "Properties response",
            formatter: false,
          },
          {
            dataField: "isActive",
            text: "Active/In-Active",
            formatter: (cellContent, row, rowIndex)=>row?.isActive?"Active":"In-Active",
          },
          // {
          //   dataField: "matchingCountFormatter",
          //   text: "Matching demands",
          //   formatter: true,
          // },
          //  {
          //   dataField:"matchingContactedFormatter",
          //   text: "Matching demands Contacted",
          //   formatter: true,
          // },
          {
            dataField: "matchingDemand",
            text: "Matching demands",
            formatter: false,
          },
          {
            dataField: "matchingDemandContacted",
            text: "Matching demands contacted",
            formatter: false,
          },
          {
            dataField:"propertyListAction",
            text: "Action",
            formatter: true,
          },
         
    ]
},
  {
    pageName:"propertyResponseList",
    data:[
        {
            dataField: "sno",
            text: "S.no",
            formatter:false
          },
      
          {
            dataField: "developerId.name",
            text: "Agent",
            formatter:false
          },
          {
            dataField: "listPropertyIdFormatter",
            text: "Property ID",
            formatter: true,
          },
          {
            dataField: "propertyTypeId.name",
            text: "Property type",
            formatter: false,
            // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
          },         
          {
            dataField: "cities",
            text: "City",
            formatter: false,
          },
          {
            dataField: "submittedBy.name",
            text: "Submitted by",
            formatter: false,
          },
          {
            dataField: "createdAtAction",
            text: "Created on",
            formatter:true 
          },

          // {
          //   dataField:"reponseCoutFormatter",
          //   text: "Properties response",
          //   formatter: true,
          // },
          {
            dataField: "propertyResponse",
            text: "Properties response",
            formatter: false,
          },
          {
            dataField: "isActive",
            text: "Active/In-Active",
            formatter: (cellContent, row, rowIndex)=>row?.isActive?"Active":"In-Active",
          },
          // {
          //   dataField: "matchingCountFormatter",
          //   text: "Matching demands",
          //   formatter: true,
          // },
          //  {
          //   dataField:"matchingContactedFormatter",
          //   text: "Matching demands Contacted",
          //   formatter: true,
          // },
          {
            dataField: "matchingDemand",
            text: "Matching demands",
            formatter: false,
          },
          {
            dataField: "matchingDemandContacted",
            text: "Matching demands contacted",
            formatter: false,
          },
          {
            dataField:"propertyListAction",
            text: "Action",
            formatter: true,
          },
         
    ]
},
  {
    pageName:"propertyPostedAgainstList",
    data:[
        {
            dataField: "sno",
            text: "S.no",
            formatter:false
          },
      
          {
            dataField: "developerId.name",
            text: "Agent",
            formatter:false
          },
          {
            dataField: "listPropertyIdFormatter",
            text: "Property ID",
            formatter: true,
          },
          {
            dataField: "propertyTypeId.name",
            text: "Property type",
            formatter: false,
            // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
          },         
          {
            dataField: "cities",
            text: "City",
            formatter: false,
          },
          {
            dataField: "submittedBy.name",
            text: "Submitted by",
            formatter: false,
          },
          {
            dataField: "createdAtAction",
            text: "Created on",
            formatter:true 
          },

          // {
          //   dataField:"reponseCoutFormatter",
          //   text: "Properties response",
          //   formatter: true,
          // },
          {
            dataField: "propertyResponse",
            text: "Properties response",
            formatter: false,
          },
          {
            dataField: "isActive",
            text: "Active/In-Active",
            formatter: (cellContent, row, rowIndex)=>row?.isActive?"Active":"In-Active",
          },
          // {
          //   dataField: "matchingCountFormatter",
          //   text: "Matching demands",
          //   formatter: true,
          // },
          //  {
          //   dataField:"matchingContactedFormatter",
          //   text: "Matching demands Contacted",
          //   formatter: true,
          // },
          {
            dataField: "matchingDemand",
            text: "Matching demands",
            formatter: false,
          },
          {
            dataField: "matchingDemandContacted",
            text: "Matching demands contacted",
            formatter: false,
          },
          {
            dataField: "demandIdFormatter",
            text: "Demand ID",
            formatter: true,
          },
          {
            dataField: "brand",
            text: "Brand name",
            formatter: false,
          },
          {
            dataField: "company",
            text: "Company name",
            formatter: false,
          },
          {
            dataField: "pocName.name",
            text: "POC name",
            formatter: false,
          },
          {
            dataField:"propertyListAction",
            text: "Action",
            formatter: true,
          },
         
    ]
}

];

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { AccessDenied, getData } from "../../../api";

//image
import BuyerImage from "../../../assets/images/Buyer_Rectangle.png";

//components
import moment from "moment";
import NavBar from "../../../components/Navbar";
import "../style.scss";
import Demand from "./Demands/demand";
import Detail from "./detail";
import Reports from "./reports";

import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import PocTable from "../CustomerForm/poc/pocTable";

const BrandPage = () => {
  const location = useLocation();
  const brandIdUpdate = useSelector((state) => state.login.brandId);
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const { parentPage: reportuser, activeSlug } = useSelector(
    (state) => state.login
  );
  const { slug } = useParams();
  //state
  const [customerData, setCustomerData] = useState({});
  const [state, setState] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const access = {
    addDemand: loginPermission?.includes(menuId.addDemand),
    buyerImportsAndExports: loginPermission?.includes(
      menuId?.buyerImportsAndExports
    ),
    view: loginPermission?.includes(menuId?.viewDemand) ? true : false,
    edit: loginPermission?.includes(menuId?.editDemand) ? true : false,
  };

  const getCustomerData = async () => {
    setState(true);
    // if (location.state.fromCompany) {
    // var url = `/brand/${brandIdUpdate}`;
    // } else {}
    var url = `/brand/getBrandByName/${slug}`;

    await getData(url, {}, {}).then((response) => {
      if (response.status) {
        let subscription = [];
        if (location?.state?.fromCustomer) {
          subscription = [
            {
              key: "featuredDemand",
              title: "Featured demand",
            },
            {
              key: "emailtoSuppliers",
              title: "Email to suppliers",
            },
            {
              key: "homePageSlotAndBanner",
              title: "Top brand slot",
            },
            {
              key: "expiredate",
              title: "Expire at",
            },
          ];
        } else {
          subscription = [
            {
              key: "propertyOrDemand",
              title: "No of demands",
            },
            {
              key: "validityDays",
              title: "Validity days",
            },
            {
              key: "userDashboard",
              title: "User dashboard",
            },
            {
              key: "alert",
              title: "Property alert",
            },
            {
              key: "contactSuppliers",
              title: "Contact suppliers",
            },
            {
              key: "featuredDemand",
              title: "Featured demand",
            },
            {
              key: "assistedUploading",
              title: "Assisted uploading",
            },
            {
              key: "emailtoSuppliers",
              title: "Email to suppliers",
            },
            {
              key: "homePageSlotAndBanner",
              title: "Top brand slot",
            },
            {
              key: "expiredate",
              title: "Expire at",
            },
          ];
        }
        let data = response.data;

        let subsArray = [];
        subscription.map((item) => {
          if (response?.data?.subscriptionPlanId?.hasOwnProperty(item.key)) {
            subsArray.push({
              value: response?.data?.subscriptionPlanId[item.key],
              title: item.title,
              key: item.key,
            });
          }
          return item;
        });
        setSubscriptionData(subsArray);
        setCustomerData(data);
        setState(false);
      } else {
        setState(false);
      }
    });
  };
  useEffect(() => {
    getCustomerData();
  }, []);
  return (
    <>
      {loginPermission?.includes(menuId.viewBuyerCustomers) ? (
        <>
          <NavBar />
          {state ? (
            <>
              <Loader />{" "}
            </>
          ) : (
            <div className="brandPage-container">
              {Object.keys(customerData).length > 0 ? (
                <>
                  {customerData?.image && customerData?.image?.viewUrl ? (
                    <img
                      className="w-100 brand-image"
                      src={customerData?.image?.viewUrl}
                      alt="buyerImage"
                    />
                  ) : (
                    <div className="w-100 brand-image"></div>
                  )}
                  <div className="brandPage-content">
                    <div className="d-flex profile-container">
                      <img
                        src={
                          customerData.logo
                            ? customerData.logo.viewUrl
                              ? customerData.logo.viewUrl
                              : BuyerImage
                            : BuyerImage
                        }
                        className="brand-profile"
                        alt="logo"
                      />
                      <div className="profile-content">
                        <label className="body-text-heading">
                          {customerData?.name}
                        </label>
                      </div>
                    </div>
                    <Detail customerData={customerData} />
                    <div style={{ marginBottom: "35px" }}>
                      <label className="body-text-heading mb-0">About</label>
                      <p className="word-wrap">{customerData.about}</p>
                    </div>
                    <div className="mt-3">
                      <label className="body-text-heading mb-0 ">POC </label>
                    </div>
                    <PocTable
                      companyTypeReceived={location?.state?.customerType}
                      type="view"
                      view={true}
                      viewPage={true}
                    />

                    <Reports
                      customerUserId={customerData?._id}
                      type={"brand"}
                    />
                    {subscriptionData.length > 0 ? (
                      <div className="mt-3 subscription-card">
                        <label className="body-text-heading mb-0">
                          Subscription
                        </label>
                        <div className="row mt-4 mb-4">
                          {subscriptionData?.map((item, i) => {
                            return (
                              <div
                                className="subscription-detail-columns"
                                key={i}
                              >
                                <p className="subscription-key word-wrap">
                                  {item.title}
                                </p>
                                <p className="subscription-value word-wrap">
                                  {item.key === "propertyOrDemand"
                                    ? item.value
                                    : item.key === "expiredate"
                                    ? moment(item.value).format("DD-MM-YYYY")
                                    : item.value}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <Demand
                      access={access}
                      customerCityData={customerData?.cities}
                      featuredPositioningStatus={
                        customerData?.subscriptionPlanId?.featuredPositioning ||
                        customerData?.subscriptionPlanId?.featuredDemand
                      }
                      customerUserId={customerData?._id}
                      customerId={customerData._id}
                      companyName={customerData.companyName}
                      image={customerData.logo ? customerData.logo : BuyerImage}
                      brandName={customerData?.name ? customerData?.name : ""}
                    />
                  </div>
                </>
              ) : (
                <center>No data found</center>
              )}
            </div>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};
export default BrandPage;

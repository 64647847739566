export const postStates = () => {
    return "/country/getStates";
}

export const getRoles = () =>{
    return "/role/roletype?type=user";
}

export const getRm = () => {
    return "/user/getAllSubAdmin?pageNo=0&limit=0&role=Relationship Manager&type=rm";
}

export const getPropertyType = () =>{
    return "/propertyType";
}
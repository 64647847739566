import { useEffect, useState } from "react";
import { getData } from "../../api";


import { useNavigate } from "react-router-dom";
import { ResposePropertyCard } from "../../components/Cards/PropertyCard/propertyCard";
const ResponseProperty = ({ demandId }) => {
    const navigate = useNavigate()
   

   

    const [propertyData, setProptyData] = useState([
        {
          "_id": "64354f4a93664ef4f2727c48",
          "imagesAndVideos": {
            "frontSide": [
              {
                "fileName": "bo_2022-cep-mh-xfn-global-20221102-3840x2160.png",
                "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/property/64354f4a93664ef4f2727c48/1681215362778-bo_2022_cep_mh_xfn_global_20221102_3840x2160.png",
                "viewUrl": "https://keydemand-bucket.s3.us-east-2.amazonaws.com/property/64354f4a93664ef4f2727c48/1681215362778-bo_2022_cep_mh_xfn_global_20221102_3840x2160.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPHUOKLR7PTAS5UJ%2F20230412%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230412T074839Z&X-Amz-Expires=28800&X-Amz-Signature=d67064b58fef7d2c835d303ac81f01d108100c5f436ededf4008308a3e9a403e&X-Amz-SignedHeaders=host",
                "type": "image"
              }
            ]
          },
          "isActive": true,
          "responses": 0,
          "featuredProperty": true,
          "carpetArea": 789,
          "builtupArea": 800,
          "rentPsf": 980,
          "negotiable": true,
          "possession": 3,
          "transactionType": "Lease",
          "location": "Madurai",
          "microLocation": {
            "_id": "642ecca84de15909bc5eaae4",
            "description": "Bypass Rd,Madurai, Tamil Nadu",
            "name": "Bypass Rd,Madurai, Tamil Nadu"
          },
          "additionalDetails": {
            "floor": "15",
            "propertyAge": 20,
            "totalFloors": 20,
            "cabins": 3,
            "meetingRooms": 2,
            "bikeParking": "100",
            "carParking": "1",
            "washRooms": 2,
            "idealFor": [
              {
                "_id": "63c0f698e611894420fbcbae",
                "isActive": true,
                "name": "Aviation",
                "image": {
                  "fileName": "Aviation.png",
                  "url": "https://s3-us-east-2.amazonaws.com//keydemand-bucket/endUse/1674728646324-Aviation.png"
                },
                "label": "Aviation",
                "value": "Aviation"
              }
            ]
          },
          "propertyTypeId": {
            "_id": "63bfad83d53152adb93ea00c",
            "name": "Office space"
          },
          "productId": {
            "prefix": "O",
            "number": 413,
            "display": "OP0413"
          },
          "developerId": {
            "_id": "64353e9193664ef4f27261b5",
            "name": "ASD"
          },
          "userId": {
            "_id": "64353eea93664ef4f2726207",
            "name": "qw"
          },
          "customerType": "Developer",
          "publishedDate": "2023-04-11T12:15:06.570Z",
          "projectDeveloper": "ASD",
          "createdAt": "2023-04-11T12:15:06.570Z",
          "timeSince": "19 hours"
        },
        {
          "_id": "6435431693664ef4f2726cae",
          "imagesAndVideos": {
            "frontSide": [
              {
                "fileName": "403286922M-1674379900152.jpg",
                "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/property/6435431693664ef4f2726cae/1681212197734-403286922M_1674379900152.jpg",
                "viewUrl": "https://keydemand-bucket.s3.us-east-2.amazonaws.com/property/6435431693664ef4f2726cae/1681212197734-403286922M_1674379900152.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPHUOKLR7PTAS5UJ%2F20230412%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230412T074839Z&X-Amz-Expires=28800&X-Amz-Signature=f9e6ec55928b55656320a70a3122422d0c707eb5846a5e896ee9eaaabe0a9c6c&X-Amz-SignedHeaders=host",
                "type": "image"
              }
            ]
          },
          "isActive": false,
          "responses": 0,
          "featuredProperty": false,
          "userId": {
            "_id": "64353ea793664ef4f27261de",
            "name": "Qw"
          },
          "transactionType": "Lease",
          "propertyTypeId": {
            "_id": "63bfad83d53152adb93ea00c",
            "name": "Office space"
          },
          "location": "Tiruppur",
          "projectDeveloper": "ASD",
          "microLocation": {
            "_id": "642e9a0fb28425bf28c9272f",
            "description": "Avinashi - Tiruppur Rd, Tiruppur, Tamil Nadu",
            "name": "Avinashi - Tiruppur Rd, Tiruppur, Tamil Nadu"
          },
          "propertyName": "QEe",
          "carpetArea": 1000,
          "builtupArea": 26565,
          "rentPsf": 2560,
          "possession": 6,
          "deposit": 10,
          "developerId": {
            "_id": "64353e9193664ef4f27261b5",
            "name": "ASD"
          },
          "customerType": "Developer",
          "createdAt": "2023-04-11T11:23:40.027Z",
          "additionalDetails": {
            "bikeParking": "12",
            "carParking": "9"
          },
          "productId": {
            "prefix": "O",
            "number": 412,
            "display": "OP0412"
          },
          "publishedDate": "2023-04-11T11:23:40.027Z",
          "timeSince": "20 hours"
        },
        {
          "_id": "64353a4793664ef4f272545a",
          "imagesAndVideos": {
            "frontSide": [
              {
                "fileName": "fc0740d7c26d93974e117cb88a81bc36.jpg",
                "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/property/64353a4793664ef4f272545a/1681209934382-fc0740d7c26d93974e117cb88a81bc36.jpg",
                "viewUrl": "https://keydemand-bucket.s3.us-east-2.amazonaws.com/property/64353a4793664ef4f272545a/1681209934382-fc0740d7c26d93974e117cb88a81bc36.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPHUOKLR7PTAS5UJ%2F20230412%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230412T074839Z&X-Amz-Expires=28800&X-Amz-Signature=da49615eee5055df14c6aba030964fdc3900f0df77582834a65f1155dc22d3cd&X-Amz-SignedHeaders=host",
                "type": "image"
              }
            ]
          },
          "isActive": true,
          "responses": 0,
          "featuredProperty": false,
          "propertyTypeId": {
            "_id": "62c8097d38ea0b35f0c6aadd",
            "name": "Retail"
          },
          "transactionType": "Lease",
          "location": "Tiruppur",
          "projectDeveloper": "ASDF",
          "microLocation": {
            "_id": "642e9a0fb28425bf28c9272f",
            "description": "Avinashi - Tiruppur Rd, Tiruppur, Tamil Nadu",
            "name": "Avinashi - Tiruppur Rd, Tiruppur, Tamil Nadu"
          },
          "propertyName": "KJJKK",
          "carpetArea": 1700,
          "builtupArea": 2000,
          "rentPsf": 12,
          "parking": 1,
          "possession": 3,
          "tenure": 1,
          "ocStatus": true,
          "deposit": 11,
          "developerId": {
            "_id": "642eb77b7a4d4df05f4b73a8",
            "contactPerson": "Zeb",
            "name": "Zeb"
          },
          "userId": {
            "_id": "642eb7787a4d4df05f4b73a6",
            "name": "Zeb",
            "lastName": "ele"
          },
          "customerType": "Agent",
          "createdAt": "2023-04-11T10:45:59.948Z",
          "additionalDetails": {},
          "productId": {
            "prefix": "R",
            "number": 424,
            "display": "RP0424"
          },
          "publishedDate": "2023-04-11T10:45:59.948Z",
          "timeSince": "21 hours"
        },
        {
          "_id": "6435369193664ef4f27249ee",
          "imagesAndVideos": {
            "frontSide": [
              {
                "fileName": "403286922M-1674379900152.jpg",
                "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/property/6435369193664ef4f27249ee/1681208982364-403286922M_1674379900152.jpg",
                "viewUrl": "https://keydemand-bucket.s3.us-east-2.amazonaws.com/property/6435369193664ef4f27249ee/1681208982364-403286922M_1674379900152.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPHUOKLR7PTAS5UJ%2F20230412%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230412T074839Z&X-Amz-Expires=28800&X-Amz-Signature=dfdd3189169c7cf0fa9504090b32e789d585819075243cb64cee9a056d5a76ca&X-Amz-SignedHeaders=host",
                "type": "image"
              }
            ]
          },
          "isActive": true,
          "responses": 0,
          "featuredProperty": false,
          "userId": {
            "_id": "6422e00173fc3586e44fc2be",
            "name": "sh"
          },
          "transactionType": "Revenue Share",
          "propertyTypeId": {
            "_id": "62c8097d38ea0b35f0c6aadd",
            "name": "Retail"
          },
          "location": "Madurai",
          "projectDeveloper": "sha sell",
          "microLocation": {
            "_id": "642ecca84de15909bc5eaae4",
            "description": "Bypass Rd,Madurai, Tamil Nadu",
            "name": "Bypass Rd,Madurai, Tamil Nadu"
          },
          "propertyName": "QWERTY",
          "carpetArea": 1900,
          "builtupArea": 2500,
          "rentPsf": 2,
          "parking": 0,
          "possession": 1,
          "tenure": 0,
          "ocStatus": true,
          "developerId": {
            "_id": "6422dfdf73fc3586e44fc25c",
            "name": "sha sell"
          },
          "customerType": "Developer",
          "createdAt": "2023-04-11T10:30:16.497Z",
          "additionalDetails": {
            "floor": "Ground",
            "buildingStructure": "Independent building",
            "propertyAge": 20,
            "previousOccupancy": "0",
            "idealFor": [
              {
                "_id": "6385ac79362c4a21647bb0f3",
                "isActive": true,
                "name": "Banks/ATM",
                "label": "Banks/ATM",
                "value": "6385ac79362c4a21647bb0f3"
              }
            ]
          },
          "productId": {
            "prefix": "R",
            "number": 423,
            "display": "RP0423"
          },
          "publishedDate": "2023-04-11T10:30:16.497Z",
          "timeSince": "21 hours"
        },
        {
          "_id": "6435233d93664ef4f27234f3",
          "imagesAndVideos": {
            "frontSide": [
              {
                "fileName": "pantry.png",
                "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/property/6435233d93664ef4f27234f3/1681204060810-pantry.png",
                "viewUrl": "https://keydemand-bucket.s3.us-east-2.amazonaws.com/property/6435233d93664ef4f27234f3/1681204060810-pantry.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPHUOKLR7PTAS5UJ%2F20230412%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230412T074839Z&X-Amz-Expires=28800&X-Amz-Signature=f0b8c4d4dc08cbd2ece8275c37a547b9c5f3e4b62d6a5c9e5791e3ccdf44c768&X-Amz-SignedHeaders=host",
                "type": "image"
              }
            ]
          },
          "isActive": true,
          "responses": 3,
          "featuredProperty": false,
          "propertyTypeId": {
            "_id": "63bfad83d53152adb93ea00c",
            "name": "Office space"
          },
          "transactionType": "Lease",
          "location": "Chennai",
          "microLocation": {
            "description": "Anna Nagar, Chennai, Tamil Nadu, India",
            "placeId": "ChIJm3EiiAdkUjoR4oGVuHcQoL0",
            "active": true,
            "index": 0,
            "formattedSuggestion": {
              "mainText": "Anna Nagar",
              "secondaryText": "Chennai, Tamil Nadu, India"
            },
            "matchedSubstrings": [
              {
                "length": 4,
                "offset": 0
              }
            ],
            "terms": [
              {
                "offset": 0,
                "value": "Anna Nagar"
              },
              {
                "offset": 12,
                "value": "Chennai"
              },
              {
                "offset": 21,
                "value": "Tamil Nadu"
              },
              {
                "offset": 33,
                "value": "India"
              }
            ],
            "types": [
              "sublocality_level_1",
              "sublocality",
              "political",
              "geocode"
            ],
            "_id": "ChIJm3EiiAdkUjoR4oGVuHcQoL0",
            "name": "Anna Nagar, Chennai, Tamil Nadu, India"
          },
          "carpetArea": 90,
          "builtupArea": 100,
          "rentPsf": 500,
          "possession": 1,
          "developerId": {
            "_id": "63ef8b911e795001de0417d3",
            "contactPerson": "ASWIN KUMAR",
            "name": "ASWIN KUMAR"
          },
          "userId": {
            "_id": "63ef8b911e795001de0417d1",
            "name": "ASWIN KUMAR"
          },
          "customerType": "Agent",
          "createdAt": "2023-04-11T09:08:20.107Z",
          "additionalDetails": {
            "bikeParking": "2",
            "carParking": "5",
            "idealFor": [
              {
                "_id": "63c0f698e611894420fbcbae",
                "isActive": true,
                "name": "Aviation",
                "label": "Aviation",
                "value": "63c0f698e611894420fbcbae"
              }
            ]
          },
          "productId": {
            "prefix": "O",
            "number": 411,
            "display": "OP0411"
          },
          "publishedDate": "2023-04-11T09:08:20.107Z",
          "timeSince": "22 hours"
        },
        {
          "_id": "6435213b93664ef4f2722e2d",
          "imagesAndVideos": {
            "frontSide": [
              {
                "fileName": "Chemical.png",
                "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/property/6435213b93664ef4f2722e2d/1681203547844-Chemical.png",
                "viewUrl": "https://keydemand-bucket.s3.us-east-2.amazonaws.com/property/6435213b93664ef4f2722e2d/1681203547844-Chemical.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPHUOKLR7PTAS5UJ%2F20230412%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230412T074839Z&X-Amz-Expires=28800&X-Amz-Signature=93e83c6af409f9e8ab1923af177e958bdc763f1f4376f2e779ecb11f3e098d6a&X-Amz-SignedHeaders=host",
                "type": "image"
              }
            ]
          },
          "isActive": true,
          "responses": 1,
          "featuredProperty": false,
          "propertyTypeId": {
            "_id": "63bfad83d53152adb93ea00c",
            "name": "Office space"
          },
          "transactionType": "Lease",
          "location": "Mumbai",
          "microLocation": {
            "description": "Andheri West, Mumbai, Maharashtra, India",
            "placeId": "ChIJ3ZHYthi25zsRMtExx1eo-JE",
            "active": true,
            "index": 0,
            "formattedSuggestion": {
              "mainText": "Andheri West",
              "secondaryText": "Mumbai, Maharashtra, India"
            },
            "matchedSubstrings": [
              {
                "length": 12,
                "offset": 0
              }
            ],
            "terms": [
              {
                "offset": 0,
                "value": "Andheri West"
              },
              {
                "offset": 14,
                "value": "Mumbai"
              },
              {
                "offset": 22,
                "value": "Maharashtra"
              },
              {
                "offset": 35,
                "value": "India"
              }
            ],
            "types": [
              "sublocality_level_1",
              "sublocality",
              "political",
              "geocode"
            ],
            "_id": "ChIJ3ZHYthi25zsRMtExx1eo-JE",
            "name": "Andheri West, Mumbai, Maharashtra, India"
          },
          "carpetArea": 90,
          "builtupArea": 400,
          "rentPsf": 500,
          "possession": 3,
          "developerId": {
            "_id": "63ef8b911e795001de0417d3",
            "contactPerson": "ASWIN KUMAR",
            "name": "ASWIN KUMAR"
          },
          "userId": {
            "_id": "63ef8b911e795001de0417d1",
            "name": "ASWIN KUMAR"
          },
          "customerType": "Agent",
          "createdAt": "2023-04-11T09:00:10.749Z",
          "additionalDetails": {
            "bikeParking": "2",
            "carParking": "11"
          },
          "productId": {
            "prefix": "O",
            "number": 410,
            "display": "OP0410"
          },
          "publishedDate": "2023-04-11T09:00:10.749Z",
          "timeSince": "22 hours"
        },
        {
          "_id": "64342adf93664ef4f271f9a4",
          "imagesAndVideos": {
            "frontSide": [
              {
                "fileName": "book.jpg",
                "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/property/64342adf93664ef4f271f9a4/1681198703601-book.jpg",
                "viewUrl": "https://keydemand-bucket.s3.us-east-2.amazonaws.com/property/64342adf93664ef4f271f9a4/1681198703601-book.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPHUOKLR7PTAS5UJ%2F20230412%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230412T074839Z&X-Amz-Expires=28800&X-Amz-Signature=fead1d09931eb289584a4f06a8431ebda416423e123488369971e0e6e0dffe75&X-Amz-SignedHeaders=host",
                "type": "image"
              }
            ]
          },
          "isActive": true,
          "responses": 0,
          "featuredProperty": false,
          "carpetArea": 789,
          "builtupArea": 800,
          "rentPsf": 980,
          "negotiable": true,
          "possession": 3,
          "transactionType": "Lease",
          "location": "Mumbai",
          "microLocation": {
            "description": "Andheri East, Mumbai, Maharashtra, India",
            "placeId": "ChIJMbHfQRu25zsRMazdY3UpaKY",
            "active": false,
            "index": 0,
            "formattedSuggestion": {
              "mainText": "Andheri East",
              "secondaryText": "Mumbai, Maharashtra, India"
            },
            "matchedSubstrings": [
              {
                "length": 7,
                "offset": 0
              }
            ],
            "terms": [
              {
                "offset": 0,
                "value": "Andheri East"
              },
              {
                "offset": 14,
                "value": "Mumbai"
              },
              {
                "offset": 22,
                "value": "Maharashtra"
              },
              {
                "offset": 35,
                "value": "India"
              }
            ],
            "types": [
              "sublocality_level_1",
              "sublocality",
              "political",
              "geocode"
            ],
            "_id": "ChIJMbHfQRu25zsRMazdY3UpaKY",
            "name": "Andheri East, Mumbai, Maharashtra, India"
          },
          "additionalDetails": {
            "floor": "15",
            "propertyAge": 20,
            "totalFloors": 20,
            "cabins": 3,
            "meetingRooms": 2,
            "bikeParking": "100",
            "carParking": "1",
            "washRooms": 2,
            "idealFor": [
              {
                "_id": "63c0f698e611894420fbcbae",
                "isActive": true,
                "name": "Aviation",
                "image": {
                  "fileName": "Aviation.png",
                  "url": "https://s3-us-east-2.amazonaws.com//keydemand-bucket/endUse/1674728646324-Aviation.png"
                },
                "label": "Aviation",
                "value": "Aviation"
              }
            ]
          },
          "propertyTypeId": {
            "_id": "63bfad83d53152adb93ea00c",
            "name": "Office space"
          },
          "productId": {
            "prefix": "O",
            "number": 409,
            "display": "OP0409"
          },
          "developerId": {
            "_id": "6422df0773fc3586e44fbf83",
            "name": "Ash dev"
          },
          "userId": {
            "_id": "6422df7273fc3586e44fc0bb",
            "name": "New"
          },
          "customerType": "Developer",
          "publishedDate": "2023-04-10T15:27:27.769Z",
          "projectDeveloper": "Ash dev",
          "createdAt": "2023-04-10T15:27:27.769Z",
          "timeSince": "40 hours"
        },
        {
          "_id": "6434197593664ef4f271dae8",
          "imagesAndVideos": {
            "frontSide": []
          },
          "isActive": true,
          "responses": 0,
          "featuredProperty": false,
          "propertyName": "test",
          "carpetArea": 7000,
          "builtupArea": 9000,
          "rentPsf": 700,
          "negotiable": true,
          "possession": 1,
          "deposit": 2,
          "transactionType": "Lease",
          "location": "Nashik",
          "microLocation": {
            "description": "Nasik Road, Nashik, Maharashtra, India",
            "placeId": "ChIJyT_j-q_q3TsRpuqorfro2ho",
            "active": false,
            "index": 0,
            "formattedSuggestion": {
              "mainText": "Nasik Road",
              "secondaryText": "Nashik, Maharashtra, India"
            },
            "matchedSubstrings": [
              {
                "length": 4,
                "offset": 0
              }
            ],
            "terms": [
              {
                "offset": 0,
                "value": "Nasik Road"
              },
              {
                "offset": 12,
                "value": "Nashik"
              },
              {
                "offset": 20,
                "value": "Maharashtra"
              },
              {
                "offset": 33,
                "value": "India"
              }
            ],
            "types": [
              "sublocality_level_1",
              "sublocality",
              "political",
              "geocode"
            ],
            "_id": "ChIJyT_j-q_q3TsRpuqorfro2ho",
            "name": "Nasik Road, Nashik, Maharashtra, India"
          },
          "additionalDetails": {
            "floor": "5",
            "propertyAge": 5,
            "idealFor": [
              {
                "_id": "63c0f698e611894420fbcbae",
                "isActive": true,
                "name": "Aviation",
                "image": {
                  "fileName": "Aviation.png",
                  "url": "https://s3-us-east-2.amazonaws.com//keydemand-bucket/endUse/1674728646324-Aviation.png"
                },
                "label": "Aviation",
                "value": "Aviation"
              }
            ],
            "totalFloors": 10,
            "cabins": 2,
            "meetingRooms": 2,
            "bikeParking": "3",
            "carParking": "2",
            "washRooms": 1
          },
          "propertyTypeId": {
            "_id": "63bfad83d53152adb93ea00c",
            "name": "Office space"
          },
          "productId": {
            "prefix": "O",
            "number": 408,
            "display": "OP0408"
          },
          "developerId": {
            "_id": "6405841051284d5fc30e6f47",
            "name": "jananicity",
            "contactPerson": "cityjan"
          },
          "userId": {
            "_id": "6405843151284d5fc30e6f93",
            "name": "cityjan1",
            "lastName": "p"
          },
          "customerType": "Developer",
          "publishedDate": "2023-04-10T14:13:09.884Z",
          "projectDeveloper": "jananicity",
          "createdAt": "2023-04-10T14:13:09.884Z",
          "timeSince": "41 hours"
        },
        {
          "_id": "643418e193664ef4f271d794",
          "imagesAndVideos": {
            "frontSide": [
              {
                "fileName": "book.jpg",
                "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/property/643418e193664ef4f271d794/1681136471195-book.jpg",
                "viewUrl": "https://keydemand-bucket.s3.us-east-2.amazonaws.com/property/643418e193664ef4f271d794/1681136471195-book.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPHUOKLR7PTAS5UJ%2F20230412%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230412T074839Z&X-Amz-Expires=28800&X-Amz-Signature=f2a03b2d449c3d11c6ab9eee99b43e4b676282485224830f06243249859c282a&X-Amz-SignedHeaders=host",
                "type": "image"
              }
            ]
          },
          "isActive": true,
          "responses": 0,
          "featuredProperty": false,
          "propertyName": "test",
          "carpetArea": 700,
          "rentPsf": 900,
          "tenure": 7,
          "ocStatus": false,
          "deposit": 1,
          "builtupArea": 9000,
          "negotiable": true,
          "possession": 1,
          "parking": 900,
          "transactionType": "Revenue Share",
          "location": "Mumbai",
          "microLocation": {
            "description": "Mumbai Naka, Renuka Nagar, Nashik, Maharashtra, India",
            "placeId": "ChIJZ4vOvxrr3TsRt_xvUNJ39jE",
            "active": false,
            "index": 1,
            "formattedSuggestion": {
              "mainText": "Mumbai Naka, Renuka Nagar",
              "secondaryText": "Nashik, Maharashtra, India"
            },
            "matchedSubstrings": [
              {
                "length": 6,
                "offset": 0
              }
            ],
            "terms": [
              {
                "offset": 0,
                "value": "Mumbai Naka"
              },
              {
                "offset": 13,
                "value": "Renuka Nagar"
              },
              {
                "offset": 27,
                "value": "Nashik"
              },
              {
                "offset": 35,
                "value": "Maharashtra"
              },
              {
                "offset": 48,
                "value": "India"
              }
            ],
            "types": [
              "sublocality_level_3",
              "sublocality",
              "political",
              "geocode"
            ],
            "_id": "ChIJZ4vOvxrr3TsRt_xvUNJ39jE",
            "name": "Mumbai Naka, Renuka Nagar, Nashik, Maharashtra, India"
          },
          "additionalDetails": {
            "floor": "Ground",
            "frontAge": 800,
            "accessRoadWidth": 600,
            "buildingStructure": "Independent Building",
            "previousOccupancy": "78q",
            "idealFor": [
              {
                "_id": "6315d89a8f02bd32d0bdce6e",
                "isActive": true,
                "name": "Cafe",
                "image": {
                  "fileName": "Cafe.png",
                  "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/endUse/1662376059818-Cafe.png"
                },
                "label": "Cafe",
                "value": "Cafe"
              }
            ]
          },
          "propertyTypeId": {
            "_id": "62c8097d38ea0b35f0c6aadd",
            "name": "Retail"
          },
          "productId": {
            "prefix": "R",
            "number": 422,
            "display": "RP0422"
          },
          "developerId": {
            "_id": "64240ff1530f51c9fecab18d",
            "name": "janans"
          },
          "userId": {
            "_id": "6424101c530f51c9fecab241",
            "name": "test"
          },
          "customerType": "Developer",
          "publishedDate": "2023-04-10T14:10:41.500Z",
          "projectDeveloper": "janans",
          "createdAt": "2023-04-10T14:10:41.500Z",
          "timeSince": "41 hours"
        },
        {
          "_id": "6433f83193664ef4f2719264",
          "imagesAndVideos": {
            "frontSide": [
              {
                "fileName": "bo_2022-cep-mh-xfn-global-20221102-3840x2160.png",
                "url": "https://s3-us-east-2.amazonaws.com/keydemand-bucket/property/6433f83193664ef4f2719264/1681127503727-bo_2022_cep_mh_xfn_global_20221102_3840x2160.png",
                "viewUrl": "https://keydemand-bucket.s3.us-east-2.amazonaws.com/property/6433f83193664ef4f2719264/1681127503727-bo_2022_cep_mh_xfn_global_20221102_3840x2160.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPHUOKLR7PTAS5UJ%2F20230412%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20230412T074839Z&X-Amz-Expires=28800&X-Amz-Signature=5463a00a0d405ba54d22d59d503c59c7be3da9b2bccdf8788a269445a96f27d9&X-Amz-SignedHeaders=host",
                "type": "image"
              }
            ]
          },
          "isActive": true,
          "responses": 1,
          "featuredProperty": false,
          "propertyTypeId": {
            "_id": "63bfad83d53152adb93ea00c",
            "name": "Office space"
          },
          "transactionType": "Lease",
          "location": "Coimbatore",
          "microLocation": {
            "_id": "642ecca84de15909bc5eaad0",
            "description": "Avinashi Road, Chinniyampalayam, Coimbatore, Tamil Nadu",
            "name": "Avinashi Road, Chinniyampalayam, Coimbatore, Tamil Nadu"
          },
          "propertyName": "RTE",
          "projectDeveloper": "excello",
          "carpetArea": 50,
          "builtupArea": 100,
          "rentPsf": 100,
          "possession": 1,
          "deposit": 1,
          "developerId": {
            "_id": "64228dbf73fc3586e44f4c9d",
            "name": "excello"
          },
          "userId": {
            "_id": "6422955c73fc3586e44f51b8",
            "name": "arun"
          },
          "customerType": "Developer",
          "createdAt": "2023-04-10T11:52:25.835Z",
          "additionalDetails": {
            "floor": "20",
            "propertyAge": 5,
            "bikeParking": "No information available",
            "carParking": "No information available",
            "washRooms": 1,
            "idealFor": [
              {
                "_id": "63c0f735e611894420fbcbbc",
                "isActive": true,
                "name": "Engineering",
                "label": "Engineering",
                "value": "63c0f735e611894420fbcbbc"
              }
            ]
          },
          "productId": {
            "prefix": "O",
            "number": 407,
            "display": "OP0407"
          },
          "publishedDate": "2023-04-10T11:52:25.835Z",
          "timeSince": "43 hours"
        }
      ]);
    const getBrandData = async () => {
       
        let url = `/demand/getproperties/${demandId}`;
        const res = await getData(url, {}, {});
        if (res.status) {
            // setProptyData(res.data.properties);
            
        }
        
        

    };
    useEffect(() => {
        getBrandData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
   

    const onClickView = (property) =>{
        
        navigate('/profile/view-response/view-property' , {state: {propertyId: property._id, location:property.location , key:"responseView"}})
    }
    const btnList = [
      {
        name: "View response",
        onClick: (property) => onClickView(property)
      }
    ]

    return <div className="mt-5">
        <h4 className="text-heading mb-4">Responses</h4>
        {propertyData.length > 0 ? 
    <ResposePropertyCard
    btnList={btnList}
    propertyData={propertyData}
    relevent={true}
    response={false}
    demandStatus={true}
   /> : <center>No reponses yet</center>    
    }
    </div>;
};
export default ResponseProperty;

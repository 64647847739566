import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

// components
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import Search from "../../../components/Search/search";
import {
  AccessDenied,
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  handleRmDashboardResponse,
  switchToUsers,
} from "../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../assets/images/switchuser.png";
import CrossSign from "../../../assets/images/CrossSign.svg";
import Loader from "../../../components/Loader";
import SwitchAccountPopup from "../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ListResponseTable = ({ filterData, trigger, tableType }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
 
  // const newResponses = searchParams.get("filter") === "new";
  const newResponses = tableType.includes("New");
  const [search, setSearch] = useState("");
  const [listType, setListType] = useState(
    newResponses ? "new" : "all"
  );
  const [filters, setFilters] = useState({
    sort: "",
    submittedBy: "",
    supplierType: "",
  });

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  const handleAccountSwitch = async (id) => {
    const data = {
      customerId: id,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      console.log({res});
      let openUrl = process.env.REACT_APP_SWITCH_URL;
      if (res.status) {
        window.open(`${openUrl}/${res?.data?.token}`);
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };

  const handleChangeResponseStatus = async (row) => {
    const url = `/property/updatePropertyResponse/${row._id}`;
    const res = await handleRmDashboardResponse(url);
    if(res?.status) {
      getTableData(false)
    }
  }

  let columnsBrand = [
    {
      text: "Timestamp",
      dataField: "createdAt",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row?.createdAt).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: "Brand Name",
      dataField: "brandName",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row?.demandId?.brandName}</div>;
      },
    },
    {
      text: "POC Name",
      dataField: "pocName",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <>
            {/* {newResponses ? (
              <div
                className="hyperlinkText"
                onClick={(e) => {
                  e.preventDefault();
                  handleAccountSwitch(row?.demandId?.userId?._id);
                }}
              >
                {row?.demandId?.userId?.name}
              </div>
            ) : (
              <div>{row?.demandId?.userId?.name}</div>
            )} */}
            <div>{row?.demandId?.userId?.name}</div>
          </>
        );
      },
    },
    {
      text: "Demand ID",
      dataField: "demandId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className={`${
              row?.demandId?.productId?.display && "hyperlinkText"
            }`}
            onClick={(e) => {
              if (row?.demandId?.productId?.display) {
                navigate(
                  "DemandDetailView/" + row?.demandId?.productId?.display, {
                    state: { isNew:  newResponses},
                  }
                );
              }
            }}
          >
            {row?.demandId?.productId?.display
              ? row?.demandId?.productId?.display
              : "-"}
          </div>
        );
      },
    },
    {
      text: "Submitted By",
      dataField: "submittedBy",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row?.submittedBy?.name}</div>;
      },
    },
    {
      text: "Supplier Type",
      dataField: "supplierType",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row?.propertyId?.customerType}</div>;
      },
    },
    {
      text: "Property ID",
      dataField: "propertyId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className={`${
              row?.propertyId?.productId?.display && "hyperlinkText"
            }`}
            onClick={(e) => {
              if (row?.propertyId?.productId?.display) {
                navigate(
                  "propertyDetailView/" + row?.propertyId?.productId?.display, {
                    state: { isNew:  newResponses},
                  }
                );
              }
            }}
          >
            {row?.propertyId?.productId?.display
              ? row?.propertyId?.productId?.display
              : "-"}
          </div>
        );
      },
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return (
    //       <>
    //         {newResponses ? (
    //           <div className="db-number-card-container">
    //             <img
    //               style={{ width: "20px", height: "20px" }}
    //               alt="cross"
    //               src={CrossSign}
    //             />
    //           </div>
    //         ) : (
    //           <div className="db-number-card-container">
    //             {getCurrentUser()?.role[0]?.name === "admin" ||
    //             getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
    //               <img
    //                 style={{ cursor: "pointer", width: "20px", height: "20px" }}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   handleAccountSwitch(row?.demandId?.userId?._id);
    //                 }}
    //                 alt="switch"
    //                 src={switchuser}
    //               />
    //             ) : (
    //               ""
    //             )}
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rmName",
        formatter: (cellContent, row, rowIndex) => {
          let name = row?.demandId?.rmData?.name;
          return <div>{name ? name : "-"}</div>;
        },
      },
    ];
    columnsBrand.splice(7, 0, ...rmData);
  }

  if (newResponses) {
    const actionCol = [
      {
        dataField: "action",
        text: "Action",
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div className="db-number-card-container">
              <img
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                alt="cross"
                src={CrossSign}
                onClick={() => {
                  handleChangeResponseStatus(row)
                }}
              />
            </div>
          );
        },
      },
    ];
    columnsBrand.splice(8, 0, ...actionCol);
  }
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }

    let url = `/property/getPropertyResponseList?type=${listType}&pageNo=${offSet}&limit=${perPage}`;
    if (search.length > 2) {
      url += `&search=${search}`;
    }
    if (filters.sort) {
      url += `&sort=${filters.sort}`;
    }
    if (filters.submittedBy) {
      url += `&submittedBy=${filters.submittedBy}`;
    }
    if (filters.supplierType) {
      url += `&supplierType=${filters.supplierType}`;
    }

    let cusomerData = await getData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data.totalResponseCount / perPage));
      setTotalCount(data.totalResponseCount);
      setTableData(data.responseList);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  let tableProps = {
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "RmDashboard",
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [perPage, offSet, search, filters, listType]);

  // useEffect(() => {
  //   getTableData(false);
  // }, [trigger]);

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];
  let supplierTypeSortValues = [
    {
      name: "Agent",
      _id: "Agent",
    },
    {
      name: "Developer",
      _id: "Developer",
    },
  ];

  const onClickChangeFilter = (e) => {
    let filteredValue = e.target.value;
    setFilters((prev) => ({ ...prev, [e.target.name]: filteredValue }));
    setOffset(1);
  };

  if (loginPermission?.includes(menuId?.enterpriseBuyerDashboard)) {
    return (
      <>
        {loading && <Loader className={"fixed"} />}
        <div className="db-filter-container">
          <Row className=" filter-select">
            <>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Posted On"
                  options={customerCreatedSortValues}
                  name={"sort"}
                  value={filters.sort}
                  onChange={onClickChangeFilter}
                  // isHideSelect
                />
              </Col>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Supplier Type"
                  options={supplierTypeSortValues}
                  name={"supplierType"}
                  value={filters.supplierType}
                  onChange={onClickChangeFilter}
                  // isHideSelect
                />
              </Col>
            </>
          </Row>
          <Row className=" filter-search-inbox">
            <Col md={6} sm={7} className="d-flex align-items-start">
              <Search onChange={handleSearch} value={search} />
            </Col>
          </Row>
        </div>
        <MainTable {...tableProps} trigger={trigger} />
        <SwitchAccountPopup
          show={switchModal}
          onClose={() => setSwitchModal(false)}
          data={pocs}
          companyId={companyId}
        />
      </>
    );
  } else {
    return (
      <AccessDenied />
    )
  }

  
};
export default ListResponseTable;

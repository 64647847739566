import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { AppContext } from "../../../../App";
import { getData, getPlanData, postImportData, postImportFlexData } from "../../../../api";
import Button from "../../../../components/Form/Button";
import ImportCsv from "../../../../components/ImportExport/Import";
import ImportErrorModal from "../../../../components/ImportExport/Import/importErrorModal";
import FormModal from "../../../../components/Modal";
import Tab from "../../../../components/Tab";
import { nameToLabel } from "../../../../utilities/commonFunction";
import { setScrollBackId } from "../../../../utilities/pageScroll";
import CompletedProperty from "./completedProperty";
import FeaturedProperty from "./featuredProperties";
import OngoingProperty from "./ongoingProperty";

const Property = (props) => {
  const {pageScroll,setPageScroll} = useContext(AppContext)
  const scrollStoreArray = [...pageScroll]  
  let tabkey = JSON.parse(localStorage.getItem("propTabKey"));
  const [key, setKey] = useState(tabkey ? tabkey : "ongoingProperties");
  const [importStatus, setImportStatus] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [error, setError] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [pocList, setPocList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [fileUoloaded, setFileUoloaded] = useState(null);
  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  
  const fromPage = useSelector((state) => state.login.parentPage);
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    addProperty: loginPermission?.includes(menuId?.addProperty) ? true : false,
    importsAndExports: loginPermission?.includes(menuId?.importsAndExports)
      ? true
      : false,
  };
  let customerData = JSON.parse(localStorage.getItem("customerData"));
  let navigate = useNavigate();
  const [propertyType, setPropertyType] = useState("");
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [pocCityList, setPocCityList] = useState([]);
  const [searchCityList, setSearchCityList] = useState([]);
const getpropertyType = async () => {
    let url = `/cityZones/checkZoneAvailability/${props.developerId}`;
    const response = await getData(url, {});
    const { status, data } = response;
    if (status === true) {
      if (data?.propertyType?.length > 0) {
        setPropertyTypeList(
          data?.propertyType?.map((item) => ({ name: item?.name }))
        );
      } else {
        setPropertyTypeList([]);
      }
    }
  };
  const getSearchCityList = async () => {
    let url = `/brand/cities/${props?.developerId}`;
    const response = await getData(url, {});
    const { status, data } = response;
    if (status === true) {
      let cities = nameToLabel(data?.city);
      setSearchCityList(cities);
    } else {
      setSearchCityList([]);
    }
  };
  const getPocList = async (id) => {
    let url = `/brand/getAllPoc/${id}`;
    const response = await getData(url, {}, {});
    const { statusCode, data } = response;
    if (statusCode === 200) {
      // setLoading(false)
      setPocList(data);
    } else {
      setPocList([]);
    }
  };
 
  const getPocCityList = async (id) => {
    let url = `/brand/poc/getcities/${props.developerId}/${id}`;
    const response = await getData(url, {}, {});
    const { statusCode, data } = response;
    if (statusCode === 200) {
      setPocCityList(data?.city);
    } else {
      setPocCityList([]);
    }
  };
  const onChangePoc = (id) => {
    if (id) {
      getPocCityList(id);
    } else {
      setPocCityList([]);
    }
  };
useEffect(() => {
    if (props.customerUserId) {
      getpropertyType();
      getSearchCityList();
    }
    if (props.customerUserId && props.type !== "agent") {
      getPocList(props.customerUserId);
    }
  }, []);
  const setScrollEvent = (id,filter) => {
    let scrollArray = setScrollBackId("myPageProperty", id, filter,"",scrollStoreArray);
    setPageScroll(scrollArray);
  };
  const handleAddpropterty = () => {
    localStorage.setItem("propertyFrom", JSON.stringify(props?.type));
    let url = `/property/checkUserPlan?developerId=${props.developerId}`;
    getPlanData(url, {}, {}).then((response) => {
      if (response.status) {
        let page =
          fromPage === "agent"
            ? "agentReportsCustomer"
            : fromPage === "seller"
            ? "sellerReportsCustomer"
            : "";
 let url = `/property/add?propertyId=0&developerId=${props.developerId}&developerType=${
          props?.type === "agent" ? "Agent" : "Developer"
        }&developerName=${customerData?.name}&fromPage=${page}`;
        navigate(url);
      } else {
        // alert(response.message)
      }
    });
  };
  const tabDetails = [
    {
      title: "Active Property",
      content: (
       <>
       {key === "ongoingProperties" && 
          <OngoingProperty
          access={props?.access}
          loading={props.loading}
          searchCityList={searchCityList}
          featuredPositioningStatus={props.featuredPositioningStatus}
          tabKey={key}
          importStatus={importStatus}
          id={props.developerId}
          image={props.image}
          fromTab={props?.type}
          setScrollEvent={setScrollEvent}
        />
       }
       </>
      ),
      eventKey: "ongoingProperties",
    },
    {
      title: "Inactive Property",
      content: (
        <>
        {key === "completedProperties" &&
        <CompletedProperty
        access={props?.access}
        loading={props.loading}
        searchCityList={searchCityList}
        tabKey={key}
        id={props.developerId}
        image={props.image}
        fromTab={props?.type}
        setScrollEvent={setScrollEvent}
      />
        }
        </>
      ),
      eventKey: "completedProperties",
    },
    {
      title: "Featured Property",
      content: (
       <>
       {key === "featuredProperties" &&
        <FeaturedProperty
        access={props?.access}
        loading={props.loading}
        searchCityList={searchCityList}
        featuredPositioningStatus={props.featuredPositioningStatus}
        tabKey={key}
        id={props.developerId}
        image={props.image}
        fromTab={props?.type}
        setScrollEvent={setScrollEvent}
      />
       }
       </>
      ),
      eventKey: "featuredProperties",
    },
  ];

  const onClickImportProperty = () => {
    setErrorObj("");
    setImportModal(true);
    setPropertyType("");
  };


  const onClickDownloadTemplate = () => {
    let url;
    url =
      props.type === "agent"
        ? "/template/downloadTemplate?type=agentProperty"
        : `/template/downloadTemplate?type=${
            propertyType === "Retail" ? "property" : "OfficeSpaceProperty"
          }`;
    getPlanData(url, {}, {}).then((response) => {
      let a = document.createElement("a");
      a.href = response?.data?.viewUrl;
      a.download = "Property-template.xlsx";
      a.click();
    });
  };
  const importFieldObject = ["city", "fileSelected", "propertyType"];
  const onClickImport = async (jsonArray, selectedObject) => {
    setImportStatus(false);

    let errObj = {};
    importFieldObject?.map((item) => {
      if (
        selectedObject[item] === "" ||
        Object.keys(selectedObject[item]).length === 0
      ) {
        errObj[item] = "This field is required";
      } else if (item === "location") {
        if (selectedObject["location"].description === "") {
          errObj[item] = "This field is required";
        } else if (errorObj?.location) {
          errObj[item] = errorObj?.location;
        }
      }
      return item;
    });
    if (
      props.type !== "agent" &&
      (!selectedObject?.userId || selectedObject?.userId === "")
    ) {
      errObj["userId"] = "This field is required";
    }

    if (Object.keys(errObj).length > 0) {
      setErrorObj({ ...errObj });
    } else {
     
      if (
        jsonArray.length === 0 ||
        Object.keys(JSON.parse(jsonArray)).length === 0 
      ) {
        errObj.fileSelected = "Please upload a CSV with data";
        setErrorObj({ ...errObj });
      } else {
        setErrorObj("");
        setBtnLoading(true);
        selectedObject.properties = JSON.parse(jsonArray);
        let data = { ...selectedObject };
        data.city = (selectedObject?.city || []).map((item) => item.label);
        delete data.fileSelected;
        delete data.userId;
        delete data.propertyType;
        let url = "";
        console.log({before:data})
        if (props.type !== "agent") {
          if(propertyType === "Flex Space") {
            let formData = new FormData();
            formData.append("file", fileUoloaded);
            formData.append("city", data?.city.join(', '));
            data = formData
            console.log({after: data})
            url = `/propertyImportAndExports/importFlexProperties/${props.developerId}/${selectedObject?.userId}/${propertyType}`;
          } else {
            url = `/propertyImportAndExports/${props.developerId}/${selectedObject?.userId}/${propertyType}`;
          }
        } else {
          url = `/propertyImportAndExports/${props.customerUserId}/${props.developerId}/${propertyType}`;
        }

        const response = propertyType === "Flex Space" ? await postImportFlexData(url, {}, data) : await postImportData(url, {}, data)

        
          if (response.status) {
            setBtnLoading(false);
            setImportStatus(true);
            setImportModal(false);
            setPocCityList([]);
          } else {
            if (typeof response.message === "string") {
              errObj.fileSelected = response.message;
              setErrorObj({ ...errObj });
              Swal.fire({
                // title: header,
                text: response.message,
                icon: "warning",
                timer: 3000,
                buttons: false,
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
              });
              setBtnLoading(false);
              Swal.fire({
                // title: header,
                text: response.message,
                icon: "warning",
                timer: 3000,
                buttons: false,
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
              });
            } else {
              setErrMsg(response.message);
              setError(true);
              setImportModal(false);
              setBtnLoading(false);
            }
          }
      }
    }
  };
  const importModalContent = () => {
    return (
      
        <ImportCsv
          customerCityData={pocCityList}
          type={props.type}
          errorObj={errorObj}
          setErrorObj={setErrorObj}
          loading={btnLoading}
          label={"Import Csv"}
          onClickImport={(jsonArray, selectedObject) =>
            onClickImport(jsonArray, selectedObject)
          }
          onClickDownloadTemplate={() => onClickDownloadTemplate()}
          pocList={props.type === "seller" ? pocList : []}
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          propertyTypeList={propertyTypeList}
          getPocCityList={onChangePoc}
          setFileUoloaded = {setFileUoloaded}
        />
     
    );
  };

  const closeImportModal = () => {
    setImportModal(false);
    setPocCityList([]);
  };
  const onClickErrorClose = () => {
    setError(false);
  };
  let sellerImport = props?.type === "seller" && props?.access?.sellerImportsAndExports
  let agentImport = props?.type === "agent" && props?.access?.agentImportsAndExports
  let sellerAddProperty = props?.type === "seller" && props?.access?.addSellerProperty
  let agentAddProperty = props?.type === "agent" && props?.access?.addAgentProperty
  return (
    <>
      {importModal && (
        <FormModal
          show={importModal}
          onClose={closeImportModal}
          heading={"Import Csv"}
          customer={false}
          children={importModalContent()}
          size={"md"}
        ></FormModal>
      )}
      {errMsg.length > 0 && (
        <FormModal
          show={error}
          onClose={() => onClickErrorClose()}
          heading={"Error"}
          children={<ImportErrorModal data={errMsg} />}
          size={"md"}
        />
      )}
      <div className="d-flex justify-content-end mb-4 btn-container">
        {props?.type !== "agent" ? (
          <Button
            className="btn-color-primary"
            disabled={sellerImport || agentImport ? false : true}
            onClick={onClickImportProperty}
          >
            Bulk Upload
          </Button>
        ) : 
          null
        }
​
        <Button
          className="btn-color-primary"
          onClick={() => handleAddpropterty()}
          disabled={sellerAddProperty || agentAddProperty ? false : true}
        >
          Add Property
        </Button>
      </div>
      <Tab
        tabDetails={tabDetails}
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
        }}
      />
    </>
  );
};
export default Property;
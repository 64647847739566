import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavBar from "../../components/Navbar";
import "./style.scss";
import Testmonials from "./testmonials";

import { Form } from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";
import Button from "../../components/Form/Button";
import Loader from "../../components/Loader";
import Tab from "../../components/Tab/index";
import { AddBanner, AdminWarningPopup } from "./addBanner";
import FormModal from "./warning";

import { AccessDenied, deleteData, getData, putData } from "../../api";

import MainTable from "../../components/Table";
import Clientele from "./clientele/clientele";

const ContentManagement = () => {
  const [contentDatas, setContentdatas] = useState("");
  const [bannerData, setbannerdata] = useState([]);
  const [bannerToggle, setBannerToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listValue, setListValue] = useState([]);
  const [contentId, setContentId] = useState("");
  const [stat, setStat] = useState(false);
  const [action, setAction] = useState("Add");
  const [bannerImageId, setBannerImageId] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [editValue, setEditValue] = useState({
    brandId: "",
    image: "",
    brandName: "",
    type: "Developer",
  });
  const [showBanner, setShowBanner] = useState(false);
  const loginPermission = useSelector((state) => state.login.userPermission);
  const [key, setKey] = useState("content");
  const [modalOpen, setModalOpen] = useState(false);
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    add: loginPermission?.includes(menuId?.addContentManagement) ? true : false,
    edit: loginPermission?.includes(menuId?.editContentManagement)
      ? true
      : false,
    delete: loginPermission?.includes(menuId?.deleteContentManagement)
      ? true
      : false,
    enable: loginPermission?.includes(menuId?.contentManagementEnableOrDisable)
      ? true
      : false,
  };
  const listData = [
    {
      sno: 1,
      data: "Site down",
      _id: "siteDown",
      status: "siteDown",
    },
    {
      sno: 2,
      data: "Top associated brand",
      _id: "topAssociatedBrand",
      status: "topAssociatedBrandStatus",
    },
    {
      sno: 3,
      data: "Top associated developer",
      _id: "topAssociatedDevelopers",
      status: "topAssociatedDeveloperStatus",
    },
    {
      sno: 4,
      data: "Featured property",
      _id: "featuredProperty",
      status: "featuredPropertyStatus",
    },
    {
      sno: 5,
      data: "Featured demand",
      _id: "featuredDemand",
      status: "featuredDemandStatus",
    },
    {
      sno: 6,
      data: "Testimonials",
      _id: "testimonial",
      status: "testimonialStatus",
    },
    {
      sno: 7,
      data: "Clientele",
      _id: "clientele",
      status: "clienteleStatus",
    },
  ];

  const getContentdata = async () => {
    setLoading(true);
    let url = "/contentManagement/";
    const getcontentDatas = await getData(url, {}, {});
    const { data, status } = getcontentDatas;
    if (status) {
      setLoading(false);
      setContentdatas(data);
      setbannerdata(data?.bannerImages);
      // setbannertoggle(data?.siteDown);

      listData.map((item) => {
        if (data[item.status]) {
          item.statusValue = data[item.status];
        }
        return item;
      });
      setListValue([...listData]);

      localStorage.setItem("contentManagementID", JSON.stringify(data?._id));
    } else {
      setLoading(false);
    }
  };

  const onActiveStatusChange = (id, stats) => {
    setModalOpen(true);
    setContentId(id);
    setStat(stats);
    setBannerToggle(stats);
  };
  const triggerToggleApi = async () => {
    let url = `/contentManagement/changeStatus/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}?type=${contentId}`;
    let dataFormat = {
      status: !stat,
    };
    const newData = await putData(url, {}, dataFormat);
    const { status } = newData;
    if (status) {
      setModalOpen(false);
      getContentdata();
    }
  };
  const clickOk = () => {
    triggerToggleApi();
  };
  const clickCancel = () => {
    setModalOpen(false);
  };

  const handelShowAddBanner = () => {
    setShowBanner(true);
    setAction("Add");
  };
  const handelShowEditBanner = (row) => {
    setEditValue({
      brandId: row.brandId,
      image: row.image,
      brandName: row.brandName,
      type: row?.type,
      _id: row?._id,
      viewUrl: row?.imageViewUrl,
    });

    setShowBanner(true);
    setAction("Edit");
  };

  const handelCloseAddBanner = (value) => {
    setShowBanner(false);
    if (value === "updated") {
      getContentdata();
    }
  };

  const handelShowConfirmation = (id) => {
    setConfirmation(true);
    setBannerImageId(id);
  };
  const handelCloseConfirmation = () => {
    setConfirmation(false);
  };
  function userButtonFormatterSeller(cell, row, rowIndex) {
    return (
      <Form.Check
        type="switch"
        id={`custom-switch_${rowIndex}}`}
        checked={row?.statusValue ? row?.statusValue : false}
        onChange={(e) =>
          onActiveStatusChange(
            row._id,
            row?.statusValue ? row?.statusValue : false
          )
        }
        disabled={access?.enable ? false : true}
      />
    );
  }
  function userButtonFormatterBanner(cell, row, rowIndex) {
    return (
      <>
        <Button
          className="table-btn p-0"
          disabled={access?.edit ? false : true}
        >
          <FaPen
            className="icon-pointer cursor-pointer"
            onClick={() => handelShowEditBanner(row)}
          />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={access?.delete ? false : true}
        >
          <FaTrash
            className="icon-pointer cursor-pointer"
            onClick={() => handelShowConfirmation(row._id)}
          />
        </Button>
      </>
    );
  }

  const headerDetail = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "data",
      text: "Landing page contents",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterSeller,
    },
  ];
  const bannerHeaderDetail = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "brandName",
      text: "Brand Name",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterBanner,
    },
  ];

  const tableProps = {
    data: listValue,
    columns: headerDetail,
  };
  const bannerTableProps = {
    data: bannerData,
    columns: bannerHeaderDetail,
  };

  const deleteBannerImage = async () => {
    let url = `/contentManagement/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}`;
    let dataFormat = {
      bannerImageId,
    };
    const newData = await deleteData(url, {}, dataFormat);
    const { status } = newData;
    if (status) {
      getContentdata();
      handelCloseConfirmation();
    }
  };

  const tabDetails = [
    {
      title: "Content",
      content: <MainTable {...tableProps} />,
      eventKey: "content",
    },
    {
      title: "Testimonial",
      content: <Testmonials access={access} apiData={contentDatas} />,
      eventKey: "testimonial",
    },
    {
      title: "Banner Images",
      content: (
        <>
          <div className="d-flex justify-content-between align-items-center mt-5 mb-4 mobileFlexWrapper">
            <div className="brand_text">
              <p className="contentManagementText">Banner Management</p>
            </div>
            <div>
              &nbsp;&nbsp;&nbsp;
              <Button
                className="btn-color-primary"
                disabled={
                  bannerData?.length >= 5 || !access?.add ? true : false
                }
                onClick={() => handelShowAddBanner()}
              >
                Add
              </Button>
              &nbsp;
            </div>
          </div>
          <MainTable {...bannerTableProps} />

          {showBanner && (
            <AddBanner
              label={action}
              onClose={handelCloseAddBanner}
              show={showBanner}
              editValue={editValue}
            />
          )}
        </>
      ),
      eventKey: "bannerImages",
    },
    {
      title: "Clientele",
      content: <Clientele access={access} />,
      eventKey: "clientele",
    },
  ];
  useEffect(() => {
    getContentdata();
  }, []);
  return (
    <>
      {loading && <Loader className={"fixed"} />}
      {loginPermission?.includes(menuId?.contentManagement) ? (
        <div>
          <NavBar />
          {/* <DashboardLayout> */}
          {modalOpen && (
            <FormModal
              open={modalOpen}
              close={setModalOpen}
              onClickOk={clickOk}
              onClickCancel={clickCancel}
              types={bannerToggle}
            />
          )}
          <div
            // style={{ backgroundColor: "#FFFFFF", padding: "40px" }}
            className="content_fullcards"
          >
            <Tab
              tabDetails={tabDetails}
              activeKey={key}
              onSelect={(k) => {
                setKey(k);
              }}
            />
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}

      {confirmation && (
        <AdminWarningPopup
          onClose={handelCloseConfirmation}
          show={true}
          message={"Are you sure you want delete this banner?"}
          warningYesClick={() => deleteBannerImage()}
        />
      )}
    </>
  );
};

export default ContentManagement;

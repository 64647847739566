import { useEffect, useState } from "react";
//components
import { Form } from "react-bootstrap";
import Button from "../../components/Form/Button";
import Select from "../../components/Form/Select";
import Loader from "../../components/Loader";
import FormModal from "../../components/Modal";
import Search from "../../components/Search/search";
//fetch
import { getData } from "../../api/index";

//style
import "./style.scss";

export function AssignCustomer({
  onClose,
  show,
  adminId,
  roleDesignation,
  onAssignCustomer,
}) {
  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [validated, setValidated] = useState(false);

  const getCustomerList = async () => {
    let url = `/brand/?pageNo=0&limit=0&role=${roleDesignation}&adminId=${adminId}`;
    setIsLoading(true);
    const reponse = await getData(url, {}, {});
    const { statusCode, data } = reponse;
    if (statusCode === 200) {
      setIsLoading(false);
      setCustomerList(data?.customerList);
      // let customerlist = []

      let customerlist = data?.customerList.map((item) => {
        return {
          _id: item._id,
          name: item.name
            ? `${item.name}(${item.type})`
            : `${item.contactPerson}(${item.type})`,
        };
      });
      setCustomerList(customerlist);
    } else {
      setIsLoading(false);
      setCustomerList([]);
    }
  };
  useEffect(() => {
    getCustomerList();
  }, []);
  const onChange = (value) => {
    setCustomerId(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setErrorMessage("This field is required");
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      onAssignCustomer(customerId, adminId);
    }
  };
  return (
    <FormModal
      onClose={onClose}
      show={show}
      heading={"Assign a Customer"}
      size={"md"}
      loading={isLoading}
      loader="fixed"
    >
      <div>
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="client mt-2">
            <Select
              errorMessage={errorMessage}
              label={"Customer"}
              name={"customer"}
              options={customerList}
              isInvalid={!!errorMessage}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          </div>
          <div className="d-flex justify-content-end  gap-3 ">
            <Button className={"btn-outline-color-primary"} onClick={onClose}>
              Cancel
            </Button>

            <Button
              disabled={customerId === ""}
              type={"submit"}
              className="btn-color-primary"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
      {isLoading && <Loader className={"fixed"} />}
    </FormModal>
  );
}

export const ViewCustomer = ({
  onClose,
  show,
  adminDetails,
  handelSelectRm,
  adminId,
  onClickRmChange,
  access,
}) => {
  const adminRole =
    adminDetails.designation === "Relationship Manager" ? "RM" : "Uploader";
  const [isLoading, setIsLoading] = useState(false);
  const [rmcustomerList, setRmCustomerList] = useState([]);
  const [search, setSearch] = useState("");
  const getRmCustomerList = async (userId) => {
    setIsLoading(true);
    let url = `/brand/customerList/${userId}`;
    if (search) {
      url += `?search=${search}`;
    }
    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setIsLoading(false);
      setRmCustomerList(data);
    } else {
      // alert(message)
      setRmCustomerList([]);
      setIsLoading(false);
    }
  };
  const handelSearchCustomer = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getRmCustomerList(adminId, search);
  }, [search]);

  return (
   
      <FormModal
        onClose={onClose}
        show={show}
        heading={"Customer"}
        size={"md"}
        loading={false}
        loader="fixed"
      >
        <div className=" customer-list">
          <div className="customer-list__name">{adminDetails.name}</div>
          <div className="customer-list__role">{adminDetails.designation}</div>
          <div className="w-75 my-2">
            {/* <Input type="search" name="customerSearch" onChange={handelSearchCustomer} value={search} placeholder="Search" /> */}
            <Search onChange={handelSearchCustomer} />
          </div>
          {rmcustomerList.length > 0 ? (
            <>
              <div className="customer__change">
                {rmcustomerList.map((item, index) => {
                  return (
                    <>
                      <div
                        className="d-flex justify-content-between p-2 py-3 customer__change_rm"
                        key={index}
                      >
                        <div className="change_rm__name">
                          {`${item.name} (${item?.type})`}{" "}
                        </div>
                        <Button
                          className="change_rm__action cursor-pointer table-btn p-0"
                          onClick={ () =>
                                  onClickRmChange(
                                    item._id,
                                    adminId,
                                    adminDetails.designation,
                                    adminRole
                                  )
                          }
                          disabled={access.adminChangeRm ? false : true}
                        >
                          {"Change " + adminRole}
                        </Button>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="text-center">No Data Found</div>
            </>
          )}
        </div>
        {isLoading && <Loader className={"fixed"} />}
      </FormModal>
  
  );
};
export const ViewEnquiry = ({ onClose, show, adminDetails, adminId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [enquiryList, setEnquiryList] = useState([]);
  const [search, setSearch] = useState("");
  const getEnquiryList = async (adminId) => {
    setIsLoading(true);
    let url = `/enquiry/getAssignedEnquiry/${adminId}`;
    if (search) {
      url += `?search=${search}`;
    }
    const getList = await getData(url, {}, {});
    const { statusCode,  data } = getList;
    if (statusCode === 200) {
      setIsLoading(false);
      setEnquiryList(data.enquiries);
    } else {
      // alert(message)
      setEnquiryList([]);
      setIsLoading(false);
    }
  };
  const handelSearchCustomer = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getEnquiryList(adminId, search);
  }, [search]);

  return (
    
      <FormModal
        onClose={onClose}
        show={show}
        heading={"Customer"}
        size={"md"}
        loading={false}
        loader="fixed"
      >
        <div className=" customer-list">
          <div className="customer-list__name">{adminDetails.name}</div>
          <div className="customer-list__role">{adminDetails.designation}</div>
          <div className="w-75 my-2">
            {/* <Input type="search" name="customerSearch" onChange={handelSearchCustomer} value={search} placeholder="Search" /> */}
            <Search onChange={handelSearchCustomer} />
          </div>
          {enquiryList.length > 0 ? (
           
              <div className="customer__change">
                {enquiryList.map((item, index) => {
                  return (
                    <>
                      <div
                        className="d-flex justify-content-between p-2 py-3 customer__change_rm"
                        key={index}
                      >
                        <div className="change_rm__name">
                          {item.companyName}{" "}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
           
          ) : (
            
              <div className="text-center">No Data Found</div>
          
          )}
        </div>
        {isLoading && <Loader className={"fixed"} />}
      </FormModal>
   
  );
};
export const ChangeRm = ({
  onClose,
  show,
  roleDesignation,
  adminId,
  customerId,
  onSelectRmChange,
}) => {
  
  const [isLoading] = useState(false);
  const [rmList, setRmList] = useState([]);
  const [rmId, setRmId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const onSubmit = () => {
    setErrorMessage("");
    let rmObj = rmList?.filter((el) => rmId === el?._id);
    let value = {
      name: rmObj[0]?.name ? rmObj[0]?.name : "",
      _id: rmObj[0]?._id ? rmObj[0]?._id : "",
    };
    onSelectRmChange(customerId, value, true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      onSubmit();
    }
  };
  const getRelationshipManager = async () => {
    let url = `/user/getAllSubAdmin?pageNo=0&limit=0&role=${roleDesignation}&adminId=${adminId}&isActive=true`;
    const apiDatas = await getData(url);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      setRmList(data.subAdminList);
    } else {
      setRmList([]);
    }
  };
  const onChange = (value) => {
    let rmID;
    rmList.find((el) => (el._id === value ? (rmID = el._id) : null));

    setRmId(rmID);
  };

  useEffect(() => {
    getRelationshipManager();
  }, []);

  return (
   
      <FormModal
        onClose={onClose}
        show={show}
        heading={"Change " + roleDesignation}
        size={"md"}
        loading={false}
        loader="fixed"
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="client mt-2">
            <Select
              errorMessage={errorMessage}
              label={"Change " + roleDesignation}
              name={"Select"}
              options={rmList}
              isInvalid={!!errorMessage}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          </div>

          <div className="d-flex justify-content-end  gap-3 ">
            <Button className={"btn-outline-color-primary"} onClick={onClose}>
              Cancel
            </Button>
            <Button type={"submit"} className="btn-color-primary">
              Save
            </Button>
          </div>
        </Form>

        {isLoading && <Loader className={"fixed"} />}
      </FormModal>
   
  );
};

export const AdminWarningPopup = ({
  onClose,
  show,
  message,
  warningYesClick,
  title
}) => {
  return (
    <FormModal
      onClose={onClose}
      show={show}
      heading={title || "Warning"}
      size={"md"}
      loading={false}
      loader="fixed"
    >
      <p>{message}</p>
      <div className="btn-container">
        <Button className={"btn-outline-color-primary"} onClick={onClose}>
          No
        </Button>
        <Button onClick={warningYesClick} className="btn-color-primary">
          Yes
        </Button>
      </div>
    </FormModal>
  );
};

import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import TextArea from "../../components/Form/TextArea";
import Checkbox from "../../components/Form/Checkbox";
import GoogleAutoComplete from "../../components/autoSearch/googleAutoSearch";
import MultiSelect from "../../components/select/dropDown";
import "./style.scss"

export const RenderInput = ({
  value,
  name,
  label,
  onChange,
  isInvalid,
  errorMessage,
  type,
  checkBoxValue,
  onChangeCheckBox,
  placeholder,
  disabled,
  required,
  checboxDisabled,
  sufix,
  prefix,
  onFocus,
  onBlur,
  hideCheckbox

}) => {
  return (
    <div className="d-flex align-items-center w-100">
      {hideCheckbox?null:<Checkbox
        name={name}
        value={checkBoxValue}
        onChange={onChangeCheckBox}
        label=" "
        size="m-0"
        disabled={checboxDisabled}
      />}
      <Input
        label={label}
        type={type ? type : "text"}
        name={name}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
        errorMessage={errorMessage}
        placeholder={placeholder}
        disabled={disabled}
        size={"w-100"}
        required={required}
        prefix={prefix}
        sufix={sufix}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};
export const RenderRangeInput = ({
  value,
  name,
  onChange,
  isInvalid,
  errorMessage,
  type,
  placeholder,
  disabled,
  required,
  sufix,
  prefix,
  onFocus,
  onBlur,
}) => {
  return (
      <Input
        label={""}
        type={type ? type : "text"}
        name={name}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
        errorMessage={errorMessage}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        sufix={sufix}
        prefix={prefix}
        onFocus={onFocus}
        onBlur={onBlur}
        size="w-100 p-0"
      />
  );
};

export const RenderSelect = ({
  value,
  name,
  onChange,
  options,
  isInvalid,
  errorMessage,
  placeholder,
  checkBoxValue,
  onChangeCheckBox,
  disabled,
  label,
  requried,
}) => {


  return (
      <div className="d-flex align-items-center w-100">
        <Checkbox
          name={name}
          value={checkBoxValue}
          onChange={onChangeCheckBox}
          label=" "
          size="m-0"
          disabled={disabled}
        />
        <Select
          label={label}
          value={value}
          onChange={onChange}
          options={options}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          placeholder={placeholder}
          disabled={disabled}
          size={"w-100"}
          required={requried}
        />
      </div>
  );
};
export const RenderGoogleLocation = ({
  name,
  handleSelectLocation,
  cityValue,
  value,
  isInvalid,
  errorMessage,
  checkBoxValue,
  onChangeCheckBox,
  disabled,
  label,
  requried,
  isMulti,
  multiselectValue,
  selectMultipleValue,
  handleBlur
}) => {

  return (
      <div className="d-flex align-items-start w-100">
        <Checkbox
          name={name}
          value={checkBoxValue}
          onChange={onChangeCheckBox}
          label=" "
          size="mt-4 pt-1"
          disabled={disabled}
        />
        <GoogleAutoComplete
          cityValue={cityValue}
          label={label}
          handleSelectLocation={handleSelectLocation}
          value={value}
          errorMessage={errorMessage}
          isInvalid={isInvalid}
          disabled={disabled}
          isMulti={isMulti}
          multiselectValue={isMulti ? multiselectValue : []}
          selectMultipleValue={isMulti ? selectMultipleValue : () => {}}
          requried={requried}
          handleBlur={handleBlur}
        />
      </div>
  );
};

export const RenderTextArea = ({
  value,
  name,
  label,
  onChange,
  isInvalid,
  errorMessage,
  checkBoxValue,
  onChangeCheckBox,
  placeholder,
  required,
  checboxDisabled,
}) => {
  return (
    <div className="d-flex align-items-center w-100">
      <Checkbox
        name={name}
        value={checkBoxValue}
        onChange={onChangeCheckBox}
        label=" "
        size="m-0"
        disabled={checboxDisabled}
      />
      <TextArea
        label={label}
         required={required}
         as="textarea"
         placeholder={placeholder}
         type={"text"}
         name={name}
         id={name}
         value={value}
         isInvalid={isInvalid}
         className={"form-control"}
         onChange={onChange}
         errorMessage={errorMessage}
         size={"w-100 "}
      />
    </div>
  );
};

export const RenderMultiSelect = ({
  value,
  name,
  onChange,
  options,
  errorMessage,
  checkBoxValue,
  onChangeCheckBox,
  disabled,
  label,
  
  isMulti=true,

}) => {


  return (
      <div className="d-flex align-items-start w-100">
        <Checkbox
          name={name}
          value={checkBoxValue}
          onChange={onChangeCheckBox}
          label=" "
          size="mt-4 pt-2"
          disabled={disabled}
        />
        <MultiSelect
                disabled={disabled}
                errormessage={errorMessage}
                onChange={onChange}
                isMulti={isMulti}
                options={options}
                value={value}              
                lable={label}
                size="w-100"
                />
      </div>
  );
};

import FileViewer from "react-file-viewer";

export default function PdfView(props) {
 
  const file = props.url;
  const type = "pdf";

  const onError = () => {};
  return (
    <div className="App">
      <FileViewer
        fileType={type}
        filePath={file}
        onError={onError}
        height={"100%"}
      />
    </div>
  );
}

import  { useEffect,useState } from "react";
import FormModal from "../../../../components/Modal";
import { Container, Row, Col } from "react-bootstrap";
import { postGetData } from "../../../../api";
import Loader from "../../../../components/Loader";

export function ViewCities({ onClose, show, initialLoading, heading, value }) {
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStates = () => {
    setLoading(true);
    let url ="/country/getStates";
    let listedId = [value?.value];
    let payload = { regionIds: listedId };
    postGetData(url, {}, payload).then((response) => {
      if (response?.status) {
        setLoading(false);
        setCityList(response?.data);
      } else {
        setLoading(false);
        setCityList([]);
      }
    });
  };

  useEffect(() => {
    if (value?.value) {
      getStates();
    }
  }, []);

  return (
    <div>
      <FormModal
        onClose={onClose}
        show={show}
        heading={value?.label}
        size={"lg"}
        loading={initialLoading}
        loader="fixed"
      >
        <Container fluid>
          <Row>
            {cityList?.map((item,i) => {
              return (
              
                  <Col md="4" sm="12" className="p-2 f-18 fw-500" key={i}>
                    {item?.name}
                  </Col>
              );
            })}
          </Row>
        </Container>
        {loading ? <Loader classname="fixed" /> : null}
      </FormModal>
    </div>
  );
}






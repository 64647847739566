import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { AiTwotoneDelete } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { IoAddCircleOutline } from "react-icons/io5";
import Slider from "react-slick";
import { deleteData, getData, putData } from "../../api";
import Button from "../../components/Form/Button";
import Select from "../../components/Form/Select/index";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import MultiSelect from "../../components/select/dropDown";
import CardComponent from "./cardComponent";
import "./contentManagement.css";
import "./style.scss";
import FormModal from "./warning";

function Testmonials(props) {
  const [handleModal, setHandleModal] = useState(false);
  const [handlePost, setHandlePost] = useState(false);
  const [getApiData, setGetApiData] = useState("");
  const [brandListData, setBrandListData] = useState([]);
  const [testmonialsData, settestmonialsdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState("Add");
  const [errorMessage, setErrorMessage] = useState({});
  let dummy = {
    brandName: "",
    brandId: "",
    message: "",
    type: "",
  };
  const [blogPost, setBlogPost] = useState(dummy);
  const [bannerToggle, setbannertoggle] = useState(false);
  useEffect(() => {
    settestmonialsdata(props.apiData?.testimonials);
    setbannertoggle(props?.apiData?.testimonialStatus);
  }, [props.apiData]);
  useEffect(() => {
    if (handlePost) {
      setErrorMessage({});
    } else {
      setBlogPost(dummy);
    }
  }, [handlePost]);

  const getContentdata = async () => {
    setLoading(true);
    let url = "/contentManagement/";
    const getcontentDatas = await getData(url, {}, {});
    const { data, status } = getcontentDatas;
    if (status) {
      setLoading(false);
      setGetApiData(data?.testimonials);
      settestmonialsdata(data?.testimonials);
      setbannertoggle(data?.testimonialStatus);
      localStorage.setItem("contentManagementID", JSON.stringify(data?._id));
    }
  };
  const triggerToggleApi = async () => {
    let url = `/contentManagement/changeStatus/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}?type=testimonial`;
    let dataFormat = {
      status: !bannerToggle,
    };
    const newData = await putData(url, {}, dataFormat);
    const { status } = newData;
    if (status) {
      setModalOpen(false);
      getContentdata();
    }
  };
  const handleChanges = (e) => {
    const { name, value } = e.target;
    delete errorMessage[name];
    setBlogPost({ ...blogPost, [name]: value });
  };
  const modalClick = () => {
    setHandlePost(true);
    setAction("Add");
  };

  const getBrandLIst = async () => {
    setLoading(true);
    let url = `/brand/?type=${blogPost?.type}&pageNo=0&limit=0`;
    const getbrand = await getData(url, {});
    const { data, status } = getbrand;
    if (status) {
      setLoading(false);
      let customerList = data?.customerList?.map((item) => {
        return {
          label: item?.name,
          value: item?._id,
        };
      });
      setBrandListData(customerList);
    }
  };

  const clickOk = () => {
    setbannertoggle(!bannerToggle);
    triggerToggleApi();
  };
  const clickCancel = () => {
    setModalOpen(false);
  };

  const checkError = (value) => {
    let err = {};
    if (!value.hasOwnProperty("brandName") || value.brandName?.length === 0) {
      err.brandName = "This Field is required";
    }

    if (!value.hasOwnProperty("message") || value.message?.length === 0) {
      err.message = "This Field is required";
    }

    if (!value.hasOwnProperty("type") || value.type?.length === 0) {
      err.type = "This Field is required";
    }
    return err;
  };
  const handleBlogchange = async (e) => {
    e.preventDefault();
    setLoading(true);
    let url = `/contentManagement/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}`;
    let copyData = { ...blogPost };
    delete copyData["imageViewUrl"];
    let datas = {
      testimonial: copyData,
    };
    const error = checkError(copyData);

    if (Object.keys(error).length > 0) {
      setErrorMessage(error);
      setLoading(false);
    } else {
      const handleApiBlogPost = await putData(url, {}, datas);
      const { status } = handleApiBlogPost;
      if (status) {
        setLoading(false);
        setHandlePost(false);
        getContentdata();
        setHandleModal(false);
      } else {
        setLoading(false);
      }
    }
  };
  const handleSelectdata = (data, key) => {
    delete errorMessage[key];
    if (key === "brandName") {
      const { label, value } = data;
      setBlogPost({
        ...blogPost,
        brandId: value,
        brandName: label,
      });
    } else if (key === "role") {
      delete errorMessage["type"];
      setBrandListData([]);
      setBlogPost({
        ...blogPost,
        type: data,
        brandId: "",
        brandName: "",
      });
    }
  };

  const handleEdit = (item) => {
    setHandlePost(true);
    setAction("Edit");
    setBlogPost({
      ...blogPost,
      brandId: item.brandId._id,
      brandName: item.brandName,
      message: item.message,
      type: item.type,
      _id: item._id,
    });
  };

  const deleteObj = async (id) => {
    const { _id } = id;
    let url = `/contentManagement/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}`;
    let all = {
      testimonialId: _id,
    };
    const apiData = await deleteData(url, {}, all);
    const { status } = apiData;
    if (status) {
      getContentdata();
    }
  };
  const PreviousBtn = (btnprops) => {
    const { className, onClick } = btnprops;
    return (
      <div className={className} onClick={onClick}>
        {/* <IoIosArrowBack style={{ color: "white" }} /> */}
      </div>
    );
  };
  const NextBtn = (btnprops) => {
    const { className, onClick } = btnprops;
    return (
      <div className={className} onClick={onClick}>
        {/* <IoIosArrowForward style={{ color: "white" }} /> */}
      </div>
    );
  };
  const conversion = (str) => {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  };

  let settings = {
    dots: true,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 5000,
    cssEase: "linear",
    // prevArrow:<PreviousBtn />,
    // nextArrow:<NextBtn />
  };

  let customerRole = [
    {
      name: "Brand",
    },
    {
      name: "Developer",
    },
    {
      name: "Agent",
    },
  ];
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleBlogchange(event);
    }
  };
  useEffect(() => {
    if (blogPost?.type) {
      getBrandLIst();
    }
  }, [blogPost?.type]);

  return (
    <div className="testWrappers">
      {modalOpen && (
        <FormModal
          open={modalOpen}
          close={setModalOpen}
          onClickOk={clickOk}
          onClickCancel={clickCancel}
          types={bannerToggle}
        />
      )}
      {loading && <Loader className={"fixed"} />}
      <div className="d-flex justify-content-between mt-5 mb-4 mobileFlexWrapper">
        <div className="brand_text">
          <p className="contentManagementText">Add testimonials</p>
        </div>
        <div className="buttonDivision">
          &nbsp;&nbsp;&nbsp;
          <Button
            className="btn-color-primary"
            disabled={props.access.add ? false : true}
            onClick={() => modalClick()}
          >
            Add
          </Button>
          &nbsp;
        </div>
      </div>

      <Slider
        {...settings}
        prevArrow={<PreviousBtn />}
        nextArrow={<NextBtn />}
        className="slider-Wrapper"
      >
        {testmonialsData?.length > 0 ? (
          testmonialsData.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {/* {index < 15 || viewAll ? ( */}
                <Col
                  key={index}
                  className="content-Card testmonialsWrap withIcon"
                >
                  <div className="d-flex sliderImage">
                    <div className="d-flex flex-column">
                      <div className="new_demand_address_1 ">
                        <div className="d-flex align-items-center justify-content-center">
                          <p className="testmonialsTest">
                            {conversion(item?.brandName)}
                          </p>

                          <span
                            className={`testmonials-tag ml-2 my-1 ${
                              item?.type === "Agent" ? "broker-tags" : ""
                            } ${
                              item?.type === "Developer" ? "develop-tags" : ""
                            } `}
                          >
                            {item?.type}
                          </span>
                        </div>

                        <div className="testWrapper">
                          <Button
                            className="table-btn p-0"
                            disabled={props.access.edit ? false : true}
                          >
                            <FiEdit2
                              className="edit"
                              onClick={() => handleEdit(item)}
                            />
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            className="table-btn p-0"
                            disabled={props.access.delete ? false : true}
                          >
                            <AiTwotoneDelete
                              className="delete"
                              onClick={() => deleteObj(item)}
                            />
                          </Button>
                        </div>
                        <br />

                        <div className="testmonialsSpan">{item.message}</div>
                      </div>
                    </div>
                  </div>
                </Col>
              </React.Fragment>
            );
          })
        ) : (
          <Col>
            <CardComponent>
              <div className="content_brand_card">
                <Card.Body>
                  <Card.Title className="mb-1">No Data</Card.Title>
                  <Card.Text>Add a Testimonials</Card.Text>
                </Card.Body>
              </div>
            </CardComponent>
          </Col>
        )}
      </Slider>
      <Modal
        onClose={setHandleModal}
        show={handleModal}
        heading={"Add a Testmonials"}
        size={"md"}
        loader="fixed"
      >
        {loading && <Loader className={"fixed"} />}
        <div className="containWrapper">
          <Col>
            <CardComponent>
              <div
                className="content_brand_card"
                // onClick={() => setHandleModal(true)}
              >
                <IoAddCircleOutline
                  size={25}
                  style={{
                    color: "#3E4958",
                    cursor: "pointer",
                  }}
                  onClick={() => setHandlePost(true)}
                />

                <Card.Body>
                  <Card.Text>Add a Testmonials</Card.Text>
                </Card.Body>
              </div>
            </CardComponent>
          </Col>
          {/* )} */}
        </div>
        <div className="ButtonWrapper">
          <Button
            variant="primary"
            className="brand_button"
            onClick={() => setHandleModal(false)}
          >
            Cancel
          </Button>
          &nbsp;
          {getApiData?.length > 0 && (
            <Button
              variant="primary"
              className="brand_button"
              onClick={() => setHandlePost(true)}
            >
              {getApiData?.length > 0 ? "Add More" : "Add"}
            </Button>
          )}
        </div>
      </Modal>
      {/*  */}
      <Modal
        onClose={setHandlePost}
        show={handlePost}
        heading={"Manage Testimonials"}
        size={"md"}
        loader="fixed"
      >
        <form onKeyDown={handleKeyPress}>
          <Select
            errorMessage={errorMessage?.type}
            label="Select Role *"
            options={customerRole}
            name={"role"}
            isInvalid={!!errorMessage?.type}
            value={blogPost && blogPost?.type}
            onChange={(e) => handleSelectdata(e.target.value, "role")}
            disabled={action === "Edit" ? true : false}
          />
          <div className="d-flex align-items-center justify-content-start mb-2">
            <BsFillInfoCircleFill color="#034168" />
            <p className="text-muted m-1 f-12">
              After selecting the role only you can access the customer name
            </p>
          </div>

          <MultiSelect
            disabled={
              blogPost?.type ? (action === "Edit" ? true : false) : true
            }
            errormessage={errorMessage?.brandName}
            onChange={(val) => handleSelectdata(val, "brandName")}
            isMulti={false}
            options={brandListData}
            value={
              blogPost && {
                label: blogPost?.brandName,
                value: blogPost?.brandId,
              }
            }
            lable="Customer Name *"
            // placeholder={"Select customer name"}
          />

          <div>
            <label>
              <b>Message *</b>
            </label>
            <Form.Control
              as="textarea"
              placeholder="Enter Message"
              style={{ height: "130px" }}
              value={blogPost?.message}
              name={"message"}
              disabled={blogPost?.type ? false : true}
              onChange={handleChanges}
            />
            {errorMessage?.message && (
              <span
                className="imageSpan"
                style={{
                  color: "#dc3545",
                  fontSize: "10px",
                  marginLeft: "15px",
                }}
              >
                {errorMessage?.message}
              </span>
            )}
          </div>

          <div className="ButtonWrapper">
            <Button
              type="button"
              variant="primary"
              className="btn-outline-color-primary"
              onClick={() => setHandlePost(false)}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              type="submit"
              variant="primary"
              className={"btn-color-primary"}
              onClick={(e) => handleBlogchange(e)}
            >
              {action === "Edit" ? "Update" : "Add"}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Testmonials;

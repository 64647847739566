import  { useState, useEffect, useRef } from "react";
import { getData, postGetData, postUploadData } from "../../api";
import Button from 'react-bootstrap/Button';
import moment from "moment";
import FormModal from "../../components/Modal";
import { GrAttachment, GrSend, GrDownload } from "react-icons/gr";
import PdfView from "../../components/pdfViewer";
import PdfImg from "../../assets/images/pdf.png";
import ImageGalleryBox from "../../components/ImageGalleryBox"
import Loader from "../../components/Loader";
function ChatChannel(props) {
  const [chatData, setChatData] = useState([]);
  const [inputText, setInputText] = useState("");
  const [id, setId] = useState();
  const [urls, setUrls] = useState("");
  const [showModal, setShowModal] = useState(false);
  const messagesEndRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const [galleryShow, setGalleryShow] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  

 

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

 

  const getChatMessage = async () => {
    if (props.userId) {
      setLoading(true);
      let url = `/chat/getMessages?roomId=${props.userId}`;

      const userChat = await getData(url, {}, {});
      const { data, statusCode } = userChat;
      if (statusCode === 200) {
        setLoading(false);
        setChatData(data.chat);
  setInputText("");
      } else {
        setLoading(false);
        setChatData([]);
  setInputText("");

      }
    }
  };

  const handleInputChat = (e) => {
    setInputText(e.target.value);
  };
  const onCloseGallery = () => {
    setGalleryShow(false);
  };
  const handleGallery = (img) => {
    setGalleryShow(true);
    setImageUrl(img);
  };
  const updateMsg =(text,type)=>{
    let pushMsg = {
      createdAt: new Date(),
      dateTime: new Date(),
      isRead: true,
      message: text,
      messageType: type,
      receiver: props.userData.userId,
      roomId: props.userId,
      senderBy: "sender",
      sender: id,
    };
    setChatData((i) => [...i, pushMsg]);
    props.sendMessage(pushMsg);
  setInputText("");
  setUrls('')
  }
  
  
  const sendFile = async (file, type, view) => {
    if (file) {
      let url ="/chat/addMessage";
      let obj = {
        roomId: props.userId,
        receiver: props.userData.userId,
        message:file,
        messageType:type,
        type:"message"
      };
      const sendData = await postGetData(url, {}, obj);
      const { statusCode } = sendData;
      if (statusCode === 200) {
        updateMsg(view,type);
        
      }
    }
  };

  const sendChat = async () => {
    let textValue = inputText.trim();
    if (textValue) {
      let url ="/chat/addMessage";
      let obj = {
        roomId: props.userId,
        receiver: props.userData.userId,
        message:textValue,
        messageType:"text",
        type:"message"
      };
      const sendData = await postGetData(url, {}, obj);
      const { statusCode } = sendData;
      if (statusCode === 200) {
        updateMsg(textValue,"text");
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (inputText) {
        sendChat();
      }
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const uploadFn = async (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    let fileType = file.type.split("/");
    if (fileType[0] === "image") {
      fileType = "image";
    } else if(file.type==="application/pdf") {
      fileType = "document";
    }
    else{
      fileType=""
    }
    if(file.type!==""){
    let url = `/chat/file-upload/${props.userId}?type=${fileType}&module=chat`;
    formData.append("uploadfiles", e.target.files[0]);
    const imageData = await postUploadData(url, {}, formData);
    const { data, status } = imageData;
    if (status) {
      setUrls(data[0].url);
      sendFile(data[0].url, fileType, data[0].viewUrl);
    }
   
  }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatData]);

  const content = () =>{
    return(
       
       <PdfView url={urls}/>
       
    )
    }

const handleModal =(url)=>{
setShowModal(true);
setUrls(url)
}
useEffect(() => {
  getChatMessage();
}, [props && props.userId]);

useEffect(() => {
  let user = JSON.parse(localStorage.getItem("keydemand_user"));
  setId(user._id);
  if (props && props.updateMessage) {
    if (props.updateMessage.sender != user._id) {
      let msg = props.updateMessage;
      msg.senderBy = "receiver";
      setChatData((i) => [...i, msg]);
    }
  }
}, [props.updateMessage]);
  return (
    <div className="chat-channel">
      <div className="chat-head d-flex">
        <div className="name-logo d-flex f-24 justify-content-center align-items-center">
          {props.userData && props.userData.name.charAt(0).toUpperCase()}
        </div>
        <div className="d-flex flex-column ml-3">
          <span className="f-16 fw-500">
            {props.userData && props.userData.name}
          </span>
          <span className="f-12 fw-400">
            {props.userData && props.userData.role}
          </span>
        </div>
      </div>
      <div>
      { loading ? 
        <Loader   />
        : <>
        <div className="datail-chat ">
          {/* <div className="d-flex justify-content-center align-item-center newday-alert"></div> */}
          {chatData && chatData.length > 0
            ? chatData.map((x,i) => {
                if (x.senderBy == "sender") {
                  return (
                    <div className="message-box-send p-2 d-flex " key={i}>
                      <div className="d-flex flex-column">
                        {x.messageType === "image" ? (
                          <>
                          <img src={x.message} className="mw-100" onClick={()=>{handleGallery(x.message)}} alt="sentImage"/>
                          {galleryShow ? (
                            <ImageGalleryBox
                              onClose={onCloseGallery}
                              imageUrl={imageUrl}
                            />
                          ) : null}
                          </>
                        ): x.messageType==="document"?(
                          <div className="d-flex pdfContainer">
                            <Button className="mr-3 noBorder" onClick={()=>{handleModal(x.message)}}>
                            <img src={PdfImg} className="pdfIcon" alt="pdfimage"/>
                              View Pdf
                              </Button>
                          <a href={x.message}
                            className="pdfLink "
                            without
                            target="_blank"
                            rel="noreferrer"

                            >
                         <Button className="ml-2 noBorder">
                          <GrDownload/>
                          </Button>
                            </a>
                            </div>
                        ): (
                          <div className="send-message-card">{x.message}</div>
                        )}
                        <div className="message-time f-10 pt-1">
                          {moment(x.createdAt).format("LLL")}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="message-box-receive p-2 d-flex " key={i}>
                      <div className="d-flex align-items-center justify-content-center ">
                        <span className="chat-logo  d-flex f-24 justify-content-center align-items-center">
                          {props.userData &&
                            props.userData.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        {x.messageType === "image" ? (
                          <>
                          <img src={x.message} className="mw-100" alt="receiveImage"/>
                          {galleryShow ? (
                              <ImageGalleryBox
                                onClose={onCloseGallery}
                                imageUrl={imageUrl}
                              />
                            ) : null}
                          </>
                        ) :x.messageType==="document"?(
                          <div className="d-flex pdfContainer">
                            <Button className="mr-3 noBorder" onClick={()=>{handleModal(x.message)}}>
                            <img src={PdfImg} className="pdfIcon" alt="receivepdf"/>
                              View Pdf
                              </Button>
                          <a href={x.message}
                            className="pdfLink "
                            without
                            target="_blank"
                            rel="noreferrer"
                            >
                         <Button className="ml-2 noBorder">
                          <GrDownload/>
                          </Button>
                            </a>
                            </div>
                        ): (
                          <div className="receive-message-card">
                            {x.message}
                          </div>
                        )}
                        <div className="f-10 pt-1">
                          {moment(x.createdAt).format("LLL")}
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            : <p className="text-center mt-2">No messages yet</p>}
          <div ref={messagesEndRef} />
        </div>
        <div className="enter-message p-2">
          <div className="text_areas">
            <input
              type="text"
              placeholder="Type the messages here"
              className=" chat-text mt-2 mb-2 "
              value={inputText}
              onChange={handleInputChat}
              onKeyDown={handleKeyDown}
            />
            <input
              style={{ display: "none" }}
              type="file"
              name="myImage"
              accept="image/*, application/pdf"
              onChange={uploadFn}
              ref={hiddenFileInput}
              onClick={(event) => {
                event.target.value = null;
              }}
            />

            <button className="attach-file" onClick={handleClick}>
              <GrAttachment />
            </button>
            <button
              className="attach-file"
              onClick={sendChat}
            >
              <GrSend/>
            </button>
          </div>
        </div>
        </>
        }
      </div>
      <FormModal
            show={showModal}
            onClose={setShowModal}
          heading="PDF VIEWER"
          // customer={false}
          children={content()}
          size={"xl"}
        />
    </div>
  );
}

export default ChatChannel;

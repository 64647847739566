import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { AccessDenied, getData, putData } from "../../api/index";
import Button from "../../components/Form/Button/index";
import Input from "../../components/Form/Input/index";
import PhoneNumberInput from "../../components/Form/Input/phoneNumberInput";
import FormModal from "../../components/Modal/index";
import NavBar from "../../components/Navbar";
import Search from "../../components/Search/search";
import MainTable from "../../components/Table/index";
import Select from "../../components/select/select";
// import HtmlParser from "react-html-parser";
import RectHtmlParser from "react-html-parser";

function Enquiries({ enquiryId }) {
  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
  
  const access = {
    newEnquiries: loginPermission?.includes(menuId?.viewNewEnquiries)
      ? true
      : false,
    assignedEnquiries: loginPermission?.includes(menuId?.assignedEnquiries)
      ? true
      : false,
    assign: loginPermission?.includes(menuId?.assignBdeToEnquiry)
      ? true
      : false,
    statusChange: loginPermission?.includes(menuId?.enquiryStatusChange)
      ? true
      : false,
  };
  const [key, setKey] = useState(access.newEnquiries ? "unassignedBde" : access?.assignedEnquiries ? "assignedBde" : "unassignedBde");
  const [offset, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [unassignedList, setUnassignedList] = useState([]);
  const [assignedList, setassignedList] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTwo, setSearchTwo] = useState("");
  const [offsetTwo, setOffsetTwo] = useState(1);
  const [perPageTwo, setPerPageTwo] = useState(10);
  const [pageCountTwo, setPageCountTwo] = useState(1);
  const [totalCountTwo, setTotalCountTwo] = useState(0);
  const [enterpriseForm, setEnterpriseForm] = useState(false);
  const [viewData, setViewData] = useState({});
  const [enquiryid, setEnquiryId] = useState();
  const [enquirytype, setEnquirytype] = useState();
  const [roleData, setRoleData] = useState();
  const [assignBDE, setAssignBDE] = useState(false);
  const [selectedBde, setSelectedBde] = useState();
  const [cityFilterValues, setCityFilterValues] = useState([]);
  const [cityFilterKeyValue, setCityFilterKeyValue] = useState("");
  const [cityFilterKeyValueAssigned, setCityFilterKeyValueAssigned] =
    useState("");
  const [wantTo, setWanTo] = useState("");
  const [wantToAssigned, setWanToAssigned] = useState("");

  const [cityID, setCityID] = useState("");
  const [cityIDAssinged, setCityIDAssigned] = useState("");

  // const [initLoading, setInitLoading] = useState(false)
 
  // useEffect(() => {
  // access.assignedEnquiries?setKey("assignedBde"):setKey("unassignedBde")
  // }, [])
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && key === "unassignedBde") {
      event.preventDefault();
      updateEnquiry(enquiryid);
    }
  };
  useEffect(() => {
    if (key === "unassignedBde") {
      document.addEventListener("keydown", handleKeyPress);
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [key, viewData, enquiryid]);

  ////////////////// Assign BDE start///////////////////////////////
  const getRolesFunction = async () => {
    let url =
      "/user/getAllSubAdmin?pageNo=0&limit=0&role=Business Development Executive";
    const apiDatas = await getData(url);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      setRoleData(data.subAdminList);
    } else {
      setRoleData([]);
    }
  };

  const handelUpdateBde = async (id) => {
    // setInitLoading(true);
    let statusData = {
      userId: selectedBde?._id,
    };
    let url = `/enquiry/${enquiryid}`;
    const getList = await putData(url, {}, statusData);
    const { statusCode } = getList;
    if (statusCode === 200) {
      setAssignBDE(false);
      // setInitLoading(false);
      getEnquiriesAssigned();
      getEnquiriesUnassigned();
    } else {
      // alert(message);
      // setInitLoading(false);
    }
  };

  const assignBDEbody = () => {
    return (
      
        <div className="mt-4">
          <div className="client mt-2">
            <Select
              label="Assign BDE"
              data={roleData}
              options="Assign BDE"
              // errormessage={props?.errors?.ownerShip}
              value={selectedBde && selectedBde?.name}
              // name={'ownerShip'}
              onChange={(val) => setSelectedBde(val)}
            />
          </div>

          <div className="d-flex justify-content-center  gap-3 ">
            <Button
              // name={"Assign BDE"}
              type={"submit"}
              className="btn-primary"
              onClick={handelUpdateBde}
              disabled={selectedBde ? false : true}
            >
              Assign BDE
            </Button>
          </div>
        </div>
      
    );
  };

  useEffect(() => {
    setCityFilterKeyValue("");
    setCityFilterKeyValueAssigned("");
    setCityID("");
    setCityIDAssigned("");
  }, [key]);
  useEffect(() => {
    getRolesFunction();
  }, []);

  ////////////////////////////////////Assigned BDE end//////////////////////////////////////////////

  /////////////////////////page common function start///////////////////////////////////////

  const handeltab = (tab) => {
    setKey(tab);
    setSearch("");
    setSearchTwo("");
    setOffset(1);
    setOffsetTwo(1);
    setWanToAssigned("");
    setWanTo("");
  };

  const handleSearch = (e) => {
    if (key === "unassignedBde") {
      setSearch(e.target.value);
      setOffset(1);
    }
    if (key === "assignedBde") {
      setSearchTwo(e.target.value);
      setOffsetTwo(1);
    }
  };
  /////////////////////////page common function end///////////////////////////////////////

  /////////////////////////get tabble List function start///////////////////////////////////////

  const getEnquiriesUnassigned = async () => {
    // isLoading();
    let url = `/enquiry?pageNo=${offset}&limit=${perPage}&status=ongoing`;
    if (search) {
      url += `&search=${search}`;
    }
    if (cityFilterKeyValue !== "City" && cityFilterKeyValue !== "") {
      url += `&city=${cityFilterKeyValue}`;
    }
    if (wantTo) {
      url += `&wantTo=${wantTo}`;
    }
    const getList = await getData(url, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      // setInitLoading(false);

      setUnassignedList(data.enquiryList);
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
    } else {
      setPageCount(0);
      // setInitLoading(false);
      setUnassignedList([]);
    }
  };
  const getEnquiriesAssigned = async () => {
    // isLoading();
    let url = `/enquiry?pageNo=${offsetTwo}&limit=${perPageTwo}&status=completed`;
    if (searchTwo) {
      url += `&search=${searchTwo}`;
    }
    if (wantToAssigned) {
      url += `&wantTo=${wantToAssigned}`;
    }
    if (
      cityFilterKeyValueAssigned !== "City" &&
      cityFilterKeyValueAssigned !== ""
    ) {
      url += `&city=${cityFilterKeyValueAssigned}`;
    }
    const getList = await getData(url, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setassignedList(data.enquiryList);
      setPageCountTwo(Math.ceil(data.totalCount / perPageTwo));
      setTotalCountTwo(data.totalCount);
    } else {
      setPageCountTwo(0);
      setassignedList([]);
    }
  };

 

  const onChangeWantTo = (val) => {
    if (key === "assignedBde") {
      setOffsetTwo(1);
      setWanToAssigned(val);
    } else if (key === "unassignedBde") {
      setOffset(1);
      setWanTo(val);
    }
  };
  const onchangeCityFilterKey = (e) => {
    let cityId;
    cityFilterValues.find((el) =>
      el.name === e.target.value ? (cityId = el._id) : null
    );

    if (key === "assignedBde") {
      setOffsetTwo(1);
      setCityFilterKeyValueAssigned(e.target.value);
      setCityIDAssigned(cityId);
    } else if (key === "unassignedBde") {
      setOffset(1);
      setCityFilterKeyValue(e.target.value);
      setCityID(cityId);
    }
  };
  const getCityList = () => {
    let url = "/country/getAllCities";
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        setCityFilterValues(response.data);
      }
    });
  };

  /////////////////////////////////////////////get Table List end///////////////////////////

  /////////////////////////////////////view function//////////////////////////////////
 
  const singleViewData = async (id) => {
    // isLoading();
    let url = `/enquiry/${id}`;
    const getList = await getData(url, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setViewData(data);
      // setInitLoading(false);
    } else {
      // setInitLoading(false);
    }
  };
  const updateEnquiry = async (id) => {
    let statusData = {
      status: "completed",
    };
    let url = `/enquiry/${id}`;
    const getList = await putData(url, {}, statusData);
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setViewData(data);
      setOffsetTwo(1);
      setEnterpriseForm(false);
      setKey("assignedBde");
      getEnquiriesAssigned();
      getEnquiriesUnassigned();
    } 
    
    
  };

  const enterFormSolution = (row) => {
    setViewData({});
    setEnquirytype(row?.wantTo)
    setEnterpriseForm(true);
    setEnquiryId(row._id);
    singleViewData(row._id);
  };

  function userButtonUnassignedFormatter(cell, row) {
    return (
        <div className="d-flex justify-content-center align-items-center cursor-point">
          <BsFillEyeFill
            onClick={access.newEnquiries ? () => enterFormSolution(row) : null}
            className="cursor-pointer"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
    );
  }

  /////////////////////////////////////view function end////////////////////////////////////

  const renderButton = (title) => {
    return `${title}<BsFillEyeFill/>`;
  };
  // let qualityFilter = []
 
  const getFormattedWantTo = (wantTo) => {
    if(wantTo === "FlexOccupier") {
      return "Flex Space Occupier"
    } else if(wantTo === "FlexOperator") {
      return "Flex Space Operator"
    } else {
      return wantTo
    }
  }
  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handelChangePerPageTwo = (count) => {
    setPerPageTwo(count);
    setOffsetTwo(1);
  };
  useEffect(() => {
    getEnquiriesUnassigned();
  }, [perPage, offset, search, cityFilterKeyValue, wantTo]);
  useEffect(() => {
    getEnquiriesAssigned();
  }, [
    perPageTwo,
    offsetTwo,
    searchTwo,
    cityFilterKeyValueAssigned,
    wantToAssigned,
  ]);

  useEffect(() => {
    getCityList();
  }, []);

  const enterpriseSolution = () => {
    return (
      <form on>
        <div className="mt-3">
          <Input
            label={"Your name"}
            type={"text"}
            placeholder={"Enter Your name"}
            name="name"
            value={viewData?.name}
            disabled={true}
          />
          <Input
            label={"Company name"}
            type={"text"}
            placeholder={"Your company name"}
            name="companyName"
            value={viewData?.companyName}
            disabled={true}
          />
          <Input
            label={"Your Designation"}
            type={"text"}
            placeholder={"Enter your designation"}
            name="designation"
            value={viewData?.designation}
            disabled={true}
          />

          <Input
            label={"city"}
            type={"text"}
            placeholder={"Select city"}
            name="city"
            value={viewData?.city?.name}
            disabled={true}
          />

          <Input
            label={"Want to"}
            type={"email"}
            placeholder={"Select buy / Sell your properties"}
            name="wantTo"
            value={getFormattedWantTo(viewData?.wantTo)}
            disabled={true}
          />

          <Input
            label={"Official Email ID"}
            type={"email"}
            placeholder={"Enter your official email ID"}
            value={viewData?.email}
            disabled={true}
          />
          <PhoneNumberInput
            label={"Phone Number"}
            type={"number"}
            value={viewData?.phoneNumber}
            placeholder={"Enter Phone number"}
            disabled={true}
          />
          {enquirytype === 'FlexOccupier' && (
            <Input
            label={"GST/CIN"}
            type={"text"}
            value={viewData?.gstcinNo}
            placeholder={"Enter your GST/CIN number"}
            disabled={true}
          />
          )}
          {enquirytype !== 'FlexOccupier' && (
              <Input
              label={"Prefered mode of Contact"}
              type={"text"}
              value={viewData?.modeOfContact}
              placeholder={"Prefered mode of Contact"}
              disabled={true}
            />
          )}
          
          {key === "assignedBde" && (
            <Input
              label={"Assigned BDE"}
              type={"text"}
              value={viewData?.userId?.name}
              placeholder={""}
              disabled={true}
            />
          )}
        </div>
        <div className="d-flex justify-content-center  gap-3 ">
          {key === "unassignedBde" ? (
            <>
              {" "}
              {viewData?.status === "ongoing" && (
                <Button
                  className={"btn-primary"}
                  disabled={access.statusChange ? false : true}
                  onClick={(e) => {
                    e.preventDefault()
                    updateEnquiry(enquiryid)
                  }}
                >
                  Close the enquiry
                </Button>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        {/* </FormModal> */}
      </form>
    );
  };
  
  const assignedColumns = [
    {
      dataField: "sno",
      text: "S.no",
    },

    {
      dataField: "name",
      text: RectHtmlParser(renderButton("Name")),
    },

    {
      dataField: "companyName",
      text: "Company Name",
    },
    {
      dataField: "designation",
      text: "Designation",
    },
    {
      dataField: "wantTo",
      text: "Want To",
      formatter: (cellContent, row, rowIndex) => getFormattedWantTo(row?.wantTo),
    },
    {
      dataField: "city",
      text: "City",
      formatter: (cellContent, row, rowIndex) => row.city?.name,
    },
    // {
    //   dataField: "gstinfo",
    //   text: "GST/CIN",
    //   formatter: (cellContent, row, rowIndex) => row.gstcinNo || '-',
    // },
    {
      dataField: "createdAt",
      text: "Created on",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
    },
    {
      dataField: "userId",
      text: "Assigned BDE",
      formatter: (cellContent, row, rowIndex) => row.userId?.name,
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    // },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonUnassignedFormatter,
    },
  ];
  const newEnquiriesColumns = [
    {
      dataField: "sno",
      text: "S.no",
    },

    {
      dataField: "name",
      text: "Name",
    },

    {
      dataField: "companyName",
      text: "Company Name",
    },
    {
      dataField: "designation",
      text: "Designation",
    },
    {
      dataField: "wantTo",
      text: "Want To",
      formatter: (cellContent, row, rowIndex) => getFormattedWantTo(row?.wantTo),
    },
    {
      dataField: "city",
      text: "City",
      formatter: (cellContent, row, rowIndex) => row.city?.name,
    },
    {
      dataField: "gstinfo",
      text: "GST/CIN",
      formatter: (cellContent, row, rowIndex) => row.gstcinNo || '-',
    },
    {
      dataField: "createdAt",
      text: "Created on",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
    },
    {
      dataField: "modeOfContact",
      text: "Pre Mode Of Contact",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.modeOfContact === null ||
          row.modeOfContact === "" ||
          !("modeOfContact" in row)
        ) {
          return "-";
        } else if (row?.modeOfContact) {
          return row?.modeOfContact;
        }
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonUnassignedFormatter,
    },
  ];
  const AssignedTableProps = {
    data: assignedList,
    columns: assignedColumns,
    offset: offsetTwo,
    setOffset: setOffsetTwo,
    handelChangePerPage: handelChangePerPageTwo,
    pageCount: pageCountTwo,
    perPage: perPageTwo,
    totalCount: totalCountTwo,
  };
  const enquriestableProps = {
    data: unassignedList,
    columns: newEnquiriesColumns,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };

  return (
    <>
      {loginPermission?.includes(menuId?.enquiry) ? (
        <div>
          <div className="d-flex justify-content-between">
            <NavBar />
            <Search
              onChange={handleSearch}
              value={
                key === "unassignedBde"
                  ? search
                  : key === "assignedBde"
                  ? searchTwo
                  : ""
              }
            />
          </div>
          <div className="filter-container">
            <select
              className="form-select filterBGColor"
              key="city"
              name="city"
              id="city"
              onChange={(e) => onchangeCityFilterKey(e)}
              value={
                key === "assignedBde"
                  ? cityFilterKeyValueAssigned
                  : cityFilterKeyValue
              }
            >
              <option key={"city"}>City</option>
              {cityFilterValues.map((item, index) => {
                return (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <select
              className="form-select filterBGColor"
              key="wantTo"
              name="wantTo"
              id="wantTo"
              value={key === "assignedBde" ? wantToAssigned : wantTo}
              onChange={(e) => onChangeWantTo(e.target.value)}
            >
              <option key={""} value="">
                Want to
              </option>
              <option key={"buyer"} value={"Buyer"}>
                Buyer
              </option>
              <option key={"seller"} value={"Seller"}>
                Seller
              </option>
              <option key={"FlexOperator"} value={"FlexOccupier"}>
                Flex Space Occupier
              </option>
              <option key={"FlexOperator"} value={"FlexOperator"}>
                Flex Space Operator
              </option>
            </select>
          </div>
          <div className="tab-containered my-3 ">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handeltab(k)}
              className="mb-3"
            >
              {access.newEnquiries ? (
                <Tab eventKey="unassignedBde" title="Ongoing">
                  <MainTable {...enquriestableProps} />
                </Tab>
              ) : null}
              <Tab eventKey="assignedBde" title="Approved">
                <MainTable {...AssignedTableProps} />
              </Tab>
            </Tabs>
          </div>
          {enterpriseForm && (
            <FormModal
              show={enterpriseForm}
              onClose={setEnterpriseForm}
              // popup={enterpriseSolution}
              heading={
                key === "assignedBde"
                  ? "BDE Assigned enquiry detail view"
                  : "Enquiries"
              }
              customer={false}
              children={enterpriseSolution()}
              size={"md"}
            />
          )}
          {assignBDE && (
            <FormModal
              show={assignBDE}
              enquiryId={enquiryId}
              onClose={setAssignBDE}
              popup={assignBDEbody}
              heading={"AssignBDE"}
              customer={false}
              children={assignBDEbody()}
              size={"md"}
            />
          )}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
}

export default Enquiries;


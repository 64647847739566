import { useContext, useEffect, useReducer, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { AccessDenied, getData, putData } from "../../api";
import Button from "../../components/Form/Button";
import Select from "../../components/Form/Select";
import Loader from "../../components/Loader";
import NavBar from "../../components/Navbar";
import Search from "../../components/Search/search";
import MainTable from "../../components/Table";
import { isObjectHasValue } from "../../utilities/commonFunction";
import {
  getContextFilterData,
  getContextSearchData,
  getTableStoreValue,
  setScrollBackId,
  setTableFilterData,
} from "../../utilities/pageScroll";
import { CustomerWarningPopup } from "../Customer/CustomerTable/warningPopup";
import "./style.scss";
export default function Properties() {
  const { tableStoreContext, setTableStoreContext, pageScroll, setPageScroll } =
    useContext(AppContext);
  let tableName = "totalProperties";
  const currentTableValueFromConText = getTableStoreValue(
    tableName,
    tableStoreContext
  );
  let contextFilterData = getContextFilterData(tableName, pageScroll);
  let contextSearchData = getContextSearchData(tableName, pageScroll);
  const storePageNo = currentTableValueFromConText?.pageNo;
  const storePerPage = currentTableValueFromConText?.perPage;
  const [offset, setOffset] = useState(
    storePageNo ? storePageNo : 1
  );
  const [perPage, setPerPage] = useState(
    storePerPage ? storePerPage : 10
  );
  const [initLoading, setInitLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [arrData, setArrData] = useState([]);
  const [propertyTypeList, setPropertyTypeList] = useState([])

  const [search, setSearch] = useState(
    contextSearchData ? contextSearchData : ""
  );


  let initialState = {
    createdSort: "",
    customerType: "",
    propertyTypeSort: "",
    transactionTypeSort: ""
  };
  const reducer = (state, action) => {

    if (!action.type) {
      return state;
    }

    return {
      ...state,
      [action.type]: action.value,
    };
  };
  let filterinitialState = contextFilterData || initialState;
  const [filter, filterReducerDispatch] = useReducer(
    reducer,
    filterinitialState
  );

  const [activeStatus, setActiveStatus] = useState(false);
  const [demandId, setDemandId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);
  let navigate = useNavigate();
  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    view: loginPermission?.includes(menuId?.viewProperty) ? true : false,
    edit: loginPermission?.includes(menuId?.editProperty) ? true : false,
    enable: loginPermission?.includes(menuId?.propertyEnableOrDisable)
      ? true
      : false,
  };


  let customerTypeFilterValues = [
    {
      name: "Agent",
      _id: "Agent",
    },
    {
      name: "Developer",
      _id: "Developer",
    },
  ];
  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];
  let customerTransactionTypeSortValues = [
    {
      _id: "Lease",
      name: "Lease",
    },
    {
      _id: "Revenue Share",
      name: "Revenue Share",
    },
    {
      _id: "MG Revenue Share",
      name: "MG Revenue Share",
    },
    {
      _id: "Sales",
      name: "Sales",
    },
  ]

  const getPropertyType = () => {
    let url = '/propertyType';
    getData(url, {}, {}).then((response) => {
      if (response?.status) {
        let propertyArray = [];
        response?.data?.map((data) => {
          propertyArray.push({
            name: data?.name,
            _id: data?._id,
          });
          return data;
        });
        setPropertyTypeList(propertyArray);
      }
    });
  };
  const getCityList = () => {
    setInitLoading(true);
    let url = "/property/propertyCities";
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        setInitLoading(false);
      }
    });
  };
  const setContextValue = (id) => {
    let scrollArray = setScrollBackId(tableName, id, filter, search, pageScroll);
    setPageScroll(scrollArray);

  }
  const updateTableStoreContext = (id) => {
    let newValue = setTableFilterData(
      tableName,
      { pageNo: offset, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  }


  const getAllPropertyList = async () => {
    setInitLoading(true);
    let url = `/property?pageNo=${offset}&limit=${perPage}`;
    if (filter?.customerType) url += `&customerType=${filter?.customerType}`;
    if (search) url += `&search=${search}`;
    if (filter?.createdSort) url += `&sort=${filter?.createdSort}`;
    if (filter?.transactionTypeSort && filter?.transactionTypeSort !== "select") url += `&transactionType=${filter?.transactionTypeSort}`;
    if (filter?.propertyTypeSort && filter?.propertyTypeSort !== "select") url += `&propertyType=${filter?.propertyTypeSort}`;
    const getList = await getData(url, {});
    const { data, statusCode } = getList;
    if (statusCode === 200) {
      setInitLoading(false);
      setArrData(data?.properties);
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
    } else {
      setPageCount(0);
      setInitLoading(false);
      setArrData([]);
    }
  };
  function userButtonFormatter(cell, row) {
    return (

      <div className="d-flex justify-content-center align-items-center ">
        <Button className="table-btn p-0" disabled={access.view ? false : true}>
          <BsFillEyeFill
            style={{ cursor: "pointer" }}
            onClick={access.view ? () => viewDetails(row) : null}
          />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button className="table-btn p-0" disabled={row?.isActive && access.edit ? false : true}>
          <FaPen
            style={{ cursor: "pointer" }}
            onClick={access.edit ? () => editButton(row) : null}
          />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Check
          type="switch"
          id="custom-switch"
          checked={row.isActive ? true : false}
          onChange={(e) => {
            updateStatus(row._id, row.isActive);
          }}
          disabled={access.enable ? false : true}
        // label="Check this switch"
        />
      </div>
    );
  }

  const columns = [
    {
      dataField: "productId.display",
      text: "Property id",
    },
    {
      dataField: "developerId.name",
      text: "Supplier",
    },
    {
      dataField: "propertyName",
      text: "Project name",
      formatter: (cellContent, row, rowIndex) => {
        return row?.propertyName ? row?.propertyName : "-";
      },
    },
    {
      dataField: "propertyTypeId.name",
      text: "Property type",
    },
    {
      dataField: "transactionType",
      text: "Transaction Type",
    },
    {
      dataField: "location",
      text: "City",
    },
    {
      dataField: "microLocation",
      text: "Locality",
      formatter: (cellContent, row, rowIndex) => {
        return <span>{row?.microLocation?.description}</span>;
      },
    },
    {
      dataField: "customerType",
      text: "Customer type",
    },
    {
      dataField: "createdAt",
      text: "Created on",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
      // sort:true,
      // onSort: (field, order) => {
      //   setCreatedSort(createdSort === "" || createdSort === "desc" ? "asc" : "desc")
      // }
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatter,
    },
  ];

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };

  const handleSelectCustomerType = (e) => {
    filterReducerDispatch({ type: "customerType", value: e.target.value })
    setOffset(1);
  };
  const handleCreatedSort = (e) => {
    filterReducerDispatch({ type: "createdSort", value: e.target.value })
    setOffset(1);
  };
  const handleTransactionTypeSort = (e) => {
    filterReducerDispatch({ type: "transactionTypeSort", value: e.target.value })
    setOffset(1);
  }
  const handlePropertyTypeSort = (e) => {
    filterReducerDispatch({ type: "propertyTypeSort", value: e.target.value })
    setOffset(1);
  }
console.log({arrData:arrData});
  const tableProps = {
    data: arrData,
    columns,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "totalProperties",
    setPerPage,
  };
  const viewDetails = (datas) => {
    updateTableStoreContext(datas?._id);
    localStorage.setItem("propertyValues", JSON.stringify(datas));
    navigate(`/properties/propertyDetailView/${datas?.productId?.display}`, {
      state: { values: datas, page: "properties" },
    });
  };
  const editButton = (data) => {
    if (data?.isActive) {
      updateTableStoreContext(data?._id);
      let url = `/property/edit?propertyId=${data?._id}&developerId=${data.developerId?._id}&developerType=${data?.customerType}&developerName=${data.developerId?.name}`;

      localStorage.setItem(
        "ownerShip",
        JSON.stringify(data?.propertyTypeId._id)
      );
      navigate(url);
    }
  };
  const warningYesClick = async () => {
    let url = `/property/changeStatus/${demandId}`;
    let datas = {
      isActive: activeStatus ? false : true,
    };
    setInitLoading(true);
    const apiDatas = await putData(url, {}, datas);
    const { statusCode } = apiDatas;
    if (statusCode === 200) {
      setInitLoading(false);
      getAllPropertyList();
      setWarningPopup(false);
    } else {
      setInitLoading(false);
      setWarningPopup(false);
    }
  };

  const handleWarningClose = () => {
    setWarningPopup(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  const updateStatus = (id, active) => {
    setActiveStatus(active);
    setDemandId(id);
    setWarningPopup(true);
    if (active === true) {
      setWarningMessage("Are you sure, you want to inactivate this Property");
    } else if (active === false) {
      setWarningMessage("Are you sure, you want to activate this Property");
    }
  };


  useEffect(() => {
    getAllPropertyList();
    if (search || isObjectHasValue(filter)) {
      setContextValue();
    }
  }, [filter, offset, search, perPage]);
  useEffect(() => {
    getCityList();
  }, []);
  useEffect(() => {
    getPropertyType()
  }, [])
  return (
    <>
      {loginPermission?.includes(menuId?.properties) ? (
        <div>
          {warningPopup && (
            <CustomerWarningPopup
              show={warningPopup}
              title={"Confirmation"}
              message={warningMessage}
              warningYesClick={warningYesClick}
              onClose={handleWarningClose}
            />
          )}
          {initLoading && <Loader className={"fixed"} />}
          {/* <Lable title="Properties">Admin</Lable> */}
          <div className="d-flex justify-content-between">
            {/* <h4 className="text-color-primary fw-500 mb-0">Properties</h4> */}
            <NavBar />
            <Row className="filter_container" style={{ width: "75%" }}>
              <Col>
                <Search onChange={handleSearch} value={search} />
              </Col>
              <Col>
                <Select
                  placeholder="Property Type"
                  options={propertyTypeList}
                  name={"PropertyType"}
                  value={filter?.propertyTypeSort}
                  onChange={handlePropertyTypeSort}
                // isHideSelect
                />
              </Col>
              <Col>
                <Select
                  placeholder="Transaction Type"
                  options={customerTransactionTypeSortValues}
                  name={"Transaction"}
                  value={filter?.transactionTypeSort}
                  onChange={handleTransactionTypeSort}
                // isHideSelect
                />
              </Col>
              <Col>
                <Select
                  placeholder="Select"
                  options={customerTypeFilterValues}
                  name={"customerType"}
                  value={filter?.customerType}
                  onChange={handleSelectCustomerType}
                // isHideSelect
                />
              </Col>

              <Col>
                <Select
                  placeholder="Created on"
                  options={customerCreatedSortValues}
                  name={"Created"}
                  value={filter?.createdSort}
                  onChange={handleCreatedSort}
                // isHideSelect
                />
              </Col>
            </Row>
          </div>

          <MainTable {...tableProps} />
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
}

import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../propertyViewList/propertyDetail.css";
import File from "../../components/pdfViewer";
import { AiOutlineDownload } from "react-icons/ai";
import ModalView from "../../components/Modal";
import "./index.scss";
import ImageGalleryBox from "../ImageGalleryBox";
import imageIcon from "../../assets/images/imageIcon.png";

function PropertyDocument(props) {
  const [pdfhandle, setPdfHandle] = useState(false);
  const [galleryShow, setGalleryShow] = useState(false);
  const [documents, setDocuments] = useState("documents")
  const [url, setUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const { apiData } = props;
  const docCati = [
    "floorPlans",
    "fireNDC",
    "autocad",
    "licenses",
    "brochure"
    // "otherDocuments",
  ];
  const handlePdf = (pdf) => {
    setPdfUrl(pdf);
    setPdfHandle(true);
  };
  const handleGallery = (img) => {
    setGalleryShow(true);
    setUrl(img);
  };
  const onCloseGallery = () => {
    setGalleryShow(false);
  };
  const checkForEmptyData = () => {

    let isDataPresent = [...docCati].every((itemData) => apiData?.[documents]?.[itemData]?.length === 0)
    return isDataPresent;
  }
  useEffect(() => {
    apiData?.propertyTypeId?.name == "Flex Space" ? setDocuments("otherDocuments") : setDocuments("documents")
  }, [apiData])
  return (
    <div>
      <ModalView show={pdfhandle} onClose={setPdfHandle}>
        <div className="pdfWrap">
          <div className="downloadIcon">
            <a
              href={pdfUrl}
              className="pdfLink"
              without
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiOutlineDownload
                size={25}
                style={{ cursor: "pointer" }}
                className="download"
              />{" "}
            </a>
          </div>
          <File url={pdfUrl} fileType={"pdf"} />
        </div>
      </ModalView>
      <Row>
        {!checkForEmptyData() ? docCati.map(
          (itemData) =>
            apiData?.[documents]?.[itemData]?.length > 0 &&
            apiData?.[documents]?.[itemData]?.map((item, index) => (
              <>
                {item?.type === "image" ?
                  <Col sm={props.sm} md={props.md} className="mt-2" key={index}>
                    <div
                      className={
                        props.pdf
                          ? "bg-light-card d-flex align-items-center Property-View-lable widthWrapper "
                          : "bg-light-card d-flex align-items-center Property-View-lable"
                      }
                      key={index}
                    >
                      <div className="pdf-container p-1 mx-1">
                        <img src={imageIcon} className="pdfIcon" alt="pdf" />
                      </div>
                      <div
                        className={
                          props.image
                            ? "w-75 Property-View-lable widthAdj pdfWrapper p-2 f-16 fw-500"
                            : "w-75 Property-View-lable p-2 f-16 fw-500"
                        }
                      >
                        {item?.fileName}
                      </div>
                      <div
                        className={`w-25 text-center Property-View-lable-left d-flex ${props.image ? "pdf-viewer" : ""
                          }`}

                      >
                        <span onClick={() => handleGallery(item?.viewUrl)} className="d-flex flex-nowrap align-items-center">
                          {props.icons}
                          <a
                            href={url}
                            className="pdfLink"
                            without
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <AiOutlineDownload
                              size={25}
                              style={{ cursor: "pointer" }}
                              className="download"
                            />{" "}
                          </a>
                        </span>
                        <div className="downloadIcon">

                        </div>
                      </div>
                    </div>
                  </Col> :
                  <Col sm={props.sm} md={props.md} className="mt-2" key={index}>
                    <div
                      className={
                        props.pdf
                          ? "bg-light-card d-flex align-items-center Property-View-lable widthWrapper"
                          : "bg-light-card d-flex align-items-center Property-View-lable"
                      }
                      key={index}
                    >
                      <div className="pdf-container p-2 mx-1">
                        <img
                          src={props.image}
                          className="pdfIcon"
                          alt="pdf-Viewer"
                        />
                      </div>
                      <div
                        className={
                          props.pdf
                            ? "w-75 Property-View-lable widthAdj pdfWrapper"
                            : "w-75 Property-View-lable"
                        }
                      >
                        {item?.fileName}
                      </div>
                      <div
                        className={`w-25 Property-View-lable-left ${props.pdf ? "pdf-viewer" : ""
                          }`}
                      >

                        <span onClick={() => handlePdf(item?.viewUrl)} className="d-flex flex-nowrap align-items-center justify-content-end">
                          {props.icons}
                          <a
                            href={pdfUrl}
                            className="pdfLink"
                            without
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <AiOutlineDownload
                              size={25}
                              style={{ cursor: "pointer" }}
                              className="download"
                            />{" "}
                          </a>
                        </span>
                        {/* <File url={item?.viewUrl}/>{props.icons} */}
                      </div>
                    </div>
                  </Col>}
              </>
            ))
        ) : <center>No documents found</center>}
      </Row>
      {galleryShow ? (
        <ImageGalleryBox
          onClose={onCloseGallery}
          imageUrl={url}
        />
      ) : null}
    </div>
  );
}

export default PropertyDocument;

import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

// components
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import Search from "../../../components/Search/search";
import {
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  switchToUsers,
} from "../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../assets/images/switchuser.png";
import CrossSign from "../../../assets/images/CrossSign.svg";
import Loader from "../../../components/Loader";
import SwitchAccountPopup from "../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useNavigate, useSearchParams } from "react-router-dom";

const ListRelevancyDemandAgentTable = ({ filterData, trigger, tableType }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [propertyType, setPropertyType] = useState();
  const [transactionType, setTransactionType] = useState();
  const [sort, setSort] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  // const newRelevancy = searchParams.get("filter") === "new";
  const newRelevancy = tableType.includes("New");
  let navigate = useNavigate();

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "dsc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];

  const [search, setSearch] = useState("");

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleAccountSwitch = async (id) => {
    setCompanyId(id);
    getAllSwitchUsers(id).then((res) => {
      if (res.status && res.statusCode === 200) {
        const companySpecificPOCs = res?.data?.companySpecificPOCs || [];
        // const brandPOCs = res?.data?.brandPOCs || [];
        // const regionPOCs = res?.data?.regionPOCs || [];
        const combinedArrays = [...companySpecificPOCs];
        if (combinedArrays.length === 1) {
          const user = combinedArrays[0];
          const data = {
            companyId: id,
            customerId: user._id,
            type: "user",
          };
          switchToUsers(data).then((res) => {
            let openUrl = process.env.REACT_APP_SWITCH_URL;
            if (res.status) {
              window.open(`${openUrl}/${res?.data?.token}`);
            }
          });
        } else if (combinedArrays.length < 1) {
          Swal.fire({
            // title: header,
            text: "No Poc Found",
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        } else {
          setSwitchModal(true);
          setPocs({
            companyPocs: companySpecificPOCs,
          });
        }
      }
    });
  };

  let columnsBrand = [
    {
      dataField: "createdAt",
      text: "Timestamp",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row.createdAt).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: "Agent Name",
      dataField: "agentName",
    },
    {
      text: "Demand ID",
      dataField: "demandId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className="hyperlinkText"
            onClick={(e) => {
              cellContent && navigate("DemandDetailView/" + cellContent);
            }}
          >
            {cellContent}
          </div>
        );
      },
    },
    {
      text: "Total Relevancy",
      dataField: "totalRelevancy",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className="hyperlinkText"
            onClick={(e) => {
              console.log("totalRelevancy ID: ", row.totalRelevancy);
            }}
          >
            {row.totalRelevancy}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <>
            {newRelevancy ? (
              <div className="db-number-card-container">
                <img
                  style={{ width: "20px", height: "20px" }}
                  alt="cross"
                  src={CrossSign}
                />
              </div>
            ) : (
              <div className="db-number-card-container">
                {getCurrentUser()?.role[0]?.name === "admin" ||
                getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
                  <img
                    style={{ cursor: "pointer", width: "20px", height: "20px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAccountSwitch(row?._id);
                    }}
                    alt="switch"
                    src={switchuser}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }

    let url = `/dashboard/getDashboardRelevantPropertiesForAgent?pageNo=${offSet}&limit=${perPage}&isNew=${
      newRelevancy ? true : false
    }`;
    if (search.length > 2) {
      url += `&searchTerm=${search.trim()}`;
    }

    if (transactionType) {
      url += `&transactionType=${transactionType}`;
    }

    if (propertyType) {
      url += `&propertyType=${propertyType}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }

    let cusomerData = await getData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data?.totalData / perPage));
      setTotalCount(data?.totalData);
      setTableData(data?.responseList);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  let newRelevancyObj = {
    text: "New Relevancy",
    dataField: "newRelevancy",
    formatter: (cellContent, row, rowIndex) => {
      return (
        <div
          className={`${cellContent && "hyperlinkText"}`}
          onClick={(e) => {}}
        >
          {cellContent}
        </div>
      );
    },
  };

  if (newRelevancy) {
    columnsBrand.splice(3, 0, newRelevancyObj);
  }

  if (newRelevancy) {
    const actionCol = [
      {
        dataField: "action",
        text: "Action",
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div className="db-number-card-container">
              <img
                style={{ width: "20px", height: "20px" }}
                alt="cross"
                src={CrossSign}
              />
            </div>
          );
        },
      },
    ];
    columnsBrand.splice(7, 0, ...actionCol);
  }
  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rmName",
        formatter: (cellContent, row, rowIndex) => {
          let rmName = row?.rmData?.name;
          return <div>{rmName ? rmName : "-"}</div>;
        },
      },
    ];
    columnsBrand.splice(4, 0, ...rmData);
  }
  let tableProps = {
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "RmDashboard",
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [perPage, offSet, search, propertyType, transactionType, sort]);

  useEffect(() => {
    getTableData(false);
  }, [trigger]);

  return (
    <>
      {loading && <Loader className={"fixed"} />}
      <div className="db-filter-container">
        <Row className=" filter-select">
          <>
            <Col md={4} sm={5}>
              <Select
                placeholder="Posted On"
                options={customerCreatedSortValues}
                name={"Posted"}
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                  setOffset(1);
                }}
                // isHideSelect
              />
            </Col>
          </>
        </Row>
        <Row className=" filter-search-inbox">
          <Col md={6} sm={7} className="d-flex align-items-start">
            <Search onChange={handleSearch} value={search} />
          </Col>
        </Row>
      </div>
      <MainTable {...tableProps} trigger={trigger} />
      <SwitchAccountPopup
        show={switchModal}
        onClose={() => setSwitchModal(false)}
        data={pocs}
        companyId={companyId}
      />
    </>
  );
};
export default ListRelevancyDemandAgentTable;


import { Navigate } from "react-router-dom";
import { isLogedIn } from "../api";

const PrivateRoute = ({ children }) => {

  if (!isLogedIn()) {
    return <Navigate to="/login" replace />;
  }
  return children;
};
export default PrivateRoute;

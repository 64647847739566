import { useEffect, useState } from "react";
import { getCurrentUser, getData, postGetData } from "../../api";
import Image from "../../assets/images/profilePic.jpg";
import { useNavigate } from "react-router-dom";
const ReleventResponse = ({ propertyId }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    // const [propertyId, setPropertyId] = useState("");

    const [propertyResponseData, setResponsePropertyData] = useState([]);
    // const getReleventDemand = async () => {
    //     setLoading(true);
    //     let url = `/demand/relevantDemands/${propertyId}`;
    //     const res = await getData(url, {}, {});
    //     if (res.status) {
    //         // setProptyData(res.data.properties);
    //         setLoading(false);
    //     } else {
    //         setLoading(false);
    //     }

    // };
    const getResponseProperty = async () => {
        setLoading(true);
        let url = `/property/propertyResponses/property?id=${propertyId}`;
        const res = await getData(url, {}, {});
        if (res.status) {
            setResponsePropertyData(res.data)
            setLoading(false);
        } else {
            setLoading(false);
        }

    };
    useEffect(() => {
        getResponseProperty();
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handelClickNavigateChat = async(id) => {
          let url = `/chat/addMember/${getCurrentUser()?._id}`;
    let payload = {
      "userId": id,
      "type": "private",
      "propertyId": propertyId
    }
    const newData = await postGetData(url, {}, payload);
    const { statusCode, data } = newData;
    if (statusCode === 200) {
      navigate("/profile/property-response/chat", { state: { roomId: data } })
    }else{
        // alert('room Id Not Found')
    }
        // navigate("/chat")
    }

    return <div className="mt-5 ">
        {
            propertyResponseData.length === 0 && <div className="text-center f-16 fw-500"> No Responses Found</div>
        }

        {propertyResponseData.length > 0 && propertyResponseData.map((property, index) => (
            <div className="existing-property-box p-3" key={index} >
                    <div className="row content m-0">
                        <div className="d-flex justify-content-between p-0">
                            <div className="d-flex" style={{alignItems:"center"}}>
                                <div className="profile-image">
                                    {property?.logo?.viewUrl?<img src={property?.logo?.viewUrl ? property?.logo?.viewUrl : Image} alt="profileImage " />:null}
                                </div>
                                <h5 className="px-3">{property?.name}</h5>
                            </div>
                            <div className="d-flex align-items-center">
                            </div>
                        </div>
                    </div>
            </div>
        ))}


        
    </div>;
};
export default ReleventResponse;

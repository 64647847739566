import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { getData, postData, putData } from "../../api";
import Button from "../../components/Form/Button";
import Loader from "../../components/Loader";
import "./style.scss";
import { companyNameFormatter } from "../reports/userReports/reportTabels/agent/agentFormatter";

function RoleContentModal(props) {
  const [formInputs, setFormInputs] = useState({
    name: "",
    permissions: [],
    type: "admin",
  });
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(true);
  let menuListData = [...props?.menuList];
  const [menuList, setMenuList] = useState(menuListData);

  const handleInput = (e) => {
    setFormInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const moveUserReports = (menuData) => {
    const statisticalReportsIndex = menuData.findIndex(
      (item) => item.name === "reports"
    );
    const userReportsIndex = menuData.findIndex(
      (item) => item.name === "userReports"
    );
    if (statisticalReportsIndex !== -1 && userReportsIndex !== -1) {
      const removedUserReports = menuData.splice(userReportsIndex, 1)[0];
      menuData.splice(statisticalReportsIndex + 1, 0, removedUserReports);
    }

    return menuData
  };

  const getRole = async (id) => {
    setIsLoading(true);
    let url = `/role/${id}`;

    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setIsLoading(false);
      setFormInputs(data);
      let editOption =
        data.name === "Relationship Manager" ||
        data.name === "Business Development Executive"
          ? false
          : true;
      setEdit(editOption);
      let menu = [...menuList];
      menu?.forEach((item) => {
        if (data?.permissions?.includes(item?._id)) {
          item.checked = true;
          item.isOpen = false;
        } else {
          item.checked = false;
          item.isOpen = false;
        }
      });

      const newMenu = moveUserReports(menu)

      setMenuList(newMenu);
    } else {
      setFormInputs({ name: "", permissions: [], type: "" });
    }
  };
  const getMenuId = (parentkey, childkeys = "") => {
    const result = [...menuList]
      .filter((item) => item?.name === parentkey)?.[0]
      ?.child?.find((childItem) => childItem?.name === childkeys)?._id;

    return result;
  };

  const getParentMenuId = (parentkey) => {
    const result = [...menuList]
      .filter((item) => item?.name === parentkey)[0]?._id

    return result;
  };

  const handleAccessPrev = (e, event, parentName) => {
    let val = e.target.value;
    let name = e.target.name;
    let addtionalValue = null;
    let lookUp = {
      addSellerProperty: () => getMenuId(parentName, "viewSellerCustomers"),
      addDemand: () => getMenuId(parentName, "viewBuyerCustomers"),
      addAgentProperty: () => getMenuId(parentName, "viewAgentCustomers"),
      viewAgentCustomers: () => getMenuId(parentName, "addAgentProperty"),
      viewBuyerCustomers: () => getMenuId(parentName, "addDemand"),
      viewSellerCustomers: () => getMenuId(parentName, "addSellerProperty"),
      enterpriseBuyerDashboard: () => getMenuId(parentName, "enterpriseBuyerSwitch"),
      enterpriseSellerDashboard: () => getMenuId(parentName, "enterpriseSellerSwitch"),
      agentDashboard: () => getMenuId(parentName, "agentSwitch"),
      getDashbaord: () => getParentMenuId(parentName)
    };
    if (e.target.checked === true) {
      if (event === "parent") {
        let menu = [...menuList];
        menu?.forEach((item) => {
          if (item._id === val) {
            item.checked = true;
            item.isOpen = true;
          }
        });
        setMenuList(menu);
      }
      if (
        ["addSellerProperty", "addDemand", "addAgentProperty"].includes(name)
      ) {
        addtionalValue = lookUp[name]();
      }

      let finalValue = addtionalValue ? [val, addtionalValue] : [val];
      setFormInputs((prev) => ({
        ...prev,
        permissions: [...prev.permissions, ...finalValue],
      }));
    } else {
      let dupArr = [...formInputs.permissions];

      if (event === "parent") {
        let index = dupArr.indexOf(val);
        if (index !== -1) {
          dupArr.splice(index, 1);
        }
        let menu = [...menuList];
        menu?.forEach((item) => {
          if (item._id === val) {
            item?.child.forEach((value) => {
              if (dupArr.includes(value._id)) {
                let index = dupArr.indexOf(value._id);
                if (index !== -1) {
                  dupArr.splice(index, 1);
                }
              }
            });
            item.checked = false;
            item.isOpen = false;
          }
        });

        setMenuList(menu);
      } else {
        if (
          [
            "viewSellerCustomers",
            "viewBuyerCustomers",
            "viewAgentCustomers",
            "enterpriseBuyerDashboard",
            "enterpriseSellerDashboard",
            "agentDashboard"
          ].includes(name)
        ) {
          addtionalValue = lookUp[name]();
        }
        dupArr = [...dupArr].filter(
          (item) => ![val, addtionalValue].includes(item)
        );
      }
      if(parentName === "dashboard") {
          const isDashboard = removeDashboard(parentName, dupArr)
          if(isDashboard) {
            const dashboardId = lookUp["getDashbaord"]()
            dupArr = [...dupArr].filter(
              (item) => ![dashboardId].includes(item)
            );
          }
      }

      setFormInputs((prev) => ({
        ...prev,
        permissions: dupArr,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (formInputs.name && formInputs.permissions.length > 0) {
      if (props.roleId) {
        delete formInputs._id;
        const res = await putData(`/role/${props.roleId}`, {}, formInputs);
        if (res.statusCode === 200) {
          props.onClose();
          props.apiCall();
        }
      } else {
        const res = await postData("/role", {}, formInputs);
        if (res.statusCode === 200) {
          props.onClose();
          props.apiCall();
        }
      }
    }
  };

  const handleOnSelect = (e, id) => {
    let menu = [...menuList];
    menu?.forEach((item) => {
      if (item._id === id) {
        item.isOpen = !item.isOpen;
      }
    });
    setMenuList(menu);
  };
  useEffect(() => {
    if (props && props.roleId) {
      getRole(props.roleId);
    } else {
      let menu = [...menuList];
      menu?.forEach((item) => {
        item.checked = false;
        item.isOpen = false;
      });
      setMenuList(menu);
    }
  }, []);

  const disabledDashbaord = (name, parentName) => {
    if(name === 'enterpriseBuyerSwitch') {
      const menuId = getMenuId(parentName, "enterpriseBuyerDashboard")
      return ![...formInputs.permissions].includes(menuId)
    }
    if(name === 'enterpriseSellerSwitch') {
      const menuId = getMenuId(parentName, "enterpriseSellerDashboard")
      return ![...formInputs.permissions].includes(menuId)
    }
    if(name === 'agentSwitch') {
      const menuId = getMenuId(parentName, "agentDashboard")
      return ![...formInputs.permissions].includes(menuId)
    }

    return false
  }

  const removeDashboard  = (parentName, dupArr) => {
      const buyerMenuId = getMenuId(parentName, "enterpriseBuyerDashboard")
      const noBuyerDashboard =  ![...dupArr].includes(buyerMenuId)
      const sellerMenuId = getMenuId(parentName, "enterpriseSellerDashboard")
      const noSellerDashbaord =  ![...dupArr].includes(sellerMenuId)
      const agentMenuId = getMenuId(parentName, "agentDashboard")
      const noAgentDashboard = ![...dupArr].includes(agentMenuId)
      if(noBuyerDashboard && noSellerDashbaord && noAgentDashboard) {
        return true
      }else {
        return false
      }

  }

  return (
    <div className="manage-roles roles-error">
      <Form
        noValidate
        validated={validated}
        className="userNoSelect"
        onSubmit={handleSubmit}
      >
        <Form.Group as={Row} controlId="role_name">
          <Form.Label column sm="3" className="required">
            Role Name
          </Form.Label>
          <Col sm="9">
            <Form.Control
              name="name"
              type="text"
              required
              placeholder="Role Name"
              value={formInputs.name}
              onChange={handleInput}
              disabled={edit ? false : true}
            />
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-3" controlId="access_privilages">
          {menuList &&
            menuList.map((item, key) => (
              <>
                <Form.Label column sm="3" className="required" key={key}>
                  {key === 0 ? "Permissions" : null}
                </Form.Label>

                <Col sm="7">
                  {item.child && item.child.length ? (
                    <div>
                      <div className="d-flex justify-content-between parent-header">
                        <Form.Group
                          controlId={key + 1}
                          className="mb-3 d-flex justify-content-between w-100"
                        >
                          <Form.Check
                            name={item.name}
                            checked={
                              formInputs.permissions.includes(item._id)
                                ? true
                                : false
                            }
                            value={item._id}
                            label={item.label}
                            onChange={(e) => handleAccessPrev(e, "parent")}
                            key={key}
                          />
                          {item?.isOpen ? (
                            <Button className={"table-btn p-0"} type={"button"}>
                              <FaAngleUp
                                onClick={(e) => handleOnSelect(e, item?._id)}
                              />
                            </Button>
                          ) : (
                            <Button
                              type={"button"}
                              className={"table-btn p-0"}
                              disabled={item.checked ? false : true}
                            >
                              <FaAngleDown
                                onClick={(e) => handleOnSelect(e, item?._id)}
                              />
                            </Button>
                          )}
                        </Form.Group>

                        {/* <span >
                          check
                        </span> */}
                      </div>
                      <div
                        className={
                          item?.checked && item?.isOpen
                            ? "d-block child-elements"
                            : "d-none"
                        }
                      >
                        {item.child.map((val, index) => (
                          <Form.Group
                            controlId={key + index + 10}
                            className="ml-3 form-check"
                          >
                            <Form.Check
                              key={index}
                              name={val.name}
                              checked={
                                formInputs.permissions.includes(val._id)
                                  ? true
                                  : false
                              }
                              value={val._id}
                              label={val.label}
                              onChange={(e) =>
                                handleAccessPrev(e, "child", item.name)
                              }
                              disabled = {disabledDashbaord(val?.name, item?.name)}
                            />
                          </Form.Group>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Form.Group controlId={key + 1} className="mb-3 ">
                      <Form.Check
                        name={item.name}
                        checked={
                          formInputs.permissions.includes(item._id)
                            ? true
                            : false
                        }
                        value={item._id}
                        label={item.label}
                        onChange={handleAccessPrev}
                        key={key}
                      />
                    </Form.Group>
                  )}
                </Col>
              </>
            ))}
          {validated === true && formInputs.permissions.length === 0 && (
            <div className="err-feedback text-danger mt-2">
              Please select atleast one role
            </div>
          )}
        </Form.Group>
        <div className="d-flex justify-content-end mt-5">
          <Button className="btn-color-primary" type="submit">
            {props.roleId ? "Update" : "Save"} Role
          </Button>
        </div>
      </Form>
      {isLoading && <Loader />}
    </div>
  );
}

export default RoleContentModal;

import { washroomData } from "../../components/DropdownOptions";
import { coworkPossessionList, possessionList, transactionType, ocStatusList, floorOption, ownerShip, ageProperty, floorOffice, WashroomsList, parkingList, buildingStructure, rentFree, closeTimeList, fitOutDataOffice, requiredDocuments, transactionTypeOffice } from "./PostDemandForms/dropDownValues";

export const fieldInitialValue = [
  {
    key: "Retail",
    value: [
      {
        step: 1,
        intialValue: {
          userId: { _id: "", name: "" },
          brandId: "",
          propertyTypeRequired: true,
          propertyType: "",
          brandNameRequired: false,
          brandName: "",
          location: "",
          locationRequired: true,
          carpetAreaMin: 0,
          carpetAreaMax: 0,
          carpetAreaRequired: true,
          otherInterestedLocation: [],
          otherInterestedLocationRequired: false,
          endUse: "",
          endUseRequired: true,
          possessionFrom: "",
          possessionFromRequired: true,
          // googleLocationRequired: false,
          // imageRequired: false,
          // videoRequired: false,
          floor: "",
          floorRequired: false,
          additionalDemandDetails: "",
          additionalDemandDetailsRequired: false,
          rentPsfMin: 0,
          rentPsfMax: 0,
          rentPsfRequired: false,
          ocStatus: "",
          ocStatusRequired: false,
          microLocation: { name: "", _id: "" },
          microLocationRequired: true,
          transactionTypeRequired: true,
          transactionType: "",
          frontage: "",
          frontageRequired: false,
          accessRoad: "",
          accessRoadRequired: false,
          propertyAge: "",
          propertyAgeRequired: false,
          buildingStructure: [],
          buildingStructureRequired: false,
          ceilingHeight: "",
          ceilingHeightRequired: false,
          ownerShip: "",
          ownerShipRequired: false,
          toiletRequired: false,
          powerBackupRequired: false,
          fitOutsRequired: false,
          parkingRequired: false,
          otherAmenities: [],
          otherAmenitiesRequired: false,
          tenure: "",
          tenureRequired: false,
          brokerageRequired: false,
          // transactionTimeline: "",
          // transactionTimelineRequired: false,
          lockInPeriod: "",
          lockInPeriodRequired: false,
          rentFreePeriod: "",
          rentFreePeriodRequired: false,
          floorPlansRequired: false,
          autoCadRequired: false,
          fireNocRequired: false,
          otherLicenses: "",
          otherLicensesRequired: false,
          response: false,
          mezzainineRequired: false,
          pincode: "",
          pincodeRequired: false,
          fitOutsStatus: false,
          toiletStatus: false,
          parkingStatus: false,
          mezzainineStatus: false,
          requiredDocuments: []
        },
        fieldArray: [
          {
            fieldShow: ["Retail", "Office space"],
            label: "Transaction Type",
            name: "transactionType",
            placeholder: "Select Transaction Type",
            type: "select",
            inputType: "",
            option: transactionType,
            checkboxOnly: false,
            defaultChecked: true,
            requried: "transactionTypeRequired",
            fieldValue: "transactionType",

          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Select POC",
            name: "userId",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "pocRequired",
            fieldValue: "userId",

          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "City *",
            name: "location",
            placeholder: "Select Transaction Type",
            type: "SearchSelect",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "locationRequired",
            fieldValue: "location",

          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Pin Code",
            name: "pincode",
            placeholder: "Enter Pin Code",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "pincodeRequired",
            fieldValue: "pincode",
            max: 6
          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Location",
            name: "microLocation",
            placeholder: "Search by location",
            type: "GoogleSearchLocation",
            isMulti: false,
            checkboxOnly: false,
            defaultChecked: true,
            requried: "microLocationRequired",
            fieldValue: "microLocation",

          },

          {
            fieldShow: ["Retail", "Office space"],
            label: "Other interested locations",
            name: "otherInterestedLocation",
            placeholder: "Search by location",
            type: "GoogleSearchLocation",
            isMulti: true,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "otherInterestedLocationRequired",
            fieldValue: "otherInterestedLocation",

          },
          {
            fieldShow: ["Retail"],
            label: "Carpet Area in sqft",
            name: "carpetAreaMin",
            placeholder: "Search by location",
            type: "rangeInput",
            rangefields: [
              {
                type: "input",
                inputType: "number",
                fieldValue: "carpetAreaMin",
                placeholder: "Min-Range",
                name: "carpetAreaMin",
                sufix: "sqft"

              },
              {
                type: "input",
                inputType: "number",
                fieldValue: "carpetAreaMax",
                placeholder: "Max-Range",
                name: "carpetAreaMax",
                sufix: "sqft"
              }

            ],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "carpetAreaRequired",
          },

          {
            fieldShow: ["Retail", "Office space"],
            label: "Possession needed from",
            name: "possessionFrom",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: possessionList,
            checkboxOnly: false,
            defaultChecked: true,
            requried: "possessionFromRequired",
            fieldValue: "possessionFrom",

          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "End Use",
            name: "endUse",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "endUseRequired",
            fieldValue: "endUse",

          },
          {
            fieldShow: ["Retail"],
            label: "Rent budget per sqft",
            name: "rentPsfMin",
            requried: "rentPsfRequired",
            type: "rangeInput",
            rangefields: [
              {
                type: "input",
                inputType: "number",
                fieldValue: "rentPsfMin",
                placeholder: "Min-Range",
                name: "rentPsfMin",
                prefix: "Rs",
              },
              {
                type: "input",
                inputType: "number",
                fieldValue: "rentPsfMax",
                placeholder: "Max-Range",
                name: "rentPsfMax",
                prefix: "Rs",
              }

            ],
            checkboxOnly: false,
            defaultChecked: false,
          },
          {
            fieldShow: ["Retail"],
            label: "OC required",
            name: "ocStatus",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: ocStatusList,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "ocStatusRequired",
            fieldValue: "ocStatus",
          },
          // {
          //  fieldShow:["Retail","Office space"],
          //  label:"Google location required",
          //  name:"googleLocationRequired",
          //  type:"checkBox",
          //  checkboxOnly:true,
          //  defaultChecked:false,
          //  requried:"googleLocationRequired",

          // },
          // {
          //  fieldShow:["Retail"],
          //  label:"Property images required",
          //  name:"imageRequired",
          //  type:"checkBox",
          //  checkboxOnly:true,
          //  defaultChecked:false,
          //  requried:"imageRequired",

          // },
          // {
          //  fieldShow:["Retail"],
          //  label:"Property videos required",
          //  name:"videoRequired",
          //  type:"checkBox",
          //  checkboxOnly:true,
          //  defaultChecked:false,
          //  requried:"videoRequired",

          // },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Additional Demand Details",
            name: "additionalDemandDetails",
            placeholder: "Enter additional demand details",
            type: "textArea",
            inputType: "text",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "additionalDemandDetailsRequired",
            fieldValue: "additionalDemandDetails",
          },
          {
            fieldShow: ["Retail"],
            label: "Preferred floor",
            name: "floor",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: floorOption,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "floorRequired",
            fieldValue: "floor",
          },
        ],

      },
      {
        step: 2,
        intialValue: {
          frontage: "",
          frontageRequired: false,
          // accessRoad: "",
          // accessRoadRequired: false,
          propertyAge: "",
          propertyAgeRequired: false,
          buildingStructure: [],
          buildingStructureRequired: false,
          ceilingHeight: "",
          ceilingHeightRequired: false,
          ownerShip: "",
          ownerShipRequired: false,
          response: false,
        },
        fieldArray: [{
          fieldShow: ["Retail"],
          label: "Minimum clear ceiling height in feet",
          name: "ceilingHeight",
          placeholder: "Enter ceiling height in feet",
          type: "input",
          inputType: "number",
          checkboxOnly: false,
          defaultChecked: false,
          requried: "ceilingHeightRequired",
          fieldValue: "ceilingHeight",
          sufix: "ft",

        },
        {
          fieldShow: ["Retail"],
          label: "Minimum Frontage in feet",
          name: "frontage",
          placeholder: "Enter frontage in feet",
          type: "input",
          inputType: "number",
          checkboxOnly: false,
          defaultChecked: false,
          requried: "frontageRequired",
          fieldValue: "frontage",
          sufix: "ft",

        },
        // {
        //   fieldShow:["Retail"],
        //   label:"Minimum Access Road Width in feet",
        //   name:"accessRoad",
        //   placeholder:"Enter access road width",
        //   type:"input",
        //   inputType:"number",
        //   checkboxOnly:false,
        //   defaultChecked:false,
        //   requried:"accessRoadRequired",
        //   fieldValue:"accessRoad",
        //   sufix:"ft",

        //  },

        {
          fieldShow: ["Retail", "Office Space"],
          label: "Store type",
          name: "buildingStructure",
          placeholder: "Select",
          type: "multiSelect",
          inputType: "",
          option: buildingStructure,
          checkboxOnly: false,
          defaultChecked: false,
          requried: "buildingStructureRequired",
          fieldValue: "buildingStructure",
        },
        {
          fieldShow: ["Retail"],
          label: "Ownership",
          name: "ownerShip",
          placeholder: "Select",
          type: "select",
          inputType: "",
          option: ownerShip,
          checkboxOnly: false,
          defaultChecked: false,
          requried: "ownerShipRequired",
          fieldValue: "ownerShip",
        },
        {
          fieldShow: ["Retail"],
          label: "Age of property",
          name: "propertyAge",
          placeholder: "Select",
          type: "select",
          inputType: "",
          option: ageProperty,
          checkboxOnly: false,
          defaultChecked: false,
          requried: "propertyAgeRequired",
          fieldValue: "propertyAge",
        },
        ]
      },
      {
        step: 3,
        intialValue: {
          toiletRequired: false,
          // powerBackupRequired: false,
          fitOutsRequired: false,
          parkingRequired: false,
          otherAmenities: [],
          otherAmenitiesRequired: false,
          tenure: "",
          tenureRequired: false,
          brokerageRequired: false,
          // transactionTimeline: "",
          // transactionTimelineRequired: false,
          lockInPeriod: "",
          lockInPeriodRequired: false,
          // rentFreePeriod: "",
          // rentFreePeriodRequired: false,
          response: false,
          mezzainineRequired: false,
        },
        fieldArrayAmenties: [

          // {
          //   fieldShow:["Retail","Office space"],
          //   label:"Power backup required",
          //   name:"powerBackupRequired",
          //   type:"checkBox",
          //   checkboxOnly:true,
          //   defaultChecked:false,
          //   requried:"powerBackupRequired",

          //  },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Fitouts required",
            name: "fitOutsStatus",
            placeholder: "Select",
            type: "select",
            option: ocStatusList,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "fitOutsRequired",
            fieldValue: "fitOutsStatus",
            isHideSelect: false
          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Toilet required",
            name: "toiletStatus",
            placeholder: "Select",
            type: "select",
            option: ocStatusList,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "toiletRequired",
            fieldValue: "toiletStatus"
          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Parking required",
            name: "parkingStatus",
            placeholder: "Select",
            option: ocStatusList,
            type: "select",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "parkingRequired",
            fieldValue: "parkingStatus"
          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Mezzanine required",
            name: "mezzainineStatus",
            type: "select",
            placeholder: "Select",
            option: ocStatusList,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "mezzainineRequired",
            fieldValue: "mezzainineStatus"
          },
          {
            fieldShow: ["Retail", "Office Space"],
            label: "Other amenities required",
            name: "otherAmenities",
            placeholder: "Select",
            type: "multiSelect",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: false,
            requried: "otherAmenitiesRequired",
            fieldValue: "otherAmenities",
          },


        ],
        fieldTransaction: [
          // {
          //   fieldShow:["Retail"],
          //   label:"Transaction closure timeline",
          //   name:"transactionTimeline",
          //   placeholder:"Select",
          //   type:"select",
          //   inputType:"",
          //   option:closeTimeList,
          //   checkboxOnly:false,
          //   defaultChecked:false,
          //   requried:"transactionTimelineRequired",
          //   fieldValue:"transactionTimeline",
          //  },
          {
            fieldShow: ["Retail"],
            label: "Lease tenure in years",
            name: "tenure",
            placeholder: "Enter tenure in years",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "tenureRequired",
            fieldValue: "tenure",
            sufix: "years",
            onFocus: true,
            onBlur: true,

          },
          {
            fieldShow: ["Retail"],
            label: "Lockin period",
            name: "lockInPeriod",
            placeholder: "Enter tenure in years",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "lockInPeriodRequired",
            fieldValue: "lockInPeriod",
            sufix: "years",


          },

          //  {
          //   fieldShow:["Retail"],
          //   label:"Fit-out/Rent free period",
          //   name:"rentFreePeriod",
          //   placeholder:"Select",
          //   type:"select",
          //   inputType:"",
          //   option:rentFree,
          //   checkboxOnly:false,
          //   defaultChecked:false,
          //   requried:"rentFreePeriodRequired",
          //   fieldValue:"rentFreePeriod",
          //  },

          {
            fieldShow: ["Retail", "Office space"],
            label: "Willing to pay brokerage?",
            name: "brokerageRequired",
            type: "checkBox",
            checkboxOnly: true,
            defaultChecked: false,
            requried: "brokerageRequired",

          },

        ]
      },
      {
        step: 4,
        intialValue: {
          floorPlansRequired: false,
          autoCadRequired: false,
          fireNocRequired: false,
          otherLicenses: "",
          otherLicensesRequired: false,
          response: false,
          requiredDocuments: []
        },
        fieldArray: [
          // {
          //   fieldShow:["Retail","Office space"],
          //   label:"Floorplans Required",
          //   name:"floorPlansRequired",
          //   type:"checkBox",
          //   checkboxOnly:true,
          //   defaultChecked:false,
          //   requried:"floorPlansRequired",

          //  },
          // {
          //   fieldShow:["Retail","Office space"],
          //   label:"Autocad Required",
          //   name:"autoCadRequired",
          //   type:"checkBox",
          //   checkboxOnly:true,
          //   defaultChecked:false,
          //   requried:"autoCadRequired",

          //  },
          // {
          //   fieldShow:["Retail","Office space"],
          //   label:"Fire NOC Required",
          //   name:"fireNocRequired",
          //   type:"checkBox",
          //   checkboxOnly:true,
          //   defaultChecked:false,
          //   requried:"fireNocRequired",

          //  },
          {
            fieldShow: ["Retail", "Office Space"],
            label: "Required Documents",
            name: "requiredDocuments",
            placeholder: "Select",
            type: "multiSelect",
            inputType: "",
            option: requiredDocuments,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "documentsRequired",
            fieldValue: "requiredDocuments",
          },
          //  {
          //   fieldShow:["Retail"],
          //   label:"Other licenses",
          //   name:"otherLicenses",
          //   placeholder:"Enter other licenses",
          //   type:"input",
          //   inputType:"text",
          //   checkboxOnly:false,
          //   defaultChecked:false,
          //   requried:"otherLicensesRequired",
          //   fieldValue:"otherLicenses",

          //  },
        ]
      },
      {
        step: 5,

        intialValue: {
          userId: { _id: "", name: "" },
          brandId: "",
          propertyTypeRequired: true,
          propertyType: "",
          brandNameRequired: false,
          brandName: "",
          location: "",
          locationRequired: true,
          carpetAreaMin: 0,
          carpetAreaMax: 0,
          carpetAreaRequired: true,
          otherInterestedLocation: [],
          otherInterestedLocationRequired: false,
          endUse: "",
          endUseRequired: true,
          possessionFrom: "",
          possessionFromRequired: true,
          googleLocationRequired: false,
          imageRequired: false,
          videoRequired: false,
          floor: "",
          floorRequired: false,
          additionalDemandDetails: "",
          additionalDemandDetailsRequired: false,
          rentPsfMin: 0,
          rentPsfMax: 0,
          rentPsfRequired: false,
          ocStatus: "",
          ocStatusRequired: false,
          microLocation: { name: "", _id: "" },
          microLocationRequired: true,
          transactionTypeRequired: true,
          transactionType: "",
          frontage: "",
          frontageRequired: false,
          accessRoad: "",
          accessRoadRequired: false,
          propertyAge: "",
          propertyAgeRequired: false,
          buildingStructure: [],
          buildingStructureRequired: false,
          ceilingHeight: "",
          ceilingHeightRequired: false,
          ownerShip: "",
          ownerShipRequired: false,
          toiletRequired: false,
          powerBackupRequired: false,
          fitOutsRequired: false,
          parkingRequired: false,
          otherAmenities: [],
          otherAmenitiesRequired: false,
          tenure: "",
          tenureRequired: false,
          brokerageRequired: false,
          // transactionTimeline: "",
          // transactionTimelineRequired: false,
          lockInPeriod: "",
          lockInPeriodRequired: false,
          rentFreePeriod: "",
          rentFreePeriodRequired: false,
          floorPlansRequired: false,
          autoCadRequired: false,
          fireNocRequired: false,
          otherLicenses: "",
          otherLicensesRequired: false,
          response: false,
          mezzainineRequired: false,
          pincode: "",
          pincodeRequired: false
        },
      }
    ],

  },
  {
    key: "Office space",
    value: [
      {
        step: 1,
        intialValue: {
          userId: { _id: "", name: "" },
          propertyTypeRequired: true,
          propertyType: "",
          brandNameRequired: false,
          brandName: "",
          location: "",
          locationRequired: true,
          carpetAreaMin: 0,
          carpetAreaMax: 0,
          carpetAreaRequired: true,
          otherInterestedLocation: [],
          otherInterestedLocationRequired: false,
          endUse: "",
          endUseRequired: true,
          possessionFrom: "",
          possessionFromRequired: true,
          googleLocationRequired: false,
          additionalDemandDetails: "",
          additionalDemandDetailsRequired: false,
          rentPsfMin: 0,
          rentPsfMax: 0,
          rentPsfRequired: false,
          microLocation: { name: "", _id: "" },
          microLocationRequired: true,
          transactionTypeRequired: true,
          transactionType: "",
          brokerageRequired: false,
          lockInPeriod: "",
          lockInPeriodRequired: false,
          propertyAge: "",
          propertyAgeRequired: false,
          floor: "",
          floorRequired: false,
          otherAmenities: [],
          otherAmenitiesRequired: false,
          rentFreePeriod: "",
          bikeParking: "",
          carParking: "",
          washRoom: "",
          fitOuts: "",
          rentFreePeriodRequired: false,
          bikeParkingRequired: false,
          carParkingRequired: false,
          washRoomRequired: false,
          fitOutsRequired: false,
          response: false,
          pincode: "",
          pincodeRequired: false,
          floorPlansRequired: false,
          autoCadRequired: false,
          fireNocRequired: false,
          otherLicenses: "",
          otherLicensesRequired: false,
          frontage: "",
          frontageRequired: false,
          // propertyAge: "",
          // propertyAgeRequired: false,
          buildingStructure: [],
          buildingStructureRequired: false,
          ceilingHeight: "",
          ceilingHeightRequired: false,
          ownerShip: "",
          ownerShipRequired: false,
          toiletRequired: false,
          // powerBackupRequired: false,
          // fitOutsRequired: false,
          parkingRequired: false,
          // otherAmenities: [],
          // otherAmenitiesRequired: false,
          tenure: "",
          tenureRequired: false,
          // brokerageRequired: false,
          // transactionTimeline: "",
          // transactionTimelineRequired: false,
          // lockInPeriod: "",
          // lockInPeriodRequired: false,
          // rentFreePeriod: "",
          // rentFreePeriodRequired: false,
          // response: false,
          mezzainineRequired: false,
          pincodeRequired: false,
          ocStatus: "",
          ocStatusRequired: false,
          requiredDocuments: [],
          fitOuts: ""
          // bikeParking: "",
          // carParking: "",
          // washRoom: "",
          // bikeParkingRequired: false,
          // carParkingRequired: false,
          // washRoomRequired: false,
        },
        fieldArray: [
          {
            fieldShow: ["Retail", "Office space"],
            label: "Transaction Type",
            name: "transactionType",
            placeholder: "Select Transaction Type",
            type: "select",
            inputType: "",
            option: transactionTypeOffice,
            checkboxOnly: false,
            defaultChecked: true,
            requried: "transactionTypeRequired",
            fieldValue: "transactionType",

          },

          {
            fieldShow: ["Retail", "Office space"],
            label: "Select POC",
            name: "userId",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "pocRequired",
            fieldValue: "userId",

          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "City *",
            name: "location",
            placeholder: "Select",
            type: "SearchSelect",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "locationRequired",
            fieldValue: "location",

          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Pin Code",
            name: "pincode",
            placeholder: "Enter Pin Code",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "pincodeRequired",
            fieldValue: "pincode",
            max: 6
          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Location",
            name: "microLocation",
            placeholder: "Search by location",
            type: "GoogleSearchLocation",
            isMulti: false,
            checkboxOnly: false,
            defaultChecked: true,
            requried: "microLocationRequired",
            fieldValue: "microLocation",

          },

          {
            fieldShow: ["Retail", "Office space"],
            label: "Other interested locations",
            name: "otherInterestedLocation",
            placeholder: "Search by location",
            type: "GoogleSearchLocation",
            isMulti: true,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "otherInterestedLocationRequired",
            fieldValue: "otherInterestedLocation",

          },

          {
            fieldShow: ["Office space"],
            label: "Carpet Area range in square feet",
            name: "carpetAreaMin",
            placeholder: "Search by location",
            type: "rangeInput",
            rangefields: [

              {
                type: "input",
                inputType: "number",
                fieldValue: "carpetAreaMin",
                placeholder: "Min-Range",
                name: "carpetAreaMin",
                sufix: "sqft"

              },
              {
                type: "input",
                inputType: "number",
                fieldValue: "carpetAreaMax",
                placeholder: "Max-Range",
                name: "carpetAreaMax",
                sufix: "sqft"
              }

            ],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "carpetAreaRequired",
          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Possession needed from",
            name: "possessionFrom",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: possessionList,
            checkboxOnly: false,
            defaultChecked: true,
            requried: "possessionFromRequired",
            fieldValue: "possessionFrom",

          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "End Use",
            name: "endUse",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "endUseRequired",
            fieldValue: "endUse",

          },

          {
            fieldShow: ["Office space"],
            label: "Rent budget per square feet on carpet area",
            name: "rentPsfMin",
            requried: "rentPsfRequired",
            type: "rangeInput",
            rangefields: [
              {
                type: "input",
                inputType: "number",
                fieldValue: "rentPsfMin",
                placeholder: "Min-Range",
                name: "rentPsfMin",
                prefix: "Rs",
              },
              {
                type: "input",
                inputType: "number",
                fieldValue: "rentPsfMax",
                placeholder: "Max-Range",
                name: "rentPsfMax",
                prefix: "Rs",
              }

            ],
            checkboxOnly: false,
            defaultChecked: false,
          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "OC required",
            name: "ocStatus",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: ocStatusList,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "ocStatusRequired",
            fieldValue: "ocStatus",
          },

          {
            fieldShow: ["Retail", "Office space"],
            label: "Google location required",
            name: "googleLocationRequired",
            type: "checkBox",
            checkboxOnly: true,
            defaultChecked: false,
            requried: "googleLocationRequired",

          },


          {
            fieldShow: ["Retail", "Office space"],
            label: "Willing to pay brokerage?",
            name: "brokerageRequired",
            type: "checkBox",
            checkboxOnly: true,
            defaultChecked: false,
            requried: "brokerageRequired",

          },
          {
            fieldShow: ["Retail"],
            label: "Lease tenure in years",
            name: "tenure",
            placeholder: "Enter tenure in years",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "tenureRequired",
            fieldValue: "tenure",
            sufix: "years",
            onFocus: true,
            onBlur: true,

          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Lockin period",
            name: "lockInPeriod",
            placeholder: "Enter lockIn period",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "lockInPeriodRequired",
            fieldValue: "lockInPeriod",
            sufix: "years",


          },
          {
            fieldShow: ["Retail", "Office space"],
            label: "Additional Demand Details",
            name: "additionalDemandDetails",
            placeholder: "Enter additional demand details",
            type: "textArea",
            inputType: "text",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "additionalDemandDetailsRequired",
            fieldValue: "additionalDemandDetails",
          },


        ]
      },
      {
        step: 2,
        intialValue: {
          propertyAge: "",
          propertyAgeRequired: false,
          floor: "",
          floorRequired: false,
          otherAmenities: [],
          otherAmenitiesRequired: false,
          rentFreePeriod: "",
          bikeParking: "",
          carParking: "",
          washRoom: "",
          fitOuts: "",
          rentFreePeriodRequired: false,
          bikeParkingRequired: false,
          carParkingRequired: false,
          washRoomRequired: false,
          fitOutsRequired: false,
          response: false
        },
        fieldArray: [{
          fieldShow: ["Retail"],
          label: "Age of property",
          name: "propertyAge",
          placeholder: "Select",
          type: "select",
          inputType: "",
          option: ageProperty,
          checkboxOnly: false,
          defaultChecked: false,
          requried: "propertyAgeRequired",
          fieldValue: "propertyAge",
        },
        {
          fieldShow: ["Retail"],
          label: "Floor",
          name: "floor",
          placeholder: "Select",
          type: "select",
          inputType: "",
          option: floorOffice,
          checkboxOnly: false,
          defaultChecked: false,
          requried: "floorRequired",
          fieldValue: "floor",
        },
        {
          fieldShow: ["Retail", "Office Space"],
          label: "Amenities required",
          name: "otherAmenities",
          placeholder: "Select",
          type: "multiSelect",
          inputType: "",
          option: [],
          checkboxOnly: false,
          defaultChecked: false,
          requried: "otherAmenitiesRequired",
          fieldValue: "otherAmenities",
        },
        {
          fieldShow: ["Retail"],
          label: "Toilets required",
          name: "washRoom",
          placeholder: "Select",
          type: "select",
          inputType: "",
          option: ocStatusList,
          checkboxOnly: false,
          defaultChecked: false,
          requried: "washRoomRequired",
          fieldValue: "washRoom",
        },
        {
          fieldShow: ["Retail"],
          label: "Bike parking required",
          name: "bikeParking",
          placeholder: "Select",
          type: "select",
          inputType: "",
          option: ocStatusList,
          checkboxOnly: false,
          defaultChecked: false,
          requried: "bikeParkingRequired",
          fieldValue: "bikeParking",
        },
        {
          fieldShow: ["Retail"],
          label: "Car parking required",
          name: "carParking",
          placeholder: "Select",
          type: "select",
          inputType: "",
          option: ocStatusList,
          checkboxOnly: false,
          defaultChecked: false,
          requried: "carParkingRequired",
          fieldValue: "carParking",
        },

        {
          fieldShow: ["Retail"],
          label: "Fit-out Status",
          name: "fitOuts",
          placeholder: "Select",
          type: "select",
          inputType: "",
          option: fitOutDataOffice,
          checkboxOnly: false,
          defaultChecked: false,
          requried: "fitOutsRequired",
          fieldValue: "fitOuts",
        },
          //  {
          //   fieldShow:["Retail"],
          //   label:"Fit-out/Rent free period",
          //   name:"rentFreePeriod",
          //   placeholder:"Select",
          //   type:"select",
          //   inputType:"",
          //   option:rentFree,
          //   checkboxOnly:false,
          //   defaultChecked:false,
          //   requried:"rentFreePeriodRequired",
          //   fieldValue:"rentFreePeriod",
          //  },



        ]
      },
      {
        step: 3,
        intialValue: {
          userId: { _id: "", name: "" },
          propertyTypeRequired: true,
          propertyType: "",
          brandNameRequired: false,
          brandName: "",
          location: "",
          locationRequired: true,
          carpetAreaMin: 0,
          carpetAreaMax: 0,
          carpetAreaRequired: true,
          otherInterestedLocation: [],
          otherInterestedLocationRequired: false,
          endUse: "",
          endUseRequired: true,
          possessionFrom: "",
          possessionFromRequired: true,
          googleLocationRequired: false,
          additionalDemandDetails: "",
          additionalDemandDetailsRequired: false,
          rentPsfMin: 0,
          rentPsfMax: 0,
          rentPsfRequired: false,
          microLocation: { name: "", _id: "" },
          microLocationRequired: true,
          transactionTypeRequired: true,
          transactionType: "",
          brokerageRequired: false,
          lockInPeriod: "",
          lockInPeriodRequired: false,
          propertyAge: "",
          propertyAgeRequired: false,
          floor: "",
          floorRequired: false,
          otherAmenities: [],
          otherAmenitiesRequired: false,
          rentFreePeriod: "",
          bikeParking: "",
          carParking: "",
          washRoom: "",
          fitOuts: "",
          rentFreePeriodRequired: false,
          bikeParkingRequired: false,
          carParkingRequired: false,
          washRoomRequired: false,
          fitOutsRequired: false,
          response: false,
          requiredDocuments: []
        },
        fieldArray: [
          {
            fieldShow: ["Retail", "Office Space"],
            label: "Required Documents",
            name: "requiredDocuments",
            placeholder: "Select",
            type: "multiSelect",
            inputType: "",
            option: requiredDocuments,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "documentsRequired",
            fieldValue: "requiredDocuments",
          },
        ]
      },

    ],

  },
  {
    key: "Flex Space",
    value: [
      {
        step: 1,
        intialValue: {
          userId: { _id: "", name: "" },
          propertyTypeRequired: true,
          propertyType: "",
          brandNameRequired: false,
          brandName: "",
          location: "",
          locationRequired: true,
          otherInterestedLocation: [],
          otherInterestedLocationRequired: false,
          // googleLocationRequired: false,
          // googleLocation:"",
          additionalDemandDetails: "",
          additionalDemandDetailsRequired: false,
          microLocation: { name: "", _id: "" },
          microLocationRequired: true,
          transactionTypeRequired: true,
          transactionType: "Lease",
          otherAmenities: [],
          otherAmenitiesRequired: true,
          bikeParking: "",
          carParking: "",
          bikeParkingRequired: false,
          carParkingRequired: false,
          response: false,
          pincode: "",
          pincodeRequired: false,
          possessionFrom: "",
          possessionFromRequired: true,
          dedicatedDesk:"",
          dedicatedDeskRequired:false,
          flexiDesk:'',
          flexiDeskRequired:false,
          meetingRoom:"",
          meetingRoomRequired:false,
          managedOffices:"",
          managedOfficesRequired:false,
          cabins:"",
          cabinsRequired: false,
        },
        fieldArray: [
          {
            fieldShow: ["Retail", "Office space", "Flex Space"],
            label: "Transaction Type ",
            name: "transactionType",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: transactionType,
            checkboxOnly: false,
            defaultChecked: true,
            requried: "transactionTypeRequired",
            fieldValue: "transactionType",
          },
          {
            fieldShow: ["Retail", "Office space", "Flex Space"],
            label: "Select POC",
            name: "userId",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "pocRequired",
            fieldValue: "userId",

          },
          {
            fieldShow: ["Retail", "Office space", "Flex Space"],
            label: "City *",
            name: "location",
            placeholder: "Select",
            type: "SearchSelect",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "locationRequired",
            fieldValue: "location",
          },
          {
            fieldShow: ["Retail", "Office space", "Flex Space"],
            label: "Location ",
            name: "microLocation",
            placeholder: "Search by location",
            type: "GoogleSearchLocation",
            isMulti: false,
            checkboxOnly: false,
            defaultChecked: true,
            requried: "microLocationRequired",
            fieldValue: "microLocation",
          },

          {
            fieldShow: ["Retail", "Office space", "Flex Space"],
            label: "Other locations",
            name: "otherInterestedLocation",
            placeholder: "Search by location",
            type: "GoogleSearchLocation",
            isMulti: true,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "otherInterestedLocationRequired",
            fieldValue: "otherInterestedLocation",
          },

          {
            fieldShow: ["Retail", "Office space", "Flex Space"],
            label: "Pin Code",
            name: "pincode",
            placeholder: "Enter Pin Code",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "pincodeRequired",
            fieldValue: "pincode",
            max: 6,
          },

           {
            fieldShow:["Retail","Office space", "Flex Space"],
            label:"Needed From ",
            name:"possessionFrom",
            placeholder:"Select",
            type:"select",
            inputType:"",
            option:coworkPossessionList,
            checkboxOnly:false,
            defaultChecked:true,
            requried:"possessionFromRequired",
            fieldValue:"possessionFrom",
           },
      
          {
            fieldShow: ["Retail", "Office space", "Flex Space"],
            label: "Other Requirements/Additional Information",
            name: "additionalDemandDetails",
            placeholder: "Enter additional information",
            type: "textArea",
            inputType: "text",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "additionalDemandDetailsRequired",
            fieldValue: "additionalDemandDetails",
          },
        ],
      },
      {
        step: 2,
        intialValue: {
          propertyAge: "",
          propertyAgeRequired: false,
          otherAmenities: [],
          otherAmenitiesRequired: true,
          bikeParking: "",
          carParking: "",
          bikeParkingRequired: false,
          carParkingRequired: false,
          response: false,
        },
        fieldArray: [
          {
            fieldShow: ["Retail", "Office Space", "Flex Space"],
            label: "Amenities required *",
            name: "otherAmenities",
            placeholder: "Select",
            type: "multiSelect",
            inputType: "",
            option: [],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "otherAmenitiesRequired",
            fieldValue: "otherAmenities",
          },
          {
            fieldShow: ["Retail", "Flex Space"],
            label: "Bike parking required",
            name: "bikeParking",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: ocStatusList,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "bikeParkingRequired",
            fieldValue: "bikeParking",
          },
          {
            fieldShow: ["Retail", "Flex Space"],
            label: "Car parking required",
            name: "carParking",
            placeholder: "Select",
            type: "select",
            inputType: "",
            option: ocStatusList,
            checkboxOnly: false,
            defaultChecked: false,
            requried: "carParkingRequired",
            fieldValue: "carParking",
          },
        ],
      },
      {
        step: 3,
        intialValue: {
          propertyTypeRequired: true,
          propertyType: "",
          brandNameRequired: false,
          brandName: "",
          location: "",
          locationRequired: true,
          budgetMin: 0,
          budgetMax: 0,
          budgetRequired: true,
          suppliedBy:"",
          suppliedByRequired: false,
          otherInterestedLocation: [],
          otherInterestedLocationRequired: false,
          googleLocationRequired: false,
          additionalDemandDetails: "",
          additionalDemandDetailsRequired: false,
          leasePeriodMin: 0,
          leasePeriodMax: 0,
          leasePeriodRequired: false,
          microLocation: { name: "", _id: "" },
          microLocationRequired: true,
          transactionTypeRequired: true,
          transactionType: "Lease",
          propertyAge: "",
          propertyAgeRequired: false,
          otherAmenities: [],
          otherAmenitiesRequired: false,
          bikeParking: "",
          carParking: "",
          bikeParkingRequired: false,
          carParkingRequired: false,
          response: false,
          pincode: "",
          pincodeRequired: false,
          dedicatedDesk:"",
          dedicatedDeskRequired:false,
          flexiDesk:'',
          flexiDeskRequired:false,
          meetingRoom:"",
          meetingRoomRequired:false,
          managedOffices:"",
          managedOfficesRequired:false,
          cabins:"",
          cabinsRequired: false,


          response: false,
        },
        fieldArray: [
          {
            fieldShow: ["Flex Space"],
            label: "Dedicated Desk",
            name: "dedicatedDesk",
            id: 'Dedicated Desk',
            placeholder: "Enter No.s of Seats",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "dedicatedDeskRequired",
            fieldValue: "dedicatedDesk",
          },

          {
            fieldShow: ["Flex Space"],
            label: "Budget/Per seat",
            name: "dedicatedDeskBudget",
            type: "rangeInput",
            rangefields: [
              {
                type: "input",
                inputType: "number",
                fieldValue: "dedicatedDeskMin",
                rangeType: 'Min',
                placeholder: "Min-Range",
                name: "dedicatedDeskMin",
                prefix: "₹",
                blongs:'Dedicated Desk'
              },
              {
                type: "input",
                inputType: "number",
                fieldValue: "dedicatedDeskMax",
                rangeType: 'Max',
                placeholder: "Max-Range",
                name: "dedicatedDeskMax",
                prefix: "₹",
                blongs:'Dedicated Desk'
              },
            ],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "dedicatedDeskBudgetRequired",
          },

          {
            fieldShow: ["Flex Space"],
            label: "Flexi Desk",
            name: "flexiDesk",
            id: 'Flexi Desk',
            placeholder: "Enter No.s of Seats",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "flexiDeskRequired",
            fieldValue: "flexiDesk",
          },

          {
            fieldShow: ["Flex Space"],
            label: "Budget/Per seat",
            name: "FlexiDeskBudget",
            type: "rangeInput",
            rangefields: [
              {
                type: "input",
                inputType: "number",
                fieldValue: "FlexiDeskMin",
                rangeType: 'Min',
                placeholder: "Min-Range",
                name: "FlexiDeskMin",
                prefix: "₹",
                blongs:'Flexi Desk'
              },
              {
                type: "input",
                inputType: "number",
                fieldValue: "FlexiDeskMax",
                rangeType: 'Max',
                placeholder: "Max-Range",
                name: "FlexiDeskMax",
                prefix: "₹",
                blongs:'Flexi Desk'
              },
            ],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "FlexiDeskBudgetRequired",
          },

          {
            fieldShow: ["Flex Space"],
            label: "Meeting Rooms",
            name: "meetingRoom",
            id: 'Meeting Room',
            placeholder: "Enter No.s of Seats",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "meetingRoomRequired",
            fieldValue: "meetingRoom",
          },

          {
            fieldShow: ["Flex Space"],
            label: "Budget/Per seat",
            name: "meetingRoomBudget",
            type: "rangeInput",
            rangefields: [
              {
                type: "input",
                inputType: "number",
                fieldValue: "meetingRoomMin",
                rangeType: 'Min',
                placeholder: "Min-Range",
                name: "meetingRoomMin",
                prefix: "₹",
                blongs:'Meeting Room'
              },
              {
                type: "input",
                inputType: "number",
                fieldValue: "meetingRoomMax",
                rangeType: 'Max',
                placeholder: "Max-Range",
                name: "meetingRoomMax",
                prefix: "₹",
                blongs:'Meeting Room'
              },
            ],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "meetingRoomBudgetRequired",
          },

          {
            fieldShow: ["Flex Space"],
            label: "Managed Offices",
            name: "managedOffices",
            id: 'Managed Office',
            placeholder: "Enter No.s of Seats",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "managedOfficesRequired",
            fieldValue: "managedOffices",
          },

          {
            fieldShow: ["Flex Space"],
            label: "Budget/Per seat",
            name: "managedOfficesBudget",
            type: "rangeInput",
            rangefields: [
              {
                type: "input",
                inputType: "number",
                fieldValue: "managedOfficesMin",
                placeholder: "Min-Range",
                rangeType: 'Min',
                name: "managedOfficesMin",
                prefix: "₹",
                blongs:'Managed Office'
              },
              {
                type: "input",
                inputType: "number",
                fieldValue: "managedOfficesMax",
                placeholder: "Max-Range",
                rangeType: 'Max',
                name: "managedOfficesMax",
                prefix: "₹",
                blongs:'Managed Office'
              },
            ],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "managedOfficesBudgetRequired",
          },

          {
            fieldShow: ["Flex Space"],
            label: "Cabins",
            name: "cabins",
            id: 'Cabins',
            placeholder: "Enter No.s of Seats",
            type: "input",
            inputType: "number",
            checkboxOnly: false,
            defaultChecked: false,
            requried: "cabinsRequired",
            fieldValue: "cabins",
          },

          {
            fieldShow: ["Flex Space"],
            label: "Budget/Per seat",
            name: "cabinsBudget",
            type: "rangeInput",
            rangefields: [
              {
                type: "input",
                inputType: "number",
                fieldValue: "cabinsMin",
                rangeType: 'Min',
                placeholder: "Min-Range",
                name: "cabinsMin",
                prefix: "₹",
                blongs: 'Cabins'
              },
              {
                type: "input",
                inputType: "number",
                fieldValue: "cabinsMax",
                placeholder: "Max-Range",
                rangeType: 'Max',
                name: "cabinsMax",
                prefix: "₹",
                blongs: 'Cabins'
              },
            ],
            checkboxOnly: false,
            defaultChecked: true,
            requried: "cabinsBudgetRequired",
          },
        ],
      },
    ],
  },
];

export const coworkInitialData = [
  {
    id: 'Dedicated Desk',
    dedicatedDesk: '',
    dedicatedDeskMax:'',
    dedicatedDeskMin: '',
    dedicatedDeskRequired:'',
  },
  {
    id: 'Flexi Desk',
    FlexiDeskMax:'',
    FlexiDeskMin:'',
    flexiDesk:'',
    flexiDeskRequired:'',
  },

  {
    id: 'Meeting Room',
    meetingRoom:'',
    meetingRoomMax:'',
    meetingRoomMin:'',
    meetingRoomRequired:'',
  },

  {
    id: 'Managed Office',
    managedOffices:'',
    managedOfficesMax:'',
    managedOfficesMin:'',
    managedOfficesRequired:'',
  },
  {
    id: 'Cabins',
    cabins:'',
    cabinsMax:'',
    cabinsMin:'',
    cabinsRequired:'',
  }
]





import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { getData } from "../../../../api";
import { PropertyCard } from "../../../../components/Cards/PropertyCard/propertyCard";
import MultiSelect from "../../../../components/select/dropDown";
import {
  getContextFilterData,
  performScrollEvent,
} from "../../../../utilities/pageScroll";
const CompletedProperty = (props) => {
  const navigate = useNavigate();
  const { pageScroll, setPageScroll } = useContext(AppContext);
  const scrollStoreArray = [...pageScroll];
  let contextFilterData = getContextFilterData(
    "myPageProperty",
    scrollStoreArray
  );
  let companyID = useSelector((state) => state.login.companyId);
  const fromPage = useSelector((state) => state.login.parentPage);
  const [apiCardDetail, setApiCardDetail] = useState([]);
  const cardDetail = [
    "propertyTypeId",
    "carpetArea",
    "endUse",
    "possession",
    "rentPsf",
    "tenure",
  ];
  const [cityFilterKeyValue, setCityFilterKeyValue] = useState(
    contextFilterData ?? { label: "City", value: "City" }
  );
  const [filterMessage, setFilterMessage] = useState("");

  const getPropertyData = async () => {
    setFilterMessage("");
    let url = `/property?pageNo=1&customerId=${props.id}&limit=0&isActive=false`;
    if (
      cityFilterKeyValue?.label !== "City" &&
      cityFilterKeyValue?.label !== ""
    ) {
      url += `&location=${cityFilterKeyValue?.label}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.status) {
        // apiDetail.push()
        setApiCardDetail(response.data.properties);
        if (
          cityFilterKeyValue?.label !== "City" &&
          cityFilterKeyValue?.label !== ""
        ) {
          if (response?.data?.properties?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      }
    });
  };

  const onchangeCityFilterKey = (val) => {
    setCityFilterKeyValue(val);
  };

  const onclickViewProperty = (a) => {
    props.setScrollEvent(a?._id);
    if (props?.fromTab === "agent") {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url =
        fromPage === "agent"
          ? "/agentReport/agentList/agentPage/propertyDetailView/"
          : "/customer/agentPage/propertyDetailView/";
      navigate(`${url}${a.productId?.display}`, {
        state: { values: a, page: "customer" },
      });
    } else {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url =
        fromPage === "seller"
          ? "/sellerReport/developerList/developerPage/propertyDetailView/"
          : "/customer/developerPage/propertyDetailView/";
      navigate(
        companyID === ""
          ? `${url}${a.productId?.display}`
          : `/company/developerCompanyCreation/developerPage/propertyDetailView/${a.productId?.display}`,
        { state: { values: a, page: "customer" } }
      );
    }
    localStorage.setItem("propTabKey", JSON.stringify("completedProperties"));
  };
  const btnList = [
    // {
    //   btnName: "Edit",
    //   onClick: (a) => onclickEditProperty(a),
    //   className: "btn-color-primary",
    // },
    {
      btnName: "View",
      onClick: (a) => onclickViewProperty(a),
      className: "btn-color-primary",
    },
  ];
  useEffect(() => {
    if (apiCardDetail?.length > 0 && props.tabKey === "completedProperties") {
      let updatedArray = performScrollEvent("myPageProperty", scrollStoreArray);
      setPageScroll(updatedArray);
    }
  }, [apiCardDetail]);

  useEffect(() => {
    getPropertyData();
  }, [cityFilterKeyValue]);
  return (
    <>
      <div className="filter-container">
        <MultiSelect
          width="25%"
          onChange={(val) => {
            onchangeCityFilterKey(val);
          }}
          isMulti={false}
          options={[{ label: "City", value: "City" }, ...props?.searchCityList]}
          value={cityFilterKeyValue}
        />
      </div>
      {apiCardDetail.length > 0 ? (
        <div>
          <PropertyCard
            access={props?.access}
            apiCardDetail={apiCardDetail}
            cardDetail={cardDetail}
            image={props?.image?.viewUrl ? props.image.viewUrl : props.image}
            // onclick={onclickViewProperty}
            button={btnList}
          />
        </div>
      ) : (
        <center>
          {filterMessage ? filterMessage : "No inactive properties yet"}
        </center>
      )}
    </>
  );
};
export default CompletedProperty;

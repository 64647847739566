import { useState, useEffect } from "react";
import "./index.scss";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Select from "../../components/Form/Select";
import moment from "moment";
import { monthYeaPie } from "./dropdownValue";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import Form from "react-bootstrap/Form";
import Button from "../../components/Form/Button";
import { getData, getExportData } from "../../api";
function PieCharts(props) {
  const [typeUser, setTypeUser] = useState("agent");
  const [timeSpentData, setTimeSpentData] = useState([]);
  const [hitData, setHitData] = useState([]);
  const [subscribedData, setSubscribedData] = useState([]);
  const [averageCount, setAverageCount] = useState({
    timeSpent: 0,
    hitCount: 0,
    subscribedData: "0",
    free: 0,
  });
  const [selectType, setSelectType] = useState("yearly");
  const [noData, setNoData] = useState(false);
  const [startYear, setStartYear] = useState(new Date());
  const [startMonth, setStartMonth] = useState(new Date());


  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  
  const timeSpentDataApi = async () => {
    let url ="/reports/spendTimeReport?period=yearly&type=filter";
    const newData = await getData(url, {});
    const { status, data } = newData;
    if (status) {
      setTimeSpentData(data);
    }
  };
  const subscriptionReport = async () => {
    let url = `/reports/revenueReport?period=${selectType}&type=filter&year=${moment(
      startYear
    ).format("YYYY")}`;
    if (selectType === "monthly") {
      url += `&month=${moment(startMonth).format("MM")}`;
    }
    // setSubscribedData([])
    const newData = await getData(url, {});
    const { status, data } = newData;
    if (status) {
      setSubscribedData(data);
      if (data?.length > 0) {
        let getTotal = data?.filter((elem) => elem?.name === "Total");
        if (getTotal?.length > 0) {
          if (getTotal[0]?.name === "Total" && getTotal[0]?.userCount === 0) {
            setNoData(true);
          } else {
            setNoData(false);
          }
        }
      }
    }
  };
  const hit = async () => {
    let url ="/reports/propertHitRatio?type=filter";
    const newData = await getData(url, {});
    const { status, data } = newData;
    if (status) {
      setHitData(data);
    }
  };

  useEffect(() => {
    if (props?.chartname === "timeSpent") {
      let totCount = timeSpentData?.filter((el) => el.name === "average");
      if (totCount?.length > 0) {
        setAverageCount({ ...averageCount, timeSpent: totCount[0].value });
      }
      // timeSpentDataApi();
    } else if (props?.chartname === "subscribed") {
      let totCount = subscribedData?.filter((el) => el.name === "Total");
      if (totCount?.length > 0) {
        setAverageCount({
          ...averageCount,
          subscribedData: `${
            totCount[0]?.userCount ? totCount[0]?.userCount : 0
          } / Revenue : ${totCount[0]?.revenue ? totCount[0]?.revenue : 0}`,
        });
      }
      // subscriptionReport();
    } else if (props?.chartname === "hitRatio") {
      // hit();
      let totCount = hitData?.filter((el) => el.name === "average");
      if (totCount?.length > 0) {
        setAverageCount({ ...averageCount, hitCount: totCount[0].value });
      }
    }
  }, [hitData, timeSpentData, subscribedData]);

  const handleDownloadExcel =  () => {
    let url;
    if (props?.chartname === "timeSpent") {
      url ="/reports/spendTimeReport?period=yearly&type=export";
    } else if (props?.chartname === "subscribed") {
      // url = `/reports/subscriptionReport?planType=${typeUser}&type=export`;
      url = `/reports/revenueReport?period=${selectType}&type=export&year=${moment(
        startYear
      ).format("YYYY")}`;
      if (selectType === "monthly") {
        url += `&month=${moment(startMonth).format("MM")}`;
      }
    } else if (props?.chartname === "hitRatio") {
      url ="/reports/propertHitRatio?type=export";
    }
    getExportData(url, {}, {})
    .then((blob) => {

     
      
      let urls = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = urls;
      a.download = `${props?.title}-${typeUser}.xlsx`;
      a.click();
    });
  };
  const handleChange = (type) => {
    setSelectType(type);
  };
  useEffect(() => {
    if (props?.chartname === "timeSpent") {
      timeSpentDataApi();
    } else if (props?.chartname === "subscribed") {
      subscriptionReport();
    } else if (props?.chartname === "hitRatio") {
      hit();
    }
  }, [typeUser, selectType, startYear, startMonth]);

  return (
    <div className="lineWrapper_pieChart">
      <div className=" timeSpentHead">
        <p className="activeUserColor">{props?.title}</p>
        <div className={props?.filter ? "" : "timeSpentActiveUserButton"}>
          <Button
            className={
              props?.filter
                ? "btn-color-primary marginTopPieButton"
                : "btn-color-primary marginTopPiechart"
            }
            onClick={() => handleDownloadExcel()}
            disabled={props.access.exportReports ? false : true}
          >
            Export CSV
          </Button>
        </div>
      </div>
      {props?.filter ? (
        <div className="activeUserButton pieChartCss">
          <Select
            size="w-30 marginWrapReport piechartSelect"
            name={"customer"}
            options={monthYeaPie}
            value={selectType}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
          &nbsp;
          {
            selectType === "yearly" ? (
              <DatePicker
                selected={startYear}
                onChange={(date) => setStartYear(date)}
                placeholderText="Select Year"
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={9}
              />
            ) : selectType === "monthly" ? (
              // <div className="hideHeaderDate pieChartMonthly">
              <DatePicker
                selected={startMonth}
                onChange={(date) => setStartMonth(date)}
                placeholderText="Select Month"
                dateFormat="MM"
                showMonthYearPicker
                dateFormatCalendar=""
              />
            ) : // </div>
            selectType === "custom" ? null : null // /> //   endVal={endDate} //   startVal={startDate} //   to={(date) => setEndDate(date)} //   from={(date) => setStartDate(date)} // <CustomDate
          }
        </div>
      ) : null}

      {selectType === "monthly" && (
        <div className="graphMonth">
          {moment(startMonth).format("MMMM, YYYY")}
        </div>
      )}
      <div className="piechartWrapContainer">
        {noData ? (
          <p className="noData">No data Found</p>
        ) : (
          <ResponsiveContainer>
            <PieChart width={400} height={400}>
              <Pie
                data={
                  props?.chartname === "timeSpent"
                    ? timeSpentData?.filter((el) => el.name !== "average")
                    : props?.chartname === "hitRatio"
                    ? hitData?.filter((el) => el.name !== "average")
                    : props?.chartname === "subscribed"
                    ? subscribedData?.filter((el) => el.name !== "Total")
                    : null
                }
                cx={200}
                cy={200}
                labelLine={props?.chartname === "subscribed" ? true : false}
                label={
                  props?.chartname === "subscribed"
                    ? ({
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        value,
                        index,
                      }) => {
                        const RADIAN = Math.PI / 180;
                        // eslint-disable-next-line
                        const radius =
                          25 + innerRadius + (outerRadius - innerRadius);
                        // eslint-disable-next-line
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        // eslint-disable-next-line
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          <text
                            x={x}
                            y={y}
                            fill="#8884d8"
                            // dy={-4}
                            textAnchor={x > cx ? "start" : "end"}
                            dominantBaseline="central"
                            fontSize={11}
                          >
                            {value}/(
                            {`Revenue: ${subscribedData[index].revenue}`})
                          </text>
                        );
                      }
                    : true
                }
                // label
                outerRadius={80}
                fill="#8884d8"
                dataKey={
                  props?.chartname === "subscribed" ? "userCount" : "value"
                }
              >
                {props?.chartname === "timeSpent"
                  ? timeSpentData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))
                  : props?.chartname === "hitRatio"
                  ? hitData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))
                  : subscribedData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
              </Pie>

              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        )}
        {/* <span>average</span> */}
        <div className="custom-counts">
          <span className="custom-boxColor"></span>
          <span className="cust-text">
            {props?.chartname === "subscribed" ? " Total Count" : " Average"} -
            {props?.chartname === "subscribed"
              ? averageCount?.subscribedData
              : props?.chartname === "timeSpent"
              ? averageCount?.timeSpent
              : averageCount?.hitCount}
          </span>
        </div>
      </div>
    </div>
  );
}

export default PieCharts;

import "./style.scss";

import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";


if (typeof window !== "undefined") {
  injectStyle();
}

export default function Toast(props) {
  return (
    
      <ToastContainer
        toastStyle={props.toast}
        progressClassName={props.progress}
        autoClose={2000}
      />
   
  );
}

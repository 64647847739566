import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { getData, postData } from "../../api";
import Button from "../../components/Form/Button";
import ClearInput from "../../components/Form/Input/clearInput";
import Loader from "../../components/Loader";

const CalendlyPopup = ({onClose}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [linkValue, setLinkValue] = useState("");
  const [clearInput, setClearInput] = useState(false);
  const [loading,setLoading] = useState()
  const getSwal = (icon,message) =>{
    Swal.fire({
      text: message,
      icon: icon,
      timer: 3000,
      buttons: false,
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }
  const getCalendlyLink = async() =>{
    setLoading(true)
    let url = "/user/getMeetLink"

    const apiData = await getData(url,{},{});
    const { status, data, message} = apiData
    if(status){
      let checkCalendlyStatus = data?.meet?.calendlyStatus;
      if(checkCalendlyStatus){
        let calendlyUrl = data?.meet?.calendly;
        setLoading(false)
        setLinkValue(calendlyUrl)
      }
      else{
        setLoading(false);
      }
    }
    else{
      setLoading(false)
      getSwal("error",message)
    }
  }
  const handleChange = (e) => {
    let value = e.target.value;

    setLinkValue(value);
  };
  const handleClearInput = () => {
    setLinkValue("");
  };
  useEffect(() => {
  getCalendlyLink()    
  },[])
  useEffect(() => {
    if (linkValue === "") {
      setClearInput(false);
    } else {
      setClearInput(true);
    }
  }, [linkValue]);

  const handleCalendlyLinkUpdate = async () => {
    setBtnLoading(true)
      let url = "/user/updateMeetLink"
      let payload = {
          calendly: linkValue,
      }
      const apiData = await postData(url,{},payload);
      const { status } = apiData;
      if(status){
        onClose()
        setBtnLoading(false)
      }
      else{
        setBtnLoading(false)
      }
  }
  return (
    <div>
        <p className="textheader f-14 fw-400">Add your calendly URL here so users can book appointments with you.</p>
        {loading ?  <Loader className={"fixed"} /> : 

          <Row>
          <Col md={9}>
            <ClearInput
              value={linkValue}
              close={clearInput}
              onChange={handleChange}
              onClose={handleClearInput}
              name={"calendly"}
            />
          </Col>
          <Col md={3}>
            <Button
              className={"btn-primary"}
              loading={btnLoading}
              children={"Submit"}
              type={"submit"}
              onClick={handleCalendlyLinkUpdate}
            />
          </Col>
        </Row>
        }
    
    </div>
  );
};
export default CalendlyPopup;

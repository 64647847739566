import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

// components
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import MultiSelect from "../../../components/select/dropDown";
import Search from "../../../components/Search/search";
import {
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  postData,
  switchToUsers,
} from "../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../assets/images/switchuser.png";
import { FaBell } from "react-icons/fa";
import { nameToLabel, truncateString } from "../../../utilities/commonFunction";
import Loader from "../../../components/Loader";
import SwitchAccountPopup from "../../../components/switchModal/SwitchCompanyBuyerPopup";

const BuyerDBTable = ({ filterData, trigger }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const [brandFilterData, setBrandFilterData] = useState("");
  const [interestFilterData, setInterestFilterData] = useState(
    filterData ? filterData : ""
  );
  const [search, setSearch] = useState("");

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);
  const filterOptions = [
    {
      name: "Properties Received",
      value: "response",
    },
    {
      name: "Relevant Properties",
      value: "relevent",
    },
  ];

  const renderRow = (value, isRead) => {
    if (value === null || value === undefined) {
      return (
        <div className="db-number-card-container">
          <div className="db-number-card">-</div>
        </div>
      );
    } else {
      return (
        <div className="db-number-card-container">
          <div className="db-number-card" title={String(value)}>
            {isRead ? "" : <div className="notification-count"></div>}
            {truncateString(String(value), 3)}
          </div>
        </div>
      );
    }
  };

  const handleAccountSwitch = async (id) => {
    setCompanyId(id);
    getAllSwitchUsers(id).then((res) => {
      if (res.status && res.statusCode === 200) {
        const companySpecificPOCs = res?.data?.companySpecificPOCs || [];
        // const brandPOCs = res?.data?.brandPOCs || [];
        // const regionPOCs = res?.data?.regionPOCs || [];
        const combinedArrays = [...companySpecificPOCs];
        if (combinedArrays.length === 1) {
          const user = combinedArrays[0];
          const data = {
            companyId: id,
            customerId: user._id,
            type: "user",
          };
          switchToUsers(data).then((res) => {
            let openUrl = process.env.REACT_APP_SWITCH_URL;
            if (res.status) {
              window.open(`${openUrl}/${res?.data?.token}`);
            }
          });
        } else if (combinedArrays.length < 1) {
          Swal.fire({
            // title: header,
            text: "No Poc Found",
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        } else {
          setSwitchModal(true);
          setPocs({
            companyPocs: companySpecificPOCs,
          });
        }
      }
    });
  };

  const columnsBrand = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "companyName",
      text: "Company Name",
    },
    {
      dataField: "demandCount",
      text: "Demands Posted",
      formatter: (cellContent, row, rowIndex) => {
        return renderRow(row?.demandCount, true);
      },
    },
    {
      dataField: "interestCount",
      text: "Properties Received",
      formatter: (cellContent, row, rowIndex) => {
        return renderRow(row?.interestCount, row?.interestisRead);
      },
    },
    {
      dataField: "releventCount",
      text: "Relevant Properties",
      formatter: (cellContent, row, rowIndex) => {
        return renderRow(row?.releventCount, row?.releventIsRead);
      },
    },
    {
      dataField: "notification",
      text: "Notification",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="db-number-card-container">
            <div className="db-number-card">
              {row?.notificationisRead ? (
                ""
              ) : (
                <div className="notification-count"></div>
              )}
              <FaBell />
            </div>
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="db-number-card-container">
            {getCurrentUser()?.role[0]?.name === "admin" ||
              getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
              <img
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleAccountSwitch(row?._id);
                }}
                alt="switch"
                src={switchuser}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }

    let url = `/dashboard/getCustomers?type=Brand&page=${offSet}&limit=${perPage}`;
    if (search !== "") {
      url += `&search=${search}`;
    }
    if (
      brandFilterData?.label &&
      brandFilterData?.value !== "Select" &&
      brandFilterData?.label !== ""
    ) {
      url += `&name=${brandFilterData?.value}`;
    }
    if (interestFilterData !== "Select" && interestFilterData !== "") {
      url += `&interest=${interestFilterData}`;
    }
    let cusomerData = await getData(url);
    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
      setTableData(data.brand);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  const handleBrandChange = (val) => {
    setBrandFilterData(val);
    setOffset(1);
  };
  const handleInterestChange = (e) => {
    setInterestFilterData(e.target.value);
    setOffset(1);
  };
  const tableProps = {
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };

  useEffect(() => {
    getTableData(true);
  }, [perPage, offSet, search, brandFilterData, interestFilterData]);

  useEffect(() => {
    getTableData(false);
  }, [trigger]);


  return (
    <>
      {loading && <Loader className={"fixed"} />}
      <div className="db-filter-container">
        <Row className=" filter-select">
          <>
            <Col md={5} sm={6} className="d-flex align-items-start pt-3">
              <Select
                placeholder={"Select..."}
                size={"w-100"}
                onChange={handleInterestChange}
                options={filterOptions}
                value={interestFilterData}
              />
            </Col>
          </>

          <>
            <Col md={5} sm={6} className="d-flex align-items-start ">
              <MultiSelect
                disabled={false}
                onChange={(val) => handleBrandChange(val)}
                isMulti={false}
                options={[
                  { label: "Select Brand Company", value: "Select" },
                  ...brandList,
                ]}
                value={brandFilterData}
                placeholder={"Select Brand Company"}
              />
            </Col>
          </>
        </Row>
        <Row className=" filter-search">
          <Col md={5} sm={6} className="d-flex align-items-start pt-3">
            <Search onChange={handleSearch} value={search} />
          </Col>
        </Row>
      </div>
      <MainTable {...tableProps} trigger={trigger} />
      <SwitchAccountPopup
        show={switchModal}
        onClose={() => setSwitchModal(false)}
        data={pocs}
        companyId={companyId}
      />
    </>
  );
};
export default BuyerDBTable;

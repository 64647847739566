import { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//icons
import { BsChatLeftDots } from "react-icons/bs";
//image
import Logo from "../../assets/images/logo.png";
//style
import { getCurrentUser, getKeyByValue } from "../../api";
import Calendly from "../../assets/images/calendly.svg";
import FormModal from "../../components/Modal";
import { socket } from "../../pages/chat/socket";
import CalendlyPopup from "./calendlyPopup";
import "./style.scss";
// import Notification from '../../components/Notification/index'

const Header = (props) => {
  const [count, setCount] = useState();
  const [connected, setConnected] = useState(false);
  const [roleName, setRoleName] = useState("");
  const userCred = getCurrentUser()?.role;
  const [calendly, setCalendly] = useState(false);

  const dispatch = useDispatch();

  // const [showNotification, setShowNotification] = useState(false);

  const navigate = useNavigate();
  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
  const setupSocketListeners = (userRoomIds) => {
    socket.emit("join", userRoomIds);
  };

  useEffect(() => {
   
    setCount(JSON.parse(localStorage.getItem("chat_count")));
    let roomIds = JSON.parse(localStorage.getItem("chat"));
    if (roomIds && roomIds.length) {
      setupSocketListeners(roomIds);
    }
  }, []);

  useEffect(() => {
    userCred?.map((item) => {
      setRoleName(item.name);
      return item;
    });
  }, []);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("keydemand_user"));

    const eventHandler = () => setConnected(true);
    const eventId = user._id;
    socket.on(eventId, eventHandler);
    socket.on(eventId, (newMessage) => {
      setCount(newMessage.chatCount);
    });
    // unsubscribe from event for preventing memory leaks
    return () => {
      socket.off(eventId, eventHandler);
    };
  }, []);
  const handleChat = () => {
    navigate("/chat");
  };
  const handleShift = () => {
    let route = getKeyByValue(menuId, loginPermission[0]);
   
    if ( ["admin","Relationship Manager"].includes(getCurrentUser()?.role?.[0]?.name)) {
      navigate("/dashboard");
    } else {
      
      if (route === "companyBuyer") {
        navigate("/company");
      } else if (
        ["customerSeller",
        "customerAgent",
         "customerBuyer",].includes(route)
      ) {
        navigate("/customer");
      }
      else{
        navigate(`/${route}`);
      }
    }
    
  };

  const handleCalendlyPopup = (status) => {
    setCalendly(status);
  };

  return (
    <>
      {calendly && (
        <FormModal
          show={calendly}
          onClose={() => handleCalendlyPopup(false)}
          heading={"Add Calendly URL"}
          customer={false}
          children={
            <CalendlyPopup onClose={() => handleCalendlyPopup(false)} />
          }
          size={"md"}
          top={"12px"}
          headerPadding={"0px"}
          bodyPadding={"0px"}
        ></FormModal>
      )}
      <div className="header bg-color-primary">
        <div className="header-left cursor">
          {props.handleShow ? (
            <GrClose
              size={30}
              className="menu-icon"
              onClick={() => props?.handleNav()}
            />
          ) : (
            <FiMenu
              size={30}
              className="menu-icon"
              onClick={() => props?.handleNav()}
            />
          )}
          <img src={Logo} className="logo" alt="logo" onClick={handleShift} />
        </div>
        <div className="header-right">
          {roleName && roleName === "Relationship Manager" ? (
            <>
              <div className="msg-icon">
                <BsChatLeftDots size={30} onClick={handleChat} />
                {count && count > 0 ? (
                  <div className="badge">{count}</div>
                ) : null}
              </div>
              <div className="msg-icon">
                <img
                  src={Calendly}
                  className="logo"
                  alt="logo"
                  onClick={() => handleCalendlyPopup(true)}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Header;

export const sellerUrls = {
  developerList: () => "/admin/developer?type=Developer",
  developerPocList: () => "/admin/developerPoc",
  cityPocList: () => "/admin/cityPoc",
  propertyAcceptedList: () => "/admin/propertyAccepted",
  propertyRejectedList: () => "/admin/propertyAccepted",
  needMoreInfoList: () => "/admin/propertyAccepted",
  propertyContactedList: () => "/admin/propertyContacted",
  propertyPostedList: () => "/admin/popertyPosted",
  propertyPostedAgainstList: () => "/admin/popertyPosted",
  propertyResponseList: () => "/admin/popertyPosted",
  propertyInActiveList: () => "/admin/popertyPosted",
  propertyActiveList: () => "/admin/popertyPosted",
};
export const sellerFilterUrls = {
  developerList: () => "/admin/brandFilter",
  developerPocList: () => "/admin/pocFilter",
  cityPocList: () => "/admin/pocFilter",
  propertyAcceptedList: () => "/admin/propertyReportFilter",
  propertyRejectedList: () => "/admin/propertyReportFilter",
  needMoreInfoList: () => "/admin/propertyReportFilter",
  propertyContactedList: () => "/admin/propertyReportFilter",
  propertyPostedList: () => "/admin/popertyPostedFilter",
  propertyPostedAgainstList: () => "/admin/popertyPostedFilter",
  propertyResponseList: () => "/admin/popertyPostedFilter",
  propertyInActiveList: () => "/admin/popertyPostedFilter",
  propertyActiveList: () => "/admin/popertyPostedFilter",
};

import { useState } from 'react'
import "./galleryBox.css"
import { IoIosArrowForward,IoIosArrowBack } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoChevronBackOutline } from "react-icons/io5";
import img1 from "../../assets/images/Livingroom1.png";
import img2 from "../../assets/images/Livingroom2.png";
import img3 from "../../assets/images/Livingroom3.png";
import img4 from "../../assets/images/Livingroom4.png";
import img5 from "../../assets/images/Livingroom5.png";
import img6 from "../../assets/images/Livingroom6.png";
import img7 from "../../assets/images/Livingroom7.png";
import img8 from "../../assets/images/Livingroom8.png";
import img9 from "../../assets/images/Livingroom9.png";
import img10 from "../../assets/images/Livingroom10.png";

const data = [img10, img2, img3, img4, img5, img6, img7, img8, img9, img1];

function GalleryBox(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const [totalSlide, setTotalSlide] = useState(props?.sampleImg.length);
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const PreviousBtn = (slide) => {
    
    setCurrentSlide(slide.currentSlide);
    // setTotalSlide(slide.slideCount - 1);
    const { className, onClick, } = slide;
    return (
      <div className={className} onClick={onClick}>
        <IoIosArrowBack style={{ color: "black" }} />
      </div>
    );
  };
  const NextBtn = (slide) => {
    setCurrentSlide(slide.currentSlide);
    // setTotalSlide(slide.slideCount - 1);
    const { className, onClick } = slide;
    return (
      <div className={className} onClick={onClick}>
        <IoIosArrowForward style={{ color: "black" }} />
      </div>
    );
  };
 
  return (
   
      <div className="galleryBox-container p-5">
        <div className="pb-2">
          <div className="d-flex align-items-center">
            <IoChevronBackOutline
              size={35}
              style={{ color: "white" }}
              onClick={props.onclose}
            />
            <div className="my-5 text-white">{props.name} </div>
          </div>

          <div>
            <div className="galleryBox-image-container">
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                dots={false}
                initialSlide={0}
                infinite
                slidesToShow={1}
                prevArrow={<PreviousBtn />}
                nextArrow={<NextBtn />}
              >
                {props?.sampleImg.map((item,i) => (
                  <div className="mainImage" key={i}>
                    <img
                      src={item}
                      alt=""
                      style={{ width: "100%", height: "500px" }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            
            <div className="galleryBox-subimage-container mt-4">
              <Slider
                asNavFor={nav1}
                prevArrow={<PreviousBtn />}
                nextArrow={<NextBtn />}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={props?.sampleImg.length}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                {props?.sampleImg.map((item) => (
                  <>
                    {" "}
                    <div className="gallery-lists ">
                      <img src={item} alt="images"/>
                    </div>
                  </>
                ))}
              </Slider>
            </div>
          </div>
          <h4 className="text-center">
              ({currentSlide+1}/{totalSlide})
            </h4>
        </div>
      </div>
  );
}

export default GalleryBox;

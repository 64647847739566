import moment from "moment";
import { Form } from "react-bootstrap";
import Rectangle from "../../../assets/images/Livingroom9.png";
import Button from "../../Form/Button";
import "./style.scss";

export const DemandCard = ({
  demandDetail,
  relevant,
  response,
  button,
  onClickPostedBy,
  onSelectDemands,
  showCheckBox,
  access
}) => {
  const cardDetail = [
    "propertyType",
    "carpetAreaMin",
    "endUse",
    "possessionFrom",
    "createdAt",
  ];

  const cardDataList = [
    { name: "Formats", id: "format", typeFor: ["Flex Space"] },
    { name: "Needed From", id: "possessionFrom", typeFor: ["Flex Space"] },
    { name: "Posted on", id: "createdAt", typeFor: ["Flex Space"] }
  ]

  const possessionList = [
    {
      _id: 1,
      name: "Immediate",
    },
    {
      _id: 3,
      name: "Within 3 months",
    },
    {
      _id: 6,
      name: "Within 6 months",
    },
    {
      _id: 9,
      name: "Within 9 months",
    },
    {
      _id: 12,
      name: "After 9 months",
    },
  ];
  const camelCaseConversion = (value) => {
    return value.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };
  return (
    <>
      {demandDetail?.map((a, index) => {
        let demandTitle = `Demand for ${a?.propertyType?.name ? a?.propertyType?.name == "Flex Space" ? a?.propertyType?.name : a?.propertyType?.name?.toLowerCase() : ""
          } ${a?.propertyType?.name == "Flex Space" ? "" : " store"} in ${a?.microLocation?.description}`;
        let postedBy = `A leading ${a?.endUse?.name?.toLowerCase()} ${a?.propertyType?.name === "Retail"
          ? "brand"
          : `company has a requirement in ${a?.microLocation?.description}`
          }`;

        return (
          <div key={index} className="demand-card p-3 d-flex" id={a?._id}>
            <div className="d-flex align-items-center">
              {showCheckBox ? (
                <Form.Check
                  type="checkbox"
                  id={"check-api-checkbox-input"}
                  className="mt-0"
                  style={{ marginRight: "10px" }}
                >
                  <Form.Check.Input
                    type={"checkbox"}
                    style={{ cursor: "pointer" }}
                    id={`check ${a?._id}`}
                    //   checked={property?.isChecked ? true : false}
                    onChange={(e) => onSelectDemands(a._id)}
                  />
                </Form.Check>
              ) : (
                ""
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              {a?.featuredDemand ? (
                <span className="ribbon-wrapper">
                  <div class="glow">&nbsp;</div>
                </span>
              ) : (
                ""
              )}

              <div className="d-flex justify-content-between">
                <div className="d-flex demand-title">
                  <img
                    className="demand-profile"
                    src={
                      a.brandNameRequired && a.brandId?.logo?.viewUrl
                        ? a.brandId?.logo?.viewUrl
                        : a?.endUse?.image
                          ? a?.endUse?.image.viewUrl
                          : Rectangle
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; 
                      currentTarget.src = Rectangle;
                    }}
                    alt="demandProfile"
                  />
                  <div className="title-text">
                    <p
                      className="card-sub-text trancateString fw-700 f-20 mb-0"
                      title={demandTitle}
                    >
                      {demandTitle}
                    </p>
                    {a?.transactionType && <p
                      className="card-sub-text trancateString fw-700 f-17 mb-0"
                      title={a?.transactionType || '-'}
                    >
                      {`For ${a?.transactionType}`}
                    </p>}
                  </div>
                </div>
                {relevant ? (
                  <div className="responses">
                    <div className="responses-count">
                      {a.matchPercentage ? a.matchPercentage + "%" : 0}
                    </div>
                    <label className="response-text">Relevent</label>
                  </div>
                ) : response ? (
                  <div className="responses">
                    <div className="responses-count">
                      {a.responseCount ? a.responseCount : 0}
                    </div>
                    <label className="response-text">Responses</label>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-3">
                <p className="f-16 fw-400">
                  <b className={"f-16 fw-700"}>Demand ID: </b>
                  {a?.productId?.display}
                </p>

                {a?.propertyType?.name !== "Flex Space" &&
                  <>
                    {cardDetail.map((c, i) => {
                      let value;
                      if (c in a) {
                        if (a[c] !== "") {
                          if (c === "carpetAreaMin") {
                            value =
                              a["carpetAreaMin"] +
                              " - " +
                              a["carpetAreaMax"] +
                              " ft";
                          } else if (c === "endUse") {
                            value = a["endUse"]?.name;
                          } else if (c === "propertyType") {
                            value = a["propertyType"]?.name;
                          } else if (c === "possessionFrom") {
                            possessionList.filter((el) =>
                              a["possessionFrom"] === el._id
                                ? (value = el.name)
                                : "-"
                            );
                          } else if (c === "createdAt") {
                            value = moment(a["createdAt"]).format("DD-MM-YYYY");
                            // possessionList.filter(el => a["possessionFrom"] === el._id ? (value = el.name) : "-")
                          }
                        } else if (a[c] === null) {
                          value = " -";
                        } else {
                          value = " -";
                        }
                      } else {
                        value = " -";
                      }

                    // if (c === "endUse" && a.transactionType == "Buy") return

                    return (
                      <p title={value} key={i} className="f-16 fw-400">
                        <b className={"f-16 fw-700"} key={i}>
                          {c === "carpetAreaMin"
                            ? "Carpet Area"
                            : c === "createdAt"
                              ? "Posted on"
                              : camelCaseConversion(c)}
                          :{" "}
                        </b>
                        {/* {c === "carpetAreaMin" ?
                                                a["carpetAreaMin"] + "-" + a["carpetAreaMax"] : c === "endUse" ? a["endUse"]?.name: c === "propertyType" ? "Retail" : !(c in a) || a[c] === "" ? "-" : a[c]} */}
                          {value}
                        </p>
                      );
                    })}
                  </>
                }

                {a?.propertyType?.name == "Flex Space" &&
                  <>
                    {cardDataList?.length > 0 && cardDataList?.map((data, index) => {
                      let value;
                      if (data?.id in a) {
                        if (a[data?.id] !== "" || a[data?.id] !== null) {

                          if (data?.id === "createdAt") {
                            value = moment(a[data?.id]).format("DD-MM-YYYY");
                          }
                          if (data?.id == "format") {
                            value = a[data?.id]
                          }
                          if (data?.id == "possessionFrom") {
                            possessionList.filter((el) =>
                              a["possessionFrom"] === el._id
                                ? (value = el.name)
                                : "-"
                            );
                          }

                        } else {
                          value = "-"
                        }
                      } else {
                        value = "-"
                      }
                      return (
                        <p
                          title={"value"}
                          style={{ width: "100%", display: "flex" }}
                          key={index}
                          className="propertyValues f-16 fw-400"
                        >
                          <div style={{ display: "flex" }}>
                            <div className={"f-16 fw-700"} key={index} style={{ marginRight: "3px" }}>{`${data?.name}: `}</div>
                            <div>
                              {
                                (data?.id == "format") ?
                                  // value?.map((val, ind) => {
                                  //   return (
                                  //     <div style={{ margin: "0px 4px", flexWrap: "wrap" }}>{`${val?._id?.name || "Format"} - ${val?.noOfSeat > 0 ? `${val?.noOfSeat} ${val?.noOfSeat > 1 ? "seats" : "seat"}` : "0 seats"}`}</div>
                                  //   )
                                  // })
                                  <div>
                                    <table style={{ width: "100%", padding: 0 }}>
                                      {/* <tr style={{ color: "#3E495" }}>
                                      <th>Formats</th>
                                      <th>{"Rate per Seat"}</th>
                                    </tr> */}
                                      {
                                        value?.filter((val) => { return val?.noOfSeat })?.map((datas) => {
                                          return (
                                            <tr>
                                              <td style={{ paddingRight: "10px" }}>{datas?._id?.name}</td>
                                              <td style={{ paddingRight: "10px" }}>-</td>
                                              <td>{datas?.noOfSeat > 0 ? `${datas?.noOfSeat} ${datas?.noOfSeat > 1 ? "seats" : "seat"}` : "-"}</td>
                                            </tr>
                                          )
                                        })
                                      }
                                    </table>
                                  </div>
                                  :
                                  ` ${value}`
                              }
                            </div>

                          </div>
                        </p>
                      )
                    })
                    }
                  </>
                }

                {relevant || response ? (
                  ""
                ) : (
                  <p
                    className="f-16 fw-800 card-highlight-text-color"
                    title={`${a?.brandNameRequired ? a.brandId?.name : postedBy
                      }`}
                  >
                    <b className="f-16 fw-700 secondary-color">Posted By: </b>
                    <span
                      className="cursor-pointer"
                      onClick={() => onClickPostedBy(a?.userId?._id)}
                    >
                      {a.brandId?.name
                        ? a.brandId?.name
                        : `${postedBy.length > 20
                          ? `${postedBy?.slice(0, 20)}...`
                          : postedBy
                        }`}
                    </span>
                  </p>
                )}
              </div>
              <div className="btn-container justify-content-center">
                {button &&
                  button.map((item, btnIndex) => {
                    let edit = item?.btnName === "Edit" && access?.edit
                    let view = item?.btnName === "View" && access?.view
                    return (
                      <Button
                        key={btnIndex}
                        className={item.className}
                        onClick={() =>
                          item.onClick(a)
                        }
                        disabled={edit || view ? false : true}

                      // a._id,
                      // a?.userId?._id,
                      // a?.productId?.display,
                      >
                        {item.btnName}
                      </Button>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export const setScrollBackId = (page, id, filter, search, storeArray) => {
  const index = storeArray?.findIndex((x) => x.page === page);
  if (index === undefined || index === null) {
    return [];
  } else if (index === -1) {


    return [
      ...storeArray,
      { page: page, id: id, filter: filter, search: search },
    ];
  } else {
    const newArray = [...storeArray];
    newArray[index] = { page: page, id: id, filter: filter, search: search };
    return newArray || [];
  }
};
export const updateScrollBackIdForTable = (page, id, storeArray) => {
  const index = storeArray?.findIndex((x) => x.page === page);
  if (index === undefined || index === null) {
    return [];
  } else if (index === -1) {
    return [
      ...storeArray,
    ];
  } else {
    const newArray = [...storeArray];
    newArray[index] = {...newArray[index],page: page, id: id,};
    return newArray || [];
  }
};

export const getScrollBackId = (page, storeArray) => {
  let filterCurrentArray = storeArray?.find((x) => x.page === page)?.id || null;
  return filterCurrentArray || null;
};
export const getContextFilterData = (page, storeArray) => {
  let filterData = storeArray?.find((x) => x.page === page)?.filter || null;
  return filterData || null;
};
export const getContextSearchData = (page, storeArray) => {
  let searchData = storeArray?.find((x) => x.page === page)?.search || null;
  return searchData || null;
};
export const removeScrollBackId = (page, storeArray) => {
  const index = storeArray?.findIndex((x) => x.page === page);
  if (index === undefined || index === null) {
    return [];
  } else if (index === -1) {
    return storeArray || [];
  }
  const newArray = [
    ...storeArray?.slice(0, index),
    ...storeArray.slice(index + 1),
  ];
  //
  return newArray || [];
};
export const removeTableFilterData = (tableName, storeArray) => {
  const newArray = storeArray?.filter((x) => x.tableName !== tableName);
  return newArray || [];
};

export const performScrollEvent = (page, storeArray) => {
  let idToScroll = getScrollBackId(page, storeArray);

  if (idToScroll) {
    const childElement = document.getElementById(idToScroll);
    if (childElement) {
      childElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
  let updatedArray = removeScrollBackId(page, storeArray);
  return updatedArray || [];
};

export const setTableFilterData = (tableName = "", filterData, storeArray) => {
  const index = storeArray?.findIndex((x) => x?.tableName === tableName);
  if (index === undefined || index === null) {
    return [];
  } else if (index === -1) {
    return [...storeArray, { tableName: tableName, ...filterData }];
  } else {
    const newArray = [...storeArray];
    newArray[index] = {
      ...newArray[index],
      tableName: tableName,
      ...filterData,
    };
 
    return newArray || [];
  }
};
export const getTableStoreValue = (tableName, storeArray) => {
  let filterCurrentArray =
    storeArray?.find((x) => x.tableName === tableName) || null;
  return filterCurrentArray || null;
};



import FormModal from "./index";
import "./style.scss"


export const ListPopup = ({onClose, show,title,lists}) =>{
    return(
        <FormModal
            onClose={onClose}
            show={show}
            heading={title?title :""}
            size={"md"}
            loading={false}
            loader="fixed"
            headerClassName={"warning-header-class"}
            className={"pt-0"}
        >
            {lists.length > 0 ? <><div className="customer__change">
                    {lists.map((item,index) => {
                        return(<div className="d-flex justify-content-between p-2 py-3 customer__change_rm" key={index}>
                                <div className="change_rm__name">{`${item} `} </div>
                            </div>
                        )
                    })}
                </div></> :<div className="text-center">No Data Found</div>}
        </FormModal>
    )
}

import React, { useState, useEffect } from "react";
import { getData, postGetData } from "../../../api";
import { Row, Col, Form } from "react-bootstrap";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select";
import { getInputData } from "../options";
import Button from "../../../components/Form/Button";
import MultiSelect from "../../../components/select/dropDown";
import { fitouts } from "../../../components/DropdownOptions";
const AdditionalAndTransaction = ({
  gotoStep,
  isExitData,
  propertyDetail,
  step,
  initData,
  propertyDetailsInputValues,
  properytyObject,
  setProperyObject,
  retailAdditionalStepButtonRef,
  retailAdditionalStepFormRef,
  setChanged,
  retailAdditionalDetailDivRef
}) => {
  const [properytyInput, setPropertyInput] = useState([]);
  const [validated, setValidated] = useState(false);
  // const [properytyObject, setProperyObject] = useState({});
  const [idealData, setIdealData] = useState([]);
  const [datasAmenitey, setDatasAmenitey] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [idealLoading, setIdealLoading] = useState(true);
  const [amenitiesLoading, setAmenitiesLoading] = useState(true);
  const [tenureError, setTenureError] = useState(false)

  // const handleDatas = async () => {
  //   setLoading(true);
  //   const objData = { ...properytyObject };
  //   const finalData = {};
  //   finalData["additionalDetails"] = {
  //     floor: objData.floor,
  //     frontAge: objData.frontAge ? Number(objData.frontAge) : "",
  //     accessRoadWidth: objData.accessRoadWidth
  //       ? Number(objData.accessRoadWidth)
  //       : "",
  //     buildingStructure: objData.buildingStructure,
  //     propertyAge: objData.propertyAge ? Number(objData.propertyAge) : "",
  //     previousOccupancy: objData.previousOccupancy,
  //     idealFor: objData.idealFor,
  //   };
  //   finalData["lockInPeriod"] = objData.lockInPeriod
  //     ? Number(objData.lockInPeriod)
  //     : "";
  //   finalData["rentFreePeriod"] = objData.rentFreePeriod
  //     ? Number(objData.rentFreePeriod)
  //     : "";
  //   finalData["otherAmenities"] = objData.otherAmenities;
  //   for (let propName in finalData) {
  //     for (let innerobj in finalData?.additionalDetails) {
  //       if (
  //         !finalData?.additionalDetails[innerobj] ||
  //         finalData?.additionalDetails[innerobj] === "" ||
  //         finalData.additionalDetails[innerobj]?.length === 0
  //       ) {
  //         delete finalData.additionalDetails[innerobj];
  //       }
  //     }
  //     if (
  //       !finalData[propName] ||
  //       finalData[propName] === "" ||
  //       finalData[propName].length === 0
  //     ) {
  //       delete finalData[propName];
  //     }
  //   }
  //   finalData["step"] = 3;
  //   finalData["propertyId"] = initData.propertyId;
  //   const res = await postGetData("/property", {}, finalData);

  //   const { statusCode } = res;
  //   if (statusCode === 200) {
  //     setLoading(false);
  //     gotoStep(4, true);
  //   }
  //   setLoading(false);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (Number(properytyObject.lockInPeriod) > Number(properytyObject.tenure)) {
      setTenureError(true)
    } else {
      setTenureError(false)
    }
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
       setValidated(true);
    }
     //setValidated(true);
     // handleDatas();
  };

  const handleChange = (e, item) => {
    const { name } = item;
    const value = e.target?.value;
    setProperyObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleMultiChange = (selectedData, selectedname) => {
    const optionData = { ...properytyObject };
    optionData[selectedname] = selectedData;
    setProperyObject(optionData);
  };
  const handleAmenitey = async () => {
    setAmenitiesLoading(true);
    let url = "/amenity?type=Retail";
    const apiDatas = await getData(url, {}, {});
    const { statusCode, data } = apiDatas;
    if (statusCode === 200) {
      data?.forEach((val) => {
        val.label = val.name;
        val.value = val._id;
      });
      setDatasAmenitey(data);
      setAmenitiesLoading(false);
      setInitLoading(false);
      //setMultiSelectOptions('otherAmenities', data)
    } else {
      setAmenitiesLoading(false);
    }
  };
  const getIdealForApiData = async () => {
    setIdealLoading(true);
    let url = "/endUse/getEndUses?type=Retail";

    const newData = await getData(url, {}, {});
    const { statusCode, data, status } = newData;
    if (status && statusCode === 200) {
      data.forEach((val) => {
        val.label = val.name;
        val.value = val._id;
      });
      setIdealData(data);
      setIdealLoading(false);
      handleAmenitey();
      //setMultiSelectOptions('idealFor', data)
    } else {
      setIdealLoading(false);
    }
  };

  const handleSelectdata = (e) => {
    setChanged(true)
    let { name, value } = e.target;
    const optionData = { ...properytyObject };
    optionData[name] = Number(value);
    setProperyObject(optionData);
  };
  const handleChanges = (e) => {
    setChanged(true)
    let { name, value } = e.target;
    const optionData = { ...properytyObject };
    optionData[name] = Number(value);
    setProperyObject(optionData);
  };
  const gotoBack = () => {
    gotoStep(step - 1);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA') {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  ;
  useEffect(() => {
    setPropertyInput(getInputData);
    getIdealForApiData();
    getInputData.forEach((item) => {
      setProperyObject((prev) => ({
        ...prev,
        [item.name]: item.type === "multiselect" ? [] : "",
      }));
    });
    // if (propertyDetail?.additionalDetails) {
    let exitingObj = {
      floor: propertyDetail?.additionalDetails?.floor,
      frontAge: propertyDetail?.additionalDetails?.frontAge,
      accessRoadWidth: propertyDetail?.additionalDetails?.accessRoadWidth,
      buildingStructure: propertyDetail?.additionalDetails?.buildingStructure,
      propertyAge: propertyDetail?.additionalDetails?.propertyAge,
      previousOccupancy: propertyDetail?.additionalDetails?.previousOccupancy,
      idealFor: propertyDetail?.additionalDetails?.idealFor,
      lockInPeriod: propertyDetail?.lockInPeriod,
      otherAmenities: propertyDetail?.otherAmenities,
      rentFreePeriod: propertyDetail?.rentFreePeriod,
      lockInPeriod: +propertyDetail.lockInPeriod,
      tenure: +propertyDetail.tenure,
    };
    setProperyObject(exitingObj);
    // }
  }, []);
  return (
    <>
      <div className="property-details-addtionals" ref={retailAdditionalDetailDivRef}>
        <Row>
          <Col md="1" />
          <Col md="10">
            {!initLoading && (
              <Form noValidate validated={validated} onSubmit={handleSubmit} ref={retailAdditionalStepFormRef}>
                <h4>Additional Details</h4>
                <Row>
                  {properytyInput &&
                    properytyInput.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.type === "text" || item.type === "number" ? (
                          <Col md="6">
                            <Input
                              {...item}
                              value={properytyObject[item.name]}
                              onChange={(e) => handleChange(e, item)}
                            />
                          </Col>
                        ) : null}
                        {item.type === "select" ? (
                          <Col md="6">
                            <Select
                              {...item}
                              value={properytyObject[item.name]}
                              onChange={(e) => handleChange(e, item)}
                            />
                          </Col>
                        ) : null}
                      </React.Fragment>
                    ))}
                  {/* {!idealLoading ? (
                    <Col md="6" hidden={initData.developerType !== "Developer"}>
                      <MultiSelect
                        onChange={(e) => handleMultiChange(e, "idealFor")}
                        isMulti={true}
                        options={idealData}
                        value={properytyObject["idealFor"]}
                        lable="Ideal For"
                        placeholder="Select Ideal For"
                      />
                    </Col>
                  ) : null} */}
                  {!amenitiesLoading ? (
                    <Col md="6">
                      <MultiSelect
                        onChange={(e) => handleMultiChange(e, "otherAmenities")}
                        isMulti={true}
                        options={datasAmenitey}
                        value={properytyObject["otherAmenities"]}
                        lable="Amenities available"
                        placeholder="Select Amenities"
                      />
                    </Col>
                  ) : null}
                </Row>
                {propertyDetailsInputValues?.transactionType !== "Sales" ?
                  <h4 className="mt-4">Transaction</h4>
                  : null
                }
                <Row>
                  {propertyDetailsInputValues?.transactionType !== "Sales" ?
                    <>
                      <Col md="6">
                        <Input
                          disabled={false}
                          label="Lease tenure In years"
                          type="number"
                          placeholder="Enter number"
                          name={"tenure"}
                          value={properytyObject["tenure"]}
                          onChange={handleChanges}
                          sufix="years"

                        />
                      </Col>
                      <Col md="6">
                        <Input
                          disabled={false}
                          label="Lock in period"
                          type="number"
                          placeholder="Enter number"
                          name={"lockInPeriod"}
                          value={properytyObject["lockInPeriod"]}
                          onChange={handleChanges}
                          sufix="years"
                          errorMessage={tenureError ? "Shouldn`t be more that lease tenure" : ""}
                          isInvalid={tenureError ? true : false}
                        />
                      </Col>
                    </>
                    : null
                  }

                  {/* <Col md="6">
                    <Select
                      disabled={false}
                      label="Fit-out/Rent free period "
                      options={fitouts}
                      placeholder="Select fitOuts/Rent free period "
                      value={properytyObject["rentFreePeriod"]}
                      name={"rentFreePeriod"}
                      onChange={(e) => handleSelectdata(e)}
                    />
                  </Col> */}

                  <Col md="12" hidden={true}>
                    <div className="text-end">

                      <Button
                        type="submit"
                        className="next-btn p-0"
                        loading={loading}
                        _ref={retailAdditionalStepButtonRef}
                      >
                        Next
                      </Button>
                    </div>
                  </Col>

                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AdditionalAndTransaction;

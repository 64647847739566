export const citList = [
  {
    _id: 1,
    name: "Mumbai",
  },
  {
    _id: 2,
    name: "Pune",
  },
  {
    _id: 3,
    name: "Nashik",
  },
];

export const coworkPossessionList = [
  {
    _id: 1,
    name: "Immediate",
  },
  {
    _id: 3,
    name: "Within 3 months",
  },
];
export const possessionList = [
  {
    _id: 1,
    name: "Immediate",
  },
  {
    _id: 3,
    name: "Within 3 months",
  },
  {
    _id: 6,
    name: "Within 6 months",
  },
  {
    _id: 9,
    name: "Within 9 months",
  },
  {
    _id: 12,
    name: "After 9 months",
  },
];
export const closeTimeList = [
  {
    _id: "0",
    name: "Immediate",
  },
  {
    _id: "3",
    name: "Within 3 months",
  },
  {
    _id: "6",
    name: "Within 6 months",
  },
  {
    _id: "9",
    name: "Within 9 months",
  },
  {
    _id: "12",
    name: "After 9 months",
  },
];
export const enduseList = [
  {
    _id: 1,
    name: "Retail",
  },
  {
    _id: 2,
    name: "Cafe",
  },
  {
    _id: 3,
    name: "Restaurant",
  },
  {
    _id: 4,
    name: "Cloud kitchen",
  },
  {
    _id: 4,
    name: "Clinic/Diagnostic center",
  },
  {
    _id: 5,
    name: "Electronic store",
  },
  {
    _id: 6,
    name: "Gym",
  },
  {
    _id: 7,
    name: "Jewellery store",
  },
  {
    _id: 8,
    name: "Clothing store",
  },
  {
    _id: 9,
    name: "Footwear store",
  },
  {
    _id: 10,
    name: "Automative showroom",
  },
  {
    _id: 11,
    name: "Cosmetics store",
  },
  {
    _id: 12,
    name: "Others",
  },
];

export const rentFree = [
  {
    _id: 2,
    name: "1-2 months",
  },
  {
    _id: 4,
    name: "2-4 months",
  },
  {
    _id: 6,
    name: "4-6 months",
  },
  {
    _id: 12,
    name: "Over 6 months",
  },
];
export const buildingStructure = [
  {
    label: "Independent building",
    value: "Independent building",
  },
  {
    label: "Mall",
    value: "Mall",
  },
  {
    label: "Mixed use",
    value: "Mixed use",
  },
  {
    label: "Highstreet",
    value: "Highstreet",
  },
  {
    label: "Kiosks",
    value: "Kiosks",
  },
  {
    label: "Store-in-store",
    value: "Store-in-store",
  },
  //    ,
  //     {
  //     _id:7,
  //     name:"Any"
  // },
];
export const ownerShip = [
  {
    _id: "Individual or Investor",
    name: "Individual or Investor",
  },
  {
    _id: "Developer",
    name: "Developer",
  },
  // {
  //   _id: "Any",
  //   name: "Any",
  // },
];
export const ageProperty = [
  {
    _id: "5",
    name: "Less than 5 years",
  },
  {
    _id: "10",
    name: "5-10 years",
  },
  {
    _id: "20",
    name: "10-20 years",
  },
  {
    _id: "30",
    name: "Above 20 years",
  },
  {
    _id: "0",
    name: "Any",
  },
];
export const floorOption = [
  {
    _id: "0",
    name: "Basement",
  },
  {
    _id: "1",
    name: "Lower Ground",
  },
  {
    _id: "2",
    name: "Upper Ground",
  },
  {
    _id: "3",
    name: "Ground",
  },
  {
    _id: "4",
    name: "1st Floor",
  },
  // {
  //   _id: "4",
  //   name: "2nd Floor",
  // },
  // {
  //   _id: "5",
  //   name: "3rd Floor",
  // },
  // {
  //   _id: "6",
  //   name: "4th Floor",
  // },
  // {
  //   _id: "7",
  //   name: "5th Floor",
  // },
  // {
  //   _id: "8",
  //   name: "Above 5",
  // },
  {
    _id: "5",
    name: "Any",
  },
];
export const transactionType = [
  {
    _id: "Lease",
    name: "Lease",
  },
  {
    _id: "Revenue Share",
    name: "Revenue Share",
  },
  {
    _id: "MG Revenue Share",
    name: "MG Revenue Share",
  },
  {
    _id: "Buy",
    name: "Buy",
  },
];
export const transactionTypeOffice = [
  {
    _id: "Lease",
    name: "Lease",
  },
  {
    _id: "Buy",
    name: "Buy",
  }
];
export const WashroomsList = [
  { _id: "1", name: "1" },
  { _id: "2", name: "2" },
  { _id: "3", name: "3" },
  { _id: "4", name: "4" },
  { _id: "5", name: "5" },
  { _id: "6", name: "6" },
  { _id: "7", name: "7" },
  { _id: "8", name: "8" },
  { _id: "9", name: "9" },
  { _id: "10", name: "10" },
  { _id: "11", name: "11" },
  { _id: "12", name: "12" },
  { _id: "13", name: "13" },
  { _id: "14", name: "14" },
  { _id: "15", name: "15" },
];
export const parkingList = [
  { _id: "0", name: "0" },
  { _id: "1", name: "1" },
  { _id: "2", name: "2" },
  { _id: "3", name: "3" },
  { _id: "4", name: "4" },
  { _id: "5", name: "5" },
  { _id: "6", name: "6" },
  { _id: "7", name: "7" },
  { _id: "8", name: "8" },
  { _id: "9", name: "9" },
  { _id: "10", name: "10" },
  { _id: "11", name: "11" },
  { _id: "12", name: "12" },
  { _id: "13", name: "13" },
  { _id: "14", name: "14" },
  { _id: "15", name: "15" },
  { _id: "16", name: "16" },
  { _id: "17", name: "17" },
  { _id: "18", name: "18" },
  { _id: "19", name: "19" },
  { _id: "20", name: "20" },
  { _id: "21", name: "21" },
  { _id: "22", name: "22" },
  { _id: "23", name: "23" },
  { _id: "24", name: "24" },
  { _id: "25", name: "25" },
];

export const floorOffice = [
  { _id: "0", name: "Any" },
  { _id: "5", name: "Between ground - 5" },
  { _id: "10", name: "Between 6 - 10" },
  { _id: "25", name: "Between 11 - 25" },
  { _id: "26", name: "Beyond 26" },
];
export const ocStatusList = [
  { name: "Yes", _id: "Yes" },
  { name: "No", _id: "No" },
];
export const fitOutDataOffice = [
  {
    name: "Bare shell",
    value: "Bare shell",
  },
  {
    name: "Warm shell",
    value: "Warm shell",
  },
  {
    name: "Fully furnished",
    value: "Fully furnished",
  },
  {
    name: "Semi furnished",
    value: "Semi furnished",
  },
  {
    name: "Owner to do fit out",
    value: "Owner to do fit out",
  },
];

export const requiredDocuments = [
  {
    label: "Floorplans Required",
    value: "floorPlansRequired"
  },
  {
    label: "Autocad Required",
    value: "autoCadRequired"
  },
  {
    label: "Fire NOC Required",
    value: "fireNocRequired"
  },
  {
    label: "Other licenses",
    value: "otherLicensesRequired"
  }
]
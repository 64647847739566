export const errorCheck = (details) => {
  let errors = {};
  let emailRegx =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  let phoneNumberRegx = /^\d{10}$/;
  let requiredField = "This Field is Required";

  if (details.name === "") {
    errors.name = requiredField;
  }
  if (details.description === "") {
    errors.description = requiredField;
  }
  if (details.contactUs === "") {
    errors.contactUs = requiredField;
  }
  if (details.email === "") {
    errors.email = requiredField;
  } else if (!details.email.match(emailRegx)) {
    errors.email = "Please provide a valid Email ID";
  }
  if (
    details.phoneNumber === "" ||
    isNaN(details.phoneNumber) ||
    details.phoneNumber === null
  ) {
    errors.phoneNumber = requiredField;
  } else if (!details.phoneNumber.toString().match(phoneNumberRegx)) {
    errors.phoneNumber = "Please provide a valid Phone Number";
  }
  // if (details.companyName === "") {
  //     errors.companyName = requiredField;
  // };
  // if (details.contactPerson === "") {
  //     errors.contactPerson = requiredField;
  // };
  return errors;
};

import {useEffect,useState} from 'react';
import ReportCards from '../../../components/Cards/report';
import { cardArray } from './reportsArray';
import Loader from '../../../components/Loader';
import { useSelector, useDispatch } from "react-redux";
import {updateParentPageData} from "../../../pages/Login/reducer";
import { useLocation } from 'react-router-dom';
import { getData } from '../../../api';

export default function ReportData() {
   const [filterArray,setFilterArray] =useState([]);
   const location =useLocation();
   const [isloding,setIsLoading] =useState(false);
   const [reportValue,setReportValue]=useState({})
   const user = useSelector((state) => state.login.parentPage);
   const storeuser = location?.state?.user?location?.state?.user:user;
   const getReportData = async () => {
    setIsLoading(true)
    let url = `/admin/report?type=${storeuser}`;
    const res = await getData (url, {}, {});
    const { statusCode, data } = res;
    if (statusCode === 200) {
      setIsLoading(false)
      setReportValue(data)
    } else {
      setIsLoading(false)
    }
  };

const dispath = useDispatch()
    useEffect(()=>{
      getReportData()
        if(storeuser){
          dispath(updateParentPageData(storeuser))
        let filterValue =cardArray.filter((item)=>item.name===storeuser)?.[0]?.value;
        setFilterArray(filterValue)
        return ()=>{
          setFilterArray([])
                  setReportValue({})
        }
        }},[storeuser])

  return (
    <>
     <label className="text-heading" title="Dashboard">
          {`${storeuser==="agent"?"Agent":`Enterprise ${storeuser}` }`}
        </label>
{isloding?<Loader/>:<ReportCards keyData={filterArray} value={reportValue}/>}
 
    
    </>
  )
}

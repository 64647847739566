import React, { useEffect, useState } from "react";
import { getData, postGetData } from "../../../api";
import { Row, Col, Form } from "react-bootstrap";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select";
import TextArea from "../../../components/Form/TextArea";
import Checkbox from "../../../components/Form/Checkbox";
import Button from "../../../components/Form/Button";
import MapDragger from "../../../components/Maps/mapDrag";
import { isFalsy, isTruthy } from "../../../utilities/commonFunction";
import {
  getPropertyDetailRetail,
  getPropertyDetailOffice,
  getPocDetail,
} from "../options";
import GoogleAutoComplete from "../../../components/autoSearch/googleAutoSearch";
import Loader from "../../../components/Loader";
import swal from "sweetalert";
import { useNavigate, useLocation } from "react-router-dom";
import MultiSelect from "../../../components/select/dropDown";
import { RenderRangeInput } from "../../AddNewDemand/inputGroups";
import { getPropertyDetailWCowork } from "../coworkingSpaceOptions";


const CoworkingPropertyDetail = ({
  gotoStep,
  isExitData,
  propertyDetail,
  step,
  cityList,
  propertyList,
  propertyType,
  initData,
  pocUserId,
  setPocUserId,
  cityLocation,
  setCityLocation,
  propertyTypeChange,
  propertyTypeId,
  cityUpdate,
  properytyObject,
  setProperyObject,
  propertyStepButtonRef,
  propertyStepFormRef,
  properyDetailUpdate,
  setChanged,
  setRateInfo,
  googleMapError,
  setGoogleMapError,
  coworkPropertyDetailDivRef,
  coworkGoogleMapDivRef
}) => {
  const [properytyData, setPropertyData] = useState([]);
  // const [properytyObject, setProperyObject] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [carpetError, setCarpetError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [microLocationError, setMicroLocationError] = useState(false);
  const [carpetAreaError, setCarpetAreaError] = useState(false)
  const [pincodeError, setPincodeError] = useState(false)
  const [tenureError, setTenureError] = useState(false)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let navigate = useNavigate();
  const checkAgent = () => {
    return initData?.developerType === "Agent" ? true : false;
  };

  useEffect(() => {
    if(!isExitData) {
      // setProperyObject({})
      setGoogleMapError(false)
    }
  }, [])

  const setInitialData = (optionsData) => {
    if (isExitData) {

      optionsData.forEach((item) => {
        if (item.name === "userId") {
          item.disabled = false;
        }
        setProperyObject((prev) => ({

          ...prev,
          [item.name]:
            propertyDetail[item.name] === true
              ? "yes"
              : propertyDetail[item.name] === false
                ? "no"
                : propertyDetail[item.name],
          locationmap: JSON.stringify(propertyDetail.microLocation),
          mapLoacation: JSON.stringify(propertyDetail.gMapLatLong),
          propertyTypeId: propertyTypeId,
          possession: +propertyDetail.possession,
          gMapLatLong: propertyDetail.gMapLatLong,
          userId: pocUserId,
          location: cityLocation,
        }));
      });
    } else {

      optionsData.forEach((item) => {
        setProperyObject((prev) => ({
          ...prev,
          [item.name]: item.name === "microLocation" ? {} : "",
          transactionType:  "Lease",
          propertyTypeId: propertyTypeId,
          locationmap: "",
          mapLoacation: "",
          location: "",
          userId: pocUserId,
          propertyUnit: "individual",
          projectDeveloper:
            searchParams.get("developerType") === "Developer"
              ? searchParams.get("developerName")
              : "",
        }));
      });
    }
  };
  const getPocData = async (optionsData) => {
    let url = `/brand/getAllPoc/${initData?.developerId}`;
    const newData = await getData(url, {}, {});
    const { statusCode, data, status } = newData;
    if (status && statusCode === 200) {
      let pocData = { ...getPocDetail };
      // if (isExitData) {
      //   pocData.disabled = true;
      // }
      pocData.options = data;
      optionsData.unshift(pocData);
      setPropertyData(optionsData);
      setInitialData(optionsData);
    }


  };

  const setOptions = () => {
    setInitLoading(true);
    const optionData = [...getPropertyDetailWCowork];
        
    optionData.forEach((element) => {

      if (element.name === "location") {
        element.options = cityList.map((x) => ({
          name: x.name,
        }));
        if (cityList && cityList.length > 0) {
          element.disabled = false;
        }
      }
      if (element.name === "propertyTypeId") {
        element.options = propertyList;
        if (propertyDetail?._id) {
          element.disabled = true;
        }
        else {
          element.disabled = false;
        }


      }
      if (element.name === "transactionType" && (propertyType === "Office space" || propertyType === "Flex Space")) {
        element.disabled = true;
      }
      // if (element.name == 'propertyName' && !checkAgent()) {
      //   element.required = true
      // }
      if (element.name === "projectDeveloper") {
        if (initData?.developerType === "Developer") {
          element.disabled = true;
        } else {
          element.disabled = false;
        }
      }
    });

    let withOutBrokerData = optionData.filter((x) => x.name !== "brokerage");
    if (initData?.developerType === "Developer") {
      getPocData(withOutBrokerData);
    } else {
      setPropertyData(optionData);
      setInitialData(optionData);
    }
    setInitLoading(false);
  };









  const handleMultiChange = (selectedData, selectedname) => {
    setLocationError(false)
    const optionData = {
      ...properytyObject, microLocation: {},
      locationmap: ""
    };
    optionData[selectedname] = selectedData;
    setProperyObject(optionData);
  };
  const checkZoneAvailablity = async (pocId) => {
    setInitLoading(true);
    let url = `/brand/poc/getcities/${initData?.developerId}/${pocId}`;
    const res = await getData(url, {});
    const { statusCode } = res;
    if (statusCode === 200) {
      cityUpdate(res?.data.city)
      setInitLoading(false);
    } else {
      setInitLoading(false);

    }
  }

  const handleChange = (e, item) => {
    setChanged(true)
    const { name, type } = item;
    const value = e.target.value;
    const checked = e.target.checked;
    if (value.length > item.max) {
      return
    }
    if (item.name === "microLocation") {
      setMicroLocationError(false)
    }
    if (item.name === "pincode") {
      setPincodeError(false)
    }
    if (item.name === "userId") {
      if (isTruthy(value)) {
        checkZoneAvailablity(value)
      }
      else {
        cityUpdate([])
      }
      setPocUserId(value);
      setCityLocation('');

      const optionData = [...getPropertyDetailWCowork]
      optionData.forEach((element) => {
        if (element.name === "location") {
          if (!checkAgent()) {
            element.disabled = true;
          }
        }
      });

    }
    setProperyObject((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    let propertyTypeObj
    if (item.name === "propertyTypeId") {
      propertyTypeObj = item.options.filter(x => x._id === value)
      propertyTypeChange(propertyTypeObj[0]);
    }
    setCarpetError(false);
    setPincodeError(false)
    if (name === "location") {
      setProperyObject((prev) => ({
        ...prev,
        microLocation: {},
        locationmap: "",
      }));
    }
  };



  const changeMapLocation = (val) => {
    setGoogleMapError(false)
    setProperyObject((prev) => ({
      ...prev,
      gMapLatLong: val,
      mapLoacation: JSON.stringify(val),
    }));
  };
  const handleSelectLocation = (suggestion, action) => {
    setMicroLocationError(false)
    let Locationdata = {
      ...suggestion,
      _id: suggestion?.placeId ? suggestion?.placeId : suggestion?._id,
      name: suggestion?.description,
    };
    setProperyObject((prev) => ({
      ...prev,
      microLocation: Locationdata,
      locationmap: JSON.stringify(Locationdata),
    }));
  };


  // const properyDetailUpdate = async () => {
  //   setLoading(true);
  //   const copyData = {};
  //   Object.entries(properytyObject).forEach((item) => {
  //     if (item[1]) copyData[item[0]] = item[1];
  //     if (item[1] === "yes") copyData[item[0]] = true;
  //     if (item[1] === "no") copyData[item[0]] = false;
  //     if(item[0]==="fitOuts"){
  //       copyData[item[0]] = item[1]
  //     }
  //   });

  //   delete copyData.locationmap;
  //   delete copyData.mapLoacation;

  //   copyData.step = 1;
  //   copyData.developerId = initData?.developerId;
  //   if (initData?.propertyId !== "0")
  //     copyData.propertyId = initData?.propertyId;
  //   const res = await postGetData("/property", {}, copyData);

  //   const { statusCode, data, message } = res;
  //   if (statusCode === 200) {
  //     setLoading(false);
  //     let url = `/property/${initData.type}?propertyId=${data?.propertyId}&developerId=${initData.developerId}&developerType=${initData.developerType}&developerName=${initData?.developerName}`;
  //     gotoStep(2, true);

  //     navigate(url);
  //   } else {
  //     setLoading(false);
  //     swal({
  //       text: message,
  //       icon: "error",
  //       timer: 3000,
  //       buttons: false,
  //     });
  //   }
  // };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (isFalsy(properytyObject.location)) {
      setLocationError(true)
    } else {
      setLocationError(false)
    }
    // console.log(properytyObject.pincode?.length !== 6)
    // debugger
    if (properytyObject.pincode?.length !== 6) {
      setPincodeError(true)
    } else {
      setPincodeError(false)
    }
    if (Number(properytyObject.lockInPeriod) > Number(properytyObject.tenure)) {
      setTenureError(true)
    } else {
      setTenureError(false)
    }

    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    if ((properytyObject.propertyUnit !== 'multiple') && (parseInt(properytyObject.builtupArea) < parseInt(properytyObject.carpetAreaMax))) {
      setCarpetError(true);
    }
    else {
      setCarpetError(false);
    }

    if (Number(properytyObject.carpetAreaMax) > 0) {
      if (Number(properytyObject.carpetAreaMax) < Number(properytyObject.carpetAreaMin)) {
        setCarpetAreaError({
          ["carpetAreaMax"]: "Check Max value"
        })
      } else {
        setCarpetAreaError(false)
      }
    } else {
      setCarpetAreaError({
        ["carpetAreaMax"]: "This field is required"
      })
    }

    setValidated(true);
    if (!form.checkValidity() && isFalsy(locationError) && isFalsy(microLocationError)) {

    }
  };

  useEffect(() => {
    setOptions(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyType, cityList]);
  const handelCheckLocationError = (name, value) => {

    if (name === "microLocation" && isFalsy(value?.placeId) && isFalsy(value?._id)) {
      setMicroLocationError(true)
      setProperyObject((pre) => ({ ...pre, microLocation: {}, locationmap: "" }));

    }
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA') {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  const handleRadio = (event) => {
    let obj = {}
    if (event.target.value === 'individual') {
      obj["noOfUnits"] = ""
      obj["carpetAreaMax"] = ""
      obj["rentPsf"] = ""
    } else {
      obj["builtupArea"] = ""
      obj["carpetAreaMax"] = ""
      obj["rentPsf"] = ""
    }
    setProperyObject((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      ...obj
    }))
  }

  return (
    <div className="property-details-container" ref={coworkPropertyDetailDivRef}>
      <Row>
        <Col md="1" />
        <Col md="10">
          <h4>Primary Details</h4>
          {initLoading && <Loader />}
          {!initLoading && (
            <Form noValidate validated={validated} onSubmit={handleSubmit} ref={propertyStepFormRef}>
              <Row>
                {properytyData &&
                  properytyData.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.type === "text" || item.type === "number" && (item.type == "number" && item.name !== "builtupArea" && (item.name !== "carpetAreaMax") && item.name !== "tenure" && item.name !== "lockInPeriod" && item.name !== "pincode") ? (
                        <Col md="6">
                          <Input
                            {...item}
                            value={properytyObject[item.name]}
                            onChange={(e) => handleChange(e, item)}
                            hidden={
                              item.name === "leaseEscalationpercentage" &&
                                properytyObject.leaseEscalationValue
                                ? false
                                : item.name === "leaseEscalationpercentage"
                                  ? true
                                  : false
                            }
                          // onBlur={(e) => {
                          //   if (item.name == "rentPsf" && properytyObject["rentPsf"] !== "") {
                          //     setRateInfo(true)
                          //   }
                          // }}
                          // errorMessage={pincodeError && item.name === "pincode" ? "Please enter valid pincode" : ''}
                          // isInvalid={pincodeError && item.name === "pincode" ? true : false}
                          />
                        </Col>
                      ) : null}
                      {(item.type == "number" && item.name == "pincode") ? (
                        <Col md="6">
                          <Input
                            {...item}
                            value={properytyObject[item.name]}
                            onChange={(e) => handleChange(e, item)}
                            hidden={
                              item.name == "leaseEscalationpercentage" &&
                                properytyObject.leaseEscalationValue
                                ? false
                                : item.name == "leaseEscalationpercentage"
                                  ? true
                                  : false
                            }
                            errorMessage={pincodeError ? "Please enter valid pincode" : ''}
                            isInvalid={!!pincodeError}
                          />
                        </Col>
                      ) : null}
                      {
                        (item.type == "number" && (item.name === "builtupArea" || item.name === "carpetAreaMax") && initData.developerType !== "Developer") ? <Col md="6">
                          <Input
                            {...item}
                            value={properytyObject[item.name]}
                            onChange={(e) => handleChange(e, item)}
                            hidden={
                              item.name == "leaseEscalationpercentage" &&
                                properytyObject.leaseEscalationValue
                                ? false
                                : item.name == "leaseEscalationpercentage"
                                  ? true
                                  : false
                            }
                            errorMessage={item.name == "builtupArea" && carpetError ? "Built Up Area must be greater than Carpet Area" : item.errorMessage}
                            isInvalid={!!(item.name == "builtupArea" && carpetError)}
                          />
                        </Col> : null
                      }
                      {
                        (item.type == "number" && item.name === "tenure" && propertyType !== "Retail") ? <Col md="6">
                          <Input
                            {...item}
                            value={properytyObject[item.name]}
                            onChange={(e) => handleChange(e, item)}
                            hidden={
                              item.name == "leaseEscalationpercentage" &&
                                properytyObject.leaseEscalationValue
                                ? false
                                : item.name == "leaseEscalationpercentage"
                                  ? true
                                  : false
                            }
                           


                          />
                        </Col> : null
                      }
                      {
                        (item.type == "number" && item.name === "lockInPeriod" && propertyType !== "Retail") ? <Col md="6">
                          <Input
                            {...item}
                            value={properytyObject[item.name]}
                            onChange={(e) => handleChange(e, item)}
                            hidden={
                              item.name == "leaseEscalationpercentage" &&
                                properytyObject.leaseEscalationValue
                                ? false
                                : item.name == "leaseEscalationpercentage"
                                  ? true
                                  : false
                            }
                            errorMessage={tenureError ? "Shouldn`t be more that lease tenure" : ""}
                            isInvalid={tenureError ? true : false}
                          />
                        </Col> : null
                      }
                      {
                        !checkAgent() && item.type == "radio" ? <><Col md="6" className="gap-2 d-flex align-items-center">
                          {
                            item?.options?.map((value, index) => {
                              return (<React.Fragment key={index}>
                                <label htmlFor={`id-${value.value}`}>
                                  <input
                                    type="radio"
                                    value={value.value}
                                    name={item.name}
                                    checked={properytyObject[item.name] == value.value}
                                    onChange={handleRadio}
                                    id={`id-${value.value}`}
                                  />{" "}
                                  {value.label} &nbsp;
                                </label>
                              </React.Fragment>
                              )
                            })
                          }
                        </Col>
                          {
                            item.fields.map((value, index) => {
                              // console.log(properytyObject["propertyUnit"], value.requiredFor, properytyObject["propertyUnit"] !== value.requriedFor)
                              if (value?.type == "number" && value.name == "builtupArea") {
                                return <Col md="6" key={index} hidden={
                                  properytyObject["propertyUnit"] !== value.requiredFor ? true : false
                                }

                                >
                                  <Input
                                    {
                                    ...value
                                    }
                                    value={properytyObject[value.name]}
                                    onChange={(e) => handleChange(e, value)}
                                    errorMessage={carpetError ? "Built Up Area must be greater than Carpet Area" : (value.errorMessage)}
                                    isInvalid={!!carpetError}
                                  />
                                </Col>
                              } else if (value?.type == "number" && value.name !== "builtupArea") {
                                return <Col md="6" key={index} hidden={
                                  properytyObject["propertyUnit"] !== value.requiredFor ? true : false
                                }

                                >
                                  <Input
                                    {
                                    ...value
                                    }
                                    value={properytyObject[value.name]}
                                    onChange={(e) => handleChange(e, value)}
                                  />
                                </Col>
                              } else if (value?.type == "rangeInput") {
                                return <Col md="5" key={index} hidden={
                                  properytyObject["propertyUnit"] !== value.requiredFor ? true : false
                                }>
                                  <label
                                    style={{
                                      fontStyle: "normal",
                                      fontWeight: "700",
                                      fontSize: "14px",
                                      color: "#000000",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {value.label}
                                    {value.defaultChecked ? "*" : null}
                                  </label>
                                  <Row className="g-2">
                                    {value?.rangefields?.map((val, i) => {
                                      return <Col
                                        key={i}
                                        md="6"
                                        className="d-flex  align-items-start range-input"
                                      >
                                        <RenderRangeInput
                                          {
                                          ...val
                                          }
                                          value={properytyObject[val.name]}
                                          name={val.name}
                                          label={val.label}
                                          onChange={(e) =>
                                            handleChange(e, val)
                                          }
                                          isInvalid={!!carpetAreaError[val.name]}
                                          errorMessage={carpetAreaError[val.name]}
                                        />
                                      </Col>
                                    })}
                                  </Row>
                                </Col>
                              }
                            })
                          }

                        </> : null
                      }
                      {item.type === "microLocation" ? (
                        <Col md="6">
                          <GoogleAutoComplete
                            cityValue={
                              properytyObject["location"]
                            }
                            label="Location * "
                            value={properytyObject[item.name]}
                            handleSelectLocation={handleSelectLocation}
                            size={"m-0"}
                            handleBlur={() => handelCheckLocationError(item.name, properytyObject[item.name])}
                          />
                          <Input
                            {...item}
                            type="text"
                            value={properytyObject.locationmap}
                            onChange={(e) => handleChange(e, item)}
                            errorMessage={microLocationError ? "Please enter valid location" : item.errorMessage}
                            isInvalid={microLocationError ? true : false}

                          />
                        </Col>
                      ) : null}
                      {item.type === "textarea" ? (
                        <Col md="6">
                          <TextArea
                            {...item}
                            value={properytyObject[item.name]}
                            onChange={(e) => handleChange(e, item)}
                          />
                        </Col>
                      ) : null}

                      {item.type === "select" ? (
                        <Col md="6">
                          <Select
                            {...item}
                            value={properytyObject[item.name]}
                            onChange={(e) => handleChange(e, item)}

                          />
                        </Col>
                      ) : null}


                      {item.type === "SearchSelect" ? (
                        <Col md="6">
                          <MultiSelect
                            value={
                              properytyObject[item.name] ?
                                { label: properytyObject[item.name], value: properytyObject[item.name] } : ""
                            }
                            lable={`${item.label}*`}
                            requried={true}
                            name={item.label}
                            onChange={(val) =>
                              handleMultiChange(val?.value, item.name)
                            }
                            options={item.options.map((opt) => ({ label: opt?.name, value: opt?.name }))}
                            errormessage={locationError ? item.errorMessage : ""}
                            placeholder={item.placeholder}
                            isMulti={false}
                          />

                        </Col>
                      ) : null}
                      {item.type === "checkbox" ? (
                        <Col md="6" >
                          <Checkbox
                            {...item}
                            value={properytyObject[item.checked]}
                            checked={item.checked}
                            onChange={(e) => handleChange(e, item)}
                          />
                        </Col>
                      ) : null}
                      {item.type === "google-location" ? (
                        <Col md="6" ref = {coworkGoogleMapDivRef}>
                          <label className="fw-700 f-14 text-black form-label">
                            Google Location
                          </label>

                          <MapDragger
                            mapValue={properytyObject[item.name]}
                            handleChangeMap={changeMapLocation}
                          />
                          <Input
                            {...item}
                            type="text"
                            value={properytyObject.mapLoacation}
                            onChange={(e) => handleChange(e, item)}
                          />
                          {googleMapError &&   <div className="customGoogleMapInvalid">{item?.errorMessage}</div>}
                        </Col>
                      ) : null}
                    </React.Fragment>
                  ))}
                {/* {carpetError ? (
                  <Col md="12">
                    <p className="text-danger text-center">
                      Built Up Area must be greater than Carpet Area
                    </p>
                  </Col>
                ) : null} */}
                <Col md="12" hidden={true}>
                  <div className="text-end">
                    <Button
                      type="submit"
                      className="next-btn p-0"
                      loading={loading}
                      _ref={propertyStepButtonRef}
                    >
                      Next
                    </Button>
                  </div>
                </Col>

              </Row>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CoworkingPropertyDetail;

import { useState, useEffect } from "react";
// components
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import MultiSelect from "../../../components/select/dropDown";
import Search from "../../../components/Search/search";
import { getCurrentUser, getData, postData, switchToUsers } from "../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../assets/images/switchuser.png";
import { FaBell } from "react-icons/fa";
import { truncateString } from "../../../utilities/commonFunction";
import Loader from "../../../components/Loader";

const AgentDBTable = ({ filterData, trigger }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [agentList, setAgentList] = useState([]);

  const [agentFilterData, setAgentFilterData] = useState("");
  const [interestFilterData, setInterestFilterData] = useState(
    filterData ? filterData : ""
  );
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);
  const filterOptions = [
    {
      name: "Interest Received",
      value: "response",
    },
    {
      name: "Matching Demands",
      value: "relevent",
    },
  ];

  //Switch Acount
  const handleAccountSwitch = async ({ userId, companyId }) => {
    const data = {
      companyId: companyId,
      customerId: userId,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = process.env.REACT_APP_SWITCH_URL;
      if (res.status) {
        window.open(`${openUrl}/${res?.data?.token}`);
      }
    });
  };

  const renderRow = (value, isRead) => {
    if (value === null || value === undefined) {
      return (
        <div className="db-number-card-container">
          <div className="db-number-card">-</div>
        </div>
      );
    } else {
      return (
        <div className="db-number-card-container">
          <div className="db-number-card" title={String(value)}>
            {isRead ? "" : <div className="notification-count"></div>}
            {truncateString(String(value), 3)}
          </div>
        </div>
      );
    }
  };

  const columnsAgent = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "contactPerson",
      text: "Agent Name",
    },
    {
      dataField: "propertyCount",
      text: "Properties Posted",
      formatter: (cellContent, row, rowIndex) => {
        return renderRow(row.propertyCount, true);
      },
    },
    {
      dataField: "interestCount",
      text: "Interest Received",
      formatter: (cellContent, row, rowIndex) => {
        return renderRow(row.interestCount, row?.interestisRead);
      },
    },
    {
      dataField: "releventCount",
      text: "Matching Demands",
      formatter: (cellContent, row, rowIndex) => {
        return renderRow(row.releventCount, row?.releventIsRead);
      },
    },
    {
      dataField: "notification",
      text: "Notification",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="db-number-card-container">
            <div className="db-number-card">
              {row?.notificationisRead ? (
                ""
              ) : (
                <div className="notification-count"></div>
              )}
              <FaBell />
            </div>
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="db-number-card-container">
            {" "}
            {getCurrentUser()?.role[0]?.name === "Relationship Manager" ||
            getCurrentUser()?.role[0]?.name === "admin" ? (
              <img
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
                onClick={() => {
                  handleAccountSwitch({
                    userId: row?.userId?._id,
                    companyId: row?._id,
                  });
                }}
                src={switchuser}
                alt="switch"
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }
    let url = `/dashboard/getCustomers?type=Agent&page=${offSet}&limit=${perPage}`;
    if (search !== "") {
      url += `&search=${search}`;
    }
    if (
      agentFilterData?.label &&
      agentFilterData?.value !== "Select" &&
      agentFilterData?.label !== ""
    ) {
      url += `&name=${agentFilterData?.value}`;
    }
    if (interestFilterData !== "Select" && interestFilterData !== "") {
      url += `&interest=${interestFilterData}`;
    }
    let cusomerData = await getData(url);
    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
      setTableData(data.brand);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const getAgentList = async () => {
    let url = "/dashboard/customerdropdown?type=Agent";
    let agentData = await getData(url);
    const { statusCode, data } = agentData;
    if (statusCode === 200) {
      let agentArray = data.brand.map((item) => {
        return { label: item.contactPerson, value: item._id };
      });
      setAgentList(agentArray);
    } else {
      setAgentList([]);
    }
  };
  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  const handleAgentChange = (val) => {
    setAgentFilterData(val);
    setOffset(1);
  };
  const handleInterestChange = (e) => {
    setInterestFilterData(e.target.value);
    setOffset(1);
  };
  const tableProps = {
    data: tableData,
    columns: columnsAgent,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };
  useEffect(() => {
    getTableData(true);
  }, [perPage, offSet, search, agentFilterData, interestFilterData]);
  useEffect(() => {
    getTableData(false);
  }, [trigger]);
  useEffect(() => {
    getAgentList();
  }, []);
  return (
    <>
      {loading && <Loader className={"fixed"} />}
      <div className="db-filter-container">
        <Row className=" filter-select">
          <Col md={5} sm={6} className="d-flex align-items-start pt-3">
            <Select
              placeholder={"Select..."}
              size={"w-100"}
              onChange={handleInterestChange}
              options={filterOptions}
              value={interestFilterData}
            />
          </Col>
          <Col md={5} sm={6} className="d-flex align-items-start ">
            <MultiSelect
              disabled={false}
              onChange={(val) => handleAgentChange(val)}
              isMulti={false}
              options={[
                { label: "Select Agent", value: "Select" },
                ...agentList,
              ]}
              value={agentFilterData}
              placeholder={"Select Agent"}
            />
          </Col>
        </Row>
        <Row className=" filter-search">
          <Col md={4} sm={6} className="d-flex align-items-start pt-3">
            <Search onChange={handleSearch} value={search} />
          </Col>
        </Row>
      </div>
      <MainTable {...tableProps} />
    </>
  );
};
export default AgentDBTable;

import { useEffect, useReducer, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "../../components/Form/Select";
import Loader from "../../components/Loader/index";
import MainTable from "../../components/Table/index";

import { useContext } from "react";
import { AppContext } from "../../App";
import {
  AccessDenied,
  getCurrentUser,
  getData,
  putData,
} from "../../api/index";
import Button from "../../components/Form/Button";
import NavBar from "../../components/Navbar";
import Search from "../../components/Search/search";
import { isObjectHasValue } from "../../utilities/commonFunction";
import { getContextFilterData, getContextSearchData, getTableStoreValue, setScrollBackId, setTableFilterData } from "../../utilities/pageScroll";
import { CustomerWarningPopup } from "../Customer/CustomerTable/warningPopup";
import "./style.scss";
export default function Demand() {
  const navigate = useNavigate();
  const { tableStoreContext, setTableStoreContext, pageScroll, setPageScroll } =
    useContext(AppContext);

  let tableName = "totalDemands";
  const currentTableValueFromConText = getTableStoreValue(tableName, tableStoreContext);
  const storePageNo = currentTableValueFromConText?.pageNo;
  const storePerPage = currentTableValueFromConText?.perPage;
  let contextFilterData = getContextFilterData(tableName, pageScroll);
  let contextSearchData = getContextSearchData(tableName, pageScroll);


  const [offset, setOffset] = useState(storePageNo ? storePageNo : 1);
  const [perPage, setPerPage] = useState(storePerPage ? storePerPage : 10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState(contextSearchData ? contextSearchData : "");
  const [demandList, setdemandList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let initialState = {
    requirementSort: "",
    createdSort: "",
    propertyTypeSort: "",
    transactionTypeSort: ""
  };
  const reducer = (state, action) => {

    if (!action.type) {
      return state;
    }

    return {
      ...state,
      [action.type]: action.value,
    };
  };
  let filterinitialState = contextFilterData || initialState;
  const [filter, filterReducerDispatch] = useReducer(
    reducer,
    filterinitialState
  );
  //active states
  const [activeStatus, setActiveStatus] = useState(false);
  const [demandId, setDemandId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);
  const [propertyTypeList, setPropertyTypeList] = useState([])

  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    view: loginPermission?.includes(menuId?.viewDemand) ? true : false,
    edit: loginPermission?.includes(menuId?.editDemand) ? true : false,
    enable: loginPermission?.includes(menuId?.demandEnableOrDisable)
      ? true
      : false,
  };

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];
  let customerRequirementValues = [
    {
      name: "Largest",
      _id: "desc",
    },
    {
      name: "Smallest",
      _id: "asc",
    },
  ];

  let customerTransactionTypeSortValues = [
    {
      _id: "Lease",
      name: "Lease",
    },
    {
      _id: "Revenue Share",
      name: "Revenue Share",
    },
    {
      _id: "MG Revenue Share",
      name: "MG Revenue Share",
    },
    {
      _id: "Buy",
      name: "Buy",
    },
  ]


  const setContextValue = (id) => {
    let scrollArray = setScrollBackId(tableName, id, filter, search, pageScroll);
    setPageScroll(scrollArray);

  }
  const updateTableStoreContext = (id) => {

    let newValue = setTableFilterData(
      tableName,
      { pageNo: offset, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  }
  const handelDemandEdit = (id, brandId, brandName, userId, propertyType) => {

    localStorage.setItem("demandId", JSON.stringify(id));
    localStorage.setItem("propertyType", JSON.stringify(propertyType));
    if (propertyType?.name === "Office space") {
      localStorage.setItem("postDemandStep", JSON.stringify(3));
    }
    else {
      localStorage.setItem("postDemandStep", JSON.stringify(5));
    }

    navigate("/demand/PostDemand", {
      state: {
        brandId: brandId,
        userId: userId,
        action: "edit",
        brandName: brandName,
        page: "demands",
      },
    });
  };

  const getPropertyType = () => {
    let url = '/propertyType';
    getData(url, {}, {}).then((response) => {
      if (response?.status) {
        let propertyArray = [];
        response?.data?.map((data) => {
          propertyArray.push({
            name: data?.name,
            _id: data?._id,
          });
          return data;
        });
        setPropertyTypeList(propertyArray);
      }
    });
  };


  const getBrandData = async (brandId, id, propertyType) => {
    let url = `/brand/${brandId}`;
    const response = await getData(url, {});
    const { status, data } = response;
    if (status === true) {
      updateTableStoreContext(id);
      handelDemandEdit(id, brandId, data?.name, brandId, propertyType);
    }


  };
  const getDemandList = async () => {
    setIsLoading(true);
    let url = `/demand/getDemands?pageNo=${offset}&limit=${perPage}&userId=${getCurrentUser()._id
      }&type=table`;
    if (search) {
      url += `&search=${search}`;
    }
    if (filter?.createdSort && filter?.createdSort !== "select") url += `&sort=${filter?.createdSort}`;
    if (filter?.requirementSort && filter?.requirementSort !== "select") url += `&carpetArea=${filter?.requirementSort}`;
    if (filter?.transactionTypeSort && filter?.transactionTypeSort !== "select") url += `&transactionType=${filter?.transactionTypeSort}`;
    if (filter?.propertyTypeSort && filter?.propertyTypeSort !== "select") url += `&propertyType=${filter?.propertyTypeSort}`;
    const response = await getData(url, {});
    const { statusCode, data } = response;
    if (statusCode === 200) {
      setIsLoading(false);
      setdemandList(data.demands);
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
    } else {
      setPageCount(0);
      setIsLoading(false);
      setdemandList([]);
    }
  };

  const warningYesClick = async () => {
    let url = `/demand/updateDemand/${demandId}`;
    let datas = {
      isActive: activeStatus ? false : true,
    };
    setIsLoading(true);
    const apiDatas = await putData(url, {}, datas);
    const { statusCode } = apiDatas;
    if (statusCode === 200) {
      setIsLoading(false);
      getDemandList();
      setWarningPopup(false);
    } else {
      setIsLoading(false);
      setWarningPopup(false);
    }
  };

  const handleWarningClose = () => {
    setWarningPopup(false);
  };

  const updateStatus = (id, active) => {
    setActiveStatus(active);
    setDemandId(id);
    setWarningPopup(true);
    if (active === true) {
      setWarningMessage("Are you sure, you want to inactivate this Demand");
    } else if (active === false) {
      setWarningMessage("Are you sure, you want to activate this Demand");
    }
  };

  const properSizeFormatter = (cell, row) => {
    return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${row.carpetAreaMax ? row.carpetAreaMax : 0
      } sqft`;
  };
  const postedByFormatter = (cell, row) => {
    if (row?.brandNameRequired) {
      return `${row.brandId ? row.brandId?.name : "Anonymous"}`;
    }
    return "Anonymous";
  };


  const onClickViewDemand = (row) => {
    updateTableStoreContext(row?._id)
    navigate(`/demand/DemandDetailView/${row?.productId?.display}`, {
      state: { demandId: row._id, brandId: row?.brandId?._id, page: "demands" },
    });
  };

  function userButtonFormatter(cell, row, rowIndex) {
    return (

      <div className="d-flex justify-content-center align-items-center ">
        <Button className="table-btn p-0" disabled={access?.view ? false : true}>
          <BsFillEyeFill onClick={() => onClickViewDemand(row)} />
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={row?.isActive && access?.edit ? false : true}

        >
          <span className="cursor-pointer">
            <FaPen
              onClick={
                access.edit
                  ? () => getBrandData(row?.brandId?._id, row._id, row?.propertyType)
                  : null
              }
            />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </Button>
        <Form.Check
          type="switch"
          id={`custom-switch_${rowIndex}}`}
          checked={row.isActive ? true : false}
          disabled={access.enable ? false : true}
          onChange={(e) => {
            updateStatus(row._id, row.isActive);
          }}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>

    );
  }
  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const columns = [
    {
      dataField: "productId.display",
      text: "Demand id",
    },
    {
      dataField: "brand",
      text: "Brand name",
    },
    {
      dataField: "brandId",
      text: "Submitted by",
      formatter: postedByFormatter,
    },
    {
      dataField: "propertyType",
      text: "Property type",
      formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
    },
    {
      dataField: "transactionType",
      text: "Transaction Type",
    },
    {
      dataField: "location",
      text: "City",
    },
    {
      dataField: "microLocation",
      text: "Locality",
      formatter: (cellContent, row, rowIndex) => {
        return <span>
          {row?.microLocation?.description}
        </span>
      }
    },
    {
      dataField: "carpetAreaMax",
      text: "Requirement size",
      formatter: properSizeFormatter,
    },
    {
      dataField: "createdAt",
      text: "Created on",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatter,
    },
  ];

  const tableProps = {
    data: demandList,
    columns,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "totalDemands",
    setPerPage
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  const handleCreatedSort = (e) => {
    filterReducerDispatch({ type: "createdSort", value: e.target.value })
    setOffset(1);
  };
  const handleRequirementSort = (e) => {
    filterReducerDispatch({ type: "requirementSort", value: e.target.value })
    setOffset(1);
  };
  const handleTransactionTypeSort = (e) => {
    filterReducerDispatch({ type: "transactionTypeSort", value: e.target.value })
    setOffset(1);
  }
  const handlePropertyTypeSort = (e) => {
    filterReducerDispatch({ type: "propertyTypeSort", value: e.target.value })
    setOffset(1);
  }

  useEffect(() => {
    getDemandList();
    if (search || isObjectHasValue(filter)) {

      setContextValue();
    }
  }, [search, perPage, offset, filter]);

  useEffect(() => {
    getPropertyType()
  }, [])

  return (
    <>
      {loginPermission?.includes(menuId?.demand) ? (
        <div className="dashboard-container">
          {warningPopup && (
            <CustomerWarningPopup
              show={warningPopup}
              title={"Confirmation"}
              message={warningMessage}
              warningYesClick={warningYesClick}
              onClose={handleWarningClose}
            />
          )}
          <div className="d-flex justify-content-between">
            <NavBar />

            <Row className="filter_container" style={{width: "75%"}}>
              <Col>
                <Search onChange={handleSearch} value={search} />
              </Col>
              <Col>
                <Select
                  placeholder="Property Type"
                  options={propertyTypeList}
                  name={"PropertyType"}
                  value={filter?.propertyTypeSort}
                  onChange={handlePropertyTypeSort}
                // isHideSelect
                />
              </Col>
              <Col>
                <Select
                  placeholder="Transaction Type"
                  options={customerTransactionTypeSortValues}
                  name={"Transaction"}
                  value={filter?.transactionTypeSort}
                  onChange={handleTransactionTypeSort}
                // isHideSelect
                />
              </Col>
              <Col>
                {/* <Select
                  options={customerRequirementValues}
                  name="customerRequirementType"
                  id="customerRequirementType"
                  onChange={handleRequirementSort}
                  value={createdSort}
                  isHideSelect
                /> */}
                <Select
                  placeholder="Requirement size"
                  options={customerRequirementValues}
                  name={"devloper"}
                  value={filter?.requirementSort}
                  onChange={handleRequirementSort}
                // isHideSelect
                />
              </Col>
              <Col>
                {/* <Select
                  data={customerCreatedSortValues}
                  filter={true}
                  options={"Created on"}
                  name="customerCreatedon"
                  id="customerCreatedon"
                  onChange={handleCreatedSort}
                  value={requirementSort}
                /> */}
                <Select
                  placeholder="Created on"
                  options={customerCreatedSortValues}
                  name={"Created"}
                  value={filter?.createdSort}
                  onChange={handleCreatedSort}
                // isHideSelect
                />
              </Col>

            </Row>
          </div>

          <div className="mt-4">
            <MainTable {...tableProps} />
          </div>
          {isLoading && <Loader className={"fixed"} />}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
}

import { Link, useNavigate } from "react-router-dom";
import image from "../../assets/images/pagenotfound.svg";
import "./style.scss"
import { getCurrentUser, getKeyByValue } from "../../api";
import { useSelector } from "react-redux";
import Button from "../../components/Form/Button";
export default function NotfoundPage() {
  const navigate = useNavigate();
  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);

  const handleShift = () => {
    let route = getKeyByValue(menuId, loginPermission[0]);
   
    if ( ["admin","Relationship Manager"].includes(getCurrentUser()?.role?.[0]?.name)) {
      navigate("/dashboard");
    } else {
      
      if (route === "companyBuyer") {
        navigate("/company");
      } else if (
        ["customerSeller",
        "customerAgent",
         "customerBuyer",].includes(route)
      ) {
        navigate("/customer");
      }
      else{
        if(route){
          navigate(`/${route}`);
        }
        else{
          navigate(`/`);
        }
      }
    }
    
  };
  return (
    
      <div className="pageNotFound centerForm">
        <img src={image} alt="404" />
        <h2 className="text-center">Oops! Page not found!</h2>
        <p className="text-center">
          Looks like the page you're trying to visit dosen't exist. Please check
          the URL .
        </p>
        <Button onClick={handleShift} className="btn-color-primary">
       Click to redirect
        </Button>
        {/* <Link to="/dashBoard">Home</Link> */}
      </div>
      
    
  );
}
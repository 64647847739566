import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DemandCard } from "../../components/Cards/Demands";
import { useSelector } from "react-redux";
import { getData } from "../../api";

const RelevantDemands = (props) => {
  const navigate = useNavigate();
  const [apiCardDetail, setApiCardDetail] = useState([]);
  const user = useSelector((state) => state.login.parentPage);

 

  const getDemandData = async () => {
    let url = `/demand/relevantDemands/${props?.propertyId}`;
    await getData(url, {}, {}).then((response) => {
      if (response.status) {
        setApiCardDetail(response.data);
      }
    });
  };

 

  const onClickViewDemand = (a) => {
    let url = user === "agent" ? "/agentReport" : "/sellerReport";
    navigate(
      `${url}/property/relevantDemand/DemandDetailView/${a?.productId?.display}`,
      {
        state: {
          demandId: "",
          brandId: "",
          page: "reports",
        },
      }
    );
  };
  const btnList = [
    {
      btnName: "View",
      onClick: (a) => onClickViewDemand(a),
      className: "btn-color-primary",
    },
  ];
  useEffect(() => {
    getDemandData();
  }, []);
  return (
    <div className="demand-card-container demand-card-container-relevant">
      <DemandCard
        demandDetail={apiCardDetail}
        response={false}
        button={btnList}
        relevant={true}
      />
    </div>
  );
};

export default RelevantDemands;

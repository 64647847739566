import React from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import Loader from "../Loader";

const SwitchModal = ({
  show,
  onClose,
  heading,
  children,
  size,
  loading,
  subText,
  className,
  hideCloseIcon,
  headerClassName,
  top,
  headerPadding,
  bodyPadding,
}) => {
  return (
    <div>
      {!loading && (
        <Modal show={show} centered onHide={onClose} size="sm">
          <Modal.Body
            className={className}
            style={{ paddingTop: bodyPadding ? bodyPadding : "" }}
          >
            <>
              {hideCloseIcon ? null : (
                <CloseButton
                  style={{ position: "absolute", top: top ? top : 3, right: 3 }}
                  onClick={() => onClose(false)}
                />
              )}
              <div>{children}</div>
            </>
          </Modal.Body>
        </Modal>
      )}
      {loading && <Loader className={"fixed"}></Loader>}
    </div>
  );
};

export default SwitchModal;

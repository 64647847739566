import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLogedIn } from "../../api";
import { getData, getKeyByValue } from "../../api/index";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import CardComponent from "../../components/loginCardComponent/cardComponent";
import "../../pages/Login/style.scss";
import { loginUsers, updateActiveUrl, updateMenuId } from "./reducer";

function Login() {
  let navigate = useNavigate();
  const formRef = useRef(null);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [apiError, setApiError] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [errorObject, setErrorObject] = useState({});
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    type: "admin",
  });
  const dispatch = useDispatch();
  const { activeUrl} = useSelector((state) => state.login);

  const onChange = (value, key) => {
    delete errorObject[key];
    let obj = { ...initialValues };
    if (key == "email") {
      obj[key] = value.replaceAll(" ", "");
      setInitialValues(obj);
    } else {
      setInitialValues({ ...initialValues, [key]: value });
    }
  };
  
  const loginData = useSelector((state) => state.login.loginData);
  const loginPermission = useSelector(
    (state) => state.login?.loginData?.permissions
  );
  const menuId = useSelector((state) => state.login.menuId);

  useEffect(() => {
    if (isLogedIn()) {
      let route = "";
        if (
          loginData?.role[0]?.name === "admin" ||
          loginData?.role[0]?.name === "Relationship Manager"
        ) {
          route = "dashboard";
        } else {
          route = getKeyByValue(menuId, loginPermission[0]);
        }
  
        navigate(`/${route}`);
    }
  }, []);

  const idListApi = async (val) => {
    const url = "/menu/getMenuFormatted";
    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;

    if (statusCode === 200) {
      const { name } = val?.role[0];
      const { permissions } = val;
      dispatch(updateMenuId(data));
      //name === "admin" || name === "Relationship Manager"
      if(activeUrl) {
        navigate(activeUrl);
        dispatch(updateActiveUrl(""))
      } else {
        if (permissions?.includes(data.dashboard)) {
          navigate("/dashboard");
        } else {
          const route = getKeyByValue(data, permissions[0]);
          if (route === "companyBuyer") {
            navigate("/company");
          } else if (
            ["customerSeller", "customerAgents", "customerBuyer"].includes(route)
          ) {
            navigate("/customer");
          } else {
            navigate(`/${route}`);
          }
        }
      }
      
    }
  };
  const errorCheck = (values) => {
    let reg =
      /^(?![^@]*([-.])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/;
    // var validRegex =
    //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let errorObj = {};
    if (values.email === "") {
      errorObj.email = "This field is required";
    }
    if (values.email !== "" && values.email.match(reg) === null) {
      errorObj.email = "Please enter a valid Email ID";
    }
    if (values.password === "") {
      errorObj.password = "This field is required";
    }
    return errorObj;
  };

  const loginUser = async (objdata) => {
    setBtnLoading(true);

    dispatch(loginUsers(objdata, { dispatch }))
      .unwrap()
      .then((res) => {
        if (res.statusCode === 200) {
          localStorage.setItem("keydemand_user", JSON.stringify(res.data));
          localStorage.setItem("keydemand_token", res.data.token);
          localStorage.setItem("chat_count", res.data.chatCount);
          localStorage.setItem("enquiry_count", res.data.enquiryCount);
          setBtnLoading(false);
          idListApi(res.data);
          // dispatch(updateLoginData(res.data));
        } else if (res.statusCode === 400 || res.statusCode === 422) {
          setApiError(res?.message);
          setBtnLoading(false);
        } else {
          // alert(res.message)
          setApiError("");
          setBtnLoading(false);
        }
      });
  };

  const handelForgot = () => {
    navigate("/forgot");
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if (initialValues?.email) {
      initialValues.email = initialValues?.email.trim()
    }
    let errors = errorCheck(initialValues);
    setErrorObject(errors);
    setApiError("");

    if (Object.keys(errors).length === 0) {
      loginUser(initialValues);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit(event);
    }
  };

  const loginContent = (name) => {
    return (
      <>
        {name === "login" && (
          <form ref={formRef} OnKeyPress={handleKeyPress}>
            <div>
              <h5 className="m-0 pb-1 fw-bold"> Hello, Admin </h5>
              <p className="getStarted-txt medium-txt mb-3 mt-1">
                {" "}
                Log in to get Started{" "}
              </p>

              <div className="mb-2">
                <Input
                  label={"Email ID"}
                  type={"text"}
                  value={initialValues?.email}
                  placeholder={"Enter your email ID"}
                  isInvalid={!!errorObject.email}
                  errorMessage={errorObject.email}
                  onChange={(e) => onChange(e.target.value, "email")}
                />
              </div>
              <div className="input-position-style">
                <Input
                  label={"Password"}
                  type={isRevealPwd ? "text" : "password"}
                  placeholder={"Enter your password"}
                  isInvalid={!!errorObject.password}
                  errorMessage={errorObject.password}
                  onChange={(e) => onChange(e.target.value, "password")}
                  className={"input-padding-icon"}
                />
                <span
                  className="eye-icon-style"
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                >
                  {" "}
                  {isRevealPwd ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )}{" "}
                </span>
              </div>

              <div className="error-message_span mb-3">{apiError}</div>
              <span className="d-block text-end blue-color fw-bold w-100 ">
                {" "}
                <span className="cursor" onClick={handelForgot}>
                  Forgot Password ?{" "}
                </span>
              </span>

              <div className="mt-4 submit-alignment">
                {/* <NavLink to="/dashboard"> */}
                <Button
                  loading={btnLoading}
                  type={"submit"}
                  className="submit-button-style btn-color-primary"
                  onClick={onSubmit}
                >
                  Login
                </Button>
                {/* </NavLink> */}
              </div>
            </div>
          </form>
        )}
      </>
    );
  };

  return (
    <div className="login-background">
      <CardComponent
        loginContent={loginContent("login")}
        Link
        to="/dashboard"
      />
    </div>
  );
}

export default Login;

export const errorCheck = (data) => {
  let pocObj = [
    "primary",
    "name",
    "designation",
    "email",
    "state",
    "phoneNumber",
    "region",
    "permissions",
    "city"
  ];
  let emailRegx =
    /^(?![^@]*([-.])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/;
  let phoneNumberRegx =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  let errors = {};
  pocObj?.map((item) => {
    if (data.hasOwnProperty(item)) {
      if (item === "permissions") {
        
        if (data[item]?.every((item)=>item.checked===false)) {
          errors[item] = "Please select atleast one item";
        }
      } else if (data[item] === "" || data[item]?.length === 0) {
        errors[item] = "This field is required";
      }
      if(item === "name" || item === "designation"){
        if (data[item]=== ""|| data[item].match(/^\s*$/)) {
            errors[item] = "This field is required";
        };
    }
      if (
        item === "email" &&
        data[item] !== "" &&
        !data[item].match(emailRegx)
      ) {
        errors[item] = "Please provide a valid Email ID";
      }
      if (
        item === "phoneNumber" &&
        data[item] !== "" &&
        data[item]?.length<10 &&
        !data[item].match(phoneNumberRegx)
      ) {
        errors[item] = "Please provide a valid Phone number";
      } 
    }
    return item 
  });
  return errors;
};

import { Form } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import Button from "../../../../../components/Form/Button";
import { buyerFilterUrls } from "./url";
// table response key
export const tableDataKey = (key) => {
  if (!key) return "";
  const lookup = {
    companyList: "companies",
    companyPocList: "companyPoc",
    regionPocList: "brand",
    brandList: "brand",
    brandPocList: "brand",
    demandPostedList: "demands",
    inActiveDemandList: "demands",
    activeDemandList: "demands",
    demandResponseList: "demands",
    propertyContactedList: "responseData",
    propertyAcceptedList: "responseData",
    propertyRejectedList: "responseData",
    needMoreInfoList: "responseData",
  };

  const dataKey = lookup[key];
  return dataKey;
};

// table list api Payload
export const getPayLoad = (data, offset, perPage) => {
  const defaultPayload = {
    pageNo: offset,
    limit: perPage,
  };
  const payloadMap = {
    inActiveDemandList: {
      pageNo: offset,
      limit: perPage,
      isActive: false,
    },
    activeDemandList: {
      pageNo: offset,
      limit: perPage,
      isActive: true,
    },
    demandResponseList: {
      pageNo: offset,
      limit: perPage,
      response: true,
    },
   
    propertyAcceptedList: {
      pageNo: offset,
      limit: perPage,
      demandStatus: "completed",
      propertyType: "accept",
    },
    propertyRejectedList: {
      pageNo: offset,
      limit: perPage,
      demandStatus: "rejected",
    },
    needMoreInfoList: {
      pageNo: offset,
      limit: perPage,
      propertyType: "needInfo",
    },
    export: {
      reportType: "export",
    },
  };
  return payloadMap[data] || defaultPayload;
};
export const getFilterURL = (key) => {
  if (!buyerFilterUrls.hasOwnProperty(key)) return "";
  return buyerFilterUrls[key]();
};

export const getFilterPayLoad = (data, offset, perPage) => {
  const defaultPayload = {
    pageNo: offset,
    limit: perPage,
  };
  const payloadMap = {
    companyList: {
      type: "Brand",
    },
    brandList: {
      type: "Brand",
    },
    companyPocList: {
      pocType: "companyPoc",
    },
    brandPocList: {
      pocType: "brandPoc",
    },
    regionPocList: {
      pocType: "regionPoc",
    },
    demandResponseList: {
      response: true,
    },
    inActiveDemandList: {
      isActive: false,
    },
    activeDemandList: {
      isActive: true,
    },
    propertyContactedList: {
      propertyType: "contectSeller",
    },
    propertyAcceptedList: {
      demandStatus: "completed",
      propertyType: "accept",
    },
    propertyRejectedList: {
      demandStatus: "rejected",
    },
    needMoreInfoList: {
      propertyType: "needInfo",
    },
  };

  return payloadMap[data] || defaultPayload;
};
//view formatter
export function viewbuttonFormatter(onClick) {
  return (cell, row, rowIndex) => {
    return (
        <div className="d-flex justify-content-center align-items-center table-row">
          <Button className="table-btn p-0" onClick={() => onClick(row)}>
            <BsFillEyeFill />
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
    );
  };
}
// poc view,edit,disableand enable formatter

export function userButtonFormatterPoc(page, openModal, openWaring, table) {
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    return (
        <div className="d-flex justify-content-center align-items-center ">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => openModal("view", brandId, row)}
          >
            <BsFillEyeFill />
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            style={{ cursor: "pointer" }}
            onClick={() => openModal("edit", brandId, row)}
          >
            <FaPen />
          </div>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={() => openWaring(row, row.isActive, table)}
            // disabled={props.access.enable?false:true}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
    );
  };
}

// city formatter
export function cityFormatter(handelOpenListPopup, key) {
  return (cell, row, rowIndex) => {
    const cities = row[key]
      ?.filter((city) => city?.name)
      .map((city) => city.name);
    const cityString = cities?.join(", ");

    if (!cityString) {
      return "-";
    }
    return (
      <>
        {cities.length > 1 ? (
          <span>
            {`${cities[0]},..`}
            <span
              className="view-all-btn cursor-pointer"
              onClick={() => handelOpenListPopup(cities, key)}
            >
              (View all)
            </span>
          </span>
        ) : (
          <span>{cityString}</span>
        )}
      </>
    );
  };
}

// property Id formatter
export function onClickIdFormater(key, onClickView) {
  return (cell, row, rowIndex) => {
    return (
      <div className="f-14 fw-700 table-link">
        {" "}
        <span className="cursor-pointer" onClick={() => onClickView(row)}>
          {row[key]?.display}
        </span>
      </div>
    );
  };
}
// created formatter
export function createdOnFormatter(key = "createdAt") {
  return (cell, row, rowIndex) => {
    if (!row[key] || row[key] === null || row[key] === "" || !(key in row)) {
      return "-";
    }
    return new Date(row[key]).toLocaleString();
  };
}
// count formatter
export function onClickCountFormater(key, onClickView) {
  return (cell, row, rowIndex) => {
    return (
      <div className="f-14 fw-700 table-link">
        {" "}
        <span className="cursor-pointer" onClick={() => onClickView(row)}>
          {row[key]}
        </span>
      </div>
    );
  };
}
export function companyNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.company?.companyName ? row?.company?.companyName : "-";
  };
}
export function projectNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.projectName !== "" ? row?.projectName : "-";
  };
}
export function properSizeFormatter() {
  return (cell, row, rowIndex) => {
    return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${
      row.carpetAreaMax ? row.carpetAreaMax : 0
    } sqft`;
  };
}
export function brandNameWithLogoFormatter() {
  return (cell, row, rowIndex) => {
    if (!row?.brandName) {
      return "-";
    }
    return (
        <div className="brand-with-logo">
          <div>{row?.brandName}</div>
          <div className="logo">
            <img src={row?.logo?.viewUrl} alt={`brandLogo ${rowIndex}`} />
          </div>
        </div>
    );
  };
}

import { BsSearch } from "react-icons/bs";
import { debounce } from '../../utilities/commonFunction';
import './style.scss'

function Search(props) {
 
  return (
    <div className='search-bar'>
      <BsSearch className='search-icon'/>
      <input  title={props.title} placeholder={props.placeholderText?props.placeholderText:'Search'} type="text" className={props.class?props.class:'search'} 
       onChange={debounce(props.onChange,600)}
      //  onKeyDown={handleKeyDown}
       defaultValue ={props?.value}
    
      />
          
    </div>
    
  )
}

export default Search
import { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import DragAndDrop from "../../../components/dragAndDrop";
import { postGetData } from "../../../api";
import { getCoworkUploadPropertyData } from "../coworkingSpaceOptions";
import { useLocation } from "react-router-dom";
import DropzoneCowork from "../../../components/dragAndDropCowork/dropZone";
import DragAndDropCowork from "../../../components/dragAndDropCowork";
const CoworkUploadProperty = ({
  gotoStep,
  propertyDetail,
  isExitData,
  step,
  propertyType,
  finishStep,
  propertyTypeId,
  uploadPropertyObject,
  setUploadPropertyObject,
  checkImage,
  uploadPropertyStepButtonRef,
  setChanged,
  coworkImagesDivRef
}) => {
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const [uploadPropertyData, setUploadPropertyData] = useState([]);
  
  const [propertyDetailImages, setPropertyDetailImages] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
 
  const handleSubmit = (event) => {
    event.preventDefault(); 
    if (Object.keys(checkImage()).length > 0) {
      setError(checkImage());
    }
  };

  const handleAllImages = (urls) => {
    const { name, data } = urls || '';
    setUploadPropertyObject((prev) => ({
      ...prev,
      [name]: data,
    }));
    setError((prev) => ({
      ...prev,
      [name]: "",
    }));
    setChanged(true)
  };
  const gotoBack = () => {
    gotoStep(step - 1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setUploadPropertyData(getCoworkUploadPropertyData);
    if (isExitData) {
      setPropertyDetailImages(propertyDetail);
    }
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  useEffect(() => {
    if(step && step !== 5){
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
   
  }, [uploadPropertyObject]);
  return (
    <div className="property-details-container pt-4" ref={coworkImagesDivRef} >
      <>
     
        <Row>
          <Col md="1" />
          <Col md="10">
            <h4>Property Images/Videos</h4>
            {uploadPropertyData &&
              uploadPropertyData.map((item) => (
                <div key={item.name} className="my-3">
                  <DragAndDropCowork
                    {...item}
                    allImages={propertyDetailImages}
                    handles={(urls) => handleAllImages(urls)}
                    error={error[item.name]}
                    propertyType = {propertyType}
                    propertyId={localStorage.getItem("addPropertyId") ? localStorage.getItem("addPropertyId") : searchParams.get("propertyId")}
                  />
                  <br />
                </div>
              ))}
            {/* {step !== 5 ? ( */}
              <Col md="12" hidden={true}>
                <div className="text-end">
                  <Button
                    type="button"
                    className="pre-btn me-3"
                    onClick={gotoBack}
                  >
                    Previous
                  </Button>
                  <Button
                    type="submit"
                    className="next-btn p-0"
                    loading={loading}
                    onClick={handleSubmit}
                    _ref={uploadPropertyStepButtonRef}
                  >
                    Next
                  </Button>
                </div>
              </Col>
            {/* ) : null} */}
          </Col>
        </Row>
      </>
    </div>
  );
};

export default CoworkUploadProperty;

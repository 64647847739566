import { useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";

import { Form } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ReactPaginate from "react-paginate";
import filterFactory from "react-bootstrap-table2-filter";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi";
import "./style.scss";

import { useContext } from "react";
import { AppContext } from "../../App";
import {
  getTableStoreValue,
  setTableFilterData,
} from "../../utilities/pageScroll";
import { TableRows } from "../../custom/constants";

export default function MainTable(props) {
  const [tableData, setTableData] = useState([]);
  const { tableStoreContext, setTableStoreContext } = useContext(AppContext);
  const currentTableValueFromConText = getTableStoreValue(
    props?.tableName,
    tableStoreContext
  );
  const tableName = currentTableValueFromConText?.tableName;

  const formatDatas = (initArr) => {
    let sn = 0;
    let filteredarray = [];
    initArr?.forEach((element) => {
      const offVal = (props.offset - 1) * props.perPage + (sn + 1);
      const offsetValue = props.offset ? offVal : sn + 1;

      filteredarray.push({
        ...element,
        sno: offsetValue,
        // action: element.id,
      });
      sn++;
    });
    return filteredarray;
  };

  const handelPerPage = (value) => {
    props?.handelChangePerPage(value);

    if (props?.tableName) {
      let valueObject = {
        pageNo: props.offset,
        perPage: value,
      };
      let newValue = setTableFilterData(
        props?.tableName,
        valueObject,
        tableStoreContext
      );
      setTableStoreContext(newValue);
    }
  };
  const handelOffSet = (value) => {
    props?.setOffset(value);
    if (props.tableName === "clientele") {
      props.handlePageChange(value);
    }
    if (props?.tableName) {
      let valueObject = {
        pageNo: value,
        perPage: props.perPage,
      };

      let newValue = setTableFilterData(
        props?.tableName,
        valueObject,
        tableStoreContext
      );
      setTableStoreContext(newValue);
    }
  };

  useEffect(() => {
    setTableData(formatDatas(props.data));
  }, [props?.data]);

  useEffect(
    () => {
      const timeoutId = setTimeout(() => {
        let newValue = setTableFilterData(
          props?.tableName,
          { id: "" },
          tableStoreContext
        );
        setTableStoreContext(newValue);
      }, 5000); // Delay in milliseconds

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [
      /* Dependencies */
    ]
  );

  const rowClasses = (row) => {
    let checkForTableName = tableName === props?.tableName;
    let checkForuniqueId = checkForTableName
      ? props?.uniqueId
        ? row?.uniqueId === currentTableValueFromConText?.id
        : row._id === currentTableValueFromConText?.id
      : false;
    return checkForuniqueId
      ? "active-row"
      : props?.tableName === "RmDashboard"
      ? ""
      : "";
  };

  return (
    <div className="table-border-radius">
      <BootstrapTable
        keyField={props?.uniqueId ? "uniqueId" : "_id"}
        bordered={false}
        data={tableData}
        noDataIndication={"No Data Found"}
        columns={props.columns}
        //   bootstrap4={true}
        headerWrapperClasses="thead-dark"
        bodyClasses="tableBody"
        wrapperClasses="table-responsive customScroll"
        // pagination={ paginationFactory(options) }
        filter={filterFactory()}
        rowClasses={rowClasses}
      />
      {props.pageCount > 0 && (
        <div className="p-1 px-2 pagination-content ">
          <div className="d-flex  align-items-center w-80   flex-shrink-1">
            <label className="page">Rows per page: &nbsp;</label>
            <div>
              <Form.Select
                aria-label="Default select example"
                defaultValue={props.perPage}
                onChange={(e) => handelPerPage(e.target.value)}
              >
                {TableRows.map((rows, index) => {
                  return (
                    <option key={index} value={rows}>{rows}</option>
                  )
                })}
              </Form.Select>
            </div>
          </div>
          <div className="pagenationwrapper d-flex  justify-content-end">
            <div className="mr-2 text-end  p-2  page pl-5   d-flex justify-content-end align-items-center page-count">
              {`${tableData[0]?.sno >= 0 ? tableData[0]?.sno : "0"}-${
                tableData[tableData.length - 1]?.sno >= 0
                  ? tableData[tableData.length - 1]?.sno
                  : "0"
              } of ${props.totalCount ? props.totalCount : ""}`}
            </div>
            <div className="d-flex align-items-start justify-content-end page-between flex-shrink-1 ">
              <div className="page__arrow" onClick={() => handelOffSet(1)}>
                <HiOutlineChevronDoubleLeft
                  size={15}
                  style={{ color: "black" }}
                />
              </div>
              <ReactPaginate
                breakLabel=""
                nextLabel={<IoChevronForwardOutline size={15} />}
                marginPagesDisplayed={0}
                pageRangeDisplayed={4}
                pageCount={props.pageCount}
                previousLabel={<IoChevronBackOutline size={15} />}
                pageClassName="page"
                pageLinkClassName="page"
                previousClassName="page"
                previousLinkClassName="page"
                nextClassName="page"
                nextLinkClassName="page"
                activeClassName={"pagination-active"}
                renderOnZeroPageCount={null}
                onPageChange={(pageNumber) =>
                  handelOffSet(pageNumber.selected + 1)
                }
                forcePage={props.offset - 1}
              />
              <div
                className="page__arrow"
                onClick={() => handelOffSet(props.pageCount)}
              >
                <HiOutlineChevronDoubleRight
                  size={15}
                  style={{ color: "black" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

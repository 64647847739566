const err = [
  {
    requried: "propertyTypeRequired",
    fieldValue: "propertyType",
  },
  {
    requried: "locationRequired",
    fieldValue: "location",
  },
  {
    requried: "microLocationRequired",
    fieldValue: "microLocation",
  },
  {
    requried: "otherInterestedLocationRequired",
    fieldValue: "otherInterestedLocation",
  },
  {
    requried: "carpetAreaRequired",
    fieldValueOne: "carpetAreaMin",
    fieldValueTwo: "carpetAreaMax",
  },
  {
    requried: "possessionFromRequired",
    fieldValue: "possessionFrom",
  },
  {
    requried: "endUseRequired",
    fieldValue: "endUse",
  },
  {
    requried: "carpetAreaRequired",
    fieldValueOne: "rentPsfMin",
    fieldValueTwo: "rentPsfMax",
  },
  {
    requried: "transactionTypeRequired",
    fieldValue: "transactionType",
  },
  {
    requried: "ocStatusRequired",
    fieldValue: "ocStatus",
  },
  {
    requried: "toiletRequired",
    fieldValue: "toiletStatus",
  },
  {
    requried: "parkingRequired",
    fieldValue: "parkingStatus",
  },
  {
    requried: "mezzainineRequired",
    fieldValue: "mezzainineStatus",
  },
  {
    requried: "fitOutsRequired",
    fieldValue: "fitOutsStatus",
  },
  {
    requried: "additionalDemandDetailsRequired",
    fieldValue: "additionalDemandDetails",
  },
  {
    requried: "floorRequired",
    fieldValue: "floor",
  },
  {
    requried: "ceilingHeightRequired",
    fieldValue: "ceilingHeight",
  },
  {
    requried: "frontageRequired",
    fieldValue: "frontage",
  },
  {
    requried: "accessRoadRequired",
    fieldValue: "accessRoad",
  },
  {
    requried: "buildingStructureRequired",
    fieldValue: "buildingStructure",
  },
  {
    requried: "ownerShipRequired",
    fieldValue: "ownerShip",
  },
  {
    requried: "propertyAgeRequired",
    fieldValue: "propertyAge",
  },
  {
    requried: "otherAmenitiesRequired",
    fieldValue: "otherAmenities",
  },
  {
    requried: "transactionTimelineRequired",
    fieldValue: "transactionTimeline",
  },
  {
    requried: "lockInPeriodRequired",
    fieldValue: "lockInPeriod",
  },
  {
    requried: "tenureRequired",
    fieldValue: "tenure",
  },
  {
    requried: "rentFreePeriodRequired",
    fieldValue: "rentFreePeriod",
  },
  // {
  //   requried: "otherLicensesRequired",
  //   fieldValue: "otherLicenses",
  // },
  {
    requried: "washRoomRequired",
    fieldValue: "washRoom",
  },
  {
    requried: "bikeParkingRequired",
    fieldValue: "bikeParking",
  },
  {
    requried: "carParkingRequired",
    fieldValue: "carParking",
  },
  {
    requried: "userId",
    fieldValue: "userId",
  },
];

const checkErrorforString = (value) => {
  if (value === "undefined") return false;
  if (value?.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const checkValidation = (stepData) => {
  let valid = {};
  err.map((item) => {
    if (
      (item.requried === "carpetAreaRequired" &&
        stepData?.hasOwnProperty("carpetAreaRequired") &&
        Boolean(stepData["carpetAreaRequired"])) ||
      (item.requried === "rentPsfRequired" &&
        stepData?.hasOwnProperty("rentPsfRequired") &&
        Boolean(stepData["rentPsfRequired"]))
    ) {
      if (stepData[item.fieldValueOne] > 0) {
        valid[item.fieldValueOne] = "";
      } else {
        valid[item.fieldValueOne] = "This Field Is Required";
      }

      if (stepData[item.fieldValueTwo] > 0) {
        if (
          Number(stepData[item.fieldValueOne]) >
          Number(stepData[item.fieldValueTwo])
        ) {
          valid[item.fieldValueTwo] = "Check Max value";
        } else {
          valid[item.fieldValueTwo] = "";
        }
      } else {
        valid[item.fieldValueTwo] = "This Field Is Required";
      }
      return item;
    } else if (
      (item.requried === "otherInterestedLocationRequired" &&
        stepData?.hasOwnProperty("otherInterestedLocationRequired") &&
        Boolean(stepData["otherInterestedLocationRequired"])) ||
      (item.requried === "buildingStructureRequired" &&
        stepData?.hasOwnProperty("buildingStructureRequired") &&
        Boolean(stepData["buildingStructureRequired"])) ||
      (item.requried === "otherAmenitiesRequired" &&
        stepData?.hasOwnProperty("otherAmenitiesRequired") &&
        Boolean(stepData["otherAmenitiesRequired"]))
    ) {
      if (stepData[item.fieldValue]?.length > 0) {
        valid[item.fieldValue] = "";
      } else {
        valid[item.fieldValue] = "This Field Is Required";
      }
      return item;
    }
    else if (
      (item.requried === "microLocationRequired" &&
        stepData?.hasOwnProperty("microLocationRequired") &&
        Boolean(stepData["microLocationRequired"]) &&
        !checkErrorforString(String(stepData[item.fieldValue]?.description)))

    ) {
      valid[item.fieldValue] = "This Field Is Required";
      return item;
    } else if (
      stepData?.hasOwnProperty(item.requried) &&
      Boolean(stepData[item.requried]) &&
      !checkErrorforString(String(stepData[item.fieldValue]))
    ) {
      valid[item.fieldValue] = "This Field Is Required";
      return item;
    }


    return item;
  });
  return valid;
};

export const checkValidationRetail = (stepData) => {
  let valid;
  let primaryDetailError;
  let additonalDetailError; 
  let amenitiesError

  ////////////////// fristStep start/////////////////////////
  // if (step === 1) {
  //   valid = {
  //     location: "",
  //     carpetAreaMax: "",
  //     carpetAreaMin: "",
  //     otherInterested: "",
  //     endUse: "",
  //     possessionFrom: "",
  //     floor: "",
  //     ocStatus: "",
  //     additionalDemandDetails: "",
  //     rentPsfMin: "",
  //     rentPsfMax: "",
  //     locality: "",
  //     propertyType: "",
  //     transactionType: "",
  //   };

  //   if (stepData.locationRequired) {
  //     valid.location = checkErrorforString(String(stepData.location))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   valid.userId =  checkErrorforString(String(stepData?.userId?._id))
  //   ? ""
  //   : "This Field Is Required";
  //   if(stepData?.transactionTypeRequired){
  //   valid.transactionType = checkErrorforString(String(stepData.transactionType))
  //     ? ""
  //     : "This Field Is Required";
  //   }

  //   if (stepData.microLocationRequired) {
  //     valid.microLocation = stepData.microLocation?.description
  //       ? ""
  //       : "This Field Is Required";
  //   }

  //   if (stepData.propertyTypeRequired) {
  //     valid.propertyType = checkErrorforString(String(stepData.propertyType))
  //       ? ""
  //       : "This Field Is Required";
  //   }

  //   if (stepData.carpetAreaRequired) {
  //     // if (stepData.carpetAreaMin > 0) {
  //     //   valid.carpetAreaMin = "";
  //     // } else {
  //     //   valid.carpetAreaMin = "This Field Is Required";
  //     // }
  //   }
  //   if (stepData.carpetAreaRequired) {
  //     if (stepData.carpetAreaMax > 0) {
  //       if (Number(stepData.carpetAreaMin) > Number(stepData.carpetAreaMax)) {
  //         valid.carpetAreaMax = "Check Max value";
  //       } else {
  //         valid.carpetAreaMax = "";
  //       }
  //     } else {
  //       valid.carpetAreaMax = "This Field Is Required";
  //     }
  //   }
  //   if (stepData.otherInterestedLocationRequired) {
  //     if (stepData.otherInterestedLocation.length > 0) {
  //       valid.otherInterestedLocation = "";
  //     } else {
  //       valid.otherInterestedLocation = "This Field Is Required";
  //     }
  //   }
  //   if (stepData.endUseRequired) {
  //     valid.endUse = checkErrorforString(String(stepData.endUse))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.possessionFromRequired) {
  //     valid.possessionFrom = checkErrorforString(
  //       String(stepData.possessionFrom)
  //     )
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.floorRequired) {
  //     valid.floor = checkErrorforString(String(stepData.floor))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.ocStatusRequired) {
  //     valid.ocStatus = checkErrorforString(stepData.ocStatus)
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.additionalDemandDetailsRequired) {
  //     valid.additionalDemandDetails = checkErrorforString(
  //       stepData.additionalDemandDetails
  //     )
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.rentPsfRequired) {
  //     // if (stepData.rentPsfMin > 0) {
  //     //   valid.rentPsfMin = "";
  //     // } else {
  //     //   valid.rentPsfMin = "This Field Is Required";
  //     // }
  //     if (stepData.rentPsfMax > 0) {
  //       if (Number(stepData.rentPsfMin) > Number(stepData.rentPsfMax)) {
  //         valid.rentPsfMax = "Check Max value";
  //       } else {
  //         valid.rentPsfMax = "";
  //       }
  //     } else {
  //       valid.rentPsfMax = "This Field Is Required";
  //     }
  //   } else {
  //     if (Number(stepData.rentPsfMin) > Number(stepData.rentPsfMax)) {
  //       valid.rentPsfMax = "Check Max value";
  //     }
  //   }
  //   return valid;
  // }

  ////////////////// fristStep end/////////////////////////

  // if (step === 2) {
  //   valid = {
  //     frontage: "",
  //     accessRoad: "",
  //     propertyAge: "",
  //     buildingStructure: "",
  //     ceilingHeight: "",
  //     ownerShip: "",
  //   };

  //   if (stepData.frontageRequired) {
  //     valid.frontage = checkErrorforString(String(stepData.frontage))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.accessRoadRequired) {
  //     valid.accessRoad = checkErrorforString(String(stepData.accessRoad))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.propertyAgeRequired) {
  //     valid.propertyAge = checkErrorforString(String(stepData.propertyAge))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.buildingStructureRequired) {
  //     if (stepData.buildingStructure.length > 0) {
  //       valid.buildingStructure = "";
  //     } else {
  //       valid.buildingStructure = "This Field Is Required";
  //     }
  //   }

  //   if (stepData.ceilingHeightRequired) {
  //     valid.ceilingHeight = checkErrorforString(String(stepData.ceilingHeight))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.ownerShipRequired) {
  //     valid.ownerShip = checkErrorforString(String(stepData.ownerShip))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   return valid;
  // }

  // if (step === 3) {
  //   valid = {
  //     otherAmenities: "",
  //     tenure: "",
  //     transactionTimeline: "",
  //     lockInPeriod: "",
  //     rentFreePeriod: "",
  //   };

  //   if (stepData.rentFreePeriodRequired) {
  //     valid.rentFreePeriod = checkErrorforString(
  //       String(stepData.rentFreePeriod)
  //     )
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.lockInPeriodRequired) {
  //     valid.lockInPeriod = checkErrorforString(String(stepData.lockInPeriod))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.tenureRequired) {
  //     valid.tenure = checkErrorforString(String(stepData.tenure))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.transactionTimelineRequired) {
  //     valid.transactionTimeline = checkErrorforString(
  //       String(stepData.transactionTimeline)
  //     )
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.otherAmenitiesRequired) {
  //     if (stepData.otherAmenities.length > 0) {
  //       valid.otherAmenities = "";
  //     } else {
  //       valid.otherAmenities = "This Field Is Required";
  //     }
  //   }

  //   return valid;
  // }
  // if (step === 4) {
  //   valid={
  //     otherLicenses: "",
  //   }
  //   if (stepData.otherLicensesRequired) {
  //     valid.otherLicenses = checkErrorforString(
  //       String(stepData.otherLicenses)
  //     )
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   return valid;
  // }
  // if (step === 5) {
  valid = {
    userId: "",
    location: "",
    propertyType: "",
    microLocation: "",
    carpetAreaMax: "",
    carpetAreaMin: "",
    otherInterestedLocation: "",
    endUse: "",
    possessionFrom: "",
    floor: "",
    ocStatus: "",
    additionalDemandDetails: "",
    rentPsfMin: "",
    rentPsfMax: "",
    transactionType: "",
    locality: "",
    frontage: "",
    accessRoad: "",
    propertyAge: "",
    buildingStructure: "",
    ceilingHeight: "",
    ownerShip: "",
    otherAmenities: "",
    tenure: "",
    transactionTimeline: "",
    lockInPeriod: "",
    rentFreePeriod: "",
    otherLicenses: "",
    pincode: "",
    fitOutsStatus: "",
    toiletStatus: "",
    parkingStatus: "",
    mezzainineStatus: "",
    requiredDocuments: "",
    fitOuts: ""
  };

  if (stepData.pincodeRequired) {
    valid.pincode = checkErrorforString(stepData.pincode)
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.pincode !== "" ? true : primaryDetailError
     
  }

  if (stepData.locationRequired) {
    valid.location = checkErrorforString(stepData.location)
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.location !== "" ? true : primaryDetailError
  }
  valid.userId = checkErrorforString(String(stepData?.userId?._id))
    ? ""
    : "This Field Is Required";

  if (stepData?.transactionTypeRequired) {
    valid.transactionType = checkErrorforString(String(stepData.transactionType))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.transactionType !== "" ? true : primaryDetailError
  }

  if (stepData.microLocationRequired) {
    valid.microLocation = stepData.microLocation?.description
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.microLocation !== "" ? true : primaryDetailError
  }

  if (stepData.propertyTypeRequired) {
    valid.propertyType = checkErrorforString(String(stepData.propertyType))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.propertyType !== "" ? true : primaryDetailError
  }
  if (stepData.carpetAreaRequired) {
    if (stepData.carpetAreaMin >= 0) {
      valid.carpetAreaMin = "";
    } else {
      valid.carpetAreaMin = "This Field Is Required";
      primaryDetailError = true
    }
  }
  if (stepData.carpetAreaRequired) {
    if (stepData.carpetAreaMax > 0) {
      if (Number(stepData.carpetAreaMin) > Number(stepData.carpetAreaMax)) {
        valid.carpetAreaMax = "Check Max value";
        primaryDetailError = true
      } else {
        valid.carpetAreaMax = "";
      }
    } else {
      valid.carpetAreaMax = "This Field Is Required";
      primaryDetailError = true
    }
  }

  if (stepData.otherInterestedLocationRequired) {
    if (stepData.otherInterestedLocation.length > 0) {
      valid.otherInterestedLocation = "";
    } else {
      valid.otherInterestedLocation = "This Field Is Required";
      primaryDetailError = true
    }
  }
  // if (stepData.transactionType !== "Buy") {
  if (stepData.endUseRequired || stepData.endUseRequired === null) {
    valid.endUse = checkErrorforString(String(stepData.endUse ?? ""))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.endUse !== "" ? true : primaryDetailError
  }
  // }
  if (stepData.possessionFromRequired) {
    valid.possessionFrom = checkErrorforString(
      String(stepData.possessionFrom)
    )
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.possessionFrom !== "" ? true : primaryDetailError
  }
  if (stepData.floorRequired) {
    valid.floor = checkErrorforString(String(stepData.floor))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.floorRequired !== "" ? true : primaryDetailError
  }
  if (stepData.ocStatusRequired) {
    valid.ocStatus = checkErrorforString(stepData.ocStatus)
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.ocStatus !== "" ? true : primaryDetailError
  }
  if (stepData.transactionType !== "Buy") {
    if (stepData.fitOutsRequired) {
      valid.fitOutsStatus = checkErrorforString(stepData.fitOutsStatus ?? "")
        ? ""
        : "This Field Is Required";
        amenitiesError =  valid.fitOutsStatus !== "" ? true : amenitiesError
    }
  }
  if (stepData.toiletRequired) {
    valid.toiletStatus = checkErrorforString(stepData.toiletStatus)
      ? ""
      : "This Field Is Required";
      amenitiesError =  valid.toiletStatus !== "" ? true : amenitiesError
  }
  if (stepData.parkingRequired) {
    valid.parkingStatus = checkErrorforString(stepData.parkingStatus)
      ? ""
      : "This Field Is Required";
      amenitiesError =  valid.parkingStatus !== "" ? true : amenitiesError
  }
  if (stepData.mezzainineRequired) {
    valid.mezzainineStatus = checkErrorforString(stepData.mezzainineStatus)
      ? ""
      : "This Field Is Required";
      amenitiesError =  valid.mezzainineStatus !== "" ? true : amenitiesError
  }
  if (stepData.additionalDemandDetailsRequired) {
    valid.additionalDemandDetails = checkErrorforString(
      stepData.additionalDemandDetails
    )
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.mezzainineStatus !== "" ? true : primaryDetailError
  }
  if (stepData.rentPsfRequired) {
    // if (stepData.rentPsfMin >= 0) {
    //   valid.rentPsfMin = "";
    // } else {
    //   valid.rentPsfMin = "This Field Is Required";
    // }
    if (stepData.rentPsfMax >= 0) {
      if (Number(stepData.rentPsfMin) > Number(stepData.rentPsfMax)) {
        valid.rentPsfMax = "Check Max value";
        primaryDetailError = true
      } else {
        valid.rentPsfMax = "";
      }
    } else {
      valid.rentPsfMax = "This Field Is Required";
      primaryDetailError = true
    }
  } else {
    if (Number(stepData.rentPsfMin) > Number(stepData.rentPsfMax)) {
      valid.rentPsfMax = "Check Max value";
      primaryDetailError = true
    }
  }

  if (stepData.frontageRequired) {
    valid.frontage = checkErrorforString(String(stepData.frontage))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.frontage !== "" ? true : additonalDetailError
  }
  if (stepData.accessRoadRequired) {
    valid.accessRoad = checkErrorforString(String(stepData.accessRoad))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.accessRoad !== "" ? true : additonalDetailError
  }
  if (stepData.propertyAgeRequired) {
    valid.propertyAge = checkErrorforString(String(stepData.propertyAge))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.propertyAge !== "" ? true : additonalDetailError
  }

  if (stepData.buildingStructureRequired) {
    if (stepData.buildingStructure.length > 0) {
      valid.buildingStructure = "";
    } else {
      valid.buildingStructure = "This Field Is Required";
      additonalDetailError =  true
    }
  }

  if (stepData.ceilingHeightRequired) {
    valid.ceilingHeight = checkErrorforString(String(stepData.ceilingHeight))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.ceilingHeight !== "" ? true : additonalDetailError
  }
  if (stepData.transactionType !== "Buy") {
    if (stepData.ownerShipRequired) {
      valid.ownerShip = checkErrorforString(String(stepData.ownerShip ?? '')) ? "" : "This Field Is Required";
      additonalDetailError =  valid.ownerShip !== "" ? true : additonalDetailError
    }
  }

  if (stepData.otherAmenitiesRequired) {
    if (stepData.otherAmenities.length > 0) {
      valid.otherAmenities = "";
    } else {
      valid.otherAmenities = "This Field Is Required";
      amenitiesError = true
    }
  }

  if (stepData.transactionType !== "Buy") {
    if (stepData.tenureRequired) {
      valid.tenure = checkErrorforString(String(stepData.tenure ?? "")) ? "" : "This Field Is Required";
    }
  }

  if (stepData.transactionTimelineRequired) {
    valid.transactionTimeline = checkErrorforString(
      String(stepData.transactionTimeline)
    )
      ? ""
      : "This Field Is Required";
  }
  // if (stepData.otherLicensesRequired) {
  //   valid.otherLicenses = checkErrorforString(stepData.otherLicenses)
  //     ? ""
  //     : "This Field Is Required";
  // }
  if (stepData.pincodeRequired) {
    valid.pincode = checkErrorforString(String(stepData.pincode)) ? "" : "This Field Is Required"
    if (!checkErrorforString(stepData.pincode)) {
      valid.pincode = "This Field Is Required";
      primaryDetailError = true
    } else if (stepData.pincode.length !== 6) {
      valid.pincode = "Enter Valid Pin Code";
      primaryDetailError = true
    }

  } else {
    if (checkErrorforString(stepData.pincode)) {
      if (stepData.pincode?.length !== 6) {
        valid.pincode = "Enter Valid Pin Code";
        primaryDetailError = true
      }
    }
  }
  if (stepData.tenure == 0 && stepData.lockInPeriod) {
    valid.tenure = "Enter valid Lease Tenure"
  }
  if (stepData.tenure && stepData.lockInPeriod) {
    if (Number(stepData.tenure) < Number(stepData.lockInPeriod)) {
      valid.lockInPeriod = "Shouldn`t be more than lease tenure"
    }
  }
  if (stepData.documentsRequired) {
    if (stepData.requiredDocuments.length > 0) {
      valid.requiredDocuments = ""
    }
    else {
      valid.requiredDocuments = "This Field Is Required"
    }
  }

  return {valid, primaryDetailError, additonalDetailError, amenitiesError};
  // }
};
export const checkValidationOfficeSpace = (stepData) => {
  let valid;
  let primaryDetailError;
  let additonalDetailError;
  ////////////////// fristStep start/////////////////////////
  // if (step === 1) {
  //   valid = {
  //     userId:"",
  //     location: "",
  //     microLocation: "",
  //     carpetAreaMax: "",
  //     carpetAreaMin: "",
  //     otherInterested: "",
  //     endUse: "",
  //     possessionFrom: "",
  //     additionalDemandDetails: "",
  //     rentPsfMin: "",
  //     rentPsfMax: "",
  //     locality: "",
  //     propertyType: "",
  //     transactionType: "",
  //   };

  //   if (stepData.locationRequired) {
  //     valid.location = checkErrorforString(stepData.location)
  //       ? ""
  //       : "This Field Is Required";
  //   }


  //     valid.userId = checkErrorforString(stepData?.userId?._id)
  //       ? ""
  //       : "This Field Is Required";

  //   if (stepData.microLocationRequired) {
  //     valid.microLocation = stepData.microLocation?.description
  //       ? ""
  //       : "This Field Is Required";
  //   }

  //   if (stepData.propertyTypeRequired) {
  //     valid.propertyType = checkErrorforString(String(stepData.propertyType))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.transactionTypeRequired) {
  //     valid.transactionType = checkErrorforString(
  //       String(stepData.transactionType)
  //     )
  //       ? ""
  //       : "This Field Is Required";
  //   }

  //   if (stepData.carpetAreaRequired) {
  //     // if (stepData.carpetAreaMin > 0) {
  //     //   valid.carpetAreaMin = "";
  //     // } else {
  //     //   valid.carpetAreaMin = "This Field Is Required";
  //     // }
  //   }
  //   if (stepData.carpetAreaRequired) {
  //     if (stepData.carpetAreaMax > 0) {
  //       if (Number(stepData.carpetAreaMin) > Number(stepData.carpetAreaMax)) {
  //         valid.carpetAreaMax = "Check Max value";
  //       } else {
  //         valid.carpetAreaMax = "";
  //       }
  //     } else {
  //       valid.carpetAreaMax = "This Field Is Required";
  //     }
  //   }
  //   if (stepData.otherInterestedLocationRequired) {
  //     if (stepData.otherInterestedLocation.length > 0) {
  //       valid.otherInterestedLocation = "";
  //     } else {
  //       valid.otherInterestedLocation = "This Field Is Required";
  //     }
  //   }
  //   if (stepData.endUseRequired) {
  //     valid.endUse = checkErrorforString(String(stepData.endUse))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.possessionFromRequired) {
  //     valid.possessionFrom = checkErrorforString(
  //       String(stepData.possessionFrom)
  //     )
  //       ? ""
  //       : "This Field Is Required";
  //   }

  //   if (stepData.additionalDemandDetailsRequired) {
  //     valid.additionalDemandDetails = checkErrorforString(
  //       stepData.additionalDemandDetails
  //     )
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.rentPsfRequired) {
  //     // if (stepData.rentPsfMin > 0) {
  //     //   valid.rentPsfMin = "";
  //     // } else {
  //     //   valid.rentPsfMin = "This Field Is Required";
  //     // }
  //     if (stepData.rentPsfMax > 0) {
  //       if (Number(stepData.rentPsfMin) > Number(stepData.rentPsfMax)) {
  //         valid.rentPsfMax = "Check Max value";
  //       } else {
  //         valid.rentPsfMax = "";
  //       }
  //     } else {
  //       valid.rentPsfMax = "This Field Is Required";
  //     }
  //   } else {
  //     if (Number(stepData.rentPsfMin) > Number(stepData.rentPsfMax)) {
  //       valid.rentPsfMax = "Check Max value";
  //     }
  //   }
  //   if (stepData.lockInPeriodRequired) {
  //     valid.lockInPeriod = checkErrorforString(String(stepData.lockInPeriod))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   return valid;
  // }

  ////////////////// fristStep end/////////////////////////

  // if (step === 2) {
  //   valid = {
  //     propertyAge: "",
  //     floor: "",
  //     rentFreePeriod: "",
  //     otherAmenities: "",
  //     bikeParking: "",
  //     carParking: "",
  //     washRoom: "",
  //     fitOuts:""
  //   };

  //   if (stepData.propertyAgeRequired) {
  //     valid.propertyAge = checkErrorforString(String(stepData.propertyAge))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.floorRequired) {
  //     valid.floor = checkErrorforString(String(stepData.floor))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.rentFreePeriodRequired) {
  //     valid.rentFreePeriod = checkErrorforString(
  //       String(stepData.rentFreePeriod)
  //     )
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.otherAmenitiesRequired) {
  //     if (stepData.otherAmenities.length > 0) {
  //       valid.otherAmenities = "";
  //     } else {
  //       valid.otherAmenities = "This Field Is Required";
  //     }
  //   }

  //   if (stepData.carParkingRequired) {
  //     valid.carParking = checkErrorforString(String(stepData.carParking))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.washRoomRequired) {
  //     valid.washRoom = checkErrorforString(String(stepData.washRoom))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.bikeParkingRequired) {
  //     valid.bikeParking = checkErrorforString(String(stepData.bikeParking))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  //   if (stepData.fitOutsRequired) {
  //     valid.fitOuts = checkErrorforString(String(stepData.fitOuts))
  //       ? ""
  //       : "This Field Is Required";
  //   }

  //   return valid;
  // }

  // if (step === 3) {
  valid = {
    userId: "",
    location: "",
    microLocation: "",
    carpetAreaMax: "",
    carpetAreaMin: "",
    otherInterested: "",
    endUse: "",
    possessionFrom: "",
    additionalDemandDetails: "",
    transactionType: "",
    rentPsfMin: "",
    rentPsfMax: "",
    locality: "",
    otherAmenities: "",
    propertyType: "",
    propertyAge: "",
    floor: "",
    rentFreePeriod: "",
    bikeParking: "",
    carParking: "",
    washRoom: "",
    pincode: "",
    requiredDocuments: ""
  };
  valid.userId = checkErrorforString(stepData?.userId?._id)
    ? ""
    : "This Field Is Required";
  primaryDetailError =  valid.userId !== "" ? true : primaryDetailError
  if (stepData.locationRequired) {
    valid.location = checkErrorforString(stepData.location)
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.location !== "" ? true : primaryDetailError
  }
  if (stepData.pincodeRequired) {
    valid.pincode = checkErrorforString(stepData.pincode)
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.pincode !== "" ? true : primaryDetailError
  }

  if (stepData.microLocationRequired) {
    valid.microLocation = stepData.microLocation?.description
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.microLocation !== "" ? true : primaryDetailError
  }

  if (stepData.propertyTypeRequired) {
    valid.propertyType = checkErrorforString(String(stepData.propertyType))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.propertyType !== "" ? true : primaryDetailError
  }
  if (stepData.transactionTypeRequired) {
    valid.transactionType = checkErrorforString(
      String(stepData.transactionType)
    )
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.transactionType !== "" ? true : primaryDetailError
  }

  if (stepData.carpetAreaRequired) {
    if (stepData.carpetAreaMin > 0) {
      valid.carpetAreaMin = "";
    } else {
      valid.carpetAreaMin = "This Field Is Required";
      primaryDetailError =  true
    }
  }
  if (stepData.carpetAreaRequired) {
    if (stepData.carpetAreaMax > 0) {
      if (Number(stepData.carpetAreaMin) > Number(stepData.carpetAreaMax)) {
        valid.carpetAreaMax = "Check Max value";
        primaryDetailError =  true
      } else {
        valid.carpetAreaMax = "";
      }
    } else {
      valid.carpetAreaMax = "This Field Is Required";
      primaryDetailError =  true
    }
  }
  if (stepData.otherInterestedLocationRequired) {
    if (stepData.otherInterestedLocation.length > 0) {
      valid.otherInterestedLocation = "";
    } else {
      valid.otherInterestedLocation = "This Field Is Required";
      primaryDetailError =  true
    }
  }

  // if (stepData.transactionType !== "Buy") {
  if (stepData.endUseRequired || stepData.endUseRequired === null) {
    valid.endUse = checkErrorforString(String(stepData.endUse ?? ""))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.endUse !== "" ? true : primaryDetailError
  }
  // }

  if (stepData.possessionFromRequired) {
    valid.possessionFrom = checkErrorforString(
      String(stepData.possessionFrom)
    )
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.possessionFrom !== "" ? true : primaryDetailError
  }

  if (stepData.additionalDemandDetailsRequired) {
    valid.additionalDemandDetails = checkErrorforString(
      stepData.additionalDemandDetails
    )
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.additionalDemandDetails !== "" ? true : primaryDetailError
  }
  if (stepData.rentPsfRequired) {
    // if (stepData.rentPsfMin > 0) {
    //   valid.rentPsfMin = "";
    // } else {
    //   valid.rentPsfMin = "This Field Is Required";
    // }
    if (stepData.rentPsfMax > 0) {
      if (Number(stepData.rentPsfMin) > Number(stepData.rentPsfMax)) {
        valid.rentPsfMax = "Check Max value";
        primaryDetailError = true
      } else {
        valid.rentPsfMax = "";
      }
    } else {
      valid.rentPsfMax = "This Field Is Required";
      primaryDetailError =  true
    }
  } else {
    if (Number(stepData.rentPsfMin) > Number(stepData.rentPsfMax)) {
      valid.rentPsfMax = "Check Max value";
      primaryDetailError =  true
    }
  }
  // if (stepData.transactionType !== "Buy") {
  //   if (stepData.lockInPeriodRequired) {
  //     valid.lockInPeriod = checkErrorforString(String(stepData.lockInPeriod))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  // }
  if (stepData.propertyAgeRequired) {
    valid.propertyAge = checkErrorforString(String(stepData.propertyAge))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.propertyAge !== "" ? true : additonalDetailError
  }
  if (stepData.floorRequired) {
    valid.floor = checkErrorforString(String(stepData.floor))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.floor !== "" ? true : additonalDetailError
  }
  if (stepData.rentFreePeriodRequired) {
    valid.rentFreePeriod = checkErrorforString(
      String(stepData.rentFreePeriod)
    )
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.rentFreePeriod !== "" ? true : additonalDetailError
  }
  if (stepData.carParkingRequired) {
    valid.carParking = checkErrorforString(String(stepData.carParking))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.carParking !== "" ? true : additonalDetailError
  }
  if (stepData.documentsRequired) {
    if (stepData.requiredDocuments.length > 0) {
      valid.requiredDocuments = ""
    }
    else {
      valid.requiredDocuments = "This Field Is Required"
    }
  }
  if (stepData.washRoomRequired) {
    valid.washRoom = checkErrorforString(String(stepData.washRoom))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.washRoom !== "" ? true : additonalDetailError
  }
  if (stepData.otherAmenitiesRequired) {
    if (stepData.otherAmenities.length > 0) {
      valid.otherAmenities = "";
    } else {
      valid.otherAmenities = "This Field Is Required";
      additonalDetailError =  true
    }
  }

  if (stepData.bikeParkingRequired) {
    valid.bikeParking = checkErrorforString(String(stepData.bikeParking))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.bikeParking !== "" ? true : additonalDetailError
  }
  if (stepData.pincodeRequired) {
    valid.pincode = checkErrorforString(String(stepData.pincode)) ? "" : "This Field Is Required"
    if (!checkErrorforString(stepData.pincode)) {
      valid.pincode = "This Field Is Required";
      primaryDetailError = true
    } else if (stepData.pincode?.length !== 6) {
      valid.pincode = "Enter Valid Pin Code";
      primaryDetailError = true
    }

  } else {
    if (checkErrorforString(stepData.pincode)) {
      if (stepData.pincode?.length !== 6) {
        valid.pincode = "Enter Valid Pin Code";
        primaryDetailError = true
      }
    }
  }
  if (stepData.transactionType !== "Buy") {
    if (stepData.tenureRequired) {
      valid.tenure = checkErrorforString(String(stepData.tenure ?? "")) ? "" : "This Field Is Required";
      primaryDetailError =  valid.tenure !== "" ? true : primaryDetailError
    }
  }
  // if (stepData.transactionType !== "Buy") {
  //   if (stepData.lockInPeriodRequired) {
  //     valid.lockInPeriod = checkErrorforString(String(stepData.lockInPeriod))
  //       ? ""
  //       : "This Field Is Required";
  //   }
  // }
  if (stepData.tenure == 0 && stepData.lockInPeriod) {
    valid.tenure = "Enter valid Lease Tenure"
  }
  if (stepData.fitOutsRequired) {
    valid.fitOuts = checkErrorforString(String(stepData.fitOuts))
      ? ""
      : "This Field Is Required";
      additonalDetailError =  valid.fitOuts !== "" ? true : additonalDetailError
  }
  if (stepData.tenure && stepData.lockInPeriod) {
    if (Number(stepData.tenure) < Number(stepData.lockInPeriod)) {
      valid.lockInPeriod = "Shouldn`t be more than lease tenure"
    }
  }


  return {valid, primaryDetailError, additonalDetailError};
  // }
};


export const checkValidationCoworkSpace = (stepData) => {
  let valid;
  let primaryDetailError = false;
  let formatDataError = false;
  valid = {
    propertyType: "",
    location: "",
    microLocation: "",
    budgetMin: "",
    budgetMax: "",
    otherInterested: "",
    additionalDemandDetails: "",
    transactionType: "",
    leasePeriodMin: "",
    leasePeriodMax: "",
    locality: "",
    otherAmenities: "",
    propertyAge: "",
    suppliedBy:"",
    pincode: "",
    bikeParking: "",
    carParking: "",
    dedicatedDesk:"",
    flexiDesk:'',
    meetingRoom:"",
    managedOffices:"",
    cabins:"",

  };
  if (stepData.pincodeRequired) {
    if (!checkErrorforString(stepData.pincode)) {
      valid.pincode = "This Field Is Required";
      primaryDetailError = true
    } else if (stepData.pincode?.length !== 6) {
      valid.pincode = "Enter Valid Pin Code";
      primaryDetailError = true
    }

  } else {
    if (checkErrorforString(stepData.pincode)) {
      if (stepData.pincode?.length !== 6) {
        valid.pincode = "Enter Valid Pin Code";
        primaryDetailError = true
      }
    }
  }

  if (stepData.locationRequired) {
    valid.location = checkErrorforString(stepData.location)
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.location !== "" ? true : primaryDetailError
  }
  if (stepData.microLocationRequired) {
    valid.microLocation = stepData.microLocation?.description
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.microLocation !== "" ? true : primaryDetailError
  }

  if (stepData.propertyTypeRequired) {
    valid.propertyType = checkErrorforString(String(stepData.propertyType))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.propertyType !== "" ? true : primaryDetailError
  }
  if (stepData.transactionTypeRequired) {
    valid.transactionType = checkErrorforString(String(stepData.transactionType))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.transactionType !== "" ? true : primaryDetailError
  }

  // if (stepData.budgetRequired) {
  //   if (stepData.budgetMin > 0) {
  //     valid.budgetMin = "";
  //   } else {
  //     valid.budgetMin = "This Field Is Required";
  //   }
  // }
  // if (stepData.budgetRequired) {
  //   if (stepData.budgetMax > 0) {
  //     if (Number(stepData.budgetMin) > Number(stepData.budgetMax)) {
  //       valid.budgetMax = "Check Max value";
  //     } else {
  //       valid.budgetMax = "";
  //     }
  //   } else {
  //     valid.budgetMax = "This Field Is Required";
  //   }
  // }
  if (stepData.otherInterestedLocationRequired) {
    if (stepData.otherInterestedLocation.length > 0) {
      valid.otherInterestedLocation = "";
    } else {
      valid.otherInterestedLocation = "This Field Is Required";
      primaryDetailError = true
    }
  }

  if (stepData.additionalDemandDetailsRequired) {
    valid.additionalDemandDetails = checkErrorforString(
      stepData.additionalDemandDetails
    )
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.additionalDemandDetails !== "" ? true : primaryDetailError
  }

  // if (stepData.leasePeriodRequired) {
  //   if (stepData.leasePeriodMin > 0) {
  //     valid.leasePeriodMin = "";
  //   } else {
  //     valid.leasePeriodMin = "This Field Is Required";
  //   }
  // }
  // if (stepData.leasePeriodRequired) {
  //   if (stepData.leasePeriodMax > 0) {
  //     if (Number(stepData.leasePeriodMin) > Number(stepData.leasePeriodMax)) {
  //       valid.leasePeriodMax = "Check Max value";
  //     } else {
  //       valid.leasePeriodMax = "";
  //     }
  //   } else {
  //     valid.leasePeriodMax = "This Field Is Required";
  //   }
  // }

  if (stepData.propertyAgeRequired) {
    valid.propertyAge = checkErrorforString(String(stepData.propertyAge))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.propertyAge !== "" ? true : primaryDetailError
  }
  if (stepData.carParkingRequired) {
    valid.carParking = checkErrorforString(String(stepData.carParking))
      ? ""
      : "This Field Is Required";
  }
  if (stepData.otherAmenitiesRequired) {
    if (stepData.otherAmenities.length > 0) {
      valid.otherAmenities = ""
    }
    else {
      valid.otherAmenities = "This Field Is Required"
    }
  }

  if (stepData.bikeParkingRequired) {
    valid.bikeParking = checkErrorforString(String(stepData.bikeParking))
      ? ""
      : "This Field Is Required";
  }

  // if (stepData.suppliedByRequired) {
  //   valid.suppliedBy = checkErrorforString(String(stepData.suppliedBy))
  //     ? ""
  //     : "This Field Is Required";
  // }

  // if (stepData.googleLocationRequired) {
  //   valid.googleLocation = checkErrorforString(String(stepData.googleLocation))
  //     ? ""
  //     : "This Field Is Required";
  // }

  if (stepData.possessionFromRequired) {
    valid.possessionFrom = checkErrorforString(String(stepData.possessionFrom))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.possessionFrom !== "" ? true : primaryDetailError
  }

  if (stepData.otherRequirementsRequired) {
    valid.otherRequirements = checkErrorforString(String(stepData.otherRequirements))
      ? ""
      : "This Field Is Required";
      primaryDetailError =  valid.otherRequirements !== "" ? true : primaryDetailError
  }

  if (stepData.dedicatedDeskRequired) {
    valid.dedicatedDesk = checkErrorforString(String(stepData.dedicatedDesk))
      ? ""
      : "This Field Is Required";
      formatDataError =  valid.dedicatedDesk !== "" ? true : formatDataError
  }

  if (stepData.dedicatedDeskMin > 0 && stepData.dedicatedDeskMax > 0) {
    if (Number(stepData.dedicatedDeskMin) > Number(stepData.dedicatedDeskMax)) {
      valid.dedicatedDeskMax = "Check Max value";
      formatDataError = true
    } else {
      valid.dedicatedDeskMax = "";
    }
  }

  if (stepData.FlexiDeskMin > 0 && stepData.FlexiDeskMax > 0) {
    if (Number(stepData.FlexiDeskMin) > Number(stepData.FlexiDeskMax)) {
      valid.FlexiDeskMax = "Check Max value";
      formatDataError = true
    } else {
      valid.FlexiDeskMax = "";
    }
  }

  if (stepData.meetingRoomMin > 0 && stepData.meetingRoomMax > 0) {
    if (Number(stepData.meetingRoomMin) > Number(stepData.meetingRoomMax)) {
      valid.meetingRoomMax = "Check Max value";
      formatDataError = true
    } else {
      valid.meetingRoomMax = "";
    }
  }

  if (stepData.managedOfficesMin > 0 && stepData.managedOfficesMax > 0) {
    if (Number(stepData.managedOfficesMin) > Number(stepData.managedOfficesMax)) {
      valid.managedOfficesMax = "Check Max value";
      formatDataError = true
    } else {
      valid.managedOfficesMax = "";
    }
  }

  if (stepData.cabinsMin > 0 && stepData.cabinsMax > 0) {
    if (Number(stepData.cabinsMin) > Number(stepData.cabinsMax)) {
      valid.cabinsMax = "Check Max value";
      formatDataError = true
    } else {
      valid.cabinsMax = "";
    }
  }
  

  if (stepData.flexiDeskRequired) {
    valid.flexiDesk = checkErrorforString(String(stepData.flexiDesk))
      ? ""
      : "This Field Is Required";
      formatDataError =  valid.flexiDesk !== "" ? true : formatDataError
  }

  if (stepData.meetingRoomRequired) {
    valid.meetingRoom = checkErrorforString(String(stepData.meetingRoom))
      ? ""
      : "This Field Is Required";
      formatDataError =  valid.meetingRoom !== "" ? true : formatDataError
  }

  if (stepData.managedOfficesRequired) {
    valid.managedOffices = checkErrorforString(String(stepData.managedOffices))
      ? ""
      : "This Field Is Required";
      formatDataError =  valid.managedOffices !== "" ? true : formatDataError
  }

  if (stepData.cabinsRequired) {
    valid.cabins = checkErrorforString(String(stepData.cabins))
      ? ""
      : "This Field Is Required";
      formatDataError =  valid.cabins !== "" ? true : formatDataError
  }

  if (!stepData.cabinsRequired && !stepData.managedOfficesRequired && !stepData.meetingRoomRequired && !stepData.flexiDeskRequired && !stepData.dedicatedDeskRequired) {
    valid.formatData  = "Please select at least one of the format field !";
    formatDataError =  valid.formatData !== "" ? true : formatDataError
  }

  return {valid, formatDataError, primaryDetailError};
  // }
};

// return Object.values(valid).every((v) => v.length ===0);

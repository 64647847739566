import { configureStore } from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import loginDataReducer  from "./pages/Login/reducer";

const reducers = combineReducers({
  login:loginDataReducer         
 });

 const persistConfig = {
  key: 'root',
  storage:storageSession,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});



// export const store = configureStore({
//     reducer: {
//    login:loginDataReducer
//     },
    
//   });


import { useMemo, useState, useCallback } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import { postUploadData } from "../../api";
import NoImage from "../../assets/images/noImage.jpg";
import swal from "sweetalert";

import "./style.scss";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgba(56, 78, 183, 0.3)",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export default function DropzoneCowork(props) {
  const [fileList, setfileList] = useState([]);
  const [fileURL, setfileURL] = useState([]);
  const [initLoading, setInitLoading] = useState(false);
  const [building, setbuilding] = useState([]);
  const [workSpace, setWorkSpace] = useState([]);
  const [commonArea, setCommonArea] = useState([]);

  useEffect(() => {
    let data;
    if (props.name === "buildingImages") {
      data = {
        name: "buildingImages",
        data: building,
      };
    } else if (props.name === "workSpaceImages") {
      data = {
        name: "workSpaceImages",
        data: workSpace,
      };
    } else if (props.name === "commonAreaImages") {
      data = {
        name: "commonAreaImages",
        data: commonArea,
      };
    }
    props.handleAllImages(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building, workSpace, commonArea]);

  const onDrop = useCallback(
    async (acceptedFiles, name, deletePosition) => {
      if (name !== "delete") {
        if (fileList.length !== 0) {
          acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
              setfileURL((prev) => [...prev, reader.result]);
            };
            reader.readAsDataURL(file);
            setfileList([...fileList, file]);
          });
        } else {
          acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
              setfileURL((prev) => [...prev, reader.result]);
            };
            reader.readAsDataURL(file);
            fileList.push(file);
          });
        }

        handleApiFile(acceptedFiles);
      } else {
        setfileURL([]);
        setfileList([]);
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onload = () => {
            setfileURL((prev) => [...prev, reader.result]);
          };
          reader.readAsDataURL(file);
          setfileList((prev) => [...prev, file]);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileList, fileURL]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  const handleApiFile = async (acceptedFiles) => {
    // isLoading()
    setInitLoading(true);
    let acceptData = acceptedFiles;
    const formData = new FormData();
    for (let i = 0; i < acceptData.length; i++) {
      if (
        acceptData[i].size > 20000 * 1024 ||
        acceptData[i].size === undefined
      ) {
        swal({
          // title: header,
          text: "file size should be less than 20 MB",
          icon: "warning",
          timer: 1500,
          buttons: false,
        });
        setInitLoading(false);
      }
      if (
        acceptData[i].type === "application/pdf" ||
        acceptData[i].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        acceptData[i].type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        swal({
          // title: header,
          text: "Only Image or Video is accepted",
          icon: "warning",
          timer: 1500,
          buttons: false,
        });
        setInitLoading(false);
      } else {
        formData.append("uploadfiles", acceptData[i]);
      }
    }

    let url = `/user/file-upload/${
      localStorage.getItem("generatedId")
        ? localStorage.getItem("generatedId")
        : localStorage.getItem("addPropertyId") 
        ? localStorage.getItem("addPropertyId")
        : props.propertyId
    }?type=image&module=property`;

    const newData = await postUploadData(url, {}, formData);
    const { statusCode, data } = newData;
    if (statusCode === 200) {
      // isNotLoading()
      setInitLoading(false);
      if (props.name === "buildingImages") {
        // setfileURL([]);
        data.forEach((item) => {
          setfileURL((prev) => [...prev, item]);

          setbuilding((arr) => [...arr, item]);
          // }
        });
      } else if (props.name === "workSpaceImages") {
        data.forEach((item) => {
          setfileURL((prev) => [...prev, item]);

          setWorkSpace((arr) => [...arr, item]);
        });
      } else {
        data.forEach((item) => {
          setfileURL((prev) => [...prev, item]);
          setCommonArea((arr) => [...arr, item]);
        });
      }
    } else {
      setInitLoading(false);
    }
  };
  const handleDelete = (position) => {
    if (props.name === "buildingImages") {
      building.splice(position, 1);
      setbuilding(building);
    } else if (props.name === "workSpaceImages") {
      workSpace.splice(position, 1);
      setWorkSpace(workSpace);
    } else {
      commonArea.splice(position, 1);
      setCommonArea(commonArea);
    }

    fileList.splice(position, 1);
    setfileList(fileList);
    onDrop(fileList, "delete", position);
  };

  useEffect(() => {
    if (props?.imagesList?.coworkingImages !== undefined) {
      const { buildingImages, workSpaceImages, commonAreaImages } =
        props?.imagesList?.coworkingImages;
      if (props.name === "buildingImages") {
        setbuilding([]);
        buildingImages.forEach((item) => {
          setbuilding((arr) => [...arr, item]);
        });
      } else if (props.name === "workSpaceImages") {
        setWorkSpace([]);
        workSpaceImages.forEach((item) => {
          setWorkSpace((arr) => [...arr, item]);
        });
      } else {
        setCommonArea([]);
        commonAreaImages.forEach((item) => {
          setCommonArea((arr) => [...arr, item]);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.imagesList?.coworkingImages]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: "image/*,audio/*,video/*",
    minSize: 0,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  return (
    <div className="container">
      {initLoading && <Loader className={"fixed"} />}
      <div {...getRootProps({ style })}>
        <input
          {...getInputProps()}
          multiple={true}
          accept="image/png, image/jpeg"
        />
        <div className="image-dropzoneWrapper image-container-grids">
          {props?.imagesList?.imagesAndVideos === undefined
            ? props.name === "buildingImages"
              ? building?.length > 0 &&
                building?.map((item, index) => (
                  <div className={"dropZone-ImageSize grid-image"} key={index}>
                    {item?.type === "video" ? (
                      <video width="150" height="150" controls>
                        <source src={item?.viewUrl} type="video/mp4" />
                      </video>
                    ) : item?.type === "image" ? (
                      <img
                        src={item?.viewUrl}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    ) : (
                      <img
                        src={NoImage}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    )}
                    <div
                      className="remove-image"
                      onClick={() => handleDelete(index)}
                    >
                      Remove
                    </div>
                  </div>
                ))
              : props.name === "workSpaceImages"
              ? workSpace?.length > 0 &&
                workSpace?.map((item, index) => (
                  <div className={"dropZone-ImageSize grid-image"} key={index}>
                    {item?.type === "video" ? (
                      <video width="150" height="150" controls>
                        <source src={item?.viewUrl} type="video/mp4" />
                      </video>
                    ) : item?.type === "image" ? (
                      <img
                        src={item?.viewUrl}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    ) : (
                      <img
                        src={NoImage}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    )}
                    <div
                      className="remove-image"
                      onClick={() => handleDelete(index)}
                    >
                      Remove
                    </div>
                  </div>
                ))
              : props.name === "commonAreaImages"
              ? commonArea?.length > 0 &&
                commonArea?.map((item, index) => (
                  <div className={"dropZone-ImageSize grid-image"} key={index}>
                    {item?.type === "video" ? (
                      <video width="150" height="150" controls>
                        <source src={item?.viewUrl} type="video/mp4" />
                      </video>
                    ) : item?.type === "image" ? (
                      <img
                        src={item?.viewUrl}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    ) : (
                      <img
                        src={NoImage}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    )}
                    <div
                      className="remove-image"
                      onClick={() => handleDelete(index)}
                    >
                      Remove
                    </div>
                  </div>
                ))
              : null
            : building?.length > 0 && props.name === "buildingImages"
            ? building?.map((item, index) => (
                <div className={"dropZone-ImageSize grid-image"} key={index}>
                  {/* <img
                      src={item?.viewUrl}
                      className={"images-dropzone"}
                      alt="images"
                    /> */}
                  {item?.type === "video" ? (
                    <video width="150" height="150" controls>
                      <source src={item?.viewUrl} type="video/mp4" />
                    </video>
                  ) : item?.type === "image" ? (
                    <img
                      src={item?.viewUrl}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  ) : (
                    <img
                      src={NoImage}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  )}
                  <div
                    className="remove-image"
                    onClick={() => handleDelete(index)}
                  >
                    Remove
                  </div>
                </div>
              ))
            : workSpace.length > 0 && props.name === "workSpaceImages"
            ? workSpace?.map((item, index) => (
                <div className={"dropZone-ImageSize grid-image"} key={index}>
                  {item?.type === "video" ? (
                    <video width="150" height="150" controls>
                      <source src={item?.viewUrl} type="video/mp4" />
                    </video>
                  ) : item?.type === "image" ? (
                    <img
                      src={item?.viewUrl}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  ) : (
                    <img
                      src={NoImage}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  )}
                  <div
                    className="remove-image"
                    onClick={() => handleDelete(index)}
                  >
                    Remove
                  </div>
                </div>
              ))
            : props.name === "commonAreaImages" && commonArea.length > 0
            ? commonArea?.map((item, index) => (
                <div className={"dropZone-ImageSize grid-image"} key={index}>
                  {item?.type === "video" ? (
                    <video width="150" height="150" controls>
                      <source src={item?.viewUrl} type="video/mp4" />
                    </video>
                  ) : item?.type === "image" ? (
                    <img
                      src={item?.viewUrl}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  ) : (
                    <img
                      src={NoImage}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  )}
                  <div
                    className="remove-image"
                    onClick={() => handleDelete(index)}
                  >
                    Remove
                  </div>
                </div>
              ))
            : null}
        </div>

        <FaCloudUploadAlt size="50px" color="#034168" onClick={open} />
        <p className="drag-container-text">
          Drag & image or Video files or{" "}
          <span className="drag-container-link" onClick={open}>
            Browse
          </span>
        </p>
        <span className="f-12">Each Property image should be under 2MB</span>
        <span className="f-12"> Each Property Video should be under 20MB</span>
        <span className="f-12">
          Image Resolution should be at least 300 pixels per inch (ppi)
        </span>
        {props.name === "commonAreaImages" && (
          <span className="drag-container-span f-12">
            Note : First Image of Front Side Categories will be display as main
            image
          </span>
        )}
      </div>
    </div>
  );
}

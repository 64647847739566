import React, { useMemo } from "react";
import "./style.scss";

export const DashboardCardRm = React.memo(
  ({ data, viewAll, viewPage, loading, loadingSkeleton }) => {
    // reduce re-rendering
    function isObject(value) {
      return typeof value === "object" && value !== null;
    }
    const memoizedItems = useMemo(() => {
      if (loading) {
        return loadingSkeleton?.datas.map((item, index) => {
          return (
            <div
              className={`card-content ${index <= 2 ? "border-card-rm" : ""}`}
              key={index}
            >
              <div className="data-wrapper">
                {isObject(item?.value) ? (
                  <>
                    <div className="card-data placeholder-glow" >
                      <span
                        className="card-content-count placeholder"
                        title={String(item?.value?.viewAll)}
                        style={{backgroundColor: "grey"}}
                      >
                        {/* item?.value?.new <= 0 */}
                      </span>
                      <button
                        className="btn-view"
                        onClick={() => viewPage(item?.url, false)}
                      >
                        View All
                      </button>
                    </div>
                    <div className="card-data placeholder-glow">
                      <span
                        className="card-content-count placeholder"
                        title={String(item?.value?.new)}
                        style={{backgroundColor: "grey"}}
                      ></span>
                      <button
                        className="btn-view"
                        onClick={() => viewPage(item?.url, true)}
                      >
                        New
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="card-data placeholder-glow">
                    <span
                      className="card-content-count placeholder"
                      title={String(item?.value)}
                      style={{backgroundColor: "grey"}}
                    ></span>
                    <button
                      className="btn-view"
                      onClick={() => viewPage(item?.url, false)}
                    >
                      View All
                    </button>
                  </div>
                )}
              </div>
              <div className="sub-title" title={item?.label}>
                {item?.label}
              </div>
            </div>
          );
        });
      } else {
        return data?.datas.map((item, index) => {
          return (
            <div
              className={`card-content ${index <= 2 ? "border-card-rm" : ""}`}
              key={index}
            >
              <div className="data-wrapper">
                {isObject(item?.value) ? (
                  <>
                    <div className="card-data">
                      <span
                        className="card-content-count"
                        title={String(item?.value?.viewAll)}
                      >
                        {/* item?.value?.new <= 0 */}
                        {true ? "" : <div className="notification-count"></div>}
                        {String(item?.value?.viewAll)?.length > 6
                          ? `${String(item?.value?.viewAll)?.slice(0, 6)}..`
                          : String(item?.value?.viewAll)}
                      </span>
                      <button
                        className="btn-view"
                        onClick={() => viewPage(item?.url, false)}
                      >
                        View All
                      </button>
                    </div>
                    <div className="card-data">
                      <span
                        className="card-content-count"
                        title={String(item?.value?.new)}
                      >
                        {item?.isRead || true ? (
                          ""
                        ) : (
                          <div className="notification-count"></div>
                        )}
                        {String(item?.value?.new)?.length > 6
                          ? `${String(item?.value?.new)?.slice(0, 6)}..`
                          : String(item?.value?.new)}
                      </span>
                      <button
                        className="btn-view"
                        onClick={() => viewPage(item?.url, true)}
                      >
                        New
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="card-data">
                    <span
                      className="card-content-count"
                      title={String(item?.value)}
                    >
                      {item?.isRead ? (
                        ""
                      ) : (
                        <div className="notification-count"></div>
                      )}
                      {String(item?.value)?.length > 6
                        ? `${String(item?.value)?.slice(0, 6)}..`
                        : String(item?.value)}
                    </span>
                    <button
                      className="btn-view"
                      onClick={() => viewPage(item?.url, false)}
                    >
                      View All
                    </button>
                  </div>
                )}
              </div>
              <div className="sub-title" title={item?.label}>
                {item?.label}
              </div>
            </div>
          );
        });
      }
    }, [data, viewAll, loading]);

    return (
      <div className="card">
        <div className="card-body">
          <div className="card-head">
            <div className="card-head-left">
              <span className="card-head-image">
                <img src={loadingSkeleton?.viewUrl} alt="buyer" />
              </span>
              <h3 className="card-title">{loadingSkeleton?.label}</h3>
            </div>

            {!loading ? (
              <div className="card-head-right">
                <span
                  className="card-head-right-content"
                  title={String(data?.userCount)}
                >
                  {String(data?.userCount)?.length > 6
                    ? `${String(data?.userCount)?.slice(0, 6)}..`
                    : String(data?.userCount)}
                </span>
              </div>
            ) : (
              <div className="card-head-right placeholder-glow" >
                <span
                  className="card-head-right-content placeholder"
                  title={String(data?.userCount)}
                  style={{backgroundColor: "grey"}}
                >
                </span>
              </div>
            )}
          </div>
          <div className="card-details row">{memoizedItems}</div>
        </div>
      </div>
    );
  }
);

import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

//components

//json

//icons
import { FaChevronLeft } from "react-icons/fa";
//css
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveSlug } from "../../pages/Login/reducer";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [splitPathArray, setSplitPathArray] = useState([]);
  const [splitPathname, setSplitPathname] = useState([]);
  const [isNew, setIsNew] = useState(location?.state?.isNew);
  const { pathDemandId, pathPropertyId } = useParams();
  const dispatch = useDispatch();
  const { parentPage: reportuser, activeSlug } = useSelector(
    (state) => state.login
  );
  const camelCaseConversion = (value) => {
    return value.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };
  useEffect(() => {
    let pathname = window.location.pathname;
    let splitPathnames = pathname.split("/");
    setSplitPathname(pathname.split("/"));
    let splitPathArrayVar = [];
    delete splitPathnames[0];
    splitPathnames.map((value) => {
      let pathValue = camelCaseConversion(value);
      splitPathArrayVar.push({
        name: pathValue,
        id: value,
      });
      return value;
    });

    //Setting Active Slug

    if (
      splitPathArrayVar.length === 4 &&
      splitPathArrayVar[0].name === "Company"
    ) {
      dispatch(updateActiveSlug(splitPathArrayVar[3]?.id));
    }

    if (
      splitPathArrayVar.length === 4 &&
      splitPathArrayVar[0].name === "Buyer Report" &&
      splitPathArrayVar[1].name === "Brand List"
    ) {
      dispatch(updateActiveSlug(splitPathArrayVar[3]?.id));
    }

    if (
      splitPathArrayVar.length === 4 &&
      splitPathArrayVar[0].name === "Seller Report" &&
      splitPathArrayVar[1].name === "Developer List"
    ) {
      dispatch(updateActiveSlug(splitPathArrayVar[3]?.id));
    }

    if (
      splitPathArrayVar.length === 4 &&
      splitPathArrayVar[0].name === "Agent Report" &&
      splitPathArrayVar[1].name === "Agent List"
    ) {
      dispatch(updateActiveSlug(splitPathArrayVar[3]?.id));
    }
    if (
      splitPathArrayVar.length === 5 &&
      splitPathArrayVar[0].name === "Buyer Report" &&
      splitPathArrayVar[1].name === "Company List"
    ) {
      dispatch(updateActiveSlug(splitPathArrayVar[4]?.id));
    }

    if (
      splitPathArrayVar.length === 3 &&
      splitPathArrayVar[0].name === "Customer"
    ) {
      dispatch(updateActiveSlug(splitPathArrayVar[2]?.id));
    }
    setSplitPathArray(splitPathArrayVar);
  }, []);

  const onClickChevron = () => {
    if (splitPathArray.length === 3 && splitPathArray[0].name === "Customer") {
      if (splitPathArray[1].name === "Developer Page") {
        navigate("/customer", {
          state: { customerActiveKey: "customerSeller" },
        });
      } else if (splitPathArray[1].name === "Agent Page") {
        navigate("/customer", {
          state: { customerActiveKey: "customerAgent" },
        });
      } else {
        delete splitPathname[0];
        let pathArrayToBeLoaded = splitPathname.slice(0, -2);
        let paths = pathArrayToBeLoaded.join("/");

        navigate(paths);
      }
    } else if (splitPathArray[0].name === "Demand") {
      navigate("/demand");
    } else if (
      (pathDemandId || pathPropertyId) &&
      ["Agent Report", "Buyer Report", "Seller Report"].includes(
        splitPathArray[0].name
      ) &&
      ["Developer Page", "Agent Page", "Brand Page"].some((value) =>
        splitPathArray.map((item) => item.name).includes(value)
      ) &&
      splitPathArray.length === 6
    ) {
      navigate(
        `/buyerReport/companyList/brandCompanyCreation/brandPage/${activeSlug}`
      );
    } else if (
      pathDemandId &&
      splitPathArray.some((item) => item.name === "Relevant Demand")
    ) {
      let url =
        reportuser === "agent"
          ? "/agentReport/propertyList/relevantDemand"
          : "/sellerReport/propertyList/relevantDemand";
      navigate(url);
    } else if (
      (pathDemandId || pathPropertyId) &&
      ["Agent Report", "Buyer Report", "Seller Report"].includes(
        splitPathArray[0].name
      ) &&
      ["Developer Page", "Agent Page", "Brand Page"].some((value) =>
        splitPathArray.map((item) => item.name).includes(value)
      )
    ) {
      let url =
        reportuser === "agent"
          ? `/agentReport/agentList/agentPage/${activeSlug}`
          : reportuser === "seller"
          ? `/sellerReport/developerList/developerPage/${activeSlug}`
          : `/buyerReport/brandList/brandPage/${activeSlug}`;
      navigate(url);
    } else if (
      (pathDemandId || pathPropertyId) &&
      splitPathArray[0].name === "Buyer Report"
    ) {
      let url = pathDemandId
        ? "/buyerReport/demandList"
        : "/buyerReport/demandList";
      navigate(url);
    } else if (
      (pathDemandId || pathPropertyId) &&
      ["Seller Report", "Agent Report"].includes(splitPathArray[0].name) &&
      splitPathArray[1]?.name === "Response List"
    ) {
      let url =
        splitPathArray[0].name === "Seller Report"
          ? "/sellerReport/propertyList"
          : "/agentReport/propertyList";
      navigate(url);
    } else if (
      (pathDemandId || pathPropertyId) &&
      ["Seller Report", "Agent Report"].includes(splitPathArray[0].name) &&
      splitPathArray[1]?.name === "Property"
    ) {
      let url =
        splitPathArray[0].name === "Seller Report"
          ? "/sellerReport/propertyList"
          : "/agentReport/propertyList";
      navigate(url);
    } else if (
      ["sellerReport", "propertyList", "propertyDetailView"].every((item) =>
        window.location.pathname.split("/").includes(item)
      )
    ) {
      navigate("/sellerReport/propertyList");
    } else if (
      ["agentReport", "propertyList", "propertyDetailView"].every((item) =>
        window.location.pathname.split("/").includes(item)
      )
    ) {
      navigate("/agentReport/propertyList");
    } else if (
      ["agentReport", "propertyList", "DemandDetailView"].every((item) =>
        window.location.pathname.split("/").includes(item)
      )
    ) {
      navigate("/agentReport/propertyList");
    } else if (
      ["sellerReport", "propertyList", "DemandDetailView"].every((item) =>
        window.location.pathname.split("/").includes(item)
      )
    ) {
      navigate("/sellerReport/propertyList");
    } else if (splitPathArray[0].name === "Demand") {
      navigate("/demand");
    } else if (splitPathArray[0].name === "Properties") {
      navigate("/properties");
    } else if (
      splitPathArray[0].name === "Customer" &&
      splitPathArray[1].name === "Agent Page" &&
      (pathDemandId || pathPropertyId)
    ) {
      navigate(`/customer/agentPage/${activeSlug}`);
    } else if (
      splitPathArray[0].name === "Customer" &&
      splitPathArray[1].name === "Developer Page" &&
      (pathDemandId || pathPropertyId)
    ) {
      navigate(`/customer/developerPage/${activeSlug}`);
    } else if (
      splitPathArray[0].name === "Customer" &&
      splitPathArray[1].name === "Brand Page" &&
      (pathDemandId || pathPropertyId)
    ) {
      navigate(`/customer/brandPage/${activeSlug}`);
    } else if (
      splitPathArray[0].name === "Company" &&
      splitPathArray[1].name === "Developer Company Creation" &&
      (pathDemandId || pathPropertyId)
    ) {
      navigate("/company/developerCompanyCreation/developerPage");
    } else if (
      splitPathArray[0].name === "Company" &&
      splitPathArray[1].name === "Brand Company Creation" &&
      (pathDemandId || pathPropertyId)
    ) {
      navigate(`/company/brandCompanyCreation/brandPage/${activeSlug}`);
    } else {
      delete splitPathname[0];
      if (
        splitPathname.length === 6 ||
        splitPathname[1] === "brandCompanyCreation"
      ) {
        var pathArrayToBeLoaded = splitPathname.slice(0, -2);
      } else if (
        splitPathname.length === 5 &&
        (splitPathname[2] === "brandCompanyCreation" || "brandList")
      ) {
        var pathArrayToBeLoaded = splitPathname.slice(0, -2);
      }else if (
        splitPathname.length === 4 &&
        (splitPathname[1] === "dashboard")
      ) {
        var pathArrayToBeLoaded = splitPathname.slice(0, -2);
      } else {
        var pathArrayToBeLoaded = splitPathname.slice(0, -1);
      }
      
      let paths = pathArrayToBeLoaded.join("/");
      // if(isNew) {
      //   paths = `${paths}?filter=new`
      // }
      navigate(paths);
    }
  };

  const onClickNavLink = (e, name, index) => {
    delete splitPathname[0];
    if (splitPathname.length === index + 2) {
      return false;
    }else if(splitPathname[1] === 'dashboard' && splitPathname.length === index + 3) {
      return
    } else {
      if(splitPathname[1] === 'dashboard' && index === 1) {
        splitPathname.splice(index + 3);
        let paths = splitPathname.join("/");
        navigate(paths);
      } else {
        splitPathname.splice(index + 2);
        let paths = splitPathname.join("/");
        navigate(paths);
      }
      
    }
  };
  return (
    <div className="navBar-Container">
      <div className="d-flex nav-path">
        {splitPathArray.map((value, index) => {
          if (
            splitPathArray[0].name === "Demand" ||
            splitPathArray[0].name === "Properties"
          ) {
            if (
              pathDemandId ||
              pathPropertyId ||
              (splitPathArray.length > 2 &&
                splitPathArray[2]?.name === "Office")
            ) {
              delete splitPathArray[2];
            }
          } else if (
            pathDemandId &&
            splitPathArray.some((item) => item.name === "Relevant Demand")
          ) {
            delete splitPathArray[4];
          } else if (
            (pathDemandId || pathPropertyId) &&
            ["Agent Report", "Buyer Report", "Seller Report"].includes(
              splitPathArray[0].name
            ) &&
            ["Developer Page", "Agent Page", "Brand Page"].some((route) =>
              splitPathArray.map((item) => item.name).includes(route)
            ) &&
            splitPathArray.length === 6
          ) {
            delete splitPathArray[5];
          } else if (
            (pathDemandId || pathPropertyId) &&
            ["Agent Report", "Buyer Report", "Seller Report"].includes(
              splitPathArray[0].name
            ) &&
            ["Developer Page", "Agent Page", "Brand Page"].some((route) =>
              splitPathArray.map((item) => item.name).includes(route)
            )
          ) {
            delete splitPathArray[4];
          } else if (
            (pathDemandId || pathPropertyId) &&
            ["Agent Report", "Buyer Report", "Seller Report"].includes(
              splitPathArray[0].name
            )
          ) {
            delete splitPathArray[3];
          } else if (splitPathArray[0].name === "Customer") {
            if (pathDemandId || pathPropertyId) {
              delete splitPathArray[3];
            }
          } else if (splitPathArray[0].name === "Company") {
            if (pathDemandId || pathPropertyId) {
              delete splitPathArray[4];
            }
          } else if (splitPathArray[0].name === "Dashboard") {
            if (pathDemandId || pathPropertyId) {
              delete splitPathArray[4];
            }
          }
          return (
            <div className="navNames text-heading" key={index}>
              {index > 0 ? (
                <p
                  style={{ cursor: "pointer", marginBottom: 0 }}
                  onClick={(e) => onClickNavLink(e, value, index)}
                >
                  {"/ " + value.name}
                </p>
              ) : splitPathArray.length > 1 ? (
                <div className="d-flex justify-content-center align-items-center">
                  <FaChevronLeft
                    style={{
                      cursor: "pointer",
                      color: "#000000",
                      marginRight: "10px",
                    }}
                    onClick={(e) => onClickChevron(e, value, index)}
                  />
                  <p
                    style={{ cursor: "pointer", marginBottom: 0 }}
                    onClick={(e) => onClickNavLink(e, value.name, index)}
                  >
                    {value.name}
                  </p>
                </div>
              ) : (
                <p>{value.name}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default NavBar;

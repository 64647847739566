import FormModal from "./index";
import Button from "../Form/Button";




export const ConformationPopup = ({
    onClose,
    show,
    message,
    warningYesClick,
  }) => {
    return (
      <FormModal
        onClose={onClose}
        show={show}
        heading={"Confirmation"}
        size={"md"}
        loading={false}
        loader="fixed"
      >
        <p>{message}</p>
        <div className="btn-container">
          <Button className={"btn-outline-color-primary"} onClick={onClose}>
            No
          </Button>
          <Button onClick={warningYesClick} className="btn-color-primary">
            Yes
          </Button>
        </div>
      </FormModal>
    );
  };
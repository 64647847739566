import { Row, Col } from "react-bootstrap";
import noImage from '../../../assets/images/noImage.jpg';
import { possessionList } from "../../../pages/AddNewDemand/PostDemandForms/dropDownValues";
import { useEffect, useState } from "react";


export const DemandDetailCard = ({ brandData, demand }) => {
  let possessionValue
  const [pdfImage, setPdfImage] = useState("")
  const [isImageConverted, setIsImageConverted] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  possessionList.filter(el => el._id === demand?.possessionFrom ? possessionValue = el.name : "-")
  const primaryCard = [
    {
      label: "Prefered Location",

      value: `${demand?.microLocation?.description ? demand?.microLocation?.description : "-"}`,
      showLabel: ["Retail", "Office space", "Flex Space"]

    },

    {
      label: "Property Type",
      value: `${demand?.propertyType?.name || "-"}`,
      showLabel: ["Retail", "Office space", "Flex Space"]

    },
    {
      label: "Carpet Area",
      value: `${(demand?.carpetAreaMin || demand?.carpetAreaMin === 0) ? demand?.carpetAreaMin : ""}-${(demand?.carpetAreaMax || demand?.carpetAreaMax === 0) ? demand?.carpetAreaMax + " sqft" : ""}`,
      showLabel: ["Retail", "Office space"]
    },
    {
      label: demand?.propertyType?.name == "Flex Space" ? "Needed From" : "Possession",
      value: `${possessionValue || "-"}`,
      showLabel: ["Retail", "Office space", "Flex Space"]
    },
    {
      label: demand?.transactionType == "Buy" ? "Rate budget per sqft on carpet area" : "Rent budget per sqft on carpet area",
      value: `${(demand?.rentPsfMin || (demand?.rentPsfMin === 0 && demand?.rentPsfMax !== 0)) ? "Rs. " + demand?.rentPsfMin : ""}-${(demand?.rentPsfMax) ? demand?.rentPsfMax : ""}`,
      showLabel: ["Retail", "Office space"]
    },
    {
      label: "Store type",
      value: `${demand?.buildingStructure?.length > 0 ? demand?.buildingStructure?.map((item) => item?.value)?.toString() : "-"}`,
      showLabel: ["Retail"]


    }
  ]
  let demandTitle = `Demand for ${demand?.propertyType?.name == "Flex Space" ? demand?.propertyType?.name : demand?.propertyType?.name?.toLowerCase()} ${demand?.propertyType?.name == "Flex Space" ? "" : " store"} in ${demand?.microLocation?.description?.length > 50 ? `${demand?.microLocation?.description?.slice(0, 50)}...` : demand?.microLocation?.description}`
  const getImageAsBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const base64Image = await convertBlobToBase64(blob);
      return base64Image;
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
  };
  
  // Function to convert a Blob to Base64
  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  useEffect(() => {
    // getPdfImageData();
    const imageUrl = demand?.brandId?.name ? demand?.brandId?.logo?.viewUrl : demand?.endUse?.image?.viewUrl ? demand?.endUse?.image?.viewUrl : "";
    if(imageUrl !== "") {
      setImageLoading(true)
      getImageAsBase64(imageUrl).then((res) => {
        if(res) {
          setImageLoading(false)
          if(res.length < 1000) {
            setIsImageConverted(false)

          } else {
            setPdfImage(res)
            setIsImageConverted(true)
          }   
        } else {
          console.log('Error')
          setIsImageConverted(false)
          setImageLoading(false)
        }
      })
    }
  }, [])
  return (
    <>
      <Row className="p-0">
        <Col sm={12} md={12} className="p-3 ">
          <div className="demand-detail-container d-flex ">
            {!imageLoading && <div className="detail-container-img ">
              {/* {demand?.brandId?.name ?
                <img 
                  src={demand?.brandId?.logo?.viewUrl ?
                    demand?.brandId?.logo?.viewUrl : demand?.endUse?.image?.viewUrl} alt="brandImage" 
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = noImage;
                  }}
                />
                :
                <img 
                  src={demand?.endUse?.image?.viewUrl ?
                    demand?.endUse?.image?.viewUrl : noImage} alt="brandImage" 
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = noImage;
                  }}
                />
              } */}
              {isImageConverted ? <img src={`data:image/jpeg;base64, ${pdfImage}`} alt="brandImage"></img> :<img src={noImage} alt="brandImage"></img> }
            </div>}

            <div className="px-3  brand-details w-100">
              <div className="text-end">
                <span className="for-rent">For {demand?.transactionType}</span>
              </div>
              <div className="primary">
                <label className="brand-text" title={demandTitle}>
                  {demandTitle}
                </label>
                <p className="f-18 fw-500">
                  <b>Demand ID: </b>
                  {demand?.productId?.display}
                </p>
                <div>
                  <label className="mb-3 brand-text">Primary Details</label></div>
                <div className="primary-details">
                  {demand && primaryCard.filter((item) => item.showLabel.includes(demand?.propertyType?.name)).map((item, index) => {
                    return (<>
                      <div className="mt-2 primary-details_card" key={index}>
                        <div className="text-start p-2 py-3 bg-light-card border-radius ">
                          <div className="primary-details_cardText f-20 fw-500 overflow-hidden-ellipsis" title={item.value}>
                            {item.value}
                          </div>
                          <div className="primary-details_cardSubtext m-0 ">
                            {item.label}
                          </div>
                        </div>
                      </div>
                    </>)
                  })}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="mt-2">
        <label className="brand-text">Description</label>
        <p>{demand?.brandId?.about}</p>
      </div>
    </>
  )
}
import { Button } from 'react-bootstrap'

function Buttons(props) {
  return (
    
      <Button {...props} className={props.className ? props.className : ""} >
      {!props.loading && <span>{props.name}</span>}
      {props.loading && <i className="fa fa-spin fa-spinner"></i>}
        </Button>
   
  )
}

export default Buttons
import { useMemo, useState, useCallback } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import swal from "sweetalert";

import "react-toastify/dist/ReactToastify.css";

import "./style.scss";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgba(56, 78, 183, 0.3)",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};
export default function LogoUpload(props) {
  const [fileList, setfileList] = useState([]);
  const [fileURL, setfileURL] = useState("");
  const onDrop = useCallback(
    (acceptedFiles, name, deletePosition) => {
      if (
        acceptedFiles[0].size > 2000 * 1024 ||
        acceptedFiles[0].size === undefined
      ) {
        swal({
          text: "file size should be less than 2 MB",
          icon: "warning",
          timer: 3000,
          buttons: false,
        });
        return;
      }

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          // const img = new Image();
          // img.src = reader.result;

          // img.onload = () => {
          //   const width = img.width;
          //   const height = img.height;

          //   if (width >= 120 && height >= 120) {
          //     console.log("Image resolution is 120x120");
          //   } else {
          //     swal({
          //       text: "Image should have a resolution of 120x120",
          //       icon: "warning",
          //       timer: 3000,
          //       buttons: false,
          //     });
          //     return;
          //   }
          // };
          setfileURL(reader.result);
        };
        props.onHandelImage(acceptedFiles[0]);
        reader.readAsDataURL(file);
        setfileList([...fileList, file]);
      });
    },
    [fileList, fileURL]
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxFiles: 1,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    noDrag: true,
    minSize: 0,
    multiple: false,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  return (
    <div>
      <div className="drop-container">
        <div className="container text-center">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} accept="image/png, image/jpeg" />
            <div className="image-dropzoneWrapper-clientele image-container-grids">
              <div className={"dropZone-ImageSize-clientele grid-image"}>
                {(fileURL || props?.defaultImageData !== "") && (
                  <img
                    src={
                      props?.defaultImageData !== ""
                        ? props?.defaultImageData
                        : fileURL
                    }
                    alt="images"
                    className={"images-dropzone-clientele"}
                  />
                )}
              </div>
            </div>

            <FaCloudUploadAlt
              size="50px"
              color="#034168"
              onClick={open}
              className="cursor-pointer"
            />

            <span className="drag-container-span">
              Upload Client Logo{" "}
              <a className="drag-container-link" onClick={open} href="#">
                Browse
              </a>
              <br />
              Image should be under 2MB <br />
              Image resolution must be 120 x 120 <br />
            </span>
          </div>
        </div>
      </div>
      <span className="customInvalid">{props.error}</span>
    </div>
  );
}

import Spinner from 'react-bootstrap/Spinner'

import "./style.scss";

const Loader = ({ className,spinner=false,variant="dark"  }) => {
  if(spinner){
    return(
    <div className={"loader d-flex alig-items-center justify-content-center h-100" }>
<Spinner animation="border" role="status" className="d-flex alig-items-center justify-content-center" variant={variant}>
</Spinner>
</div>)
  }

  return (
    <div className={`loader text-center ${className}`}>
      <div className="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;

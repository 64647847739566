import {
    fitOutData,
    possessionData,
    possessionDataCowork,
    ownershipData,
    depositMonthData,
    brokerageeData,
    propTypeData,
    floorVal,
    buildingStructureData,
    propertyAgeData,
    washroomData,
    totalFloors,
    meetingRoomData,
    floorNumber,
    leaseData,
    fitouts,
    fitOutDataOffice,
    cabinData,
    amenetiesAvailable,
    idealFor,
    floorOption,
    buisnessHoursData,
    nearestConnectivityList,
  } from "../../components/DropdownOptions";

   const parkingData = [
    {
        value: "payable",
        name: "Payable",
    },
    {
        value: "included",
        name: "Included",
    },
    {
        value: "unavailable",
        name: "Unavailable",
    },
  ]

  export const getCoworkDocumentData = [
    {
      label: "Brochure Upload",
      type: "file",
      name: "brochure",
      brochure: [],
      uploadFiles: []
    },
  ];

export const getPropertyDetailWCowork = [
  {
    disabled: false,
    label: " Project Name / Building Name",
    type: "text",
    errorMessage: "Project name is required",
    required: false,
    placeholder: "Enter Project Name / Building Name",
    name: "propertyName",
  },
  {
    // disabled: false,
    label: "Property Type",
    type: "select",
    errorMessage: "Property Type is required",
    required: true,
    name: "propertyTypeId",
    placeholder: "Select Property Type",
    options: [],
  },
  {
    disabled: false,
    label: "Transaction Type",
    type: "select",
    errorMessage: "Transaction Type is required",
    required: true,
    name: "transactionType",
    placeholder: "Select Transaction Type",
    options: propTypeData,
  },
  {
    disabled: false,
    label: "City",
    type: "SearchSelect",
    required: true,
    placeholder: "Select city name",
    errorMessage: "City is required",
    name: "location",
  },

  {
    label: "Location",
    type: "microLocation",
    name: "microLocation",
    errorMessage: "Location is required",
    hidden: true,
    required: true,
    size: "hidden-group",
  },

  {
    disabled: false,
    label: "Pin Code",
    type: "number",
    required: true,
    placeholder: "Enter Pin Code",
    errorMessage: "Pin Code is required",
    name: "pincode",
    max: 6,
  },
  {
    disabled: false,
    label: "Property Available from",
    type: "select",
    errorMessage: "Property available from is required",
    required: true,
    name: "possession",
    placeholder: "Select Property Avaliable",
    options: possessionDataCowork,
  },

  {
    disabled: false,
    label: " About Property/Project",
    type: "textarea",
    errorMessage: "About is required",
    required: true,
    placeholder: "Enter about property",
    name: "about",
  },
  {
    label: "Google Location",
    type: "google-location",
    name: "gMapLatLong",
    hidden: true,
    required: false,
    errorMessage: "Google map is required",
  },

  // {
  //   disabled: false,
  //   label: "Developer Name",
  //   type: "text",
  //   errorMessage: "Developer name is required",
  //   required: false,
  //   placeholder: "Enter Developer name",
  //   name: "projectDeveloper",
  // },

  // {
  //   disabled: false,
  //   type: "radio",
  //   errorMessage: "Units is required",
  //   required: true,
  //   placeholder: "Enter Units",
  //   name: "propertyUnit",
  //   options: [{
  //     value: 'individual',
  //     label: 'Individual'
  //   }, {
  //     value: 'multiple',
  //     label: 'Multiple'
  //   }],
  //   fields: [{
  //     disabled: false,
  //     label: "Builtup Area In Square Feet",
  //     type: "number",
  //     errorMessage: "Builtup Area In Square Feet is required",
  //     required: true,
  //     placeholder: "Enter Builtup Area",
  //     name: "builtupArea",
  //     requiredFor: 'individual',
  //     sufix: "sq.ft",
  //     positive: true,
  //   }, {
  //     disabled: false,
  //     label: "Number of Units",
  //     type: "number",
  //     errorMessage: "Number of Units is required",
  //     required: true,
  //     placeholder: "Enter  Number of Units",
  //     name: "noOfUnits",
  //     requiredFor: 'multiple',
  //     positive: true,
  //   },
  //   {
  //     disabled: false,
  //     label: "Carpet Area range in square feet",
  //     name: "carpetAreaMin",
  //     placeholder: "Enter number",
  //     required: "carpetAreaRequired",
  //     type: "rangeInput",
  //     defaultChecked: true,
  //     requiredFor: 'multiple',
  //     rangefields: [
  //       {
  //         type: "input",
  //         inputType: "number",
  //         fieldValue: "carpetAreaMin",
  //         placeholder: "Min-Range",
  //         name: "carpetAreaMin",
  //         sufix: "sqft"

  //       },
  //       {
  //         type: "input",
  //         inputType: "number",
  //         fieldValue: "carpetAreaMax",
  //         placeholder: "Max-Range",
  //         name: "carpetAreaMax",
  //         sufix: "sqft"
  //       }
  //     ],
  //   },
  //   {
  //     disabled: false,
  //     label: "Carpet Area in square feet",
  //     type: "number",
  //     errorMessage: "Carpet Area is required",
  //     required: true,
  //     placeholder: "Enter number",
  //     name: "carpetAreaMax",
  //     requiredFor: 'individual',
  //     required: true,
  //     sufix: "sq.ft",
  //     min: "0",
  //   },
  //   ]
  // },
  // {
  //   disabled: false,
  //   label: "Carpet Area in square feet",
  //   type: "number",
  //   errorMessage: "Carpet Area is required",
  //   required: true,
  //   placeholder: "Enter number",
  //   name: "carpetArea",

  //   sufix: "sq.ft",
  //   min: "0",
  // },
  // {
  //   disabled: false,
  //   label: "Carpet Area in square feet",
  //   type: "number",
  //   errorMessage: "Carpet Area is required",
  //   required: true,
  //   placeholder: "Enter number",
  //   name: "carpetAreaMax",
  //   sufix: "sq.ft",
  //   positive: true,
  // },
  // {
  //   disabled: false,
  //   label: "Builtup Area in square feet",
  //   type: "number",
  //   errorMessage: "Builtup Area is required",
  //   required: true,
  //   placeholder: "Enter number",
  //   name: "builtupArea",
  //   sufix: "sq.ft",
  //   positive: true,
  // },
  // {
  //   disabled: false,
  //   label: "Rate per square feet on carpet area",
  //   type: "number",
  //   errorMessage: "Rate psf is required",
  //   required: true,
  //   placeholder: "Enter number",
  //   name: "rentPsf",
  //   note: "Note: Please confirm that the rate entered is on Carpet Area",
  //   prefix: "₹",
  //   sufix: "sq.ft",
  //   min: "0",
  // },

  // {
  //   disabled: false,
  //   label: "Property available from",
  //   type: "select",
  //   errorMessage: "Property available from is required",
  //   required: true,
  //   name: "possession",
  //   placeholder: "Select Property Avaliable",
  //   options: possessionData,
  // },
  // {
  //   disabled: false,
  //   label: "Negotiable",
  //   type: "checkbox",
  //   errorMessage: "Negotiable is required",
  //   required: false,
  //   name: "negotiable",
  //   note: "Note: Tick the box if negotiable",
  //   checked: false,
  // },

  // {
  //   disabled: false,
  //   label: "Deposit in Months",
  //   type: "select",
  //   errorMessage: "Deposit in Months is required",
  //   required: false,
  //   name: "deposit",
  //   placeholder: "Select Deposit in Months",
  //   options: depositMonthData,
  // },

  // {
  //   disabled: false,
  //   label: "Brokerage in days",
  //   type: "select",
  //   errorMessage: "Brokerage in days is required",
  //   required: false,
  //   name: "brokerage",
  //   placeholder: "Select Brokerage in days",
  //   options: brokerageeData,
  // },
  // {
  //   disabled: false,
  //   label: "Lease escalation",
  //   type: "select",
  //   errorMessage: "Lease escalation is required",
  //   name: "leaseEscalationValue",
  //   placeholder: "Select Lease escalation",
  //   options: leaseData,
  // },
  // {
  //   disabled: false,
  //   label: "Lease escalation",
  //   type: "number",
  //   errorMessage: "lease escalation is required",
  //   name: "leaseEscalationpercentage",
  //   placeholder: "Enter lease escalation",
  //   sufix: "%",
  // },

  // {
  //   disabled: false,
  //   label: "Transaction Type",
  //   type: "select",
  //   errorMessage: "Transaction Type is required",
  //   required: true,
  //   name: "transactionType",
  //   placeholder: "Select Transaction Type",
  //   options: propTypeData,
  // },

  // {
  //   disabled: false,
  //   label: "Fit-out/Rent free period",
  //   type: "select",
  //   errorMessage: "Fit-out/Rent free period is required",
  //   required: false,
  //   name: "rentFreePeriod",
  //   placeholder: "Select Fit-out/Rent free period",
  //   options: fitouts,
  // },
  // {
  //   disabled: false,
  //   label: "OC available",
  //   type: "select",
  //   errorMessage: "OC available is required",

  //   required: true,
  //   name: "ocStatus",
  //   placeholder: "Select OC Avaliable",
  //   options: fitOutData,
  // },
  // {
  //   disabled: false,
  //   label: "Lease tenure In years",
  //   type: "number",
  //   errorMessage: "Lease tenure is required",
  //   required: false,
  //   placeholder: "Enter number",
  //   name: "tenure",

  //   sufix: "years",
  //   positive: "true",
  // },
  // {
  //   disabled: false,
  //   label: "Lock in period",
  //   type: "number",
  //   errorMessage: "Lock in period is required",
  //   required: false,
  //   name: "lockInPeriod",
  //   placeholder: "Enter Lock in period",
  //   sufix: "years",
  // }
  ];

  export const CoworkSpaceAdditonalnput = [
    {
      label: "Buisness Hours",
      type: "select",
      disabled: false,
      errorMessage: "Buisness hours is required",
      required: false,
  
      placeholder: "Select buisness hours",
      name: "businessHours",
      options: buisnessHoursData,
    },
    {
      label: "Bike parking",
      type: "select",
      disabled: false,
      errorMessage: "Bike parking is required",
      required: false,
  
      placeholder: "Select Bike parking",
      name: "bikeParking",
      options: parkingData,
    },
    {
      label: "Car parking",
      type: "select",
      disabled: false,
      errorMessage: "Car parking is required",
      required: false,
  
      placeholder: "Select Car parking",
      name: "carParking",
      options: parkingData,
    },
    {
      label: "Floor Number",
      type: "number",
      disabled: false,
      errorMessage: "Floor number is required",
      required: false,
  
      placeholder: "Enter floor number",
      name: "availableFloor",
    },
    // {
    //   label: "Available Floor number ",
    //   type: "number",
    //   disabled: false,
    //   errorMessage: "Available Floor number is required",
    //   required: false,
    //   placeholder: "Enter Available Floor number Avaliable",
    //   name: "availablefloors",
    // },
  
    // {
    //   disabled: false,
    //   label: "Ameneties available",
    //   type: "select",
    //   errorMessage: "Ameneties available is required",
    //   value: "",
    //   required: false,
    //   name: "ameneties",
    //   placeholder: "Select Ameneties available",
    //   options: amenetiesAvailable,
    // },
    {
      disabled: false,
      label: "Age of property",
      type: "select",
      errorMessage: "Age of property is required",
      value: "",
      required: false,
      name: "propertyAge",
      placeholder: "Select Age of property",
      options: propertyAgeData,
    },
    // {
    //   label: "Nearest Connectivity",
    //   type: "select",
    //   disabled: false,
    //   errorMessage: "Nearest connectivity is required",
    //   required: false,
    //   placeholder: "Select nearest connectivity",
    //   name: "nearestConnectivity",
    //   options: nearestConnectivityList,
    // },
  ];

  export const getCoworkUploadPropertyData = [
    {
      title: "Building Images",
      name: "buildingImages",
    },
    {
      title: "Workspace Images",
      name: "workSpaceImages",
    },
    {
      title: "Common Area Images",
      name: "commonAreaImages",
    },
  ];

  export const coworkFormatData = [
    {
      label: "Rate per seat for Dedicated Desk",
      type: "text",
      disabled: false,
      id: "Dedicated Desk",
      errorMessage: "Dedicated desk is required",
      required: false,
      placeholder: "Enter Amount",
      name: "dedicatedDesk",
    },
    {
      label: "Rate per seat for Flexi Desk",
      type: "text",
      id: "Flexi Desk",
      disabled: false,
      errorMessage: "Flexi desk is required",
      required: false,
      placeholder: "Enter Amount",
      name: "flexiDesk",
    },
    {
      label: "Rate per seat for Meeting Room",
      type: "text",
      id: "Meeting Room",
      disabled: false,
      errorMessage: "Meeting room is required",
      required: false,
      placeholder: "Enter Amount",
      name: "meetingRoom",
    },
    {
      label: "Rate per seat for Managed Offices",
      type: "text",
      disabled: false,
      id: "Managed Office",
      errorMessage: "Managed offices is required",
      required: false,
      placeholder: "Enter Amount",
      name: "managedOffices",
    },
    {
      label: "Rate per seat for Cabins",
      type: "text",
      disabled: false,
      id: "Cabins",
      errorMessage: "Cabins is required",
      required: false,
      placeholder: "Enter Amount",
      name: "cabins",
    },
  ];
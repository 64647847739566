import  { useEffect,useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Form } from "react-bootstrap";
import "./style.scss";
import { AiOutlineClose } from "react-icons/ai";
import {postGetData} from "../../api"

export default function GoogleAutoComplete({
  value,
  handleSelectLocation,
  label,
  disabled,
  errorMessage,
  size,
  isInvalid,
 
  name,
  zIndex,
  isMulti,
  multiselectValue,
  selectMultipleValue,
  cityValue,
  requried,
  handleBlur
}) {
  // const [setDeg] = useState()
  const [location, setLocation] = useState([]);
  const [inputFocus, setInputFocused] = useState(false);


  const google = window.google;

  const getLocationApi = async (val) => {
    let url = `/cityZones/${cityValue}?type=description&search=${val}`;
    let payload = {
      zoneIds:[]
    }
    const getZoneData = await postGetData(url, {}, payload);
    const { data, status } = getZoneData;
    if (status) {
      setLocation(data)
      
    } 
   
    
  };

  const handleSelect =  (address, placeId, suggestion) => {
    setInputFocused(false)
    handleSelectLocation(suggestion);
    setLocation([])
    if (isMulti) {
      let suggestedValue = {
        ...suggestion,
        _id: suggestion.placeId,
        name: suggestion.description,
      };
      let valueexist = multiselectValue.find(
        (item) => item.description === suggestedValue.description
      );
      if (!valueexist) {
        selectMultipleValue([...multiselectValue, suggestedValue], "add", suggestedValue);
      }
    }
  };
  const handleChange = (address) => {
    setInputFocused(true)
    handleSelectLocation({ description: address },"input");
    if(cityValue && address){
      getLocationApi(address)
    }

    // addIncidentObject((prev:) => ({
    //     ...prev,
    //     address: address,
    //     lat: '',
    //     lng: ''
    // }))
  };

  const searchOptions = {
    location: new google.maps.LatLng(13.067439, 80.237617),
    // bounds: new google.maps.LatLngBounds(google.maps.LatLng(12.9236939, 80.110186), google.maps.LatLng(13.2436939, 80.430186)),
    bounds: {
      south: 37.6398299,
      west: -123.17382499999997,
      north: 37.9298239,
      east: -122.28178000000003,
    },
    componentRestrictions: { country: "in" },
    radius: 2000,
    types: ["sublocality"],
    strictBounds: true,
  };

  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  };
  useEffect(() => {
    geocodeByAddress("Mumbai, India")
      .then((results) => getLatLng(results[0]))
      .then()
      .catch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRemoveValue = (val) => {
    const filteredArray = [...multiselectValue].filter(
      (item) => item.description !== val.description
    );
    selectMultipleValue([...filteredArray], "remove", {});
  };


 
  const handelfocusOut =(e)=>{
    handleBlur(e);
    setInputFocused(false);
    setLocation([])
  
  }
  const handelFocusin =()=>{
    setInputFocused(true);
    setLocation([])
  }

  

  return (
    <div className="autocomplete-container" style={{zIndex: zIndex ? 0 : 1}}>
      <PlacesAutocomplete
        value={value?.description ? value?.description : ""}
        onChange={handleChange}
        onError={onError}
        // strictbounds="true"
        onSelect={handleSelect}
        searchOptions={searchOptions}
        // searchOptions={{ types: ['sublocality', 'cities'] }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Form.Group className={"form-group " + size} controlId={name}>
              <Form.Label
                className={`fw-700 f-14 ${ "text-black"
                }`}
              >
                {`${label} ${requried?"*":""}`}
              </Form.Label>

              <Form.Control
                isInvalid={isInvalid}
                disabled={disabled ? true : false}
                {...getInputProps({
                  placeholder: "Search by location",
                  // className:history?"search search-width ":" search search-width-landing-page"
                  className: "form-group input form-control w-100 mb-0",
                  disabled: disabled ? true : false,
                  
                  
                })}
                onFocus={handelFocusin}
                onBlur={handelfocusOut}
               
              ></Form.Control>

              <Form.Control.Feedback type="invalid">
                {errorMessage}
              </Form.Control.Feedback>
            </Form.Group>


            {inputFocus && <div className="autocomplete-dropdown-container">
              {value?.address?.length > 0 && loading && <div>Loading...</div>}
              {value?.description && [...(
                suggestions.filter((item)=>{
                  // condition 1:filtering location based on cityValue 
                  //condition 2:check for includes Maharashtra,because some location comes under Maharashtra not from mumbai or pune
                  // condition 3:mumbai and pune are in Maharashtra only, so we fliter based on city which city is  selected
                  return ((cityValue === "Mumbai" || cityValue === "Pune") ? (item.description.includes(cityValue && cityValue) || (item.description.includes("Maharashtra") && !item.description.includes(cityValue === "Mumbai" ? "Pune" : "Mumbai"))) : (item.description.includes(cityValue && cityValue)))
                }).length < 2
              
              ? [...suggestions.filter((item)=>{
                 return ((cityValue === "Mumbai" || cityValue === "Pune") ? (item.description.includes(cityValue && cityValue) || (item.description.includes("Maharashtra") && !item.description.includes(cityValue === "Mumbai" ? "Pune" : "Mumbai"))) : (item.description.includes(cityValue && cityValue)))
              }),...location]:   suggestions.filter((item)=>{
          
                return ((cityValue === "Mumbai" || cityValue === "Pune") ? (item.description.includes(cityValue && cityValue) || (item.description.includes("Maharashtra") && !item.description.includes(cityValue === "Mumbai" ? "Pune" : "Mumbai"))) : (item.description.includes(cityValue && cityValue)))
              }))].slice(0, 5).map((suggestion, index) => {
              
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? {
                      backgroundColor: "#FAFAFA",
                      cursor: "pointer",
                      minHeight: "25px",
                      display: "flex",
                      alignItems: "center",
                    }
                  : {
                      backgroundColor: "#E5E5E5",
                      cursor: "pointer",
                      minHeight: "25px",
                      display: "flex",
                      alignItems: "center",
                    };
                return (

                  <>
                   {(suggestion.description.includes(cityValue&&cityValue) || ((suggestion.description.includes("Maharashtra")) && !suggestion.description.includes(cityValue==="Mumbai"?"Pune":"Mumbai")))  || suggestion._id? 
                  <div
                  key={index + 1}
                  className="mt-2 list-unstyled p-2 backGround"
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span className="p-2 py-4">{suggestion.description}</span>
                </div>
                   :
                    ""}

                  </>
                
                 
                );
              })}
            </div>}
          </div>
        )}
      </PlacesAutocomplete>
      <div className="d-flex  flex-wrap justify-content-start align-items-center mt-1">
        {isMulti
          ? multiselectValue.map((val) => (
              <div
                key={val.description}
                className="multi-select-list ml-2 my-1"
              >
                <span
                  className="overflow-hidden-ellipsis"
                  title={val.description}
                >{`${
                  val.description?.length > 20
                    ? `${val.description?.slice(0, 20)}...`
                    : val.description
                }`}</span>

                {!disabled && (
                  <button
                    className="close-icon"
                    name={val.description}
                    onClick={() => handleRemoveValue(val)}
                  >
                    <AiOutlineClose
                      style={{
                        color: "white",
                        fontSize: "1em",
                        paddingLeft: "3px",
                        cursor: "pointer",
                      }}
                    />
                  </button>
                )}
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { AccessDenied, getData, putData } from "../../api";
import Input from "../../components/Form/Input";
import PhoneNumberInput from "../../components/Form/Input/phoneNumberInput";
import TextArea from "../../components/Form/TextArea";
import Loader from "../../components/Loader";
import { ConformationPopup } from "../../components/Modal/conformationModal";
import NavBar from "../../components/Navbar";
import Buttons from "../../components/button/buttons";
import { errorCheck } from "./errorCheck";
import "./settings.css";

function Settings() {
  const [initLoading, setInitLoading] = useState(false);
  const [errorObject, setErrorObject] = useState({});
  const [communication, setCommunication] = useState({
    whatsappNotification: false,
    emailNotification: false,
  });
  const [openModal, setOpenModal] = useState({
    key: "",
    isOpen: false,
    message: "",
  });
  const [settingsField, setSettingsField] = useState({
    name: "",
    description: "",
    contactUs: "",
    email: "",
    phoneNumber: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
  });

  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    edit: loginPermission?.includes(menuId?.editSetting) ? true : false,
  };
  const getDataApi = async () => {
    setInitLoading(true);
    let url = "/settings/";
    const newData = await getData(url, {});
    const { data, statusCode } = newData;
    if (statusCode === 200) {
      let newData =data;
      setCommunication({
        whatsappNotification:newData?.whatsappNotification,
        emailNotification:newData?.emailNotification
      })
      delete newData.whatsappNotification
      delete newData.emailNotification
      setSettingsField(newData);
      setInitLoading(false);
    } else {
      setErrorObject({});
    }
  };
  const settingsArray = [
    {
      name: "Company Name*",
      key: "name",
      type: "input",
      inputType: "text",
      placeholder: "Enter Company Name",
      required: true,
    },
    {
      name: "Contact us*",
      key: "contactUs",
      type: "input",
      inputType: "text",
      placeholder: "Enter Contact us/Address",
      required: true,
    },
    {
      name: "Email id*",
      key: "email",
      type: "input",
      inputType: "email",
      placeholder: "Enter Email",
      required: true,
    },

    {
      name: "Phone Number*",
      key: "phoneNumber",
      type: "phoneNumberInput",
      inputType: "number",
      placeholder: "Enter Phone Number",
      required: true,
    },
    {
      name: "Description*",
      key: "description",
      type: "textArea",
      inputType: "text",
      placeholder: "Enter Description",
      required: true,
    },
    {
      name: "Whatsapp*",
      key: "whatsapp",
      type: "input",
      inputType: "text",
      placeholder: "Whatsapp Link",
      required: false,
    },
    {
      name: "Facebook*",
      key: "facebook",
      type: "input",
      inputType: "text",
      placeholder: "Facebook Link",
      required: false,
    },
    {
      name: "Instagram*",
      key: "instagram",
      type: "input",
      inputType: "text",
      placeholder: "Instagram Link",
      required: false,
    },
    {
      name: "LinkedIn*",
      key: "linkedIn",
      type: "input",
      inputType: "text",
      placeholder: "LinkedIn Link",
      required: false,
    },
  ];
  const handleInput = (key, value) => {
    delete errorObject[key];
    setSettingsField({ ...settingsField, [key]: value });
  };
  const handleChangeSwitch = (option) => {
    if (option?.key) {
      setOpenModal({
        key: option?.key,
        isOpen: true,
        message: communication[option?.key]
          ? option?.disabledMessage
          : option?.enabledMessage,
      });
    }

    // setCommunication({ ...communication, [option.key]: communication[option.key]?false:true });
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setInitLoading(true);
    let datas = { ...settingsField };
    delete datas["createdAt"];
    delete datas["isActive"];
    delete datas["updatedAt"];
    delete datas["_id"];
    const err = errorCheck(datas);
    if (Object.keys(err).length === 0) {
      let url = `/settings/${settingsField?._id}`;
      const newData = await putData(url, {}, datas);
      const { statusCode } = newData;
      if (statusCode === 200) {
        setInitLoading(false);
      } else {
        setInitLoading(false);
      }
    } else {
      setErrorObject(err);
      setInitLoading(false);
    }
  };
  const updateCommunication = async (payLoad) => {
    setInitLoading(true);
      let url = `/settings/${settingsField?._id}`;
      const newData = await putData(url, {}, payLoad);
      const { statusCode } = newData;
      if (statusCode === 200) {
        getDataApi()
        setOpenModal({
          isOpen: false,
          message: "",
          key: "",
        });
        setInitLoading(false);
      } else {
        setOpenModal({
          isOpen: false,
          message: "",
          key: "",
        });
        setInitLoading(false);
      }
  
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleClick(event);
    }
  };
  const renderInput = (name, key, value, error, inputType, placeholder) => {
    return (
      <Input
        placeholder={placeholder}
        errorMessage={error}
        label={name}
        name={name}
        value={value}
        type={inputType}
        isInvalid={!!error}
        disabled={access.edit ? false : true}
        min={inputType === "number" ? 0 : ""}
        size="w-50"
        onChange={(e) => handleInput(key, e.target.value)}
      />
    );
  };

  const renderTextArea = (name, key, value, error, placeholder) => {
    return (
      <TextArea
        errorMessage={error}
        placeholder={placeholder}
        label={name}
        name={name}
        value={value}
        isInvalid={!!error}
        size={"w-100"}
        disabled={access.edit ? false : true}
        onChange={(e) => handleInput(key, e.target.value)}
      />
    );
  };
  const renderPhoneNumberInput = (
    name,
    key,
    value,
    error,
    inputType,
    placeholder
  ) => {
    return (
      <PhoneNumberInput
        placeholder={placeholder}
        errorMessage={error}
        label={name}
        name={name}
        value={value}
        type={inputType}
        isInvalid={!!error}
        disabled={access.edit ? false : true}
        min={inputType === "number" ? 0 : ""}
        size="w-40"
        max="10"
        onChange={(e) => {
          if (e.target.value.length > 10) {
            return false;
          }
          handleInput(key, e.target.value);
        }}
      />
    );
  };
  useEffect(() => {
    getDataApi();
  }, []);
  const options = [
    {
      label: "Whatsapp",
      id: "custom-switch_sms",
      checked: true,
      key: "whatsappNotification",
      disabledMessage:
        "Please Note. Any message going in from Keydemand will be stopped. Are you sure you want to stop service?",
      enabledMessage:
        "Please Note. Any message going in from Keydemand will resume. Are you sure you want to start service?",
        toolTip:"Switching off the toggle allows the system to switch off all the messages that goes to all the users."
    },

    {
      label: "EMAIL",
      id: "custom-switch_email",
      checked: true,
      key: "emailNotification",
      disabledMessage:
        "Please Note. Any mail going in from Keydemand will be stopped. Are you sure you want to stop service?",
      enabledMessage:
        "Please Note. Any mail going in from Keydemand will resume. Are you sure you want to start service?",
        toolTip:" Switching off the toggle allows the system to switch off all the Mail that goes to all the users."
    },
  ];
  const handelCloseConfirmation = () => {
    setOpenModal({
      isOpen: false,
      message: "",
      key: "",
    });
  };
  const onSaveCommunicationData = (e) => {
    e.preventDefault();
    let payLoad ={
      [openModal.key]: communication[openModal.key] ? false : true,
    }
    updateCommunication(payLoad)
    // setCommunication((pre) => ({
    //   ...pre,
    //   [openModal.key]: communication[openModal.key] ? false : true,
    // }));
    
  };
  return (
    <>
      {loginPermission?.includes(menuId?.settings) ? (
        <div>
          {initLoading && <Loader className={"fixed"} />}
          <div className="privacy_text_header ms-4 d-flex">
            <NavBar />
          </div>
          <form onKeyDown={handleKeyPress}>
            <div className="row settingCustom">
              {settingsArray.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {item.type === "input"
                      ? renderInput(
                          item.name,
                          item.key,
                          settingsField[item.key],
                          errorObject[item.key],
                          item.inputType,
                          "",
                          item.placeholder
                        )
                      : item.type === "textArea"
                      ? renderTextArea(
                          item.name,
                          item.key,
                          settingsField[item.key],
                          errorObject[item.key],
                          item.placeholder
                        )
                      : item.type === "phoneNumberInput"
                      ? renderPhoneNumberInput(
                          item.name,
                          item.key,
                          settingsField[item.key],
                          errorObject[item.key],
                          item.inputType,
                          item.placeholder
                        )
                      : null}
                  </React.Fragment>
                );
              })}
              <div className="button_division">
                <Buttons
                  type="submit"
                  name={"Save"}
                  className=""
                  disabled={access.edit ? false : true}
                  onClick={handleClick}
                />
              </div>
            </div>
          </form>
          <div className="settingCustom mt-3 row ">
            <label className="f-18 fw-700">Manage communication</label>
            {options.map((option) => (
              <Row className="d-flex align-items-center" key={option.id}>
                <Col sm={3} md={1} className="f-16 fw-500 m-3">
                  {option.label}
                </Col>
                <Col  sm={3} md={1} className="d-flex">
                  <Form.Check
                    type="switch"
                    id={option.id}
                    checked={communication[option.key]}
                    className="switch-custom-toogle"
                    onChange={() => handleChangeSwitch(option)}
                    disabled={access.edit ? false : true}
                  />
                   <div className="tooltipCustom">
                    <AiOutlineInfoCircle size={15} className="colorInfo" />
                    <span className="tooltiptextCustom">
                     {option.toolTip}
                    </span>
                  </div>
                </Col>
                <Col>
               
                </Col>
              </Row>
            ))}

            
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
      {openModal?.isOpen && (
        <ConformationPopup
          onClose={handelCloseConfirmation}
          show={openModal?.isOpen}
          message={openModal?.message}
          warningYesClick={(e) => onSaveCommunicationData(e)}
        />
      )}
    </>
  );
}

export default Settings;

import  { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import PropertyResponse from "./propertyResponse";
import { getData, } from "../../api";
import "./style.scss";
import { ResposePropertyCard } from "../../components/Cards/PropertyCard/propertyCard";
import Loader from "../../components/Loader";
import NavBar from "../../components/Navbar";

const PropertyResponsePage = () => {
  const location = useLocation();
  
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [loading, setLoading] = useState(false)

  const propertyId = location?.state?.propertyId;
  const getPropertDetails = async () => {
    setLoading(true)
    let url =`/property/${propertyId}`;
    const response = await getData(url, {});
    const { status, data } = response;
    if (status === true) {
      setLoading(false)
      let propertyDetail = []
      propertyDetail.push(data)
      setPropertyDetails(propertyDetail)
    } else {
      setLoading(false)


      
    }
  };

  const btnList = [
   
  ]
  
  useEffect(() => {
    getPropertDetails()
    if(propertyId){
      localStorage.setItem("property-id", JSON.stringify(propertyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div className="property-response-container p-2">
      <NavBar/>
          {loading &&<Loader className="fixed"/>}

    <div className="demand-detail-view ">
      <ResposePropertyCard
        btnList={btnList}
        propertyData={propertyDetails}
        relevent={false}
        response={false}
      />

      <div className=" mt-5 ">
        <h3 className="f-6 fw-500">Response</h3>
        <PropertyResponse propertyId={propertyId} />
      </div>
    </div>
    </div>
  );
};
export default PropertyResponsePage;

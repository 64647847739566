import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import TextArea from "../../components/Form/TextArea";
import "./style.scss";
import TableSelect from "../../components/Table/selectTable";
import { getData, postData, postGetData } from "../../api";
import Button from "../../components/Form/Button";
import Search from "../../components/Search/search";
import Loader from "../../components/Loader";
import MainTable from "../../components/Table";
import { nameToLabel } from "../../utilities/commonFunction";
import { RenderMultiSelect } from "../Customer/CustomerForm/customComponents/inputGroups";
import MultiSelect from "../../components/select/dropDown";
import Checkbox from "../../components/Form/Checkbox";
export default function CreateNotification({
  action,
  onClose,
  notificationId,
  notificationData,
}) {
  const [offset, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [editId, setEditId] = useState();
  const [filter, setFilter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [subBtnLoading, setSubBtnLoading] = useState(false);
  const [senderList, setSenderList] = useState({
    isSms: true,
    isEmail: true,
  });

  const [formInputs, setFormInputs] = useState({
    // notificationType:"",
    scheduleDate: "",
    title: "",
    message: "",
    userType: "",
    brandType: "",
    user_ids: [],
  });
  const [key, setKey] = useState();
  const [disableStatus, setDisableStatus] = useState();
  const [dateTimePicker, setDateTimePicker] = useState(false);
  const [datepickerError, setDatePickerError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [selectTable, showSelectTable] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [search, setSearch] = useState("");
  const [regionList, setRegionList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [region, setRegion] = useState([]);
  const [city, setCity] = useState([]);
  const [customer, setCustomer] = useState("");

  useEffect(() => {
    getRegions();
    getCities();
  }, []);
  const getRegions = async () => {
    let url = "/region/regions";
    const apiDatas = await getData(url);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      let regionArray = nameToLabel(data);
      setRegionList(regionArray);
    } else {
      setRegionList([]);
    }
  };

  const getCities = (value) => {
    let url = "/country/getCitiesByCondition";
    // let listedId = stateId.map((item) => item.value);
    let payload = {
      type: "all",
      isState: true,
      // isRegion: true,
      // regionIds: ['64147926dee4fc39989e2767']
    };

    // if (value?.length > 0) {
    //   const regionIds = value.map((reg) => {
    //     return reg?.value;
    //   });

    //   payload = {
    //     isRegion: true,
    //     regionIds: regionIds,
    //   };
    // }
    postGetData(url, {}, payload).then((response) => {
      if (response?.status) {
        let cityArray = [];
        response?.data?.map((data) => {
          cityArray.push({
            label: data?.name,
            value: data?._id,
          });
          return data;
        });
        setCityList(cityArray);
      }
    });
  };

  const onChange = (value, key) => {
    if (key === "city") {
      setCity(value);
    }
    if (key === "region") {
      setRegion(value);
    }
  };
  const errorCheck = (checkdata = {}) => {
    let errors = {};

    let requiredField = "This Field is Required";

    if (checkdata.notificationType === "scheduled") {
      if (checkdata.scheduleDate === "") {
        errors.scheduleDate = requiredField;
      }
    }
    if (checkdata.title === "") {
      errors.title = requiredField;
    }
    if (checkdata.message === "") {
      errors.message = requiredField;
    }
    if (checkdata.userType === "") {
      errors.userType = requiredField;
    }
    if (checkdata.userType === "send it to groups") {
      if (checkdata.brandType === "") {
        errors.brandType = requiredField;
      }
    }
    if (checkdata.userType === "send it to selected user") {
      if (checkdata.user_ids.length === 0) {
        errors.user_ids = "Please select atleast one user";
      }
    }

    return errors;
  };

  const NotificationSenders = [
    {
      name: "isSms",
      label: "WhatsApp",
    },
    {
      name: "isEmail",
      label: "Email",
    },
  ];

  const userType = [
    {
      _id: "send it to selected user",
      name: "Send it to selected user",
    },
    {
      _id: "send it to groups",
      name: "Send it to groups",
    },
  ];
  const userGroups = [
    {
      _id: "Enterprise Buyer",
      name: "Enterprise Buyer",
    },
    {
      _id: "Enterprise Seller",
      name: "Enterprise Seller",
    },
    {
      _id: "Agent",
      name: "Agent",
    },
  ];

  const customerType = [
    {
      _id: "Enterprise Buyer",
      name: "Enterprise Buyer",
    },
    {
      _id: "Enterprise Seller",
      name: "Enterprise Seller",
    },
    {
      _id: "Agent",
      name: "Agent",
    },
  ];

  const columns = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "contactPerson",
      text: "Contact Person",
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "name",
      text: "Enterprise Name",
      formatter: (cell, row, rowIndex) => {
        return row?.name ? row?.name : row?.contactPerson;
      },
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cell, row, rowIndex) => {
        return row?.userId?.roles[0]?.name;
      },
    },
    // {
    //     dataField: "rm",
    //     text: "Relationship Manager",
    //     formatter: (cellContent, row, rowIndex) => {
    //         if (row.rm === null || row.rm === "" || !("rm" in row)) {
    //             return "-"
    //         }
    //         else if (row.rm && row.rm.name) {
    //             return row.rm.name
    //         }
    //     }
    // },
    // {
    //     dataField: "action",
    //     text: "Action",
    //     formatter: userButtonFormatterBuyer,
    // },
  ];
  const getSelectedUsers = () => {
    setIsLoading(true);
    let url = `/notification/getUsers?notificationId=${notificationId}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (filter) {
      url += `&type=${filter}`;
    }

    if (filter !== "Enterprise Buyer") {
      if (city.length > 0) {
        const cities = city.map((obj) => obj.label).join(",");
        url += `&cityFilter=${cities}`;
      }
    }

    if (filter === "Enterprise Buyer") {
      if (region.length > 0) {
        const regions = region.map((obj) => obj.label).join(",");
        url += `&regionFilter=${regions}`;
      }
    }
    getData(url).then((response) => {
      if (response.status) {
        setIsLoading(false);
        setPageCount(Math.ceil(response.data.totalCount / perPage));
        setTotalCount(response.data.totalCount);
        setUserListData(response.data.users);
      } else {
        setIsLoading(false);
        setPageCount(0);
        setUserListData([]);
      }
    });
  };

  const getCustomerList = async () => {
    setIsLoading(true);
    let url = `/notification/getUsers?pageNo=${offset}&limit=${perPage}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (filter) {
      url += `&type=${filter}`;
    }

    if (filter !== "Enterprise Buyer") {
      if (city.length > 0) {
        const cities = city.map((obj) => obj.label).join(",");
        url += `&cityFilter=${cities}`;
      }
    }

    if (filter === "Enterprise Buyer") {
      if (region.length > 0) {
        const regions = region.map((obj) => obj.label).join(",");
        url += `&regionFilter=${regions}`;
      }
    }

    await getData(url).then((response) => {
      if (response.status) {
        setIsLoading(false);
        setPageCount(Math.ceil(response.data.totalCount / perPage));
        setTotalCount(response.data.totalCount);

        let customerList = response.data.users.map((item) => {
          return { ...item, keyId: item?.userId?._id };
        });
        setUserListData(customerList);
      } else {
        setIsLoading(false);
        setPageCount(0);
        setUserListData([]);
      }
    });
  };
  const resetDate = () => {
    setStartDate();
    setFormInputs((prev) => ({
      ...prev,
      scheduleDate: null,
    }));
    setDatePickerError(false);
  };

  const reset = () => {
    setCity([]);
    setRegion([]);
    setCustomer("");
    setSenderList({
      isSms: true,
      isEmail: true,
    });
    setFormInputs((prev) => {
      return {
        ...prev,
        title: "",
        message: "",
        user_ids: [],
      };
    });
    setFilter("");
    setSenderList({
      isSms: true,
      isEmail: true,
    })
  };

  const updateDate = (date) => {
    setStartDate(date);

    setFormInputs((prev) => ({
      ...prev,
      scheduleDate: moment(date).format("YYYY-MM-DD HH:mm:ss"),
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = errorCheck(formInputs);
    if (Object.keys(errors).length === 0) {
      createNoficationFn();
    } else {
      setErrorMessage(errors);
    }
  };

  const createNoficationFn = async () => {
    let url = "/notification/addNotification";

    let payLoad = {
      title: formInputs.title,
      message: formInputs.message,
      selectedUsers: formInputs.userType === "send it to selected user" ? true : false,
      ...senderList
    };
    if (!(formInputs.userType === "send it to selected user")) {
      payLoad.userType = formInputs.brandType;
      delete payLoad["userIds"];
    } else if (formInputs.userType === "send it to selected user") {
      payLoad.userIds = formInputs.user_ids;
      payLoad = { ...payLoad };
    }
    setSubBtnLoading(true);
    const adminData = await postData(url, {}, payLoad);
    const { statusCode } = adminData;
    if (statusCode === 200) {
      setSubBtnLoading(false);
      onClose();
    } else {
      setSubBtnLoading(false);
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    delete errorMessage[`${name}`];

    setFormInputs((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "userType" && value === "send it to selected user") {
      setFormInputs((prev) => ({
        ...prev,
        [name]: value,
        brandType: "",
      }));

      setSenderList({
        isSms: true,
        isEmail: true,
      })
    }

    if (name === "userType" && value === "send it to groups") {
      reset();
    }

    if (name === "notificationType") {
      if (value === "scheduled") {
        let dt = new Date();
        setStartDate(dt);
        setDateTimePicker(true);
        setFormInputs((prev) => ({
          ...prev,
          scheduleDate: moment(dt).format("YYYY-MM-DD HH:mm:ss"),
        }));
      } else {
        setDateTimePicker(false);
        setStartDate(null);
        setFormInputs((prev) => ({
          ...prev,
          scheduleDate: null,
        }));
      }
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  

  const handleCustomerTypeChange = (value) => {
    handelfilter(value);
    setCustomer(value);
    setFormInputs((prev) => {
      return {
        ...prev,
        user_ids: []
      }
    })

    
    if (value === "Enterprise Buyer") {
      setCity([]);
    } else {
      setRegion([]);
    }
  };

  const getSelectedRow = (data) => {
    setSelectedRow(data);
    setFormInputs((prev) => ({
      ...prev,
      user_ids: [...data],
    }));
  };
  const handelfilter = (filterValue) => {
    setFilter(filterValue);
    setOffset(1);
  };

  const tableProps = {
    data: userListData,
    columns,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  const handleSenderListChange = (e, name) => {
    setSenderList((prev) => {
      return {
        ...prev,
        [name]: e.target.value === "false" ? true : false,
      };
    });
  };

  useEffect(() => {
    if (action === "view") {
      delete notificationData?._id;
      delete notificationData?.createdAt;
      delete notificationData?.isActive;
      delete notificationData?.isRead;
      delete notificationData?.updatedAt;
      if (notificationData.selectedUsers === true) {
        notificationData.userType = "send it to selected user";
      } else {
        notificationData.brandType = notificationData?.userType;
        notificationData.userType = "send it to groups";
      }
      setFormInputs(notificationData);
      getSelectedUsers();
    } else {
      getCustomerList();
    }
  }, [perPage, offset, search, filter, city, region]);

  return (
    <div className="notification-container-form">
      <div>
        <div className="mt-3">
          {dateTimePicker && (
            <Form.Group
              controlId="targetTimed"
              className="datePickerBox_Cs mt-3"
            >
              <Form.Label className="required text-black f-14 fw-700">
                Targeted Date & Time
              </Form.Label>
              <br />
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  date ? updateDate(date) : resetDate();
                }}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                required={true}
                autoComplete="off"
                placeholderText="MM/dd/yyyy hh:mm AM/PM"
                minDate={new Date()}
                disabled={action === "view" ? true : false}
              />
              {errorMessage?.scheduleDate && (
                <div className="err-feedback">{errorMessage?.scheduleDate}</div>
              )}

              {datepickerError && formInputs.scheduleDate && (
                <div className="error-msg">Please select a future time</div>
              )}
            </Form.Group>
          )}
        </div>

        <Input
          label="Notification Title *"
          errorMessage={errorMessage?.title && errorMessage?.title}
          name="title"
          type="text"
          value={formInputs && formInputs.title}
          onChange={handleInput}
          disabled={action === "view" ? true : false}
          isInvalid={errorMessage?.title && true}
        />

        <TextArea
          label="Message *"
          name="message"
          errorMessage={errorMessage?.message && errorMessage?.message}
          value={formInputs && formInputs.message}
          onChange={handleInput}
          disabled={action === "view" ? true : false}
          isInvalid={errorMessage?.message && true}
        />
        <div className="row">
          <div
            className={`${
              formInputs?.userType === ""
                ? "col col-12"
                : "col col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6"
            }`}
          >
            <Select
              label={"Select Users *"}
              errorMessage={errorMessage?.userType && errorMessage?.userType}
              disabled={action === "view" ? true : false}
              options={userType}
              name={"userType"}
              value={formInputs.userType && formInputs?.userType}
              onChange={handleInput}
              isInvalid={errorMessage?.userType && true}
            />
          </div>
          {formInputs?.userType === "send it to selected user" &&
            action !== "view" && (
              <>
                <div className="col col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <Select
                    label={"Select Customer Type"}
                    errorMessage={
                      errorMessage?.customerType && errorMessage?.customerType
                    }
                    value={customer}
                    disabled={action === "view" ? true : false}
                    options={customerType}
                    name={"customerType"}
                    onChange={(e) => {
                      handleCustomerTypeChange(e.target.value);
                    }}
                    isInvalid={errorMessage?.customerType && true}
                  />
                </div>
                {customer === "Enterprise Buyer" && (
                  <div className="col col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <MultiSelect
                      onChange={(val) => onChange(val, "region")}
                      isMulti={true}
                      disabled={action === "view" ? true : false}
                      name="region"
                      size={"w-100"}
                      options={regionList}
                      value={region}
                      lable="Filter By Region"
                      placeholder="Select Region"
                      // errormessage={error}
                    />
                  </div>
                )}
                {customer === "Enterprise Seller" || customer === "Agent" ? (
                  <div className="col col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <MultiSelect
                      onChange={(val) => onChange(val, "city")}
                      isMulti={true}
                      disabled={action === "view" ? true : false}
                      name="city"
                      size={"w-100"}
                      options={cityList}
                      value={city}
                      lable="Filter By City"
                      placeholder="Select City"
                      // errormessage={error}
                    />
                  </div>
                ) : null}
              </>
            )}

          {formInputs?.userType === "send it to groups" && (
            <>
              <div className="col col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Select
                  label={"Select Group *"}
                  errorMessage={
                    errorMessage?.brandType && errorMessage?.brandType
                  }
                  options={userGroups}
                  name={"brandType"}
                  value={formInputs.brandType && formInputs?.brandType}
                  onChange={handleInput}
                  isInvalid={errorMessage?.brandType && true}
                  disabled={action === "view" ? true : false}
                />
              </div>

                {action !== "view" && (
                  <div>
                    <Form.Label id="sender" className="fw-700 f-14 text-black">
                      Notification Send on:
                    </Form.Label>

                    <div
                      className="d-flex align-items-center justify-content-start  flex-wrap"
                      style={{ marginLeft: "-30px" }}
                    >
                      {NotificationSenders.map((sender) => {
                        return (
                          <Checkbox
                            label={sender?.label}
                            name={sender?.name}
                            onChange={(e) =>
                              handleSenderListChange(e, sender?.name)
                            }
                            value={senderList[sender?.name]}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
            </>
          )}
        </div>

        {formInputs?.userType === "send it to selected user" ? (
          <div className="mt-3">
            <div className={`d-flex align-items-center ${action !== "view" ? 'justify-content-between ' : 'justify-content-end mb-3'}  mt-1 flex-wrap`}>
              <>
                {action !== "view" && (
                  <div>
                    <Form.Label id="sender" className="fw-700 f-14 text-black">
                      Notification Send on:
                    </Form.Label>

                    <div
                      className="d-flex align-items-center justify-content-start  flex-wrap"
                      style={{ marginLeft: "-30px" }}
                    >
                      {NotificationSenders.map((sender) => {
                        return (
                          <Checkbox
                            label={sender?.label}
                            name={sender?.name}
                            onChange={(e) =>
                              handleSenderListChange(e, sender?.name)
                            }
                            value={senderList[sender?.name]}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                <Search onChange={handleSearch} />
                {/* <div className="filter-container w-100 m-0 mt-1">
                <div className="d-flex justify-content-end w-50">
                  <select
                    className="form-select filterBGColor w-100"
                    key="admin"
                    name="admin"
                    id="admin"
                    onChange={(e) => handelfilter(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Enterprise Buyer">Enterprise Buyer</option>
                    <option value="Enterprise Seller">Enterprise Seller</option>
                    <option value="Agent">Agent</option>
                  </select>
                </div>
              </div> */}
              </>
            </div>
            {action !== "view" && (
              <div className="p-1">
                {`Selected Users: ${formInputs?.user_ids.length}`}
              </div>
            )}
            {errorMessage?.user_ids && (
              <div className="error-msg">
                {errorMessage?.user_ids ? errorMessage?.user_ids : ""}
              </div>
            )}

            {action === "view" ? (
              <MainTable {...tableProps} />
            ) : (
              selectTable && (
                <TableSelect
                  {...tableProps}
                  selectedRow={
                    editId &&
                    formInputs &&
                    formInputs.user_ids &&
                    !formInputs.user_ids.includes("all")
                      ? formInputs.user_ids
                      : []
                  }
                  getSelectedRow={getSelectedRow}
                  disabled={key === "View" ? disableStatus : false}
                  customer = {customer}
                />
              )
            )}
          </div>
        ) : (
          ""
        )}
        <div className="mt-3 text-end">
          {action === "view" ? (
            ""
          ) : (
            <Button
              className="submit-btn"
              onClick={handleSubmit}
              loading={subBtnLoading}
            >
              Send
            </Button>
          )}
        </div>
      </div>

      {isLoading && <Loader className={"fixed"} />}
    </div>
  );
}

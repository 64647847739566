import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import "./index.scss";
// import Header from "../../../Components/Header/Header";
import Profilepic from "../../../assets/images/profilePic.jpg";

//api
import { AccessDenied, getData } from "../../../api";
import NavBar from "../../../components/Navbar";
//loader
import moment from "moment";
import NoImage from "../../../assets/images/noImage.jpg";
import Loader from "../../../components/Loader";
import { camelCaseConversion } from "../../../custom/camelCaseConversion";
import Property from "./Properties/properties";
import Cities from "./cities";

function AgentPage() {
  //   const [demandList, setDemandList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [brandData] = useState({});
  const { slug } = useParams()

  const location = useLocation();
  const [customerData, setCustomerData] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  let agentIdStore = useSelector((state) => state.login.agentId);

  const agentId = location.state ? location?.state?.id : agentIdStore;
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  const access = {
    addAgentProperty: loginPermission?.includes(menuId.addAgentProperty),
    agentImportsAndExports: loginPermission?.includes(
      menuId?.agentImportsAndExports
    ),
    view: loginPermission?.includes(menuId?.viewProperty) ? true : false,
    edit: loginPermission?.includes(menuId?.editProperty) ? true : false,
  };

  const getCustomerData = async () => {
    // let url = `/brand/${agentId}`;
    let url = `/brand/getBrandByName/${slug}`
    setPageLoad(false);
    await getData(url, {}, {}).then((response) => {
      if (response.status) {
        let removeObject = [
          "isActive",
          "discount",
          "type",
          "zone",
          "_id",
          "rm",
          "updatedAt",
          "createdAt",
        ];
        removeObject.map((item) => {
          if (response?.data?.hasOwnProperty("subscriptionPlanId")) {
            return delete response?.data?.subscriptionPlanId[item];
          }
          return item;
        });

        setCustomerData(response.data);
        localStorage.setItem("customerData", JSON.stringify(response.data));

        setIsLoading(false);
        setPageLoad(true);
      } else {
        setPageLoad(true);
      }
    });
  };

  useEffect(() => {
    getCustomerData();
  }, []);

  return (
    <>
      {loginPermission.includes(menuId.viewAgentCustomers) ? (
        <>
          {pageLoad ? (
            <>
              <NavBar />
              {Object.keys(customerData).length > 0 ? (
                <div className="brandPage-container pb-5">
                  {customerData?.image && customerData?.image?.viewUrl ? (
                    <img
                      className="w-100 brand-image"
                      src={customerData?.image?.viewUrl}
                      alt="buyerImage"
                    />
                  ) : (
                    <div className="w-100 brand-image"></div>
                  )}
                  <div className="brandPage-content wrapAgent">
                    <div className="d-flex profile-container imgnameWrap">
                      <img
                        src={
                          customerData?.logo?.viewUrl
                            ? customerData?.logo?.viewUrl
                            : Profilepic
                        }
                        className="brand-profile"
                        alt="images"
                      />
                      <div className="profile-content">
                        <label className="text-heading f-35 fw-500">
                          {customerData?.contactPerson}
                        </label>
                      </div>
                    </div>
                    <div className="totalPropertyWrapper">
                      <p>
                        <span>
                          <b>
                            {customerData?.overallCount
                              ? customerData?.overallCount
                              : 0}
                          </b>
                        </span>
                        <span>No. of Properties</span>
                      </p>
                    </div>
                  </div>
                  <div style={{ marginBottom: "35px" }} className="px-4 pb-4">
                    <label className="text-heading mb-0 f-28 fw-500">
                      About
                    </label>
                    <p className="word-wrap">{customerData?.about}</p>
                  </div>
                  {/*  */}
                  { }
                  {customerData?.operatingCities?.length > 0 && (
                    <div style={{ marginBottom: "35px" }} className="px-4 pb-4">
                      <label className="text-heading mb-0 f-28 fw-500">
                        Localities they work
                      </label>{" "}
                      <br />
                      <div className="operaWrapper">
                        {customerData?.operatingCities?.map((item, ind) => {
                          return (
                            <p className="operaWrapper-para" key={ind}>
                              <b>{item?.name}</b>
                              <span>{`${item?.propertyCount} + Properties`}</span>
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {customerData?.subscriptionPlanId ? (
                    Object.keys(customerData.subscriptionPlanId).length > 0 ? (
                      <div
                        style={{ marginBottom: "35px" }}
                        className="px-4 pb-4 subscription-card"
                      >
                        <label className="text-heading mb-0 f-28 fw-500">
                          Subscription
                        </label>
                        <div className="row mt-4 mb-4">
                          {Object.entries(customerData.subscriptionPlanId).map(
                            ([key, value], i) => {
                              let suffix =
                                key === "discount"
                                  ? "%"
                                  : key === "validityDays"
                                    ? " days"
                                    : "";
                              let prefix =
                                key === "subscriptionAmount" ? "₹" : "";
                              if (
                                key === "isFreeTrial" ||
                                key === "discountAmount"
                              ) {
                                return;
                              }
                              return (
                                <>
                                  {key === "price" ? (
                                    ""
                                  ) : (
                                    <div
                                      className="subscription-detail-columns"
                                      key={i}
                                    >
                                      <p className="subscription-key">
                                        {key === "propertyOrDemand"
                                          ? "Property Uploads"
                                          : key === "featuredPositioning"
                                            ? "Featured property"
                                            : key === "region"
                                              ? "Zones selected"
                                              : key === "expiredate"
                                                ? "Valid till"
                                                : key === "demandMails"
                                                  ? "Demand alerts"
                                                  : key === "noOfRegion"
                                                    ? "No of zone selected"
                                                    : camelCaseConversion(key)}
                                      </p>
                                      {key === "region" ? (
                                        <p className="subscription-value word-wrap">
                                          {value.map((item, i) => {
                                            return (
                                              <li key={i}>{item.label}</li>
                                            );
                                            // i === value.length - 1 ? item.label : item.label + ", "
                                          })}
                                        </p>
                                      ) : (
                                        <p className="subscription-value word-wrap">
                                          {key === "propertyOrDemand"
                                            ? customerData?.agentPropertyCount +
                                            "/" +
                                            value
                                            : key === "expiredate"
                                              ? moment(value).format("DD-MM-YYYY")
                                              : key === "subscriptionDate"
                                                ? moment(value).format("DD-MM-YYYY")
                                                : key === "subscriptionAmount"
                                                  ? customerData?.subscriptionPlanId
                                                    ?.price
                                                    ? "₹ " +
                                                    customerData?.subscriptionPlanId
                                                      ?.price
                                                    : "₹ " + value
                                                  : prefix
                                                    ? prefix + " " + value
                                                    : suffix
                                                      ? value + " " + suffix
                                                      : key === "rm"
                                                        ? value?.name
                                                        : value}{" "}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <div className="px-4">
                    <Property
                      access={access}
                      featuredPositioningStatus={
                        customerData?.subscriptionPlanId?.featuredProperty
                      }
                      customerUserId={customerData?.userId?._id}
                      image={
                        customerData.logo
                          ? customerData.logo.viewUrl
                            ? customerData.logo.viewUrl
                            : NoImage
                          : NoImage
                      }
                      developerId={agentId}
                      type="agent"
                    />
                  </div>
                  <div>
                    <div className="my-4">
                      {brandData?.cities ? (
                        <Cities data={brandData?.cities} />
                      ) : null}
                    </div>
                  </div>
                  {isLoading && <Loader className={"fixed"} />}
                </div>
              ) : (
                <div>No data found</div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
}

export default AgentPage;

export const monthYear=[
    {
        name:'Monthly',
        value:'monthly',
    },
    {
        name:'Yearly',
        value:'yearly',
    },
    {
        name:'Custom',
        value:'custom',
    }
]

export const monthYeaPie=[
    {
        name:'Monthly',
        value:'monthly',
    },
    {
        name:'Yearly',
        value:'yearly',
    },
    
]


export const type=[
    {
        name:'Agent',
        value:'agent',
    },
    {
        name:'Developers',
        value:'developers',
    },
    {
        name:'Brands',
        value:'brands',
    },
]
export const typeData = [
    {
        name:'seller',
        value:'seller',
    },
    {
        name:'buyer',
        value:'buyer',
    },
]
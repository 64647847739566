import { useState, useEffect } from "react";
import FormModal from "../../components/Modal";
import Select from "../../components/Form/Select";
import SingleImageDropzone from "../../components/dragAndDrop/singleFileDrop";
import Button from "../../components/Form/Button";
import { postUploadData, getData, putData } from "../../api/index";
import Loader from "../../components/Loader";

export function AddBanner({ onClose, show, label, editValue }) {
  const [errorMessage, setErrorMessage] = useState({});
  const [formValues, setFormValues] = useState({
    brandId: "",
    image: "",
    brandName: "",
    type: "Developer",
  });
  const [imageData, setImageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formSubmitloading, setformSubmitLoading] = useState(false);
  const [developerList, setdevloperList] = useState([]);
  const [initLoading, setInitLoading] = useState(true);

  const handelOnSelect = (value, name) => {
    delete errorMessage[name];
    let brandIdName = developerList.find((el) => el._id === value);
    let data = { ...formValues };
    data["brandName"] = brandIdName?.name;
    data["brandId"] = brandIdName?._id ? brandIdName?._id : "";
    setFormValues(data);
  };

  const checkErrorMessage = (data) => {
    let error = {};
    let requriedMessage = "This Field Is Required";

    if (data.brandName === "" || data.brandId === "") {
      error.brandName = requriedMessage;
    }
    if (data.image === "") {
      error.image = requriedMessage;
    }

    return error;
  };

 

  const handleSubmitForm = async () => {
    setformSubmitLoading(true);
    let url = `/contentManagement/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}`;

    let payLoad = {
      bannerImage: {
        ...formValues,
      },
    };
    const zoneData = await putData(url, {}, payLoad);
    const {  status } = zoneData;

    if (status) {
      setformSubmitLoading(false);
      onClose("updated");
    } else {
      setformSubmitLoading(false);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const checkError = checkErrorMessage(formValues);
    setErrorMessage(checkError);

    let NoError = Object.values(checkError).every((v) => v.length === 0);
    if (NoError) {
      handleSubmitForm();
    }
  };

  const handleImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", file[0]);
    let url = `/contentManagement/file-upload/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}?module=banner`;
    const imageDataUpload = await postUploadData(url, {}, formData);
    const { data, status } = imageDataUpload;
    if (status) {
      setLoading(false);
      setImageData(data);
      setFormValues((pre) => ({ ...pre, image: data?.url }));
      setErrorMessage((pre) => ({ ...pre, image: "" }));
    } else {
      setLoading(false);
    }
  };
  const getBrandLIst = async () => {
    setInitLoading(true);
    setLoading(true);
    let url ="/brand/?type=Developer&pageNo=0&limit=0";
    const getbrand = await getData(url, {});
    const { data, status } = getbrand;
    if (status) {
      setLoading(false);
      setInitLoading(false);

      setdevloperList(data?.customerList);
    } else {
      setInitLoading(false);

      setLoading(false);
    }
  };
  useEffect(() => {
    getBrandLIst();

    if (label === "Edit") {
      setFormValues((pre) => {
        return {
          ...pre,
          ...{
            brandId: editValue.brandId,
            image: editValue.image,
            brandName: editValue.brandName,
            type: editValue?.type,
            _id: editValue?._id,
          },
        };
      });

      setImageData({
        viewUrl: editValue.viewUrl,
      });
    }
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [formValues]);

  return (
      <FormModal
        onClose={onClose}
        show={show}
        heading={`${label}  Banner`}
        size={"md"}
        loading={initLoading}
        loader="fixed"
      >
        <div className="mt-1">
          <div className="select-head">
            <form
              noValidate
              // validated={validated}
              onSubmit={handleSubmit}
            >
              <Select
                errorMessage={
                  errorMessage?.brandName && errorMessage?.brandName
                }
                label={"Select Developer Name*"}
                options={developerList}
                name={"devloper"}
                isInvalid={errorMessage?.brandName && true}
                value={formValues?.brandId && formValues?.brandId}
                onChange={(e) => {
                  handelOnSelect(e.target.value, "brandName");
                }}
              />
              <div className="w-100">
                <label
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#000000",
                    marginBottom: "12px",
                  }}
                >
                  Banner Image*
                </label>
              </div>

              <SingleImageDropzone
                loading={loading}
                onHandelImage={handleImage}
                data={imageData}
              />
              <div className="error-msg">{errorMessage.image}</div>

              <div className="d-flex justify-content-end  gap-3 mt-4 ">
                <Button type={"button"} className={"pre-btn"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  className="next-btn"
                  loading={formSubmitloading}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>

        {loading && <Loader className={"fixed"} />}
      </FormModal>
  );
}

export const AdminWarningPopup = ({
  onClose,
  show,
  message,
  warningYesClick,
}) => {
  return (
    <FormModal
      onClose={onClose}
      show={show}
      heading={"Confirmation"}
      size={"md"}
      loading={false}
      loader="fixed"
    >
      <p>{message}</p>
      <div className="btn-container">
        <Button className={"btn-outline-color-primary"} onClick={onClose}>
          No
        </Button>
        <Button onClick={warningYesClick} className="btn-color-primary">
          Yes
        </Button>
      </div>
    </FormModal>
  );
};

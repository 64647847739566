import React, { useEffect, useState } from "react";
import {  Container, Row, Col } from "react-bootstrap";
import {
  RenderInput,
  RenderRangeInput,
  RenderSelect,

  RenderMultiSelect,
} from "../inputGroups";
import { getData } from "../../../api";
import Checkbox from "../../../components/Form/Checkbox";

export default function PostCoworkFormats({
  propertyType,
  fields,
  initialValues,
  onChangeInput,
  error,
  action,
  disableEdit,
  responseStatus=false,
  setCoworkFormatData,
  coworkFormatData,
  initCoworkFormatData,
  demandFormatDetailDivRef
}) {
  // const navigate = useNavigate();
  const [amentities, setAmentities] = useState([]);
  const [coworkFields, setCoworkFields] = useState([]);
  const setSelectOption = (key, options) => {
    fields?.forEach((element) => {
      if (element.name === key) {
        element.option = options;
      }
    });
  };
  useEffect(() => {
    if (amentities?.length > 0) {
      setSelectOption("otherAmenities", amentities);
    }
  }, [amentities]);
  const handleAmenitey = async () => {
    let url = `/amenity?type=${propertyType?.name}`;
    const apiDatas = await getData(url, {}, {});
    const { statusCode, data } = apiDatas;
    if (statusCode === 200) {
      if (data?.length > 0) {
        let amenitieschange = data.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        setAmentities(amenitieschange);
      }
    }
  };

  const handleCoworkData = async() => {
    let url = `/coWorking/getCoWorking`
    const apiDatas = await getData(url, {}, {});
    const { statusCode, data } = apiDatas;
    if (statusCode === 200) {
      if(action !== 'edit') {
        if(coworkFormatData.length < 5) {
          data?.map((item) => {
            setCoworkFormatData((prev) => {
              return [...prev, {_id: item?._id, isRequired: false, name:item?.name}]
            })
          })
        }
      }
      setCoworkFields([...data])
      
    }
    
  }

  useEffect(() => {
    handleCoworkData()
  }, [])
  useEffect(() => {
    // if (propertyType?.name) {
    //   handleAmenitey();
    // }
    if(action === 'edit') {
      setCoworkFormatData(initCoworkFormatData)
    }
    window.scrollTo(0, 0);
  }, [initCoworkFormatData]);

  const handelChangeRangeInputs = (value, name, id, type) => {
    if(action === 'edit') {
      const filtredData = coworkFormatData.filter((data) => data?._id?.name ===  id)
      console.log({id, filtredData})
      if(filtredData.length > 0) {
        var newData = coworkFormatData.map((data) => {
          if(data?._id?.name === id) {
            if(type === 'Min') {
              return {...data, budgetMin: value}
            } else {
              return {...data, budgetMax: value}
            } 
          }else {
            return data
          }
        })
        console.log({newData})
        setCoworkFormatData([...newData])
      } else {
        console.log('hello')
        const  newfield = coworkFields.filter((data) => data?.name === id)
        if(type === 'Min') {
          setCoworkFormatData((prev) => {
            return [...prev, { _id: {_id: newfield[0]?._id, name: newfield[0]?.name}, budgetMin: value}]
          })
        } else {
          setCoworkFormatData((prev) => {
            return [...prev, { _id: {_id: newfield[0]?._id, name: newfield[0]?.name}, budgetMax: value}]
          })
        }
        
      }
      
    } else {
      var newData = coworkFormatData.map((data) => {
        if(data?.name === id) {
          if(type === 'Min') {
            return {...data, budgetMin: value}
          } else {
            return {...data, budgetMax: value}
          } 
        }else {
          return data
        }
      })
      setCoworkFormatData([...newData])
    }
    onChangeInput(value, name);
  }
  const handelChangeInputs = (value, name, id) => {
    if(action === 'edit') {
      const filtredData = coworkFormatData.filter((data) => data?._id?.name ===  id)
      if(filtredData.length > 0) {
        var newData = coworkFormatData.map((data) => {
          if(data?._id?.name === id) {
            return {...data, noOfSeat: value}
          }else {
            return data
          }
        })
        setCoworkFormatData([...newData])
      } else {
        const  newfield = coworkFields.filter((data) => data?.name === id)
        setCoworkFormatData((prev) => {
          return [...prev, { _id: {_id: newfield[0]?._id, name: newfield[0]?.name}, noOfSeat: value}]
        })
      }
      
    } else {
      var newData = coworkFormatData.map((data) => {
        if(data?.name === id) {
          return {...data, noOfSeat: value}
        }else {
          return data
        }
      })
      setCoworkFormatData([...newData])
    }
    onChangeInput(value, name);
  };
  const handelChangeSelect = (value, name) => {
    onChangeInput(value, name);
  };
  const handelChangeCheckBox = (value, name, id) => {
    if(action === 'edit') {
      const filtredData = coworkFormatData.filter((data) => data?._id?.name ===  id)
      if(filtredData.length > 0) {
        var newData = coworkFormatData.map((data) => {
          if(data?._id?.name === id) {
            return {...data, isRequired: value}
          }else {
            return data
          }
        })
        setCoworkFormatData([...newData])
      } else {
        const  newfield = coworkFields.filter((data) => data?.name === id)
        setCoworkFormatData((prev) => {
          return [...prev, { _id: {_id: newfield[0]?._id, name: newfield[0]?.name}, isRequired: value}]
        })
      }
      
    }else {
      var newData = coworkFormatData.map((data) => {
        if(data?.name === id) {
          return {...data, isRequired: value}
        }else {
          return data
        }
      })
      setCoworkFormatData([...newData])
    } 
    onChangeInput(value, name);
  };
  return (
    <div ref={demandFormatDetailDivRef}>
      <Container fluid="md" className=" invidual-form">
        <Row>
          <Col md="12">
            <Row>
              {fields?.length > 0 &&
                fields.map((item, index) => {
                  const insertCol =
                    Number(index) % 2 === 0 ? <Col md="2"></Col> : null;
                  if (item.type === "input") {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="my-2">
                          <RenderInput
                            value={initialValues[item.name]}
                            name={item.name}
                            label={item.label}
                            onChange={(e) =>
                              handelChangeInputs(e.target.value, item.name, item?.id)
                            }
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            type={item.inputType}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried,
                                      item?.id
                                    )
                            }
                            checkBoxValue={initialValues[item.requried]}
                            placeholder={item.placeholder}
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                            requried={item.defaultChecked}
                            checboxDisabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "rangeInput") {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-center my-2">
                          <Row className=" ms-sm-5 ms-md-0">
                            <label
                              style={{
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "14px",
                                color: "#000000",
                                marginBottom: "0px",
                              }}
                            >
                              {item.label}
                            </label>
                            {item.rangefields.map((range, index) => {
                              return (
                                <>
                                  <Col
                                    sm="5"
                                    className=" d-flex justifu-content-center align-items-center "
                                  >
                                    <RenderRangeInput
                                      value={initialValues[range.name]}
                                      name={range.name}
                                      label={range.label}
                                      onChange={(e) =>
                                        handelChangeRangeInputs(
                                          e.target.value,
                                          range.name,
                                          range?.blongs,
                                          range?.rangeType
                                        )
                                      }
                                      isInvalid={!!error[range.name]}
                                      errorMessage={error[range.name]}
                                      type={range.inputType}
                                      placeholder={range.placeholder}
                                      disabled={
                                        action === "edit" &&
                                        responseStatus &&
                                        disableEdit[item.requried]
                                          ? true
                                          : false
                                      }
                                      sufix={range.Checkboxsufix}
                                      prefix={range.prefix}
                                      // onFocus={(e) =>
                                      //   handelChangeInputs(
                                      //     initialValues[range.name] === 0
                                      //       ? ""
                                      //       : initialValues[range.name],
                                      //     range.name
                                      //   )
                                      // }
                                      // onBlur={(e) =>
                                      //   handelChangeInputs(
                                      //     initialValues[range.name] === ""
                                      //       ? 0
                                      //       : initialValues[range.name],
                                      //     range.name
                                      //   )
                                      // }
                                    />
                                  </Col>
                                  {/* {index === 0 && (
                                    <Col
                                      md="auto"
                                      className="d-flex align-items-center mb-3"
                                    >
                                      -
                                    </Col>
                                  )} */}
                                </>
                              );
                            })}
                          </Row>
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "checkBox" && item.checkboxOnly) {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-center my-4">
                          <Checkbox
                            label={" "}
                            customLabel={item.label}
                            name={item.name}
                            value={initialValues[item.requried]}
                            onChange={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            size="m-0"
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                            requried={item.defaultChecked}
                            autoFocus={item?.autoFocus}
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "select") {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-center my-2">
                          <RenderSelect
                            value={initialValues[item.name]}
                            label={item.label}
                            requried={item.defaultChecked}
                            name={item.name}
                            onChange={(e) =>
                              handelChangeSelect(e.target.value, item.name)
                            }
                            options={item.option}
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            placeholder={item.placeholder}
                            checkBoxValue={initialValues[item.requried]}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "multiSelect") {
                    let option=item.name==="otherAmenities"?amentities:item.option
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-center my-2">
                          <RenderMultiSelect
                            value={
                              initialValues[item.name]?.length > 0
                                ? initialValues[item.name]
                                : []
                            }
                            label={item.label}
                            requried={item.defaultChecked}
                            name={item.name}
                            onChange={(val) =>
                              handelChangeSelect(val, item.name)
                            }
                            options={option}
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            placeholder={item.placeholder}
                            checkBoxValue={initialValues[item.requried]}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  }

                  return null;
                })}
            </Row>
            <div className="text-danger text-center">{error?.formatData && error?.formatData}</div>
          </Col>
          
        </Row>
      </Container>
    </div>
  );
}

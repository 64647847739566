import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Form } from "react-bootstrap";
import MainTable from "../../../../../components/Table";

import { BsFillEyeFill } from "react-icons/bs";
import lockIcon from "../../../../../assets/images/lock1_1.svg";
import { FaPen } from "react-icons/fa";
import {
  getCurrentUser,
  getData,
  putData,
  resetPassword,
} from "../../../../../api";
import FormModal from "../../../../../components/Modal";
import { CustomerWarningPopup } from "../../../CustomerTable/warningPopup";
import BuyerPocModal from "../pocModal/buyerPocModal";
import SellerPocModal from "../pocModal/sellerPocModal";

const RegionPOCTable = forwardRef(
  (
    {
      companyTypeReceived,
      modkey,
      type,
      brandID,
      view,
      viewPage,
      cityList,
      customerDetails,
      regions,
    },
    ref
  ) => {
    const [listData, setListData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState();
    // pagination
    const [perPage, setPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [offset, setOffset] = useState(1);

    // data
    const [rowObj, setRowObj] = useState({});
    const [pocType, setPocType] = useState("");
    const [pocId, setPocId] = useState("");
    const [status, setStatus] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");

    //password reset
    const [passwordResetWarningPopup, setPasswordResetWarningPopup] =
      useState(false);
    const [currentUserId, setCurrentUserId] = useState("");

    useImperativeHandle(ref, () => ({
      getPocList() {
        let url;
        if (modkey === "cityPOC") {
          url = `/brand/poc/pocList?pageNo=${offset}&limit=${perPage}&id=${brandID}&userType=cityPoc`;
        } else {
          url = `/brand/poc/RegionPocList?id=${brandID}&pageNo=${offset}&limit=${perPage}`;
        }
        getData(url).then((response) => {
          if (response?.status) {
            setPageCount(Math.ceil(response.data.totalDoc / perPage));
            setTotalCount(response.data.totalDoc);
            setListData(response?.data?.pocList);
          }
        });
      },
    }));

    // PASSWORD RESET
    const handleResetPassword = (row) => {
      setCurrentUserId(row._id);
      setPasswordResetWarningPopup(true);
      setWarningMessage(
        "Are you sure, do you want to reset the login credentials?"
      );
    };

    const passwordResetWarningYesClick = () => {
      resetPassword(currentUserId).then(() => {
        setPasswordResetWarningPopup(false);
      });
    };

    const handlePasswordResetWarningClose = () => {
      setPasswordResetWarningPopup(false);
    };

    const getPocList = () => {
      let url;
      if (modkey === "cityPOC") {
        url = `/brand/poc/pocList?pageNo=${offset}&limit=${perPage}&id=${brandID}&userType=cityPoc`;
      } else {
        url = `/brand/poc/RegionPocList?id=${brandID}&pageNo=${offset}&limit=${perPage}`;
      }
      getData(url).then((response) => {
        if (response?.status) {
          setPageCount(Math.ceil(response.data.totalDoc / perPage));
          setTotalCount(response.data.totalDoc);
          setListData(response?.data?.pocList);
        }
      });
    };

    const onClickEdit = (row, type) => {
      setOpenModal(true);
      setPocType(type);
      setRowObj(row);
      // if(type=="developer"){

      // }
    };
    const onActiveStatusChange = (id, active) => {
      setOpenWarningModal(true);
      setWarningMessage(
        `Are you sure, you want to ${active ? "deactivate" : "active"} this POC`
      );
      setPocId(id);
      setStatus(active);
    };
    function userButtonFormatterBuyer(cell, row, rowIndex) {
      return (
        <div className="d-flex justify-content-center align-items-center ">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => onClickEdit(row, "view")}
          >
            <BsFillEyeFill />
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            style={{ cursor: "pointer" }}
            onClick={
              view || !row.isActive ? null : () => onClickEdit(row, "edit")
            }
          >
            <FaPen />
          </div>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={
              view ? null : (e) => onActiveStatusChange(row._id, row.isActive)
            }
            // disabled={props.access.enable?false:true}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {getCurrentUser()?.role[0]?.name === "admin" ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleResetPassword(row);
              }}
            >
              <img
                src={lockIcon}
                alt="lock icon"
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  marginLeft: "-7px",
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
    const handelChangePerPage = (count) => {
      setPerPage(count);
      setOffset(1);
    };
    const columnsBuyer = [
      {
        dataField: "sno",
        text: "S.no",
      },
      {
        dataField: "name",
        text: "POC Name",
      },
      ...(modkey === "cityPOC"
        ? [
            {
              dataField: "city",
              text: "city",
              formatter: (cellContent, row, rowIndex) => {
                if (
                  row?.city === null ||
                  row?.city === "" ||
                  !("city" in row) ||
                  row?.city?.length === 0
                ) {
                  return "-";
                } else if (row?.city?.length > 0) {
                  let cityArray = [];

                  row?.city?.map((val) => {
                    cityArray.push(val?.name);
                    return val;
                  });
                  return cityArray.toString();
                }
              },
            },
            {
              dataField: "state",
              text: "State",
              formatter: (cellContent, row, rowIndex) => {
                if (
                  row?.state === null ||
                  row?.state === "" ||
                  !("state" in row) ||
                  row?.state?.length === 0
                ) {
                  return "-";
                } else if (row?.state?.length > 0) {
                  let stateArray = [];

                  row?.state?.map((val) => {
                    stateArray.push(val?.name);
                    return val;
                  });
                  return stateArray.toString();
                }
              },
            },
          ]
        : [
            {
              dataField: "region",
              text: "Region",
              formatter: (cellContent, row, rowIndex) => {
                if (
                  row?.region === null ||
                  row?.region === "" ||
                  !("region" in row) ||
                  row?.region?.length === 0
                ) {
                  return "-";
                } else if (row?.region?.length > 0) {
                  let regionArray = [];

                  row?.region?.map((val) => {
                    regionArray.push(val?.name);
                    return val;
                  });
                  return regionArray.toString();
                }
              },
            },
          ]),

      {
        dataField: "designation",
        text: "Designation",
      },
      {
        dataField: "email",
        text: "Email ID",
      },
      {
        dataField: "phoneNumber",
        text: "Phone Number",
      },
      ...(!viewPage
        ? [
            {
              dataField: "action",
              text: "Action",
              formatter: userButtonFormatterBuyer,
            },
          ]
        : []),
    ];
    const tableProps = {
      data: listData,
      columns: columnsBuyer,
      offset,
      setOffset,
      handelChangePerPage,
      pageCount,
      perPage,
      totalCount,
    };
    const handleClose = () => {
      setOpenModal(false);
    };
    const warningYesClick = () => {
      let data = {
        isActive: !status,
        type: type === "developer" ? "cityPoc" : "regionPoc",
      };
      let url = `/brand/poc/updatePoc/${brandID}/${pocId}`;
      putData(url, {}, data).then((response) => {
        if (response.status) {
          getPocList();
          setOpenWarningModal(false);
        } else {
          setOpenWarningModal(false);
        }
      });
    };
    const handleWarningClose = () => {
      setOpenWarningModal(false);
    };

    useEffect(() => {
      getPocList();
    }, [perPage, offset]);
    return (
      <>
        <MainTable {...tableProps} />
        <FormModal
          show={openModal}
          onClose={handleClose}
          heading={
            pocType === "view"
              ? "View POC"
              : pocType === "edit"
              ? "Edit POC"
              : "Add POC"
          }
          children={
            type === "developer" ? (
              <SellerPocModal
                companyTypeReceived={companyTypeReceived}
                modKey={modkey}
                type={type}
                regions={regions}
                customerDetails={customerDetails}
                pocType={pocType}
                cityList={cityList}
                pocList={getPocList}
                id={brandID}
                onClose={handleClose}
                pocEditData={rowObj}
              />
            ) : (
              <BuyerPocModal
                companyTypeReceived={companyTypeReceived}
                modKey={modkey}
                type={type}
                regions={regions}
                customerDetails={customerDetails}
                pocType={pocType}
                cityList={cityList}
                pocList={getPocList}
                id={brandID}
                onClose={handleClose}
                pocEditData={rowObj}
              />
            )
          }
          size={"lg"}
        />
        {openWarningModal && (
          <CustomerWarningPopup
            title={"Confirmation"}
            show={openWarningModal}
            message={warningMessage}
            warningYesClick={warningYesClick}
            onClose={handleWarningClose}
          />
        )}
        {passwordResetWarningPopup && (
          <CustomerWarningPopup
            title={"Confirmation"}
            show={passwordResetWarningPopup}
            message={warningMessage}
            warningYesClick={passwordResetWarningYesClick}
            onClose={handlePasswordResetWarningClose}
          />
        )}
      </>
    );
  }
);

export default RegionPOCTable;

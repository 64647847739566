import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { getData, postData } from "../../../../api";
import { PropertyCard } from "../../../../components/Cards/PropertyCard/propertyCard";
import Button from "../../../../components/Form/Button";
import MultiSelect from "../../../../components/select/dropDown";
import { getContextFilterData, performScrollEvent } from "../../../../utilities/pageScroll";
const FeaturedProperty = (props) => {
  const navigate = useNavigate();
  const {pageScroll,setPageScroll} = useContext(AppContext)
  const scrollStoreArray = [...pageScroll]
  let contextFilterData = getContextFilterData("myPageProperty", scrollStoreArray);
  let companyID = useSelector((state) => state.login.companyId);
  const [apiCardDetail, setApiCardDetail] = useState([]);
  const cardDetail = [
    "propertyTypeId",
    "carpetArea",
    "endUse",
    "possession",
    "rentPsf",
    "tenure",
  ];
  const [cityFilterKeyValue, setCityFilterKeyValue] = useState(contextFilterData ?? {label:"City",value:"City"});
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [propertyId, setPropertyId] = useState([]);
  const [filterMessage, setFilterMessage] = useState("");
  const fromPage = useSelector((state) => state.login.parentPage);
 

  const getPropertyData = async () => {
    setFilterMessage("");
    let url = `/property?pageNo=1&customerId=${props.id}&limit=0&featuredProperty=true`;
    if (cityFilterKeyValue?.label !== "City" && cityFilterKeyValue?.label !== "") {
      url += `&location=${cityFilterKeyValue?.label}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.status) {
        setApiCardDetail(response.data.properties);
        if (cityFilterKeyValue?.label !== "City" && cityFilterKeyValue?.label !== "") {
          if (response?.data?.properties?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      }
    });
  };

  const onchangeCityFilterKey = (val) => {
    setCityFilterKeyValue(val);
  };
  const onclickViewProperty = (a) => {
    props.setScrollEvent(a?._id,cityFilterKeyValue)
    if (props?.fromTab === "agent") {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url=fromPage==="agent"?"/agentReport/agentList/agentPage/propertyDetailView/":"/customer/agentPage/propertyDetailView/"
      navigate(
        `${url}${a.productId?.display}`,
        { state: { values: a, page: "customer" } }
      );
    } else {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url=fromPage==="seller"?"/sellerReport/developerList/developerPage/propertyDetailView/":"/customer/developerPage/propertyDetailView/"
      navigate(
        companyID === ""
          ? `${url}${a.productId?.display}`
          : `/company/developerCompanyCreation/developerPage/propertyDetailView/${a.productId?.display}`,
        { state: { values: a, page: "customer" } }
      );
    }
    localStorage.setItem('propTabKey', JSON.stringify("featuredProperties"))
  };
  const onClickFeaturedProperty = () => {
    setShowCheckBox(true);
  };

  const onSelectProperties = (id) => {
    let propertyCheckedArray = [...propertyId];
    let includes = propertyCheckedArray.includes(id);
    if (includes) {
      let removedValue = propertyCheckedArray.filter((item) => item !== id);
      setPropertyId(removedValue);
    } else {
      propertyCheckedArray.push(id);
      setPropertyId([...propertyCheckedArray]);
    }
  };

  const onClickMove = async () => {
    let data = {
      propertyIds: propertyId,
      userId: props?.id,
      status: false,
    };
    await postData("/property/addFeaturedProperty", {}, data).then(
      (response) => {
        if (response.status) {
          getPropertyData();
          setShowCheckBox(false);
          setPropertyId([]);
        }
      }
    );
  };
  const resetClick = () => {
    setShowCheckBox(false);
    setPropertyId([]);
  };
  const btnList = [
    {
      btnName: "Edit",
      onClick: (a) => onclickEditProperty(a),
      className: "btn-color-primary",
    },
    {
      btnName: "View",
      onClick: (a) => onclickViewProperty(a),
      className: "btn-color-primary",
    },
  ];
  const onclickEditProperty = (a) => {
    props.setScrollEvent(a?._id,cityFilterKeyValue)
    let page =fromPage==="agent"?"agentReportsCustomer":fromPage==="seller"?"sellerReportsCustomer":""

    let url;
    localStorage.setItem('propTabKey', JSON.stringify("featuredProperties"))
    localStorage.setItem("propertyValues", JSON.stringify(a));
    url = `/property/editProp?propertyId=${a?._id}&developerId=${a?.developerId?._id}&developerType=${a?.customerType}&developerName=${a?.developerId?.name}&fromPage=${page}`;
    localStorage.setItem("ownerShip", JSON.stringify(a?.propertyTypeId._id));
    navigate(url);
  };
  useEffect(() => {
    if (apiCardDetail?.length > 0 && props.tabKey === "featuredProperties") {
     let updatedArray = performScrollEvent("myPageProperty", scrollStoreArray);
      setPageScroll(updatedArray);
    }
  }, [apiCardDetail]);

  useEffect(() => {
      getPropertyData();
      setShowCheckBox(false);
      setPropertyId([]);
  }, [cityFilterKeyValue]);
  return (
    <>
      <div className="filter-container">
        <MultiSelect
        width="25%"
          onChange={(val) => {
            onchangeCityFilterKey(val);
          }}
          isMulti={false}
          options={[{ label: "City", value: "City" }, ...props?.searchCityList]}
          value={cityFilterKeyValue}
        />
      </div>
      {apiCardDetail.length > 0 ? (
        <>
          {props.featuredPositioningStatus &&
          props.featuredPositioningStatus === "Yes" ? (
            <div className="d-flex btn-container">
              {showCheckBox && propertyId?.length > 0 ? (
                <div
                  className="d-flex mb-4 btn-container"
                  style={{ marginRight: "auto" }}
                >
                  <Button
                    onClick={resetClick}
                    className="btn-outline-color-primary"
                  >
                    Reset
                  </Button>
                  <Button onClick={onClickMove} className="btn-color-primary">
                    Remove
                  </Button>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-end mb-4 btn-container">
                <Button
                  onClick={onClickFeaturedProperty}
                  className="btn-color-primary"
                >
                  Remove from featured properties
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}

          <PropertyCard
           access={props?.access}
            apiCardDetail={apiCardDetail}
            cardDetail={cardDetail}
            image={props?.image?.viewUrl ? props.image.viewUrl : props.image}
            // onclick={onclickViewProperty}
            showCheckBox={showCheckBox}
            onSelectProperties={onSelectProperties}
            button={btnList}
          />
        </>
      ) : (
        <center>
          {filterMessage ? filterMessage : "No featured properties yet"}
        </center>
      )}
    </>
  );
};
export default FeaturedProperty;
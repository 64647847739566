import { useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "./propertyView.scss";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Button from "../../components/button/buttons";
import { BiMap } from "react-icons/bi";
import PropertyViewList from "../../components/propertyViewList/propertyDetail";
import PropertyAmenties from "../../components/propertyViewList/propertyAmenties";
import Pdf from "../../assets/images/pdf.png";
import { AiOutlineEye } from "react-icons/ai";
import swimmingpool from "../../assets/images/swimmingpool.png";
import cctv from "../../assets/images/cctv.png";
import park from "../../assets/images/parks.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Intercom from "../../assets/images/intercom.png";
import Gym from "../../assets/images/gym.png";
import Lobby from "../../assets/images/lobby.png";
import Balcony from "../../assets/images/balcony.png";
import Security from "../../assets/images/security.png";
import PropertyDocument from "../../components/propertyViewList/propertyDocument";
import { getData } from "../../api";
import NavBar from "../../components/Navbar";
import NoIMage from "../../assets/images/noImage.jpg";
import MapWrapper from "../../components/Maps/map";
import Loader from "../../components/Loader";
import ViewOnGoogleMap from "../../components/googleMapButtons/ViewOnGoogleMap";
import ViewInStreetView from "../../components/googleMapButtons/ViewInStreetView";
import { Col, Row } from "react-bootstrap";

function PropertyView() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [viewDatas, setViewDatas] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [allImages] = useState([]);
  let location = useLocation();
  let navigate = useNavigate();
  const { pathPropertyId } = useParams();

  const passedData = location.state ? location.state.values : "";
  const page = location?.state?.page ? location.state.page : "";

  const keys = [
    "frontSide",
    "interior",
    "exterior",
    "buildingImages",
    "workSpaceImages",
    "commonAreaImages",
    "videoUrl",
  ];
  const getPropertyDatas = async () => {
    setLoading(true);
    let propertyData = JSON.parse(localStorage.getItem("propertyValues"));
    let url = `/property/${(passedData?._id ? passedData?._id : pathPropertyId) || propertyData?._id
      }`;
    const newData = await getData(url, {}, {});
    const { statusCode, data } = newData;
    if (statusCode === 200) {
      setViewDatas(data);
      setLoading(false);
      data?.propertyTypeId?.name !== "Flex Space"
        ? handleAllImages(data?.imagesAndVideos)
        : handleAllImages(data?.coworkingImages);
    } else {
      setLoading(false);
    }
  };
  const handleAllImages = (images) => {
    let newArray = [];
    keys.map((item) => {
      images[item]?.map((items) => {
        if (items?.type === "youtube" || items?.type === "vimeo") {
          const embedUrl =
            items?.type === "youtube"
              ? items?.url.replace("youtube.com/watch?v=", "youtube.com/embed/")
              : items?.url.replace("vimeo.com/", "player.vimeo.com/video/");

          items = { ...items, url: embedUrl };
          newArray?.push(items);
        } else {
          newArray?.push(items);
        }
      });
    });
    newArray.forEach((item) => {
      if (!allImages.includes(item) && allImages.length !== newArray.length) {
        allImages?.push(item);
      }
    });
  };

  console.log({ allImages });

  const PreviousBtn = (props) => {
    setCurrentSlide(props.currentSlide + 1);

    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IoIosArrowBack style={{ color: "white" }} />
      </div>
    );
  };
  const NextBtn = (props) => {
    setCurrentSlide(props.currentSlide + 1);
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IoIosArrowForward style={{ color: "white" }} />
      </div>
    );
  };

  const reportdata = [
    {
      id: 1,
      title: `${viewDatas?.ownerShip ? viewDatas?.ownerShip : "-"}`,
      value: "Ownership",
    },
    {
      id: 1,
      title: `${viewDatas?.additionalDetails?.idealFor
        ? viewDatas?.additionalDetails?.idealFor?.map((item) => {
          return item?.name;
        })
        : "-"
        }`,
      value: "Ideal for",
    },
    {
      id: 1,
      title: `${viewDatas?.builtupArea ? viewDatas?.builtupArea + " sqft" : "-"
        } `,
      value: "Builtup area in sqft",
    },
    {
      id: 1,
      title:
        viewDatas?.customerType === "Developer" &&
          viewDatas?.propertyUnit == "multiple"
          ? `${viewDatas?.carpetAreaMin +
          " - " +
          viewDatas?.carpetAreaMax +
          " sqft"
          }`
          : `${viewDatas?.carpetAreaMax
            ? viewDatas?.carpetAreaMax + " sqft"
            : "-"
          } `,
      value:
        viewDatas?.customerType === "Developer" &&
          viewDatas?.propertyUnit == "multiple"
          ? "Carpet Area Range"
          : "Carpet Area in sqft",
    },
    {
      id: 2,
      title: viewDatas.propertyUnit,
      value: "Individual Units / Multiple units",
    },
    {
      id: 3,
      title: `${viewDatas?.noOfUnits ? viewDatas?.noOfUnits : "-"}`,
      value: "Number of Units",
    },
  ];

  const data2 = [
    {
      name: "City",
      value: "Yes",
      key: "location",
      type: ["Retail", "Office space", "Flex Space"],
    },
    {
      name: "Pin Code",
      value: "Yes",
      key: "pincode",
      type: ["Retail", "Office space", "Flex Space"],
    },
    {
      name: "Transaction Type",
      value: "Yes",
      key: "transactionType",
      type: ["Flex Space"],
    },
    {
      name: "Location",
      value: "Yes",
      key: "microLocation",
      type: ["Retail", "Office space", "Flex Space"],
    },
    {
      name: "No of parkings",
      value: "Yes",
      key: "parking",
      type: "Retail",
    },
    {
      name: "Ceiling height in feet",
      value: "3 floors",
      key: "ceilingHeight",
      type: "Retail",
    },
    {
      name: "Fitouts available",
      value: "Yes",
      key: "fitOuts",
      type: ["Retail", "Office space"],
    },
    // {
    //   name: "Power backup available",
    //   value: "Yes",
    //   key: "powerBackup",
    //   type: 'all'
    // },
    {
      name: "Mezzainine Available",
      value: "3 ",
      key: "mezzainineAvailable",
      type: "Retail",
    },
    {
      name: "Toilet avalilable",
      value: "3 ",
      key: "toilet",
      type: ["Retail", "Office space"],
    },
    // {
    //   name: "Washrooms avalilable",
    //   value: "3 ",
    //   key: "washRooms",
    //   type: 'Office space'
    // },
    {
      name: "Lease tenure in years",
      value: "3 ",
      key: "tenure",
      type: ["Retail", "Office space"],
    },
    {
      name: "LockIn Period",
      value: "3 ",
      key: "lockInPeriod",
      type: ["Retail", "Office space"],
    },
    {
      name: "Property available from",
      value: "3 ",
      key: "possession",
      type: ["Retail", "Office space", "Flex Space"],
    },
    // {
    //   name: "Available From",
    //   value: "3 ",
    //   key: "possession",
    //   type: ["Flex Space"],
    // },
    {
      name: "OC available",
      value: "3 ",
      key: "ocStatus",
      type: ["Retail", "Office space"],
    },
    {
      name: "Property Type",
      value: "3 ",
      key: "propertyTypeId",
      type: ["Retail", "Office space"],
    },
    {
      name: "Bike parking allotted",
      value: "3 ",
      key: "bikeParking",
      type: ["Office space", "Flex Space"],
    },
    {
      name: "Car parking allotted",
      value: "3 ",
      key: "carParking",
      type: ["Office space", "Flex Space"],
    },
    {
      name: "Ownership",
      value: "3 ",
      key: "ownerShip",
      type: "Retail",
    },
    {
      name: "Floor",
      value: "Yes",
      key: "floor",
      type: ["Retail"],
    },
    {
      name: "Floor Number",
      value: "Yes",
      key: "availableFloor",
      type: ["Flex Space"],
    },
    {
      name: "Business hours",
      value: "Yes",
      key: "businessHours",
      type: ["Flex Space"],
    },
    {
      name: "Nearest Connectivity",
      value: "Yes",
      key: "nearestConnectivity",
      type: ["Flex Space"],
    },
    {
      name: "Age of property",
      value: "30 Months",
      key: "propertyAge",
      type: ["Retail", "Office space", "Flex Space"],
    },
  ];

  const data3 = [
    {
      name: "Deposit in months",
      value: "Rs. 20,000",
    },
    {
      name: "Price per sqft",
      value: "Rs. 2999",
    },
    {
      name: "Negotiable",
      value: "2%",
    },
    {
      name: "Brokerage",
      value: "2%",
    },
  ];
  const data4 = [
    {
      img: Pdf,
      name: "Certificate 1",
      value: <AiOutlineEye className="eye-icon" />,
    },
    {
      img: Pdf,
      name: "License 1",
      value: <AiOutlineEye className="eye-icon" />,
    },
    {
      img: Pdf,
      name: "Occupancy Certificate",
      value: <AiOutlineEye className="eye-icon" />,
    },
    {
      img: Pdf,
      name: "License 2",
      value: <AiOutlineEye className="eye-icon" />,
    },
    {
      img: Pdf,
      name: "Non Objection Certificate",
      value: <AiOutlineEye className="eye-icon" />,
    },
    {
      img: Pdf,
      name: "License 3",
      value: <AiOutlineEye className="eye-icon" />,
    },
  ];

  const data5 = [
    // {
    //   name: "Previous Occupancy",
    //   value: "Yes",
    //   key: "previousOccupancy",
    //   type: 'Retail'
    // },
    {
      name: "Floor",
      value: "Yes",
      key: "floor",
      type: "Retail",
    },
    {
      name: "Total floors",
      value: "Yes",
      key: "totalFloors",
      type: "Office space",
    },
    {
      name: "Available Floor number",
      value: "Yes",
      key: "availablefloors",
      type: "Office space",
    },

    {
      name: "Frontage in feet",
      value: "30 Months",
      key: "frontAge",
      type: "Retail",
    },
    {
      name: "Age of property",
      value: "30 Months",
      key: "propertyAge",
      type: "all",
    },
    // {
    //   name: "Access road width in feet",
    //   value: "30 Months",
    //   key: "accessRoadWidth",
    //   type: 'Retail'
    // },
    {
      name: "Store type",
      value: "2 Months",
      key: "buildingStructure",
      type: "Retail",
    },
    {
      name: "Lockin period",
      key: "lockInPeriod",
      type: "all",
    },
    // {
    //   name: "Fitouts/Rent free period",
    //   key: "rentFreePeriod",
    //   type: 'all'
    // },
    {
      name: "Cabins",
      key: "cabins",
      type: "Office space",
    },
    {
      name: "Meeting rooms",
      key: "meetingRooms",
      type: "Office space",
    },
    // {
    //   name: "Lease escalation",
    //   key: "leaseEscalationValue",
    //   type: 'Office space'
    // },
    // {
    //   name: "Lease escalation %",
    //   key: "leaseEscalationpercentage",
    //   type: 'Office space'
    // },
  ];

  const data6 = [
    {
      img: swimmingpool,
      name: "Swimming pool",
    },
    {
      img: cctv,
      name: "cctv",
    },
    {
      img: park,
      name: "parks",
    },
    {
      img: Intercom,
      name: "intercom",
    },
    {
      img: Gym,
      name: "gym",
    },
    {
      img: Lobby,
      name: "lobby",
    },
    {
      img: Balcony,
      name: "balcony",
    },
    {
      img: Security,
      name: "security",
    },
  ];

  const viewResponse = () => {
    let path = location.state
      ? location.state.page === "customer"
        ? "/customer/developerPage/propertyDetailView/propertyResponse"
        : location.state.page === "agent"
          ? "/customer/agentPage/propertyDetailView/propertyResponse"
          : "/properties/propertyView"
      : " ";
    localStorage.setItem("propertyResponceId", JSON.stringify(viewDatas?._id));
    navigate(path, { state: { allData: viewDatas !== "" ? viewDatas : {} } });
  };
  useEffect(() => {
    getPropertyDatas();
  }, [passedData]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [passedData]);

  // const getPdfDownloadLink = async (load) => {
  //   if (load) {
  //     setButtonLoading(true);
  //   }
  //   let url = `/property/getPropertyPdfDownloadLink/${passedData?._id || viewDatas?._id
  //     ? passedData?._id || viewDatas?._id
  //     : JSON.parse(localStorage.getItem("property-id"))
  //     }`;
  //   let pdfData = await getData(url, {});
  //   const { data, statusCode } = pdfData;
  //   if (statusCode == 200) {
  //     setButtonLoading(false);
  //     downloadByOctetStream("Property.pdf", pdfData.data);
  //   } else {
  //     setButtonLoading(false);
  //   }
  // };

  return (
    <>
      <NavBar />
      {loading && <Loader className={"fixed"} />}
      {!loading && (
        <>
          <div
            // style={{ margin: "10px", padding: "20px", paddingBottom: "0px" }}
            className="carousel"
          >
            <Slider
              dots
              initialSlide={0}
              infinite
              prevArrow={<PreviousBtn />}
              nextArrow={<NextBtn />}
              customPaging={(i) => {
                return (
                  <div>
                    {allImages[i]?.type === "video" ? (
                      <video width="100" height="80">
                        <source src={allImages[i]?.viewUrl} type="video/mp4" />
                      </video>
                    ) : allImages[i]?.type === "image" ? (
                      <div className="d-flex">
                        <img
                          className="imagelist-hide"
                          src={allImages[i]?.viewUrl}
                          alt=""
                          style={{
                            width: "80%",
                            height: "80px",
                            objectFit: "cover",
                            border: "1px #fff",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                    ) : (
                      <iframe
                        width="100"
                        height="80"
                        src={allImages[i]?.url}
                      />
                    )}
                  </div>
                );
              }}
              dotsClass="slick-dots custom-indicator"
            >
              {allImages?.length > 0 ? (
                allImages.map((item, index) => (
                  <div key={index} className="noimageWrapper">
                    {item?.type === "video" ? (
                      <video width="100%" height="400px" controls>
                        <source src={item?.viewUrl} type="video/mp4" />
                      </video>
                    ) : item?.type === "youtube" || item?.type === "vimeo" ? (
                      <iframe
                        // width="fit-content"
                        // height="80vh"
                        src={item?.url}
                        style={{ width: "100%", height: "400px" }}
                      ></iframe>
                    ) : item?.type === "image" ? (
                      <img
                        src={item?.viewUrl}
                        alt="images"
                        style={{ width: "fit-content", height: "60vh" }}
                      />
                    ) : (
                      <img
                        src={NoIMage}
                        alt="images"
                        style={{ width: "fit-content", height: "60vh" }}
                        className="noimageWidth"
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="noimageWrapper">
                  <img
                    src={NoIMage}
                    alt="NoImage"
                    style={{ width: "fit-content", height: "100px" }}
                    className="noimageWidth"
                  />
                </div>
              )}
            </Slider>
            {allImages?.length > 0 && (
              <div className="text-center ">
                <label className="image-counter">
                  {currentSlide}/{allImages.length}
                </label>
              </div>
            )}
          </div>
          <div className="bg-white property-Name mt-4">
            <div className="d-flex justify-content-between property-hidden">
              <div>
                <div className="d-flex gap-3 property-info">
                  {viewDatas?.propertyName ? (
                    <div className="property-text">
                      {viewDatas?.propertyName}
                    </div>
                  ) : null}
                  {/* <div className="property-text">{viewDatas?.propertyName}</div> */}
                  <div
                    className="location-text"
                    title={viewDatas?.microLocation?.description}
                  >
                    {viewDatas?.microLocation?.description}
                  </div>
                </div>
                <p className="f-18 fw-500">
                  <b>Property ID: </b>
                  {viewDatas?.productId?.display}
                </p>

                <div className="d-flex gap-3 pt-2 property-info">
                  <label className="fw-bold">
                    {viewDatas?.projectDeveloper}
                  </label>
                  <div
                    className={`${viewDatas?.customerType === "Agent"
                      ? "broker-tag"
                      : "develop-text"
                      } `}
                  >
                    {viewDatas?.customerType}
                  </div>
                </div>
              </div>
              <div className="mobile">
                <BiMap className="map-icon" />
                <Button
                  name="View on Map"
                  className="viewmap"
                  onClick={() =>
                    document.getElementById("mapDivision").scrollIntoView()
                  }
                />
              </div>
            </div>

            {viewDatas?.propertyTypeId?.name !== "Flex Space" && (
              <div className="developer-details pt-3">
                {reportdata.map((item, index) => {
                  if (
                    (viewDatas?.customerType === "Agent" ||
                      viewDatas?.propertyTypeId?.name === "Office space") &&
                    item.value === "Ownership"
                  )
                    return null;
                  if (
                    (viewDatas?.customerType === "Agent" ||
                      viewDatas?.propertyTypeId?.name === "Retail") &&
                    item.value === "Ownership" &&
                    viewDatas.transactionType == "Sales"
                  ) {
                    return null;
                  }

                  if (
                    viewDatas?.customerType === "Agent" &&
                    item.value === "Individual Units / Multiple units"
                  ) {
                    return;
                  }
                  if (
                    viewDatas?.propertyTypeId?.name === "Retail" &&
                    item.value === "Ideal for"
                  ) {
                    return null;
                  }
                  if (
                    viewDatas?.propertyTypeId?.name === "Office space" &&
                    item.value === "Ideal for" &&
                    viewDatas.transactionType == "Sales"
                  ) {
                    return null;
                  }
                  if (
                    viewDatas?.customerType === "Agent" &&
                    item.value === "Individual Units / Multiple units"
                  ) {
                    return;
                  }
                  if (
                    viewDatas.propertyUnit === "multiple" &&
                    item.value === "Builtup area in sqft"
                  ) {
                    return;
                  }
                  if (
                    viewDatas.propertyUnit === "individual" &&
                    item.value === "Number of Units"
                  ) {
                    return;
                  }
                  if (
                    viewDatas?.customerType === "Agent" &&
                    item.value === "Number of Units"
                  ) {
                    return;
                  }

                  return (
                    <div className="mt-2 developer-details_card" key={index}>
                      <div className="text-start p-2 py-3 bg-light-card border-radius ">
                        <div
                          title={item?.title}
                          className={
                            "developer-details_cardText overflow-hidden-ellipsis " +
                            (item.value === "Individual Units / Multiple units"
                              ? "text-capitalize"
                              : ``)
                          }
                        >
                          {item?.title}
                        </div>
                        <div className="developer-details_cardSubtext m-0">
                          {item?.value}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="mt-4 ">
              <label className="describe-text">Description</label>
              <p className="describe-subtext p-1">{viewDatas?.about}</p>
            </div>
          </div>
          <div className="property-Name bg-white mt-4">
            <label className="Property-Details-text my-2">
              Primary Details
            </label>
            {viewDatas && (
              <PropertyViewList
                data={data2}
                sm={12}
                md={6}
                pdf={false}
                apiData={viewDatas}
                typeData={"Primary Details"}
              />
            )}
          </div>

          {viewDatas?.propertyTypeId?.name == "Flex Space" &&
            viewDatas?.format?.length > 0 && (
              <div className="bg-white property-Name m-0 mt-4 ">
                <label className="body-text-heading my-2">Format</label>
                <Row>
                  {viewDatas?.format?.map((data) => {
                    return (
                      <Col sm={12} md={6} className="column-data">
                        <div className="bg-light-card d-flex align-items-center Property-View-lable w-100">
                          <div className="w-50 Property-View-lable">{`Rate per seat of ${data?.name}`}</div>
                          <div
                            className={
                              "w-50 Property-View-lable-left word-wrap"
                            }
                          >
                            {data?.amount ? `Rs. ${data?.amount}` : "-"}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )}

          {(
            <div className="d-flex property-costed mt-4">
              {viewDatas?.propertyTypeId?.name !== "Flex Space" && <div
                className={`bg-white property-cost m-0 ${viewDatas?.propertyTypeId?.name === "Office space"
                  ? "w-100"
                  : ""
                  }`}
              >
                <label className="Property-Details-text my-2">
                  Property Cost
                </label>
                {viewDatas && (
                  <PropertyViewList
                    data={data3}
                    sm={12}
                    md={12}
                    pdf={false}
                    typeData={"Property Cost"}
                    apiData={viewDatas}
                  />
                )}
              </div>}
              {/* {viewDatas?.propertyTypeId?.name === "Retail" ? ( */}

              {(viewDatas?.propertyTypeId?.name == "Flex Space" ? viewDatas?.otherDocuments?.brochure?.length > 0 : viewDatas?.documents) &&
                <div className="bg-white property-docs w-50 mt-0">
                  <label className="Property-Details-text my-2">
                  {/* {viewDatas?.propertyTypeId?.name == "Flex Space" ? `Property Brochure` : `Property Documents`} */}
                  Property Documents
                  </label>
                  {/* <PropertyViewList data={data4} sm={12} md={6} pdf={true} /> */}
                  <PropertyDocument
                    data={data4}
                    sm={12}
                    md={6}
                    pdf={true}
                    typeData={"Property Documents"}
                    apiData={viewDatas}
                    image={Pdf}
                    icons={<AiOutlineEye className="eye-icon" />}
                  />
                </div>}
              {/* ) : (
              ""
            )} */}
            </div>
          )}

          <div className="d-flex  property-costed">
            {viewDatas?.propertyTypeId?.name !== "Flex Space" && (
              <div className="bg-white property-cost w-50  mt-3">
                <label className="Property-Details-text my-2">
                  More Details
                </label>
                {viewDatas && (
                  <PropertyViewList
                    data={data5}
                    sm={12}
                    md={12}
                    pdf={false}
                    typeData={"More Details"}
                    apiData={viewDatas}
                  />
                )}
              </div>
            )}
            <div className="property-docs bg-white w-50 mt-3" id="mapDivision">
              <div className="row">
                <div className="col col-lg-4 col-sm-12">
                  <label className="body-text-heading my-2">Property Map</label>
                </div>
                {viewDatas?.gMapLatLong &&
                  viewDatas?.gMapLatLong.length > 0 && (
                    <div className="col col-lg-8 col-sm-12 d-flex align-items-center justify-content-end p-3 gap-3">
                      <ViewInStreetView cordinates={viewDatas?.gMapLatLong} />
                      <ViewOnGoogleMap cordinates={viewDatas?.gMapLatLong} />
                    </div>
                  )}
              </div>

              <MapWrapper data={viewDatas?.nearByloactions} />
            </div>
          </div>

          <div className="bg-white Ameneties mt-4 mb-4">
            <label className="Property-Details-text my-2">Amenties</label>
            <div>
              <PropertyAmenties
                apiData={viewDatas}
                data={data6}
                sm={12}
                md={1}
                pdf={false}
              />
            </div>
          </div>

          <div className="Ameneties-footer">
            <div className="footer-left">
              <p className="name-of-property">{viewDatas?.propertyName}</p>
              <p className="developer-name">
                {viewDatas?.customerType === "Agent"
                  ? `${viewDatas?.userId?.name} ${viewDatas?.userId?.lastName
                    ? viewDatas?.userId?.lastName
                    : ""
                  }`
                  : viewDatas?.projectDeveloper}
              </p>
            </div>
            {/* {page === "properties" ? "" :  */}
            {/* <Button
              name="Download as PDF"
              loading={buttonLoading}
              className="View-Responses-color"
              // onClick={getPdfDownloadLink}
            /> */}

          </div>
        </>
      )}
    </>
  );
}

export default PropertyView;
import  { useEffect, useState } from "react";
//components
import FormModal from "../../components/Modal";
import Select from "../../components/Form/Select";
import Loader from "../../components/Loader";
import Button from "../../components/Form/Button";
import Search from "../../components/Search/search";
import { Form } from "react-bootstrap";

//fetch
import { getData, putData, postData, postGetData } from "../../api/index";

//style
import "./style.scss";

//components
import Input from "../../components/Form/Input";
//api


export const ViewLocations = ({ onClose, show, viewDetails, access }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [locationList, setlocationList] = useState([]);
  const [search, setSearch] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [localitiyId, setLocalityId] = useState("");

  const getLocationList = async () => {
    setIsLoading(true);
    let url = `/cityZones/getlocalities/${viewDetails?.stateId}/${viewDetails?.cityId}`;
    if (search) {
      url += `?search=${search}`;
    }

    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setIsLoading(false);
      setlocationList(data);
    } else {
      setlocationList([]);
      setIsLoading(false);
    }
  };
  const handelUpdateStatus = (isActive, id) => {
    if (id) {
      setUpdateStatus(!isActive);
      setConfirmation(true);
      setLocalityId(id);
    }
  };
  const updateLocationStatus = async (localitiy) => {
    setConfirmation(false);
    setIsLoading(true);
    let url = `/cityZones/activateOrDeactivate/${viewDetails?.stateId}/${viewDetails?.cityId}/${localitiy}`;
    let payLoad = {
      status: updateStatus,
    };
    const getList = await putData(url, {}, payLoad);
    const { statusCode } = getList;
    if (statusCode === 200) {
      getLocationList();
    } else {
      setIsLoading(false);
    }
  };
  const handelSearchLocation = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (viewDetails?.cityId) {
      getLocationList();
    }
  }, [search]);
  const handelCloseConfirmation = () => {
    setConfirmation(false);
  };

  return (
    
      <FormModal
        onClose={onClose}
        show={show}
        heading={"View localities"}
        size={"md"}
        loading={false}
        loader="fixed"
      >
        <div className=" customer-list">
          <div className="customer-list__name">{viewDetails?.cityName}</div>

          <div className="w-75 my-2">
            <Search onChange={handelSearchLocation} />
          </div>
          {locationList.length > 0 ? (
           
              <div className="customer__change">
                {locationList.map((item, index) => {
                  return (
                    
                      <div
                        className="d-flex justify-content-between p-2 py-3 customer__change_rm"
                        key={index}
                      >
                        <div className="change_rm__name">{item.name} </div>
                        <div className="change_rm__action cursor-pointer">
                          <Form.Check
                            type="switch"
                            id={`custom-switch_${index}}`}
                            checked={item.isActive ? true : false}
                            onChange={() => {
                              handelUpdateStatus(item.isActive, item._id);
                            }}
                            disabled={
                              access.enableDisableLocality ? false : true
                            }
                          />
                        </div>
                      </div>
                   
                  );
                })}
              </div>
            
          ) : (
            
              <div className="text-center">No Data Found</div>
           
          )}
        </div>
        {isLoading && <Loader className={"fixed"} />}
        {confirmation && (
          <AdminWarningPopup
            onClose={handelCloseConfirmation}
            show={confirmation}
            message={`Are you sure you want ${
              updateStatus ? "enable" : "disable"
            } this locality`}
            warningYesClick={() => updateLocationStatus(localitiyId)}
          />
        )}
      </FormModal>
    
  );
};

export const AdminWarningPopup = ({
  onClose,
  show,
  message,
  warningYesClick,
}) => {
  return (
    <FormModal
      onClose={onClose}
      show={show}
      heading={"Confirmation"}
      size={"md"}
      loading={false}
      loader="fixed"
    >
      <p>{message}</p>
      <div className="btn-container">
        <Button className={"btn-outline-color-primary"} onClick={onClose}>
          No
        </Button>
        <Button onClick={warningYesClick} className="btn-color-primary">
          Yes
        </Button>
      </div>
    </FormModal>
  );
};

export function AddCity({ onClose, show }) {
  const [errorMessage, setErrorMessage] = useState({});
  const [stateList, setStateList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [regionName, setRegionName] = useState("");
  const [formValues, setFormValues] = useState({ city: "", stateId: "" });

  const [formSubmitLoading, setFormSubmitLoading] = useState(false);

  const handleChanges = (value, name) => {
    errorMessage[name] = "";

    if (name === "region") {
      setRegionName(value);
    } else {
      let data = { ...formValues };
      if (name === "city") {
        data["city"] = value.replace(/ {2,}/g, " ");
      } else {
        data["stateId"] = value;
      }
      setFormValues(data);
    }
  };

  const getRegionList = async () => {
    let url = "/region/regions";
    const apiDatas = await getData(url);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      setRegionList(data);
    } else {
      setRegionList([]);
    }
  };

  const getStateApi = async () => {
    let url = "/country/getStates";
    let region = {
      regionIds: [regionName],
    };
    const apiDatas = await postGetData(url, {}, region);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      setStateList(data);
    } else {
      setStateList([]);
    }
  };
  const checkErrorMessage = (data) => {
    let error = {};
    let requriedMessage = "This Field Is Required";
    if (data.city === "") {
      error.city = requriedMessage;
    }
    if (data.stateId === "") {
      error.stateId = requriedMessage;
    }
    if (regionName === "") {
      error.region = requriedMessage;
    }
    return error;
  };
  const handelCitySubmit = async () => {
    const checkError = checkErrorMessage(formValues);
    setErrorMessage(checkError);
    let NoError = Object.values(checkError).every((v) => v.length === 0);
    if (NoError) {
      setFormSubmitLoading(true);
      let url = "/cityZones";
      let payLoad = {
        name: formValues.city,
        stateId: formValues.stateId,
      };
      const locationData = await postData(url, {}, payLoad);
      const { message, status } = locationData;

      if (status) {
        setFormSubmitLoading(false);
        onClose("updated");
        setErrorMessage();
      } else if (!status && message === "Unable to add invalid city.") {
        setFormSubmitLoading(false);
        setErrorMessage({ city: message });
      } else {
        setFormSubmitLoading(false);
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    handelCitySubmit();
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  useEffect(() => {
    getRegionList();
  }, []);

  useEffect(() => {
    if (regionName !== "") {
      getStateApi();
    }
  }, [regionName]);
  return (
    
      <FormModal
        onClose={onClose}
        show={show}
        heading={"Add city"}
        size={"md"}
        loader="fixed"
      >
        <div className="mt-1">
          <div className="select-head">
            <form
              noValidate
              // validated={validated}
              onSubmit={(e) => handleSubmit(e)}
              onKeyDown={handleKeyPress}
            >
              <Select
                errorMessage={errorMessage?.region && errorMessage?.region}
                label={"Region*"}
                options={regionList}
                placeholder="Select state"
                name={"stateId"}
                isInvalid={errorMessage?.region && true}
                value={regionName && regionName}
                onChange={(e) => {
                  handleChanges(e.target.value, "region");
                }}
              />
              <Select
                errorMessage={errorMessage?.stateId && errorMessage?.stateId}
                label={"State name*"}
                options={stateList}
                placeholder="Select state"
                name={"stateId"}
                isInvalid={errorMessage?.stateId && true}
                value={formValues.stateId && formValues?.stateId}
                onChange={(e) => {
                  handleChanges(e.target.value, "state");
                }}
              />
              <Input
                errorMessage={errorMessage?.city && errorMessage?.city}
                label={"City name *"}
                type={"text"}
                name={"name"}
                placeholder={"Enter city name"}
                onChange={(e) => {
                  handleChanges(e.target.value, "city");
                }}
                value={formValues?.city}
                isInvalid={errorMessage?.city && true}
              />

              <div className="d-flex justify-content-end  gap-3 ">
                <Button className={"pre-btn"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  className="next-btn"
                  loading={formSubmitLoading}
                >
                  Add
                </Button>
              </div>
            </form>
          </div>
        </div>
        {}
      </FormModal>
   
  );
}

import { useEffect, useState } from "react";
//lib
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

//images
import { MdLocationOn } from "react-icons/md";
import { checkUserPermission, getCurrentUser } from "../../api/index";
import Notification from "../../assets/images/Notification -sidebar.png";
import Admin from "../../assets/images/admin-icon.png";
import ReportAgent from "../../assets/images/agent (1).png";
import Brand from "../../assets/images/brand-icon.png";
import CompanyLogo from "../../assets/images/companyIcon.png";
import Content from "../../assets/images/content-icon.png";
import Customer from "../../assets/images/customer-icon.png";
import Demand from "../../assets/images/demand-icone.png";
import Coupon from "../../assets/images/discountIcon.png";
import ReportBuyer from "../../assets/images/enterprise buyer (1).png";
import ReportSeller from "../../assets/images/enterprise seller.png";
import Dashboard from "../../assets/images/home.png";
import LogOut from "../../assets/images/logOut-icon.png";
import Privacy from "../../assets/images/privacy-icon.png";
import Properties from "../../assets/images/properties-icon.png";
import Setting from "../../assets/images/setting-icon.png";
import ReportIcon from "../../assets/images/term-icon.png";
import Term from "../../assets/images/terms.png";
import UserReport from "../../assets/images/user report.png";
import UserManagement from "../../assets/images/userMan.png";
import { socket } from "../../pages/chat/socket";
import "./style.scss";

import { useContext } from "react";
import { AppContext } from "../../App";
import { reset } from "../../pages/Login/reducer";
const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [roleName, setRoleName] = useState("");
  const userCred = getCurrentUser()?.role;
  const { setTableStoreContext, setPageScroll } = useContext(AppContext);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [connected, setConnected] = useState(false);
  const [enquiryCount, setEnquiryCount] = useState();

  const loginPermission = useSelector((state) => state.login.userPermission);
  const loginData = useSelector((state) => state.login.loginData);

  const sideBarClick = searchParams.get("route");
  const menuId = useSelector((state) => state.login.menuId);
  let dashboardPermission =  loginPermission?.includes(menuId.dashboard);
  let buyerdashboardPermission = loginPermission?.includes(menuId.enterpriseBuyerDashboard);
  let sellerdashboardPermission = loginPermission?.includes(menuId.enterpriseSellerDashboard);
  let agentdashboardPermission = loginPermission?.includes(menuId.agentDashboard);
  let noRedirectionTable = [
    "/dashboard",
    "/admin",
    "/managerole",
    "/enquiry",
    "/coupon",
    "/subscription",
    "/contentManagement",
    "/locationManagement",
    "/privacyPolicy",
    "/termsConditions",
    "/reports",
    "/buyerReport",
    "/sellerReport",
    "/agentReport",
    "/notification",
    "/settings",
  ];
  const removeContext = () => {
    setTableStoreContext([]);
    setPageScroll([]);
  };

  useEffect(() => {
    userCred?.forEach((item) => {
      setRoleName(item.name);
      // console.log({ role: item.name });
    }); // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (
      noRedirectionTable.includes(location.pathname) ||
      sideBarClick === "yes"
    ) {
      removeContext();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("keydemand_user"));
    let enquiry_count = 0;
    setEnquiryCount(enquiry_count);
    const eventHandler = () => setConnected(true);
    const eventId = user._id;
    socket.on(eventId, eventHandler);
    socket.on(eventId, (newMessage) => {
      if (
        newMessage?.enquiryCount ||
        String(newMessage?.enquiryCount) === "0"
      ) {
        localStorage.setItem(
          "enquiry_count",
          JSON.stringify(newMessage?.enquiryCount)
        );
        setEnquiryCount(newMessage?.enquiryCount);
        setEnquiryCount(JSON.parse(localStorage.getItem("enquiry_count")));
      }
    });
    // unsubscribe from event for preventing memory leaks
    return () => {
      socket.off(eventId, eventHandler);
    };
  }, []);

  const logout = () => {
    dispatch(reset());
    localStorage.clear();
    navigate("/login");
  };

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <li
        variant="link"
        onClick={decoratedOnClick}
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        {children}
      </li>
    );
  };

  const reportLinks = [
    // {
    //   to: "/reports",
    //   text: "Statistics",
    //   imgSrc: ReportIcon,
    // },
    {
      to: "/buyerReport",
      text: "Enterprise buyer",
      imgSrc: ReportBuyer,
      state: { user: "buyer" },
      permissionKey: "enterpriseBuyerReports",
    },
    {
      to: "/sellerReport",
      text: "Enterprise seller",
      imgSrc: ReportSeller,
      state: { user: "seller" },
      permissionKey: "enterpriseSellerReports",
    },
    {
      to: "/agentReport",
      text: "Agent",
      imgSrc: ReportAgent,
      state: { user: "agent" },
      permissionKey: "agentReports",
    },
  ];

  let customerView =
    loginPermission?.includes(menuId.customerBuyer) ||
    loginPermission?.includes(menuId.customerSeller) ||
    loginPermission?.includes(menuId.customerAgents);
  let companyView =
    loginPermission?.includes(menuId.company) ||
    loginPermission?.includes(menuId.companyBuyer);

  return (
    <div className="sidebar">
      <nav className={props.handleShow ? "mobileSide" : "sidebar customScroll"}>
        <ul>
          {dashboardPermission && (buyerdashboardPermission || sellerdashboardPermission || agentdashboardPermission) ? (
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Dashboard} alt="Dashboard" />
                </div>
                <div>Dashboard</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.admin) ? (
            <li>
              <NavLink
                to="/admin"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Admin} alt="Dashboard" />
                </div>
                <div>Admin Team </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          {customerView || companyView ? (
            <li>
              <Accordion className="active" defaultActiveKey="0">
                <CustomToggle eventKey="0">
                  <img
                    src={UserManagement}
                    alt="UserManagement"
                    className="p-15 cursor-pointer "
                  />
                  User Management
                </CustomToggle>
                <Accordion.Collapse eventKey="0">
                  <ul className="p-2">
                    {companyView ? (
                      <li>
                        <NavLink
                          to="/company?route=yes"
                          activeClassName="active"
                        >
                          <div>
                            <img src={CompanyLogo} alt="CompanyLogo" />
                          </div>
                          Company
                        </NavLink>
                      </li>
                    ) : null}
                    {customerView ? (
                      <li>
                        <NavLink
                          to="/customer?route=yes"
                          activeClassName="active"
                        >
                          <div>
                            <img src={Customer} alt="Customers" />
                          </div>
                          Customers
                        </NavLink>
                      </li>
                    ) : null}
                  </ul>
                </Accordion.Collapse>
              </Accordion>
            </li>
          ) : (
            ""
          )}

          {roleName === "admin" &&
          loginData?.role &&
          roleName === loginData?.role[0]?.name ? (
            <li>
              <NavLink
                to="/managerole"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Brand} alt="Dashboard" />
                </div>
                <div>Manage Roles</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.enquiry) ? (
            <li>
              <NavLink
                to="/enquiry"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Brand} alt="Dashboard" />
                </div>
                <div className="notification">
                  Enquiries
                  {enquiryCount && enquiryCount > 0 ? (
                    <div className="notification-count"></div>
                  ) : (
                    ""
                  )}
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.properties) ? (
            <li>
              <NavLink
                to="/properties"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => removeContext()}
              >
                <div>
                  <img src={Properties} alt="Dashboard" />
                </div>
                <div>Properties</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.demand) ? (
            <li>
              <NavLink
                to="/demand"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => removeContext()}
              >
                <div>
                  <img src={Demand} alt="Dashboard" />
                </div>
                <div>Demand</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.coupon) ? (
            <li>
              <NavLink
                to="/coupon"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Coupon} alt="coupon" />
                </div>
                <div>Discount Coupon</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.subscription) ? (
            <li>
              <NavLink
                to="/subscription"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Brand} alt="Dashboard" />
                </div>
                <div>Subscription</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.contentManagement) ? (
            <li>
              <NavLink
                to="/contentManagement"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Content} alt="Dashboard" />
                </div>
                <div>Content Management</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          {loginPermission?.includes(menuId.locationManagement) ? (
            <li>
              <NavLink
                to="/locationManagement"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <MdLocationOn color="rgb(196,196,196)" size={20} />
                </div>
                <div>Location Management</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          {loginPermission?.includes(menuId.reports) ? (
            <li>
              <NavLink
                to="/reports"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={ReportIcon} alt="StatisticalReport" />
                </div>
                <div>Statistical Report</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          {loginPermission?.includes(menuId.reports) ? (
            <li>
              <Accordion className="active" defaultActiveKey="0">
                <CustomToggle eventKey="0">
                  <img
                    src={UserReport}
                    alt="UserManagement"
                    className=" cursor-pointer sidebar-icons-accordian"
                  />
                  User Reports
                </CustomToggle>
                <Accordion.Collapse eventKey="0">
                  <ul className="p-2">
                    {reportLinks.map((link, index) => {
                      if (
                        loginPermission?.includes(menuId[link?.permissionKey])
                      ) {
                        return (
                          <li key={index}>
                            <NavLink
                              to={link.to}
                              activeClassName="active"
                              state={link.state}
                            >
                              <div>
                                <img
                                  src={link.imgSrc}
                                  alt={`reports ${link.text}`}
                                  className="sidebar-icons-nested"
                                />
                              </div>
                              {link.text}
                            </NavLink>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </Accordion.Collapse>
              </Accordion>
            </li>
          ) : (
            ""
          )}

          {loginPermission?.includes(menuId.notification) ? (
            <li>
              <NavLink
                to="/notification"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img
                    src={Notification}
                    alt="reports"
                    className="sidebar-icons"
                  />
                </div>
                <div>Notification</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.privacyPolicy) ? (
            <li>
              <NavLink
                to="/privacyPolicy"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Privacy} alt="Dashboard" />
                </div>
                <div>Privacy Policy</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.termsConditions) ? (
            <li>
              <NavLink
                to="/termsConditions"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Term} alt="Dashboard" />
                </div>
                <div>Terms and conditions</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {loginPermission?.includes(menuId.settings) ? (
            <li>
              <NavLink
                to="/settings"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div>
                  <img src={Setting} alt="settings" />
                </div>
                <div>Settings</div>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          <li>
            <NavLink
              to="/login"
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={logout}
            >
              <div>
                <img src={LogOut} alt="Dashboard" />
              </div>
              <div>Logout</div>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};
export default Sidebar;

import React, { useEffect, useRef, useState } from "react";
import { getData, postGetData } from "../../../api";
import { Row, Col, Form } from "react-bootstrap";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select";
import Button from "../../../components/Form/Button";
import { OfficeSpaceAdditonalnput } from "../options";
import MultiSelect from "../../../components/select/dropDown";
import { useLocation } from "react-router-dom";
import { CoworkSpaceAdditonalnput } from "../coworkingSpaceOptions";
import { nearestConnectivityList } from "../../../components/DropdownOptions";
import CustomeMultiSelect from "../../../components/CustomeMultiselect";
const CoworkingSpaceAdditionalDetails = ({
  gotoStep,
  propertyDetail,
  step,
  properytyObject,
  setProperyObject,
  coworkingSpaceAdditionalStepButtonRef,
  coworkingSpaceAdditionalStepFormRef,
  setChanged,
  amenietiesError,
  setAmenietiesError,
  nearestLocationError,
  setNearestLocationError,
  coworkAdditionalDetailDivRef
}) => {
  const [properytyData, setPropertyData] = useState([]);
  // const [properytyObject, setProperyObject] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datasAmenitey, setDatasAmenitey] = useState([]);
  const [datasNearestConnectivity, setDatasNearestConnectivity] = useState([]);
  const [idealData, setIdealData] = useState([]);
  const inputRef = useRef(null)

  useEffect(() => {
    if(!propertyDetail?.additionalDetails) {
      // setProperyObject({})
      setNearestLocationError({})
      setAmenietiesError(false)
    }
  }, [])
  const handleChange = (e, item) => {
    const { name } = item;
    const value = e.target?.value;
    setProperyObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const onChangeStationName = (value, stationType) => {
    delete nearestLocationError[stationType]
    const newNearestConnectivityArr = properytyObject?.nearestConnectivity.map((station) => {
      if(station?.value === stationType) {
        return {...station, stationName: value}
      } else {
        return station
      }
    })
    setProperyObject((prev) => {
      return {...prev, nearestConnectivity: newNearestConnectivityArr}
    })
  }

  const handleMultiChange = (selectedData, selectedname) => {
    setAmenietiesError(false)
    const optionData = { ...properytyObject };
    optionData[selectedname] = selectedData;
    setProperyObject(optionData);
    setChanged(true)
  };
  const handleAmenitey = async () => {
    let url = "/amenity?type=Flex%20Space";
    const apiDatas = await getData(url, {}, {});
    const { statusCode, data } = apiDatas;
    if (statusCode === 200) {
      data?.forEach((val) => {
        val.label = val.name;
        val.value = val._id;
      });
      setDatasAmenitey(data);
      setDatasNearestConnectivity(nearestConnectivityList)
    }
  };
  // const getIdealForApiData = async () => {
  //   let url = "/endUse/getEndUses?type=Co-working%20space";
  //   // let objData = {
  //   //   type: "Office space",
  //   // };
  //   const newData = await getData(url, {}, {});
  //   const { statusCode, data, status } = newData;
  //   if (status && statusCode === 200) {
  //     data.forEach((val) => {
  //       val.label = val.name;
  //       val.value = val._id;
  //     });
  //     setIdealData(data);
  //   }
  // };
  useEffect(() => {
    setPropertyData(CoworkSpaceAdditonalnput);
    handleAmenitey();
    // getIdealForApiData();
    CoworkSpaceAdditonalnput.forEach((item) => {
      setProperyObject((prev) => ({
        ...prev,
        [item.name]: "",
      }));
    });
    if (propertyDetail?.additionalDetails) {
      let exitingObj = {
        propertyAge: propertyDetail?.additionalDetails.propertyAge,
        bikeParking: propertyDetail?.additionalDetails.bikeParking,
        carParking: propertyDetail?.additionalDetails.carParking,
        otherAmenities: propertyDetail?.otherAmenities,
        availablefloors: propertyDetail?.additionalDetails?.availablefloors,
        nearestConnectivity: propertyDetail?.additionalDetails?.nearestConnectivity,
        businessHours: propertyDetail?.additionalDetails?.businessHours,
        availableFloor:propertyDetail?.additionalDetails?.availableFloor 
      };
      setProperyObject(exitingObj);
    }
  }, []);
  const properyDetailUpdate = async (data) => {
    const res = await postGetData("/property", {}, data);
    const { statusCode } = res;
    if (statusCode === 200) {
      setLoading(false);
      gotoStep(3, true);
    }
  };
  const gotoBack = () => {
    gotoStep(step - 1);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA') {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <div className="property-details-container pt-4" ref={coworkAdditionalDetailDivRef}>
      <Row>
        <Col md="1" />
        <Col md="10">
          <h4>Additional Details</h4>
          <Form noValidate validated={validated} onSubmit={handleSubmit} ref={coworkingSpaceAdditionalStepFormRef}>
            <Row>
              
              <Col md="6">
                <MultiSelect
                  onChange={(e) => handleMultiChange(e, "otherAmenities")}
                  isMulti={true}
                  options={datasAmenitey}
                  value={properytyObject["otherAmenities"]}
                  lable="Amenities available *"
                  placeholder="Select Amenities"
                  isInvalid = {amenietiesError}
                  errormessage = {amenietiesError ? 'Please select an amenities' : ''}
                />
              </Col>
              {properytyData &&
                properytyData.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.type == "text" || item.type == "number" ? (
                      <Col md="6">
                        <Input
                          {...item}
                          value={properytyObject[item.name]}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </Col>
                    ) : null}
                    {item.type == "select" && (item.name !== "powerBackup" && item.name !== "availablefloors"  && item.name !== "mezzainineAvailable") ? (
                      <Col md="6">
                        <Select
                          {...item}
                          value={properytyObject[item.name]}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </Col>
                    ) : null}
                    {item.type == "select" && (item.name === "powerBackup" || item.name === "availablefloors"  || item.name === "mezzainineAvailable") && JSON.parse(localStorage.getItem('keydemand_data'))?.type === "Developer" ? (
                      <Col md="6">
                        <Select
                          {...item}
                          value={properytyObject[item.name]}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </Col>
                    ) : null}
                  </React.Fragment>
                ))}
              <Col md="6">
                <CustomeMultiSelect
                  onChange={(e) => handleMultiChange(e, "nearestConnectivity")}
                  isMulti={true}
                  options={nearestConnectivityList}
                  value={properytyObject["nearestConnectivity"]}
                  lable="Nearest Connectivity"
                  placeholder="Select nearest connectivity"
                  onChangeStationName = {onChangeStationName}
                  nearestLocationError = {nearestLocationError}
                  setNearestLocationError = {setNearestLocationError}
                />
              </Col>
              <Col md="12" hidden={true}>
                <div className="text-end">
                  <Button
                    type="button"
                    className="pre-btn  me-3"
                    onClick={gotoBack}
                  >
                    Previous
                  </Button>
                  <Button
                    type="submit"
                    className="next-btn p-0"
                    loading={loading}
                    _ref={coworkingSpaceAdditionalStepButtonRef}
                  >
                    Next
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default CoworkingSpaceAdditionalDetails;

import { useRef, useEffect, useState } from "react";
import { MapContainer, useMap, TileLayer, Marker, Popup } from "react-leaflet";
import styles from "./controlling-the-map-from-outside-the-map.module.css";
import * as L from "leaflet";
import Accordion from "react-bootstrap/Accordion";
import location from '../../assets/images/location.png'
import "./style.scss";
import { camelCaseConversion } from "../../custom/camelCaseConversion";
const tileLayer = {
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};
const MapWrapper = (props) => {
  const [selected, setSelected] = useState();
  const [points, setPoints] = useState([]);
  const [center, setCenter] = useState([]);
  const [nearbyListShow, setNearbyListShow] = useState(false);
  const [accordionClose, setAccordionClose] = useState(false);
  const [localityData,setLocalityData] = useState({})
  const [selectedLocalityIndex,setSelectedLocalityIndex] = useState()

  const LeafIcon = L.Icon.extend({
    options: {}
  });

  useEffect(() => {
    setPoints(props?.data?.places);
    setCenter(props?.data?.center);
  }, [props]);

  const blueIcon = new LeafIcon({
    iconUrl:location,
    iconSize: [45, 50],
  }),
   redIcon = new LeafIcon({
    iconUrl:
      "https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon.png"
  });

  const ListMarkers = ({ onItemClick }) => {
    return (
      <div className="marker_col d-flex">
        <div className={styles.markersList + " customScroll"}>
          {points &&
            points.length > 0 &&
            points.map(({ title, viewUrl }, index) => (
              <div
                className={styles.placeColumn}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick(index);
                }}
              >
                <span className={index === selected ? styles.iconSelectedContainer : styles.iconContainer}>
                  <img src={viewUrl} className={styles.iconImg} alt="listImg"/>
                </span>
                <div title={title} className={index === selected ? styles.markerSelectedItem : styles.markerItem}>{title}</div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const MyMarkers = ({ data, selectedIndex }) => {
    return data?.map((item, index) =>
      index === 0 && (
        <PointMarker
          key={index}
          content={item.title}
          center={{ lat: item.lat, lng: item.lng }}
          openPopup={selectedIndex === index}
        />
      ) 
    );
  };
  const LocalityContent =({data}) =>{
    return <div>
      <div>
        {data?.name}
      </div>
      <div className="d-flex justify-content-between">
        <p className="mb-0 mt-2">{camelCaseConversion(localityData?.title)}</p>
        <p className="mb-0 mt-2">{data?.distance}</p>
      </div>
    </div>
  }
  const OtherMarkers = ({ data, selectedIndex }) => {
    return  data.nearbyPlaces.map((item, index) =>
          <PointMarker
          key={index}
          content={<LocalityContent data = {item}/>}
          center={{ lat: item.lat, lng: item.lng }}
          openPopup={selectedIndex === index}
        />
    );
  };

  const PointMarker = ({ center, content, openPopup }) => {
    const map = useMap();
    const markerRef = useRef(null);

    useEffect(() => {
      if (openPopup) {
        map.flyToBounds([center]);
        markerRef.current.openPopup();
      }
    }, [map, center, openPopup]);

    return (
      <Marker 
      icon={content === 'Property' ? blueIcon : redIcon} 
      ref={markerRef} position={center}>
        <Popup>{content}</Popup>
      </Marker>
    );
  };

  function handleItemClick(index) {
    setSelectedLocalityIndex()
    if(index > 0){
      setNearbyListShow(true);
     points.map((item,i)=>{
      if(i === index){
        setLocalityData(item)
      }
      return item
     })
    }
    else{
      setNearbyListShow(false);
    }
    setSelected(index);
  }

  const handelAccordionHeaderClick = () => {
    setAccordionClose(!accordionClose);
  };
  const handleClickLocalityItem =(index) =>{
    setSelectedLocalityIndex(index)
  }
  useEffect(()=>{
if(localityData?.title){
  setAccordionClose(false);
}

  },[localityData?.title])
  return (
    <>
      {center && center.length > 0 ? (
        <>
          <MapContainer
            center={center}
            zoom={16}
            scrollWheelZoom={false}
            style={{ height: "40vh", width: "100wh" }}
          >
            {nearbyListShow && (
              <div
                className={accordionClose ? "map-card map-close" : "map-card"}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey={"1"} key={1}>
                    <Accordion.Header onClick={handelAccordionHeaderClick}>
                     
                      <img src={localityData?.viewUrl} className={styles.iconImg} alt="viewUrl"/>
                      <span style={{marginLeft:"10px"}}>
                      {`${localityData?.count} ${camelCaseConversion(localityData?.title)}s around this property`}
</span>                   
                     </Accordion.Header>
                  
                   <div className="map-accordion-data-container customScroll">
                   {localityData?.nearbyPlaces.map((item,index)=>{
                    return(
                      <div key={index} className="map-card-accordion-content" onClick={()=>handleClickLocalityItem(index)}>
                        <p className="mb-0">{item?.name}</p>
                        <p className="mb-0">{item?.distance}</p>
                      </div>
                    )
                   })}
                   </div>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
            <TileLayer {...tileLayer} />
            <MyMarkers selectedIndex={selected} data={points} />
            {selected > 0 ? 
            <OtherMarkers selectedIndex={selectedLocalityIndex} data={localityData} /> :""
          }
          </MapContainer>
          <ListMarkers data={points} onItemClick={handleItemClick} />
        </>
      ) : (
        <p className="text-center">No data found</p>
      )}
    </>
  );
};

export default MapWrapper;

import { useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";

import { Form } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ReactPaginate from "react-paginate";
import filterFactory from "react-bootstrap-table2-filter";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

import "./style.scss";

export default function MainTable(props) {
  const [tableData, setTableData] = useState([]);
  const [selectRowId, setSelectRowId] = useState([]);


  useEffect(() => {
    setSelectRowId([])
  }, [props?.customer])
  const formatDatas = (initArr) => {
    let sn = 0;
    let filteredarray = [];
    initArr?.forEach((element) => {
      const offVal = (props.offset - 1) * props.perPage + (sn + 1);
      const offsetValue = props.offset ? offVal : sn + 1;

      filteredarray.push({
        ...element,
        sno: offsetValue,
        // action: element.id,
      });
      sn++;
    });
    return filteredarray;
  };

  useEffect(() => {
    setTableData(formatDatas(props.data));
  }, [props.data]);

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    style: { background: "#eee" },
    selected: selectRowId,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectRowId((prev) => [...prev, row?.keyId]);
      } else {
        let index = selectRowId.indexOf(row?.keyId);
        if (index !== -1) {
          const dup = [...selectRowId];
          dup.splice(index, 1);
          setSelectRowId([...dup]);
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        tableData.forEach((item) => {
          setSelectRowId((prev) => [...prev, item.keyId]);
        });
      } else {
        let copySelected = [...selectRowId];
        let removedValue = rows.map((item) => item.keyId);
        copySelected = copySelected.filter(
          (item) => !removedValue.includes(item)
        );
        setSelectRowId(copySelected);
      }
    },
  };
  const rmvDuplicate = (arr) => {
    let uniqueArr = arr.filter((item, index) => {
      return arr.indexOf(item) === index;
    });
    return uniqueArr;
  };

  useEffect(() => {
    if (selectRowId && selectRowId.length > 0) {
      props.getSelectedRow(rmvDuplicate(selectRowId));
    } else {
      props.getSelectedRow([]);
    }
  }, [selectRowId, setSelectRowId]);

  useEffect(() => {
    if (props.selectedRow && props.selectedRow.length > 0) {
      setSelectRowId([...props.selectedRow]);
    }
  }, []);

  return (
    <ToolkitProvider keyField="keyId" data={tableData} columns={props.columns}>
      {(prop) => (
        <div>
          <BootstrapTable
            keyField="keyId"
            bordered={false}
            {...prop.baseProps}
            noDataIndication={"No Data Found"}
            selectRow={selectRow}
            //   bootstrap4={true}
            headerWrapperClasses="thead-dark"
            bodyClasses="tableBody"
            wrapperClasses="table-responsive customScroll"
            // pagination={ paginationFactory(options) }
            filter={filterFactory()}
          />
          {props.pageCount > 0 && (
            <div className="p-1 px-2 pagination-content ">
              <div className="d-flex  align-items-center w-80   flex-shrink-1">
                <label className="page">Rows per page: &nbsp;</label>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    defaultValue={props.perPage}
                    onChange={(e) => props.handelChangePerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </Form.Select>
                </div>
              </div>
              <div className="pagenationwrapper d-flex  justify-content-end">
                <div className="mr-2 text-end  p-2  page pl-5   d-flex justify-content-end align-items-center page-count">
                  {`${tableData[0]?.sno >= 0 ? tableData[0]?.sno : "0"}-${
                    tableData[tableData.length - 1]?.sno >= 0
                      ? tableData[tableData.length - 1]?.sno
                      : "0"
                  } of ${props.totalCount ? props.totalCount : ""}`}
                </div>
                <div className="d-flex align-items-start justify-content-end page-between flex-shrink-1 ">
                  <div
                    className="page__arrow"
                    onClick={() => props.setOffset(1)}
                  >
                    <HiOutlineChevronDoubleLeft
                      size={15}
                      style={{ color: "black" }}
                    />
                  </div>
                  <ReactPaginate
                    breakLabel=""
                    nextLabel={<IoChevronForwardOutline size={15} />}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={4}
                    pageCount={props.pageCount}
                    previousLabel={<IoChevronBackOutline size={15} />}
                    pageClassName="page"
                    pageLinkClassName="page"
                    previousClassName="page"
                    previousLinkClassName="page"
                    nextClassName="page"
                    nextLinkClassName="page"
                    activeClassName={"pagination-active"}
                    renderOnZeroPageCount={null}
                    onPageChange={(pageNumber) =>
                      props.setOffset(pageNumber.selected + 1)
                    }
                    forcePage={props.offset - 1}
                  />
                  <div
                    className="page__arrow"
                    onClick={() => props.setOffset(props.pageCount)}
                  >
                    <HiOutlineChevronDoubleRight
                      size={15}
                      style={{ color: "black" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </ToolkitProvider>
  );
}

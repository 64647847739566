import { Form } from "react-bootstrap";
const Checkbox = ({
  label,
  name,
  value,
  onChange,
  size,
  disabled,
  note,
  customLabel,
  
}) => {
  return (
    <Form.Group className={"form-group " + size} controlId={name}>
      <Form.Check
        inline
        label={label}
        name={name}
        type="checkbox"
        className="checkBoxs"
        id={name}
        value={value}
        checked={value}
        onChange={onChange}
        disabled={disabled}
      />
       {customLabel && <label
                            style={{
                              fontStyle: "normal",
                              fontWeight: "700",
                              fontSize: "14px",
                              color: "#000000",
                              marginBottom: "0px",
                            }}
                          >
                            {customLabel}
                          </label>}

      {note ? <div className="text-muted f-14">{note}</div> : null}
    </Form.Group>
  );
};

export default Checkbox;

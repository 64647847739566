export let demandValues = [
  {
    lable: "City",
    required: "locationRequired",
    value: "location",
    subText: "",
    showLabel: ["Retail", "Office space", "Flex Space"],
  },
  // {
  //   lable: "Property Type",
  //   required: "propertyTypeRequired",
  //   value: "propertyType",
  //   subText: "",
  //   showLabel: ["Flex Space"],
  // },
  {
    lable: "Pincode",
    required: "pincodeRequired",
    value: "pincode",
    subText: "",
    showLabel: ["Retail", "Office space", "Flex Space"],
  },


  // {
  //   lable: "Image required",
  //   required: "imageRequired",
  //   value: "imageRequired",
  //   subText: "",
  //   hideLabel: true,
  //   showLabel: ["Retail"],
  // },
  {
    lable: "Google location required",
    required: "googleLocationRequired",
    value: "googleLocationRequired",
    subText: "",
    showLabel: ["Office space"],
  },
  // {
  //   lable: "Video required",
  //   required: "videoRequired",
  //   value: "videoRequired",
  //   subText: "",
  //   hideLabel: true,
  //   showLabel: ["Retail"],
  // },
  {
    lable: "Minimum frontage in feet",
    required: "frontageRequired",
    value: "frontage",
    subText: "ft",
    hideLabel: true,
    showLabel: ["Retail"],
  },
  // {
  //   lable: "Minimum Access Road Width in feet",
  //   required: "accessRoadRequired",
  //   value: "accessRoad",
  //   subText: "ft",
  //   hideLabel: true,
  //   showLabel: ["Retail"],
  // },
  {
    lable: "Age of property",
    required: "propertyAgeRequired",
    value: "propertyAge",
    subText: "",
    showLabel: ["Retail", "Office space"],
  },

  {
    lable: "Minimum clear ceiling height in feet",
    required: "ceilingHeightRequired",
    value: "ceilingHeight",
    subText: "ft",
    hideLabel: true,
    showLabel: ["Retail"],
  },
  {
    lable: "Ownership",
    required: "ownerShipRequired",
    value: "ownerShip",
    subText: "",
    hideLabel: true,
    showLabel: ["Retail"],
  },
  // {
  //   lable: "Power backup",
  //   required: "powerBackupRequired",
  //   value: "powerBackupRequired",
  //   subText: "",
  //   hideLabel: true,
  //   showLabel: ["Retail"],
  // },
  {
    lable: "Fitouts required",
    required: "fitOutsRequired",
    value: "fitOutsStatus",
    subText: "",
    showLabel: ["Retail"],
  },
  {
    lable: "Toilet required",
    required: "toiletRequired",
    value: "toiletStatus",
    subText: "",
    hideLabel: true,
    showLabel: ["Retail"],
  },
  {
    lable: "Parking required",
    required: "parkingRequired",
    value: "parkingStatus",
    subText: "",
    hideLabel: true,
    showLabel: ["Retail"],
  },

  {
    lable: "Lease tenure in years",
    required: "tenureRequired",
    value: "tenure",
    subText: "years",
    hideLabel: true,
    showLabel: ["Retail", "Office space"],
  },
  {
    lable: "Willing to pay brokerage?",
    required: "brokerageRequired",
    value: "brokerageRequired",
    subText: "",
    showLabel: ["Retail", "Office space"],
  },
  {
    lable: "Lock in period",
    required: "lockInPeriodRequired",
    value: "lockInPeriod",
    subText: "years",
    showLabel: ["Retail", "Office space"],
  },
  // {
  //   lable: "Rent free period",
  //   required: "rentFreePeriodRequired",
  //   value: "rentFreePeriod",
  //   subText: "",
  //   showLabel: [ "Office space"],
  // },

  // {
  //   lable: "Transaction closure timeline",
  //   required: "transactionTimelineRequired",
  //   value: "transactionTimeline",
  //   subText: "",
  //   hideLabel: true,
  //   showLabel: ["Retail"],
  // },
  {
    lable: "End use",
    required: "endUseRequired",
    value: "endUse",
    subText: "",
    showLabel: ["Retail", "Office space", "Flex Space"],
  },
  {
    lable: "OC Required",
    required: "ocStatusRequired",
    value: "ocStatus",
    subText: "",
    hideLabel: true,
    showLabel: ["Retail", "Office space"],
  },

  {
    lable: "Wash room required",
    required: "washRoomRequired",
    value: "washRoom",
    subText: "",
    showLabel: ["Office space"],
  },
  {
    lable: "Car parking required",
    required: "carParkingRequired",
    value: "carParking",
    subText: "",
    showLabel: ["Office space", "Flex Space"],
  },
  {
    lable: "Floor",
    required: "floorRequired",
    value: "floor",
    subText: "",
    showLabel: ["Retail", "Office space"],
  },
  {
    lable: "Bike parking required",
    required: "bikeParkingRequired",
    value: "bikeParking",
    subText: "",
    showLabel: ["Office space", "Flex Space"],
  },
  {
    lable: "Other interested location",
    required: "otherInterestedLocationRequired",
    value: "otherInterestedLocation",
    subText: "",
    showLabel: ["Retail", "Office space", "Flex Space"],
  },

  {
    lable: "Other amenities",
    required: "otherAmenitiesRequired",
    value: "otherAmenities",
    subText: "",
    showLabel: ["Retail", "Office space", "Flex Space"],
  },
  {
    lable: "Fit-out status",
    required: "fitOutsRequired",
    value: "fitOuts",
    subText: "",
    showLabel: ["Office space"],
  },
  {
    lable: "Mezzanine Required",
    required: "mezzainineRequired",
    value: "mezzainineStatus",
    subText: "",
    showLabel: ["Retail"],
  },
];

import {Tabs, Tab} from 'react-bootstrap';

import './style.scss'

const CTab =  (props) =>{

    return(
        <div className="tab-containered">
            <Tabs 
            id="controlled-tab-example"
            activeKey={props.activeKey}
            onSelect={props.onSelect}
            className="mb-3"
            >
                {props.tabDetails && props.tabDetails.map((item)=>{
                    return(
                        <Tab key={item.eventKey} eventKey={item.eventKey} title={item.title}>
                            {item.content}
                        </Tab>
                    )   
                })}
            </Tabs>
        </div>
        
    )
}
export default CTab;
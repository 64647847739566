import { Modal } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import Loader from "../Loader";

//scss
import "./style.scss";

const FormModal = ({
  show,
  onClose,
  heading,
  children,
  size,
  loading,
  subText,
  className,
  hideCloseIcon,
  headerClassName,
  top,
  headerPadding,
  bodyPadding,
  closeIconPosition,
  right,
}) => {
  return (
    <div>
      {!loading && (
        <Modal show={show} centered onHide={onClose} size={size}>
          <Modal.Header
            className={headerClassName}
            style={{ paddingBottom: headerPadding ? headerPadding : "" }}
          >
            <div>
              <Modal.Title>{heading}</Modal.Title>
              {subText ? <p className="textheader">{subText}</p> : ""}
            </div>
            {hideCloseIcon ? null : (
              <CloseButton
                style={{
                  position: closeIconPosition ? closeIconPosition : "",
                  top: top ? top : "",
                  right: right ? right : "",
                }}
                onClick={() => onClose(false)}
              />
            )}
          </Modal.Header>
          <Modal.Body
            className={className}
            style={{ paddingTop: bodyPadding ? bodyPadding : "" }}
          >
            <div>{children}</div>
          </Modal.Body>
        </Modal>
      )}
      {loading && <Loader className={"fixed"}></Loader>}
    </div>
  );
};

export default FormModal;

import { useEffect, useState } from "react";
import Button from "../../components/Form/Button";
import { getData } from "../../api";
import Image from "../../assets/images/Livingroom1.png";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
const ReleventResponse = ({ propertId }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
  

    const [propertyResponseData, setResponsePropertyData] = useState([]);
   
    const getResponseProperty = async () => {
        setLoading(true);
        let url = `/property/propertyResponses/${propertId}`;
        const res = await getData(url, {}, {});
        if (res.status) {
            setResponsePropertyData(res.data)
            setLoading(false);
        } else {
            setLoading(false);
        }

    };
    useEffect(() => {
        getResponseProperty();

    }, []);

    const handelClickNavigateChat = () => {
        navigate("/chat")
    }

    return <div className="mt-5 ">
        {
            propertyResponseData.length === 0 && <div className="text-center f-16 fw-500"> No Data Found</div>
        }

        {propertyResponseData.length > 0 && propertyResponseData.map((property, index) => (
            <div className="existing-property-box p-3" key={index} >
                <div className="row">
                    <div className="row content">
                        <div className="d-flex justify-content-between ">
                            <div className="d-flex">
                                <div className="profile-image">
                                    <img src={property?.logo?.viewUrl ? property?.logo?.viewUrl : Image} alt="profileImage " />
                                </div>
                                <h5 className="px-2">{property?.name}</h5>
                            </div>
                            <div className="mb-2 d-flex align-items-center">
                                <Button className="btn-view me-3" onClick={handelClickNavigateChat}>view </Button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        ))}


        {loading && <Loader />}
    </div>;
};
export default ReleventResponse;

import  {  useState } from "react";

const Detail = ({customerData}) => {
  const [detail] = useState([
    {
      key:"name",
      title:"Poc name"
    },
    {
      key:"designation",
      title:"Designation"
    },
    {
      key:"email",
      title:"Email"
    },
    {
      key:"phoneNumber",
      title:"Phone number"
    }
  ]);
  let customerDatas = customerData?.pocDetails ? customerData?.pocDetails : {}

  return (
    <div className="detail-card">
           {Object.keys(customerDatas).length > 0 ?
      <>
       <label className="card-text-heading">Details</label>
      <div className="row">
        {detail.map((item,i) => {
          return (
            <div key={i} title={customerDatas[item.key]} className="detail-card-text f-14 mb-2">
              <label className="card-sub-text fw-500 f-14">
                {item.title} : {" "}
              </label>
              {customerDatas[item.key]}
            </div>
          );
        })}
      </div>
      </>:<center>No poc available</center>
}
    </div>
  );
};
export default Detail;

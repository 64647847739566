import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { AppContext } from "../../../../App";
import { getData, getPlanData, postImportData, postImportFlexData } from "../../../../api";
import Button from "../../../../components/Form/Button";
import ImportCsv from "../../../../components/ImportExport/Import";
import ImportErrorModal from "../../../../components/ImportExport/Import/importErrorModal";
import FormModal from "../../../../components/Modal";
import Tab from "../../../../components/Tab";
import { nameToLabel } from "../../../../utilities/commonFunction";
import { setScrollBackId } from "../../../../utilities/pageScroll";
import CompletedDemands from "./completedDemands";
import FeaturedDemands from "./featuredDemands";
import OngoingDemands from "./ongoingDemands";
const Demand = ({
  customerId,
  customerUserId,
  image,
  brandName,
  featuredPositioningStatus,
  access
}) => {
  const navigate = useNavigate();
  const {pageScroll,setPageScroll} = useContext(AppContext)

  let companyID = useSelector((state) => state.login.companyId);
  let tabKey = JSON.parse(localStorage.getItem("demandTabKey"));
  const [key, setKey] = useState(tabKey ? tabKey : "ongoingDemands");
  const [importStatus, setImportStatus] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [error, setError] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [propertyType, setPropertyType] = useState("");
  const [propertyTypeList, setPropertyTypeList] = useState("");
  const [pocList, setPocList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [searchCityList, setSearchCityList] = useState([]);
  const [fileUoloaded, setFileUoloaded] = useState(null);
  const fromPage = useSelector((state) => state.login.parentPage);
  const scrollStoreArray = [...pageScroll]
  const getpropertyType = async () => {
    let url = `/cityZones/checkZoneAvailability/${customerUserId}`;
    const response = await getData(url, {});
    const { status, data } = response;
    if (status === true) {
      if (data?.propertyType?.length > 0) {
        setPropertyTypeList(
          data?.propertyType?.map((item) => ({ name: item?.name }))
        );
      } else {
        setPropertyTypeList([]);
      }
    }
  };
  const getPocList = async (id) => {
    let url = `/brand/getAllPoc/${id}?type=demand`;
    const response = await getData(url, {}, {});
    const { statusCode, data } = response;
    if (statusCode === 200) {
      setPocList(data);
    } else {
      setPocList([]);
    }
  };
  const getSearchCityList = async () => {
    let url = `/brand/cities/${customerUserId}`;
    const response = await getData(url, {});
    const { status, data } = response;
    if (status === true) {
      let cities = nameToLabel(data?.city);
      setSearchCityList(cities);
    } else {
      setSearchCityList([]);
    }
  };
  useEffect(() => {
    if (customerUserId) {
      getpropertyType();
      getPocList(customerUserId);
      getSearchCityList();
    }
  }, []);

 
  const getPocCityList = async (id) => {
    let url = `/brand/poc/getcities/${customerId}/${id}`;
    const response = await getData(url, {}, {});
    const { statusCode, data } = response;
    if (statusCode === 200) {
      setCityList(data?.city);
    } else {
      setCityList([]);
    }
  };
  const onChangePoc = (id) => {
    if (id) {
      getPocCityList(id);
    } else {
      setCityList([]);
    }
  };

  const onClickAddDemand = () => {
    let url = `/demand/checkUserPlan?brandId=${customerId}`;
    getPlanData(url, {}, {}).then((response) => {
      if (response.status) {
        localStorage.removeItem("postDemandStep");
        localStorage.removeItem("demandId");
        localStorage.removeItem("propertyType");
        localStorage.removeItem("brandData");
        localStorage.removeItem("checkedDemand");
        let pageFrom =
          companyID && fromPage === "buyer"
            ? "reportsCompany"
            : fromPage === "buyer"
            ? "reportsCustomer "
            : "customer";

        navigate(
          companyID === ""
            ? "/customer/brandPage/PostDemand"
            : "/company/brandCompanyCreation/brandPage/PostDemand",
          {
            state: {
              brandId: customerId,
              userId: customerUserId,
              action: "add",
              brandName: brandName,
              page: pageFrom,
            },
          }
        );
      } else {
        Swal.fire({
          // title: header,
          text: response.message,
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };
  const setScrollEvent = (id,filter) => {
    let scrollArray = setScrollBackId("myPageDemand", id,filter,"", scrollStoreArray);
    setPageScroll(scrollArray);
  };

  const tabDetails = [
    {
      title: "Active Demands",
      content: (
      <>
      {key === "ongoingDemands" &&
        <OngoingDemands
        access={access}
        featuredPositioningStatus={featuredPositioningStatus}
        tabKey={key}
        importStatus={importStatus}
        id={customerUserId}
        brandId={customerId}
        image={image}
        searchCityList={searchCityList}
        brandName={brandName}
        setScrollEvent={setScrollEvent}
      />
      }
      </>
      ),
      eventKey: "ongoingDemands",
    },
    {
      title: "Inactive Demands",
      content: (
       <>
       {key === "completedDemands" &&
        <CompletedDemands
        access={access}
        searchCityList={searchCityList}
        id={customerUserId}
        brandId={customerId}
        image={image}
        tabKey={key}
        setScrollEvent={setScrollEvent}
      />
       }
       </>
      ),
      eventKey: "completedDemands",
    },
    {
      title: "Featured Demands",
      content: (
      <>
      {key === "featuredDemands" &&
        <FeaturedDemands
        access={access}
        searchCityList={searchCityList}
        featuredPositioningStatus={featuredPositioningStatus}
        tabKey={key}
        id={customerUserId}
        importStatus={importStatus}
        image={image}
        brandName={brandName}
        setScrollEvent={setScrollEvent}
      />
      }
      </>
      ),
      eventKey: "featuredDemands",
    },
  ];

  const onClickDownloadTemplate = () => {
    let url = `/template/downloadTemplate/?type=${
      propertyType === "Retail" ? "demand" : "officeSpaceDemand"
    }`;
    getPlanData(url, {}, {}).then((response) => {
      let a = document.createElement("a");
      a.href = response?.data?.viewUrl;
      a.download = "demand-template.xlsx";
      a.click();
    });
  };
  const onClickImportDemand = () => {
    setImportModal(true);
    setErrMsg("");
    setPropertyType("");
  };
  const onClickErrorClose = () => {
    setError(false);
  };
  const onClickImport = async(jsonArray, selectedObject) => {
    setImportStatus(false);
    let errObj = {};
    importFieldObject?.map((item) => {
      if (
        selectedObject[item] === "" ||
        Object.keys(selectedObject[item]).length === 0
      ) {
        errObj[item] = "This field is required";
      } else if (
        item === "location" &&
        selectedObject["location"].description === ""
      ) {
        errObj[item] = "This field is required";
      }
      return item
    });

    if (Object.keys(errObj).length > 0) {
      setErrorObj({ ...errObj });
    } else {
      if (
        jsonArray.length === 0 ||
        Object.keys(JSON.parse(jsonArray)).length === 0
      ) {
        errObj.fileSelected = "Please upload a CSV with data";
        setErrorObj({ ...errObj });
      } else {
        setBtnLoading(true);
        setErrorObj("");
        selectedObject.demands = JSON.parse(jsonArray);
        let data = { ...selectedObject };
        data.city = (selectedObject?.city || []).map((item) => item.label);
        delete data.fileSelected;
        delete data.userId;
        delete data.propertyType;
        let url = ""
        if(propertyType === "Flex Space") {
          let formData = new FormData();
          formData.append("file", fileUoloaded);
          formData.append("city", data?.city.join(', '));
          data = formData
          console.log({after: data})
           url = `/demand/flexDemandBulkUpload/${customerUserId}/${selectedObject?.userId}/${propertyType}`;
        } else {
           url = `/demand/demandBulkUpload/${customerUserId}/${selectedObject?.userId}/${propertyType}`;
        }

        const response = propertyType === "Flex Space" ? await postImportFlexData(url, {}, data) : await postImportData(url, {}, data)
          if (response.status) {
            setImportStatus(true);
            setImportModal(false);
            setBtnLoading(false);
            setCityList([]);
          } else {
            if (typeof response.message === "string") {
              errObj.fileSelected = response.message;
              setErrorObj({ ...errObj });
              setBtnLoading(false);
              Swal.fire({
                // title: header,
                text: response.message,
                icon: "warning",
                timer: 3000,
                buttons: false,
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
              });
            } else {
              setErrMsg(response.message);
              setError(true);
              setImportModal(false);
              setBtnLoading(false);
            }
          }
      }
    }
  };
  const importModalContent = () => {
    return (
      
        <ImportCsv
          customerCityData={cityList}
          errorObj={errorObj}
          setErrorObj={setErrorObj}
          loading={btnLoading}
          label={"Import Csv"}
          onClickDownloadTemplate={() => onClickDownloadTemplate()}
          onClickImport={(jsonArray, selectedObject) =>
            onClickImport(jsonArray, selectedObject)
          }
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          propertyTypeList={propertyTypeList}
          type="brand"
          pocList={pocList}
          getPocCityList={onChangePoc}
          setFileUoloaded = {setFileUoloaded}
        />
      
    );
  };
  const importFieldObject = [
    "city",
    // "location",
    "fileSelected",
    "userId",
    "propertyType",
  ];


  const closeImportModal = () => {
    setErrorObj({});
    setImportModal(false);
    setCityList([]);
  };
  return (
    <div>
      {importModal && (
        <FormModal
          show={importModal}
          onClose={closeImportModal}
          heading={"Import Csv"}
          customer={false}
          children={importModalContent()}
          size={"md"}
        ></FormModal>
      )}
      {errMsg.length > 0 && (
        <FormModal
          show={error}
          onClose={() => onClickErrorClose()}
          heading={"Error"}
          children={<ImportErrorModal data={errMsg} />}
          size={"md"}
        />
      )}
      <div className="d-flex justify-content-end mb-4 btn-container">
        {/* <Button className='btn-color-primary' disabled={access.importsAndExports?false:true} onClick={onClickExportDemand}>Export Demand</Button> */}
        <Button
          className="btn-color-primary"
          disabled={access.buyerImportsAndExports ? false : true}
          onClick={onClickImportDemand}
        >
          Bulk Upload
        </Button>
        <Button
          className="btn-color-primary"
          disabled={access.addDemand ? false : true}
          onClick={onClickAddDemand}
        >
          Add Demand
        </Button>
      </div>
      <Tab
        tabDetails={tabDetails}
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
        }}
      />
    </div>
  );
};
export default Demand;

import React from "react";
import { Button } from "react-bootstrap";

const ViewInStreetView = ({ cordinates }) => {
  const handleStreetView = async () => {
    const googleMapsApiKey = "AIzaSyAmjQV3IolzLipggThoN5tGGyG-YUjusWQ";
    const url = `https://maps.googleapis.com/maps/api/streetview?location=${cordinates[0]},${cordinates[1]}&size=600x300&key=${googleMapsApiKey}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          window.open(
            `https://www.google.com/maps?q=${cordinates[0]},${cordinates[1]}&layer=c&cbll=${cordinates[0]},${cordinates[1]}`
          );
          throw new Error("Network response was not ok");
        }
        return response.url;
      })
      .then((streetViewUrl) => {
        window.open(streetViewUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error fetching Street View:", error);
      });
  };
  return (
    <div>
      <Button
        className="btn-color-primary map_buttons"
        onClick={handleStreetView}
      >
        View in Street View
      </Button>
    </div>
  );
};

export default ViewInStreetView;

export const errorCheck = (customer, subscription) => {
    let errors = {}
    let requiredField = "This Field is Required"
    const customerArray =["brandNameRequired","companyName", "cities", "name", "state", "aboutCompany", "region", "propertyType" ,"logo"]
    const subscriptionArray =["propertyOrDemandSelect", "validityDays", "contactSuppliersSelect", "emailtoSuppliers", "userDashboard" , "alert" ,"referalCredits", "featuredDemand", "assistedUploading", "homePageSlotAndBanner", "relationshipManager", "rm" ]
    customerArray?.map(item =>{

        if(customer.hasOwnProperty(item)){
            if(item === "companyName" || item === "aboutCompany" || item === "name"){
                if (customer[item]=== ""|| customer[item].match(/^\s*$/)) {
                    errors[item] = requiredField;
                };
            }
            if(item === "logo"){
                if (customer.logo === ""  || Object.keys(customer.logo).length === 0) {
                    errors.logo = requiredField;
                };
            }
            if(item=== "brandNameRequired"){
                if(customer[item] === ""){
                    errors[item] = requiredField
                }
            }
           if(item === "cities" || item === "region" || item === "state" || item === "propertyType" ){
            if (customer[item]?.length === 0) {
                errors[item] = requiredField;
            }
           }
           return item; 
        }
        return item; 
    })
    subscriptionArray.map(item =>
        {

        if(subscription.hasOwnProperty(item)){
            if(item === "propertyOrDemandSelect"){
                if(subscription[item] === ""){
                    errors.propertyOrDemandSelect = requiredField
                }
                else if(subscription[item] === "Enter number"){
                    if (subscription.propertyOrDemand === null || subscription.propertyOrDemand === ""|| subscription.propertyOrDemand.match(/^\s*$/)) {
                        errors.propertyOrDemand = requiredField
                    }
                }
            }
            if(item === "contactSuppliersSelect"){
                if(subscription[item] === ""){
                    errors[item] = requiredField
                }
                else if(subscription[item] === "Enter number"){
                    if (subscription.contactSuppliers === null || subscription.contactSuppliers === ""|| subscription.contactSuppliers.match(/^\s*$/)) {
                        errors.contactSuppliers = requiredField
                    }
                }
            }
            if(item === "validityDays"){
                if (subscription[item] === null || subscription[item] === "" || isNaN(subscription[item])) {
                    errors[item] = requiredField;
                }
            }
            if(item === "emailtoSuppliers" || item ==="alert" || item === "featuredDemand" || item === "assistedUploading" || item === "homePageSlotAndBanner" || item === "relationshipManager"){
                if (subscription[item] === "Select" || subscription[item] === ""){
                    errors[item] = requiredField;
                }
            }
            if(item === 'userDashboard'){
                if (subscription[item] === null || subscription[item] === "Select" || subscription[item] === ""){
                    errors[item] = requiredField;
                }
            }
            if(item === "rm"){
                if (subscription.relationshipManager === "Yes" && (subscription?.rm === "" ||subscription?.rm === undefined || Object.keys(subscription?.rm)?.length === 0)) {
                    errors.rm = requiredField
                }
            }
           
        }
        return item; 
    })
    return errors
}
import  { useState,useEffect } from 'react';
//components
import FormModal from "../../components/Modal";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import Button from "../../components/Form/Button";
//api
import { getData, putData} from "../../api";
import { IoMdAddCircleOutline } from "react-icons/io"
import { MdRemoveCircleOutline } from "react-icons/md"
import "./style.scss"

export function AddLocation({
  onClose,
  show,
  value,
  action
}) {
  const [cityList, setCityList] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
 
  const [formAction, setFormAction] = useState("addLocation");
  const [formValues, setFormValues] = useState({});
  const [dynamicInput, setDynamicInput] = useState([{
    name: "",
    error: ""
  }]);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)

  //useEffect


 const getCityList = async () => {
  setInitialLoading(true)
    let url ="/cityZones/getLocalities?page=1&limit=0";
    const apiDatas = await getData(url);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      setCityList(data.localities);
  setInitialLoading(false)

    } else {
      setCityList([]);
      setInitialLoading(false)
    }
  };



  const addFields = () => {
    let newfield = {
      name: "",
      error: ""
    }
    setDynamicInput([...dynamicInput, newfield])
  }
  const removeFields = (index) => {
    let data = [...dynamicInput];
    data.splice(index, 1)
    setDynamicInput(data)
  }

  const handleFormChange = (index, event) => {
    let data = [...dynamicInput];
    data[index]["name"] = event.target.value.replace(/ {2,}/g,' ');
    data[index]["error"] = "";
    setDynamicInput(data)
  }

  const checkDynamicInputsError =()=>{
    let requriedMessage = "This Field Is Required"
    let duplicateMessage = "Found duplicate entry"
    let isError = false;
    let valueExistCheck = [];
    let updateErrorfields = [...dynamicInput].map((item) => {
    let isExist =item.name.trim().length > 0 ?valueExistCheck.includes(item.name.trim().replace(/ {2,}/g,' ')):false
      if(!isExist){
        valueExistCheck.push(item.name.trim().replace(/ {2,}/g,' '))
      }
      if (item.name.trim().length > 0 && !isExist) {
       
        return { ...item, error: "" }
      } else {
        isError = true;
        return { ...item, error: isExist?duplicateMessage:requriedMessage }
      }

    })
    setDynamicInput(updateErrorfields);
    
    return isError

  }

  const checkErrorMessage = (data, btnAction) => {
    let error = {};
    let requriedMessage = "This Field Is Required"
    switch (btnAction) {
      case "addZone":
        if (data.city === "" || data.cityId === "") {
          error.city = requriedMessage;
        }
        if (dynamicInput.length > 0 && checkDynamicInputsError()) {
            error.dynamicInput=requriedMessage
        }

        break;

      case "addLocation":

        if (data.city === "") {

          error.city = requriedMessage;
        }
        if (data.zone === "") {

          error.zone = requriedMessage
        }
        if (dynamicInput.length > 0 && checkDynamicInputsError()) {
          error.dynamicInput=requriedMessage
      }
        
        break;
      default:
        break;
    }



    return error

  }

  const handelLocationSubmit = async () => {
    const checkError = checkErrorMessage(formValues, "addLocation");
    setErrorMessage(checkError);

    let NoError = Object.values(checkError).every((v) => v.length === 0);

    if (NoError) {
      setFormSubmitLoading(true)
      let url = `/cityZones/addLocality/${value?.stateId}/${value?.cityId}`;
      let payLoad = {
        localities: dynamicInput
      }
      const locationData = await putData(url, {}, payLoad);
      const {message, status } = locationData;

      if (status) {
        setFormSubmitLoading(false)
        onClose("updated");
        setErrorMessage();
      } else if (!status && message === "Localities already exists") {
        setFormSubmitLoading(false)
        setDynamicInput(locationData?.data)


      } else {
        setFormSubmitLoading(false)
      }
    }

  }
  const handelZoneSubmit = async () => {
    const checkError = checkErrorMessage(formValues, "addZone");
    setErrorMessage(checkError);

    let NoError = Object.values(checkError).every((v) => v.length === 0);

    if (NoError) {
      setFormSubmitLoading(true)
      let url = `/cityZones/${formValues?.cityId}`;
      let payLoad = {
        zones: dynamicInput
      }
      const zoneData = await putData(url, {}, payLoad);
      const { message, status } = zoneData;

      if (status) {
        setFormSubmitLoading(false)
        onClose("updated");
        setErrorMessage();
      } else if (!status && message === "Zones already exists") {
        setFormSubmitLoading(false)
        setDynamicInput(zoneData?.data)

      } else {
        setFormSubmitLoading(false)
      }
    }

  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formAction === "addLocation") {
      handelLocationSubmit()

    }
    else if (formAction === "addZone") {
      handelZoneSubmit()

    }

  };
  const handelOnSelect = (selectedValue, name) => {
    errorMessage[name] = "";

    let data = { ...formValues };
    data[name] = selectedValue;
    setFormValues(data)
    if (name === "city") {
      let cityId;
      cityList.find((el) =>
        el.name === selectedValue ? (cityId = el._id) : null
      );

      let datas = { ...formValues };
      datas[name] = selectedValue;
      datas["cityId"] = cityId ? cityId : "";
      setFormValues(datas)

    }

  }


 

  const handleKeyPress = (event) => {
    if (event.key === 'Enter'&&event.target.tagName !== 'TEXTAREA') {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  useEffect(() => {
    getCityList();
    if (action) {
      setFormAction(action)
    }
    if (action === "addLocation") {
      setFormValues(() => ({ city: value?.cityId }))
      
    }
    if (action === "addZone") {
      setFormValues({ city: "", cityId: "" })
    }

  }, [])

  return (
      <FormModal
        onClose={onClose}
        show={show}
        heading={action === "addLocation" ? "Add Locality" : "Add Zone"}
        size={"md"}
        loading={initialLoading}
        loader="fixed"
      >
        {initialLoading?null:
        <div className="mt-1">
          <div className="select-head">
            <form
              noValidate
              // validated={validated}
              onSubmit={(e) => handleSubmit(e)}
              onKeyDown={handleKeyPress}
            >
              <Select
                errorMessage={errorMessage?.city && errorMessage?.city}
                label={"City"}
                options={cityList}
                name={"city"}
                isInvalid={errorMessage?.city && true}
                value={formValues.city && formValues?.city}
                onChange={(e) => {
                  handelOnSelect(e.target.value, "city");
                }}
                disabled={formAction === "addLocation" ? true : false}
              />
             

              <div className="dynamic-form">
                {dynamicInput.map((input, index) => {
                  return (
                    <div key={index} className="d-flex align-items-center ">
                      <Input
                        errorMessage={
                          input?.error && input?.error
                        }
                        type={"text"}
                        isInvalid={input?.error && true}
                        label={index === 0 ? formAction === "addLocation" ? "Add locality" : "Add zone" : ""}
                        name={formAction === "addLocation" ? "location" : "zone"}
                        autocomplete={"new-password"}
                        placeholder={index === 0 ? formAction === "addLocation" ? "Add locality" : "Add zone" : `${formAction === "addLocation" ? "Add locality" : "Add zone"} ${index + 1}`}
                        value={input?.name}
                        className={"input-padding-icon w-100 "}
                        onChange={(event) => handleFormChange(index, event)}
                        size={"w-100"}
                      />
                      {(index===(dynamicInput.length-1)) ? 
                        <div className={`ml-auto m-2 mt-0  cursor-pointer ${index===0?"mt-3":""}`} onClick={ addFields }>
                          <IoMdAddCircleOutline size={18} color="#034168" />
                        </div>
                       : 
                        <div className="ml-auto m-2 mt-0 cursor-pointer" onClick={() => removeFields(index)}>
                          <MdRemoveCircleOutline size={18} color="red" />
                        </div>
                      }

                    </div>
                  )
                })}
              </div>
              <div className="d-flex justify-content-end  gap-3 ">
                <Button
                type={"button"}
                  className={"pre-btn"}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button type={"submit"} className="next-btn" loading={formSubmitLoading}>
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>}
        { }
      </FormModal>
  )
}


export const brandErrorCheck = (subscription) => {
  let errors = {};
  let requiredField = 'This Field is Required';
  const subscriptionArray = [
    'propertyOrDemandSelect',
    'validityDays',
    'contactSuppliersSelect',
    'emailtoSuppliers',
    'userDashboard',
    'alert',
    'referalCredits',
    'featuredDemand',
    'assistedUploading',
    'homePageSlotAndBanner',
    'relationshipManager',
    'rm',
  ];

  subscriptionArray.map((item) => {
    if (subscription.hasOwnProperty(item)) {
      if (item === 'propertyOrDemandSelect') {
        if (subscription[item] === '' || subscription[item] === 'Select') {
          errors.propertyOrDemandSelect = requiredField;
        } else if (subscription[item] === 'Enter number') {
          if (
            subscription.propertyOrDemand === null ||
            subscription.propertyOrDemand === '' ||
            subscription.propertyOrDemand.match(/^\s*$/)
          ) {
            errors.propertyOrDemand = requiredField;
          }
        }
      }

      if (item === 'contactSuppliersSelect') {
        if (subscription[item] === '' || subscription[item] === 'Select') {
          errors[item] = requiredField;
        } else if (subscription[item] === 'Enter number') {
          if (
            subscription.contactSuppliers === null ||
            subscription.contactSuppliers === '' ||
            subscription.contactSuppliers.match(/^\s*$/)
          ) {
            errors.contactSuppliers = requiredField;
          }
        }
      }
      if (item === 'validityDays') {
        if (
          subscription[item] === null ||
          subscription[item] === '' ||
          isNaN(subscription[item])
        ) {
          errors[item] = requiredField;
        }
      }
      if (
        item === 'emailtoSuppliers' ||
        item === 'alert' ||
        item === 'featuredDemand' ||
        item === 'assistedUploading' ||
        item === 'homePageSlotAndBanner' ||
        item === 'relationshipManager'
      ) {
        if (subscription[item] === 'Select' || subscription[item] === '') {
          errors[item] = requiredField;
        }
      }
      if (item === 'userDashboard') {
        if (
          subscription[item] === null ||
          subscription[item] === 'Select' ||
          subscription[item] === ''
        ) {
          errors[item] = requiredField;
        }
      }
      if (item === 'rm') {
        if (
          subscription.relationshipManager === 'Yes' &&
          (subscription?.rm === '' ||
            subscription?.rm === undefined ||
            Object.keys(subscription?.rm)?.length === 0)
        ) {
          errors.rm = requiredField;
        }
      }
    }
    return item;
  });
  return errors;
};

import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
// components
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import MultiSelect from "../../../components/select/dropDown";
import Search from "../../../components/Search/search";
import {
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  postData,
  switchToUsers,
} from "../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../assets/images/switchuser.png";
import { FaBell } from "react-icons/fa";
import { nameToLabel, truncateString } from "../../../utilities/commonFunction";
import Loader from "../../../components/Loader";
import SwitchSellerAccountPopup from "../../../components/switchModal/SwitchCompanySellerPopup";

const SellerDBTable = ({ filterData, trigger }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [developerList, setDeveloperList] = useState([]);

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [developerFilterData, setDeveloperFilterData] = useState("");
  const [interestFilterData, setInterestFilterData] = useState(
    filterData ? filterData : ""
  );
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);
  const filterOptions = [
    {
      name: "Interest Received",
      value: "response",
    },
    {
      name: "Matching Demands",
      value: "relevent",
    },
  ];

  const handleAccountSwitch = async (id) => {
    setCompanyId(id);
    getAllSwitchUsers(id).then((res) => {
      if (res.status && res.statusCode === 200) {
        const developerPocs = res?.data?.developerPOCs || [];
        const cityPocs = res?.data?.cityPOCs || [];
        const combinedArrays = [...developerPocs, ...cityPocs];
        if (combinedArrays.length === 1) {
          const user = combinedArrays[0];
          const data = {
            companyId: id,
            customerId: user._id,
            type: "user",
          };
          switchToUsers(data).then((res) => {
            let openUrl = process.env.REACT_APP_SWITCH_URL;
            if (res.status) {
              window.open(`${openUrl}/${res?.data?.token}`);
            }
          });
        } else if (combinedArrays.length < 1) {
          Swal.fire({
            // title: header,
            text: "No Poc Found",
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        } else {
          setSwitchModal(true);
          setPocs({
            developerPocs,
            cityPocs,
          });
        }
      }
    });
  };

  const renderRow = (value, isRead) => {
    if (value === null || value === undefined) {
      return (
        <div className="db-number-card-container">
          <div className="db-number-card">-</div>
        </div>
      );
    } else {
      return (
        <div className="db-number-card-container">
          <div className="db-number-card" title={String(value)}>
            {isRead ? "" : <div className="notification-count"></div>}
            {truncateString(String(value), 3)}
          </div>
        </div>
      );
    }
  };

  const columnsSeller = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "name",
      text: "Company Name",
    },
    {
      dataField: "propertyCount",
      text: "Properties Posted",
      formatter: (cellContent, row, rowIndex) => {
        return renderRow(row.propertyCount, true);
      },
    },
    {
      dataField: "interestCount",
      text: "Interest Received",
      formatter: (cellContent, row, rowIndex) => {
        return renderRow(row.interestCount, row?.interestisRead);
      },
    },
    {
      dataField: "releventCount",
      text: "Matching Demands",
      formatter: (cellContent, row, rowIndex) => {
        return renderRow(row.releventCount, row?.releventIsRead);
      },
    },
    {
      dataField: "notification",
      text: "Notification",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="db-number-card-container">
            <div className="db-number-card">
              {row?.notificationisRead ? (
                ""
              ) : (
                <div className="notification-count"></div>
              )}
              <FaBell />
            </div>
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="db-number-card-container">
            {getCurrentUser()?.role[0]?.name === "admin" ||
            getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
              <img
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleAccountSwitch(row?.companyId);
                }}
                alt="switch"
                src={switchuser}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }
    let url = `/dashboard/getCustomers?type=Developer&page=${offSet}&limit=${perPage}`;
    if (search !== "") {
      url += `&search=${search}`;
    }
    if (
      developerFilterData?.label &&
      developerFilterData?.value !== "Select" &&
      developerFilterData?.label !== ""
    ) {
      url += `&name=${developerFilterData?.value}`;
    }
    if (interestFilterData !== "Select" && interestFilterData !== "") {
      url += `&interest=${interestFilterData}`;
    }
    let cusomerData = await getData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
      setTableData(data.brand);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };
  const getDeveloperList = async () => {
    let url = "/dashboard/customerdropdown?type=Developer";
    let developerData = await getData(url);

    const { statusCode, data } = developerData;
    if (statusCode === 200) {
      setDeveloperList(nameToLabel(data.brand));
    } else {
      setDeveloperList([]);
    }
  };
  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  const handleDeveloperChange = (val) => {
    setDeveloperFilterData(val);
    setOffset(1);
  };
  const handleInterestChange = (e) => {
    setInterestFilterData(e.target.value);
    setOffset(1);
  };
  const tableProps = {
    data: tableData,
    columns: columnsSeller,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };
  useEffect(() => {
    getTableData(true);
  }, [perPage, offSet, search, developerFilterData, interestFilterData]);
  useEffect(() => {
    getTableData(false);
  }, [trigger]);
  useEffect(() => {
    getDeveloperList();
  }, []);
  return (
    <>
      {loading && <Loader className={"fixed"} />}
      <div className="db-filter-container">
        <Row className=" filter-select">
          <Col md={5} sm={6} className="d-flex align-items-start pt-3">
            <Select
              placeholder={"Select..."}
              size={"w-100"}
              onChange={handleInterestChange}
              options={filterOptions}
              value={interestFilterData}
            />
          </Col>
          <Col md={5} sm={6} className="d-flex align-items-start ">
            <MultiSelect
              disabled={false}
              onChange={(val) => handleDeveloperChange(val)}
              isMulti={false}
              options={[
                { label: "Select Developer", value: "Select" },
                ...developerList,
              ]}
              value={developerFilterData}
              placeholder={"Select Developer"}
            />
          </Col>
        </Row>
        <Row className=" filter-search">
          <Col md={4} sm={6} className="d-flex align-items-start pt-3">
            <Search onChange={handleSearch} value={search} />
          </Col>
        </Row>
      </div>

      <MainTable {...tableProps} />
      <SwitchSellerAccountPopup
        show={switchModal}
        onClose={() => setSwitchModal(false)}
        data={pocs}
        companyId={companyId}
      />
    </>
  );
};
export default SellerDBTable;

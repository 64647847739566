import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { isLogedIn } from "../../api";
import { getKeyByValue, postDataForgot } from "../../api/index";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import CardComponent from "../../components/loginCardComponent/cardComponent";
import "../Login/style.scss";


function Forgot() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  useEffect(()=>{
    if(isLogedIn()){
      let route = getKeyByValue(menuId,loginPermission[0])
      navigate(`/${route}`);
    }
    
    },[])
    const forgotPwd = async (objdata) => {
      setBtnLoading(true)
      setErrorMessage('')
      const newData = await postDataForgot("/user/forgotPassword", {}, objdata);
      if (newData.statusCode === 200) {
        setBtnLoading(false)
        Swal.fire({
          // title: header,
          text:newData?.message,
          icon: "success",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
        navigate("/login");
        // toast.success(newData.message);
      }else if(newData.statusCode === 400 || newData.statusCode === 422){
        setBtnLoading(false)
        setErrorMessage(newData.message);
      } 
      else {
        setBtnLoading(false)
        Swal.fire({
          // title: header,
          text:newData?.message,
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
        // toast.error(newData.message);
      }
    };
  const handleSubmit = (e) => {
    e.preventDefault();

    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email === "") {
      setErrorMessage("This Field is Required");
    } else if (email.match(validRegex) === null) {
      setErrorMessage("Please enter a valid Email ID");
    } else {
      setErrorMessage("");
    }

    setValidated(true);

    if (email.match(validRegex)) {
      const objdata = {
        email: email,
        type: "admin"
      };
      forgotPwd(objdata);
    }
  };



  const loginContent = (name) => {
    return (
      <>
      <ToastContainer 
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover/>
        {name === "forgot" && (
          <>
            <h5 className="m-0 pb-1 fw-bold"> Forgot Password </h5>
            <p className="getStarted-txt medium-txt mb-3">
              {" "}
              Enter the email associated with your account and we’ll send an
              email with instructions to reset the password{" "}
            </p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="mb-3">
                <Input
                  errorMessage={errorMessage ? errorMessage : ""}
                  label={"Email ID"}
                  type={"email"}
                  value={email}
                  isInvalid={!!errorMessage}
                  placeholder={"Enter Your Email Id"}
                  className="blue-color input-bgcolor"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorMessage("");
                  }}
                />
              </div>

              <div className="forgotButton-style submit-alignment">
              <Button
                  loading={btnLoading}
                  type={"submit"}
                  className="submit-button-style btn-color-primary"                  
                  // onClick={onSubmit}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </>
        )}
      </>
    );
  };

  return (
    <div className="login-background">
      <CardComponent loginContent={loginContent("forgot")} />
    </div>
  );
}

export default Forgot;

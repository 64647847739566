import React, { useCallback, useRef, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import Compass from "../../assets/images/compass.svg";
const libraries = ["places"];
const mapContainerStyle = {
  height: "500px",
  // width: "400px",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
// const center = {
//   lat: 19.0760,
//   lng: 72.8777,
// };

export default function Mapdragger(props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAmjQV3IolzLipggThoN5tGGyG-YUjusWQ",
    libraries,
  });
  const [markers, setMarkers] = useState([19.076, 72.8777]);
  const [hasValue, setHasValue] = useState(false);
  const [center] = useState({
    lat: 19.076,
    lng: 72.8777,
  });

 
 

  useEffect(() => {
    if (props.mapValue) {
      if(props?.mapValue?.length>0){
        setHasValue(true)
      }
      
      setMarkers(props.mapValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[props?.mapValue]]);

  function Locate({ panTo }) {
    return (
      <button
        className="locate"
        onClick={() => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              panTo({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            () => null
          );
        }}
      >
        <img src={Compass} alt="compass" />
      </button>
    );
  }

  function Search({ panTo }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 100 * 1000,
      },
    });

    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

    const handleInput = (e) => {
      setValue(e.target.value);
    };

    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();

      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        panTo({ lat, lng });
      } catch (error) {
      }
    };

    return (
      <div className="search-map-drag">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Search your location"
          />
          <ComboboxPopover>
            <ComboboxList>
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption key={id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const triggerFun = (val) => {
    props.handleChangeMap(val);
  };
  const onMapClick =
  // useCallback(
  (e) => {
    setMarkers([e.latLng.lat(), e.latLng.lng()]);
    triggerFun([e.latLng.lat(), e.latLng.lng()]);
  };
  const panTo =
    //  useCallback(
    ({ lat, lng }) => {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(12);
      setMarkers([lat, lng]);
      // props.handleChangeMap([lat,lng])
      triggerFun([lat, lng]);
    };
  // , []);
 
  const onUnmount = React.useCallback(function callback(map) {
    // setMap(null)
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div style={{ width: "100%", height: "100%" }} className="mapFun">
      <Search panTo={panTo} />

      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={hasValue?{lat: markers[0], lng: markers[1]}:center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
        onUnmount={onUnmount}
      >
        <Locate panTo={panTo} />

        {/* {markers.map((marker) => ( */}
        <Marker
          key={`${markers[0]}-${markers[1]}`}
          position={{ lat: markers[0], lng: markers[1] }}
          onClick={() => {
            // setSelected(markers);
          }}
         
        />
       
        
      </GoogleMap>
    </div>
  );
}

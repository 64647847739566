import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

// components
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import Search from "../../../components/Search/search";
import {
  AccessDenied,
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  handleDashboardNewResponse,
  switchToUsers,
} from "../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../assets/images/switchuser.png";
import CrossSign from "../../../assets/images/CrossSign.svg";
import Loader from "../../../components/Loader";
import SwitchAccountPopup from "../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ListDeveloperResponseTable = ({ filterData, trigger, tableType }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  // const newResponses = searchParams.get("filter") === "new";
  const newResponses = tableType.includes("New");
  const [search, setSearch] = useState("");
  const [listType, setListType] = useState(
    searchParams.get("filter") === "new" ? "new" : "all"
  );
  const [sort, setSort] = useState();

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  const handleAccountSwitch = async (id) => {
    const data = {
      customerId: id,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = process.env.REACT_APP_SWITCH_URL;
      if (res.status) {
        window.open(`${openUrl}/${res?.data?.token}`);
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };

  const handlePropertyResponse = async (row) => {
    console.log({row})
    const url = `/property/updateResponseStatus`
    const data = {
      propertyId: row?.propertyId,
      responseId: row?.response?._id,
      responseTypeId: row?.response?.types?._id
    }

    const res = await handleDashboardNewResponse(url, data);
    if(res?.status) {
      getTableData(false)
    }
  }

  const getFormattedResponse = (response) => {
    if(response === 'contectSeller') {
      return 'Contact Selller'
    }

    if(response === 'accept') {
      return 'Accept'
    }
  }

  let columnsBrand = [
    {
      text: "Timestamp",
      dataField: "date",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row?.response?.types?.date).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: "Developer Name",
      dataField: "developerName",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row?.developerName}</div>;
      },
    },
    {
      text: "R Type",
      dataField: "ResponseType",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{getFormattedResponse(row?.response?.types?.type)}</div>;
      },
    },
    {
      text: "POC Name",
      dataField: "pocName",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <>
            {/* {newResponses ? (
              <div
                className="hyperlinkText"
                onClick={(e) => {
                  e.preventDefault();
                  handleAccountSwitch(row?.pocId);
                }}
              >
                {row?.pocName}
              </div>
            ) : (
              <div>{row?.pocName}</div>
            )} */}
            <div>{row?.pocName}</div>
          </>
        );
      },
    },
    {
      text: "Property ID",
      dataField: "propertyId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className="hyperlinkText"
            onClick={(e) => {
              navigate("propertyDetailView/" + row?.productId, {
                state: { isNew:  newResponses},
              });
            }}
          >
            {row?.productId}
          </div>
        );
      },
    },
    {
      text: "Responded By",
      dataField: "respondedBy",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row?.responededBy}</div>;
      },
    },
    {
      text: "User Type",
      dataField: "userType",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row?.responededByType}</div>;
      },
    },
    {
      text: "Demand ID",
      dataField: "demandId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className={`${row.demandId !== '-' && "hyperlinkText"}`}
            onClick={(e) => {
              if(row.demandId !== '-') {
                navigate("DemandDetailView/" + row.demandId);
              }
            }}
          >
            {row.demandId ? row.demandId : "-"}
          </div>
        );
      },
    },
    // {
    //   text: "Action",
    //   dataField: "action",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return (
    //       <>
    //         {newResponses ? (
    //           <div className="db-number-card-container">
    //             <img
    //               style={{ width: "20px", height: "20px" }}
    //               alt="cross"
    //               src={CrossSign}
    //             />
    //           </div>
    //         ) : (
    //           <div className="db-number-card-container">
    //             {getCurrentUser()?.role[0]?.name === "admin" ||
    //             getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
    //               <img
    //                 style={{ cursor: "pointer", width: "20px", height: "20px" }}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   handleAccountSwitch(row?.pocId);
    //                 }}
    //                 alt="switch"
    //                 src={switchuser}
    //               />
    //             ) : (
    //               ""
    //             )}
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rmName",
        formatter: (cellContent, row, rowIndex) => {
          return <div>{cellContent ? cellContent : "-"}</div>;
        },
      },
    ];
    columnsBrand.splice(8, 0, ...rmData);
  }
  if (newResponses) {
    const actionCol = [
      {
        dataField: "action",
        text: "Action",
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div className="db-number-card-container">
              <img
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                alt="cross"
                src={CrossSign}
                onClick={() => {
                  handlePropertyResponse(row)
                }}
              />
            </div>
          );
        },
      },
    ];
    columnsBrand.splice(9, 0, ...actionCol);
  }
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }

    let url = `/dashboard/getPropertyResponses?pageNo=${offSet}&limit=${perPage}&type=Developer&isNew=${
      newResponses ? true : false
    }`;
    if (search.length > 2) {
      url += `&search=${search}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    } else {
      url += `&sort=${"desc"}`
    }

    let cusomerData = await getData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data.count / perPage));
      setTotalCount(data.count);
      setTableData(data.list);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  let tableProps = {
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "RmDashboard",
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [perPage, offSet, search, sort, listType]);

  // useEffect(() => {
  //   getTableData(false);
  // }, [trigger]);

  let sortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];

  if (loginPermission?.includes(menuId?.enterpriseSellerDashboard)) {
    return (
      <>
        {loading && <Loader className={"fixed"} />}
        <div className="db-filter-container">
          <Row className=" filter-select">
            <>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Posted On"
                  options={sortValues}
                  name={"sort"}
                  value={sort}
                  onChange={(e) => {
                    setSort(e.target.value);
                    setOffset(1);
                  }}
                />
              </Col>
            </>
          </Row>
          <Row className=" filter-search-inbox">
            <Col md={6} sm={7} className="d-flex align-items-start">
              <Search onChange={handleSearch} value={search} />
            </Col>
          </Row>
        </div>
        <MainTable {...tableProps} trigger={trigger}  />
        <SwitchAccountPopup
          show={switchModal}
          onClose={() => setSwitchModal(false)}
          data={pocs}
          companyId={companyId}
        />
      </>
    );
  } else {
    return (
      <AccessDenied />
    )
  }

  
};
export default ListDeveloperResponseTable;

export const companyDefaultValue = [{ label: "Company", value: "select" }];
export const developerDefaultValue = [{ label: "Developer", value: "select" }];
export const agentDefaultValue = [{ label: "Agent", value: "select" }];
export const brandDefaultValue = [{ label: "Brand", value: "select" }];
export const cityDefaultValue = [{ label: "City", value: "select" }];
export const regionDefaultValue = [{ label: "Region", value: "select" }];
export const pocDefaultValue = [{ label: "POC name", value: "select" }];
export const propertyTypeDefaultValue = [
  { label: "Property type", value: "select" },
];
export const supplierDefaultValue = [
  { label: "Supplier type", value: "select" },
];
export const sortByDefaultValue = [{ label: "Sort by", value: "select" }];
export const createdAtDefaultValue = [
  { label: "Created date", value: "select" },
];
export const contactedAtDefaultValue = [
  { label: "Contacted date", value: "select" },
];
export const brandPages = [
  "companyList",
  "companyPocList",
  "brandList",
  "brandPocList",
  "regionPocList",
  "demandPostedList",
  "inActiveDemandList",
  "activeDemandList",
  "demandResponseList",
  "propertyAcceptedList",
];
export const sellerPage = [
  "developerList",
  "propertiesPostedList",
  "developerPocList",
  "cityPocList",
];
export const filterArrayBuyer = [
  {
    tableName: "companyList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "regionList",
        option: [],
        key: "region",
        defaultValue: regionDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        option: [],
        key: "cities",
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },

  {
    tableName: "companyPocList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "companyList",
        option: [],
        key: "companies",
        defaultValue: companyDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: false,
        name: "sortByList",
        key: "sort",
        option: [],
        defaultValue: sortByDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },

  {
    tableName: "brandList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "regionList",
        key: "region",
        option: [],
        defaultValue: regionDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        key: "cities",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 4,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },

  {
    tableName: "brandPocList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "companyList",
        option: [],
        key: "companies",
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        name: "brandList",
        key: "brand",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: false,
        name: "sortByList",
        key: "sort",
        option: [],
        defaultValue: sortByDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },

  {
    tableName: "regionPocList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "companyList",
        option: [],
        key: "companies",
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        name: "regionList",
        option: [],
        key: "region",
        defaultValue: regionDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: false,
        name: "sortByList",
        key: "sort",
        option: [],
        defaultValue: sortByDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "demandPostedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "companyList",
        option: [],
        key:"companies",
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key:"brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key:"poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        key:"cities",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key:"property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        key:"sort",
        name: "createdByList",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "demandResponseList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "companyList",
        option: [],
        key:"companies",
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key:"poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        key: "cities",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        key:"sort",
        name: "createdByList",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "activeDemandList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "companyList",
        option: [],
        key:"companies",
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key:"brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key:"poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        key:"cities",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key:"property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        key:"sort",
        name: "createdByList",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "inActiveDemandList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "companyList",
        option: [],
        key:"companies",
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key:"brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key:"poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        key:"cities",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key:"property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        key:"sort",
        name: "createdByList",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },

  {
    tableName: "propertyContactedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "companies",
        name: "companyList",
        option: [],
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 6,
        fieldType: "select",
        isMulti: true,
        key: "supplier",
        name: "supplierList",
        option: [],
        defaultValue: supplierDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyAcceptedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "companies",
        name: "companyList",
        option: [],
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 6,
        fieldType: "select",
        isMulti: true,
        key: "supplier",
        name: "supplierList",
        option: [],
        defaultValue: supplierDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyRejectedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "companies",
        name: "companyList",
        option: [],
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 6,
        fieldType: "select",
        isMulti: true,
        key: "supplier",
        name: "supplierList",
        option: [],
        defaultValue: supplierDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "needMoreInfoList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "companies",
        name: "companyList",
        option: [],
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 6,
        fieldType: "select",
        isMulti: true,
        key: "supplier",
        name: "supplierList",
        option: [],
        defaultValue: supplierDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
];

export const filterArraySeller = [
  {
    tableName: "developerList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "developer",
        fieldType: "select",
        isMulti: true,
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 2,
        key: "cities",
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "developerPocList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "developer",
        fieldType: "select",
        isMulti: true,
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 3,
        key:"sort",
        fieldType: "select",
        isMulti: false,
        name: "sortByList",
        option: [],
        defaultValue: sortByDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "cityPocList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key:"developer",
        fieldType: "select",
        isMulti: true,
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key:"cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 3,
        key:"sort",
        fieldType: "select",
        isMulti: false,
        name: "sortByList",
        option: [],
        defaultValue: sortByDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyPostedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "developer",
        fieldType: "select",
        isMulti: true,
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyPostedAgainstList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "developer",
        fieldType: "select",
        isMulti: true,
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyResponseList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "developer",
        fieldType: "select",
        isMulti: true,
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyInActiveList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "developer",
        fieldType: "select",
        isMulti: true,
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyActiveList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "developer",
        fieldType: "select",
        isMulti: true,
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyAcceptedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },

      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "developer",
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        name: "pocList",
        key: "poc",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyRejectedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },

      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "developer",
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        name: "pocList",
        key: "poc",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "needMoreInfoList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "developer",
        fieldType: "select",
        isMulti: true,
        name: "developerList",
        option: [],
        defaultValue: developerDefaultValue,
      },

      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        name: "pocList",
        key: "poc",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyContactedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "companies",
        name: "companyList",
        option: [],
        defaultValue: companyDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        name: "brandList",
        key: "brand",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        key: "cities",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },

      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
];

export const filterArrayAgent = [
  {
    tableName: "agentList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "agent",
        fieldType: "select",
        isMulti: true,
        name: "agentList",
        option: [],
        defaultValue: agentDefaultValue,
      },
      {
        id: 2,
        key: "cities",
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyPostedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "agent",
        fieldType: "select",
        isMulti: true,
        name: "agentList",
        option: [],
        defaultValue: agentDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
       {
        id: 3,
        fieldType: "export",
         name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyResponseList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "agent",
        fieldType: "select",
        isMulti: true,
        name: "agentList",
        option: [],
        defaultValue: agentDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
         name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyInActiveList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "agent",
        fieldType: "select",
        isMulti: true,
        name: "agentList",
        option: [],
        defaultValue: agentDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
         name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyActiveList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "agent",
        fieldType: "select",
        isMulti: true,
        name: "agentList",
        option: [],
        defaultValue: agentDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyAcceptedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "agent",
        fieldType: "select",
        isMulti: true,
        name: "agentList",
        option: [],
        defaultValue: agentDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyRejectedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 2,
        key: "agent",
        fieldType: "select",
        isMulti: true,
        name: "agentList",
        option: [],
        defaultValue: agentDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "needMoreInfoList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        name: "pocList",
        key: "poc",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        key: "brand",
        name: "brandList",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
    ],
  },
  {
    tableName: "propertyContactedList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        key: "poc",
        name: "pocList",
        option: [],
        defaultValue: pocDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "companies",
        name: "companyList",
        option: [],
        defaultValue: companyDefaultValue,
      },
      {
        id: 4,
        fieldType: "select",
        isMulti: true,
        name: "brandList",
        key: "brand",
        option: [],
        defaultValue: brandDefaultValue,
      },
      {
        id: 3,
        fieldType: "select",
        isMulti: true,
        name: "cityList",
        key: "cities",
        option: [],
        defaultValue: cityDefaultValue,
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },

      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "contactedList",
        key: "sort",
        option: [],
        defaultValue: contactedAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
        name: "Export to CSV",
        key: "export",
      },
      {
        tableName: "propertyPostedAgainstList",
        filters: [
          {
            id: 1,
            fieldType: "search",
            name: "search",
          },
           {
            id: 2,
            key:"agent",
            fieldType: "select",
            isMulti: true,
            name: "agentList",
            option: [],
            defaultValue: agentDefaultValue,
          },
          {
            id: 2,
            fieldType: "select",
            isMulti: true,
            key: "cities",
            name: "cityList",
            option: [],
            defaultValue: [{ label: "City", value: "select" }],
          },
          {
            id: 5,
            fieldType: "select",
            isMulti: true,
            key: "property",
            name: "propertyTypeList",
            option: [],
            defaultValue: propertyTypeDefaultValue,
          },
          {
            id: 8,
            fieldType: "select",
            isMulti: false,
            name: "contactedList",
            key: "sort",
            option: [],
            defaultValue: contactedAtDefaultValue,
          },
          {
            id: 3,
            fieldType: "export",
            name: "Export to CSV",
            key: "export",
          },
        ],
      },
    ],
  },
  {
    tableName: "propertyPostedAgainstList",
    filters: [
      {
        id: 1,
        fieldType: "search",
        name: "search",
      },
       {
        id: 2,
        key:"agent",
        fieldType: "select",
        isMulti: true,
        name: "agentList",
        option: [],
        defaultValue: agentDefaultValue,
      },
      {
        id: 2,
        fieldType: "select",
        isMulti: true,
        key: "cities",
        name: "cityList",
        option: [],
        defaultValue: [{ label: "City", value: "select" }],
      },
      {
        id: 5,
        fieldType: "select",
        isMulti: true,
        key: "property",
        name: "propertyTypeList",
        option: [],
        defaultValue: propertyTypeDefaultValue,
      },
      {
        id: 8,
        fieldType: "select",
        isMulti: false,
        name: "createdByList",
        key: "sort",
        option: [],
        defaultValue: createdAtDefaultValue,
      },
      {
        id: 3,
        fieldType: "export",
         name: "Export to CSV",
        key: "export",
      },
    ],
  },
];

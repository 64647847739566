import React, { useState, useEffect } from "react";
import {
  getData,
  getCurrentUser,
  postGetData,
  postUploadData,
} from "../../../api";
import { Row, Col, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { HiUpload } from "react-icons/hi";
import { getDocumentData } from "../options";
import Button from "../../../components/Form/Button";
import swal from "sweetalert";
import Loader from "../../../components/Loader";
import { useRef } from "react";
import "./style.scss";
import { getCoworkDocumentData } from "../coworkingSpaceOptions";
const CoworkUploadDocuments = ({
  gotoStep,
  isExitData,
  propertyDetail,
  step,
  properytyInput,
  setPropertyInput,
  uploadDocumentStepButtonRef,
  setChanged,
}) => {
  // const [properytyInput, setPropertyInput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  useEffect(() => {
    setPropertyInput(getCoworkDocumentData);
    if (propertyDetail.otherDocuments) {
      getCoworkDocumentData.forEach((item, index) => {
        let data = Object.keys(propertyDetail.otherDocuments);

        data.forEach((document) => {
          if (item.name === document) {
            item.uploadFiles = propertyDetail.otherDocuments[item.name];
          }
        });
      });
    } else {
      setPropertyInput([
        {
          label: "Brochure",
          type: "file",
          name: "brochure",
          brochure: [],
          uploadFiles: [],
        },
      ]);
    }
  }, []);

  const handleChange = (e, item) => {
    setChanged(true);
    UploadImageValidation(e, item);
  };
  const UploadImageValidation = (imagedata, selectedField) => {
    const { files } = imagedata.target;
  
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 5000 * 1024) {
        swal({
          text: "File size should be less than 5 MB",
          icon: "warning",
          timer: 3000,
          buttons: false,
          showCloseButton: true,
        });
      } else if (files[i].type !== "application/pdf") {
        swal({
          text: "Accept only PDF files",
          icon: "warning",
          timer: 3000,
          buttons: false,
          showCloseButton: true,
        });
      } else {
        let datas = new FormData();
        datas.append("uploadfiles", files[i]);
        UPloadImage(datas, selectedField);
      }
    }
  };
  
  const UPloadImage = async (datas, selectedField) => {
    setFileLoading(true);
    let url = `/user/file-upload/${localStorage.getItem(
      "addPropertyId"
    )}?type=image&module=property`;
    const resData = await postUploadData(url, {}, datas);
    const { statusCode, data } = resData;
    if (statusCode === 200) {
      data.forEach((element) => {
        const optionData = [...properytyInput];
        optionData.forEach((data) => {
          if (data.name === selectedField.name) {
            data.uploadFiles.push(element);
          }
        });
        setPropertyInput(optionData);
      });
      setFileLoading(false);
    }
    setFileLoading(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // let documentDetails = {};
    // properytyInput.forEach((element) => {
    //   documentDetails[element.name] = element.uploadFiles;
    // });
    // handleDatas(documentDetails);
  };
  // const handleDatas = async (documentDetails) => {
  //   setLoading(true);
  //   let finaldata = {
  //     step: 4,
  //     propertyId: localStorage.getItem("addPropertyId"),
  //     documents: documentDetails,
  //   };
  //   const res = await postGetData("/property", {}, finaldata);

  //   const { statusCode } = res;
  //   if (statusCode === 200) {
  //     setLoading(false);
  //     gotoStep(5, true);
  //   }
  //   setLoading(false);
  // };
  const removeFile = (data, mainIndex, removeIndex) => {
    const optionData = [...properytyInput];
    optionData[mainIndex].uploadFiles.splice(removeIndex, 1);
    setPropertyInput(optionData);
  };
  const gotoBack = () => {
    gotoStep(step - 1);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  // useEffect(() => {
  //   if(step && step !== 5){
  //     document.addEventListener('keydown', handleKeyPress);
  //     return () => {
  //       document.removeEventListener('keydown', handleKeyPress);
  //     };
  //   }

  // }, [properytyInput]);
  return (
    <>
      <div className="property-details-container pt-4">
        <Row>
          <Col md="1" />
          <Col md="10">
            <h4>Documents</h4>
            <Form onSubmit={handleSubmit}>
              <Row>
                {properytyInput &&
                  properytyInput.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.type == "file" ? (
                        <Col md="6">
                          <div>
                            <Form.Label>
                              <span className="f-14 fw-700">{item.label}</span>
                            </Form.Label>
                            <div className="upload-lable">
                              <div className="p-1 upload-container">
                                <div className="d-flex  flex-wrap justify-content-start align-items-center upload-container-wrapper">
                                  {item?.uploadFiles?.map(
                                    (innerdata, documentIndex) => {
                                      return (
                                        <div
                                          className="m-1 uploadUIWidth"
                                          key={documentIndex}
                                        >
                                          <div className="upload-list ">
                                            <label className="NameOfFile">
                                              {innerdata.name
                                                ? innerdata.name
                                                : innerdata.fileName}
                                            </label>
                                            &nbsp;
                                            <AiOutlineClose
                                              className="closeButtonUpload"
                                              style={{
                                                color: "white",
                                                fontSize: "em",
                                                paddingLeft: "3px",
                                                cursor: "pointer",
                                              }}
                                              size={20}
                                              onClick={() =>
                                                removeFile(
                                                  item,
                                                  index,
                                                  documentIndex
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div className="upload-icon">
                                  <label className="cursor-point">
                                    <div>
                                      <HiUpload
                                        size={20}
                                        style={{
                                          color: "#3E4958",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <input
                                      name="file"
                                      id="id"
                                      type="file"
                                      onChange={(e) => handleChange(e, item)}
                                      className={"d-none"}
                                      // multiple={props.multiple?true:false}
                                      multiple={true}
                                      accept=".pdf,image/jpeg,image/jpg,image/gif,image/png,application/pdf,image/x-eps"
                                    />
                                  </label>
                                </div>
                              </div>
                              <span
                                style={{
                                  fontSize: "11px",
                                  color: "gray",
                                  marginLeft: "10px",
                                }}
                              >
                                File Size not more than 5 MB
                              </span>
                            </div>
                          </div>
                        </Col>
                      ) : null}
                    </React.Fragment>
                  ))}
                <Col md="12" hidden={true}>
                  <div className="text-end">
                    <Button
                      type="button"
                      className="pre-btn  me-3"
                      onClick={gotoBack}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      className="next-btn p-0"
                      loading={loading}
                      _ref={uploadDocumentStepButtonRef}
                    >
                      Next
                    </Button>
                  </div>
                </Col>
              </Row>
              {fileLoading && <Loader />}
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default CoworkUploadDocuments;

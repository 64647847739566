import { useEffect } from "react";
import { Form } from "react-bootstrap";
import "./style.scss";
const Input = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  size,
  errorMessage,
  isInvalid,
  min,
  max,
  disabled,
  addText,
  onFocus,
  onBlur,
  autocomplete,
  positive,
  className,
  sufix,
  prefix,
  enableNote,
  required,
  hidden,
  note
}) => {
  useEffect(() => {
    if (type === "number") {
      let input = document.getElementById(name + "input");
      input.addEventListener("mousewheel", function (evt) {
        evt.preventDefault();
      });
    }
  }, []);
  const handlePaste = (e) => {
    // Get the pasted text
    const pastedText = e.clipboardData.getData("text");
    // Check if the pasted text contains only numbers
    if (!/^\d+$/.test(pastedText)) {
      // If the pasted text is not all numbers, prevent the paste operation
      e.preventDefault();
    }
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189
    ) {
      e.preventDefault();
    }

  }
  return (
    <>
      <Form.Group className={"form-group "+(note ? "mb-0 " : "") + size}>
        <div className="d-grid">
          {!hidden ? (
            <Form.Label
              id={name}
              className={`fw-700 f-14 text-black ${className ? className : "text-black"
                }`}
            >
              {label} {required && label ? "*" : null}
            </Form.Label>
          ) : null}

          {addText ? (
            <label
              id={addText}
              className="fw-400 f-14 text-black mb-1 maxWidthStyle"
            >
              {addText}
            </label>
          ) : (
            ""
          )}
        </div>
        {value ? (
          <span className="prefixWrapper">{prefix ? prefix : ""}</span>
        ) : (
          ""
        )}
        <Form.Control
          required={required ? required : false}
          type={type}
          name={name}
          value={value}
          isInvalid={isInvalid}
          placeholder={placeholder}
          onChange={onChange}
          min={min}
          id={name + "input"}
          disabled={disabled}
          autoComplete={autocomplete ? autocomplete : "off"}
          onBlur={onBlur}
          onFocus={onFocus}
          positive={positive}
          className={className}
          onPaste={type === "number" ? handlePaste : {}}
          onKeyDown={type === "number" ? handleKeyDown : {}}
          style={{
            paddingLeft: prefix && value ? "35px" : ".75rem",
            paddingRight: sufix ? "65px" : ".75rem",
          }}
          hidden={hidden}
        />
        {value ? <span className="sufixWrapper">{sufix ? sufix : ""}</span> : ""}
        {enableNote && <p className="mb-0 f-14">{enableNote}</p>}
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      {note ? <div className={`text-muted f-14 mb-5 ${errorMessage && "mt-3"}`}>{note}</div> : null}
    </>
  );
};

export default Input;

import{useState} from 'react';
import Buttons from '../button/buttons';
import Dropzone from './dropZone';
import {Modal} from "react-bootstrap";
import GalleryBox from '../galleryBox/galleryBox';
import { getSampleImagesData } from '../../api';
import DropzoneCowork from './dropZone';

export default function DragAndDropCowork(props) {
  const [show, setShow] = useState(false);
  const [sampleImg, setSampleImg] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () =>{ 
    const propType = props?.propertyType ===  'Office space' ? 'OfficeSp' : props?.propertyType ===  'Retail'? 'RetailSp' : 'CoworkSp'
    const url = `/property/getSampleImagesOfProperty?propType=${propType}`
    getSampleImagesData(url).then((res) => {
      if(props.name === 'buildingImages') {
        setSampleImg(res?.data?.buildingImages)
      } else if(props.name === 'workSpaceImages') {
        setSampleImg(res?.data?.workSpaceImages) 
      } 
      else if(props.name === 'commonAreaImages') { 
        setSampleImg(res?.data?.commonAreaImages)
      }
      else {
        setSampleImg([])
      }
      setShow(true);
    })
  }
  return (
    <div>
      <div className="gallery-modal">
           <Modal size="lg" show={show} onHide={handleClose} fullscreen={true} >
             <GalleryBox onclose={handleClose} name={props.title} sampleImg = {sampleImg}/>
           </Modal>
           </div>
    <div className="drop-container">
        <div className="d-flex justify-content-between p-2 pb-4 f-18 fw-500">
        <p>{props.title}</p>
        <Buttons name="View Some Examples" className="viewExamples" onClick={handleShow}></Buttons>
        </div>
         <DropzoneCowork handleAllImages={(urls) =>props.handles(urls)} name={props.name} imagesList={props?.allImages} propertyId={props.propertyId}/>

    </div>
    <span className="customInvalid">
        {props.error}
      </span>
    </div>
  )
}

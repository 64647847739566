import "./index.scss";

export default function DemandDetailListCard(props) {
  return (
    <div className="bg-light-card w-100 d-flex  demand-detail-lable">
      <div className=" w-50  demand-detail-lable d-flex align-items-center">
        {props.name}
      </div>
      <div className="w-50 demand-detail-lable-left word-wrap d-flex align-items-center">
        {props.value}
      </div>
    </div>
  );
}

export function DemandFormatListCard(props) {
  return (
    <>
      <div className="d-flex">
        {/* <Col sm={12} md={12} className="format-cards"> */}
        <div className="bg-light-card w-100 d-flex  demand-detail-lable m-2">
          <div className=" w-50  demand-detail-lable d-flex align-items-center">
            {props.name}
          </div>
          <div className="w-50 demand-detail-lable-left word-wrap d-flex align-items-center">
            {props?.value?.noOfSeat > 0 ? `${props.value.noOfSeat} ${props.value.noOfSeat > 1 ? "seats" : "seat"}` : "0 seats"}
          </div>
        </div>
        <div className="bg-light-card w-100 d-flex  demand-detail-lable m-2">
          <div className=" w-50  demand-detail-lable d-flex align-items-center">
            {`${props.name} Budget`}
          </div>
          <div className="w-50 demand-detail-lable-left word-wrap d-flex align-items-center">
            {(props?.value?.budgetMin && props?.value?.budgetMin) ? `Rs. ${props?.value?.budgetMin || 0} - ${props?.value?.budgetMax}` : "-"}
          </div>
        </div>
        {/* </Col> */}
      </div>
    </>
  );
}

import  { useState, useEffect } from "react";
import "./propertyDetailView.scss";
import { useLocation } from "react-router-dom";
import ReleventResponse from "./ReleventProperty";

import NavBar from "../../components/Navbar";
import "./style.scss";
import { RelevantPropertyCard } from "../../components/Cards/PropertyCard/propertyCard";
export default function UserManagement() {
  const [getSpecDetails, setgetSpecDetails] = useState([]);

  let location = useLocation();
 

  const details = location?.state ? location.state?.allData : "";

  useEffect(() => {
    let propertyDetail = []
    propertyDetail.push(details)
    setgetSpecDetails(propertyDetail);
  }, [details]);

  
  return (
    <>
      <NavBar />
        <div className="px-4 p-3 user-container">
           
            <RelevantPropertyCard
            propertyData={getSpecDetails}
            relevent={false}
            response={false}
            demandStatus={false}
            noPostedBy={true}
            />

          <label className="title p-2 mt-2">Responses</label>
          <ReleventResponse
            propertId={
              getSpecDetails?._id
                ? getSpecDetails?._id
                : JSON.parse(localStorage.getItem("propertyResponceId"))
            }
          />
        </div>
    </>
  );
}

import  { useState, useEffect } from "react";
import Button from "../../components/Form/Button";
import Select from "../../components/Form/Select";
import "./index.scss";
import CustomDate from "./customDate";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { monthYear } from "./dropdownValue";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getData, getExportData } from "../../api";
function LineCharts(props) {
  const [startYear, setStartYear] = useState(new Date());
  const [startMonth, setStartMonth] = useState(new Date());
  const [selectType, setSelectType] = useState("yearly");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  // fetch data in array
  const [yearlyDataActive, setyearlyDataActive] = useState([]);
  const [dataVisitors, setDataVisitors] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);
  const [dataRegistry, setDataRegistry] = useState([]);



  const getUserActiveList = async () => {
    let url;
    if (selectType === "yearly") {
      url = `/reports/UserReport?period=${selectType}&year=${moment(
        startYear
      ).format("YYYY")}&type=filter`;
    } else if (selectType === "monthly") {
      url = `/reports/UserReport?period=${selectType}&year=${moment(
        startYear
      ).format("YYYY")}&month=${moment(startMonth).format("MM")}&type=filter`;
    } else {
      url = `/reports/UserReport?period=${selectType}&startDate=${moment(
        startDate
      ).format("YYYY/MM/DD")}&endDate=${moment(endDate).format(
        "YYYY/MM/DD"
      )}&type=filter`;
    }
    const newData = await getData(url, {});
    const { status, statusCode, data } = newData;
    if (status && statusCode === 200) {
      setyearlyDataActive(data);
    }
  };
  const getVisitorsList = async () => {
    let url;
    if (selectType === "yearly") {
      url = `/reports/visitorsReport?period=${selectType}&year=${moment(
        startYear
      ).format("YYYY")}&type=filter`;
    } else if (selectType === "monthly") {
      url = `/reports/visitorsReport?period=${selectType}&year=${moment(
        startYear
      ).format("YYYY")}&month=${moment(startMonth).format("MM")}&type=filter`;
    } else {
      url = `/reports/visitorsReport?period=${selectType}&startDate=${moment(
        startDate
      ).format("YYYY/MM/DD")}&endDate=${moment(endDate).format(
        "YYYY/MM/DD"
      )}&type=filter`;
    }
    const newData = await getData(url, {});
    const { status, statusCode, data } = newData;
    if (status && statusCode === 200) {
      setDataVisitors(data);
    }
  };
  const getPropertyList = async () => {
    let url;
    if (selectType === "yearly") {
      url = `/reports/propertyAndDemandReport?period=${selectType}&year=${moment(
        startYear
      ).format("YYYY")}&type=filter`;
    } else if (selectType === "monthly") {
      url = `/reports/propertyAndDemandReport?period=${selectType}&year=${moment(
        startYear
      ).format("YYYY")}&month=${moment(startMonth).format("MM")}&type=filter`;
    } else {
      url = `/reports/propertyAndDemandReport?period=${selectType}&startDate=${moment(
        startDate
      ).format("YYYY/MM/DD")}&endDate=${moment(endDate).format(
        "YYYY/MM/DD"
      )}&type=filter`;
    }
    const newData = await getData(url, {});
    const { status, statusCode, data } = newData;
    if (status && statusCode === 200) {
      setDataProperty(data);
    }
  };
  const getRegisList = async () => {
    let url;
    if (selectType === "yearly") {
      url = `/reports/enquiryreport?period=${selectType}&year=${moment(
        startYear
      ).format("YYYY")}&type=filter`;
    } else if (selectType === "monthly") {
      url = `/reports/enquiryreport?period=${selectType}&year=${moment(
        startYear
      ).format("YYYY")}&month=${moment(startMonth).format("MM")}&type=filter`;
    } else {
      url = `/reports/enquiryreport?period=${selectType}&startDate=${moment(
        startDate
      ).format("YYYY/MM/DD")}&endDate=${moment(endDate).format(
        "YYYY/MM/DD"
      )}&type=filter`;
    }
    const newData = await getData(url, {});
    const { status, statusCode, data } = newData;
    if (status && statusCode === 200) {
      setDataRegistry(data);
    }
  };
  const handleChange = (type) => {
    setSelectType(type);
  };
  const cards = ["Agents", "Brands", "Developers", "Total Users"];
  const handleDownloadExcel =  () => {
    let url;
    if (props?.chartName === "usersActive") {
      if (selectType === "yearly") {
        url = `/reports/UserReport?period=${selectType}&year=${moment(
          startYear
        ).format("YYYY")}&type=export`;
      } else if (selectType === "monthly") {
        url = `/reports/UserReport?period=${selectType}&year=${moment(
          startYear
        ).format("YYYY")}&month=${moment(startMonth).format("MM")}&type=export`;
      }
     
      
    } else if (props?.chartName === "usersVisitors") {
      if (selectType === "yearly") {
        url = `/reports/visitorsReport?period=${selectType}&year=${moment(
          startYear
        ).format("YYYY")}&type=export`;
      } else if (selectType === "monthly") {
        url = `/reports/visitorsReport?period=${selectType}&year=${moment(
          startYear
        ).format("YYYY")}&month=${moment(startMonth).format("MM")}&type=export`;
      }
     
      
    } else if (props?.chartName === "usersProperty") {
      if (selectType === "yearly") {
        url = `/reports/propertyAndDemandReport?period=${selectType}&year=${moment(
          startYear
        ).format("YYYY")}&type=export`;
      } else if (selectType === "monthly") {
        url = `/reports/propertyAndDemandReport?period=${selectType}&year=${moment(
          startYear
        ).format("YYYY")}&month=${moment(startMonth).format("MM")}&type=export`;
      }
     
      
    } else if (props?.chartName === "usersRegistration") {
      if (selectType === "yearly") {
        url = `/reports/enquiryreport?period=${selectType}&year=${moment(
          startYear
        ).format("YYYY")}&type=export`;
      } else if (selectType === "monthly") {
        url = `/reports/enquiryreport?period=${selectType}&year=${moment(
          startYear
        ).format("YYYY")}&month=${moment(startMonth).format("MM")}&type=export`;
      } 
     
      
    }
    getExportData(url, {}, {}).then((blob) => {
      let urls = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = urls;
      a.download = `${props?.title}-${selectType}.xlsx`;
      a.click();
    });
  };
  const getcustomXaxis = () => {
    let start = moment(startDate).format("YYYY/MM/DD");
    let end = moment(endDate).format("YYYY/MM/DD");
    const date1 = new Date(start);
    const date2 = new Date(end);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= 31) {
      // setCustomMonthDisplay(true);
      return "date";
    } else if (diffDays > 31 && (diffDays <= 365 || diffDays <= 366)) {
      // setCustomMonthDisplay(false);
      return "name";
    } else {
      // setCustomMonthDisplay(false);
      return "year";
    }
  };

  useEffect(() => {
  
   
    // getUserActiveList()
    if (props?.chartName === "usersActive") {
      getUserActiveList();
    } else if (props?.chartName === "usersVisitors") {
      getVisitorsList();
    } else if (props?.chartName === "usersProperty") {
      getPropertyList();
    } else if (props?.chartName === "usersRegistration") {
      getRegisList();
    }
  }, [startDate, endDate,startYear,startMonth]);

  useEffect(() => {
    if (props?.chartName === "usersActive") {
      getUserActiveList();
    } else if (props?.chartName === "usersVisitors") {
      getVisitorsList();
    } else if (props?.chartName === "usersProperty") {
      getPropertyList();
    } else if (props?.chartName === "usersRegistration") {
      getRegisList();
    }
  }, [selectType]);
  return (
    <div className="lineWrapper">
      <div className="lineHeadercontailer">
        <p className="activeUserColor">{props?.title}</p>
        <div className="activeUserButton">
          <Select
            size="w-30 marginWrapReport"
            name={"customer"}
            options={monthYear}
            value={selectType}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
          &nbsp;
          {selectType === "yearly" ? (
            <DatePicker
              selected={startYear}
              onChange={(date) => setStartYear(date)}
              placeholderText="Select Year"
              showYearPicker
              dateFormat="yyyy"
              yearItemNumber={9}
            />
          ) : selectType === "monthly" ? (
            <div className="hideHeaderDate">
              <DatePicker
                selected={startMonth}
                onChange={(date) => setStartMonth(date)}
                placeholderText="Select Month"
                dateFormat="MM"
                showMonthYearPicker
                dateFormatCalendar=""
              />
            </div>
          ) : selectType === "custom" ? (
            <CustomDate
              from={(date) => setStartDate(date)}
              to={(date) => setEndDate(date)}
              startVal={startDate}
              endVal={endDate}
            />
          ) : null}
          &nbsp;
          <Button
            className="btn-color-primary marginTopReport"
            onClick={() => handleDownloadExcel()}
            disabled={props.access.exportReports?false:true}
          >
            Export CSV
          </Button>
        </div>
      </div>
      {selectType === "custom" && getcustomXaxis() === "date" ? (
        <div className="graphMonth">
          {moment(startDate).format("MMMM , YYYY")}
        </div>
      ) : selectType === "monthly" ? (
        <div className="graphMonth">{moment(startMonth).format("MMMM, YYYY")}</div>
      ) : (
        ""
      )}
      <div className="graphWrapper">
        <p>
          <b>{props?.sideVal ? props?.sideVal : "Users"}</b>
        </p>
        <ResponsiveContainer>
          <LineChart
            width={1000}
            height={400}
            data={
              props?.chartName === "usersActive"
                ? yearlyDataActive
                : props?.chartName === "usersVisitors"
                ? dataVisitors
                : props?.chartName === "usersProperty"
                ? dataProperty
                : props?.chartName === "usersRegistration"
                ? dataRegistry
                : null
            }
            margin={{ top: 25, right: 30, left: 20, bottom: 5 }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis
              dataKey={
                selectType === "monthly"
                  ? "date"
                  : selectType === "custom"
                  ? getcustomXaxis()
                  : "name"
              }
            />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            {props?.chartName === "usersActive" ? (
              <>
                <Line
                  type="monotone"
                  dataKey="agentCount"
                  stroke="#7B17D6"
                  name="Agent"
                />
                <Line
                  type="monotone"
                  dataKey="brandCount"
                  stroke="#27B4EF"
                  name="Brands"
                />
                <Line
                  type="monotone"
                  name="Developer"
                  dataKey="developerCount"
                  stroke="#FFBF3F"
                />
              </>
            ) : props?.chartName === "usersVisitors" ? (
              <>
                <Line
                  type="monotone"
                  dataKey="activeUser"
                  stroke="#7B17D6"
                  name="Active User"
                />
                <Line
                  type="monotone"
                  dataKey="inactiveUser"
                  stroke="#27B4EF"
                  name="In Active User"
                />
              </>
            ) : props?.chartName === "usersProperty" ? (
              <>
                <Line
                  type="monotone"
                  dataKey="propertyCount"
                  stroke="#7B17D6"
                  name="Property Count"
                />
                <Line
                  type="monotone"
                  dataKey="demandCount"
                  stroke="#27B4EF"
                  name="Demand Count"
                />
              </>
            ) : props?.chartName === "usersRegistration" ? (
              <>
                <Line
                  type="monotone"
                  dataKey="buyerCount"
                  stroke="#7B17D6"
                  name="Buyer Count"
                />
                <Line
                  type="monotone"
                  dataKey="sellerCount"
                  stroke="#27B4EF"
                  name="Seller Count"
                />
              </>
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="reportWrapper_1">
        {props?.cardData?.length > 0
          ? props.cardData?.map((item, index) =>
              cards.includes(item?.label) ? (
                <div className="reportHeader" key={index}>
                  <p>{item?.label}</p>
                  <span>
                    <b>{item?.value}</b>
                  </span>
                </div>
              ) : null
            )
          : null}
      </div>
    </div>
  );
}

export default LineCharts;

import Modal from "../../components/Modal";
import Button from "../../components/Form/Button";

function Warning(props) {
  const { onClickCancel, onClickOk } = props;
  return (
    <Modal show={props?.open} onClose={props?.close} heading="Confirmation">
      <div>
        <b style={{fontSize:'18px'}}>
        The respective content will be {props?.types ? "hide" : "shown"} in user
        landing page</b>
      </div>
      <br />
      <div className="d-flex justify-content-end">
        <div className="btn-container">
          <Button className={"btn-color-primary"} onClick={onClickCancel}>
            Cancel
          </Button>
        </div>
        &nbsp;
        <div className="btn-container">
          <Button className={"btn-color-primary"} onClick={onClickOk}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default Warning;

import { Form } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import Button from "../../../../../components/Form/Button";
import { sellerFilterUrls, sellerUrls } from "./urls";

// table data URL
export const getURL = (key, offsetValue, perPageValue) => {
  if (!sellerUrls.hasOwnProperty(key)) return "";
  return sellerUrls[key](offsetValue, perPageValue);
};

// get payload
export  const getPayLoad = (data,offset,perPage) => {
  const defaultPayload = {
    pageNo: offset,
    limit: perPage,
  };
    const payloadMap = {
      cityPocList: {
        pageNo: offset,
        limit: perPage,
        poc: "cityPoc",
        
      },
      developerPocList: {
        pageNo: offset,
        limit: perPage,
        poc: "brandPoc",
        
      },
      propertyAcceptedList:{
        pageNo: offset,
        limit: perPage,
        demandStatus: "completed",
        propertyType: "accept",
        customerType: "Developer"
      },
      propertyRejectedList: {
        pageNo: offset,
        limit: perPage,
        demandStatus: "rejected",
        customerType: "Developer"
      },
      needMoreInfoList:{
        pageNo: offset,
        limit: perPage,
        propertyType: "needInfo",
        customerType: "Developer"
      },
      propertyPostedAgainstList:{
        pageNo: offset,
        limit: perPage,
      demand: true,
      customerType: "Developer"
      },
      propertyPostedList:{
        pageNo: offset,
        limit: perPage,
        customerType: "Developer",
      },
      propertyResponseList:{
        pageNo: offset,
        limit: perPage,
        customerType: "Developer",
        response:true
      },
      propertyInActiveList:{
        pageNo: offset,
        limit: perPage,
        customerType: "Developer",
        isActive:false
      },
      propertyActiveList:{
        pageNo: offset,
        limit: perPage,
        customerType: "Developer",
        isActive:true
      },
      propertyContactedList:{
        pageNo: offset,
        limit: perPage,
        customerType: "Developer",
      },  
    };
  
    return (payloadMap[data] || defaultPayload);
  }

// table data key
export const tableDataKey = (key, response) => {
  if (!key) return "";
  const lookup = {
    developerList: "brand",
    propertiesPostedList: "properties",
    developerPocList: "brand",
    cityPocList: "brand",
    propertyAcceptedList:"responseData",
    propertyRejectedList:"responseData",
    needMoreInfoList:"responseData",
    propertyContactedList:"responseData",
    propertyPostedList:"propertyData",
    propertyPostedAgainstList:"propertyData",
    propertyResponseList:"propertyData",
    propertyInActiveList:"propertyData",
    propertyActiveList:"propertyData",

  };

  const dataKey = lookup[key];
  return dataKey;
};

// filter
// filter URL
export const getFilterURL = (key) => {
  if (!sellerFilterUrls.hasOwnProperty(key)) return "";
  return sellerFilterUrls[key]();
};

// filter payload'
export const getFilterPayLoad = (data, offset, perPage) => {
  const payloadMap = {
    developerList: {
      type: "Developer",
    },
    developerPocList:{
        pocType:"developerPoc"
    },
    cityPocList:{
        pocType:"cityPoc"
    },
    propertyPostedList: {
      customerType: "Developer"
    },
    
    propertyResponseList: {
      customerType: "Developer",
      response: true
    },
    propertyPostedAgainstList: {
      demand: true,
      customerType: "Developer"
    },
    propertyActiveList: {
      customerType: "Developer",
      isActive: true,
    },
    propertyInActiveList: {
      customerType: "Developer",
      isActive: false,
    },
    propertyContactedList: {
      propertyType: "contectSeller",
      customerType: "Developer"
    
    },
    propertyAcceptedList: {
      customerType: "Developer",
      demandStatus: "completed",
      propertyType: "accept",
    },
    propertyRejectedList: {
      demandStatus: "rejected",
      customerType: "Developer"
    },
    needMoreInfoList: {
      customerType: "Developer",
      propertyType: "needInfo",
    },
  };

  return payloadMap[data];
};
// formatter

export function viewbuttonFormatter(onClick) {
  return (cell, row, rowIndex) => {
    return (
      <Button
        className="d-flex table-btn p-0 m-auto"
        onClick={() => onClick(row)}
      >
        <BsFillEyeFill />{" "}
      </Button>
    );
  };
}

export function userButtonFormatterPoc(page, openModal, openWaring, table) {
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    return (
        <div className="d-flex justify-content-center align-items-center ">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => openModal("view", brandId, row)}
          >
            <BsFillEyeFill />
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            style={{ cursor: "pointer" }}
            onClick={() => openModal("edit", brandId, row)}
          >
            <FaPen />
          </div>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={() => openWaring(row, row.isActive, table)}
            // disabled={props.access.enable?false:true}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
    );
  };
}

export function postedByFormatter() {
  return (cell, row, rowIndex) => {
    if (row?.brandNameRequired) {
      return `${row.brandId ? row.brandId?.name : "Anonymous"}`;
    }
    return "Anonymous";
  };
}

export function properSizeFormatter() {
  return (cell, row, rowIndex) => {
    return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${
      row.carpetAreaMax ? row.carpetAreaMax : 0
    } sqft`;
  };
}

export function createdOnFormatter(key = "createdAt") {
  return (cell, row, rowIndex) => {
    if (!row[key] || row[key] === null || row[key] === "" || !(key in row)) {
      return "-";
    }
    return new Date(row[key]).toLocaleString();
  };
}
export function cityFormatter(handelOpenListPopup) {
  return (cell, row, rowIndex) => {
    const cities = row?.cities
      ?.filter((city) => city?.name)
      .map((city) => city.name);
    const cityString = cities?.join(", ");

    if (!cityString) {
      return "-";
    }
    return (
      <>
        {cities.length > 1 ? (
          <span>
            {`${cities[0]},..`}
            <span
              className="view-all-btn cursor-pointer"
              onClick={() => handelOpenListPopup(cities)}
            >
              (View all)
            </span>
          </span>
        ) : (
          <span>{cityString}</span>
        )}
      </>
    );
  };
}
export function onClickIdFormater(key, onClickView, toPage = "") {
  return (cell, row, rowIndex) => {
    return (
      <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn">
        {" "}
        <span
          className="cursor-pointer"
          onClick={() => onClickView(row, toPage)}
        >
          {row[key]?.display}
        </span>
      </div>
    );
  };
}
export function companyNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.company?.companyName;
  };
}

export function propertyTypeFormatter() {
  return (cell, row, rowIndex) => {
    return row?.propertyType?.name;
  };
}

// optional field
export function projectNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.projectName !== "" ? row?.projectName : "-";
  };
}

export function onClickCountFormater(key, onClickView) {
  return (cell, row, rowIndex) => {
    return (
      <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn">
        {" "}
        <span className="cursor-pointer" onClick={() => onClickView(row)}>
          {row[key]}
        </span>
      </div>
    );
  };
}

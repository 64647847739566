import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

function ImageGalleryBox(props) {
  return (
    <div>
      <Lightbox image={props.imageUrl} title="" onClose={props.onClose}/>
    </div>
  )
}

export default ImageGalleryBox

import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import noImage from "../../../assets/images/noImage.jpg";
// import Rectangle from "../../../assets/images/Livingroom9.jpg";
import Rectangle from "../../../assets/images/Livingroom9.png";
import Button from "../../Form/Button";
import "./style.scss";

export const PropertyCard = ({
  apiCardDetail,
  fromAgentCard,
  onSelectProperties,
  showCheckBox,
  button,
  access
}) => {
  const cardDetails = [
    {
      key: "propertyTypeId",
      name: "Property Type",
      suffix: "",
      prefix: "",
    },
    {
      key: "carpetAreaMax",
      name: "Carpet Area",
      suffix: " sq.ft",
      prefix: "",
    },
    {
      key: "builtupArea",
      name: "Builtup Area",
      suffix: " sq.ft",
      prefix: "",
    },
    {
      key: "possession",
      name: "Possession from",
      suffix: "",
      prefix: "",
    },
    {
      key: "rentPsf",
      name: "Rent per square feet",
      suffix: " /sq.ft",
      prefix: "₹ ",
    },
    {
      key: "createdAt",
      name: "Posted on",
      suffix: "",
      prefix: "",
    },
  ];

  const cardDataList = [
    { name: "Formats", id: "format", typeFor: ["Flex Space"] },
    { name: "Available From", id: "possession", typeFor: ["Flex Space"] },
    { name: "Posted on", id: "createdAt", typeFor: ["Flex Space"] }
  ]

  const possessionData = [
    {
      value: 1,
      label: "Immediate",
    },
    {
      value: 3,
      label: "Within 3 months",
    },
    {
      value: 6,
      label: "Within 6 months",
    },
    {
      value: 9,
      label: "Within 9 months",
    },
    {
      value: 12,
      label: "After 9 months",
    },
  ];


  const getImage = (property, propertyType) => {
    let src;
    if (propertyType == "Flex Space") {
      if(property?.coworkingImages?.workSpaceImages) {
        src = property?.coworkingImages?.workSpaceImages[0]?.viewUrl
        ? property?.coworkingImages?.workSpaceImages[0].viewUrl
        : Rectangle
      } else {
        src = Rectangle
      }
      
    } else {
      if(property?.imagesAndVideos?.frontSide) {
        src = property?.imagesAndVideos?.frontSide[0]?.viewUrl
        ? property.imagesAndVideos.frontSide[0].viewUrl
        : Rectangle
      } else {
        src = Rectangle
      }
      
    } return src
  }


  return (
    <div
      className={
        fromAgentCard
          ? "property-card-container paddStyle"
          : "property-card-container"
      }
    >
      {apiCardDetail.map((a, index) => {
        return (
          <div key={index} className=" property-card p-2" id={a?._id}>
            {a?.featuredProperty ? (
              <span className="ribbon-wrapper">
                <div class="glow">&nbsp;</div>
              </span>
            ) : (
              ""
            )}

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {showCheckBox ? (
                  <Form.Check
                    type="checkbox"
                    id={"check-api-checkbox-input"}
                    style={{ marginRight: "10px" }}
                    className="m-0"
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      style={{ cursor: "pointer" }}
                      id={`check ${a?._id}`}
                      onChange={() => onSelectProperties(a._id)}
                    />
                  </Form.Check>
                ) : (
                  ""
                )}
              </div>
              <img
                className="property-profile"
                src={getImage(a, a?.propertyTypeId?.name)}
                alt="Property Image"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = Rectangle;
                }}
              />
              <div className="property-card-left-content">
                <div className="d-flex">
                  <div style={{ width: "50%" }}>
                    <p
                      className="card-sub-text fw-800 f-20 mb-0 mw-70 text-center text-sm-start overflow-hidden-ellipsis"
                      title={`Space for ${a?.transactionType} in ${a?.microLocation?.description
                        ? a?.microLocation?.description
                        : ""
                        }`}
                    >
                      {`Space for ${a?.transactionType} in ${a?.microLocation?.description
                        ? a?.microLocation?.description
                        : ""
                        }`}
                    </p>
                    <p className="f-16 m-0">
                      <b>Property ID: </b>
                      {a?.productId?.display}
                    </p>
                    <p className="card-sub-text fw-400 f-14 mb-1">
                      {a.location}
                    </p>
                    <div className="d-block">
                      <p className="f-20 fw-500 mb-0">{a?.transactionType}</p>
                    </div>
                  </div>

                  {/* <div className="property-end-style">
                      <Button
                        onClick={() => onclick(a)}
                        className={"btn-color-primary"}
                      >
                        View Property
                      </Button>
                    </div> */}
                </div>


                {a?.propertyTypeId?.name !== "Flex Space" &&
                  <div className="row mt-0">
                    {cardDetails.map((c, i) => {
                      let possessionValue;
                      if (c.key === "possession") {
                        possessionData.filter((el) =>
                          el.value === a.possession
                            ? (possessionValue = el.label)
                            : " -"
                        );
                      }
                      let carpetArea;
                      if (a.customerType == "Developer" && a.propertyUnit == "multiple") {
                        carpetArea = `${a.carpetAreaMin ? a.carpetAreaMin : "0"} - ${a.carpetAreaMax ? a.carpetAreaMax : "0"} sqft`
                      } else {
                        carpetArea = `${a.carpetAreaMax ? a.carpetAreaMax : "0 sqft"}`
                      }
                      let value =
                        c.key in a
                          ? c.key === "propertyTypeId"
                            ? a["propertyTypeId"]?.name
                            : c.key === "possession"
                              ? possessionValue
                              : c.key === "createdAt"
                                ? moment(a["createdAt"]).format("DD-MM-YYYY")
                                : c.key === "carpetAreaMax"
                                  ? carpetArea
                                  : [c.prefix !== "" ? c.prefix : ""] +
                                  a[c.key] +
                                  [c.suffix !== "" ? c.suffix : ""]
                          : "-";

                      return (
                        <p
                          key={i}
                          className="property-detail f-14"
                          title={value}
                        >
                          <b>{c.name} : </b> {value}
                        </p>
                      );
                    })}

                  </div>}

                {a?.propertyTypeId?.name == "Flex Space" &&
                  <>
                    {cardDataList?.length > 0 && cardDataList?.map((data, index) => {
                      let value;
                      if (data?.id in a) {
                        if (a[data?.id] !== "" || a[data?.id] !== null) {

                          if (data?.id === "createdAt") {
                            value = moment(a[data?.id]).format("DD-MM-YYYY");
                          }
                          if (data?.id == "format") {
                            value = a[data?.id]
                          }
                          if (data?.id == "possession") {
                            possessionData.filter((el) =>
                              a["possession"] === el.value
                                ? (value = el.label)
                                : "-"
                            );
                          }

                        } else {
                          value = "-"
                        }
                      } else {
                        value = "-"
                      }
                      return (
                        <p
                          title={"value"}
                          style={{ width: "100%", display: "flex" }}
                          key={index}
                          className="propertyValues f-14 fw-400"
                        >
                          <div style={{ display: "flex" }}>
                            <div className={"f-14 fw-700"} style={{ marginRight: "4px" }} key={index}>{`${data?.name}: `}</div>
                            <div>
                              {
                                (data?.id == "format") ?
                                  // value?.length > 1 ?
                                  //   value?.map((data) => {
                                  //     return data?._id?.name
                                  //   })?.join(', ')
                                  //   : value[0]?._id?.name
                                  <div>
                                    <table style={{ width: "100%", padding: 0 }}>
                                      {/* <tr style={{ color: "#3E495" }}>
                                      <th>Formats</th>
                                      <th>{"Rate per Seat"}</th>
                                    </tr> */}
                                      {
                                        value?.filter((val) => { return val?.amount })?.map((datas) => {
                                          return (
                                            <tr>
                                              <td style={{ paddingRight: "10px" }}>{datas?._id?.name}</td>
                                              <td style={{ paddingRight: "10px" }}>-</td>
                                              <td>{datas?.amount > 0 ? `Rs. ${datas?.amount}/seat` : "-"}</td>
                                            </tr>
                                          )
                                        })
                                      }
                                    </table>
                                  </div>
                                  :
                                  ` ${value}`
                              }
                            </div>

                          </div>
                        </p>
                      )
                    })
                    }
                  </>
                }


              </div>
            </div>
            <div className="btn-container justify-content-center mt-2">
              {button &&
                button.map((item, index) => {
                  let edit = item?.btnName === "Edit" && access?.edit
                  let view = item?.btnName === "View" && access?.view
                  return (
                    <Button
                      key={index}
                      className={item.className}
                      onClick={() =>
                        item.onClick(a)
                      }
                      disabled={edit || view ? false : true}
                    >
                      {item.btnName}
                    </Button>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const RelevantPropertyCard = ({
  propertyData,
  btnList,
  relevent,
  response,
  demandStatus,
  noPostedBy,
}) => {
  const navigate = useNavigate();
  const [setUserType] = React.useState("");

  React.useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("keyDemand_user_data"));
    setUserType(userData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {propertyData &&
        propertyData.map((property, index) => (
          <div className="propertyCard-container" key={index}>
            <div className="row">
              <div className="col-md-9 parent-col">
                <div className="row content">
                  <div className="col-md-4">
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${property?.imagesAndVideos?.frontSide[0]?.viewUrl
                          ? property?.imagesAndVideos?.frontSide[0]?.viewUrl
                          : property?.imageViewUrl
                            ? property?.imageViewUrl
                            : noImage
                          })`,
                      }}
                    ></div>
                  </div>
                  <div className="col-md-8">
                    <div className="inner-content">
                      <p
                        className="card-sub-text fw-800 f-20 mb-0 mw-70 text-center text-sm-start overflow-hidden-ellipsis"
                        title={`Space for ${property?.transactionType} in ${property?.microLocation?.description
                          ? property?.microLocation?.description
                          : ""
                          }`}
                      >
                        {/* {property?.additionalDetails?.idealFor?.map((val, index) => {
                        let titleLable = `${val ? val?.value || val?.label ||val?.name || val : ""} ${property?.additionalDetails?.idealFor?.length === index + 1 ? "" : ", "}`
                        return <span title={`${val ? val?.value || val?.label||val?.name || val : ""} ${property?.additionalDetails?.idealFor?.length === index + 1 ? "" : ", "} space for ${property?.transactionType}`} 
                        key={index}>
                          {val ? val?.value || val?.label ||val?.name || val : ""}
                        {property?.additionalDetails?.idealFor?.length === index + 1 ? "" : ", "}
                        </span>
                      })} */}
                        {`Space for ${property?.transactionType} in ${property?.microLocation?.description
                          ? property?.microLocation?.description
                          : ""
                          }`}
                      </p>
                      <div>
                        <p className="f-16 fw-400">
                          <span className="f-16 fw-700">Property type : </span>{" "}
                          {property?.propertyType
                            ? property.propertyType?.name
                            : property?.propertyTypeId
                              ? property?.propertyTypeId?.name
                              : "-"}
                        </p>
                        <p className="f-16 fw-400">
                          <span className="f-16 fw-700">Carpet area : </span>{" "}
                          {property?.carpetArea
                            ? property.carpetArea + " sqft"
                            : "-"}
                        </p>
                        <p className="f-16 fw-400">
                          <span className="f-16 fw-700">Builtup area : </span>{" "}
                          {property?.builtupArea
                            ? property?.builtupArea + " sqft"
                            : "-"}
                        </p>
                        <p className="f-16 fw-400">
                          <span className="f-16 fw-700">Tenure : </span>{" "}
                          {property?.tenure ? property?.tenure + " Years" : "-"}
                        </p>
                        <p className="f-16 fw-400">
                          <span className="f-16 fw-700">
                            Rent PSF on carpet area:{" "}
                          </span>{" "}
                          {property?.rentPsf
                            ? "₹ " + property.rentPsf + " /sq.ft"
                            : "-"}
                        </p>
                        {relevent || response || noPostedBy ? (
                          ""
                        ) : (
                          <p className="propertyValues">
                            <span className="fw-700 f-16">Posted By: </span>
                            <span
                              style={{ cursor: "pointer" }}
                              className="posted-by-name f-16 fw-700"
                              onClick={() =>
                                navigate("/seller-profile", {
                                  state: { brandId: property?.userId?._id },
                                })
                              }
                            >
                              {property?.projectDeveloper
                                ? property?.projectDeveloper
                                : property?.developerId?.name}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 parent-col">
                <div className="row">
                  <div
                    className={
                      relevent || response
                        ? "col-md-9 sub-col-9"
                        : "col-md-12 sub-col-9"
                    }
                  >
                    <div className="buttons ">
                      {/* <label className={userType?.role[0]?.name === 'agent' ?"tag-style brokerColor" : "tag-style"}>{userType?.role[0]?.name === 'agent' ?"Broker" : 'Developer'}</label> */}
                      <label className="tag-style">Developer</label>
                      <div className="btn-content">
                        <div>
                          <div className="d-block text-end">
                            <p className="f-20 fw-500">
                              {property?.transactionType &&
                                property?.transactionType}
                            </p>
                            {/* <p className="f-24 fw-700">{property?.deposit ? 'Rs.' + " " + property?.deposit : '-'}</p> */}
                          </div>
                        </div>
                        {btnList ? (
                          <div className="w-100 justify-content-end btn-list">
                            {btnList?.map((item, btnIndex) => {
                              return (
                                <Button
                                  key={btnIndex}
                                  className={item.className}
                                  onClick={() => item.onClick(property)}
                                >
                                  {item.name}
                                </Button>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {relevent || response ? (
                    <div className="col-md-3 sub-col-3">
                      {demandStatus ? (
                        <div
                          className={`${property?.demands?.status === "pending" ? "" : ""
                            }${property?.demands?.status === "completed"
                              ? "completed-style"
                              : ""
                            }${property?.demands?.status === "rejected"
                              ? "pending-style"
                              : ""
                            } text-center`}
                        >
                          {property?.demands?.status === "completed" &&
                            "Completed"}
                          {property?.demands?.status === "rejected" &&
                            "Rejected"}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="colors">
                        {relevent ? (
                          <h4
                            className={`${property?.matchPercentage >= 50 ? "green" : "red"
                              }`}
                          >
                            {property?.matchPercentage === 0
                              ? 0
                              : property?.matchPercentage}{" "}
                            %<span>Relevance</span>
                          </h4>
                        ) : (
                          <h4
                            className={`${property?.responses >= 50 ? "green" : "red"
                              }`}
                          >
                            {property?.responses?.length <= 0
                              ? 0
                              : property?.responses}{" "}
                            %<span>Responses</span>
                          </h4>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export const ResposePropertyCard = ({ propertyData, btnList, relevent, response, demandStatus, checkBox, onSelectProperties, }) => {




  React.useEffect(() => {
    // let userData = JSON.parse(localStorage.getItem("keyDemand_user_data"))
    // setUserType(userData)
  }, [])
  return (
    <>
      {propertyData && propertyData.map((property, index) => (
        <div className="propertyCard-container" key={index}>
          <div className="row">
            <div className="col-md-9 parent-col d-flex align-items-center">
              {property?.featuredProperty ? <span className="ribbon-wrapper"><div class="glow">&nbsp;</div></span> : ""}

              {checkBox ? <Form.Check
                type="checkbox"
                id={"check-api-checkbox-input"}
                className="m-0"
              >
                <Form.Check.Input
                  type={"checkbox"}
                  id={`check ${property?._id}`}
                  checked={property?.isChecked ? true : false}
                  onChange={() => onSelectProperties(property)}
                />
              </Form.Check> : ""}
              <div className="row content">
                <div className="col-md-3">
                  <div className="image" style={{ backgroundImage: `url(${property?.imagesAndVideos?.frontSide[0]?.viewUrl ? property?.imagesAndVideos?.frontSide[0]?.viewUrl : property?.imageViewUrl ? property?.imageViewUrl : noImage})` }}>

                  </div>
                </div>
                <div className="col-md-9">
                  <div className="inner-content">
                    <p className='card-sub-text fw-800 f-20 mb-0 mw-70 text-center text-sm-start overflow-hidden-ellipsis m-0' title={`Space for ${property?.transactionType} in ${property?.microLocation?.description ? property?.microLocation?.description : ""}`}>
                      {/* {property?.additionalDetails?.idealFor?.map((val, index) => {
                        let titleLable = `${val ? val?.value || val?.label ||val?.name || val : ""} ${property?.additionalDetails?.idealFor?.length === index + 1 ? "" : ", "}`
                        return <span title={`${val ? val?.value || val?.label||val?.name || val : ""} ${property?.additionalDetails?.idealFor?.length === index + 1 ? "" : ", "} space for ${property?.transactionType}`} 
                        key={index}>
                          {val ? val?.value || val?.label ||val?.name || val : ""}
                        {property?.additionalDetails?.idealFor?.length === index + 1 ? "" : ", "}
                        </span>
                      })} */}
                      {`Space for ${property?.transactionType} in ${property?.microLocation?.description ? property?.microLocation?.description : ""}`}</p>
                    <div className="f-18 fw-500">Property ID:<span className="px-1">{property?.productId?.display}</span></div>
                    <p className="fw-400 f-14 mb-0">{property?.location}</p>
                    <div>
                      <p className="f-16 fw-400 mb-0"><span className="f-16 fw-700" title={property?.propertyTypeId?.name}>Property Type : </span>{property?.propertyTypeId?.name?.length > 4 ? `${property?.propertyTypeId?.name?.slice(0, 4)}...` : property?.propertyTypeId?.name}</p>
                      <p className="f-16 fw-400 mb-0"><span className="f-16 fw-700">Carpet area : </span> {property?.carpetArea ? property.carpetArea + " sqft" : "-"}</p>
                      <p className="f-16 fw-400 mb-0"><span className="f-16 fw-700">Builtup area : </span> {property?.builtupArea ? property?.builtupArea + " sqft" : "-"}</p>
                      {property?.tenure?.toString() && <p className="f-16 fw-400 mb-0"><span className="f-16 fw-700">Tenure : </span> {property?.tenure?.toString() ? property?.tenure?.toString() + " years" : "-"}</p>}
                      <p className="f-16 fw-400 mb-0"><span className="f-16 fw-700">Rent PSF on carpet area: </span> {property?.rentPsf ? "Rs. " + property.rentPsf : '-'}</p>
                      <p className="f-16 fw-400 mb-0"><span className="f-16 fw-700">Posted on: </span>{moment(property?.createdAt).format('DD-MM-YYYY')}</p>
                      {relevent || response ? "" : <p className="propertyValues mb-0">
                        <span className="fw-700 f-16">Posted by: </span>
                        <span

                          className="f-16 fw-500"

                        >
                          {property?.customerType === "Agent"
                            ? property?.developerId?.contactPerson
                            : property?.projectDeveloper
                              ? property?.projectDeveloper
                              : property?.developerId?.name}
                        </span>
                      </p>}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 parent-col">
              <div className="row">
                <div className={relevent || response ? "col-md-8 sub-col-8" : "col-md-12 sub-col-9"}>
                  <div className="buttons ">

                    {property?.customerType === "Agent" ? (
                      <label className="broker-lable_custom  text-center ">Agent</label>
                    ) : (
                      <label className="devloper-lable  text-center">Developer</label>
                    )}


                    {demandStatus ? <div className={`${property?.demands?.status === "pending" ? "" : ""}${property?.demands?.status === "completed" ? "completed-style" : ""}${property?.demands?.status === "rejected" ? "pending-style" : ""} text-center my-2 `}>{property?.demands?.status === "completed" && "Accepeted"}{property?.demands?.status === "rejected" && "Rejected"}</div> : ""}

                    <div className="btn-content">
                      <div>
                        <div className="d-block text-end">
                          <p className="f-20 fw-500">For {property?.transactionType && property?.transactionType}</p>
                          {/* <p className="f-24 fw-700">{property?.deposit ? 'Rs.' + " " + property?.deposit : '-'}</p> */}
                        </div>
                      </div>

                      {btnList ?
                        <div className="w-100 justify-content-end btn-list">
                          {btnList?.map((item, btnIndex) => {
                            let disabledBtn = item.name === "Update and Submit" ? property?.demandResponse ? true : false : false
                            return (
                              <Button key={btnIndex} className={item.className} onClick={() => item.onClick(property)} disabled={disabledBtn}>
                                {disabledBtn ? "Property submitted" : item.name}
                              </Button>
                            )
                          })
                          }
                        </div> : ""}
                    </div>
                  </div>
                </div>

                {relevent || response ?
                  <div className="col-md-4 sub-col-3">
                    <div className="colors">
                      {relevent ?
                        <h4 className={`${property?.matchPercentage >= 50 ? 'green ' : 'red'} nowrap`}>{property?.matchPercentage === 0 ? 0 : property?.matchPercentage} %<span>Relevance</span></h4>
                        :
                        <h4 className={`${property?.responses >= 50 ? 'green' : 'red'} nowrap`}>{property?.responses?.length <= 0 ? 0 : property?.responses} %<span>
                          Responses</span></h4>
                      }


                    </div>
                  </div>
                  : ""}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}


import React, { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Form from "react-bootstrap/Form";
import { BsFillInfoCircleFill } from "react-icons/bs";
import defaultLogo from "../../../assets/images/defaultLogo.png";

import {
  postUploadData,
  getData,
  getClienteleData,
  setClienteleStatus,
  deleteClientele,
  putUploadData,
} from "../../../api";
import Button from "../../../components/Form/Button";
import Select from "../../../components/Form/Select/index";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import "../contentManagement.css";
import "../style.scss";
import FormModal from "../warning";
import ClienteleBox from "./clienteleBox";
import Input from "../../../components/Form/Input";
import LogoUpload from "./clienteleLogoUpload";
import MainTable from "../../../components/Table";
import { FaPen, FaPencilAlt, FaTrash } from "react-icons/fa";
import { CustomerWarningPopup } from "../../Customer/CustomerTable/warningPopup";

function Clientele(props) {
  const [handleBox, setHandleBox] = useState(false);
  const [handlePost, setHandlePost] = useState(false);
  //   const [getApiData, setGetApiData] = useState("");
  const [brandListData, setBrandListData] = useState([]);
  const [filterClientele, setFilterClientele] = useState("");
  const [brandData, setBrandData] = useState([]);
  const [businessUseData, setbusinessUseData] = useState([]);
  const [clienteleType, setClienteleType] = useState("");
  const [listValue, setListValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState("Add");
  const [errorMessage, setErrorMessage] = useState({});
  const [checked, setChecked] = useState("new");
  //Edit Clientele
  const [isEditing, setIsEditing] = useState(false);
  const [editClinteleId, setEditClinteleId] = useState("");
  //delete clintele
  const [warningMessage, setWarningMessage] = useState("");
  const [currentClienteleId, setCurrentClienteleId] = useState("");
  const [delteClienteleWarningPopup, setDelteClienteleWarningPopup] =
    useState(false);
  let dummy = {
    customer_id: "",
    business_use: "",
    image: "",
    role: "",
  };

  //pagination
  const [offset, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalDataList, setTotalDataList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [lastIndex, setLastIndex] = useState(0);

  const [imageData, setImageData] = useState({});
  const [defaultImageData, setDefaultImageData] = useState("");
  const [clientele, setClientele] = useState(dummy);
  const [bannerToggle, setbannertoggle] = useState(false);

  let customerRole = [
    { label: "Brand", value: "BRAND" },
    { label: "Developer", value: "DEVELOPER" },
  ];

  let brandBuissnesUse = [
    { value: "CAFE", label: "Cafe" },
    { value: "RESTAURANT", label: "Restaurant" },
    { value: "CLOUD_KITCHEN", label: "Cloud kitchen" },
    { value: "CLINIC", label: "Clinic" },
    { value: "GYM", label: "Gym" },
    { value: "ELECTRONICS", label: "Electronics store" },
    { value: "JEWELLERY", label: "Jewellery store" },
    { value: "CLOTHING", label: "Clothing store" },
    { value: "FOOTWEAR", label: "Footwear store" },
    { value: "AUTOMOTIVE", label: "Automotive showroom" },
    { value: "COSMETICS", label: "Cosmetics store" },
    { value: "BANKS_ATM", label: "Banks/ATM" },
    { value: "SALON", label: "Salon" },
    { value: "SUPERMARKET", label: "Supermarket" },
    { value: "OTHER", label: "Other" },
  ];

  let developerBuisnesUse = [
    { value: "DEVELOPERS", label: "Developers" },
    { value: "MALLS", label: "Malls" },
  ];

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setPageCount(Math.ceil(totalDataList.length / count));
    setListValue([...totalDataList.slice(0, count)]);
    setLastIndex(count - 1);
    setOffset(1);
  };

  const modalClick = (e) => {
    e.preventDefault();
    setIsEditing(false);
    setDefaultImageData("");
    setHandleBox(true);
    setChecked("new");
    setAction("Add");
  };

  const getBrandLIst = async () => {
    setLoading(true);
    let url = `/contentManagement/getCustomersByRole?role=${clientele?.role}`;
    const getbrand = await getData(url, {});
    const { data, status } = getbrand;
    if (status) {
      setLoading(false);
      let customerList = data.map((item) => {
        return {
          label: item?.name,
          value: item?._id,
        };
      });
      setBrandListData(customerList);
      setBrandData(data);
    }
  };

  const clickOk = () => {
    setbannertoggle(!bannerToggle);
    // triggerToggleApi();
  };
  const clickCancel = () => {
    setModalOpen(false);
  };

  const checkError = (value) => {
    let err = {};
    if (clienteleType === "new" && !isEditing) {
      if (!value.hasOwnProperty("image") || value.image?.length === 0) {
        err.image = "This Field is required";
      }
    }
    if (clienteleType === "new") {
      if (
        !value.hasOwnProperty("customer_name") ||
        value.customer_name?.length === 0
      ) {
        err.customer_name = "This Field is required";
      }
    } else {
      if (
        !value.hasOwnProperty("customer_id") ||
        value.customer_id?.length === 0
      ) {
        err.customer_id = "This Field is required";
      }
    }

    if (
      !value.hasOwnProperty("business_use") ||
      value.business_use?.length === 0
    ) {
      err.business_use = "This Field is required";
    }

    if (!value.hasOwnProperty("role") || value.role?.length === 0) {
      err.role = "This Field is required";
    }

    return err;
  };

  //Handle Edit Clientle
  const handleEdit = (id) => {
    setEditClinteleId(id);
    const flteredClintele = listValue.filter((client) => client?._id === id);
    const customerName = flteredClintele[0]?.customerName;
    const buisnessUse = flteredClintele[0]?.businessUseFor.toUpperCase();
    const role = flteredClintele[0]?.role.toUpperCase();
    setClienteleType("new");
    if (role === "BRAND") {
      const arr = brandBuissnesUse.filter(
        (item) => item.label === flteredClintele[0]?.businessUseFor
      );
      var newBuisness = arr[0]?.value;
    }
    setClientele({
      customer_name: customerName,
      business_use: role === "BRAND" ? newBuisness : buisnessUse,
      image: "",
      role: role,
    });

    if (flteredClintele[0]?.role.toUpperCase() === "BRAND") {
      setbusinessUseData(brandBuissnesUse);
    } else if (flteredClintele[0]?.role.toUpperCase() === "DEVELOPER") {
      setbusinessUseData(developerBuisnesUse);
    } else {
      setbusinessUseData([]);
    }
    setDefaultImageData(flteredClintele[0]?.logo?.viewUrl);
    setHandlePost(true);

    setIsEditing(true);
  };

  //Handle Delte Clientele
  const handleDlt = async (row) => {
    setCurrentClienteleId(row._id);
    setDelteClienteleWarningPopup(true);
    setWarningMessage("Are you sure, do you want to delete this Clientele?");
  };

  const handleClinteleAdd = async (action) => {
    let copyData = { ...clientele };
    const error = checkError(copyData);
    setLoading(true);
    if (Object.keys(error).length > 0) {
      setErrorMessage(error);
      setLoading(false);
    } else {
      const formData = new FormData();
      formData.append("image", clientele.image);
      formData.append("business_use", clientele.business_use);

      formData.append("role", clientele.role);

      //Editing Clientele
      if (action === "edit") {
        formData.append("customer_name", clientele.customer_name);
        var url = `/contentManagement/handleUpdation/${editClinteleId}`;
        const editClinteleData = await putUploadData(url, {}, formData);
        const { status, message } = editClinteleData;
        if (status) {
          Swal.fire({
            // title: header,
            text: message,
            icon: "success",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
          setLoading(false);
          setHandlePost(false);
          setErrorMessage({});
          getClinteleList();
        } else {
          setLoading(false);
          Swal.fire({
            // title: header,
            text: message,
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        }
        setLoading(false);

        //Adding Clienele
      } else {
        if (clienteleType === "new") {
          formData.append("customer_name", clientele.customer_name);
          var url = `/contentManagement/newClientClientele`;
        } else {
          formData.append("customer_id", clientele.customer_id);
          var url = `/contentManagement/addExistingClientClientele`;
        }
        const handleClintelePost = await postUploadData(url, {}, formData);
        const { status, message } = handleClintelePost;
        if (status) {
          Swal.fire({
            // title: header,
            text: message,
            icon: "success",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
          setLoading(false);
          setHandlePost(false);
          setErrorMessage({});
          getClinteleList();
        } else {
          setLoading(false);
          Swal.fire({
            // title: header,
            text: message,
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        }
      }
    }
  };

  //Image Handle
  const handleImage = async (file) => {
    delete errorMessage["image"];
    setDefaultImageData("");
    setClientele((pre) => ({ ...pre, image: file }));
    setErrorMessage((pre) => ({ ...pre, image: "" }));
  };

  // Handle Clientele Data Chnage
  const handleSelectdata = (data, key) => {
    delete errorMessage[key];
    if (key === "brandName") {
      if (clienteleType === "new") {
        delete errorMessage["customer_name"];
        setClientele({
          ...clientele,
          customer_name: data,
        });
      } else if (clienteleType === "existing") {
        delete errorMessage["customer_id"];
        const defaultData = brandData.filter((bdata) => bdata._id === data);
        setDefaultImageData(defaultData[0]?.logo?.viewUrl);
        setClientele((pre) => ({
          ...pre,
          customer_id: data,
        }));
      }
    } else if (key === "role") {
      delete errorMessage["role"];
      setBrandListData([]);
      if (clienteleType === "existing") {
        setDefaultImageData("");
        setClientele({
          ...clientele,
          role: data,
          business_use: "",
          customer_id: "",
          image: "",
        });
      } else {
        setClientele({
          ...clientele,
          role: data,
          business_use: "",
        });
      }

      if (data === "BRAND") {
        setbusinessUseData(brandBuissnesUse);
      } else if (data === "DEVELOPER") {
        setbusinessUseData(developerBuisnesUse);
      } else {
        setbusinessUseData([]);
      }
    } else if (key === "business_use") {
      delete errorMessage["business_use"];
      setClientele({
        ...clientele,
        business_use: data,
      });
    }
  };

  //Clientele Logo Formatter

  function clienteleLogoFormatter(cell, row, rowIndex) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <img
            src={row?.logo?.viewUrl}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultLogo;
            }}
            className="clientele-logo-img"
          />
        </div>
      </>
    );
  }

  //Clentele Action Buttons Formatter
  function userButtonFormatterClientele(cell, row, rowIndex) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row?.isActive ? row?.isActive : false}
            onChange={(e) => {
              handleStatusChange(row);
            }}
          />
          <Button className="table-btn p-0">
            <FaTrash
              className="icon-pointer cursor-pointer"
              onClick={() => handleDlt(row)}
            />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="table-btn p-0"
            // disabled={access?.delete ? false : true}
          >
            <FaPen
              className="icon-pointer cursor-pointer"
              onClick={() => handleEdit(row._id)}
            />
          </Button>
        </div>
      </>
    );
  }

  //Clientele Table Columns
  const headerDetail = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "logo",
      text: "Logo",
      formatter: clienteleLogoFormatter,
    },
    {
      dataField: "role",
      text: "Role",
    },
    {
      dataField: "customerName",
      text: "Customer Name",
    },

    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterClientele,
    },
  ];
  const handlePageChange = (pageNo) => {
    setListValue([
      ...totalDataList.slice(perPage * (pageNo - 1), pageNo * perPage),
    ]);
  };

  //Clientele Table Props
  const tableProps = {
    data: listValue,
    columns: headerDetail,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "clientele",
    setPerPage,
    handlePageChange,
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      // handleBlogchange(event);
    }
  };

  const deleteClienteleWarningYesClick = () => {
    const url = `/contentManagement/deleteClientele/${currentClienteleId} `;
    deleteClientele(url).then((res) => {
      if (res.status) {
        getClinteleList();
        setDelteClienteleWarningPopup(false);
      }
    });
  };

  const delteClienteleWarningClose = () => {
    setDelteClienteleWarningPopup(false);
  };

  //Handle Clientele Status Chnage
  const handleStatusChange = async (row) => {
    const url = `/contentManagement/handleClienteleVisiblity/${row?._id} `;
    const body = {
      activationStatus: !row?.isActive,
    };
    setLoading(true);
    const getClienteleStatus = await setClienteleStatus(url, body);
    const { data, status } = getClienteleStatus;
    if (status) {
      getClinteleList();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  // Get All Clientele Data
  const getClinteleList = async () => {
    if (filterClientele !== "") {
      var url = `/contentManagement/getAllClienteleList?role=${filterClientele}`;
    } else {
      url = `/contentManagement/getAllClienteleList`;
    }

    setLoading(true);
    const filter = {
      filter: {
        role: filterClientele,
      },
    };
    const getClienteleList = await getClienteleData(url, filter);
    const { data, status } = getClienteleList;
    if (status) {
      data.reverse();
      setLoading(false);
      setPageCount(Math.ceil(data.length / perPage));
      setListValue([...data.slice(0, perPage)]);
      setOffset(1);
      setTotalDataList([...data]);
      setTotalCount(data.length);
      setLastIndex(perPage - 1);
    } else {
      setLoading(false);
    }
  };

  //useEffects
  useEffect(() => {
    if (clientele?.role) {
      getBrandLIst();
    }
  }, [clientele?.role]);

  useEffect(() => {
    getClinteleList();
  }, [filterClientele]);

  //Return Component
  return (
    <div className="testWrappers">
      {modalOpen && (
        <FormModal
          open={modalOpen}
          close={setModalOpen}
          onClickOk={clickOk}
          onClickCancel={clickCancel}
          types={bannerToggle}
        />
      )}
      {loading && <Loader className={"fixed"} />}
      <div className="d-flex align-items-center justify-content-between mt-5 mb-4 mobileFlexWrapper">
        <div className="brand_text">
          <p className="contentManagementText">Add Clientele</p>
        </div>

        <div className="buttonDivision">
          <div className="">
            <select
              style={{
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              className="form-select filterBGColor "
              name="activeInactiveFilter"
              id="activeInactiveFilter"
              onChange={(e) => {
                setFilterClientele(e.target.value);
              }}
              value={filterClientele}
            >
              <option value={""}>Select</option>
              <option value={"BRAND"}>Brand</option>
              <option value={"DEVELOPER"}>Developer</option>
            </select>
          </div>
          &nbsp;&nbsp;&nbsp;
          <Button
            className="btn-color-primary"
            disabled={props.access.add ? false : true}
            onClick={modalClick}
          >
            Add
          </Button>
          &nbsp;
        </div>
      </div>
      <MainTable {...tableProps} />

      {/*  */}
      <Modal
        onClose={setHandlePost}
        show={handlePost}
        heading={isEditing ? "Update Clientele" : "Add Clientele"}
        size={"md"}
        loader="fixed"
      >
        <form onKeyDown={handleKeyPress}>
          <Select
            errorMessage={errorMessage?.role}
            label="Select Role *"
            options={customerRole}
            name={"role"}
            isInvalid={!!errorMessage?.role}
            value={clientele && clientele?.role}
            onChange={(e) => handleSelectdata(e.target.value, "role")}
            disabled={action === "Edit" ? true : false}
          />
          <div className="d-flex align-items-center justify-content-start mb-2">
            <BsFillInfoCircleFill color="#034168" />
            <p className="text-muted m-1 f-12">
              After selecting the role only you can access the customer name
            </p>
          </div>

          {clienteleType === "existing" ? (
            <Select
              disabled={
                clientele?.role ? (action === "Edit" ? true : false) : true
              }
              name={"customer_id"}
              label="Select Customer Name *"
              errorMessage={errorMessage?.customer_id}
              onChange={(e) => handleSelectdata(e.target.value, "brandName")}
              options={brandListData}
              value={clientele && clientele?.customer_id}
              isInvalid={!!errorMessage?.customer_id}
              lable="Customer Name *"
              placeholder={"Select customer name"}
            />
          ) : (
            <Input
              errorMessage={errorMessage?.customer_name}
              label="Customer Name *"
              type="text"
              name="customer_name"
              placeholder="Enter Customer Name"
              onChange={(e) => handleSelectdata(e.target.value, "brandName")}
              value={clientele && clientele?.customer_name}
              isInvalid={!!errorMessage?.customer_name}
            />
          )}

          <Select
            errorMessage={errorMessage?.business_use}
            label="Select Buissness Use *"
            options={businessUseData}
            name={"business_use"}
            isInvalid={!!errorMessage?.business_use}
            value={clientele && clientele?.business_use}
            onChange={(e) => handleSelectdata(e.target.value, "business_use")}
            disabled={
              clientele?.role ? (action === "Edit" ? true : false) : true
            }
          />

          <div>
            <label
              style={{
                fontStyle: "normal",
                fontSize: "14px",
                marginBottom: "12px",
              }}
            >
              <b>{`Logo ${clienteleType === "new" ? "*" : ""}`}</b>
            </label>
            <LogoUpload
              loading={loading}
              onHandelImage={handleImage}
              data={imageData}
              error={errorMessage?.image}
              defaultImageData={defaultImageData}
            />
            {errorMessage?.message && (
              <span
                className="imageSpan"
                style={{
                  color: "#dc3545",
                  fontSize: "10px",
                  marginLeft: "15px",
                }}
              >
                {errorMessage?.message}
              </span>
            )}
          </div>

          <div className="ButtonWrapper">
            <Button
              type="button"
              variant="primary"
              className="btn-outline-color-primary"
              onClick={() => setHandlePost(false)}
              disabled={loading}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              type="submit"
              variant="primary"
              className={"btn-color-primary"}
              onClick={(e) => {
                e.preventDefault();
                handleClinteleAdd(isEditing ? "edit" : "add");
              }}
              loading={loading}
            >
              {isEditing ? "Update" : "Add"}
            </Button>
          </div>
        </form>
      </Modal>

      <ClienteleBox
        showModal={handleBox}
        onHide={setHandleBox}
        checked={checked}
        setChecked={setChecked}
        onChangeCheckBox={(clienteleType) => {
          setClienteleType(clienteleType);
          setHandlePost(true);
          setErrorMessage({});
          setHandleBox(false);
          clienteleType === "new"
            ? setClientele({
                customer_name: "",
                business_use: "",
                image: "",
                role: "",
              })
            : setClientele({
                customer_id: "",
                business_use: "",
                image: "",
                role: "",
              });
        }}
      />
      {delteClienteleWarningPopup && (
        <CustomerWarningPopup
          title={"Confirmation"}
          show={delteClienteleWarningPopup}
          message={warningMessage}
          warningYesClick={deleteClienteleWarningYesClick}
          onClose={delteClienteleWarningClose}
        />
      )}
    </div>
  );
}

export default Clientele;

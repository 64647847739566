import { CloseButton, Form } from "react-bootstrap";
import "./style.scss";
const ClearInput = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  size,
  errorMessage,
  isInvalid,
  min,
  max,
  disabled,
  onFocus,
  onBlur,
  autocomplete,
  positive,
  className,
  sufix,
  prefix,
  required,
  hidden,
  close,
  onClose
}) => {

  return (
    <Form.Group className={"form-group " + size}>
      <Form.Control
        required={required ? required : false}
        type={type}
        name={name}
        value={value}
        isInvalid={isInvalid}
        placeholder={placeholder}
        onChange={onChange}
        min={min}
        max={max}
        id={name + "input"}
        disabled={disabled}
        autoComplete={autocomplete ? autocomplete : "off"}
        onBlur={onBlur}
        onFocus={onFocus}
        positive={positive}
        className={className}
        style={{
          paddingRight: "65px",
        }}
        hidden={hidden}
      />
      {close && <CloseButton  className="sufixWrapper" style={{width:"9px",height:"4px",top:"15px"}} onClick={onClose}/>}
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default ClearInput;

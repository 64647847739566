export const pocModalArray = [
  {
    key: "companyPOC",
    pocArray: [
      {
        name: "Primary POC *",
        key: "primary",
        type: "select",
        option: ["Yes", "No"],
      },
      {
        name: "POC Name *",
        key: "name",
        type: "input",
        inputType: "text",
        placeholder: "Enter text",
      },
      {
        name: "Designation *",
        key: "designation",
        type: "input",
        inputType: "text",
        placeholder: "Enter text",
      },
      {
        name: "Email *",
        key: "email",
        type: "input",
        inputType: "email",
        placeholder: "Enter email",
      },
      {
        name: "Phone Number *",
        key: "phoneNumber",
        type: "phoneInput",
        inputType: "number",
        placeholder: "Enter number",
      },
    ],
    pocObj:{
        primary:"",
        name:"",
        designation:"",
        email:"",
        phoneNumber:""
    }
  },
  {
    key: "brandPOC",
    pocArray: [
      {
        name: "Primary POC *",
        key: "primary",
        type: "select",
        option: ["Yes", "No"],
      },
      {
        name: "POC Name *",
        key: "name",
        type: "input",
        inputType: "text",
        placeholder: "Enter text",
      },
      {
        name: "Designation *",
        key: "designation",
        type: "input",
        inputType: "text",
        placeholder: "Enter text",
      },
      {
        name: "Email *",
        key: "email",
        type: "input",
        inputType: "email",
        placeholder: "Enter email",
      },
      {
        name: "Phone Number *",
        key: "phoneNumber",
        type: "phoneInput",
        inputType: "number",
        placeholder: "Enter number",
      },
    ],
    pocObj:{
        primary:"",
        name:"",
        designation:"",
        email:"",
        phoneNumber:"" 
    }
  },
  {
    key: "regionPOC",
    pocArray: [
      {
        name: "POC Name *",
        key: "name",
        type: "input",
        inputType: "text",
        placeholder: "Enter text",
      },
      {
        name: "Designation *",
        key: "designation",
        type: "input",
        inputType: "text",
        placeholder: "Enter text",
      },
      {
        name: "Email *",
        key: "email",
        type: "input",
        inputType: "email",
        placeholder: "Enter email",
      },
      {
        name: "Phone Number *",
        key: "phoneNumber",
        type: "phoneInput",
        inputType: "number",
        placeholder: "Enter number",
      },
      {
        name: "Select Region *",
        key: "region",
        type: "multiSelect",
        isMulti: true,
        option: [
            
        ],
      },
    ],
    pocObj:{
        name:"",
        designation:"",
        email:"",
        phoneNumber:"",
        region:[]
    }
  },
  {
    key: "cityPOC",
    pocArray: [
      {
        name: "POC Name *",
        key: "name",
        type: "input",
        inputType: "text",
        placeholder: "Enter text",
      },
      {
        name: "Designation *",
        key: "designation",
        type: "input",
        inputType: "text",
        placeholder: "Enter text",
      },
      {
        name: "Email *",
        key: "email",
        type: "input",
        inputType: "email",
        placeholder: "Enter email",
      },
      {
        name: "Phone Number *",
        key: "phoneNumber",
        type: "phoneInput",
        inputType: "number",
        placeholder: "Enter number",
      },
      {
        name: "Select State *",
        key: "state",
        type: "multiSelect",
        option: [],
        isMulti: false,
      },
      {
        name: "Select City *",
        key: "city",
        type: "multiSelect",
        option: [],
        isMulti: false,
      },
    ],
    pocObj:{
        name:"",
        designation:"",
        email:"",
        state:[],
        phoneNumber:"",
        city:[]
    }
  },
];

export const pocAccessArray = [
  {
    key:"brand",
    access:[
      {
        id:1,
        name:"Post a demand"
      },
      {
        id:2,
        name:"View/manage my demands"
      },
      {
        id:3,
        name:"View/manage response recieved"
      },
      {
        id:4,
        name:"View/manage my profile"
      },
      {
        id:5,
        name:"Add poc"
      }
    ]
  },
  {
    key:"developer",
    access:[
      {
        id:1,
        name:"Post a propery"
      },
      {
        id:2,
        name:"Submit a property against demand"
      },
      {
        id:3,
        name:"View/manage my properties"
      },
      {
        id:4,
        name:"View/manage response recieved"
      },
      {
        id:5,
        name:"View/manage my profile"
      },
      {
        id:6,
        name:"Add poc"
      }
    ]
  }
]

import  { useEffect, useState } from 'react';


//Images
import Pune from '../../../assets/images/Pune.png';
import Chennai from '../../../assets/images/Chennaicity.png';
import Bangalore from '../../../assets/images/Bangalore.png';
import Mumbai from '../../../assets/images/Mumbai.png';


const Cities = (props) => {

  const [cityData, setCityData] = useState([])

  const cities_data = [
    {
      id: 1,
      img: Pune,
      city: 'Pune',
      label: "pune"
    },
    {
      id: 2,
      img: Mumbai,
      city: 'Mumbai',
      label: 'mumbai'
    },
    {
      id: 3,
      img: Bangalore,
      city: 'Bangalore',
      label: "bangalore"
    },
    {
      id: 4,
      img: Chennai,
      city: 'Chennai',
      label: "chennai"
    }
  ]



  const getCityData = () => {
    let result
    result = cities_data.filter((el) => el.city === props.data.name || el.label === props.data.name)
    setCityData(result)
  }
  useEffect(() => {
    getCityData()
  }, [props])

  return (
    <>
      <div className='body-text-heading mb-0'>Operating in Cities</div>
        <div className='mt-3 city-card-container'>
          {cityData.map((item, index) => {
            return (
              <div key={index} className='city-card m-auto d-block text-center'>
                 <img className='city_image' src={item.img} alt="cityImage"/>
                <label className='mt-3 f-20 fw-500'>{item.city}</label>
              </div>
            )
          })}
        </div>
    </>
  )
}

export default Cities;
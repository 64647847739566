import Card from 'react-bootstrap/Card';
import './cardComponent.css';

const CardComponent = (props) => {

    return (
        <div>
            {
                <Card className={ 'Cards ' + props?.className }>
                    {
                        props.children
                    }
                </Card>

            }

        </div >
    )
};
export default CardComponent;


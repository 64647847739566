import io from "socket.io-client";
import { getCurrentUser } from "../../api";
// dev url
// const SOCKET_URL = "https://chatdevkeydemands.underdev.in";
// live url
// const SOCKET_URL = "https://chat.keydemand.in/";"

const SOCKET_URL = process.env.REACT_APP_CHAT_URL;
const userDetails = getCurrentUser();
const userId = userDetails ? userDetails._id : '';
const socketQuery = userId ? 'userId=' + userId : '';
export const socket = io(SOCKET_URL, { query: socketQuery });

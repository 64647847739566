import React from "react";
import { useState } from "react";
import FormModal from "../../../components/Modal";
import { Button, Form } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";

import "./clienteleBox.css";

const ClienteleBox = ({
  showModal,
  onHide,
  onChangeCheckBox,
  checked,
  setChecked,
}) => {
  return (
    <FormModal
      show={showModal}
      onClose={onHide}
      heading={"Add Clientele"}
      subText={"Select the clientele type to proceed further"}
      top={"10px"}
      closeIconPosition="absolute"
      right={"10px"}
    >
      <>
        <div className="radioform">
          <Form className="subform">
            {["radio"].map((type) => (
              <div key={`default-${type}`} className="mb-3 formradiobuttons">
                <div className="existingsubmitlabel">Choose</div>
                <Form.Check
                  type={type}
                  id={`default-${type}`}
                  label={"New Client"}
                  checked={checked === "new"}
                  onChange={() => {
                    setChecked("new");
                  }}
                />
                <div className="tooltipCustom">
                  <AiOutlineInfoCircle size={15} className="colorInfo" />
                  <span className="tooltiptextCustom"> New Client</span>
                </div>
                <Form.Check
                  type={type}
                  id={`default-${type}`}
                  label={"Existing Client"}
                  checked={checked === "existing"}
                  onChange={() => {
                    setChecked("existing");
                  }}
                />
                <div className="tooltipCustom">
                  <AiOutlineInfoCircle size={15} className="colorInfo" />
                  <span className="tooltiptextCustom">
                    Add a Client from your Keydemand inventory
                  </span>
                </div>
              </div>
            ))}
          </Form>
        </div>
        <div className="demandradiobutton">
          <Button
            variant="pre-btn-clientele"
            className="pre-btn-clientele"
            onClick={() => onHide(false)}
          >
            Cancel
          </Button>
          <Button
            variant="next-btn-clientele"
            className="next-btn-clientele"
            onClick={() => {
              onChangeCheckBox(checked);
            }}
            // loading={loading}
          >
            Submit
          </Button>
        </div>
      </>
    </FormModal>
  );
};

export default ClienteleBox;

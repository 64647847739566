export const BrandFieldArray = (cityList, companyList, regionList) => {
  return [
    {
      key: 'companyYesBrand',
      fieldArray: [
        {
          name: 'Select company*',
          key: 'companyId',
          type: 'select',
          option: companyList,
        },
        {
          name: 'Show brand name*',
          key: 'brandNameRequired',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Brand name*',
          key: 'name',
          type: 'input',
          inputType: 'text',
          placeholder: 'Enter brand name',
        },
        {
          name: 'About brand*',
          key: 'about',
          type: 'textArea',
          inputType: 'text',
          placeholder: 'Enter about brand',
        },
        {
          type: 'coverImage',
        },
        {
          type: 'logo',
        },
      ],
      fieldObject: {
        companyId: '',
        brandNameRequired: '',
        disableBrandNameStatus: true,
        cities: [],
        state: [],
        region: [],
        name: '',
        propertyTypes: '',
        about: '',
        logo: '',
        image: '',
      },
    },
    {
      key: 'companyNoBrand',
      fieldArray: [
        {
          name: 'Brand name*',
          key: 'name',
          type: 'input',
          inputType: 'text',
          placeholder: 'Enter brand name',
        },
        {
          name: 'Show brand name*',
          key: 'brandNameRequired',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'About brand*',
          key: 'about',
          type: 'textArea',
          inputType: 'text',
          placeholder: 'Enter about brand',
        },
        {
          type: 'coverImage',
        },
        {
          type: 'logo',
        },
        // {
        //     name: "Select Region *",
        //     key: "region",
        //     type: "multiSelect",
        //     option: regionList,
        //     preview: false,
        //   },
        // {
        //   name: "Operating cities *",
        //   key: "cities",
        //   type: "multiSelect",
        //   option: cityList,
        //   preview: false,
        // },
        //  {
        //   name: "Property Type*",
        //   key: "propertyType",
        //   type: "select",
        //   option: propertyTypeList,
        // },
      ],
      fieldObject: {
        brandNameRequired: '',
        cities: [],
        region: [],
        state: [],
        name: '',
        propertyTypes: '',
        about: '',
        logo: '',
        image: '',
      },
    },
  ];
};

export const BrandPlanFieldArray = (rmList) => {
  return [
    {
      key: 'companyNoBrand',
      fieldArray: [
        {
          name: 'No. of demands *',
          key: 'propertyOrDemandSelect',
          subKey: 'propertyOrDemand',
          type: 'select',
          option: ['Unlimited', 'Enter number'],
        },
        {
          name: 'No. of demands *',
          key: 'propertyOrDemand',
          subKey: 'propertyOrDemandSelect',
          type: 'input',
          inputType: 'number',
          placeholder: 'Enter no. of demands',
        },
        {
          name: 'Validity days *',
          key: 'validityDays',
          type: 'input',
          inputType: 'number',
        },
        {
          name: 'Email to suppliers *',
          key: 'emailtoSuppliers',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Contact suppliers *',
          key: 'contactSuppliersSelect',
          subKey: 'contactSuppliers',
          type: 'select',
          option: ['Unlimited', 'Enter number'],
        },
        {
          name: 'Contact suppliers *',
          key: 'contactSuppliers',
          subKey: 'contactSuppliersSelect',
          type: 'input',
          inputType: 'number',
          placeholder: 'Enter number',
        },
        {
          name: 'User dashboard *',
          key: 'userDashboard',
          type: 'input',
          inputType: 'text',
          placeholder: 'eg:Multi user dashboard',
        },
        {
          name: 'Property Alerts *',
          key: 'alert',
          type: 'select',
          option: ['Instant', 'Daily', 'Monthly', 'Weekly'],
        },
        {
          name: 'Top brand slot *',
          key: 'homePageSlotAndBanner',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Assisted uploading *',
          key: 'assistedUploading',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Featured demands *',
          key: 'featuredDemand',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Relationship manager *',
          key: 'relationshipManager',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Select RM *',
          key: 'rm',
          type: 'select',
          option: rmList,
        },
      ],
      fieldObject: {
        propertyOrDemandSelect: '',
        propertyOrDemand: '',
        validityDays: '',
        contactSuppliersSelect: '',
        contactSuppliers: '',
        emailtoSuppliers: '',
        userDashboard: 'Yes',
        alert: '',
        featuredDemand: '',
        assistedUploading: '',
        homePageSlotAndBanner: '',
        relationshipManager: '',
        rm: '',
      },
    },
    {
      key: 'companyYesBrand',
      fieldArray: [
        {
          name: 'Top brand slot *',
          key: 'homePageSlotAndBanner',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Email to suppliers *',
          key: 'emailtoSuppliers',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Featured demands *',
          key: 'featuredDemand',
          type: 'select',
          option: ['Yes', 'No'],
        },
      ],
      fieldObject: {
        emailtoSuppliers: '',
        featuredDemand: '',
        homePageSlotAndBanner: '',
      },
    },
  ];
};

export const DeveloperFieldArray = () => {
  return [
    {
      fieldArray: [
        {
          name: 'Developer name*',
          key: 'name',
          type: 'input',
          inputType: 'text',
          placeholder: 'Enter developer name',
        },

        {
          name: 'State *',
          key: 'state',
          type: 'multiSelect',
          option: [],
          preview: false,
        },
        {
          name: 'Operating cities *',
          key: 'cities',
          type: 'multiSelect',
          option: [],
          preview: false,
        },
        {
          name: 'Property Type*',
          key: 'propertyTypes',
          type: 'multiSelect',
          option: [],
          preview: false,
        },
        {
          name: 'About Developer*',
          key: 'about',
          type: 'textArea',
          inputType: 'text',
          placeholder: 'Enter about developer',
        },
        {
          type: 'coverImage',
        },
        {
          type: 'logo',
        },

        {
          name: 'Stats*',
          key: 'stats',
          type: 'input',
          inputType: 'number',
          placeholder: 'Enter Stats',
        },
      ],
      fieldObject: {
        cities: [],
        region: [],
        state: [],
        name: '',
        propertyTypes: [],
        about: '',
        logo: '',
        image: '',
        stats: '',
      },
    },
  ];
};

export const DeveloperPlanFieldArray = (rmList) => {
  return [
    {
      fieldArray: [
        {
          name: 'Property uploads *',
          key: 'propertyOrDemandSelect',
          subKey: 'propertyOrDemand',
          type: 'select',
          option: ['Unlimited', 'Enter number'],
        },
        {
          name: 'Property uploads *',
          key: 'propertyOrDemand',
          subKey: 'propertyOrDemandSelect',
          type: 'input',
          inputType: 'number',
          placeholder: 'Property upload',
        },
        {
          name: 'Validity days *',
          key: 'validityDays',
          type: 'input',
          inputType: 'number',
        },
        {
          name: 'User dashboard *',
          key: 'userDashboard',
          type: 'input',
          inputType: 'text',
          placeholder: 'eg:Multi-user dashboard',
        },
        {
          name: 'Demand Alerts *',
          key: 'demandMails',
          type: 'select',
          option: ['Instant', 'Daily', 'Monthly', 'Weekly'],
        },
        {
          name: 'Top developer slot *',
          key: 'homePageSlotAndBanner',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Submission against demand *',
          key: 'submissionAgainstDemandSelect',
          subKey: 'submissionAgainstDemand',
          type: 'select',
          option: ['Unlimited', 'Enter number'],
        },
        {
          name: 'Submission against demand *',
          key: 'submissionAgainstDemand',
          subKey: 'submissionAgainstDemandSelect',
          type: 'input',
          inputType: 'number',
          placeholder: 'Submission against demand',
        },
        {
          name: 'Featured property *',
          key: 'featuredProperty',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Assisted uploading *',
          key: 'assistedUploading',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Relationship manager *',
          key: 'relationshipManager',
          type: 'select',
          option: ['Yes', 'No'],
        },
        {
          name: 'Select RM *',
          key: 'rm',
          type: 'select',
          option: rmList,
        },
      ],
      fieldObject: {
        propertyOrDemandSelect: '',
        propertyOrDemand: '',
        validityDays: '',
        userDashboard: 'Yes',
        demandMails: '',
        homePageSlotAndBanner: '',
        assistedUploading: '',
        featuredProperty: '',
        submissionAgainstDemand: '',
        submissionAgainstDemandSelect: '',
        relationshipManager: '',
        rm: '',
      },
    },
  ];
};

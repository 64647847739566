import  {useState,useRef,useEffect} from "react";
import Button from "../../../../../components/Form/Button";
import FormModal from "../../../../../components/Modal";
import Tab from "../../../../../components/Tab/index";
import BuyerPocModal from "../pocModal/buyerPocModal";
import SellerPocModal from "../pocModal/sellerPocModal";
import BrandPOCTable from "./brandPocTable";
import RegionPOCTable from "./regionPocTable";
import { useSelector } from 'react-redux';
import { getData } from "../../../../../api";


const PocTable = ({customerDetails,companyTypeReceived,type, view,viewPage,regions}) =>{
  const brandIdUpdate = useSelector((state) => state.login.brandId);
    const [key, setKey] = useState("brandPOC");
    const [openModal, setOpenModal] = useState(false);
    const [cityList, setCityList] = useState([]);
    const [pocType, setPocType] = useState("");
    const refRegion = useRef();
    const refBrand= useRef();
    const tabDetails = [
        {
            title: type === "developer" ? "Developer POC" : "Brand POC",
            content:(<BrandPOCTable view={view} ref={refBrand} modkey={"brandPOC"} type={type} companyTypeReceived={companyTypeReceived}  brandID={brandIdUpdate} setPocType={setPocType} viewPage={viewPage}/>),
            eventKey:"brandPOC"
        },
        {
            title: type === "developer" ? "City POC" : "Region POC",
            content:(<RegionPOCTable customerDetails={customerDetails} cityList={cityList} view={view} ref={refRegion} modkey={type === "developer" ? "cityPOC" : "regionPOC"} type={type} companyTypeReceived={companyTypeReceived}  brandID={brandIdUpdate} setPocType={setPocType} viewPage={viewPage}regions={regions}/>),
            eventKey:type ==="developer"?"cityPOC":"regionPOC"
        }
    ]
 

  const getCities = () => {
    let url=`/cityZones/checkZoneAvailability/${brandIdUpdate}`
   
    getData(url).then(res =>{
      if(res.status){
        let data = res?.data
        setCityList(data.city)
 
      }
    })
  };

    const setTabKey =(k)=>{
        setKey(k);
      }
      const onOpenPocModal = () => {
        setOpenModal(true);
        setPocType("")
      };
      const handleClose = () => {
        setOpenModal(false);
      };
      useEffect(() => {
        if(type === "developer"){
          getCities();
         }
      }, [])
return(
    <div>
      {view ? null : 
   <div className="btn-container">
   <Button
        type="button"
        className="btn-color-primary d-block"
        onClick={onOpenPocModal}
        >
        {type ==="developer" && key ==="brandPOC" ?"Add Developer POC" : key ==="cityPOC" && type === "developer" ? "Add city POC":key ==="regionPOC" && type === "brand"? "Add region POC":"Add brand POC"}
    </Button>
   </div>
}
    <Tab
     tabDetails={tabDetails}
     activeKey={key}
     onSelect={(k) => setTabKey(k)}
    />
    

<FormModal
          show={openModal}
          onClose={handleClose}
          heading={"Add POC"}
          children=
            {type === "developer"?
            <SellerPocModal
            customerDetails={customerDetails}
            companyTypeReceived= {companyTypeReceived}
              modKey={key}
              type={type}
              regions={regions}
              pocType={pocType}
              id={brandIdUpdate}
              cityList={cityList}
              pocList={() => {
                if(key === "brandPOC"){
                  refBrand.current.getPocList()
                }
                else{
                  refRegion.current.getPocList()
                }
                }}
              onClose={handleClose}
            />
            :
            <BuyerPocModal
            customerDetails={customerDetails}
            companyTypeReceived= {companyTypeReceived}
              modKey={key}
              type={type}
              regions={regions}
              pocType={pocType}
              id={brandIdUpdate}
              cityList={cityList}
              pocList={() => {
                if(key === "brandPOC"){
                  refBrand.current.getPocList()
                }
                else{
                  refRegion.current.getPocList()
                }
                }}
              onClose={handleClose}
            />}
          
          size={"lg"}
        />
    </div>
)
}
export default PocTable;
import { getAllSwitchUsers, getCurrentUser, switchToUsers } from "../../../api";
import switchuser from "../../../assets/images/switchuser.png";
import CrossSign from "../../../assets/images/CrossSign.svg";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const crossUnread = async (receiverId, senderId) => {
  try {
    const url = `/chat/updateChats/${senderId}/${receiverId}`;
    const response = await fetch(`${process.env.REACT_APP_API_URL + url}`, {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      }
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getBrandColumns = (newMessages, type, getTableData) => {

  async function crossUnreaddd(receiverId, senderId){
    crossUnread(receiverId, senderId);
    await getTableData(false);
  }

  const handleAccountSwitch = async (customerId, isChatRedirect) => {
    const data = {
      customerId: customerId,
      type: "user"
    };
    switchToUsers(data).then((res) => {
      let openUrl = process.env.REACT_APP_SWITCH_URL;
      // let openUrl = `http://localhost:3001/admin-user`;

      if (res.status) {
        const { data } = res;

        openUrl = `${openUrl}/${res?.data?.token}`;
        if (data?.dashboardSwitch) {
          openUrl = `${openUrl}?fromDashoard=${data?.dashboardSwitch}`;
        }
        window.open(openUrl);
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };

  let columnsBrand = [
    {
      dataField: "publishedDate",
      text: "Timestamp",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row.latestMessageTime).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: `${type === "Enterprise Seller" ? "Developer" : "Brand"} Name`,
      dataField: "brandName",
    },
    {
      text: "POC Name",
      dataField: "receiverName",
    },
    {
      text: "Total Messages",
      dataField: "messageCount",
      formatter: (cellContent, row, rowIndex) => {
        return (
          // <div
          //   className="hyperlinkText"
          //   onClick={(e) => {
          //     e.preventDefault();
          //     handleAccountSwitch(row?.receiverId, true);
          //   }}
          // >
          //   {row.messageCount}
          // </div>
          <div>
            {row?.messageCount}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <>
            {newMessages ? (
              <div className="db-number-card-container" onClick={()=>crossUnreaddd(row.receiverId, row.senderId)}>
                <img
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  alt="cross"
                  src={CrossSign}
                />
              </div>
            ) : (
              <div className="db-number-card-container">
                {getCurrentUser()?.role[0]?.name === "admin" ||
                getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
                  <img
                    style={{ cursor: "pointer", width: "20px", height: "20px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAccountSwitch(
                        row?.companyId,
                        row?.receiverId,
                        false
                      );
                    }}
                    alt="switch"
                    src={switchuser}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];

  let newMessagesObj = [
    {
      text: "Sender POC",
      dataField: "senderName",
    },
    {
      text: "User Type",
      dataField: "userType",
    },
    {
      text: "Unread Messages",
      dataField: "messageCount",
      formatter: (cellContent, row, rowIndex) => {
        return (
          // <div
          //   className="hyperlinkText"
          //   onClick={(e) => {
          //     e.preventDefault();
          //     handleAccountSwitch(row?.receiverId, false);
          //   }}
          // >
          //   {row.messageCount}
          // </div>
          <div>
            {row?.messageCount}
          </div>
        );
      },
    },
  ];

  if (newMessages) {
    columnsBrand.splice(3, 1, ...newMessagesObj);
  }

  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rmName",
        formatter: (cellContent, row, rowIndex) => {
          return <div>{cellContent ? cellContent : "-"}</div>;
        },
      },
    ];
    columnsBrand.splice(6, 0, ...rmData);
  }

  return columnsBrand;
};

export const getAgentColumns = (newMessages, type, getTableData) => {

  async function crossUnreaddd(receiverId, senderId){
    crossUnread(receiverId, senderId);
    await getTableData(false);
  }

  const handleAccountSwitch = async (id) => {
    const data = {
      customerId: id,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = process.env.REACT_APP_SWITCH_URL;
      // let openUrl = `http://localhost:3001/admin-user`;
      if (res.status) {
        window.open(`${openUrl}/${res?.data?.token}`);
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };
  let columnsAgent = [
    {
      dataField: "publishedDate",
      text: "Timestamp",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row.latestMessageTime).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: `Agent Name`,
      dataField: "receiverName",
    },
    {
      text: "Total Messages",
      dataField: "messageCount",
      formatter: (cellContent, row, rowIndex) => {
        return (
          // <div
          //   className="hyperlinkText"
          //   onClick={(e) => {
          //     console.log("totalMessages : ", row.messageCount);
          //   }}
          // >
          <div>
            {row?.messageCount}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <>
            {newMessages ? (
              <div className="db-number-card-container" onClick={()=>crossUnreaddd(row.receiverId, row.senderId)}>
                <img
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  alt="cross"
                  src={CrossSign}
                />
              </div>
            ) : (
              <div className="db-number-card-container">
                {getCurrentUser()?.role[0]?.name === "admin" ||
                getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
                  <img
                    style={{ cursor: "pointer", width: "20px", height: "20px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAccountSwitch(row?._id);
                    }}
                    alt="switch"
                    src={switchuser}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];

  let newMessagesObj = [
    {
      text: "Sender POC",
      dataField: "senderName",
    },
    {
      text: "User Type",
      dataField: "userType",
    },
    {
      text: "Unread Messages",
      dataField: "messageCount",
      formatter: (cellContent, row, rowIndex) => {
        return (
          // <div
          //   className="hyperlinkText"
          //   onClick={(e) => {
          //     e.preventDefault();
          //     handleAccountSwitch(row?.receiver);
          //   }}
          // >
          //   {row.messageCount}
          // </div>
          <div>
            {row?.messageCount}
          </div>
        );
      },
    },
  ];

  if (newMessages) {
    columnsAgent.splice(2, 1, ...newMessagesObj);
  }

  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rmName",
        formatter: (cellContent, row, rowIndex) => {
          return <div>{cellContent ? cellContent : "-"}</div>;
        },
      },
    ];
    columnsAgent.splice(5, 0, ...rmData);
  }

  return columnsAgent;
};

import React from "react";
import { Button } from "react-bootstrap";
import "./style.scss";
const ViewOnGoogleMap = ({ cordinates }) => {
  const handleGoogleMaps = () => {
    if (cordinates.length > 0) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${cordinates[0]},${cordinates[1]}`,
        "_blank"
      );
    }
  };
  return (
    <div>
      <Button
        className="btn-outline-color-primary-google-map map_buttons"
        onClick={handleGoogleMaps}
      >
        View on Google Maps
      </Button>
    </div>
  );
};

export default ViewOnGoogleMap;

import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { getData, postData } from "../../../../api";
import { DemandCard } from "../../../../components/Cards/Demands";
import Button from "../../../../components/Form/Button";
import MultiSelect from "../../../../components/select/dropDown";
import {
  getContextFilterData,
  performScrollEvent,
} from "../../../../utilities/pageScroll";

const FeaturedDemands = (props) => {
  const navigate = useNavigate();
  const { pageScroll, setPageScroll } = useContext(AppContext);
  let companyID = useSelector((state) => state.login.companyId);
  const scrollStoreArray = [...pageScroll];
  let contextFilterData = getContextFilterData(
    "myPageDemand",
    scrollStoreArray
  );
  const [apiCardDetail, setApiCardDetail] = useState([]);
  const [cityFilterKeyValue, setCityFilterKeyValue] = useState(
    contextFilterData && props.tabKey === "featuredDemands"
      ? contextFilterData
      : { label: "City", value: "City" }
  );
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [demandId, setDemandId] = useState([]);
  const [filterMessage, setFilterMessage] = useState("");
  const fromPage = useSelector((state) => state.login.parentPage);
  useEffect(() => {
    if (apiCardDetail?.length > 0 && props.tabKey === "featuredDemands") {
      let updatedArray = performScrollEvent("myPageDemand", scrollStoreArray);
      setPageScroll(updatedArray);
    }
  }, [apiCardDetail]);



  const getDemandData = async () => {
    setFilterMessage("");
    let url = `/demand/getDemands?pageNo=1&customerId=${props.id}&limit=0&isActive=true&featuredDemand=true`;
    if (
      cityFilterKeyValue?.label !== "City" &&
      cityFilterKeyValue?.label !== ""
    ) {
      url += `&location=${cityFilterKeyValue?.label}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.status) {
        // apiData = response.data
        setApiCardDetail(response.data.demands);
        if (
          cityFilterKeyValue?.label !== "City" &&
          cityFilterKeyValue?.label !== ""
        ) {
          if (response?.data?.demands?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      }
    });
  };

  const onchangeCityFilterKey = (val) => {
    setCityFilterKeyValue(val);
  };

 

  const onClickEditDemand = (a) => {
    props.setScrollEvent(a?._id, cityFilterKeyValue);
    localStorage.setItem("demandId", JSON.stringify(a?._id));
    localStorage.setItem("propertyType", JSON.stringify(a?.propertyType));
    if (a?.propertyType?.name === "Office space") {
      localStorage.setItem("postDemandStep", JSON.stringify(3));
    } else {
      localStorage.setItem("postDemandStep", JSON.stringify(5));
    }
    let pageFrom =
      companyID && fromPage === "buyer"
        ? "reportsCompany"
        : fromPage === "buyer"
        ? "reportsCustomer"
        : "brandPage";

    localStorage.setItem("demandTabKey", JSON.stringify("featuredDemands"));
    navigate("/demand/PostDemand", {
      state: {
        brandId: a?.brandId?._id,
        action: "edit",
        brandName: props?.brandName,
        page: pageFrom,
      },
    });
  };
  const onClickViewDemand = (a) => {
    props.setScrollEvent(a?._id, cityFilterKeyValue);
    localStorage.setItem("demandid", JSON.stringify(a?._id));
    localStorage.setItem("brandid", JSON.stringify(props?.brandId));
    localStorage.setItem("demandTabKey", JSON.stringify("featuredDemands"));
    let url =
      fromPage === "buyer"
        ? "/buyerReport/brandList/brandPage/DemandDetailView/"
        : "/customer/brandPage/DemandDetailView/";
    let url2 =
      fromPage === "buyer"
        ? "/buyerReport/companyList/brandCompanyCreation/brandPage/DemandDetailView/"
        : "/company/brandCompanyCreation/brandPage/DemandDetailView/";

    navigate(
      companyID === ""
        ? `${url}${a?.productId?.display}`
        : `${url2}${a?.productId?.display}`,
      { state: { demandId: a?._id, brandId: props?.brandId } }
    );
  };

  const onClickFeaturedDemand = () => {
    setShowCheckBox(true);
  };
  const onSelectDemands = (id) => {
    let propertyCheckedArray = [...demandId];
    let includes = propertyCheckedArray.includes(id);
    if (includes) {
      let removedValue = propertyCheckedArray.filter((item) => item !== id);
      setDemandId(removedValue);
    } else {
      propertyCheckedArray.push(id);
      setDemandId([...propertyCheckedArray]);
    }
  };

  const onClickMove = async () => {
    let data = {
      demands: demandId,
      userId: props?.id,
      status: false,
    };
    await postData("/demand/addFeaturedDemands", {}, data).then((response) => {
      if (response.statusCode === 200) {
        getDemandData();
        setShowCheckBox(false);
        setDemandId([]);
      }
    });
  };
  const resetClick = () => {
    setShowCheckBox(false);
    setDemandId([]);
  };
  const btnList = [
    {
      btnName: "Edit",
      onClick: (a) => onClickEditDemand(a),
      className: "btn-color-primary",
    },
    {
      btnName: "View",
      onClick: (a) => onClickViewDemand(a),
      className: "btn-color-primary",
    },
  ];
  useEffect(() => {
    getDemandData();
    setShowCheckBox(false);
    setDemandId([]);
  }, [cityFilterKeyValue]);

  return (
    <>
      <div className="filter-container">
        <MultiSelect
          width="25%"
          onChange={(val) => {
            onchangeCityFilterKey(val);
          }}
          isMulti={false}
          options={[{ label: "City", value: "City" }, ...props?.searchCityList]}
          value={cityFilterKeyValue}
        />
      </div>
      {apiCardDetail?.length > 0 ? (
        <>
          {props?.featuredPositioningStatus &&
          props?.featuredPositioningStatus === "Yes" ? (
            <div className="d-flex mb-4 btn-container">
              {showCheckBox && demandId?.length > 0 ? (
                <div
                  className="d-flex mb-4 btn-container"
                  style={{ marginRight: "auto" }}
                >
                  <Button
                    onClick={resetClick}
                    className="btn-outline-color-primary"
                  >
                    Reset
                  </Button>
                  <Button onClick={onClickMove} className="btn-color-primary">
                    Remove
                  </Button>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-end mb-4 btn-container">
                <Button
                  onClick={onClickFeaturedDemand}
                  className="btn-color-primary"
                >
                  Remove from featured demands
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="demand-card-container">
            <DemandCard
             access={props?.access}
              demandDetail={apiCardDetail}
              response={true}
              button={btnList}
              relevant={false}
              showCheckBox={showCheckBox}
              onSelectDemands={onSelectDemands}
            />
          </div>
        </>
      ) : (
        <center>
          {filterMessage ? filterMessage : "No featured demands yet"}
        </center>
      )}
    </>
  );
};
export default FeaturedDemands;

import { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../Ckeditor/style.scss";
class EditEditors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      description: "",
    };
  }
  render() {
    return (
      <div className="App">
        <CKEditor
          editor={ClassicEditor}
          data={this.props.data.description}
          onChange={(event, editor) => {
            const data = editor.getData();
            const updateDataList = {
              description: data,
            };
            this.props.updateData(updateDataList);
          }}
          config={{
            toolbar: {
              items: [
                "bold",
                "italic",
                "underline",
                "fontColor",
                "|",
                "alignment",
                "numberedList",
                "bulletedList",
                "descreaseIndent",
                "|",
                "link",
                "Unlink",
                "|",
                "undo",
                "redo",
              ],
            },
            list: {
              properties: {
                  styles: true,
                  startIndex: true,
                  reversed: true
              }
          },
            isReadOnly: true,
            shouldNotGroupWhenFull: true,
          }}
        />
      </div>
    );
  }
}
export default EditEditors;
import React, { useEffect, useReducer, useState } from "react";
// import "./style.scss";
import { Row } from "react-bootstrap";
import Loader from "../../../../../components/Loader";
import NavBar from "../../../../../components/Navbar";
import MainTable from "../../../../../components/Table";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateAgentId,
  updateRelevantPropertyId,
} from "../../../../Login/reducer";
//api
import { postExportData, postGetData } from "../../../../../api";
import { agentConstantHeaderValue } from "../reportConstants";
import { sellerColumns } from "./agentColumns";
import { Filter } from "./agentFilter";
import {
  cityFormatter,
  companyNameFormatter,
  createdOnFormatter,
  getPayLoad,
  getURL,
  onClickCountFormater,
  onClickIdFormater,
  projectNameFormatter,
  propertyTypeFormatter,
  tableDataKey,
  userButtonFormatterPoc,
  viewbuttonFormatter,
} from "./agentFormatter";
// import { sellerFormatter } from "./sellerColumns";
import { useContext } from "react";
import { AppContext } from "../../../../../App";
import {
  getContextFilterData,
  getTableStoreValue,
  setScrollBackId,
  setTableFilterData,
} from "../../../../../utilities/pageScroll";
import AgentModal from "./agentModals";
export const AgentContext = React.createContext();

export default function AgentReportTable() {
  const page = useSelector((state) => state.login.childPage);
  const parentPage = useSelector((state) => state.login.parentPage);

  let tableName = `${page}${parentPage}`;
  const { tableStoreContext, setTableStoreContext, pageScroll, setPageScroll } =
    useContext(AppContext);
  const currentTableValueFromConText = getTableStoreValue(
    tableName,
    tableStoreContext
  );
  let contextFilterData = getContextFilterData(tableName, pageScroll);
  const storePageNo = currentTableValueFromConText?.pageNo;
  const storePerPage = currentTableValueFromConText?.perPage;
  const initialfilterValue = contextFilterData || {};

  const actions = {
    agentList: "agentList",
    companyList: "companyList",
    developerList: "developerList",
    brandList: "brandList",
    cityList: "cityList",
    regionList: "regionList",
    pocList: "pocList",
    propertyTypeList: "propertyTypeList",
    supplierList: "supplierList",
    createdByList: "createdByList",
    sortByList: "sortByList",
    search: "search",
    contactedList: "contactedList",
  };

  const filterReducer = (state, action) => {
    const { type, isMulti, value } = action || {};
    let filterValue = value;
    if (action && isMulti === "no" && value && value?.value === "select") {
      filterValue = {};
    }
    const actionType = actions[type];
    if (!actionType) {
      return state;
    }
    return { ...state, [actionType]: filterValue };
  };

  const [filterValues, filterDispatch] = useReducer(
    filterReducer,
    initialfilterValue
  );
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(storePageNo ? storePageNo : 1);
  const [perPage, setPerPage] = useState(storePerPage ? storePerPage : 10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showListCities, setShowListcities] = useState({
    show: false,
    value: [],
  });
  const [openPocModal, setOpenPocModal] = useState({
    show: false,
    pocType: "",
    brandID: "",
    rowObj: {},
    companyTypeReceived: "",
  });
  const [warningPopup, setWarningPopup] = useState({
    show: false,
    message: "",
    rowData: {},
    key: "",
  });
  const [viewCities, setViewCities] = useState({ show: false, value: {} });

  let dispatch = useDispatch();
  let navigate = useNavigate();
  //table columns buyer start

  const getIds = (array) => array.map((item) => item.value);

  const getTableData = (exportData) => {
    setIsLoading(true);
    let url = getURL(page, offset, perPage);
    let payLoadAddOn = getPayLoad(page, offset, perPage);
    let dataKey = tableDataKey(page);
    let payLoad = {
      ...(filterValues?.search && { search: filterValues?.search }),

      ...(filterValues?.agentList?.length > 0 && {
        agent: getIds(filterValues?.agentList),
      }),
      ...(filterValues?.cityList?.length > 0 && {
        city: getIds(filterValues?.cityList),
      }),
      ...(filterValues?.companyList?.length > 0 && {
        company: getIds(filterValues?.companyList),
      }),
      ...(filterValues?.brandList?.length > 0 && {
        brand: getIds(filterValues?.brandList),
      }),
      ...(filterValues?.developerList?.length > 0 && {
        developer: getIds(filterValues?.developerList),
      }),
      ...(filterValues?.sortByList?.value !== "select" &&
        filterValues?.sortByList?.value && {
          sort: filterValues?.sortByList?.value,
        }),
      ...(filterValues?.createdByList?.value !== "select" &&
        filterValues?.createdByList?.value && {
          sort: filterValues?.createdByList?.value,
        }),
      ...(filterValues?.contactedList?.value !== "select" &&
        filterValues?.contactedList?.value && {
          sort: filterValues?.contactedList?.value,
        }),
      ...(filterValues?.pocList?.length > 0 && {
        poc: getIds(filterValues?.pocList),
      }),
      ...(filterValues?.propertyTypeList?.length > 0 && {
        property: getIds(filterValues?.propertyTypeList),
      }),
      ...(filterValues?.supplierList?.length > 0 && {
        supplier: getIds(filterValues?.supplierList),
      }),
      ...(filterValues?.sortBy?.value !== "select" &&
        filterValues?.sortBy?.value && {
          sort: filterValues?.sortBy?.value,
        }),
      ...(payLoadAddOn !== "" && payLoadAddOn),
    };
    if (exportData) {
      let filterPayload = { ...payLoad, reportType: "export" };
      delete filterPayload["pageNo"];
      delete filterPayload["limit"];
      postExportData(url, {}, filterPayload).then((blob) => {
        if (blob) {
          let urls = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = urls;
          a.download = `${parentPage}-${page}.xlsx`;
          a.click();
        }
      });
      setIsLoading(false);
    } else {
      postGetData(url, {}, payLoad).then((response) => {
        if (response.status) {
          setIsLoading(false);
          setTableData(response?.data?.[dataKey]);
          setPageCount(Math.ceil(response.data?.totalDoc / perPage));
          setTotalCount(response.data?.totalDoc);
          // setTableData(response.data.companies);
        } else {
          setIsLoading(false);
          setPageCount(0);
          setTableData([]);
        }
      });
    }
  };
  const setContextValue = (id = "") => {
    let scrollArray = setScrollBackId(
      tableName,
      id,
      filterValues,
      "",
      pageScroll
    );
    setPageScroll(scrollArray);
  };
  const updateTableStoreContext = (id) => {
    let newValue = setTableFilterData(
      tableName,
      { pageNo: offset, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };

  useEffect(() => {
    if (page) {
      getTableData();
    }

    setContextValue();
  }, [filterValues, page, perPage, offset]);

  const onClickViewAgent = (row) => {
    updateTableStoreContext(row?.uniqueId);
    localStorage.removeItem("propTabKey");
    localStorage.setItem("customerId", JSON.stringify(row?.userId?._id));
    localStorage.setItem("agentId", JSON.stringify(row._id));
    dispatch(updateAgentId(row._id));
    navigate(`/customer/agentPage/${row.slug}`, {
      state: { id: row._id, view: true },
    });

    navigate(`/agentReport/agentList/agentPage/${row?.brandId?.slug}`, {
      state: {
        id: row._id,
        view: true,
        customerType: row?.subBrands
          ? "companyYesDeveloper"
          : "companyNoDeveloper",
      },
    });
  };
  const onClickDemandId = (row) => {
    updateTableStoreContext(row?.uniqueId);
    let url =
      page === "propertyPostedAgainstList"
        ? "/agentReport/propertyList/"
        : "/agentReport/propertyList/";
    navigate(`${url}DemandDetailView/${row?.demandId?.display}`, {
      state: {
        demandId: "",
        brandId: "",
        page: "reports",
      },
    });
  };

  const onClickPropertyId = (row, toPage = "") => {
    updateTableStoreContext(row?.uniqueId);

    let url =
      toPage === "property"
        ? "/agentReport/propertyList/propertyDetailView"
        : "/agentReport/propertyList/propertyDetailView";
    localStorage.setItem("propertyValues", JSON.stringify(row));
    let propertyId = {
      _id: row?.productId?.display,
    };
    navigate(`${url}/${row?.productId?.display}`, {
      state: { values: propertyId, page: "properties" },
    });
  };
  const onClickProperty = (action, brandId, row) => {
    updateTableStoreContext(row?.uniqueId);

    if (action === "view") {
      localStorage.setItem("propertyValues", JSON.stringify(row));
      let propertyId = {
        _id: row?.productId?.display,
      };
      navigate(
        `/agentReport/propertyList/propertyDetailView/${row?.productId?.display}`,
        {
          state: { values: propertyId, page: "properties" },
        }
      );
    } else {
      if (row?.isActive) {
        let url = `/property/edit?propertyId=${row?._id}&developerId=${row.developerId?._id}&developerType=${row?.customerType}&developerName=${row.developerId?.name}&fromPage=agentReports`;

        localStorage.setItem(
          "ownerShip",
          JSON.stringify(row?.propertyTypeId._id)
        );
        navigate(url);
      }
    }
  };
  const handelOpenWarning = (row, active, key) => {
    setWarningPopup({
      show: true,
      message: `Are you sure, you want to ${active ? "deactivate" : "active"} ${
        key === "poc" ? "this customer" : "this property"
      }?`,
      rowData: row,
      key,
    });
  };

  const handelOpenListPopup = (data) => {
    setShowListcities({ show: true, value: data });
  };
  const onNavigateMatchingDemand = (row) => {
    updateTableStoreContext(row?.uniqueId);
    dispatch(updateRelevantPropertyId(row?._id));
    navigate("/agentReport/propertyList/relevantDemand", {
      state: { propertyId: row?._id, page: "properties" },
    });
  };
  const onNavigatePropertyresponse = (row) => {
    navigate("/agentReport/propertyList/response", {
      state: { propertyId: row?._id, page: "properties" },
    });
  };

  const sellerFormatter = (key) => {
    const columnArray = sellerColumns?.find(
      (item) => item.pageName === key
    )?.data;
    if (!columnArray) return [];

    const getFormatter = {
      cityAction: cityFormatter(handelOpenListPopup),
      agentAction: viewbuttonFormatter(onClickViewAgent),
      acceptedAction: createdOnFormatter("acceptedOn"),
      rejectedAction: createdOnFormatter("rejectedOn"),
      propertyIdFormatter: onClickIdFormater("productId", onClickPropertyId),
      demandIdFormatter: onClickIdFormater("demandId", onClickDemandId),
      listPropertyIdFormatter: onClickIdFormater(
        "productId",
        onClickPropertyId,
        "property"
      ),
      companyNameFormatter: companyNameFormatter(),
      propertyTypeFormatter: propertyTypeFormatter(),
      projectNameFormatter: projectNameFormatter(),
      contactedAction: createdOnFormatter("contactedOn"),
      createdAtAction: createdOnFormatter(),
      propertyListAction: userButtonFormatterPoc(
        page,
        onClickProperty,
        handelOpenWarning,
        "property"
      ),
      matchingContactedFormatter: onClickCountFormater(
        "matchingDemandContacted",
        () => {}
      ),
      matchingCountFormatter: onClickCountFormater(
        "matchingDemand",
        onNavigateMatchingDemand
      ),
      reponseCoutFormatter: onClickCountFormater(
        "propertyResponse",
        onNavigatePropertyresponse
      ),
    };

    return columnArray.map((element) => ({
      ...element,
      ...(element?.formatter === true && {
        formatter: getFormatter[element.dataField],
      }),
    }));
  };
  const columnsDeveloper = sellerFormatter(page);
  //activate-deactivate

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };

  const tableProps = {
    data: tableData,
    columns: columnsDeveloper,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    uniqueId: true,
    tableName: tableName,
    setPerPage,
  };

  const onClickExportData = () => {
    getTableData(true);
  };
  const filterProps = {
    page,
    setOffset,
    onClickExportData,
  };

  return (
    <AgentContext.Provider
      value={{
        openPocModal,
        setOpenPocModal,
        warningPopup,
        setWarningPopup,
        showListCities,
        setShowListcities,
        filterDispatch,
        filterValues,
        viewCities,
        setViewCities,
      }}
    >
      <div>
        <NavBar />
        <div className="d-flex justify-content-between align-items-center text-heading">
          <label title="Dashboard">{agentConstantHeaderValue?.[page]}</label>
        </div>
        <Row>
          <Filter {...filterProps} />
        </Row>

        {columnsDeveloper?.length > 0 && (
          <div className="mt-4">
            <MainTable {...tableProps} />
            {isLoading && <Loader className="fixed" />}
          </div>
        )}
      </div>
      <AgentModal page={page} getTableData={getTableData} />
    </AgentContext.Provider>
  );
}

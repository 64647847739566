import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  RenderInput,
  RenderRangeInput,
  RenderSelect,

  RenderMultiSelect,
} from "../inputGroups";
import { getData } from "../../../api";
import Checkbox from "../../../components/Form/Checkbox";

export default function PostPropertyAmenities({
  propertyType,
  fields,
  initialValues,
  onChangeInput,
  error,
  action,
  disableEdit,
  responseStatus = false,
  demandAmenitiesDivRef
}) {
  // const navigate = useNavigate();
  const [amentities, setAmentities] = useState([]);
  const setSelectOption = (key, options) => {
    fields?.forEach((element) => {
      if (element.name === key) {
        element.option = options;
      }
    });
  };
  useEffect(() => {
    if (amentities?.length > 0) {
      setSelectOption("otherAmenities", amentities);
    }
  }, [amentities]);
  const handleAmenitey = async () => {
    let url = `/amenity?type=${propertyType?.name}`;
    const apiDatas = await getData(url, {}, {});
    const { statusCode, data } = apiDatas;
    if (statusCode === 200) {
      if (data?.length > 0) {
        let amenitieschange = data.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        setAmentities(amenitieschange);
      }
    }
  };

  useEffect(() => {
    if (propertyType) {
      handleAmenitey();
    }
    window.scrollTo(0, 0);
  }, []);

  const handelChangeInputs = (value, name) => {
    onChangeInput(value, name);
  };
  const handelChangeSelect = (value, name) => {
    onChangeInput(value, name);
  };
  const handelChangeCheckBox = (value, name) => {
    onChangeInput(value, name);
  };
  return (
    <div>
      <Container fluid="md" className=" invidual-form" ref={demandAmenitiesDivRef}>
        <Row>
          <Col md="12">
            <Row>
              {fields?.length > 0 &&
                fields.map((item, index) => {
                  const insertCol = <Col md="1"></Col>;
                  if (item.type === "input") {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="my-2">
                          <RenderInput
                            value={initialValues[item.name]}
                            name={item.name}
                            label={item.label}
                            onChange={(e) =>
                              handelChangeInputs(e.target.value, item.name)
                            }
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            type={item.inputType}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => { }
                                : (e) =>
                                  handelChangeCheckBox(
                                    e.target.checked ? true : false,
                                    item.requried
                                  )
                            }
                            checkBoxValue={initialValues[item.requried]}
                            placeholder={item.placeholder}
                            disabled={
                              action === "edit" &&
                                responseStatus &&
                                disableEdit[item.requried]
                                ? true
                                : false
                            }
                            requried={item.defaultChecked}
                            checboxDisabled={
                              action === "edit" &&
                                responseStatus &&
                                disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "rangeInput") {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-center my-2">
                          <Checkbox
                            label={" "}
                            name={item.name}
                            value={initialValues[item.requried]}
                            onChange={
                              item.defaultChecked
                                ? () => { }
                                : (e) =>
                                  handelChangeCheckBox(
                                    e.target.checked ? true : false,
                                    item.requried
                                  )
                            }
                            size="m-0"
                            disabled={
                              action === "edit" &&
                                responseStatus &&
                                disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                          <Row>
                            <label
                              style={{
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "14px",
                                color: "#000000",
                                marginBottom: "0px",
                              }}
                            >
                              {item.label}
                              {item.defaultChecked ? "*" : null}
                            </label>
                            {item.rangefields.map((range, rangeIndex) => {
                              return (
                                <>
                                  <Col
                                    md="5"
                                    className="my-2 d-flex justifu-content-center align-items-center"
                                  >
                                    <RenderRangeInput
                                      value={initialValues[range.name]}
                                      name={range.name}
                                      label={range.label}
                                      onChange={(e) =>
                                        handelChangeInputs(
                                          e.target.value,
                                          range.name
                                        )
                                      }
                                      isInvalid={!!error[range.name]}
                                      errorMessage={error[range.name]}
                                      type={range.inputType}
                                      placeholder={range.placeholder}
                                      disabled={
                                        action === "edit" &&
                                          responseStatus &&
                                          disableEdit[item.requried]
                                          ? true
                                          : false
                                      }
                                      sufix={range.Checkboxsufix}
                                      prefix={range.prefix}
                                      onFocus={(e) =>
                                        handelChangeInputs(
                                          initialValues[range.name] === 0
                                            ? ""
                                            : initialValues[range.name],
                                          range.name
                                        )
                                      }
                                      onBlur={() =>
                                        handelChangeInputs(
                                          initialValues[range.name] === ""
                                            ? 0
                                            : initialValues[range.name],
                                          range.name
                                        )
                                      }
                                    />
                                  </Col>
                                  {rangeIndex === 0 && (
                                    <Col
                                      md="auto"
                                      className="d-flex align-items-center mb-3"
                                    >
                                      -
                                    </Col>
                                  )}
                                </>
                              );
                            })}
                          </Row>
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "checkBox" && item.checkboxOnly) {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-center my-4">
                          <Checkbox
                            label={" "}
                            customLabel={item.label}
                            name={item.name}
                            value={initialValues[item.requried]}
                            onChange={
                              item.defaultChecked
                                ? () => { }
                                : (e) =>
                                  handelChangeCheckBox(
                                    e.target.checked ? true : false,
                                    item.requried
                                  )
                            }
                            size="m-0"
                            disabled={
                              action === "edit" &&
                                responseStatus &&
                                disableEdit[item.requried]
                                ? true
                                : false
                            }
                            requried={item.defaultChecked}
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "select" && item.name !== "fitOutsStatus") {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-center my-2">
                          <RenderSelect
                            value={initialValues[item.name]}
                            label={item.label}
                            requried={item.defaultChecked}
                            name={item.name}
                            onChange={(e) =>
                              handelChangeSelect(e.target.value, item.name)
                            }
                            options={item.option}
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            placeholder={item.placeholder}
                            checkBoxValue={initialValues[item.requried]}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => { }
                                : (e) =>
                                  handelChangeCheckBox(
                                    e.target.checked ? true : false,
                                    item.requried
                                  )
                            }
                            disabled={
                              action === "edit" &&
                                responseStatus &&
                                disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "select" && item.name == "fitOutsStatus" && initialValues.transactionType !== "Buy") {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-center my-2">
                          <RenderSelect
                            value={initialValues[item.name]}
                            label={item.label}
                            requried={item.defaultChecked}
                            name={item.name}
                            onChange={(e) =>
                              handelChangeSelect(e.target.value, item.name)
                            }
                            options={item.option}
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            placeholder={item.placeholder}
                            checkBoxValue={initialValues[item.requried]}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => { }
                                : (e) =>
                                  handelChangeCheckBox(
                                    e.target.checked ? true : false,
                                    item.requried
                                  )
                            }
                            disabled={
                              action === "edit" &&
                                responseStatus &&
                                disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "multiSelect") {
                    let option = item.name === "otherAmenities" ? amentities : item.option
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-center my-2">
                          <RenderMultiSelect
                            value={
                              initialValues[item.name]?.length > 0
                                ? initialValues[item.name]
                                : []
                            }
                            label={item.label}
                            requried={item.defaultChecked}
                            name={item.name}
                            onChange={(val) =>
                              handelChangeSelect(val, item.name)
                            }
                            options={option}
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            placeholder={item.placeholder}
                            checkBoxValue={initialValues[item.requried]}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => { }
                                : (e) =>
                                  handelChangeCheckBox(
                                    e.target.checked ? true : false,
                                    item.requried
                                  )
                            }
                            disabled={
                              action === "edit" &&
                                responseStatus &&
                                disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  }

                  return null;
                })}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

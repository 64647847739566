
import MainTable from "../Table/index";
import FormModal from "../Modal";
import "./style.scss";
import { MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";

const FilterTableList = ({
  data,
  onClose,
  show,
  handleRemoveValue,
  onClickViewList,
  view,
}) => {

  function userButtonFormatter(cell,row) {
    return (
      
        <div className="d-flex align-items-center justify-content-center">
         
          {view && (
            <button className="close-icon delete-icon">
              <span
                className="cursor-pointer m-1"
                onClick={() => onClickViewList(row)}
              >
                <BsFillEyeFill
                  style={{
                    color: "black",
                    fontSize: "1.3em",
                    paddingLeft: "3px",
                    cursor: "pointer",
                  }}
                />
              </span>
            </button>
            
          )}
           <button
            className="close-icon delete-icon"
            name={row.value}
            onClick={handleRemoveValue}
          >
            <MdDelete
              style={{
                color: "black",
                fontSize: "1.5em",
                paddingLeft: "3px",
                cursor: "pointer",
              }}
            />
          </button>
        </div>
    );
  }
  const column = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "label",
      text: "Value",
    },
    {
      dataField: "Action",
      text: "Action",
      formatter: userButtonFormatter,
    },
  ];

  const tableProp = {
    data: data,
    columns: column,
    offset: 1,
    setOffset: 1,
    // handelChangePerPage,
    pageCount: 0,
    perPage: 10,
    totalCount: 10,
    tableName:"",
  };
  return (
      <FormModal
        onClose={onClose}
        show={show}
        heading={"List"}
        size={"md"}
        loading={false}
        loader="fixed"
      >
        <div className="filter-multi-select-container">
          <MainTable {...tableProp} />
        </div>
      </FormModal>
  );
};

export default FilterTableList;

import  { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ChatChannel from "./chatChannel";
import { getData } from "../../api";
import Search from "../../components/Search/search";
import moment from "moment";
import "./style.scss";
import { socket } from "./socket";
import Loader from "../../components/Loader";

function Chat() {
  const [search, setSearch] = useState("");
  const [userList, SetUserList] = useState("");
  const [indexKey, setIndexKey] = useState();
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState();
  const [roomIds, setRoomIds] = useState();
  // eslint-disable-next-line
  const [newMessage, setNewMessage] = useState({});
// eslint-disable-next-line
  const [connected, setConnected] = useState(false);
  const [loading,setLoading]=useState(true);
  const [searchLoading,setSearchLoading]=useState(false)

  // var socket = io();

 

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setSearchLoading(true)
  };

  

  const getUserList = async () => {
    if(loading){
      setLoading(true)
     }
    let url ="/chat";
    if (search) {
      // setSearchLoading(true)
      url += `?search=${search}`;
    }
    const response = await getData(url, {}, {});
    const { data, statusCode } = response;
    if (statusCode === 200) {
      setLoading(false);
      setSearchLoading(false)
      SetUserList(data);
      const userRoomIds = data.map((dataEntry) => dataEntry.roomId);

      setRoomIds(userRoomIds);
      if (!userId) {
        setUserId(data[0].roomId);
        setIndexKey(data[0].roomId)
        setUserData(data[0]);
      }
    } else {
      setLoading(false)
      setSearchLoading(false)
    }
  };

  const handleUser = (index, id, item) => {
    setIndexKey(id);
    setUserId(id);
    setUserData(item);
  };

 

  const sendMessage = (message) => {
    socket.emit("sendMessage", message);
  };

  // chat connection and listion
  const setupSocketListeners = (userRoomIds) => {
    socket.emit("join", userRoomIds);
  };
  useEffect(() => {
    getUserList();
    // eslint-disable-next-line
  }, [search,newMessage]);
  useEffect(() => {
    if (roomIds && roomIds.length) {
      setupSocketListeners(roomIds);
    }
  }, [roomIds]);

  useEffect(() => {
   
    const eventHandler = () => setConnected(true);
    socket.on('receiveMessage', eventHandler);
    socket.on("receiveMessage", (newMessage) => {
      setNewMessage(newMessage);
    });
    // unsubscribe from event for preventing memory leaks
    return () => {
       socket.off('receiveMessage', eventHandler);
    };
 }, []);

  return (
    <div className="chat">
       {loading?<Loader />:
       <Row>
        <Col className="p-0 m-0" md="3">
          <div className="user-list">
            <div className="static-data">
              <p className="fw-600 f-24 mt-2 mb-3">Inbox</p>
              <div className="mb-3">
                <Search
                  placeholderText="Search People or message"
                  class="chat-search"
                  onChange={handleSearch}
                />
              </div>
            </div>
            {searchLoading?<div className="user-cards mt-3">
          <Loader spinner={true} />
          </div>:
            <div className="user-cards pt-3">
              {userList &&userList.length>0?
                userList.map((item, index) => {
                  return (
                    <div
                    key={index}
                      className={`${
                        item.roomId === indexKey
                          ? "d-flex chat-card-active cursor-pointer"
                          : "d-flex chat-card cursor-pointer"
                      }`}
                      onClick={() => {
                        handleUser(index, item.roomId, item);
                      }}
                    >
                      <div className=" d-flex flex-column w-75">
                        <span className="f-16 text-truncate text-person">{item.name}</span>
                        <span className="f-10 text-truncate text-last-msg">
                          {item.lastMessage}
                        </span>
                      </div>
                      <div className=" d-flex w-25 align-items-center justify-content-center">
                        <span className="f-10 w-75 text-break">
                          {moment(item.dateTime).format("MMM DD ")}
                        </span>
                        {item.messageCount !== 0 ? (
                          <span className="chat-count f-12 d-flex align-items-center justify-content-center text-white  w-25">
                            {item.messageCount}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  );
                }):
                <p className="text-center">No Result Found</p>}
            </div>}
            <div></div>
            {/* {isLoading && <Loader className={"fixed"} />} */}
          </div>
        </Col>
        <Col className="p-0 m-0" md="9">
          {userList&&userList.length>0?
          <ChatChannel
            userId={userId}
            userData={userData}
            updateMessage={newMessage&&newMessage.roomId===userId?newMessage:false}
            sendMessage={sendMessage}
          />:<p className="mt-5 text-center">No Chat History Found</p>
}
        </Col>
      </Row>}
    </div>
  );
}

export default Chat;

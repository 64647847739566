import  { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiUpload } from "react-icons/hi";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import "./style.scss"

export default function SingleImageUpload(props) {
  const [state, setState] = useState(props.files ? props.files : []);
  
  useEffect(() => {
    setState(props.files)
  }, [props.files])
  const remove = (id) => {
    const filterState = state.filter((item) => id !== item.id)
    setState(filterState);
    props.imageApi(filterState)
  }

 

  //this function will trigger file upload
  const upload =  ({ target: { files } }) => {
      props?.setErrorObject({...props?.errorObject , [props?.id] :""})
    let data = new FormData();
    data.append('file', files[0]);
   if(files[0].name.split('.').pop() === "JPEG" || files[0].name.split('.').pop() === "jpg" ||files[0].name.split('.').pop() === "JPG"|| files[0].name.split('.').pop() === "png" || files[0].name.split('.').pop() === "PNG" || files[0].name.split('.').pop() === "jpeg"){

    props.imageApi(files)
    setState([{ id: Math.random(), name: files[0].name }]);
   }
   else{
    swal({
      text: "Upload image with JPEG,JPG,PNG format",
      icon: "warning",
      timer: 3000,
      buttons: false,
    });
    setState([]);
    props.imageApi([])
   }
  }
  const onClickUpload = () =>{
    document.getElementById(props.id).onchange = upload;
  }    
  const fileFormat = state?.length === 1 && state[0]?.name !== "" && (state[0].name.split('.').pop() === "JPEG" || state[0].name.split('.').pop() === "jpeg" || state[0].name.split('.').pop() === "jpg"|| state[0].name.split('.').pop() === "JPG" || state[0].name.split('.').pop() === "png")

  return (
  <Form.Group className={`${props.className?props.className:"w-40"}`}>
      <div className="d-block">
      <Form.Label className="fw-700 f-14 text-black">{props.label}</Form.Label>
     
      </div>
      
      <div style={{border: props?.errorMessage ? "1px solid #dc3545": "1px solid #ced4da", backgroundColor:props?.disabled ? "#e9ecef" : "#ffff"}}  className="p-1 upload-container form-control d-flex justify-content-between align-items-center ">
        <div className="d-flex  flex-wrap justify-content-start align-items-center upload-container-wrapper">
          
          {(state?.length === 0 || state[0]?.name === "") &&
            <div className="p-2 upload-input">{props.placeholder ? props.placeholder : ""}</div>}
          {state?.length === 1 && state[0]?.name !== "" && state?.map((item, index) => {
            return (
              <>
                {/* {fileExtension === "jpg" || fileExtension === "jpeg"|| fileExtension === "JPEG" || fileExtension === "JPG" || fileExtension === "PNG" || fileExtension === "png" ? */}
                <div className="m-1 w-100" key={index}>
                  <div className="upload-list ">
                    <label className="upload-image-text" title={item.name}>{item.name}</label>
                    &nbsp;
                    <AiOutlineClose
                      style={{
                        color: "white",
                        fontSize: "em",
                        paddingLeft: "3px",
                        cursor:props?.disabled ? "" : "pointer",
                      }}
                      size={20}
                      
                      onClick={() => {
                        
                        if(!props?.disabled){
                          document.getElementById(props.id).value =""
                         remove(item.id)
                        }
                        
                      }}
                    />
                  </div>
                </div>
              
              </>
            )

          })}
        </div>
        <div className="upload-icon">
          <label htmlFor={props.id} className="cursor-point">
            <div>
         

{fileFormat ?
            
          "" :
            <>
             {/* {!props?.loading ?  */}
             <HiUpload size={20} style={{ color: "#3E4958" }} onClick={onClickUpload}/> 
             {/* // :<i className="fa fa-spin fa-spinner"></i>} */}

            </>
            }
             
            </div>
           
            <input
              disabled={props.disabled || fileFormat ?  true : false}
              name={props.name}
              id={props.id}
              type="file"
              className="d-none"
              multiple={false}
              accept="image/png, image/jpeg, image/jpg"
            />
          </label>

        </div>
        {props?.loading && <i className="fa fa-spin fa-spinner" style={{marginRight:"10px"}}></i>}
      </div>
      <p className="f-12 mb-0">
          *Upload image with JPEG,PNG,JPG format
      </p>
      <div className="invalid-feedback">
             {props?.errorMessage ? props?.errorMessage :""}
            </div>
    </Form.Group>
  );
}
import React , {useState} from "react";
import FormModal from "../../../../../components/Modal";
import BuyerPocModal from "../../../../Customer/CustomerForm/poc/pocModal/buyerPocModal";
import { BuyerContext } from "./buyerReport";
import { CustomerWarningPopup } from "../../../../Customer/CustomerTable/warningPopup";
import { putData } from "../../../../../api";
import { ListPopup } from "../../../../../components/Modal/listModal";

const BuyerModal = ({ page, getTableData }) => {
  const {
    openPocModal,
    setOpenPocModal,
    warningPopup,
    setWarningPopup,
    showListCities,
    setShowListcities,
  } = React.useContext(BuyerContext);

  // state
  const [btnLoading, setBtnLoading] = useState(false);

  // edit and view poc modal
  const handelClosePocModal = () => {
    setOpenPocModal({ show: false, pocType: "", brandID: "", rowObj: {} });
  };
  const getPocType = (value) =>
    value === "regionPocList"
      ? "regionPOC"
      : value === "companyPocList"
      ? "companyPOC"
      : "brandPOC";

  // warning modal action
  const warningYesClick = () => {
    onHandelChangeStatus();
  };
  const onHandelChangeStatus = () => {
    setBtnLoading(true);
    const { rowData } = warningPopup || {};
    const { companyId, _id, brandId } = rowData || {};
    const data = {
      isActive: !rowData?.isActive,
      type: {
        regionPocList: "regionPoc",
        companyPocList: "companyPoc",
        brandPocList: "brandPoc",
      }[page] || "brandPoc",
    };
    const url = {
      companyPocList: `/company/updatePoc/${companyId}/${_id}`,
      brandPocList: `/brand/poc/updatePoc/${brandId}/${_id}`,
      regionPocList: `/brand/poc/updatePoc/${brandId}/${_id}`,
      demandPostedList: `/demand/updateDemand/${_id}`,
      inActiveDemandList:`/demand/updateDemand/${_id}`,
      activeDemandList:`/demand/updateDemand/${_id}`,
      demandResponseList:`/demand/updateDemand/${_id}`,
    }[page];

    if (!url) return;
    if (["companyPocList","demandPostedList","activeDemandList","demandResponseList","inActiveDemandList"].includes(page)) delete data.type;
    putData(url, {}, data).then((response) => {
      if (response.status) {
        setBtnLoading(false);
        getTableData();
        handleCloseWarning();
      } else {
        setBtnLoading(false);
        handleCloseWarning();
      }
    });
  };
  const handleCloseWarning = () => {
    setWarningPopup({ show: false, message: "", rowData: {}, key: "" });
  };

  //   warning poup action ends

  // city list popup action starts
  const onCloseListModal = () => {
    setShowListcities({ show: false, value: [] });
  };
  //   city list popup action ends
  return (
    <>
      {/* edit and view poc modal */}
      {openPocModal?.show && <FormModal
        show={openPocModal?.show}
        onClose={handelClosePocModal}
        heading={
          openPocModal?.pocType === "view"
            ? "View POC"
            : openPocModal?.pocType === "edit"
            ? "Edit POC"
            : "Add POC"
        }
        children={
          <BuyerPocModal
          companyTypeReceived={
            openPocModal?.companyTypeReceived === "Yes"
              ? "companyYesBrand"
              : "companyNoBrand"
          }
          modKey={getPocType(page)}
          type={"brand"}
          pocType={openPocModal?.pocType}
          pocList={getTableData}
          id={openPocModal?.brandID}
          onClose={handelClosePocModal}
          pocEditData={openPocModal?.rowObj}
          regions={openPocModal?.regionList}
          />
        }
        size={"lg"}
      />}
      {warningPopup?.show && (
        <CustomerWarningPopup
        btnLoading={btnLoading}
          title={"Confirmation"}
          show={warningPopup?.show}
          message={warningPopup?.message}
          warningYesClick={warningYesClick}
          onClose={handleCloseWarning}
        />
      )}
      {showListCities?.show && (
        <ListPopup
          onClose={onCloseListModal}
          lists={showListCities?.value}
          show={showListCities?.show}
          title={`${showListCities?.title==="region"?"Regions":"Cities"}`}
        />
      )}
    </>
  );
};
export default BuyerModal;

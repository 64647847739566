import  { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

import { Outlet } from "react-router-dom";
//scss
import "./style.scss";
import PrivateRoute from "../privateRoute";
//pages
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LayoutNoSidebar() {

  const [showNav,setShowNav] =useState(false)

  
  return (
   
      <PrivateRoute>
        <Header handleNav={()=>setShowNav(!showNav)} handleShow={showNav}/>
        <main className="main-layout full d-block p-4" >
          {showNav && <Sidebar handleShow={showNav} /> }
          <ToastContainer 
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover/>
            <Outlet></Outlet>
        </main>
      </PrivateRoute>
   
  );
}

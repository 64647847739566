import { useEffect, useState } from "react";

import { Tab, Tabs } from "react-bootstrap";

import Button from "../../components/Form/Button/index";

//fetch
import { AccessDenied, getData } from "../../api/index";

//css
import { useSelector } from "react-redux";
import NavBar from "../../components/Navbar";
import AgentDataTable from "./agent/agentDataTable/agentDataTable";
import AgentEditTable from "./agent/agentEditTable/agentEditTable";
import "./subscription.scss";

const Subscription = () => {
  const [key, setKey] = useState("Agent");
  const [agentEditClick, setAgentEditClick] = useState(false);
  const [planData, setPlanData] = useState([]);

  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);

  const access = {
    editSubscription: loginPermission?.includes(menuId?.editSubscription)
      ? true
      : false,
  };
  const getSubscription = () => {
    let url = "/subscription";
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        if (key === "Buyer") {
          let planArray = [];
          response.data.filter((el) =>
            el._id === key ? (planArray = el.plans) : ""
          );
          setPlanData(planArray);
        } else if (key === "Seller") {
          let planArray = [];
          response.data.filter((el) =>
            el._id === key ? (planArray = el.plans) : ""
          );
          setPlanData(planArray);
        } else if (key === "Agent") {
          let planArray = [];
          response.data.filter((el) =>
            el._id === key ? (planArray = el.plans) : ""
          );
          setPlanData(planArray);
        }
      }
    });
  };

  const onClickEdit = () => {
    setAgentEditClick(true);
  };
  useEffect(() => {
    getSubscription();
  }, [key, agentEditClick]);
  return (
    <>
      {loginPermission?.includes(menuId?.subscription) ? (
        <>
          <NavBar />
          <div style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
            <div className="tab-containered">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="Agent" title="Agent">
                  {agentEditClick ? (
                    <AgentEditTable
                      editState={setAgentEditClick}
                      page={"Agent"}
                      data={planData}
                    />
                  ) : (
                    <>
                      <AgentDataTable page={"Agent"} data={planData} />
                      <div className="btn-container p-5">
                        <Button
                          onClick={(e) => onClickEdit(e, "Agent")}
                          className={"btn-color-primary"}
                          disabled={access?.editSubscription ? false : true}
                        >
                          Edit
                        </Button>
                      </div>
                    </>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default Subscription;

import { useEffect, useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { AccessDenied, getData } from "../../api";
import Button from "../../components/Form/Button";
import Loader from "../../components/Loader";
import FormModal from "../../components/Modal";
import MainTable from "../../components/Table";
import CreateNotification from "./createNotification";

export default function Notification() {
  const [notification, setNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [label, setLable] = useState("Create Notification");

  const [notificationList, setNotificationList] = useState([]);
  const [notificationId, setNotificationId] = useState("");
  const [notificationData, setNotificationData] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const [offset, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [action, setAction] = useState("Add");
  const [isOpen, setIsopen] = useState(false);
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    viewNotifications: loginPermission?.includes(menuId?.viewNotifications)
      ? true
      : false,
    createNotifications: loginPermission?.includes(menuId?.createNotifications)
      ? true
      : false,
  };
 

  const getnotificationList = () => {
    setIsLoading(true);
    let url = `/notification/getNotifications?title=true&pageNo=${offset}&limit=10`;

    getData(url).then((response) => {
      if (response.status) {
        setIsLoading(false);

        setPageCount(Math.ceil(response.data.totalCount / perPage));
        setTotalCount(response?.data?.totalCount);
        setNotificationList(response?.data?.notifications);
      } else {
        setIsLoading(false);
        setPageCount(0);
        setNotificationList([]);
      }
    });
  };

  useEffect(() => {
    getnotificationList();
  }, [notification]);

  const handelClose = () => {
    setNotificationData({});
    setNotification(true);
    setOpenViewModal(false);
    setIsopen(false);
    getnotificationList();
  };
  const handelOpen = () => {
    setIsopen(true);
    setAction("Add");
    setOpenViewModal(true);
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };

  const onClickView = (id) => {
    setAction("view");
    setIsopen(true);
    let url = `/notification/getNotification/${id}`;
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        setOpenViewModal(true);
        setNotificationData(response.data);
        setNotificationId(id);
      }
    });
  };

  function btnFormatter(cell, row) {
    return (
      <Button
        variant="link"
        dataid={cell}
        className="table-btn"
        disabled={access.viewNotifications ? false : true}
        onClick={() => {
          onClickView(row?._id);
        }}
      >
        <BsFillEyeFill />
        {/* <FontAwesomeIcon icon={faEye} /> */}
      </Button>
    );
  }

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "title", text: "Notification Title" },
    {
      dataField: "createdAt",
      text: "Notification Created On",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
    },

    { dataField: "id", text: "Action", formatter: btnFormatter },
  ];

  const tableProps = {
    data: notificationList,
    columns,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };

  return (
    <>
      {loginPermission?.includes(menuId?.notification) ? (
        <>
          <div className="dashboard-container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center text-heading">
                <label title="Dashboard">Notification</label>
              </div>

              <div className="d-flex mb-4 flexEnd_div">
                <Button
                  className="btn-color-primary"
                  onClick={handelOpen}
                  disabled={access.createNotifications ? false : true}
                >
                  Create Notification
                </Button>
              </div>
            </div>

            <div className="mt-4 bg-light">
              <MainTable {...tableProps} />
            </div>
          </div>

          {isOpen && (
            <>
              <FormModal
                onClose={handelClose}
                show={isOpen}
                heading={`${label}  `}
                size={"lg"}
                loading={false}
                loader="fixed"
              >
                {openViewModal && (
                  <CreateNotification
                    action={action}
                    onClose={handelClose}
                    notificationId={notificationId}
                    notificationData={notificationData}
                  />
                )}
              </FormModal>
            </>
          )}
          {isLoading && <Loader className={"fixed"} />}
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
}

import { useEffect, useState } from "react";

//library
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//fetch
import { getCurrentUser, getData, putData } from "../../../api/index";
//icons
import { BsFillEyeFill } from "react-icons/bs";

//components
import { useContext } from "react";
import { AppContext } from "../../../App";
import Button from "../../../components/Form/Button";
import Loader from "../../../components/Loader";
import { ListPopup } from "../../../components/Modal/listModal";
import MainTable from "../../../components/Table/index";
import { isObjectHasValue } from "../../../utilities/commonFunction";
import {
  getTableStoreValue,
  setScrollBackId,
  setTableFilterData,
} from "../../../utilities/pageScroll";
import { updateBrandId } from "../../Login/reducer";
import { CustomerWarningPopup } from "./warningPopup";

const SellerTable = (props) => {
  const dispatch = useDispatch();

  let tableName = "customerSeller";
  const { tableStoreContext, setTableStoreContext, pageScroll, setPageScroll } =
    useContext(AppContext);
  let scrollStoreArray = [...pageScroll];
  const currentTableValueFromConText = getTableStoreValue(
    tableName,
    tableStoreContext
  );
  const storetableName = currentTableValueFromConText?.tableName;
  const storePerPage = currentTableValueFromConText?.perPage;
  let checkForTableName = tableName === storetableName;
  let navigate = useNavigate();
  const [initLoading, setInitLoading] = useState(false);

  //list
  const [listData, setListData] = useState([]);
  //pagination
  const [perPage, setPerPage] = useState(
    checkForTableName ? (storePerPage ? storePerPage : 10) : 10
  );
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  //active
  const [activeStatus, setActiveStatus] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);
  const [showListPopup, setShowListPopup] = useState(false);
  const [cityList, setcityList] = useState([]);

  let search = props.search ? props.search : "";

  const setContextValue = (id) => {
    let scrollArray = setScrollBackId(
      tableName,
      id,
      props?.filter,
      search,
      scrollStoreArray
    );
    setPageScroll(scrollArray);
  };
  const updateTableStoreContext = (id) => {
    let newValue = setTableFilterData(
      tableName,
      { pageNo: props?.offSet, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };
  const getCustomerList = () => {
    setInitLoading(true);
    let url = `/brand/?pageNo=${
      props.offSet ? props.offSet : 1
    }&limit=${perPage}&type=${"Developer"}&userId=${getCurrentUser()._id}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (props?.filter?.active === "Active") {
      url += `&isActive=${true}`;
    }
    if (props?.filter?.active === "Inactive") {
      url += `&isActive=${false}`;
    }
    if (
      props?.filter?.city?.label !== "City" &&
      props?.filter?.city?.label !== ""
    ) {
      url += `&cities=${props?.filter?.city?.label}&id=${props?.filter?.city?.value}`;
    }
    getData(url).then((response) => {
      if (response.status) {
        setInitLoading(false);
        setPageCount(Math.ceil(response.data.totalCount / perPage));
        setTotalCount(response.data.totalCount);
        setListData(response.data.customerList);
        localStorage.removeItem("customerData");
      } else {
        setInitLoading(false);
        setPageCount(0);
        setListData([]);
      }
    });
  };

  const handelOpenListPopup = (cityData) => {
    setcityList(cityData);
    setShowListPopup(true);
  };

  const warningYesClick = () => {
    let status = {
      isActive: activeStatus ? false : true,
    };
    let url = `/brand/${customerId}`;
    putData(url, {}, status).then((response) => {
      if (response.status) {
        getCustomerList();
        setWarningPopup(false);
      } else {
        setWarningPopup(false);
      }
    });
  };

  const handleWarningClose = () => {
    setWarningPopup(false);
  };
  const onActiveStatusChange = (id, active) => {
    setActiveStatus(active);
    setCustomerId(id);
    setWarningPopup(true);
    if (active === true) {
      setWarningMessage("Are you sure, you want to deactivate this customer");
    } else if (active === false) {
      setWarningMessage("Are you sure, you want to activate this customer");
    }
  };

  function userButtonFormatterSeller(cell, row, rowIndex) {
    return (
      <div className="d-flex justify-content-center align-items-center ">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          onClick={(e) => {
            localStorage.removeItem("propTabKey");
            dispatch(updateBrandId(row?._id));
            localStorage.setItem("customerId", JSON.stringify(row._id));
            navigate(`/customer/developerPage/${row.slug}`, {
              state: {
                id: row._id,
                view: true,
                customerType: row?.subBrands
                  ? "companyYesDeveloper"
                  : "companyNoDeveloper",
              },
            });
          }}
          disabled={props?.access?.viewSellerCustomers ? false : true}
          className={"table-btn p-0"}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Check
          type="switch"
          id={`custom-switch_${rowIndex}}`}
          checked={row.isActive ? true : false}
          onChange={(e) => onActiveStatusChange(row._id, row.isActive)}
          disabled={props.access.sellerEnableDisable ? false : true}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  }

  const handelChangePerPage = (count) => {
    setPerPage(count);
    props?.setOffset(1);
  };
  const columnsBuyer = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "companyId",
      text: "Company Name",
      formatter: (cellContent, row, rowIndex) => {
        if (row.companyId === null || !("companyId" in row)) {
          return "-";
        } else if (row.companyId && row.companyId.companyName) {
          return row.companyId.companyName;
        }
      },
    },
    {
      dataField: "name",
      text: "Developer Name",
    },

    {
      dataField: "city",
      text: "City",
      formatter: (cellContent, row, rowIndex) => {
        if (row.cities === null || row?.cities?.length === 0) {
          return "-";
        } else if (row?.cities?.length > 0) {
          let cityArray = [];

          row?.cities?.map((val, index) => {
            cityArray.push(val?.name);
            return val;
          });
          return (
            <>
              {cityArray?.length > 1 ? (
                <span>
                  {`${cityArray[0]},..`}
                  <span
                    className="view-all-btn cursor-pointer"
                    onClick={() => handelOpenListPopup(cityArray)}
                  >
                    (View all)
                  </span>
                </span>
              ) : (
                <span>{cityArray.toString()}</span>
              )}
            </>
          );
        }
      },
    },
    {
      dataField: "count",
      text: "Property uploads",
    },

    {
      dataField: "rm",
      text: "Relationship Manager",
      formatter: (cellContent, row, rowIndex) => {
        if (
          ((row?.rm === null || row?.rm === "" || !("rm" in row)) &&
            (row?.subscriptionPlanId?.rm === null ||
              row?.subscriptionPlanId?.rm === "")) ||
          row?.subscriptionPlanId?.relationshipManager === "No"
        ) {
          return "-";
        } else {
          if ("rm" in row?.subscriptionPlanId) {
            return row?.subscriptionPlanId?.rm?.name;
          } else {
            return row?.rm?.name;
          }
        }
      },
    },
    {
      dataField: "companyId",
      text: "Subscription Valid Till",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row?.companyId?.subscriptionPlanId?.validDate === null ||
          row?.companyId?.subscriptionPlanId?.validDate === undefined ||
          row?.companyId?.subscriptionPlanId?.validDate === "" ||
          !("companyId" in row)
        ) {
          return "-";
        } else if (row?.companyId) {
          return new Date(
            row?.companyId?.subscriptionPlanId?.validDate
          ).toLocaleDateString();
        }
      },
    },
    {
      dataField: "createdAt",
      text: "Created on",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterSeller,
    },
  ];
  //
  const tableProps = {
    data: listData,
    columns: columnsBuyer,
    offset: props?.offSet,
    setOffset: props?.setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "customerSeller",
    setPerPage,
  };

  const onCloseCityListPopup = () => {
    setcityList([]);
    setShowListPopup(false);
  };
  useEffect(() => {
    getCustomerList();
    if (search || isObjectHasValue(props?.filter)) {
      setContextValue();
    }
  }, [perPage, props?.offSet, search, props?.filter]);
  return (
    <div>
      {warningPopup && (
        <CustomerWarningPopup
          show={warningPopup}
          title={"Confirmation"}
          message={warningMessage}
          warningYesClick={warningYesClick}
          onClose={handleWarningClose}
        />
      )}
      {initLoading && <Loader className={"fixed"} />}
      <MainTable {...tableProps} />
      {showListPopup && (
        <ListPopup
          onClose={onCloseCityListPopup}
          lists={cityList}
          show={showListPopup}
          title="Cities"
        />
      )}
    </div>
  );
};
export default SellerTable;

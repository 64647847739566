
//check for access

export const checkForAccess =(permissions,menuId)=>{
  if(Array.isArray(permissions)){
    return permissions.includes(menuId)? true : false;
  }
  return false
}
 

export const debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, delay);
  };
};

export const labelToName = (dataArray) =>{
  let array = []
 dataArray?.map(data => {
    array.push({
      name:data?.label,
      _id:data?.value
    })
    return data
})
return array;
}
export const nameToLabel = (dataArray) =>{
  let array = [];
  dataArray?.map(val => {
    array.push({
      label: val?.name,
      value: val?._id,
    });
    return val
  });
  return array;
}

export const truncateString = (str, num) =>
str?.length > num ? str.slice(0, num) + '...' : str;

// To check if a value is a thruthy value
export const isTruthy = (value)=>!!value;
// To check if a value is a thruthy value
export const isFalsy = (value)=>!value;
export function isObjectHasValue(obj) {
  let keyValue =[]
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      keyValue.push(key);
    }
  }

  return keyValue?.length>0?true:false;
}

import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaCopy, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";

//components
import Button from "../../components/Form/Button";
import Loader from "../../components/Loader/index";
import MainTable from "../../components/Table/index";
//fetch
import { AccessDenied, getData, postData, putData } from "../../api/index";
import Input from "../../components/Form/Input";
import FormModal from "../../components/Modal";
import "./style.scss";

export default function Coupon() {
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [addDiscount, setAddDiscount] = useState({ discount: '', couponCode: '' });
  const [validated, setValidated] = useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [id, setId] = useState('');
  const [warningMessage, setWarningMessage] = useState(false);

  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    add: loginPermission?.includes(menuId?.addCoupon) ? true : false,
    remove: loginPermission?.includes(menuId?.removeCoupon) ? true : false,
  };



  const handleChanges = (e) => {
    const { name, value } = e.target;
    if (Object.keys(errorMessage).length > 0) {
      let error = { ...errorMessage };
      delete error[name];
      setErrorMessage(error);
    }
    const datas = { ...addDiscount };
    datas[name] = value;
    if (datas.discount.length > 3) return false;
    setAddDiscount(datas);
  };

  const CustomValidator = () => {
    let error = {};
    let message = "This field is required";
    let datas = [
      "discount",
      "couponCode",
    ];
    datas.forEach((item) => { // eslint-disable-next-line react-hooks/exhaustive-deps
      if (!(item in addDiscount)) {
        error[item] = message;
      } else {
        if (addDiscount[item]?.length === 0) {
          error[item] = message;
        }
        else {
          delete error[item];
        }
      }
    });
    if (addDiscount?.discount > 100) {
      error["discount"] = "Discount must be less than 100%"
    }

    return error;
  };
  const getCouponList = async () => {
    setIsLoading(true);
    let url = `/discount?pageNo=${offset}&limit=${perPage}`;
    // if (search) {
    //   url += `&search=${search}`;
    // }
    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setIsLoading(false);
      setDiscountList(getList.data.discountData)
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
    } else {
      setPageCount(0);
      setIsLoading(false);
    }
  };
  const submitDiscountCoupon = async () => {
    let url ="/discount";
    setIsLoading(true);
    const data = await postData(url, {}, addDiscount);
    const { statusCode } = data;
    if (statusCode === 200) {
      setIsLoading(false);
      setAddDiscount({})
      getCouponList();
      setErrorMessage({});
    } else if (statusCode === 400 || statusCode === 422) {
      getCouponList();
    } else {
      setIsLoading(false);
    }
  }
  const closeModal = () => {
    setIsOpen(false);
    setErrorMessage({});
    setAddDiscount();
    setValidated(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
   
    let err = CustomValidator();
    if (err === undefined || Object.keys(err).length === 0) {
      if (form.checkValidity() === true) {
        submitDiscountCoupon();
        closeModal();
        setValidated(true);
      }
    } else {
      setErrorMessage(err);
    }
  };

 

  const addModal = () => {
    return (
      <div>

        <div className="mt-1">
          <div className="select-head">
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Input
                errorMessage={errorMessage?.discount && errorMessage?.discount}
                label={"Discount Percentage*"}
                type={"number"}
                name={"discount"}
                placeholder={"Type Discount Percentage"}
                onChange={handleChanges}
                value={addDiscount?.discount}
                isInvalid={errorMessage?.discount && true}
              />

              <Input
                errorMessage={errorMessage?.couponCode && errorMessage?.couponCode}
                label={"Coupon Code *"}
                type={"text"}
                name={"couponCode"}
                placeholder={"Enter Coupon Code"}
                onChange={handleChanges}
                value={addDiscount?.couponCode}
                isInvalid={errorMessage?.couponCode && true}
              />


              <div className="d-flex justify-content-end  gap-3 ">
                <Button
                 type={"button"}
                  className={"btn-outline-color-primary"}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type={"submit"} className="btn-color-primary">
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>

        {isLoading && <Loader className={"fixed"} />}
      </div>
    )
  }
 



  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  function userButtonFormatter(cell, row, rowIndex) {
    return (
     
        <div className="d-flex justify-content-center">

          <Button
            className="table-btn p-0"
          >
            <FaCopy
              className="icon-pointer cursor-pointer"
              onClick={() => { navigator.clipboard.writeText(row.couponCode) }}
            //   onClick={access.edit ? () => handelAddAdmin("Edit", row) : null}
            />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="table-btn p-0"
            disabled={access.remove? false : true}
          >
            <FaTrash
              className="icon-pointer cursor-pointer"
              onClick={() => handleDlt(row)}
            //   onClick={access.edit ? () => handelAddAdmin("Edit", row) : null}
            />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </div>
     
    );
  }

  const columns = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "couponCode",
      text: "Coupon Code",
    },
    {
      dataField: "discount",
      text: "Discount Rate",
    },
    {
      dataField: "isActive",
      text: "Action",
      formatter: userButtonFormatter,
    },
  ];

  const handleDlt = (row) => {
    setId(row._id)
    setWarningMessage(true);

  }
  const dltCoupon = async () => {
    let url = `/discount/${id}`;
    let payload = {
      "isActive": false
    }
    setIsLoading(true);
    const apiDatas = await putData(url, {}, payload);
    const { statusCode } = apiDatas;
    if (statusCode === 200) {
      getCouponList()
    }
    setWarningMessage(false)
  }
  const onClose = () => {
    setWarningMessage(false)
  }
  const handleModal = () => {
    setIsOpen(true);
  }
  const dltContent = () => {
    return (
      <>
        <p>Are you sure want to delete ?</p>
        <div className='btn-container'>
          <Button
            className={"btn-outline-color-primary"}
            onClick={onClose}>
            No
          </Button>
          <Button
            onClick={dltCoupon}
            className="btn-color-primary"
          >
            Yes
          </Button>
        </div>
      </>)
  }




  const tableProps = {
    data: discountList,
    columns,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };
  useEffect(() => {
    getCouponList(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, offset]);

  return (
   
      <div>
        {loginPermission?.includes(menuId?.coupon)?
        <div className="dashboard-container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center text-heading">
              <label title="Dashboard">Discount Coupons</label>
            </div>

            <div className="d-flex mb-4 flexEnd_div">

              <Button
                className="btn-color-primary"
                onClick={handleModal}
                disabled={access.add ? false : true}
              >
                Add Coupon
              </Button>
            </div>
          </div>

          <div className="mt-4">
            <MainTable {...tableProps} />
          </div>
        </div>:<AccessDenied/>}
        {isOpen &&
          <FormModal
            show={isOpen}
            onClose={closeModal}
            heading={"Add Discount Coupons"}
            children={addModal()}
            size={"md"}
          />}
        <FormModal
          show={warningMessage}
          onClose={onClose}
          heading={"Warning"}
          children={dltContent()}
          size={"md"}
        />
        {isLoading && <Loader className={"fixed"} />}
      </div>

    
  );
}

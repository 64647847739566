import React, { useState } from "react";
import { Button } from "react-bootstrap";
import RefreshIcon from "../../assets/images/Refresh1.png";

const RefreshButton = ({loading, onClick}) => {
  return (
    <Button
      variant="outline"
      onClick={onClick}
      className={"custom_ref_btn"}
    >
      <span>Refresh</span>
      {!loading && <img src={RefreshIcon} alt="Refresh" />}
      {loading && <i className="fa fa-spin fa-spinner"></i>}
    </Button>
  );
};

export default RefreshButton;

import FormModal from "../../components/Modal/index";
import Button from "../../components/Form/Button";

export function WarningMessage({
  onClose,
  show,

  subText,
}) {
  return (
    <div>
      <FormModal
        show={show}
        heading={"Tip"}
        size={"sm"}
        loading={false}
        loader="fixed"
        subText={subText}
        onClose={onClose}
      >
        <p className="f-14 my-2">
          Keeping fewer mandatory fields will get you more property submissions
        </p>
        <Button type="submit" className="submit-btn w-100" onClick={onClose}>
          Continue anyway
        </Button>
      </FormModal>
    </div>
  );
}
export function InfoMessage({
  onClose,
  show,
  subText,
}) {
  return (
    <div>
      <FormModal
        show={show}
        heading={"Tip"}
        size={"sm"}
        loading={false}
        loader="fixed"
        subText={subText}
        onClose={onClose}
      >
        <p className="f-14 my-2">
          Please tick boxes that you want to keep as mandatory.(Note: The more
          boxes you tick, fewer will be the responses)
        </p>
        {/* <Button type="submit" className="submit-btn w-100" onClick={onClose}>Close</Button> */}
      </FormModal>
    </div>
  );
}

import  { useState, useEffect } from "react";
import ResponseProperty from "./responseProperties"
import { getData } from "../../api";
import "./style.scss";
import { DemandDetailCard } from "../../components/Cards/Demands/demandDetailCard";


const DemandResponse = () => {
  const[demand,setDemand]=useState("6433e89393664ef4f27152a5");
  const demandId = JSON.parse(localStorage?.getItem("demandId")) ? 
  JSON.parse(localStorage?.getItem("demandId")) 
  : JSON.parse(localStorage?.getItem("demandId"))

 
  const getDemandData = async () => {
   
    let url = `/demand/getDemand/${demandId ? demandId : 
      JSON.parse(localStorage?.getItem("demandId"))}`;
    const response = await getData(url, {}, {});
    const { statusCode, data } = response;
    if (statusCode === 200) {
      
      setDemand(data);
     
    } else {
      setDemand({});
     
    }
  };
  useEffect(()=>{
    getDemandData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
      <div >
        <div className="demand-response">
        <div className="demand-detail card-container p-2">
          <DemandDetailCard
            demand={demand}
          />
        </div>
        </div>
        <ResponseProperty demandId={demandId} />
      </div>
  );
};
export default DemandResponse;
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { getData } from "../../../../api";
import { DemandCard } from "../../../../components/Cards/Demands";
import MultiSelect from "../../../../components/select/dropDown";
import { performScrollEvent } from "../../../../utilities/pageScroll";
const CompletedDemands = (props) => {
  const navigate = useNavigate();
  const {pageScroll,setPageScroll} = useContext(AppContext)
  const [apiCardDetail, setApiCardDetail] = useState([]);
  const [cityFilterKeyValue, setCityFilterKeyValue] = useState({
    label: "City",
    value: "City",
  });
  const [filterMessage, setFilterMessage] = useState("");
  let companyID = useSelector((state) => state.login.companyId);
  const fromPage = useSelector((state) => state.login.parentPage);
  const scrollStoreArray = [...pageScroll]
  useEffect(() => {
    if (apiCardDetail?.length > 0 && props.tabKey === "completedDemands") {
     let updatedArray = performScrollEvent("myPageDemand", scrollStoreArray);
     setPageScroll(updatedArray);
    }
  }, [apiCardDetail]);
  const getDemandData = async () => {
    setFilterMessage("");
    let url = `/demand/getDemands?pageNo=1&customerId=${props.id}&limit=0&isActive=false`;
    if (
      cityFilterKeyValue?.label !== "City" &&
      cityFilterKeyValue?.label !== ""
    ) {
      url += `&location=${cityFilterKeyValue?.label}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.status) {
        // apiData = response.data
        setApiCardDetail(response.data.demands);
        if (
          cityFilterKeyValue?.label !== "City" &&
          cityFilterKeyValue?.label !== ""
        ) {
          if (response?.data?.demands?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      }
    });
  };
  useEffect(() => {
    getDemandData();
  }, []);

  useEffect(() => {
    getDemandData();
  }, [props, cityFilterKeyValue]);



  const onchangeCityFilterKey = (val) => {
    setCityFilterKeyValue(val);
  };

  
 
  const onClickViewDemand = (a) => {
    props.setScrollEvent(a?._id)
    localStorage.setItem("demandid", JSON.stringify(a?._id));
    localStorage.setItem("brandid", JSON.stringify(props?.brandId));
    localStorage.setItem('demandTabKey', JSON.stringify("completedDemands"))
    let url=fromPage==="buyer"?"/buyerReport/brandList/brandPage/DemandDetailView/":"/customer/brandPage/DemandDetailView/";
    let url2=fromPage==="buyer"?"/buyerReport/companyList/brandCompanyCreation/brandPage/DemandDetailView/":"/company/brandCompanyCreation/brandPage/DemandDetailView/";

    navigate(
      companyID === ""
        ? `${url}${a?.productId?.display}`
        : `${url2}${a?.productId?.display}`,
      { state: { demandId: a?._id, brandId: props?.brandId } }
    );
   
  };
  const btnList = [
    {
      btnName: "View",
      onClick: (demand, userId, productId) =>
        onClickViewDemand(demand, userId, productId),
      className: "btn-color-primary",
    },
  ];

  return (
    <>
      <div className="filter-container">
        <MultiSelect
          width="25%"
          onChange={(val) => {
            onchangeCityFilterKey(val);
          }}
          isMulti={false}
          options={[{ label: "City", value: "City" }, ...props?.searchCityList]}
          value={cityFilterKeyValue}
        />
      </div>
      {apiCardDetail.length > 0 ? (
          <div className="demand-card-container">
            <DemandCard
             access={props?.access}
              demandDetail={apiCardDetail}
              response={true}
              button={btnList}
              relevant={false}
            />
          </div>
      ) : (
        <center>
          {filterMessage ? filterMessage : "No inactive demands yet"}
        </center>
      )}
    </>
  );
};
export default CompletedDemands;

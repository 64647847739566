import { Form } from "react-bootstrap";
function Select(props) {
  const handleSelect = (e) => {
    let getId;
    props.data.find((el) =>
      el.name === e.target.value
        ? (getId = el._id ? el._id : el.id ? el.id : el.value)
        : null
    );

    let val = {
      name: e.target.value,
      _id: getId,
      actionName: e?.target?.name,
    };
    props.onChange(val);
  };

  const options = props?.data?.map((item, index) => (
    <option
      value={
        props?.ispropertytype === "propertyType"
          ? item?._id
            ? item?._id
            : item.name
            ? item.name
            : item.value
          : item.name
          ? item.name
          : item.value
      }
      key={index}
    >
      {item.name ? item.name : item.label}{" "}
    </option>
  ));
  return (
    <Form.Group
      className={props.filter ? "w-100" : "mb-3"}
      controlId="formBasicEmail"
      style={{ width: props.width ? props.width : "100%" }}
    >
      {/* {props?.label && (
        <Form.Label {...props}>
          <span className="input-lable"> {props?.label}</span>
        </Form.Label>
      )} */}
      {props?.label && (
        <div className="d-grid">
          <Form.Label {...props}>
            <span className="f-14 fw-700"> {props?.label}</span>
          </Form.Label>
          {props?.addText ? (
            <label id={props?.addText} className="fw-400 f-14 text-black mb-1">
              {props?.addText}
            </label>
          ) : (
            ""
          )}
        </div>
      )}
      <Form.Select
        placeholder={props.placeholder ? props.placeholder : ""}
        required
        isValid={props.errormessage ? false : ""}
        isInvalid={props.isInvalid}
        aria-label="Default select example"
        className={
          props.errormessage
            ? ""
            : props.filter
            ? "filterBGColor select-client my-1"
            : "select-client my-1"
        }
        style={{ borderColor: props.errormessage ? "#dc3545" : "#ced4da" }}
        disabled={props.disabled ? true : false}
        onChange={handleSelect}
        // value={props.booldataview?.toString() === 'true' ? props?.value === true ? "Yes" : "No" : props?.value}
        value={props?.value}
        name={props?.name}
        onBlur={props?.onBlur}
        // defaultValue={props.defaultValue ? props.defaultValue : ""}
      >
        {props.options && <option value="">{props.options}</option>}
        {options}
      </Form.Select>
    
      <span className="error-msg">&nbsp;&nbsp;&nbsp;{props.errormessage}</span>
     
    </Form.Group>
  );
}
export default Select;

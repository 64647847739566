import  { useState,useEffect } from "react";
import Button from "../../Form/Button";
import { AiOutlineClose } from "react-icons/ai";
import { HiUpload } from "react-icons/hi";
import { Form } from "react-bootstrap";
import "./style.scss";
import Select from "../../Form/Select";

import MultiSelect from "../../select/dropDown";
import { nameToLabel } from "../../../utilities/commonFunction";

const ImportCsv = ({
  label,
  onClickDownloadTemplate,
  onClickImport,
  loading,
  setErrorObj,
  customerCityData,
  errorObj,
  pocList,
  type,
  propertyType,
  setPropertyType,
  propertyTypeList,
  getPocCityList,
  setFileUoloaded
}) => {
  let XLSX = require("xlsx");

  const cityTempData = customerCityData;
  const [selectedFileName, setSelectedFileName] = useState({
    propertyType: "",
    city: "",
    // location:"",
    fileSelected: "",
    ...(type !== "agent" && { userId: "" }),
  });
  const [jsonArray, setJsonArray] = useState([]);

  

  const handleImport = ({ target: { files } }) => {
    console.log({files})
    if (Object.keys(errorObj).length > 0 && errorObj["fileSelected"] !== "") {
      setErrorObj({ ...errorObj, fileSelected: "" });
    }
    selectedFileName.fileSelected = "";
    let selectedFile;
    selectedFile = files[0];
    setFileUoloaded(selectedFile)
    setSelectedFileName({ ...selectedFileName, fileSelected: files[0].name });
    if (selectedFile) {
      let fileReader = new FileReader();
      fileReader.onload = function (event) {
        let data = event.target.result;

        let workbook = XLSX.read(data, {
          type: "binary",
        });
        workbook.SheetNames.forEach((sheet) => {
          let rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          let jsonObject = JSON.stringify(rowObject);
          setJsonArray(jsonObject);
        });
      };
      fileReader.readAsBinaryString(selectedFile);
    }
  };
  const onClickUpload = () => {
    document.getElementById("fileUpload").onchange = handleImport;
  };

  const remove = () => {
    selectedFileName.fileSelected = "";
    document.getElementById("fileUpload").value = "";
    setErrorObj({ ...errorObj, fileSelected: "" });
  };

  const onSelectChange = (value, key) => {
    if (Object.keys(errorObj).length > 0) {
      errorObj[key] = "";
      setErrorObj({ ...errorObj });
    }
    
    if (key === "userId") {
      setErrorObj((pre)=>({...pre,location:"",city:""}));
      getPocCityList(value);
      setSelectedFileName({ ...selectedFileName, [key]: value,city:"" });
    }
    
    else{
      setSelectedFileName({ ...selectedFileName, [key]: value });
    }
 
    
  };
 
  const onChangePropertyType = (value) => {
    setPropertyType(value);
    onSelectChange(value, "propertyType");
  };
 

  const handleKeyPress = (event) => {
    if (event.key === 'Enter'&&event.target.tagName !== 'TEXTAREA') {
      event.preventDefault();
      onClickImport(jsonArray, selectedFileName);
    }
  };
  useEffect(() => {
   
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    
   
  }, [selectedFileName,jsonArray]);
  const onSubmitImport =(e)=>{
    e.preventDefault();
    onClickImport(jsonArray, selectedFileName)
  }

  return (
    <form >
      <div className="import-export-container">
        <Select
          label="Property type*"
          options={propertyTypeList}
          onChange={(e) => onChangePropertyType(e.target.value)}
          errorMessage={errorObj?.propertyType && errorObj?.propertyType}
          isInvalid={!!errorObj?.propertyType}
          disabled={selectedFileName.fileSelected ? true : false}
        />

        {type !== "agent" && (
          <Select
            label="Select POC*"
            options={pocList}
            onChange={(e) => onSelectChange(e.target.value, "userId")}
            errorMessage={errorObj?.userId && errorObj?.userId}
            isInvalid={!!errorObj?.userId}
          />
       )}

       
        <div className="city-wrapper">
        <MultiSelect
            onChange={(val) => {
              onSelectChange(val, "city");
            }}
            isMulti={true}
            name={"City*"}
            options={nameToLabel(cityTempData)}
            value={selectedFileName?.city}
            lable={"City*"}
            placeholder={"Select city"}
            errormessage={errorObj?.city && errorObj?.city}
          />
        </div>

         
          <Form.Group className="w-100">
            <Form.Label className="fw-700 f-14 text-black">{`${label}*`}</Form.Label>
            <div
              className="p-1 upload-container form-control d-flex justify-content-between align-items-center "
              style={{
                border: errorObj?.fileSelected
                  ? "1px solid #dc3545"
                  : "1px solid #ced4da",
                backgroundColor:
                  selectedFileName?.city === "" ||
                  selectedFileName?.propertyType === ""
                    ? "#E9ECEF"
                    : "#fff",
              }}
            >
              <div className="d-flex  flex-wrap justify-content-start align-items-center upload-container-wrapper">
                {selectedFileName.fileSelected === "" ? (
                  ""
                ) : (
                  <div className="m-1">
                    <div className="upload-list ">
                      <label className="upload-image-text" title={selectedFileName.fileSelected}>
                        {selectedFileName.fileSelected}
                      </label>
                      &nbsp;
                      <AiOutlineClose
                        style={{
                          color: "white",
                          fontSize: "em",
                          paddingLeft: "3px",
                          cursor: "pointer",
                        }}
                        size={20}
                        onClick={() => remove()}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="upload-icon">
                <label
                  htmlFor={"fileUpload"}
                  className="cursor-pointer"
                  style={{
                    backgroundColor:
                      selectedFileName?.city === "" ||
                      selectedFileName?.propertyType === ""
                        ? "#E9ECEF"
                        : "#fff",
                  }}
                >
                  <HiUpload
                    size={20}
                    style={{ color: "#3E4958" }}
                    onClick={onClickUpload}
                  />
                  <input
                    className="d-none"
                    type="file"
                    id="fileUpload"
                    accept=".xls,.xlsx, .csv"
                    disabled={
                      selectedFileName?.city === "" ||
                      selectedFileName?.propertyType === ""
                        ? true
                        : false
                    }
                  />
                  <br />
                </label>
              </div>
            </div>
          </Form.Group>
          <div className="invalid-feedback">{errorObj?.fileSelected}</div>
          {propertyType && (
            <div className="btn-container mt-4">
              
                <Button
                type={"button"}
                  className="btn-outline-color-primary"
                  onClick={() => onClickDownloadTemplate()}
                >
                  Download template
                </Button>
                <Button
                   type="submit"
                  className="btn-color-primary"
                  disabled={loading}
                  loading={loading}
                  onClick={(e) =>onSubmitImport(e) }
                >
                  Import
                </Button>
            
            </div>
          )}
       
      </div>
​
      
    </form>
  );
};
export default ImportCsv;
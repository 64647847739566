import { useEffect, useState } from "react";
import RectHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import {
    AccessDenied,
    getData,
    putData,
} from "../../api/index";
import EditEditors from "../../components/Ckeditor/index";
import Button from "../../components/Form/Button";

import Loader from "../../components/Loader";

const TermsConditions = () => {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState();
  const [update, setUpdate] = useState({});
  const [updateId, setUpdateId] = useState();
  const [loading, setLoading] = useState(false);
  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
const access={
  edit : loginPermission?.includes(menuId?.manageTermsAndConditions) ? true :false,
}
  const getListData = async () => {
    let url ="/termAndCondition/";
    setLoading(true)
    const userData = await getData(url, {});
    const { data, statusCode } = userData;
    if (statusCode === 200) {
      setEdit(data);
      setUpdateId(data._id);
      setUpdate({description:data?.description})
      setLoading(false)
    } else {
      setLoading(false)
    }
  };
  const updateDataOnChange = (data) => {
    setUpdate(data);
    
  };
  const updateData = async () => {
    if(update.description && update.description.length > 0){
    let url = `/termAndCondition/${updateId}`;
    const newData = await putData(url, {}, update);
    const { statusCode} = newData;
    if (statusCode === 200) {
      setShow(false);
    } 
   
    
  }
  
  
  };
  useEffect(() => {
    getListData();
  }, [show]);
  return (
    <>
    {loginPermission?.includes(menuId?.termsConditions)?
        <div className="privacy_fullpage">
          <div className="d-flex justify-content-between mb-2">
            <div className="privacy_text_header ms-4"> Terms and condition</div>
           {loading?null: <Button
              variant="primary"
              className="privacy_edit_button me-4"
              onClick={() => setShow(!show)}
              disabled={access.edit?show:true}
            >
              Edit
            </Button>}
          </div>
          {loading?<Loader/>:<>
          
          {!show && (
            <div className="content_fullcards">
              <div className="privacy_text">
                <p>{RectHtmlParser(edit?.description)}</p>
              </div>
            </div>
          )}
          {show && (
            <div className="editCKeditors">
              <EditEditors data={edit} updateData={updateDataOnChange} />
              <div className="privacy-button-footer">
                <Button
                  variant="primary"
                  className="privacy_update_button"
                  onClick={updateData}
                  disabled={update?.description?.length === 0?true:false}
                >
                  Update Changes
                </Button>
              </div>
            </div>
          )}
          </>}
        </div>
        :
        <AccessDenied/>}
    </>
  );
};
export default TermsConditions;

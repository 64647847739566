import { Row, Col } from "react-bootstrap";
import Input from "../../../components/Form/Input";
import { useEffect } from "react";

const UploadVideoUrl = ({
    propertyDetail, 
    setVideoUrl, 
    videoUrl, 
    videoUrlError, 
    setVideoUrlError, 
    isExitData,
    propertyType,
    propertyVideoURLDivRef
  }) => {  
  const handleVideoUrlChange = (e) => {
    setVideoUrlError("")
    setVideoUrl(e.target.value)
  };

  useEffect(() => {
    if(isExitData) {
      if(propertyType === 'Flex Space') {
        if(propertyDetail['coworkingImages']?.videoUrl) {
          setVideoUrl(propertyDetail['coworkingImages']?.videoUrl[0]?.url)
        } 
      } else {
        if(propertyDetail['imagesAndVideos']?.videoUrl) {
          setVideoUrl(propertyDetail['imagesAndVideos']?.videoUrl[0]?.url) 
        }
      }    
    }
  }, [])
  return (
    <div className="property-details-container pt-4" ref={propertyVideoURLDivRef}>
      <>
        <Row>
          <Col md="1" />
          <Col md="10">
            <h4>Add Video URL</h4>
            <Input
              size="w-100"
              type="text"
              onChange={(e) => {
                handleVideoUrlChange(e);
              }}
              placeholder={`"Paste your Youtube or Vimeo link"`}
              errorMessage = {videoUrlError}
              isInvalid = {videoUrlError}
              value={videoUrl}
            />
          </Col>
        </Row>
      </>
    </div>
  );
};

export default UploadVideoUrl;
